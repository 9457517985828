import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsOpenExternal: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M14.111 17.667A1.111 1.111 0 0013 18.777V35.89A1.111 1.111 0 0014.111 37h17.111a1.111 1.111 0 001.111-1.111v-9.333a2 2 0 014 0v9.333A5.11 5.11 0 0131.223 41H14.11A5.111 5.111 0 019 35.889V18.778a5.111 5.111 0 015.111-5.111h9.334a2 2 0 010 4H14.11zM27.667 11a2 2 0 012-2H39a2 2 0 012 2v9.333a2 2 0 11-4 0V13h-7.333a2 2 0 01-2-2z"
    />
    <path
      fill="currentColor"
      d="M40.414 9.586a2 2 0 010 2.828l-17.11 17.111a2 2 0 11-2.83-2.828L37.587 9.586a2 2 0 012.828 0z"
    />
  </Icon>
);

export default GeneralActionsOpenExternal;
