import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersGlobe: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M41.52 21a17.188 17.188 0 00-1.05-3 17 17 0 00-12.82-9.79A12.94 12.94 0 0025.88 8h-1.75c-.593.02-1.185.08-1.77.18A17 17 0 009.53 18c-.425.967-.76 1.971-1 3A17.47 17.47 0 008 25a17.15 17.15 0 00.46 4 16.34 16.34 0 001 3 17 17 0 0012.86 9.84c.586.099 1.177.162 1.77.19L25 42h.88a14.58 14.58 0 001.76-.19A17 17 0 0040.51 32c.43-.965.764-1.97 1-3 .32-1.31.484-2.652.49-4a17.47 17.47 0 00-.48-4zm-4.44-3h-5.42a2.476 2.476 0 00-.13-.35l-2.47-6A14 14 0 0137.08 18zM29.5 29h-9a16.17 16.17 0 010-8h9a16.17 16.17 0 010 8zm-3.94 10a7.84 7.84 0 01-1.12 0l-2.89-7h6.9l-2.89 7zm-1.13-28c.38-.027.76-.027 1.14 0l2.85 7h-6.84l2.85-7zm-3.49.6l-2.47 6c-.052.113-.095.23-.13.35h-5.42a14 14 0 018.02-6.33v-.02zm-9.56 16.66A14.308 14.308 0 0111 25c-.002-1.118.13-2.233.39-3.32.06-.25.14-.49.22-.73h5.82a18.93 18.93 0 000 8h-5.85c-.07-.23-.14-.46-.2-.69zM12.86 32h5.46c0 .12.09.24.14.36L21 38.4a14.07 14.07 0 01-8.14-6.4zm16.19 6.44l2.49-6.08c.05-.12.1-.24.14-.36h5.46a14 14 0 01-8.09 6.39v.05zm9.57-10.14c0 .24-.13.47-.2.7h-5.85a18.93 18.93 0 000-8h5.82c.07.23.15.46.21.7.52 2.17.52 4.43 0 6.6h.02z"
    ></path>
  </Icon>
);

export default GeneralOthersGlobe;
