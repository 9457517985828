import React, { useRef, useState, useLayoutEffect } from 'react';
import { HStack, Center, StackProps } from '@wegroup/design-system';
import flattenChildren from 'react-keyed-flatten-children';

interface Props {
  expandElement?: React.ReactNode;
  onChange?: (hiddenAmount: number, visibleAmount: number) => void;
}

const OverflowCollapse: React.FC<
  React.PropsWithChildren<Props & Omit<StackProps, 'onChange'>>
> = ({ children, expandElement, onChange = () => null, ...props }) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const innerWrapper = useRef<HTMLDivElement>(null);
  const expandElementWrapper = useRef<HTMLDivElement>(null);
  const initialWidths = useRef<number[]>([]);
  const [isCalculated, setisCalculated] = useState(false);
  const [amountOfFittingChildren, setAmountOfFittingChildren] =
    useState<number>(0);

  const calculateItems = () => {
    if (wrapper.current) {
      const parentWidth = wrapper.current.offsetWidth;
      const fullWidthOfChildren = initialWidths.current.reduce(
        (partial_sum, a) => partial_sum + a,
        0,
      );

      if (fullWidthOfChildren < parentWidth) {
        setAmountOfFittingChildren(initialWidths.current.length);
        onChange(0, initialWidths.current.length);
        return;
      }

      let width = 0;
      if (expandElementWrapper.current) {
        const styles = getComputedStyle(expandElementWrapper.current);
        const marginLeft = parseFloat(styles.marginLeft);
        const marginRight = parseFloat(styles.marginRight);
        width =
          width +
          expandElementWrapper.current.offsetWidth +
          marginLeft +
          marginRight;
      }

      let childIndex = 0;

      for (let index = 0; index < initialWidths.current.length; index++) {
        const elementWidth = initialWidths.current[index];

        if (elementWidth && width + elementWidth <= parentWidth) {
          width = width + elementWidth;
          childIndex++;
        } else if (width + elementWidth > parentWidth) {
          break;
        }
      }

      setAmountOfFittingChildren(childIndex);
      onChange(initialWidths.current.length - childIndex, childIndex);
    }
  };

  useLayoutEffect(() => {
    if (innerWrapper.current) {
      initialWidths.current = (
        Array.from(innerWrapper.current.children) as HTMLElement[]
      ).map((element) => {
        const styles = getComputedStyle(element);
        const marginLeft = parseFloat(styles.marginLeft);
        const marginRight = parseFloat(styles.marginRight);
        const elementWidth = element.offsetWidth + marginLeft + marginRight;

        return elementWidth;
      });
    }
    calculateItems();

    setisCalculated(true);

    window.addEventListener('resize', calculateItems);

    return () => window.removeEventListener('resize', calculateItems);
  }, []);

  return (
    <HStack
      data-test-id="DesignSystem_OverflowCollapse"
      ref={wrapper}
      minWidth={0}
      flexGrow={1}
      spacing="1"
    >
      <HStack flexGrow={1} ref={innerWrapper} {...props}>
        {flattenChildren(children).map((child, index) => {
          if (!isCalculated || index < amountOfFittingChildren) {
            return child;
          }

          return null;
        })}
      </HStack>
      {!(
        isCalculated &&
        amountOfFittingChildren >= flattenChildren(children).length
      ) && <Center ref={expandElementWrapper}>{expandElement}</Center>}
    </HStack>
  );
};

export default OverflowCollapse;
