import React from 'react';
import { VStack, useBoolean, Card } from '@wegroup/design-system';
import { Annex } from '../../../../../../types/SMT';
import { FormProvider, useForm } from 'react-hook-form';
import AnnexInputCardHeader from './AnnexInputCardHeader';
import { useTranslation } from 'react-i18next';
import AnnexInputCardForm from './AnnexInputCardForm';
import AnnexInputCardOverview from './AnnexInputCardOverview';
import { annexOptions } from './constants/inputs';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';

interface Props {
  annex?: Annex;
  onSubmit: (annex: Annex) => void;
  onRemove: () => void;
}

const validationSchema = object({
  type: string().required('REQUIRED'),
  surface: string().required('REQUIRED'),
});

const AnnexInputCard: React.FC<Props> = ({ annex, onSubmit, onRemove }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useBoolean(!annex);
  const isNewAnnex = !annex;
  const methods = useForm({
    defaultValues: annex,
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = methods.handleSubmit((values) => {
    onSubmit(values);
    setIsEditing.off();
  });

  const getLabel = () => {
    if (isNewAnnex) return t('Add a new annex');
    if (isEditing) return t('Edit annex');
    return annexOptions.find(({ value }) => value === annex.type)?.label;
  };

  return (
    <FormProvider {...methods}>
      <Card p="0" flexGrow="1" boxShadow="none" bg="white" overflow="none">
        <AnnexInputCardHeader
          label={getLabel()}
          setIsEditing={setIsEditing}
          isEditing={isEditing}
          onRemove={onRemove}
          onCancel={isNewAnnex ? onRemove : setIsEditing.off}
          onSave={handleSubmit}
        />
        <VStack align="stretch" spacing="3" p="4">
          {isEditing || isNewAnnex ? (
            <AnnexInputCardForm onSave={handleSubmit} />
          ) : (
            <AnnexInputCardOverview annex={annex} />
          )}
        </VStack>
      </Card>
    </FormProvider>
  );
};

export default AnnexInputCard;
