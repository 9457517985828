import { IMessage } from '@novu/notification-center';
import { NotificationPayload } from '../types/Notification';

const useNotificationPayload = (notification: IMessage) => {
  return getNotificationPayload(notification);
};

export default useNotificationPayload;

export const getNotificationPayload = (notification: IMessage) => {
  const payload = notification.payload as unknown as NotificationPayload;
  return payload;
};
