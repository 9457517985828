import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralLabelsDocumentZip: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M37.28 16h-7a.29.29 0 0 1-.3-.3v-7a.3.3 0 0 1 .51-.21l7 7a.3.3 0 0 1-.21.51Z"
      fill="#888D9B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3 18h9.4a.292.292 0 0 1 .28.184.29.29 0 0 1 .02.116v23.4a.29.29 0 0 1-.3.3H12.3a.29.29 0 0 1-.3-.3V8.3a.29.29 0 0 1 .3-.3h15.4a.29.29 0 0 1 .3.3v9.4a.29.29 0 0 0 .3.3Zm-5.604 15.508v-1.584h-4.56l4.5-5.376V25h-6.48v1.572h4.272L16 31.9v1.608h6.696Zm3.199 0V25h-1.68v8.508h1.68Zm3.656-7.068v2.436h1.308c.828 0 1.332-.48 1.332-1.212 0-.756-.504-1.224-1.332-1.224H29.55Zm4.308 1.212c0 1.548-1.116 2.652-2.796 2.652h-1.524v3.204h-1.656V25h3.18c1.68 0 2.796 1.116 2.796 2.652Z"
      fill="#888D9B"
    />
  </Icon>
);

export default GeneralLabelsDocumentZip;
