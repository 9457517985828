import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyFurnitureFire: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M27.12 28.43v-5.54a1.05 1.05 0 100-2.1h-4.24a1.05 1.05 0 000 2.1v5.54a8.43 8.43 0 00-6.38 8.15V44a1.06 1.06 0 001.06 1h14.88a1.06 1.06 0 001.06-1v-7.42a8.43 8.43 0 00-6.38-8.15z"
    ></path>
    <path
      fill="currentColor"
      d="M20.75 27.61v-3.42a3.131 3.131 0 01-1.06-2.35 3.19 3.19 0 013.19-3.16h4.24a3.19 3.19 0 013.19 3.16 3.13 3.13 0 01-1.06 2.35v3.38c2-1.06 4.18-3.31 4.18-7.91 0-4.1-4.18-6.83-4.18-6.83v1.75a1.5 1.5 0 01-1.54 1.49c-1.09 0-1.62-1.06-1.62-2.62s.92-3.17 0-5.29C24.73 5.18 20.75 5 20.75 5a7.89 7.89 0 011 3.63c0 2.32-1.11 3.59-3.05 5.76a7.6 7.6 0 00-2.21 5.27c.01 4.67 2.29 6.9 4.26 7.95z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyFurnitureFire;
