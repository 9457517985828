import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilitySurroundings: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M11.84 35.57v-3.65l3.56-3.57-1.22-1.21-2.34 2.34v-3.66A6.19 6.19 0 0017 19.75a6.1 6.1 0 00-1.41-3.87c-.14-.16-.26-.34-.42-.5-.16-.16-.35-.29-.52-.43a6.13 6.13 0 00-10 4.8 6.18 6.18 0 005.16 6.07v8.91a6.338 6.338 0 00-1.45-.18A6.41 6.41 0 002 41h12.79a6.4 6.4 0 00-2.95-5.43zM47.47 41h-3a.5.5 0 01-.5-.5v-3.74a.5.5 0 01.67-.47A5.001 5.001 0 0148 40.42a.509.509 0 01-.53.58z"
    ></path>
    <path
      fill="currentColor"
      d="M41.56 18.56L38 16.17v-5.36a1 1 0 00-1-1h-4a1 1 0 00-1 1v1.34l-4.45-3a1 1 0 00-1.1 0l-8.91 6a8 8 0 011.46 4.6 8.09 8.09 0 01-3 6.34l.84.85 1.28 1.27a.19.19 0 010 .28l-1.28 1.28-2.94 2.91a.2.2 0 00-.06.14v1.66a.18.18 0 00.07.15A8.392 8.392 0 0116.79 41H19V31a1 1 0 011-1h5a1 1 0 011 1v10h15a1 1 0 001-1V19.4a1 1 0 00-.44-.84z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilitySurroundings;
