import { Collapse, Divider } from '@wegroup/design-system';
import React from 'react';
import QuestionBodyFooter from './QuestionBodyFooter';
import QuestionBodyInput from './QuestionBodyInput';

interface Props {
  onSubmit: () => void;
  showInput: boolean;
  isDisabled?: boolean;
  isSuccess?: boolean;
  isSubmitDisabled?: boolean;
  tooltipText?: string;
}

const QuestionBodyInputAndFooter: React.FC<Props> = ({
  onSubmit,
  showInput,
  isDisabled,
  isSubmitDisabled,
  isSuccess,
  tooltipText,
}) => {
  return (
    <Collapse in={showInput}>
      <Divider borderColor="gray.50" />
      <QuestionBodyInput isDisabled={isDisabled} />
      <Collapse in={!isSuccess}>
        <Divider borderColor="gray.50" />
        <QuestionBodyFooter
          isSubmitDisabled={isSubmitDisabled}
          isDisabled={isDisabled}
          onSubmit={onSubmit}
          tooltipText={tooltipText}
        />
      </Collapse>
    </Collapse>
  );
};

export default QuestionBodyInputAndFooter;
