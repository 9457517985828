import React, { ReactElement } from 'react';
import { VStack, DrawerHeader, HStack, Text } from '@wegroup/design-system';

interface Props {
  title: string;
  description?: string;
  icon?: ReactElement;
  rightElement?: ReactElement;
}

const DrawerGeneralHeader: React.FC<Props> = ({
  title,
  description,
  icon,
  rightElement,
}) => {
  return (
    <DrawerHeader
      as={HStack}
      borderBottom="1px"
      borderColor="gray.50"
      py="4"
      px="4"
      spacing={{ base: '2', laptop: '4' }}
    >
      {icon}
      <VStack spacing="1" flexGrow="1" align="stretch">
        <Text textStyle="normalBodyMedium">{title}</Text>
        {description && (
          <Text textStyle="smallBody" color="gray.500">
            {description}
          </Text>
        )}
      </VStack>
      {rightElement}
    </DrawerHeader>
  );
};

export default DrawerGeneralHeader;
