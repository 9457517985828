import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyCondensation: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M23 37.2c0 .7-.1 1.5-.4 2.2-.3.7-.7 1.3-1.2 1.9-.5.5-1.1 1-1.8 1.3-.6.2-1.4.4-2.1.4-.7 0-1.5-.2-2.2-.5-.7-.3-1.3-.7-1.8-1.3-.5-.5-.9-1.2-1.2-1.9-.3-.7-.4-1.4-.4-2.2C12 34 16.1 27 17.5 27c1.4 0 5.5 7 5.5 10.2z"
    ></path>
    <path
      fill="currentColor"
      d="M37.7 12.3c-.2-.2-.4-.3-.7-.3H15c-.3 0-.5.1-.7.3-.2.2-.3.4-.3.7v14.5c1.8-2.5 3-2.5 3.5-2.5s2.3 0 5 4.9c1.1 1.9 1.9 3.9 2.4 6.1H37c.3 0 .5-.1.7-.3.2-.2.3-.4.3-.7V13c0-.3-.1-.5-.3-.7zm-15.5 1.9c.2.2.2.5 0 .7l-5.4 5.4c-.2.2-.5.2-.7 0-.2-.2-.2-.5 0-.7l5.4-5.4c.2-.2.5-.2.7 0zm-6 2.3l2.4-2.4c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-2.4 2.4c-.2.2-.5.2-.7 0-.2-.2-.2-.5 0-.7zm.6 6.7c-.2.2-.5.2-.7 0-.2-.2-.2-.5 0-.7l8.4-8.4c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-8.4 8.4zm2.3 0c-.2-.2-.2-.5 0-.7l8.4-8.4c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-8.4 8.4c-.1.2-.5.2-.7 0zm1.6 1.5c-.2-.2-.2-.5 0-.7l9.9-9.9c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-9.9 9.9c-.2.2-.5.2-.7 0zm1.2 1.7c-.2-.2-.2-.5 0-.7l11.6-11.6c.2-.2.5-.2.7 0 .2.2.2.5 0 .7L22.6 26.4c-.1.2-.5.2-.7 0zm1.2 1.9c-.2-.2-.2-.5 0-.7l12-12c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-12 12c-.2.2-.5.2-.7 0zm1.8 1.9c-.2.2-.5.2-.7 0-.2-.2-.2-.5 0-.7l11-11c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-11 11zm.2 2.1c-.2-.2-.2-.5 0-.7l10-10c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-10 10c-.2.1-.5.1-.7 0zm1.4 1.5c-.2-.2-.2-.5 0-.7l8.6-8.6c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-8.6 8.6c-.2.2-.5.2-.7 0zm3 0c-.2-.2-.2-.5 0-.7l5.6-5.6c.2-.2.5-.2.7 0 .2.2.2.5 0 .7l-5.6 5.6c-.2.2-.5.2-.7 0zm6.4-2.6l-2.6 2.6c-.2.2-.5.2-.7 0-.2-.2-.2-.5 0-.7l2.6-2.6c.2-.2.5-.2.7 0 .2.2.2.5 0 .7z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyCondensation;
