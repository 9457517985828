import React from 'react';
import {
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  IconButton,
  useBoolean,
} from '@wegroup/design-system';
import { parseAnyStringToDate } from '../../../services/dateHelper';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

interface InputProps {
  value: Date | null;
  onChange: (date: Date | null) => void;
  placeholder?: string;
  isDisabled?: boolean;
  dateFormat?: string;
}

const DateInput: React.FC<React.PropsWithChildren<InputProps>> = ({
  value,
  onChange,
  placeholder,
  isDisabled = false,
  dateFormat = 'dd/MM/yyyy',
}) => {
  const { t } = useTranslation();
  const [isReadOnly, setIsReadOnly] = useBoolean();

  const computeReadableDate = (date: Date | null) => {
    if (!date) return '';
    return format(date, dateFormat);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(new Date(event.target.value));
  };

  const handleOnBlur = () => {
    setIsReadOnly.on();
    if (typeof value === 'string') {
      onChange(parseAnyStringToDate(value as string));
    }
  };

  const handleOnClearClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsReadOnly.off();
    onChange(null);
  };

  return (
    <InputGroup>
      <Input
        isDisabled={isDisabled}
        value={computeReadableDate(value)}
        onChange={handleInputChange}
        onBlur={handleOnBlur}
        readOnly={isReadOnly}
        placeholder={placeholder || t('PICK-A-DATE')}
        _readOnly={{ cursor: 'pointer' }}
      />
      {!isDisabled && (
        <InputRightElement onClick={(e) => e.stopPropagation()}>
          <IconButton
            bg="transparent"
            aria-label="clear input"
            icon={<Icon name="IconActionClose" />}
            h="auto"
            minW="auto"
            p="2px"
            onClick={handleOnClearClick}
            _hover={{
              bg: 'gray.strokeAndBg',
            }}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default DateInput;
