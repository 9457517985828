import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusDislike: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.177 39.946a2.235 2.235 0 01-2.236-2.235v-2.117a10.471 10.471 0 00-8.47-10.278V14.417a4.353 4.353 0 014.352-4.353h14.824a2 2 0 00.17-.007c.224-.02.577.05 1.005.478.44.44.865 1.184 1.106 2.19l2.066 10.33a2.235 2.235 0 01-2.23 2.068h-6.352a2 2 0 00-2 2V37.71a2.235 2.235 0 01-2.235 2.235zm-11.46-32.14a4.121 4.121 0 00-3.364-1.742H9.118A4.118 4.118 0 005 10.182v14.823a4.118 4.118 0 004.118 4.118h6.353a6.47 6.47 0 016.47 6.47v2.118a6.235 6.235 0 1012.47 0v-8.588h4.354A6.235 6.235 0 0045 22.888c0-.132-.013-.264-.039-.393l-2.117-10.588a1.98 1.98 0 00-.014-.064c-.37-1.577-1.097-3.053-2.18-4.136-1.078-1.079-2.506-1.746-4.077-1.643h-14.75a8.353 8.353 0 00-5.106 1.743zM13.47 25.124H9.118A.118.118 0 019 25.005V10.182a.118.118 0 01.118-.118h4.235a.118.118 0 01.118.118v14.94z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralStatusDislike;
