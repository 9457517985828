import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationToolkit: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M28.63 25l4.88-4.88a3.6 3.6 0 003.63-1.42l4.71-6.37a.87.87 0 00-.13-1.12l-2.95-3a.88.88 0 00-1.12-.13l-6.37 4.71a3.6 3.6 0 00-1.42 3.63L25 21.37l-4.52-4.52a6.45 6.45 0 00-8.38-8.39L16.57 13l-3.64 3.65-4.47-4.51a6.45 6.45 0 008.33 8.33l3.58 3.58A4.41 4.41 0 0019 25l-9.73 9.63a4.33 4.33 0 00.05 6.17 4.33 4.33 0 006-.06L25 31.05a4.408 4.408 0 001-1.42l11.57 11.61a2.574 2.574 0 103.64-3.64L28.63 25z"
    ></path>
  </Icon>
);

export default GeneralNavigationToolkit;
