import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsAddPlusCircle: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M25 10.349c-8.092 0-14.651 6.56-14.651 14.651 0 8.092 6.56 14.651 14.651 14.651 8.092 0 14.651-6.56 14.651-14.651 0-8.092-6.56-14.651-14.651-14.651zM7 25c0-9.941 8.059-18 18-18s18 8.059 18 18-8.059 18-18 18S7 34.941 7 25z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.884 25c0-.925.75-1.674 1.674-1.674h10.884a1.674 1.674 0 110 3.348H19.558c-.925 0-1.674-.75-1.674-1.674z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M25 17.884c.925 0 1.674.75 1.674 1.674v10.884a1.674 1.674 0 01-3.348 0V19.558c0-.925.75-1.674 1.674-1.674z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralActionsAddPlusCircle;
