import React from 'react';
import { Box, Icon } from '@chakra-ui/react';

const RiskObjectsVehicleTrailer: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Box transform="scaleX(-1)">
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        d="M7 16.375v10.5h6v.749h3v-.75h6v4.5h8.25a3 3 0 116 0 3 3 0 116 0H43v-15H7z"
      ></path>
      <path
        fill="currentColor"
        d="M35.5 31.375a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zM41.5 31.376a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
      ></path>
    </Icon>
  </Box>
);

export default RiskObjectsVehicleTrailer;
