import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersOpenLockOrUnlocked: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M19 17c0-3.505 2.57-6 5.5-6 3.448 0 5.5 1.974 5.5 4.314a2 2 0 104 0C34 10.026 29.337 7 24.5 7 19.147 7 15 11.505 15 17v4.404h-1.197a5.2 5.2 0 00-5.2 5.2V37.8a5.2 5.2 0 005.2 5.199h22.394a5.2 5.2 0 005.2-5.2V26.605a5.2 5.2 0 00-5.2-5.2H19V17zm-6.396 9.604a1.2 1.2 0 011.199-1.2h22.394a1.2 1.2 0 011.2 1.2V37.8a1.2 1.2 0 01-1.2 1.199H13.803a1.2 1.2 0 01-1.2-1.2V26.605z"
    />
  </Icon>
);

export default GeneralOthersOpenLockOrUnlocked;
