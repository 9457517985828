import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersDiscount: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M32.395 17.565a2 2 0 010 2.828L20.393 32.395a2 2 0 01-2.828-2.828l12.002-12.002a2 2 0 012.828 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        d="M19.98 20.98a1 1 0 100-2 1 1 0 000 2z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.98 20.98a1 1 0 100-2 1 1 0 000 2zm-3-1a3 3 0 116 0 3 3 0 01-6 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        d="M29.98 30.98a1 1 0 100-2 1 1 0 000 2z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M29.98 30.98a1 1 0 100-2 1 1 0 000 2zm-3-1a3 3 0 116 0 3 3 0 01-6 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M25 9a2.4 2.4 0 00-1.702.708l-.004.004-1.404 1.404a6.4 6.4 0 01-4.51 1.862H15.38a2.4 2.4 0 00-2.401 2.4v2.002a6.4 6.4 0 01-1.862 4.51l-.004.004-1.4 1.4-.004.004a2.401 2.401 0 000 3.404l.004.004 1.404 1.404a6.4 6.4 0 011.862 4.51v2.001a2.4 2.4 0 002.4 2.401h2.002a6.4 6.4 0 014.51 1.862l.004.004 1.404 1.404a2.4 2.4 0 003.404 0l.004-.004 1.4-1.4.004-.004a6.4 6.4 0 014.51-1.862h2.001a2.4 2.4 0 002.401-2.4V32.62a6.4 6.4 0 011.862-4.51l.004-.004 1.4-1.4.004-.004a2.402 2.402 0 000-3.404l-.004-.004-1.4-1.4-.004-.004a6.4 6.4 0 01-1.862-4.51V15.38a2.4 2.4 0 00-2.4-2.401H32.62a6.4 6.4 0 01-4.51-1.862l-.004-.004-1.4-1.4-.004-.004A2.401 2.401 0 0025 9zM8.978 15.379a6.4 6.4 0 016.4-6.401h2a2.4 2.4 0 001.691-.698l1.392-1.393.003-.002a6.4 6.4 0 019.073 0c0 .001 0 .002.002.002L30.93 8.28l.002.001a2.4 2.4 0 001.689.697h2a6.4 6.4 0 016.4 6.4v2c0 .633.25 1.24.697 1.69l.001.001 1.393 1.392.002.003a6.401 6.401 0 01-.002 9.075L41.72 30.93l-.002.002a2.4 2.4 0 00-.696 1.69v1.998a6.401 6.401 0 01-6.4 6.401h-2a2.4 2.4 0 00-1.69.696l-.001.002-1.392 1.393-.002.001a6.399 6.399 0 01-9.073 0l-.003-.001-1.392-1.393-.002-.002a2.4 2.4 0 00-1.69-.696H15.38a6.401 6.401 0 01-6.401-6.4v-2a2.4 2.4 0 00-.698-1.691l-1.393-1.392-.002-.003a6.4 6.4 0 010-9.073c.001 0 .002 0 .002-.002L8.28 19.07l.001-.002a2.4 2.4 0 00.697-1.689v-2z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersDiscount;
