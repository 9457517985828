import React from 'react';
import { useTranslation } from 'react-i18next';
import { Annex } from '.';
import { LabelParamFallback } from '../../../../../common/src/components/label-param-fallback';
import { annexOptions } from './constants/inputs';

interface Props {
  annex: Annex;
}

const AnnexInputCardOverview: React.FC<Props> = ({ annex }) => {
  const { t } = useTranslation();

  return (
    <>
      <LabelParamFallback label={t('Type')}>
        {annexOptions.find(({ value }) => value === annex.type)?.label}
      </LabelParamFallback>
      <LabelParamFallback label={t('Surface')}>
        {annex.surface && `${annex.surface} m²`}
      </LabelParamFallback>
    </>
  );
};

export default AnnexInputCardOverview;
