import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskAnalysisSubjectsLifeAndFuture: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 12.5a2.5 2.5 0 00-2.5 2.5v24a2.5 2.5 0 002.5 2.5h24a2.5 2.5 0 002.5-2.5V15a2.5 2.5 0 00-2.5-2.5H13zM7.5 15A5.5 5.5 0 0113 9.5h24a5.5 5.5 0 015.5 5.5v24a5.5 5.5 0 01-5.5 5.5H13A5.5 5.5 0 017.5 39V15z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M33 5.5A1.5 1.5 0 0134.5 7v8a1.5 1.5 0 01-3 0V7A1.5 1.5 0 0133 5.5zM17 5.5A1.5 1.5 0 0118.5 7v8a1.5 1.5 0 01-3 0V7A1.5 1.5 0 0117 5.5zM7.5 23A1.5 1.5 0 019 21.5h32a1.5 1.5 0 010 3H9A1.5 1.5 0 017.5 23zM21.5 31a1.5 1.5 0 011.5-1.5h2a1.5 1.5 0 010 3h-2a1.5 1.5 0 01-1.5-1.5z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 29.5a1.5 1.5 0 011.5 1.5v6a1.5 1.5 0 01-3 0v-6a1.5 1.5 0 011.5-1.5z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisSubjectsLifeAndFuture;
