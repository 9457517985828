import useFlowQuestions from '../../../../../hooks/useFlowQuestions';
import { FlowsQuestion } from '../../../../../types/FlowsTypes';

const useTargetQuestion = (
  question: FlowsQuestion,
): FlowsQuestion | undefined => {
  const questions = useFlowQuestions();
  const targetQuestion = getTargetQuestion(question, questions);

  return targetQuestion;
};

export const getTargetQuestion = (
  question: FlowsQuestion,
  questions: FlowsQuestion[],
) => {
  return questions.find(({ id }) => id === question.rules.indexed_question_id);
};

export default useTargetQuestion;
