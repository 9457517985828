import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationUnderwriting: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M33.5 10h-26a.5.5 0 00-.5.5v18a.5.5 0 00.5.5H11l2.4 5.61a.65.65 0 001.2 0L17 29h16.5a.5.5 0 00.5-.5v-18a.5.5 0 00-.5-.5z"
    ></path>
    <path
      fill="currentColor"
      d="M43 25.5v12a.5.5 0 01-.5.5H40l-2 4.67a.54.54 0 01-1 0L35 38H22.34a.5.5 0 01-.5-.5V31H33.5a2.5 2.5 0 002.5-2.5V25h6.5a.5.5 0 01.5.5z"
    ></path>
  </Icon>
);

export default GeneralNavigationUnderwriting;
