import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationHolderTypeLivingWithParents: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3749_10082"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3749_10082)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M178.082 95.781l-57.517 57.512v73.511h115.029v-73.511l-57.512-57.512z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M235.594 90.023a5.899 5.899 0 00-5.898-5.898h-9.748a5.9 5.9 0 00-5.898 5.898v11.668l21.565 21.313-.021-32.98z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M254.002 141.202l-65.812-65.05a14.382 14.382 0 00-20.22 0l-65.812 65.05a10.784 10.784 0 0015.249 15.249l60.676-60.67 60.669 60.67a10.786 10.786 0 007.625 3.159 10.786 10.786 0 0010.783-10.783c0-2.86-1.136-5.603-3.158-7.625z"
        ></path>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          stroke={colors.primary[100]}
          strokeWidth="4"
          d="M146.926 173.148c2.702.738 4.459 1.758 6.376 3.7 1.653 1.674 2.47 3.055 3.115 5.269.22.751.23 1.119.268 8.973.038 8.134.037 8.194-.198 8.74-.438 1.019-1.306 1.583-2.433 1.583-.81 0-1.34-.224-1.893-.801-.727-.759-.705-.495-.705-8.135 0-3.812-.03-7.01-.067-7.107-.04-.103-.199-.177-.38-.177s-.34.074-.38.177c-.037.097-.068 3.248-.068 7.001v6.824l-.838 10.534c-.461 5.793-.887 10.829-.947 11.189-.257 1.54-1.437 2.684-3.088 2.992-.963.18-3.762.068-4.443-.178-.728-.262-1.679-1.154-2.036-1.909-.279-.591-.314-.933-1.179-11.708l-.892-11.099v-6.735c0-3.704-.031-6.814-.068-6.911-.095-.248-.572-.221-.711.039-.077.143-.116 2.572-.116 7.107 0 7.596.022 7.337-.705 8.096-.607.634-1.116.83-2.026.783-.676-.035-.871-.095-1.322-.406a3.388 3.388 0 01-.839-.894l-.31-.527-.034-7.551c-.022-4.938.008-7.917.088-8.608.527-4.588 4.268-8.692 9.231-10.13 1.479-.428 2.057-.494 3.915-.448 1.342.033 1.88.097 2.685.317z"
          clipRule="evenodd"
        ></path>
        <rect
          width="8.961"
          height="11.841"
          x="139.406"
          y="155.998"
          fill={colors.primary[100]}
          stroke={colors.primary[100]}
          strokeWidth="4"
          rx="4.48"
        ></rect>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          stroke={colors.primary[100]}
          strokeWidth="4"
          d="M214.926 173.148c2.702.738 4.459 1.758 6.376 3.7 1.653 1.674 2.47 3.055 3.115 5.269.22.751.23 1.119.268 8.973.038 8.134.037 8.194-.198 8.74-.438 1.019-1.306 1.583-2.433 1.583-.81 0-1.34-.224-1.893-.801-.727-.759-.705-.495-.705-8.135 0-3.812-.03-7.01-.067-7.107-.04-.103-.199-.177-.38-.177s-.34.074-.38.177c-.037.097-.068 3.248-.068 7.001v6.824l-.838 10.534c-.461 5.793-.887 10.829-.947 11.189-.257 1.54-1.437 2.684-3.088 2.992-.963.18-3.762.068-4.443-.178-.728-.262-1.679-1.154-2.036-1.909-.279-.591-.314-.933-1.179-11.708l-.892-11.099v-6.735c0-3.704-.031-6.814-.068-6.911-.095-.248-.572-.221-.711.039-.077.143-.116 2.572-.116 7.107 0 7.596.022 7.337-.705 8.096-.607.634-1.116.83-2.026.783-.676-.035-.871-.095-1.322-.406a3.388 3.388 0 01-.839-.894l-.31-.527-.034-7.551c-.022-4.938.008-7.917.088-8.608.527-4.588 4.268-8.692 9.231-10.13 1.479-.428 2.057-.494 3.915-.448 1.342.033 1.88.097 2.685.317z"
          clipRule="evenodd"
        ></path>
        <rect
          width="8.961"
          height="11.841"
          x="207.406"
          y="155.998"
          fill={colors.primary[100]}
          stroke={colors.primary[100]}
          strokeWidth="4"
          rx="4.48"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M180.651 180.411c2.316.633 3.822 1.507 5.465 3.172 1.417 1.435 2.117 2.619 2.67 4.516.188.644.198.959.229 7.691.034 6.972.032 7.024-.169 7.492-.376.873-1.119 1.357-2.085 1.357-.694 0-1.149-.192-1.623-.687-.623-.65-.604-.425-.604-6.973 0-3.267-.026-6.008-.058-6.092-.034-.088-.17-.151-.325-.151-.156 0-.292.063-.326.151-.032.084-.058 2.784-.058 6.001v5.849l-.718 9.029c-.396 4.966-.761 9.282-.813 9.591-.219 1.32-1.232 2.301-2.646 2.565-.826.154-3.225.058-3.809-.153-.623-.225-1.438-.989-1.745-1.636-.239-.506-.269-.8-1.011-10.035l-.764-9.514v-5.773c0-3.174-.026-5.84-.058-5.924-.082-.212-.49-.189-.61.034-.065.122-.099 2.204-.099 6.092 0 6.511.019 6.289-.604 6.939-.521.543-.957.712-1.737.671-.579-.03-.746-.081-1.133-.348a2.886 2.886 0 01-.719-.766l-.266-.452-.029-6.472c-.019-4.232.007-6.786.075-7.379.453-3.932 3.659-7.449 7.913-8.682 1.268-.367 1.763-.424 3.356-.384 1.15.028 1.61.083 2.301.271z"
          clipRule="evenodd"
        ></path>
        <rect
          width="7.109"
          height="9.578"
          x="174.494"
          y="165.998"
          fill={colors.primary[500]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="3.555"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationHolderTypeLivingWithParents;
