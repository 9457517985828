import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMotorCollector: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_4345_10124"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4345_10124)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M230.71 151.812a1.789 1.789 0 012.111 1.393l7.601 37.113a1.788 1.788 0 11-3.504.717l-7.601-37.112a1.787 1.787 0 011.393-2.111zM260.227 153.754a1.787 1.787 0 012.399.8l14.309 28.616a1.79 1.79 0 01-3.2 1.6l-14.308-28.617a1.787 1.787 0 01.8-2.399z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M169.811 195.595c0 16.792-13.613 30.405-30.406 30.405C122.613 226 109 212.387 109 195.595c0-16.793 13.613-30.406 30.405-30.406 16.793 0 30.406 13.613 30.406 30.406z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M139.405 222.423c14.817 0 26.828-12.011 26.828-26.828s-12.011-26.828-26.828-26.828-26.828 12.011-26.828 26.828 12.011 26.828 26.828 26.828zm0 3.577c16.793 0 30.406-13.613 30.406-30.405 0-16.793-13.613-30.406-30.406-30.406-16.792 0-30.405 13.613-30.405 30.406C109 212.387 122.613 226 139.405 226z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M155.78 195.595c0 9.042-7.33 16.372-16.372 16.372s-16.372-7.33-16.372-16.372 7.33-16.372 16.372-16.372 16.372 7.33 16.372 16.372z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M139.408 208.39c7.066 0 12.795-5.729 12.795-12.795 0-7.067-5.729-12.795-12.795-12.795-7.067 0-12.795 5.728-12.795 12.795 0 7.066 5.728 12.795 12.795 12.795zm0 3.577c9.042 0 16.372-7.33 16.372-16.372s-7.33-16.372-16.372-16.372-16.372 7.33-16.372 16.372 7.33 16.372 16.372 16.372z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M312.002 195.595c0 16.792-13.613 30.405-30.405 30.405-16.793 0-30.406-13.613-30.406-30.405 0-16.793 13.613-30.406 30.406-30.406 16.792 0 30.405 13.613 30.405 30.406z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M281.597 222.423c14.816 0 26.828-12.011 26.828-26.828s-12.012-26.828-26.828-26.828c-14.817 0-26.829 12.011-26.829 26.828s12.012 26.828 26.829 26.828zm0 3.577c16.792 0 30.405-13.613 30.405-30.405 0-16.793-13.613-30.406-30.405-30.406-16.793 0-30.406 13.613-30.406 30.406 0 16.792 13.613 30.405 30.406 30.405z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M297.972 195.595c0 9.042-7.33 16.372-16.372 16.372s-16.372-7.33-16.372-16.372 7.33-16.372 16.372-16.372 16.372 7.33 16.372 16.372z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M281.6 208.39c7.066 0 12.795-5.729 12.795-12.795 0-7.067-5.729-12.795-12.795-12.795-7.067 0-12.795 5.728-12.795 12.795 0 7.066 5.728 12.795 12.795 12.795zm0 3.577c9.042 0 16.372-7.33 16.372-16.372s-7.33-16.372-16.372-16.372-16.372 7.33-16.372 16.372 7.33 16.372 16.372 16.372zM167.96 141.698a1.788 1.788 0 01.751 2.415l-27.722 52.762a1.788 1.788 0 01-3.167-1.664l27.723-52.762a1.788 1.788 0 012.415-.751z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M137.617 158.037c0-.988.8-1.789 1.788-1.789 9.716 0 23.315 3.68 31.374 14.109a1.788 1.788 0 11-2.831 2.187c-7.144-9.245-19.479-12.719-28.543-12.719a1.788 1.788 0 01-1.788-1.788zM249.905 154.396c-6.089.327-12.497-1.408-19.598-6.392a1.788 1.788 0 00-2.055 2.928c7.655 5.373 14.84 7.412 21.844 7.036 6.955-.373 13.54-3.118 20.031-6.961a1.788 1.788 0 00-1.822-3.078c-6.257 3.704-12.263 6.138-18.4 6.467z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M240.43 190.976c5.167-30.576 33.538-42.769 56.297-32.41a1.79 1.79 0 001.482-3.256c-24.874-11.321-55.743 2.152-61.307 35.07a1.788 1.788 0 003.528.596z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M155.505 136.574c3.13 0 6.707 1.789 12.52 6.707-5.366 4.919-8.048 7.154-12.52 7.154-2.683 1.342-3.13-13.861 0-13.861z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M153.02 136.216c.452-.663 1.263-1.431 2.485-1.431 3.831 0 7.847 2.199 13.675 7.13a1.787 1.787 0 01.054 2.684c-5.167 4.736-8.326 7.511-13.413 7.621-.38.104-.798.123-1.228.015a2.66 2.66 0 01-1.306-.803c-.541-.592-.86-1.392-1.067-2.084-.436-1.456-.654-3.412-.683-5.313-.029-1.908.128-3.937.537-5.547.201-.792.494-1.611.946-2.272zm2.734 12.428c3.166-.068 5.27-1.463 9.54-5.298-4.533-3.632-7.263-4.845-9.403-4.972-.103.197-.229.519-.35.994-.304 1.198-.454 2.887-.427 4.612.026 1.732.228 3.325.533 4.342.038.127.074.233.107.322z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M168.917 147.833c0-10.823 10.026-18.86 20.59-16.505l35.488 7.913a12.907 12.907 0 0110.098 12.597c0 7.128-5.778 12.906-12.906 12.906h-36.36c-9.339 0-16.91-7.571-16.91-16.911z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M224.217 142.733l-35.488-7.913c-8.33-1.858-16.235 4.479-16.235 13.013 0 7.364 5.969 13.334 13.333 13.334h36.36a9.33 9.33 0 002.03-18.434zm-34.71-11.405c-10.564-2.355-20.59 5.682-20.59 16.505 0 9.34 7.571 16.911 16.91 16.911h36.36c7.128 0 12.906-5.778 12.906-12.906a12.907 12.907 0 00-10.098-12.597l-35.488-7.913z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M237.775 181.124c0-8.693-8.546-14.81-16.774-12.007l-20.934 7.133a9.02 9.02 0 002.909 17.557h22.116c7.005 0 12.683-5.678 12.683-12.683z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M201.221 179.636l20.934-7.133c5.907-2.012 12.043 2.379 12.043 8.621a9.106 9.106 0 01-9.106 9.106h-22.116a5.442 5.442 0 01-1.755-10.594zm19.78-10.519c8.228-2.803 16.774 3.314 16.774 12.007 0 7.005-5.678 12.683-12.683 12.683h-22.116a9.02 9.02 0 01-2.909-17.557l20.934-7.133z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M194.706 171.639a1.79 1.79 0 01-.94 2.349l-7.714 3.306c2.293 5.075 6.148 13.602 7.744 17.114.987 2.172 2.068 3.151 3.116 3.655 1.118.539 2.44.662 4.198.662h39.795v3.577H201.11c-1.819 0-3.85-.101-5.75-1.016-1.971-.948-3.573-2.652-4.821-5.398-1.789-3.937-6.411-14.164-8.497-18.784a1.787 1.787 0 01.925-2.38l9.39-4.024a1.789 1.789 0 012.349.939zM191.145 119.812a1.789 1.789 0 01-.996 2.325c-2.155.862-7.96 3.499-13.578 7.01-2.424 1.516-4.171 4.12-5.357 6.945-1.18 2.809-1.721 5.64-1.856 7.331a1.788 1.788 0 01-3.566-.285c.163-2.035.784-5.241 2.123-8.431 1.333-3.174 3.461-6.531 6.76-8.593 5.829-3.643 11.829-6.372 14.145-7.298a1.789 1.789 0 012.325.996zM161.099 143.041a1.999 1.999 0 012.36 1.558l8.5 41.5a2 2 0 01-3.918.803l-8.5-41.5a2 2 0 011.558-2.361z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M194.106 145.212a2 2 0 012.683.894l16 32a2 2 0 01-3.578 1.789l-16-32a2 2 0 01.895-2.683z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M93 192c0 18.778-15.222 34-34 34s-34-15.222-34-34 15.222-34 34-34 34 15.222 34 34z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M59 222c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30zm0 4c18.778 0 34-15.222 34-34s-15.222-34-34-34-34 15.222-34 34 15.222 34 34 34z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M77.308 192.001c0 10.111-8.197 18.308-18.308 18.308s-18.308-8.197-18.308-18.308S48.89 173.693 59 173.693c10.11 0 18.308 8.197 18.308 18.308z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M59 206.309c7.902 0 14.308-6.406 14.308-14.308S66.901 177.693 59 177.693s-14.308 6.406-14.308 14.308S51.098 206.309 59 206.309zm0 4c10.11 0 18.308-8.197 18.308-18.308S69.11 173.693 59 173.693s-18.308 8.197-18.308 18.308S48.89 210.309 59 210.309z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M252 192c0 18.778-15.222 34-34 34s-34-15.222-34-34 15.222-34 34-34 34 15.222 34 34z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M218 222c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30zm0 4c18.778 0 34-15.222 34-34s-15.222-34-34-34-34 15.222-34 34 15.222 34 34 34z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M236.308 192.001c0 10.111-8.197 18.308-18.308 18.308s-18.308-8.197-18.308-18.308 8.197-18.308 18.308-18.308 18.308 8.197 18.308 18.308z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M218 206.309c7.902 0 14.308-6.406 14.308-14.308s-6.406-14.308-14.308-14.308-14.308 6.406-14.308 14.308 6.406 14.308 14.308 14.308zm0 4c10.111 0 18.308-8.197 18.308-18.308s-8.197-18.308-18.308-18.308-18.308 8.197-18.308 18.308 8.197 18.308 18.308 18.308zM90.93 131.73a2 2 0 01.84 2.701l-31 59a2 2 0 01-3.54-1.861l31-59a2 2 0 012.7-.84z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M57 150a2 2 0 012-2c10.864 0 26.071 4.115 35.083 15.777a2 2 0 01-3.166 2.446C82.93 155.885 69.136 152 59 152a2 2 0 01-2-2zM182.563 145.929c-6.808.365-13.974-1.574-21.914-7.147a1.999 1.999 0 10-2.298 3.274c8.56 6.008 16.595 8.287 24.426 7.867 7.777-.417 15.141-3.486 22.4-7.784a2 2 0 00-2.038-3.442c-6.997 4.142-13.713 6.864-20.576 7.232z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M171.972 186.835c5.779-34.191 37.503-47.825 62.953-36.242a2 2 0 101.657-3.64c-27.814-12.66-62.333 2.406-68.554 39.215a2 2 0 103.944.667z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M77 126c3.5 0 7.5 2 14 7.5-6 5.5-9 8-14 8-3 1.5-3.5-15.5 0-15.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M74.22 125.6c.507-.741 1.414-1.6 2.78-1.6 4.284 0 8.775 2.459 15.292 7.973a2 2 0 01.06 3.001c-5.778 5.297-9.31 8.399-14.998 8.522a2.69 2.69 0 01-1.374.017 2.964 2.964 0 01-1.46-.898c-.605-.662-.962-1.557-1.194-2.33-.487-1.628-.73-3.816-.763-5.942-.033-2.133.143-4.402.6-6.202.225-.886.552-1.801 1.058-2.541zm3.058 13.897c3.541-.076 5.894-1.636 10.668-5.924-5.069-4.062-8.122-5.418-10.515-5.56-.115.221-.256.581-.39 1.112-.34 1.34-.508 3.228-.479 5.157.03 1.936.256 3.718.596 4.855.043.142.083.261.12.36z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M92 138.591c0-12.103 11.212-21.09 23.025-18.456l39.684 8.848A14.432 14.432 0 01166 143.069c0 7.971-6.461 14.432-14.432 14.432H110.91c-10.444 0-18.91-8.466-18.91-18.91z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M153.839 132.887l-39.685-8.848C104.84 121.962 96 129.048 96 138.591c0 8.235 6.675 14.91 14.91 14.91h40.658a10.433 10.433 0 002.271-20.614zm-38.814-12.752C103.212 117.501 92 126.488 92 138.591c0 10.444 8.466 18.91 18.91 18.91h40.658c7.971 0 14.432-6.461 14.432-14.432 0-6.76-4.693-12.615-11.291-14.086l-39.684-8.848z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M169 175.818c0-9.721-9.556-16.561-18.757-13.426l-23.409 7.975a10.087 10.087 0 003.252 19.634h24.731c7.833 0 14.183-6.35 14.183-14.183z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M128.124 174.154l23.409-7.976c6.606-2.25 13.467 2.661 13.467 9.64 0 5.624-4.559 10.183-10.183 10.183h-24.731a6.086 6.086 0 01-1.962-11.847zm22.119-11.762c9.201-3.135 18.757 3.705 18.757 13.426 0 7.833-6.35 14.183-14.183 14.183h-24.731a10.087 10.087 0 01-3.252-19.634l23.409-7.975z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M120.838 165.213a2 2 0 01-1.05 2.626l-8.626 3.697c2.564 5.675 6.874 15.21 8.659 19.137 1.104 2.429 2.313 3.524 3.484 4.088 1.251.602 2.729.74 4.695.74h44.5v4H128c-2.034 0-4.306-.113-6.43-1.136-2.204-1.061-3.995-2.966-5.391-6.037-2.001-4.403-7.169-15.838-9.502-21.004a1.999 1.999 0 011.035-2.662l10.5-4.5a2 2 0 012.626 1.051zM116.857 107.258a2 2 0 01-1.114 2.599c-2.41.964-8.9 3.913-15.183 7.84-2.71 1.694-4.665 4.606-5.99 7.765-1.32 3.142-1.925 6.307-2.076 8.198a2 2 0 01-3.987-.319c.181-2.276.876-5.86 2.374-9.427 1.49-3.55 3.87-7.304 7.56-9.609 6.517-4.074 13.226-7.125 15.816-8.161a1.999 1.999 0 012.6 1.114z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M201.099 143.041a1.999 1.999 0 012.36 1.558l8.5 41.5a2 2 0 01-3.918.803l-8.5-41.5a2 2 0 011.558-2.361zM234.106 145.212a2 2 0 012.683.894l16 32a2 2 0 01-3.578 1.789l-16-32a2 2 0 01.895-2.683z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M133 192c0 18.778-15.222 34-34 34s-34-15.222-34-34 15.222-34 34-34 34 15.222 34 34z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M99 222c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30zm0 4c18.778 0 34-15.222 34-34s-15.222-34-34-34-34 15.222-34 34 15.222 34 34 34z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M117.308 192.001c0 10.111-8.197 18.308-18.308 18.308s-18.308-8.197-18.308-18.308S88.89 173.693 99 173.693s18.308 8.197 18.308 18.308z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M99 206.309c7.902 0 14.308-6.406 14.308-14.308S106.902 177.693 99 177.693s-14.308 6.406-14.308 14.308S91.098 206.309 99 206.309zm0 4c10.111 0 18.308-8.197 18.308-18.308S109.111 173.693 99 173.693s-18.308 8.197-18.308 18.308S88.89 210.309 99 210.309z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M292 192c0 18.778-15.222 34-34 34s-34-15.222-34-34 15.222-34 34-34 34 15.222 34 34z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M258 222c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30zm0 4c18.778 0 34-15.222 34-34s-15.222-34-34-34-34 15.222-34 34 15.222 34 34 34z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M276.308 192.001c0 10.111-8.197 18.308-18.308 18.308s-18.308-8.197-18.308-18.308 8.197-18.308 18.308-18.308 18.308 8.197 18.308 18.308z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M258 206.309c7.902 0 14.308-6.406 14.308-14.308s-6.406-14.308-14.308-14.308-14.308 6.406-14.308 14.308 6.406 14.308 14.308 14.308zm0 4c10.111 0 18.308-8.197 18.308-18.308s-8.197-18.308-18.308-18.308-18.308 8.197-18.308 18.308 8.197 18.308 18.308 18.308zM130.93 131.73a2 2 0 01.84 2.701l-31 59a2 2 0 11-3.54-1.861l30.999-59a2 2 0 012.701-.84z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M97 150a2 2 0 012-2c10.864 0 26.071 4.115 35.083 15.777a2.001 2.001 0 01-3.166 2.446C122.929 155.885 109.136 152 99 152a2 2 0 01-2-2zM222.563 145.929c-6.808.365-13.974-1.574-21.914-7.147a1.999 1.999 0 10-2.298 3.274c8.56 6.008 16.595 8.287 24.426 7.867 7.777-.417 15.141-3.486 22.4-7.784a2 2 0 00-2.038-3.442c-6.997 4.142-13.713 6.864-20.576 7.232z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M211.972 186.835c5.779-34.191 37.503-47.825 62.953-36.242a2 2 0 101.657-3.64c-27.814-12.66-62.333 2.406-68.554 39.215a2 2 0 103.944.667z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M117 126c3.5 0 7.5 2 14 7.5-6 5.5-9 8-14 8-3 1.5-3.5-15.5 0-15.5z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M114.221 125.6c.506-.741 1.413-1.6 2.779-1.6 4.284 0 8.775 2.459 15.292 7.973a2.001 2.001 0 01.06 3.001c-5.778 5.297-9.31 8.399-14.998 8.522a2.69 2.69 0 01-1.374.017c-.658-.165-1.137-.545-1.46-.898-.605-.662-.962-1.557-1.194-2.33-.487-1.628-.73-3.816-.763-5.942-.033-2.133.143-4.402.6-6.202.225-.886.552-1.801 1.058-2.541zm3.057 13.897c3.541-.076 5.894-1.636 10.668-5.924-5.069-4.062-8.122-5.418-10.515-5.56-.115.221-.256.581-.391 1.112-.34 1.34-.507 3.228-.478 5.157.03 1.936.256 3.718.596 4.855.043.142.083.261.12.36z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M132 138.591c0-12.103 11.212-21.09 23.025-18.456l39.684 8.848A14.432 14.432 0 01206 143.069c0 7.971-6.461 14.432-14.432 14.432H150.91c-10.444 0-18.91-8.466-18.91-18.91z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M193.839 132.887l-39.685-8.848C144.84 121.962 136 129.048 136 138.591c0 8.235 6.675 14.91 14.91 14.91h40.658a10.433 10.433 0 002.271-20.614zm-38.814-12.752C143.212 117.501 132 126.488 132 138.591c0 10.444 8.466 18.91 18.91 18.91h40.658c7.971 0 14.432-6.461 14.432-14.432 0-6.76-4.693-12.615-11.291-14.086l-39.684-8.848z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M209 175.818c0-9.721-9.556-16.561-18.757-13.426l-23.409 7.975a10.087 10.087 0 003.252 19.634h24.731c7.833 0 14.183-6.35 14.183-14.183z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M168.124 174.154l23.409-7.976c6.606-2.25 13.467 2.661 13.467 9.64 0 5.624-4.559 10.183-10.183 10.183h-24.731a6.086 6.086 0 01-1.962-11.847zm22.119-11.762c9.201-3.135 18.757 3.705 18.757 13.426 0 7.833-6.35 14.183-14.183 14.183h-24.731a10.087 10.087 0 01-3.252-19.634l23.409-7.975z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M160.838 165.213a2 2 0 01-1.05 2.626l-8.626 3.697c2.564 5.675 6.874 15.21 8.659 19.137 1.104 2.429 2.313 3.524 3.484 4.088 1.251.602 2.729.74 4.695.74h44.5v4H168c-2.034 0-4.306-.113-6.43-1.136-2.204-1.061-3.995-2.966-5.391-6.037-2.001-4.403-7.169-15.838-9.502-21.004a1.999 1.999 0 011.035-2.662l10.5-4.5a2 2 0 012.626 1.051zM156.857 107.258a2 2 0 01-1.114 2.599c-2.41.964-8.9 3.913-15.183 7.84-2.711 1.694-4.664 4.606-5.991 7.765-1.319 3.142-1.924 6.307-2.075 8.198a2 2 0 01-3.988-.319c.183-2.276.877-5.86 2.375-9.427 1.49-3.55 3.87-7.304 7.559-9.609 6.518-4.074 13.227-7.125 15.817-8.161a1.999 1.999 0 012.6 1.114z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationMotorCollector;
