import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import {
  Link,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
} from '@wegroup/design-system';
import SearchInput from '../../SearchPickerInputField';
import SearchPickerList from '../../SearchPickerList/SearchPickerList';

interface Option {
  label: string;
  value: string;
  icon?: React.FC<React.PropsWithChildren<{ color: string }>>;
}

interface Category {
  label: string;
  option_keys: string[];
}
export interface SearchPickerProps {
  isOpen: boolean;
  placeholder?: string;
  onClose: (selectedOptions: Option[]) => void;
  options?: Option[];
  categories?: Category[];
  searchValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedOptions: Option[];
  setSelectedOptions: Dispatch<SetStateAction<Option[]>>;
  extraActionLabel?: string;
  extraActionOnClick?: () => void;
  noResultsText?: string;
  hideIcons?: boolean;
  isDisabled?: boolean;
}

const SearchPicker: React.FC<React.PropsWithChildren<SearchPickerProps>> = ({
  placeholder,
  isOpen,
  onClose,
  options = [],
  categories = [],
  searchValue,
  onChange,
  selectedOptions,
  setSelectedOptions,
  extraActionLabel,
  extraActionOnClick,
  noResultsText,
  hideIcons,
  isDisabled,
}) => {
  return (
    <Modal onClose={() => onClose(selectedOptions)} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: '0', tabletS: 'md' }}
        overflow="hidden"
        h={{ base: '100%', tabletS: 'unset' }}
        m={{ base: '0', tabletS: '10' }}
      >
        <SearchInput
          onClose={() => onClose(selectedOptions)}
          value={searchValue}
          selectedOptions={selectedOptions}
          onChangeSelected={(option) =>
            setSelectedOptions((prev) =>
              prev.filter((o) => o.value !== option.value),
            )
          }
          onChange={onChange}
          placeholder={placeholder}
          isDisabled={isDisabled}
        />
        <SearchPickerList
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          options={options}
          categories={categories}
          noResultsText={noResultsText}
          hideIcons={hideIcons}
          isDisabled={isDisabled}
        />
        {extraActionLabel && (
          <Box p="4" borderTop="1px" borderColor="gray.strokeAndBg">
            <Link variant="gray" fontSize="sm" onClick={extraActionOnClick}>
              {extraActionLabel}
            </Link>
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SearchPicker;
