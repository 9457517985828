import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersQR: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path fill="currentColor" d="M22 10H28V16H22z"></path>
      <path fill="currentColor" d="M28 10H34V16H28z"></path>
      <path fill="currentColor" d="M10 10H16V16H10z"></path>
      <path fill="currentColor" d="M10 22H16V28H10z"></path>
      <path fill="currentColor" d="M16 16H22V22H16z"></path>
      <path fill="currentColor" d="M28 28H34V34H28z"></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 22h18v18H22V22zm3.177 3.177v11.647h11.647V25.177H25.177z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10 3h4v4h-4a3 3 0 00-3 3v4H3v-4a7 7 0 017-7zM3 36h4v4a3 3 0 003 3h4v4h-4a7 7 0 01-7-7v-4zm33 7v4h4a7 7 0 007-7v-4h-4v4a3 3 0 01-3 3h-4zm7-29v-4a3 3 0 00-3-3h-4V3h4a7 7 0 017 7v4h-4z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersQR;
