import React, { PropsWithChildren, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { getI18nLanguageCode } from '../../../../../i18n';
import GlobalStyle from '../../../../../resetStyling';
import FavIcons from '../../../../common/src/components/FavIcons';
import SessionRecordingUrOverlay from '../../components/SessionRecordingUrOverlay';
import { isSocialLoginUrl } from '../../features/authentication/constants/SocialAuth';
import {
  AuthProvider,
  ReduxProviders,
  LogrocketProvider,
  ThemeProvider,
} from '../function-providers';
import useFeatureFlags from '../../hooks/useFeatureFlags';
import {
  isStaging,
  useGlobalSelector,
} from '../../../../common/src/utils/globalVariables';
import ReactQueryDevTools from '../../../../../ReactQueryDevTools';
import { BrowserRouter } from 'react-router-dom';
import LanguageProvider from '../function-providers/LanguageProvider';
import LoadingScreen from '../../../../common/src/components/LoadingScreen';
import { Box } from '@wegroup/design-system';
import { use100vh } from 'react-div-100vh';
import VersionCheck from '../../components/VersionCheck';
import useNewVersionCheck from '../../hooks/useNewVersionCheck';
import CaptchaProvider from './CaptchaProvider';
import PostHogProvider from './PostHogProvider';

const MainProvider: React.FC<PropsWithChildren> = ({ children }) => {
  // Any older routes with a hashtag will be corretly redirected
  if (window.location.href.split('/')[3] === '#') {
    // Replace will always replace the first occurence of the string, so this will work as aspected for any URI
    const routeWithoutHash = window.location.href.replace('/#/', '/');
    window.location.replace(routeWithoutHash);
    return null;
  }

  useEffect(() => {
    window.addEventListener('storage', checkIfVersionChanged);
    return () => {
      window.removeEventListener('storage', checkIfVersionChanged);
    };
  }, []);

  /**
   * This will check for each tab if the version change was triggered
   */
  const checkIfVersionChanged = (e: StorageEvent) => {
    if (e.key === 'version-change') {
      window.location.reload();
    }
  };

  return (
    <VersionCheck>
      <BrowserRouter>
        <ReduxProviders>
          <ThemeProvider>
            <InnerMainProvider>{children}</InnerMainProvider>
          </ThemeProvider>
        </ReduxProviders>
      </BrowserRouter>
    </VersionCheck>
  );
};

const InnerMainProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { showLogRocketSessionURL } = useFeatureFlags();
  const isLoadingApp = useGlobalSelector(
    (state) => state.generalData.isLoadingApp,
  );

  const height = use100vh() || '100vh';

  useNewVersionCheck(isLoadingApp);

  return (
    <Box pos="relative" w="100%" h={height}>
      {isLoadingApp && (
        <Box
          pos="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          bg="white"
          zIndex="overlay"
        >
          <LoadingScreen />
        </Box>
      )}

      <AuthProvider>
        <PostHogProvider>
          <CaptchaProvider>
            <LogrocketProvider>
              <LanguageProvider>
                <Helmet>
                  <html lang={getI18nLanguageCode()} translate="no" />
                  <meta name="google" content="notranslate" />
                </Helmet>
                {children}
                {!isSocialLoginUrl && <FavIcons />}
                <GlobalStyle />
                {showLogRocketSessionURL && (
                  <SessionRecordingUrOverlay bottom="0" right="2" />
                )}
                {isStaging && <ReactQueryDevTools />}
              </LanguageProvider>
            </LogrocketProvider>
          </CaptchaProvider>
        </PostHogProvider>
      </AuthProvider>
    </Box>
  );
};

export default MainProvider;
