import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersScan: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.998 25a4 4 0 118 0 4 4 0 01-8 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.441 13.898a12 12 0 0112.98 19.646 2 2 0 01-2.808-2.848A8 8 0 1024.997 33a2 2 0 010 4 12 12 0 01-4.556-23.102z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.648 6.827A20 20 0 0140.18 38.022a2 2 0 11-3.036-2.604A16 16 0 1024.998 41a2 2 0 010 4 20 20 0 01-8.35-38.173z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.998 23a2 2 0 012 2v18a2 2 0 11-4 0V25a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersScan;
