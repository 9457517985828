import { createContext } from 'react';
import { ReactReduxContextValue } from 'react-redux';
import rootReducer from '../redux/reducers/rootReducer';
import companyScanStore from '../redux/store';

const CompanyScanContext = createContext<ReactReduxContextValue>({
  store: companyScanStore,
  storeState: rootReducer,
});
export default CompanyScanContext;
