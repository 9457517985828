import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyOther: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25 30.99a2.825 2.825 0 002.83-2.82A2.825 2.825 0 0025 25.35a2.825 2.825 0 00-2.83 2.82A2.825 2.825 0 0025 30.99zM34.21 30.99a2.825 2.825 0 002.83-2.82 2.825 2.825 0 00-2.83-2.82 2.825 2.825 0 00-2.83 2.82 2.825 2.825 0 002.83 2.82zM15.79 30.99a2.825 2.825 0 002.83-2.82 2.825 2.825 0 00-2.83-2.82 2.825 2.825 0 00-2.83 2.82 2.825 2.825 0 002.83 2.82z"
    ></path>
    <path
      fill="currentColor"
      d="M42.47 17.36l-4-2.64V8.24a1.12 1.12 0 00-1.12-1.12h-5.6a1.12 1.12 0 00-1.12 1.12v1.25l-5-3.29a1.18 1.18 0 00-1.32 0L7.53 17.36a1.19 1.19 0 00-.53 1V42.8A1.2 1.2 0 008.2 44h33.6a1.2 1.2 0 001.2-1.2V18.35a1.189 1.189 0 00-.53-.99zm-9.91-4.08zm7.19 27.48h-29.5V19.52L25 9.83l4.12 2.73 2.33 1.53a1.57 1.57 0 002.43-1.31v-2.43h1.37v5.44A1.52 1.52 0 0036 17.1l3.79 2.43-.04 21.23z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyOther;
