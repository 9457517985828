import { QuoteQuestionAnswer } from '../../../../types/QuoteQuestion';
import { getTextualRepresenation } from '../hooks/useTextualRepresentation';
import { FlowsQuestion } from '../types/FlowsTypes';
import { isIndexEqual } from './flowsQuestionsUtils';

interface Props {
  answers: QuoteQuestionAnswer[];
  question: string;
  index?: number;
  toDisplayIndex?: number;
  questions?: FlowsQuestion[];
  bold?: boolean;
}

/**
 * This function replaces strings that have contain a '{...}' like
 * structure. It uses the answers to fill these missing variables.
 * @param answers The answers that the assistant should look for
 * @param advQuestion The question that requires parsing
 */
export const parseStringForAnswers = ({
  answers,
  question,
  index,
  toDisplayIndex,
  questions,
  bold,
}: Props): string => {
  // This regex matches all the string that need interpolation
  if (!question) return '';
  const variables = question
    .match(new RegExp(/{{1,2}[a-z0-9_.]+}{1,2}/, 'gi'))
    ?.map((regex) => regex.replace(/[{}]+/g, ''));
  if (variables?.length === 0) return question;

  // Creates a collection of all the to be interpolated strings with their values
  const interpolatedStrings = Object.assign(
    {},
    ...(variables || []).map((interpolString) => {
      // Matches the answerId (first part) and the possible object properties (second part)
      const matches = interpolString.match(/[a-z0-9_]+\.{0,1}[a-z0-9_]+/gi);
      const answerId = matches?.[0];
      const objProperties = interpolString
        .replace(matches?.[0] || '', '')
        ?.split('.')
        .slice(1);

      let answer = answers.find(
        ({ id, index: answerIndex }) =>
          id === answerId &&
          (isIndexEqual(index, answerIndex) || answerIndex == null),
      )?.answer;

      if (index != null && interpolString === 'index') {
        return {
          [interpolString]: (
            (toDisplayIndex != null ? toDisplayIndex : index) + 1
          )?.toString(),
        };
      }

      if (!answer) return { [interpolString]: '-' };

      // If not equal, it needs to look inside the answer object
      if (interpolString !== answerId) {
        objProperties?.forEach(
          (prop) => (answer = (answer as { [x: string]: unknown })?.[prop]),
        );
      }

      if (typeof answer === 'object' && questions) {
        const question = questions.find(({ id }) => id === interpolString);
        if (question) answer = getTextualRepresenation({ question, answer });
      }

      return {
        [interpolString]: answer,
      };
    }),
  );

  let interpolatedAdvQuestion = question;

  Object.entries(interpolatedStrings).forEach(([interpolString, answer]) => {
    interpolatedAdvQuestion = interpolatedAdvQuestion.replace(
      new RegExp(`{{1,2}${interpolString}}{1,2}`, 'g'),
      answer == null
        ? ''
        : bold
        ? `<strong>${answer as string}</strong>`
        : (answer as string),
    );
  });

  return interpolatedAdvQuestion.replace(' .', '.');
};
