import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsHospital: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 43a2 2 0 012-2h36a2 2 0 110 4H7a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 9a2 2 0 00-2 2v32a2 2 0 11-4 0V11a6 6 0 016-6h20a6 6 0 016 6v32a2 2 0 11-4 0V11a2 2 0 00-2-2H15z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 33a2 2 0 00-2 2v8a2 2 0 11-4 0v-8a6 6 0 016-6h4a6 6 0 016 6v8a2 2 0 11-4 0v-8a2 2 0 00-2-2h-4zM19 19a2 2 0 012-2h8a2 2 0 110 4h-8a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 13a2 2 0 012 2v8a2 2 0 11-4 0v-8a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsHospital;
