const oceanBlue = {
  primary: {
    main: '#0077AA',
    50: '#D5F3FF',
    100: '#B4E9FF',
    150: '#93DFFF',
    200: '#72D5FF',
    300: '#30C1FF',
    400: '#00A5EC',
    500: '#0077AA',
    600: '#00628B',
    700: '#004C6D',
    750: '#00415D',
    800: '#00374E',
    850: '#002C3F',
    900: '#002130',
  },
  secondary: {
    main: '#A5C6D5',
    50: '#F3F8FA',
    100: '#EBF2F5',
    150: '#E2EDF1',
    200: '#D9E7ED',
    300: '#C8DCE5',
    400: '#B6D1DD',
    500: '#A5C6D5',
    600: '#74A7BE',
    700: '#4B86A0',
    750: '#3F7188',
    800: '#345D70',
    850: '#294957',
    900: '#1D353F',
  },
};

export default oceanBlue;
