import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersStarFilled: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M25 9.784L29.944 19.8 41 21.416l-8 7.792 1.888 11.008-9.888-5.2-9.888 5.2L17 29.208l-8-7.792L20.056 19.8 25 9.784z"
    ></path>
  </Icon>
);

export default GeneralOthersStarFilled;
