import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCarCollector: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3884_11354"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3884_11354)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <g stroke={colors.primary[500]} strokeWidth="4" opacity="0.15">
          <path
            fill="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M61.58 117.377a5.13 5.13 0 015.125 5.128v17.936H56.453v-17.936a5.128 5.128 0 015.128-5.128z"
          ></path>
          <circle
            cx="56.997"
            cy="191.5"
            r="33.5"
            fill={colors.primary[100]}
          ></circle>
          <circle cx="56.997" cy="191.5" r="21.5" fill="#fff"></circle>
          <circle
            cx="296.997"
            cy="191.5"
            r="33.5"
            fill={colors.primary[100]}
          ></circle>
          <circle cx="296.997" cy="191.5" r="21.5" fill="#fff"></circle>
          <path
            fill={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M133.329 184.005h103.968a25.353 25.353 0 0012.808-3.698 25.348 25.348 0 009.164-9.682 43.445 43.445 0 0170.604-7.836 5.128 5.128 0 007.095.278 5.124 5.124 0 00.561-7.078 53.705 53.705 0 00-70.027-9.051 53.69 53.69 0 00-17.205 18.739 14.89 14.89 0 01-13 8.092H133.329c-11.66 0-20.444-7.248-30.608-15.64-11.488-9.484-24.508-20.236-43.704-20.236a65.406 65.406 0 00-38.8 13.72 5.123 5.123 0 00-1.312 7.128 5.126 5.126 0 007.128 1.312A55.634 55.634 0 0159 148.145c15.512 0 26.036 8.688 37.176 17.888 10.72 8.828 21.804 17.972 37.152 17.972z"
          ></path>
          <path
            fill="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M297.329 137.876a53.807 53.807 0 00-47.052 27.784 14.89 14.89 0 01-13 8.092H133.329c-11.66 0-20.444-7.248-30.608-15.64-9.88-8.156-20.92-17.2-36-19.6v-16h61.204a15.659 15.659 0 0015.656-15.656V68.5c-.065-1.544 2.777-7.755 7.688-7.496h143.5a10.249 10.249 0 0110.248 10.244v67.256a53.498 53.498 0 00-7.688-.628z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M251.205 122.504v15.512a13.275 13.275 0 01-3.284 8.736l-10.688 12.22a13.263 13.263 0 01-4.51 3.348 13.257 13.257 0 01-5.49 1.184h-24.716v-41h48.688z"
          ></path>
          <path
            fill={colors.primary[200]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M251.205 71.252h33.292a7.712 7.712 0 017.712 7.712v28.156a15.384 15.384 0 01-15.384 15.384h-25.6l-.02-51.252z"
          ></path>
          <path
            fill={colors.primary[200]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M251.205 71.252h-48.688V122.5h48.688V71.252zM153.829 122.504V78.96a7.71 7.71 0 017.708-7.708h40.98v51.252h-48.688z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M202.517 122.504v41h-40.996a7.687 7.687 0 01-7.11-4.757 7.678 7.678 0 01-.582-2.947v-33.296h48.688zM220.453 132.752h-7.684M192.269 132.752h-7.688"
          ></path>
        </g>
        <path
          fill="#fff"
          d="M8 187.5c0 14.5 11 21 21 21h11.5L66 203h215.5c19 0 35.5 3.5 50 0 11.6-2.8 13.167-9.5 12.5-12.5V139c0-10.4-4.667-15.667-7-17l-37.5-33.5h-103c-28.5 0-30 0-57 18s-41 23.5-51 26S50 141 29 145.5C8 152 8 173 8 187.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M168.026 92.662c-6.978 2.147-13.847 6.456-27.417 15.502-27.104 18.07-41.32 23.7-51.624 26.276-8.205 2.051-28.765 6.439-47.448 10.426-4.155.887-8.217 1.754-12.03 2.571-9.726 3.038-14.535 9.346-16.985 16.695C10.017 171.648 10 180.191 10 187.5c0 6.694 2.515 11.38 6.053 14.421C19.64 205.002 24.407 206.5 29 206.5h11.287l25.5-5.5H281.5c6.686 0 13.112.432 19.172.839 2.611.176 5.154.346 7.621.476 8.276.436 15.784.419 22.738-1.259 5.469-1.32 8.296-3.489 9.706-5.413 1.425-1.945 1.516-3.784 1.311-4.709l-.048-.214V139c0-9.82-4.393-14.35-5.992-15.264l-.183-.104L298.737 90.5H196.5c-14.356 0-21.512.02-28.474 2.162zm28.13-6.162h104.107l37.926 33.88c3.077 1.892 7.811 7.872 7.811 18.62v51.3c.385 2.063-.023 4.958-2.037 7.707-2.107 2.876-5.863 5.457-11.994 6.937-7.546 1.822-15.538 1.805-23.887 1.366-2.58-.136-5.187-.311-7.833-.489-6.001-.404-12.202-.821-18.749-.821H66.213l-25.5 5.5H29c-5.406 0-11.14-1.752-15.553-5.546C8.985 201.12 6 195.306 6 187.5v-.134c0-7.171 0-16.315 2.728-24.498 2.793-8.379 8.464-15.807 19.68-19.279l.086-.026.087-.019c3.86-.827 7.97-1.704 12.17-2.6 18.682-3.987 39.135-8.352 47.264-10.384 9.695-2.424 23.48-7.794 50.376-25.724 13.43-8.954 20.811-13.645 28.458-15.998 7.601-2.338 15.38-2.338 29.307-2.338z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M29 188v20.5h18.5l18-6H103V188c0-16-19-26-27-26H59.5C40.3 162 29 177 29 188z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M59.5 164C41.368 164 31 178.141 31 188v18.5h16.175l18-6H101V188c0-7.154-4.25-13.134-9.733-17.425a36.037 36.037 0 00-8.43-4.911C80.064 164.531 77.645 164 76 164H59.5zM27 188c0-12.141 12.232-28 32.5-28H76c2.355 0 5.311.719 8.35 1.961a40.049 40.049 0 019.383 5.464C99.75 172.134 105 179.154 105 188v16.5H65.825l-18 6H27V188z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M96 200c0 16.569-13.431 30-30 30-16.569 0-30-13.431-30-30 0-16.569 13.431-30 30-30 16.569 0 30 13.431 30 30z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M66 226c14.36 0 26-11.641 26-26s-11.64-26-26-26-26 11.641-26 26 11.64 26 26 26zm0 4c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M83 200c0 9.389-7.611 17-17 17s-17-7.611-17-17 7.611-17 17-17 17 7.611 17 17z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M66 213c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13zm0 4c9.389 0 17-7.611 17-17s-7.611-17-17-17-17 7.611-17 17 7.611 17 17 17z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M318 188v16.5h-18.5l-18-2H244V188c0-16 19-26 27-26h16.5c19.2 0 30.5 15 30.5 26z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M287.5 164c18.132 0 28.5 14.141 28.5 24v14.5h-16.389l-18-2H246V188c0-7.154 4.25-13.134 9.733-17.425a36.02 36.02 0 018.43-4.911c2.773-1.133 5.191-1.664 6.837-1.664h16.5zm32.5 24c0-12.141-12.232-28-32.5-28H271c-2.354 0-5.311.719-8.35 1.961a40.058 40.058 0 00-9.383 5.464C247.25 172.134 242 179.154 242 188v16.5h39.389l18 2H320V188z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M311 200c0 16.569-13.431 30-30 30-16.569 0-30-13.431-30-30 0-16.569 13.431-30 30-30 16.569 0 30 13.431 30 30z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M281 226c14.359 0 26-11.641 26-26s-11.641-26-26-26-26 11.641-26 26 11.641 26 26 26zm0 4c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M298 200c0 9.389-7.611 17-17 17s-17-7.611-17-17 7.611-17 17-17 17 7.611 17 17z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M281 213c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13zm0 4c9.389 0 17-7.611 17-17s-7.611-17-17-17-17 7.611-17 17 7.611 17 17 17z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M136 134.5h170.5c14.5 0 10.4-11.6 6-16-5.5-5.5-11.5-11-17-16-4.4-4-12.833-5-16.5-5h-92c-12.4 0-18.833 2.667-20.5 4C156.333 108 135 121.6 131 124c-5 3-4.5 10.5 5 10.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M173.206 100.941c-3.259.807-4.956 1.72-5.457 2.121l-.082.066-.09.057c-10.147 6.488-31.516 20.111-35.548 22.53-1.884 1.13-2.419 2.882-2.054 4.076.328 1.072 1.743 2.709 6.025 2.709h170.5c3.396 0 5.381-.685 6.495-1.497 1.043-.76 1.513-1.768 1.597-2.99.089-1.291-.265-2.814-.955-4.328-.684-1.505-1.633-2.852-2.551-3.771-5.463-5.463-11.431-10.934-16.931-15.934-1.834-1.667-4.658-2.816-7.648-3.536-2.951-.71-5.818-.944-7.507-.944h-92c-6.059 0-10.604.652-13.794 1.441zm-.962-3.882c3.544-.877 8.415-1.559 14.756-1.559h92c1.977 0 5.16.266 8.443 1.055 3.243.781 6.836 2.132 9.402 4.465 5.5 5 11.532 10.529 17.069 16.066 1.282 1.281 2.496 3.034 3.364 4.942.864 1.898 1.453 4.1 1.305 6.259-.154 2.228-1.102 4.395-3.231 5.948-2.058 1.5-4.998 2.265-8.852 2.265H136c-5.218 0-8.803-2.113-9.85-5.541-1.011-3.306.705-6.804 3.821-8.674 3.958-2.375 25.155-15.887 35.372-22.42 1.195-.915 3.516-1.968 6.901-2.806z"
          clipRule="evenodd"
        ></path>
        <path fill="#fff" d="M204 98h11l-9 36h-11l9-36z"></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M202.438 96h15.124l-10 40h-15.124l10-40zm3.124 4l-8 32h6.876l8-32h-6.876z"
          clipRule="evenodd"
        ></path>
        <path fill="#fff" d="M260 98h11l18 36h-11l-18-36z"></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M256.764 96h15.472l20 40h-15.472l-20-40zm6.472 4l16 32h6.528l-16-32h-6.528z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M26 166.5c-3.313 2.65-9 2.5-16.5 2.5 0-5.6 4.333-11.333 6.5-13.5h13.5c5.2 0 5.5 3.5 3.5 5s-4.5 4-7 6z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M15.172 153.5H29.5c2.953 0 5.332 1.011 6.276 3.121.93 2.081.015 4.285-1.576 5.479-.918.688-1.976 1.627-3.179 2.703l-.19.17c-1.123 1.006-2.35 2.103-3.582 3.089-2.103 1.682-4.813 2.363-7.718 2.671-2.601.275-5.624.272-8.876.268L9.5 171h-2v-2c0-3.283 1.257-6.466 2.695-9.019 1.448-2.571 3.186-4.69 4.39-5.895l.587-.586zm1.688 4a25.663 25.663 0 00-3.18 4.444c-.91 1.617-1.636 3.351-1.974 5.057 2.845 0 5.29-.022 7.404-.246 2.641-.28 4.431-.849 5.64-1.817 1.144-.914 2.294-1.943 3.439-2.968l.165-.148c1.172-1.049 2.364-2.11 3.446-2.922.409-.306.344-.602.324-.646-.006-.015-.377-.754-2.624-.754H16.86z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M337 134.5h6.5v23H330c-3.2 0-3.333-2.667-3-4 1.5-4.167 4.8-13.1 6-15.5 1.2-2.4 3.167-3.333 4-3.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M336.608 132.539c.129-.026.26-.039.392-.039h6.5a2 2 0 012 2v23a2 2 0 01-2 2H330c-1.078 0-2.056-.226-2.885-.717a4.393 4.393 0 01-1.75-1.932c-.655-1.394-.538-2.906-.305-3.836.016-.065.036-.129.058-.192 1.477-4.103 4.826-13.183 6.093-15.717 1.494-2.987 3.998-4.287 5.397-4.567zm.652 3.961c-.451.159-1.667.786-2.471 2.394-1.123 2.246-4.327 10.899-5.867 15.171-.031.155-.052.35-.045.551.009.24.054.419.108.533a.4.4 0 00.169.193c.086.051.324.158.846.158h11.5v-19h-4.24z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M343.5 145.5h-13v-4h13v4z"
          clipRule="evenodd"
        ></path>
        <rect
          width="29.155"
          height="22.524"
          x="34.947"
          y="167.93"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M47.137 171.735l-6.724 15.332-2.901 7.46c-.007 8.289 5.38 14.505 23.208 14.505h256.952c9.947 0 8.289-31.445 0-37.714-42.778-32.353-82.243-47.669-140.193-49.191-6.356-.167-12.538 2.037-17.503 6.009l-30.826 24.66a3.99 3.99 0 01-1.989.844l-67.408 8.651a16 16 0 00-12.616 9.444z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M25 189.967a7.117 7.117 0 017.118-7.117h15.55a4 4 0 014 4v6.235a4 4 0 01-4 4h-15.55A7.118 7.118 0 0125 189.967zM331 192.455a7.117 7.117 0 01-7.118 7.117h-15.55a4 4 0 01-4-4v-6.235a4 4 0 014-4h15.55a7.118 7.118 0 017.118 7.118z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M160.583 138.36l-12.43 10.577c-2.834 2.411-1.129 7.046 2.592 7.046h99.926c7.619 0 9.509-9.875 2.284-12.292-23.989-8.023-44.397-11.716-68.195-13.451-8.805-.641-17.453 2.398-24.177 8.12z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M221.93 136.092v19.893M211.984 134.434v20.722M129.918 152.668c-5.003 7.974-5.381 15.334-5.387 21.551v34.813M211.984 155.984v23.209c0 5.388-5.388 19.073-12.019 30.254M194.163 165.93h8.703"
        ></path>
        <circle
          cx="260.471"
          cy="201.987"
          r="24.11"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="260.471"
          cy="201.987"
          r="11.677"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="84.753"
          cy="201.987"
          r="24.11"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="84.753"
          cy="201.987"
          r="11.677"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationCarCollector;
