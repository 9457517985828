import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleNewCar: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M37.93 19.48l-.26-.08-.08-.26-.11-.38-.11.38-.08.26-.26.08-.38.11.38.12.26.07.08.26.11.38.11-.38.08-.26.26-.07.38-.12-.38-.11zm0 0l-.26-.08-.08-.26-.11-.38-.11.38-.08.26-.26.08-.38.11.38.12.26.07.08.26.11.38.11-.38.08-.26.26-.07.38-.12-.38-.11zM16.48 13l-6.42 11H5.48a3.5 3.5 0 100 7h2.46a3.45 3.45 0 002.48-1l1.24-1.23L16.48 37H46V13H16.48zM9 28.56a1.52 1.52 0 01-1.06.44H5.48a1.5 1.5 0 110-3h4.58l.56.95L9 28.56zM14 27a2.087 2.087 0 01-.51-.07l1.22-1.22a.999.999 0 00.21-1.09A1 1 0 0014 24h-1.74A2 2 0 1114 27zm15.19 5.93a.11.11 0 01-.2 0l-1.79-6-6-1.8a.1.1 0 110-.2l6-1.8 1.79-6a.11.11 0 01.2 0l1.8 6 6 1.8a.1.1 0 01.07.17.1.1 0 01-.07.03l-6 1.8-1.8 6zM40 30.53l-1.89.56-.6 1.91h-.06l-.56-1.89-1.89-.58v-.06l1.89-.56.56-1.91h.06l.56 1.89 1.89.56.04.08zm0-10.91l-1.89.57-.56 1.88-.56-1.88-1.99-.57v-.06l1.89-.56.56-1.88h.06l.56 1.88 1.89.56.04.06zm-2.29-.22l-.08-.26-.11-.38-.11.38-.08.26-.26.08-.38.11.38.12.26.07.08.26.11.38.11-.38.08-.26.26-.07.38-.12-.38-.11-.26-.08z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleNewCar;
