import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationRacing: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3884_11438"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3884_11438)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M173.243 43.466c-20.006-8.293-43.104-1.338-61.715 16.954l33.231 76.297c14.16-17.911 32.553-25.162 45.1-21.024 33.826 11.156 48.911 11.858 64.991 2.431 1.662-.974 2.292-3.043 1.553-4.822l-28.02-67.432c-.858-2.066-3.243-3.018-5.314-2.172-17.806 7.276-29.817 8.061-49.826-.232z"
        ></path>
        <mask
          id="mask1_3884_11438"
          style={{ maskType: 'alpha' }}
          width="150"
          height="104"
          x="109"
          y="37"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M173.235 43.466c-20.006-8.293-43.103-1.338-61.715 16.954l33.231 76.297c14.16-17.911 32.554-25.162 45.1-21.024 33.826 11.156 48.911 11.858 64.991 2.431 1.662-.974 2.292-3.043 1.553-4.822l-28.02-67.432c-.858-2.066-3.243-3.018-5.314-2.172-17.805 7.276-29.816 8.061-49.826-.232z"
          ></path>
        </mask>
        <g mask="url(#mask1_3884_11438)">
          <path
            fill={colors.primary[100]}
            d="M114.229 50.925l19.668-10.85 7.121 18.65-18.311 13.224-8.478-21.024zM140.682 59.402c7.562-3.933 13.215-4.666 22.38-3.391l7.799 19.328c-8.581-.717-13.692.12-22.041 4.07l-8.138-20.007zM131.864 91.174c7.562-3.933 10.173-10.068 17.633-11.764l8.478 20.346c-7.46 2.034-9.624 7.476-17.972 11.424l-8.139-20.006zM157.637 100.329c7.562-3.932 13.903-5.999 21.363-5.32l8.138 19.328c-7.46 2.034-13.353.798-21.702 4.747l-7.799-18.755zM170.523 75.701c7.562-3.932 19.328 6.081 26.789 6.76l7.799 18.65c-5.087-1.357-17.633-6.655-26.789-6.655l-7.799-18.755z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M188.828 63.705c8.816 1.8 17.294 3.157 24.415 1.8l8.477 20.007c-6.104.678-15.938-.678-25.093-3.052l-7.799-18.755z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M221.388 85.174c8.816 1.8 13.224-5.765 20.345-7.121l8.139 20.006c-6.104.679-11.53 8.244-20.685 5.87l-7.799-18.755zM205.103 101.789c8.817 1.801 17.633 4.747 24.754 3.391l8.477 18.989c-6.103.679-15.937 1.018-25.093-1.356l-8.138-21.024zM156.277 40.308c7.562-3.932 16.946 5.273 26.111 6.548l6.443 16.277c-6.782-1.018-17.219-9.093-25.772-6.887l-6.782-15.938zM206.462 49.523c7.562-3.932 10.842-7.67 20.007-6.396l6.443 13.903c-4.408 2.713-11.115 6.224-19.668 8.43l-6.782-15.937z"
          ></path>
          <path
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M117.627 76.358c12.031-12.592 26.789-21.228 37.301-21.024 10.851 0 15.181 1.252 36.961 8.816 12.547 4.358 28.484 2.374 41.031-7.46M127.125 96.03c12.03-12.592 26.788-21.228 37.3-21.025 10.851 0 15.181 1.253 36.961 8.817 12.547 4.358 28.484 2.374 41.031-7.46M136.612 115.699c12.031-12.591 26.789-21.227 37.301-21.024 10.851 0 15.181 1.253 36.961 8.817 12.547 4.357 28.484 2.374 41.031-7.46M134.92 44.145l30.858 74.94M155.944 39.736l31.536 74.94M183.076 47.197l30.519 75.279M206.47 48.553l31.875 75.618"
          ></path>
          <path
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M173.235 43.466c-20.006-8.293-43.103-1.338-61.715 16.954l33.231 76.297c14.16-17.911 32.554-25.162 45.1-21.024 33.826 11.156 48.911 11.858 64.991 2.431 1.662-.974 2.292-3.043 1.553-4.822l-28.02-67.432c-.858-2.066-3.243-3.018-5.314-2.172-17.805 7.276-29.816 8.061-49.826-.232z"
          ></path>
        </g>
        <rect
          width="10.92"
          height="170.973"
          x="101.613"
          y="50.596"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="5.46"
          transform="rotate(-22.5 101.613 50.596)"
        ></rect>
      </g>
    </svg>
  );
};

export default IllustrationRacing;
