import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationDigitalSecurity: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_643_3299"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_643_3299)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M104 103.253c0-9.94 8.059-18 18-18h113c9.941 0 18 8.059 18 18v105.131H104V103.253z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M104 103.253c0-9.94 8.059-18 18-18h113c9.941 0 18 8.06 18 18v10.522H104v-10.522z"
        ></path>
        <circle
          cx="118.261"
          cy="99.514"
          r="4.652"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="135.261"
          cy="99.514"
          r="4.652"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="152.261"
          cy="99.514"
          r="4.652"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M232.383 73.68a53.394 53.394 0 00-91.285-38.01 53.394 53.394 0 00-15.496 38.01v11.051h19.416V73.68a33.985 33.985 0 019.838-24.23 33.974 33.974 0 0158.111 24.23v11.051h19.416V73.68z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M224.844 159.652l-8.752 8.749h-13.127l-4.375-4.376-4.376 4.376-4.376-4.376-4.375 4.376h-10.599a21.88 21.88 0 01-37.029 5.048 21.872 21.872 0 010-27.599 21.874 21.874 0 0127.017-5.639 21.884 21.884 0 0110.012 10.687h41.228l8.752 8.754z"
        ></path>
        <circle
          cx="146.717"
          cy="159.753"
          r="4.283"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
      </g>
    </svg>
  );
};

export default IllustrationDigitalSecurity;
