import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationHunting: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_4402_10215"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4402_10215)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <circle
          cx="178"
          cy="113"
          r="71"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="178"
          cy="112.999"
          r="59.007"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M178 54.297v51.75M178 119.953v51.75M184.953 113h51.75M119.297 113h51.75"
        ></path>
        <rect
          width="4"
          height="4"
          x="176"
          y="111"
          fill={colors.primary[500]}
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M122.884 174.945l-1.259-5.843 28.576-6.16-1.283 5.623c-.919 4.028-3.829 7.36-7.797 8.51a75.97 75.97 0 01-11.889 2.476c-2.988.384-5.713-1.661-6.348-4.606zM128.949 167.523c2.334 4.353 3.538 5.797 6.342 6.301"
        ></path>
        <rect
          width="163.15"
          height="11.741"
          x="170.646"
          y="129.143"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
          transform="rotate(-12.164 170.646 129.143)"
        ></rect>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M111.14 158.72c.017-.022.033-.044.048-.067 2.288-3.326 3.769-6.177 4.994-8.59l.21-.413c1.14-2.249 2.013-3.97 3.149-5.464 2.247-2.956 5.723-5.262 15.443-7.357l38.467-8.291a.999.999 0 011.188.767l5.527 25.641a1.001 1.001 0 01-.767 1.189l-53.487 11.529c-4.246.915-7.945 2.132-11.053 4.375-3.147 2.272-5.523 5.475-7.352 10.075-.393.989-1.286 1.555-2.158 1.517l-12.024-.528a6.006 6.006 0 00-3.224.776l-58.19 33.012c-3.523 1.998-7.973.004-8.826-3.955l-6.049-28.061a6 6 0 014.601-7.129l66.54-14.343a2.124 2.124 0 011.12.073l.644-1.893-.644 1.893c4.745 1.614 8.76 2.483 12.368 1.839 3.742-.668 6.694-2.887 9.475-6.595z"
        ></path>
        <mask
          id="mask1_4402_10215"
          style={{ maskType: 'alpha' }}
          width="169"
          height="94"
          x="14"
          y="126"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M111.14 158.72c.017-.022.033-.044.048-.067 2.288-3.326 3.769-6.177 4.994-8.59l.21-.413c1.14-2.249 2.013-3.97 3.149-5.464 2.247-2.956 5.723-5.262 15.443-7.357l38.467-8.291a.999.999 0 011.188.767l5.527 25.641a1.001 1.001 0 01-.767 1.189l-53.487 11.529c-4.246.915-7.945 2.132-11.053 4.375-3.147 2.272-5.523 5.475-7.352 10.075-.393.989-1.286 1.555-2.158 1.517l-12.024-.528a6.006 6.006 0 00-3.224.776l-58.19 33.012c-3.523 1.998-7.973.004-8.826-3.955l-6.049-28.061a6 6 0 014.601-7.129l66.54-14.343a2.124 2.124 0 011.12.073l.644-1.893-.644 1.893c4.745 1.614 8.76 2.483 12.368 1.839 3.742-.668 6.694-2.887 9.475-6.595z"
          ></path>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_4402_10215)"
        >
          <path
            fill="#fff"
            d="M6.627 172.163H26.613V237.871H6.627z"
            transform="rotate(-12.164 6.627 172.163)"
          ></path>
          <path
            fill="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M143.73 182.738l-42.009-41.935 80.435-23.471 11.766 54.588-50.192 10.818z"
          ></path>
          <rect
            width="31.979"
            height="7.243"
            x="133.551"
            y="145.573"
            fill={colors.primary[100]}
            rx="3.622"
            transform="rotate(-12.164 133.551 145.573)"
          ></rect>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M111.14 158.72c.017-.022.033-.044.048-.067 2.288-3.326 3.769-6.177 4.994-8.59l.21-.413c1.14-2.249 2.013-3.97 3.149-5.464 2.247-2.956 5.723-5.262 15.443-7.357l38.467-8.291a.999.999 0 011.188.767l5.527 25.641a1.001 1.001 0 01-.767 1.189l-53.487 11.529c-4.246.915-7.945 2.132-11.053 4.375-3.147 2.272-5.523 5.475-7.352 10.075-.393.989-1.286 1.555-2.158 1.517l-12.024-.528a6.006 6.006 0 00-3.224.776l-58.19 33.012c-3.523 1.998-7.973.004-8.826-3.955l-6.049-28.061a6 6 0 014.601-7.129l66.54-14.343a2.124 2.124 0 011.12.073l.644-1.893-.644 1.893c4.745 1.614 8.76 2.483 12.368 1.839 3.742-.668 6.694-2.887 9.475-6.595z"
          ></path>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M178.461 140.493l62.768-13.53 1.999 9.276a6 6 0 01-4.601 7.129l-56.903 12.265-3.263-15.14z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M179.604 148.17l63.381-13.661"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M227.125 129.108l-20.397 4.397c-.867.186-1.498.925-1.605 1.806-.602 4.953-2.318 7.07-7.548 8.537-1.059.297-1.741 1.347-1.51 2.422l1.187 5.505a4 4 0 004.753 3.067l25.395-5.474a6 6 0 004.601-7.129l-2.5-11.597a1.999 1.999 0 00-2.376-1.534z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationHunting;
