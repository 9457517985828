import React from 'react';
import { Box, UseCheckboxProps, useCheckbox } from '@wegroup/design-system';

export interface Props {
  children?: string | ChildNode | React.ReactNode;
  useCheckboxProps: UseCheckboxProps;
}

const ButtonCheckbox: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  useCheckboxProps,
  ...rest
}) => {
  const { getInputProps, getLabelProps, state } = useCheckbox(useCheckboxProps);

  return (
    <Box
      as="label"
      display="block"
      bgColor="white"
      cursor="pointer"
      data-test-id="DesignSystem_ButtonCheckbox"
      p={{ base: '5', mobileL: '7' }}
      borderColor="gray.50"
      borderRadius="md"
      borderStyle="solid"
      borderWidth="1px"
      boxShadow="level2"
      width="fit-content"
      // userSelect fixes bug (not being able to click)
      userSelect="none"
      _checked={{
        borderColor: 'primary.500',
        borderWidth: '2px',
        boxShadow: 'level4',
        p: { base: '19px', mobileL: '27px' },
      }}
      _disabled={{
        _hover: {
          cursor: 'not-allowed',
          boxShadow: state.isChecked ? 'level4' : 'level2',
        },
      }}
      _hover={{
        boxShadow: 'level4',
      }}
      transition="box-shadow .1s, border-color .1s"
      position="relative"
      {...getLabelProps()}
      {...rest}
    >
      <input {...getInputProps()} />
      {children}
    </Box>
  );
};

export default ButtonCheckbox;
