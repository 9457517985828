import React from 'react';
import { Icon, Text } from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import LouiseChatIntroColumn from './LouiseChatIntroColumn';
import LouiseChatIntroCard from './LouiseChatIntroCard';

const LouiseChatIntroLimitations: React.FC = () => {
  const { t } = useTranslation();
  return (
    <LouiseChatIntroColumn
      title={t('TOOLKIT_INSURANCE-TERMS-BOT_INTRO_LIMITATIONS')}
      icon={
        <Icon
          name="GeneralStatusWarningTriangle"
          boxSize={8}
          color="primary.500"
        />
      }
    >
      <LouiseChatIntroCard>
        <Text>{t('TOOLKIT_INSURANCE-TERMS-BOT_INTRO_LIMITATION_1')}</Text>
      </LouiseChatIntroCard>
      <LouiseChatIntroCard>
        <Text>{t('TOOLKIT_INSURANCE-TERMS-BOT_INTRO_LIMITATION_2')}</Text>
      </LouiseChatIntroCard>
      <LouiseChatIntroCard>
        <Text>{t('TOOLKIT_INSURANCE-TERMS-BOT_INTRO_LIMITATION_3')}</Text>
      </LouiseChatIntroCard>
    </LouiseChatIntroColumn>
  );
};

export default LouiseChatIntroLimitations;
