import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleRearCrossTraficWarning: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M9.085 13.42l-2.83-1.96c-4.34 6.27-5.44 14.3-2.93 21.48.82 2.37 2.01 4.59 3.53 6.59l2.75-2.08a20.5 20.5 0 01-.52-24.03z"
    ></path>
    <path
      fill="currentColor"
      d="M12.595 30.39A16.085 16.085 0 0114.565 16l-2.83-1.96a19.538 19.538 0 00-2.4 17.49 19.44 19.44 0 002.88 5.37l2.74-2.09c-1-1.33-1.8-2.82-2.36-4.42zM47.965 37.27h-4.77c-.23 0-.46-1.69-.23-1.76h4.49c.17 0 .34.01.5.04V15.1c-.2.03-.41.04-.62.04h-4.38c-.23-.06.01-1.76.23-1.76h4.77V11h-21.66c-4.03 0-7.47 2.5-8.87 6.03 0 0-3.28 7.92 0 16.6 1.4 3.53 4.84 6.03 8.87 6.03H47.965v-2.39zm-7.15-23.89v1.76s-2.65.19-6.95-.25c-.71-.07-5.16-1.51-5.16-1.51h12.11zm-14.23 22.26c-.98-3.09-1.53-6.6-1.53-10.31 0-3.71.55-7.22 1.53-10.31.08-.26.27-.4.45-.34l5.48 1.78c.23.07.37.43.29.78-.59 2.52-.91 5.24-.91 8.09 0 2.85.33 5.58.91 8.09.08.35-.05.71-.29.78l-5.48 1.78c-.18.05-.37-.09-.45-.34zm14.23 1.8h-12.11s4.45-1.44 5.16-1.51c4.3-.43 6.95-.25 6.95-.25v1.76z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleRearCrossTraficWarning;
