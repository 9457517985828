import { object, string, InferType } from 'yup';
import { caribbeanCountries } from '../../../../../../common/src/constants/caribbeanCountries';

export const addressSearchNLSchema = object({
  zipCode: string()
    .required('Required')
    .matches(/[1-9][0-9]{3} ?(S[BCE-RT-Z]|[A-RT-Za-rt-z][A-Za-z])/, {
      message: 'invalidZipcode',
    }),
  houseNr: string().required('Required'),
});

export type AddressSearchNLFormValues = InferType<typeof addressSearchNLSchema>;

export const addressSchema = object().shape({
  country_code: string(),
  street: string()
    .matches(
      /^([a-zA-z0-9/\\'',-\s]{2,255})$/,
      'The answer contains invalid characters',
    )
    .required('REQUIRED_FIELD'),
  housenr: string()
    .matches(/[aA-zZ\s0-9]+/, 'Only alphanumeric characters are allowed')
    .matches(/^\d+/, 'Should start with a number')
    .required('REQUIRED_FIELD'),
  boxnr: string()
    .matches(/^[aA-zZ\s0-9]+$/, 'Only alphanumeric characters are allowed')
    .nullable(),
  zipcode: string()
    .matches(/^[aA-zZ\s0-9]+$/, 'Only alphanumeric characters are allowed')
    .when('country_code', (countryCode, schema) => {
      if (caribbeanCountries.includes(countryCode)) {
        return schema;
      }
      return schema.required('REQUIRED_FIELD');
    }),
  city: string().matches(
    /^([a-zA-z0-9/\\'',-\s]{2,255})$/,
    'The answer contains invalid characters',
  ),
});

export type Address = InferType<typeof addressSchema>;
