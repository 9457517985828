import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsArrowDown: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 5a2 2 0 012 2v36a2 2 0 11-4 0V7a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M41.843 26.157a2 2 0 010 2.829L26.414 44.414a2 2 0 11-2.828-2.828l15.428-15.429a2 2 0 012.829 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.157 26.157a2 2 0 012.829 0l15.428 15.429a2 2 0 01-2.828 2.828L8.157 28.986a2 2 0 010-2.829z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsArrowDown;
