import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyPool: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M44 24a1 1 0 00-1 1v4.12a20.67 20.67 0 01-16.85 1.58 40 40 0 01-2.15-.85V17.69A4.62 4.62 0 0019.2 13a4.975 4.975 0 00-.56.07.199.199 0 00-.148.24.2.2 0 00.048.09c.367.451.673.949.91 1.48a.18.18 0 00.17.11A2.5 2.5 0 0122 17.5v2.3a.2.2 0 01-.2.2h-3.6a.2.2 0 01-.2-.2v-2.1a4.64 4.64 0 00-4-4.7 4.51 4.51 0 00-5 4.5V19a1 1 0 002 0v-1.35A2.61 2.61 0 0113.24 15 2.51 2.51 0 0116 17.5v10a66.193 66.193 0 00-9-.5v-2a1 1 0 10-2 0v18a1 1 0 001 1h38a1 1 0 001-1V25a1 1 0 00-1-1zm-25.8-2h3.6a.2.2 0 01.2.2v1.6a.2.2 0 01-.2.2h-3.6a.2.2 0 01-.2-.2v-1.6a.2.2 0 01.2-.2zm0 4h3.6a.2.2 0 01.2.2V29c-.24-.1-.48-.2-.73-.28a22.574 22.574 0 00-3.27-.89V26.2a.2.2 0 01.2-.2z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyPool;
