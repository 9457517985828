import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycleAllTerrain: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3627_10147"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3627_10147)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M57.115 206.513L24.36 227.986c-1.64 1.075-.914 3.623 1.047 3.672l295.651 7.306a2 2 0 001.931-1.322l32.046-88.965a2 2 0 00-1.881-2.677h-24.743a20.007 20.007 0 00-10.873 3.213l-35.007 22.675a19.993 19.993 0 01-8.808 3.107l-46.051 4.779a20 20 0 01-4.341-.023l-38.425-4.403a20.006 20.006 0 00-13.561 3.357l-20.813 14.223a20.011 20.011 0 01-6.603 2.931L97.817 206.96a20.004 20.004 0 01-8.668.155l-17.082-3.475a20 20 0 00-14.951 2.873z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M196.634 72.374l-19.049 73.144-3.871-1.008 19.049-73.144 3.871 1.008z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M147.38 152.568c4.325 21.664-9.731 42.732-31.395 47.057-21.663 4.325-42.732-9.731-47.056-31.395-4.326-21.664 9.73-42.732 31.394-47.057 21.664-4.325 42.732 9.731 47.057 31.395z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M136.593 154.721c3.136 15.707-7.055 30.981-22.761 34.117-15.707 3.135-30.981-7.055-34.117-22.762-3.135-15.706 7.055-30.98 22.762-34.116 15.706-3.135 30.98 7.055 34.116 22.761z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M280.749 125.942c4.325 21.664-9.731 42.732-31.395 47.057-21.664 4.325-42.732-9.731-47.057-31.395-4.325-21.664 9.731-42.732 31.395-47.057 21.664-4.325 42.732 9.731 47.057 31.395z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M269.962 128.096c3.135 15.706-7.055 30.981-22.762 34.116-15.706 3.136-30.98-7.055-34.116-22.761-3.136-15.706 7.055-30.981 22.761-34.116 15.707-3.136 30.981 7.055 34.117 22.761z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M109.625 160.105l12.327-70.925a1 1 0 00-1.181-1.152l-16.221 3.239"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M121.81 96.999c-1.579.315-2.157 2.273-1.002 3.395l50.922 49.488c.472.459 1.14.656 1.785.527l66.826-13.341c1.58-.315 2.158-2.273 1.003-3.395l-50.922-49.489a2.002 2.002 0 00-1.786-.526l-66.826 13.34zm8.243 2.433c-1.579.315-2.157 2.273-1.002 3.395l43.958 42.721a2 2 0 001.786.527l57.303-11.44c1.58-.315 2.157-2.273 1.002-3.395l-43.958-42.721a2 2 0 00-1.785-.527l-57.304 11.44z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M186.607 144.737c1.297 6.499-2.92 12.819-9.419 14.117-6.499 1.297-12.82-2.92-14.117-9.419-1.298-6.499 2.919-12.819 9.418-14.117 6.5-1.297 12.82 2.919 14.118 9.419z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M176.405 154.931a8 8 0 10-3.132-15.69 8 8 0 003.132 15.69zm.783 3.923c6.499-1.298 10.716-7.618 9.419-14.117-1.298-6.5-7.618-10.716-14.118-9.419-6.499 1.298-10.716 7.618-9.418 14.117 1.297 6.499 7.618 10.716 14.117 9.419zM173.098 77.072a2 2 0 011.57-2.353l27.458-5.482a2 2 0 11.783 3.923l-27.458 5.481a2 2 0 01-2.353-1.57z"
          clipRule="evenodd"
        ></path>
        <rect
          width="48"
          height="57"
          x="210.867"
          y="102.808"
          fill={colors.primary[200]}
          stroke="#fff"
          strokeWidth="4"
          rx="6"
          transform="rotate(-11.29 210.867 102.808)"
        ></rect>
        <rect
          width="40"
          height="49"
          x="215.573"
          y="105.948"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
          transform="rotate(-11.29 215.573 105.948)"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M219.097 123.599l39.226-7.831M228.219 115.66l2.35 11.767M246.852 111.94l2.349 11.768"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBicycleAllTerrain;
