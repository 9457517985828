const parts = ['container', 'button', 'panel', 'icon'];

const baseStyle = {
  button: {
    color: 'black',
    lineHeight: 1,
    fontSize: 'md',
    fontWeight: 400,
    p: 3,
  },
  icon: {
    color: 'gray.300',
    background: 'gray.strokeAndBg',
    borderRadius: 'md',
    width: '8',
    height: '8',
  },
};

const variants = {
  standalone: {
    container: {
      background: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'gray.50',
      boxShadow: 'level1',
      borderRadius: 'md',
    },
  },
  white: {
    container: {
      bg: 'white',
    },
    button: {
      borderBottom: '1px',
      borderColor: 'gray.50',
      py: '0',
      display:"flex",
      justifyContent: "space-between",
      h: "55px",
      _expanded: {
        '.chakra-accordion__icon': {
          bg: 'primary.500',
          color: 'white',
        },
      },
    },
    icon: {
      bg: 'unset',
      color: 'gray.500',
      transition: "0.2s",
      borderRadius:"full",
      boxSize: 4,
    },
    panel: {
      p: '4',
      borderBottom: '1px',
      borderColor: 'gray.50',
    }
  },
  categoryList: {
    container: {
      background: 'white',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'gray.50',
      boxShadow: 'level1',
      borderRadius: 'md',
    },
    button: {
      display:"flex",
      justifyContent:"space-between",
      borderBottom:"1px solid",
      borderColor:"gray.strokeAndBg",
      gridGap:"2",
    },
    panel: {
      px: 0,
      py: 3,
    }
  }
};

const Accordion = {
  parts,
  baseStyle,
  variants,
};

export default Accordion;
