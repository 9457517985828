import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMotoSpareTime: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_4345_10338"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4345_10338)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2zM201.099 143.041a1.999 1.999 0 012.36 1.558l8.5 41.5a2 2 0 01-3.918.803l-8.5-41.5a2 2 0 011.558-2.361zM234.106 145.212a2 2 0 012.683.894l16 32a2 2 0 01-3.578 1.789l-16-32a2 2 0 01.895-2.683z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M133 192c0 18.778-15.222 34-34 34s-34-15.222-34-34 15.222-34 34-34 34 15.222 34 34z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M99 222c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30zm0 4c18.778 0 34-15.222 34-34s-15.222-34-34-34-34 15.222-34 34 15.222 34 34 34z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M117.308 192.001c0 10.111-8.197 18.308-18.308 18.308s-18.308-8.197-18.308-18.308S88.89 173.693 99 173.693s18.308 8.197 18.308 18.308z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M99 206.309c7.902 0 14.308-6.406 14.308-14.308S106.902 177.693 99 177.693s-14.308 6.406-14.308 14.308S91.098 206.309 99 206.309zm0 4c10.111 0 18.308-8.197 18.308-18.308S109.111 173.693 99 173.693s-18.308 8.197-18.308 18.308S88.89 210.309 99 210.309z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M292 192c0 18.778-15.222 34-34 34s-34-15.222-34-34 15.222-34 34-34 34 15.222 34 34z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M258 222c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30zm0 4c18.778 0 34-15.222 34-34s-15.222-34-34-34-34 15.222-34 34 15.222 34 34 34z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M276.308 192.001c0 10.111-8.197 18.308-18.308 18.308s-18.308-8.197-18.308-18.308 8.197-18.308 18.308-18.308 18.308 8.197 18.308 18.308z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M258 206.309c7.902 0 14.308-6.406 14.308-14.308s-6.406-14.308-14.308-14.308-14.308 6.406-14.308 14.308 6.406 14.308 14.308 14.308zm0 4c10.111 0 18.308-8.197 18.308-18.308s-8.197-18.308-18.308-18.308-18.308 8.197-18.308 18.308 8.197 18.308 18.308 18.308zM130.93 131.73a2 2 0 01.84 2.701l-31 59a2 2 0 11-3.54-1.861l30.999-59a2 2 0 012.701-.84z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M97 150a2 2 0 012-2c10.864 0 26.071 4.115 35.083 15.777a2.001 2.001 0 01-3.166 2.446C122.929 155.885 109.136 152 99 152a2 2 0 01-2-2zM222.563 145.929c-6.808.365-13.974-1.574-21.914-7.147a1.999 1.999 0 10-2.298 3.274c8.56 6.008 16.595 8.287 24.426 7.867 7.777-.417 15.141-3.486 22.4-7.784a2 2 0 00-2.038-3.442c-6.997 4.142-13.713 6.864-20.576 7.232z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M211.972 186.835c5.779-34.191 37.503-47.825 62.953-36.242a2 2 0 101.657-3.64c-27.814-12.66-62.333 2.406-68.554 39.215a2 2 0 103.944.667z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M117 126c3.5 0 7.5 2 14 7.5-6 5.5-9 8-14 8-3 1.5-3.5-15.5 0-15.5z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M114.221 125.6c.506-.741 1.413-1.6 2.779-1.6 4.284 0 8.775 2.459 15.292 7.973a2.001 2.001 0 01.06 3.001c-5.778 5.297-9.31 8.399-14.998 8.522a2.69 2.69 0 01-1.374.017c-.658-.165-1.137-.545-1.46-.898-.605-.662-.962-1.557-1.194-2.33-.487-1.628-.73-3.816-.763-5.942-.033-2.133.143-4.402.6-6.202.225-.886.552-1.801 1.058-2.541zm3.057 13.897c3.541-.076 5.894-1.636 10.668-5.924-5.069-4.062-8.122-5.418-10.515-5.56-.115.221-.256.581-.391 1.112-.34 1.34-.507 3.228-.478 5.157.03 1.936.256 3.718.596 4.855.043.142.083.261.12.36z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M132 138.591c0-12.103 11.212-21.09 23.025-18.456l39.684 8.848A14.432 14.432 0 01206 143.069c0 7.971-6.461 14.432-14.432 14.432H150.91c-10.444 0-18.91-8.466-18.91-18.91z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M193.839 132.887l-39.685-8.848C144.84 121.962 136 129.048 136 138.591c0 8.235 6.675 14.91 14.91 14.91h40.658a10.433 10.433 0 002.271-20.614zm-38.814-12.752C143.212 117.501 132 126.488 132 138.591c0 10.444 8.466 18.91 18.91 18.91h40.658c7.971 0 14.432-6.461 14.432-14.432 0-6.76-4.693-12.615-11.291-14.086l-39.684-8.848z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M209 175.818c0-9.721-9.556-16.561-18.757-13.426l-23.409 7.975a10.087 10.087 0 003.252 19.634h24.731c7.833 0 14.183-6.35 14.183-14.183z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M168.124 174.154l23.409-7.976c6.606-2.25 13.467 2.661 13.467 9.64 0 5.624-4.559 10.183-10.183 10.183h-24.731a6.086 6.086 0 01-1.962-11.847zm22.119-11.762c9.201-3.135 18.757 3.705 18.757 13.426 0 7.833-6.35 14.183-14.183 14.183h-24.731a10.087 10.087 0 01-3.252-19.634l23.409-7.975z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M160.838 165.213a2 2 0 01-1.05 2.626l-8.626 3.697c2.564 5.675 6.874 15.21 8.659 19.137 1.104 2.429 2.313 3.524 3.484 4.088 1.251.602 2.729.74 4.695.74h44.5v4H168c-2.034 0-4.306-.113-6.43-1.136-2.204-1.061-3.995-2.966-5.391-6.037-2.001-4.403-7.169-15.838-9.502-21.004a1.999 1.999 0 011.035-2.662l10.5-4.5a2 2 0 012.626 1.051zM156.857 107.258a2 2 0 01-1.114 2.599c-2.41.964-8.9 3.913-15.183 7.84-2.711 1.694-4.664 4.606-5.991 7.765-1.319 3.142-1.924 6.307-2.075 8.198a2 2 0 01-3.988-.319c.183-2.276.877-5.86 2.375-9.427 1.49-3.55 3.87-7.304 7.559-9.609 6.518-4.074 13.227-7.125 15.817-8.161a1.999 1.999 0 012.6 1.114z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationMotoSpareTime;
