import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsRefreshOrReload: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 14.523c.966 0 1.75.783 1.75 1.75v4.795h4.795a1.75 1.75 0 110 3.5H13a1.75 1.75 0 01-1.75-1.75v-6.545c0-.967.784-1.75 1.75-1.75zM28.704 27.182c0-.966.784-1.75 1.75-1.75H37c.967 0 1.75.784 1.75 1.75v6.546a1.75 1.75 0 01-3.5 0v-4.796h-4.796a1.75 1.75 0 01-1.75-1.75z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.792 13.89a11.569 11.569 0 0114.12 7.253 1.75 1.75 0 11-3.3 1.168 8.068 8.068 0 00-13.352-2.974l-5.062 4.756a1.75 1.75 0 01-2.396-2.55l5.042-4.738a11.569 11.569 0 014.948-2.915zm16.483 12.093a1.75 1.75 0 01-.077 2.474l-5.042 4.739a11.57 11.57 0 01-19.068-4.34 1.75 1.75 0 013.3-1.167 8.068 8.068 0 0013.352 2.974l5.062-4.756a1.75 1.75 0 012.473.076z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsRefreshOrReload;
