import React from 'react';
import { InsuranceTermsBotResource } from '../../api/postInsuranceTermsBotChat';
import ErrorChatMessage from './ErrorChatMessage';
import LoadingChatMessage from './LoadingChatMessage';
import LouiseSingleChatFooter from './LouiseSingleChatFooter';
import LouiseSingleChatMessage from './LouiseSingleChatMessage';
import {
  LouiseChatBox,
  LouiseSingleAvatarWrapper,
} from './LouiseSingleChatWrapper';

interface Props {
  name?: string;
  message?: string;
  resources?: InsuranceTermsBotResource[];
  isLoading?: boolean;
  isError?: boolean;
  isTyperDone?: boolean;
}

const LouiseSingleChat: React.FC<Props> = ({
  name = 'Louise',
  message,
  resources,
  isLoading,
  isError,
  isTyperDone,
}) => {
  if (isLoading)
    return (
      <LouiseSingleAvatarWrapper name={name}>
        <LoadingChatMessage />
      </LouiseSingleAvatarWrapper>
    );

  if (isError)
    return (
      <LouiseSingleAvatarWrapper name={name}>
        <ErrorChatMessage />
      </LouiseSingleAvatarWrapper>
    );

  return (
    <LouiseSingleAvatarWrapper name={name}>
      <LouiseChatBox>
        <LouiseSingleChatMessage message={message} />
        <LouiseSingleChatFooter
          resources={resources}
          isCopyDisabled={!isTyperDone}
          message={message}
        />
      </LouiseChatBox>
    </LouiseSingleAvatarWrapper>
  );
};

export default LouiseSingleChat;
