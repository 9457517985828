const darkGrey = {
  primary: {
    main: '#636363',
    50: '#F8F8F8',
    100: '#E8E8E8',
    150: '#D7D7D7',
    200: '#C6C6C6',
    300: '#A5A5A5',
    400: '#848484',
    500: '#636363',
    600: '#3F3F3F',
    700: '#1C1C1C',
    750: '#0A0A0A',
    800: '#000000',
    850: '#000000',
    900: '#000000',
  },
  secondary: {
    main: '#424242',
    50: '#D7D7D7',
    100: '#C7C7C7',
    150: '#B6B6B6',
    200: '#A5A5A5',
    300: '#848484',
    400: '#636363',
    500: '#424242',
    600: '#303030',
    700: '#1E1E1E',
    750: '#151515',
    800: '#0C0C0C',
    850: '#040404',
    900: '#000000',
  },
};

export default darkGrey;
