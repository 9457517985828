import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationLogout: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.757 8.757A6 6 0 0111 7h14a6 6 0 016 6v4a2 2 0 11-4 0v-4a2 2 0 00-2-2H11a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 114 0v4a6 6 0 01-6 6H11a6 6 0 01-6-6V13a6 6 0 011.757-4.243z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.586 17.586a2 2 0 012.828 0l6 6a2 2 0 010 2.828l-6 6a2 2 0 11-2.828-2.828L38.172 27H15a2 2 0 110-4h23.172l-2.586-2.586a2 2 0 010-2.828z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationLogout;
