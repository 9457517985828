import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationLogin: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.826 7.885a6.235 6.235 0 014.41-1.826h14.823a6.235 6.235 0 016.235 6.235v4.235a2 2 0 11-4 0v-4.235a2.235 2.235 0 00-2.235-2.235H11.235A2.235 2.235 0 009 12.294v25.412a2.235 2.235 0 002.235 2.235H26.06a2.235 2.235 0 002.235-2.235V33.47a2 2 0 014 0v4.236a6.235 6.235 0 01-6.235 6.235H11.235A6.235 6.235 0 015 37.706V12.294c0-1.654.657-3.24 1.826-4.409z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.238 17.233a2 2 0 010 2.828L20.298 23H43a2 2 0 110 4H20.299l2.939 2.939a2 2 0 01-2.829 2.828l-6.353-6.353a2 2 0 010-2.828l6.353-6.353a2 2 0 012.829 0z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationLogin;
