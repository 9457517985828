import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskAnalysisSubjectsMobility: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 36.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM7.5 39a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0zM37 8.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM31.5 11a5.5 5.5 0 1111 0 5.5 5.5 0 01-11 0zM18 12.5a5.5 5.5 0 000 11h16a8.5 8.5 0 110 17h-9a1.5 1.5 0 010-3h9a5.5 5.5 0 000-11H18a8.5 8.5 0 110-17h7a1.5 1.5 0 010 3h-7z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisSubjectsMobility;
