import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationRentalCar: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3799_10423"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3799_10423)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M53.676 194.551c0 10.559 8.01 15.293 15.293 15.293h8.375l18.57-4.005h156.931c13.837 0 25.852 2.548 36.411 0 8.448-2.039 9.589-6.919 9.103-9.103v-37.504c0-7.573-3.398-11.409-5.097-12.379l-27.309-24.396h-75.007c-20.754 0-21.846 0-41.508 13.108-19.662 13.108-29.858 17.113-37.14 18.934-7.282 1.82-28.036 6.19-43.329 9.467-15.293 4.733-15.293 20.026-15.293 30.585z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M170.211 125.487c-5.082 1.564-10.084 4.702-19.966 11.289-19.738 13.159-30.09 17.259-37.594 19.135-5.975 1.494-20.947 4.689-34.552 7.593-3.026.645-5.984 1.277-8.76 1.871-7.084 2.213-10.585 6.806-12.37 12.159-1.824 5.473-1.836 11.694-1.836 17.017 0 4.874 1.831 8.287 4.408 10.501 2.611 2.244 6.083 3.335 9.428 3.335h8.22l18.569-4.005h157.087c4.869 0 9.548.314 13.962.611 1.901.127 3.753.252 5.549.346 6.027.318 11.495.306 16.559-.917 3.982-.961 6.041-2.54 7.068-3.942 1.038-1.416 1.104-2.755.954-3.429l-.034-.156v-37.663c0-7.152-3.2-10.45-4.364-11.116l-.133-.076-27.009-24.127h-74.451c-10.454 0-15.666.014-20.735 1.574zM190.696 121h75.813l27.618 24.672c2.241 1.378 5.689 5.733 5.689 13.56v37.357c.28 1.503-.017 3.611-1.484 5.613-1.534 2.094-4.269 3.974-8.734 5.052-5.495 1.326-11.316 1.314-17.395.994a388.659 388.659 0 01-5.704-.356c-4.37-.294-8.886-.598-13.654-.598H96.068l-18.57 4.006h-8.53c-3.936 0-8.111-1.276-11.326-4.039-3.249-2.792-5.422-7.026-5.422-12.71v-.098c0-5.222 0-11.881 1.986-17.84 2.034-6.102 6.164-11.511 14.332-14.039l.062-.019.064-.014c2.81-.602 5.804-1.241 8.862-1.894 13.605-2.903 28.499-6.082 34.419-7.562 7.06-1.765 17.099-5.675 36.685-18.732 9.78-6.521 15.155-9.937 20.724-11.65C174.889 121 180.553 121 190.696 121z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M68.969 194.916v14.929H82.44l13.108-4.37h27.308v-10.559c0-11.651-13.836-18.934-19.662-18.934H91.18c-13.982 0-22.211 10.924-22.211 18.934z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M91.18 177.438c-13.205 0-20.755 10.298-20.755 17.478v13.472h11.78l13.107-4.37h26.089v-9.102c0-5.21-3.095-9.565-7.088-12.69a26.23 26.23 0 00-6.139-3.576c-2.019-.825-3.781-1.212-4.979-1.212H91.18zm-23.668 17.478c0-8.842 8.908-20.391 23.668-20.391h12.015c1.715 0 3.868.524 6.081 1.429a29.155 29.155 0 016.832 3.978c4.382 3.43 8.206 8.541 8.206 14.984v12.015H95.785l-13.108 4.37H67.512v-16.385z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M117.76 203.655c0 12.066-9.781 21.847-21.847 21.847s-21.847-9.781-21.847-21.847c0-12.065 9.781-21.846 21.847-21.846s21.847 9.781 21.847 21.846z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M95.913 222.589c10.457 0 18.934-8.477 18.934-18.934s-8.477-18.934-18.934-18.934-18.934 8.477-18.934 18.934 8.477 18.934 18.934 18.934zm0 2.913c12.066 0 21.847-9.781 21.847-21.847 0-12.065-9.781-21.846-21.847-21.846s-21.847 9.781-21.847 21.846c0 12.066 9.781 21.847 21.847 21.847z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M108.293 203.655c0 6.837-5.543 12.38-12.38 12.38-6.837 0-12.38-5.543-12.38-12.38 0-6.837 5.543-12.38 12.38-12.38 6.837 0 12.38 5.543 12.38 12.38z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M95.913 213.122a9.467 9.467 0 10-9.467-9.467 9.467 9.467 0 009.467 9.467zm0 2.913c6.837 0 12.38-5.543 12.38-12.38 0-6.837-5.543-12.38-12.38-12.38-6.837 0-12.38 5.543-12.38 12.38 0 6.837 5.543 12.38 12.38 12.38z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M279.425 194.916v12.016h-13.472l-13.108-1.457h-27.309v-10.559c0-11.651 13.837-18.934 19.662-18.934h12.016c13.982 0 22.211 10.924 22.211 18.934z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M257.214 177.438c13.204 0 20.754 10.298 20.754 17.478v10.559h-11.935l-13.108-1.457h-25.932v-9.102c0-5.21 3.095-9.565 7.087-12.69a26.27 26.27 0 016.139-3.576c2.02-.825 3.781-1.212 4.979-1.212h12.016zm23.667 17.478c0-8.842-8.907-20.391-23.667-20.391h-12.016c-1.714 0-3.867.524-6.08 1.428a29.201 29.201 0 00-6.833 3.979c-4.382 3.43-8.205 8.541-8.205 14.984v12.015h28.684l13.108 1.457h15.009v-13.472z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M274.328 203.655c0 12.066-9.781 21.847-21.847 21.847s-21.847-9.781-21.847-21.847c0-12.065 9.781-21.846 21.847-21.846s21.847 9.781 21.847 21.846z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M252.481 222.589c10.457 0 18.934-8.477 18.934-18.934s-8.477-18.934-18.934-18.934-18.934 8.477-18.934 18.934 8.477 18.934 18.934 18.934zm0 2.913c12.066 0 21.847-9.781 21.847-21.847 0-12.065-9.781-21.846-21.847-21.846s-21.847 9.781-21.847 21.846c0 12.066 9.781 21.847 21.847 21.847z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M264.861 203.655c0 6.837-5.543 12.38-12.38 12.38-6.837 0-12.38-5.543-12.38-12.38 0-6.837 5.543-12.38 12.38-12.38 6.837 0 12.38 5.543 12.38 12.38z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M252.481 213.122a9.467 9.467 0 100-18.934 9.467 9.467 0 00-9.467 9.467 9.466 9.466 0 009.467 9.467zm0 2.913c6.837 0 12.38-5.543 12.38-12.38 0-6.837-5.543-12.38-12.38-12.38-6.837 0-12.38 5.543-12.38 12.38 0 6.837 5.543 12.38 12.38 12.38z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M146.889 155.954h124.162c10.559 0 7.573-8.447 4.369-11.652-4.005-4.005-8.374-8.01-12.38-11.651-3.204-2.913-9.345-3.641-12.015-3.641h-66.997c-9.03 0-13.715 1.942-14.928 2.913-7.404 4.733-22.939 14.637-25.852 16.385-3.641 2.184-3.277 7.646 3.641 7.646z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M173.983 131.515c-2.373.588-3.609 1.252-3.974 1.544l-.06.049-.065.041c-7.39 4.725-22.951 14.645-25.887 16.407-1.372.823-1.761 2.099-1.496 2.968.239.781 1.269 1.973 4.388 1.973h124.162c2.473 0 3.918-.499 4.73-1.09.759-.554 1.101-1.288 1.163-2.178.064-.939-.194-2.048-.696-3.151-.498-1.096-1.189-2.077-1.858-2.746-3.978-3.978-8.324-7.963-12.329-11.604-1.336-1.214-3.392-2.051-5.57-2.575-2.148-.517-4.236-.687-5.466-.687h-66.997c-4.413 0-7.722.474-10.045 1.049zm-.7-2.827c2.58-.639 6.128-1.135 10.745-1.135h66.997c1.44 0 3.758.193 6.148.768 2.362.569 4.978 1.553 6.847 3.252 4.005 3.641 8.398 7.667 12.43 11.699.933.933 1.817 2.209 2.45 3.599.629 1.382 1.058 2.985.95 4.558-.112 1.623-.803 3.201-2.353 4.331-1.499 1.093-3.64 1.65-6.446 1.65H146.889c-3.8 0-6.411-1.539-7.173-4.035-.736-2.408.513-4.955 2.782-6.317 2.882-1.729 18.319-11.569 25.759-16.326.871-.667 2.56-1.434 5.026-2.044z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M196.408 129.373h8.01l-6.554 26.216h-8.01l6.554-26.216z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M195.271 127.916h11.012l-7.282 29.129h-11.013l7.283-29.129zm2.274 2.913l-5.826 23.303h5.008l5.826-23.303h-5.008z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M237.188 129.373h8.011l13.108 26.216h-8.011l-13.108-26.216z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M234.832 127.916h11.267l14.564 29.129h-11.267l-14.564-29.129zm4.713 2.913l11.651 23.303h4.754l-11.652-23.303h-4.753z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M66.784 179.259c-2.412 1.93-6.554 1.82-12.015 1.82 0-4.078 3.155-8.253 4.733-9.831h9.831c3.787 0 4.005 2.549 2.549 3.641-1.457 1.093-3.277 2.913-5.098 4.37z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M58.899 169.791h10.434c2.15 0 3.883.736 4.57 2.273.678 1.516.011 3.121-1.147 3.99-.669.501-1.44 1.184-2.315 1.968l-.138.124c-.819.732-1.712 1.531-2.609 2.249-1.532 1.226-3.505 1.721-5.62 1.945-1.895.201-4.096.198-6.464.196l-.841-.001h-1.457v-1.457c0-2.39.915-4.708 1.963-6.567 1.054-1.873 2.32-3.416 3.197-4.293l.427-.427zm1.23 2.913a18.709 18.709 0 00-2.316 3.236c-.664 1.178-1.192 2.441-1.438 3.683 2.072 0 3.853-.016 5.391-.179 1.924-.204 3.227-.619 4.108-1.323.833-.666 1.67-1.416 2.504-2.162l.12-.108c.854-.764 1.722-1.537 2.51-2.128.297-.223.25-.438.236-.47-.005-.011-.275-.549-1.911-.549h-9.205z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M293.262 155.955h4.733v16.749h-9.831c-2.33 0-2.427-1.942-2.185-2.913 1.093-3.034 3.496-9.539 4.37-11.287.874-1.748 2.306-2.428 2.913-2.549z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M292.976 154.526c.094-.018.19-.028.285-.028h4.734c.804 0 1.456.652 1.456 1.456v16.75c0 .804-.652 1.456-1.456 1.456h-9.831c-.785 0-1.497-.164-2.101-.522a3.192 3.192 0 01-1.274-1.407c-.478-1.015-.392-2.116-.223-2.794a1.41 1.41 0 01.043-.14c1.075-2.987 3.514-9.6 4.437-11.445 1.087-2.175 2.911-3.122 3.93-3.326zm.474 2.885c-.328.115-1.213.572-1.799 1.744-.818 1.635-3.151 7.936-4.272 11.047a1.767 1.767 0 00-.033.402c.006.174.04.304.079.388a.293.293 0 00.122.14c.063.037.237.115.617.115h8.374v-13.836h-3.088z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M297.995 163.966h-9.467v-2.913h9.467v2.913z"
          clipRule="evenodd"
        ></path>
        <g filter="url(#filter0_dd_3799_10423)">
          <circle cx="117" cy="116" r="55" fill="#fff"></circle>
          <circle
            cx="117"
            cy="116"
            r="53"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M91.683 128.188c0-10.416 9.393-24.526 19.716-29.575h10.755c10.327 5.057 19.717 19.167 19.717 29.582 0 12.728-11.242 18.825-25.102 18.825s-25.086-6.104-25.086-18.832zM116.912 98.607s-2.276-2.456-4.697-4.966a246.899 246.899 0 00-5.081-5.12 1.793 1.793 0 011.509-3.057 57.854 57.854 0 0016.539 0 1.791 1.791 0 012.025 2.084 1.79 1.79 0 01-.521.98 210.446 210.446 0 00-5.079 5.12c-2.417 2.503-4.695 4.959-4.695 4.959z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M120.864 94.423c.713-.627 1.472-1.2 2.27-1.714 3.143-1.455 4.572-.772 5.182.571.61 1.343.196 2.868-2.942 4.302a16.214 16.214 0 01-6.237 1.022h-2.225s3.269-3.465 3.952-4.18zM116.912 98.607c.714.628 5.755 5.287 5.344 10.794M112.962 94.423a19.404 19.404 0 00-2.271-1.714c-3.143-1.455-4.571-.772-5.182.571-.61 1.343-.195 2.868 2.941 4.302a16.218 16.218 0 006.239 1.022h2.223s-3.267-3.465-3.95-4.18zM116.912 98.607c-.714.628-5.753 5.287-5.342 10.794"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M123.2 132.352a2.142 2.142 0 00-.139.001h-2.05a6.933 6.933 0 01-2.804-.595h.046a3.675 3.675 0 002.552-1.122 3.71 3.71 0 00.498-4.51 3.685 3.685 0 003.144-2.26 3.722 3.722 0 000-2.861 3.698 3.698 0 00-2.044-2.01 3.659 3.659 0 00-1.414-.258h-2.78a6.94 6.94 0 012.801-.593s0 0 0 0h2.14l.05-.001a3.675 3.675 0 002.552-1.121 3.708 3.708 0 000-5.154 3.671 3.671 0 00-2.552-1.121l-.05-.001h-2.144a14.31 14.31 0 00-8.131 2.551 14.478 14.478 0 00-4.709 5.442h-1.523a3.677 3.677 0 00-3.458 2.268 3.718 3.718 0 000 2.861 3.689 3.689 0 001.001 1.379 3.703 3.703 0 00-1.288 2.81c0 .491.098.977.287 1.431a3.689 3.689 0 003.458 2.268h1.523a14.47 14.47 0 004.71 5.441 14.313 14.313 0 008.129 2.552h2.145l.05-.001a3.675 3.675 0 002.552-1.121 3.71 3.71 0 000-5.154 3.671 3.671 0 00-2.552-1.121z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_3799_10423"
          width="142"
          height="142"
          x="46"
          y="53"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3799_10423"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_3799_10423"
            result="effect2_dropShadow_3799_10423"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_3799_10423"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationRentalCar;
