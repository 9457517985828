import React from 'react';
import { Box } from '@wegroup/design-system';

const Bullet: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Box
    {...props}
    data-test-id="DesignSystem_RadioButton_Bullet"
    bgColor="white"
    w="7"
    h="7"
    p="1"
    flexShrink={0}
    borderRadius="full"
    borderWidth="2px"
    borderStyle="solid"
    borderColor="gray.100"
    _checked={{
      borderColor: 'primary.500',
    }}
    _disabled={{
      opacity: '0.3',
    }}
    transition="all 0.1s"
  >
    <Box
      {...props}
      w="100%"
      h="100%"
      borderRadius="100%"
      bgColor="white"
      _checked={{
        bgColor: 'primary.500',
      }}
      _disabled={{
        opacity: '0.3',
      }}
      transition="all 0.1s"
    />
  </Box>
);

export default Bullet;
