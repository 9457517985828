import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationHomeTheft: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3770_10755"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0-.001h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3770_10755)">
        <path
          fill={colors.primary[50]}
          d="M337 160.999c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.635 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M207.954 86.204l-55.345 54.486 10.485 10.484 55.86-55.688c8.129-10.571 10.168-16.652 7.907-27.845-.903-4.469 3.781-7.219 7.563-5.156l17.015 11.516-4.812-12.547 12.375.515-19.078-13.062c-13.751-7.22-29.563 7.562-27.501 19.25 1.916 7.893.669 11.825-4.469 18.047z"
        ></path>
        <mask id="path-5-inside-1_3770_10755" fill="#fff">
          <path
            fillRule="evenodd"
            d="M96.122 184.641l-.009.009 22.244 22.244.008-.008a7.778 7.778 0 0010.998-10.998l31.014-31.013c6.142-6.143 6.142-16.102 0-22.245-6.143-6.142-16.102-6.142-22.245 0l-31.015 31.015a7.779 7.779 0 00-10.995 10.996z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M96.122 184.641l-.009.009 22.244 22.244.008-.008a7.778 7.778 0 0010.998-10.998l31.014-31.013c6.142-6.143 6.142-16.102 0-22.245-6.143-6.142-16.102-6.142-22.245 0l-31.015 31.015a7.779 7.779 0 00-10.995 10.996z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M96.113 184.65l-2.829-2.828-2.828 2.828 2.829 2.828 2.828-2.828zm.01-.009l2.828 2.828 2.749-2.75-2.67-2.826-2.908 2.748zm22.234 22.253l-2.828 2.829 2.828 2.828 2.829-2.828-2.829-2.829zm.008-.008l2.783-2.872-2.827-2.74-2.784 2.784 2.828 2.828zm10.998-10.998l-2.828-2.828-2.784 2.784 2.739 2.828 2.873-2.784zm31.014-31.013l-2.829-2.829 2.829 2.829zm0-22.245l-2.829 2.829 2.829-2.829zm-22.245 0l-2.828-2.828 2.828 2.828zm-31.015 31.015l-2.747 2.908 2.826 2.671 2.75-2.75-2.829-2.829zm-10.839.156l2.828 2.829-2.828-2.829zm2.664 13.677l.009-.009-5.657-5.657-.01.01 5.658 5.656zm22.244 16.588l-22.245-22.245-5.656 5.657 22.244 22.245 5.657-5.657zm-5.649-.008l-.008.008 5.657 5.657.007-.008-5.656-5.657zm10.91-.088a3.779 3.779 0 01-5.299.044l-5.566 5.745c4.61 4.467 11.967 4.423 16.522-.132l-5.657-5.657zm.043-5.298a3.777 3.777 0 01-.043 5.298l5.657 5.657c4.555-4.555 4.599-11.912.132-16.522l-5.746 5.567zm31.058-36.626l-31.013 31.014 5.657 5.657 31.013-31.014-5.657-5.657zm0-16.587c4.581 4.58 4.581 12.007 0 16.587l5.657 5.657c7.705-7.705 7.705-20.196 0-27.901l-5.657 5.657zm-16.587 0c4.58-4.581 12.007-4.581 16.587 0l5.657-5.657c-7.705-7.705-20.196-7.705-27.901 0l5.657 5.657zm-31.015 31.015l31.015-31.015-5.657-5.657-31.015 31.015 5.657 5.657zm-10.84.156a3.778 3.778 0 015.264-.077l5.495-5.815c-4.617-4.363-11.895-4.285-16.415.235l5.656 5.657zm-.077 5.263a3.778 3.778 0 01.078-5.263l-5.657-5.657c-4.52 4.52-4.599 11.798-.235 16.415l5.814-5.495z"
          mask="url(#path-5-inside-1_3770_10755)"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M141.685 142.475l18.968 18.968"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationHomeTheft;
