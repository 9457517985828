import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationOpportunities: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.25 9a.25.25 0 00-.25.25v9c0 .138.112.25.25.25h9a.25.25 0 00.25-.25v-9a.25.25 0 00-.25-.25h-9zM5 9.25A4.25 4.25 0 019.25 5h9a4.25 4.25 0 014.25 4.25v9a4.25 4.25 0 01-4.25 4.25h-9A4.25 4.25 0 015 18.25v-9zM9.25 31.5a.25.25 0 00-.25.25v9c0 .138.112.25.25.25h9a.25.25 0 00.25-.25v-9a.25.25 0 00-.25-.25h-9zM5 31.75a4.25 4.25 0 014.25-4.25h9a4.25 4.25 0 014.25 4.25v9A4.25 4.25 0 0118.25 45h-9A4.25 4.25 0 015 40.75v-9zM31.75 31.5a.25.25 0 00-.25.25v9c0 .138.112.25.25.25h9a.25.25 0 00.25-.25v-9a.25.25 0 00-.25-.25h-9zm-4.25.25a4.25 4.25 0 014.25-4.25h9A4.25 4.25 0 0145 31.75v9A4.25 4.25 0 0140.75 45h-9a4.25 4.25 0 01-4.25-4.25v-9zM27.5 13.75a2 2 0 012-2H43a2 2 0 110 4H29.5a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M36.25 5a2 2 0 012 2v13.5a2 2 0 11-4 0V7a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationOpportunities;
