import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilityChildren: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M41 22.5V27a8 8 0 01-8 8H15a8 8 0 01-8-8v-4.5a.5.5 0 01.5-.5h33a.5.5 0 01.5.5zM33.5 44a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM14.5 44a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM44 14.05a1.05 1.05 0 01-1 1.06h-1.89V19A1.06 1.06 0 0139 19v-5.5a.5.5 0 01.5-.5H43a1 1 0 011 1.05zM10.14 12.48l7.22 6.57A.54.54 0 0117 20H7.56a.55.55 0 01-.56-.58 12.93 12.93 0 012.32-6.86.54.54 0 01.82-.08zM20 7.55v9.75a.54.54 0 01-.91.4l-7.5-6.82a.55.55 0 010-.82A12.93 12.93 0 0119.43 7a.55.55 0 01.57.55z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilityChildren;
