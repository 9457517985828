import { useTranslation } from 'react-i18next';

interface Params {
  isLoading: boolean;
  query: string;
  customText?: string;
  requiredChars?: number;
}

const useNothingFoundText = ({
  isLoading,
  query,
  customText,
  requiredChars = 0,
}: Params): string => {
  const { t } = useTranslation();

  const getNothingFoundText = () => {
    if (isLoading) return `${t('LOADING')}...`;
    if (customText) return t(customText);
    if (query.length >= requiredChars) return t('NO-RESULTS-FOUND');
    return t('TYPE-TO-SEARCH');
  };

  return getNothingFoundText();
};

export default useNothingFoundText;
