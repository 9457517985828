import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceLegalTaxAssistance: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_5248_12346"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_5248_12346)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M131.814 213.345a15.999 15.999 0 0114.311-8.845h63.75a15.999 15.999 0 0114.311 8.845l5.131 10.261c.665 1.329-.302 2.894-1.789 2.894h-99.056c-1.487 0-2.454-1.565-1.789-2.894l5.131-10.261z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M165.103 204.5l6.799-135h12.196l6.799 135h-25.794z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M97 98.5l28 55.5M100 98.5L72 154"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M98 175.5c-18.559 0-32.951-11.423-33.945-25h67.89c-.994 13.577-15.386 25-33.945 25z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M257 98.5l28 55.5M260 98.5L232 154"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M258 175.5c-18.559 0-32.951-11.423-33.945-25h67.89c-.994 13.577-15.386 25-33.945 25z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M181.491 66.235a4 4 0 01-3.726 4.256c-7.386.49-13.037 1.369-18.793 3.196-5.788 1.837-11.851 4.687-20.004 9.295a936.675 936.675 0 00-8.401 4.827c-5.063 2.931-9.886 5.725-14.225 7.99C109.789 99.22 103.356 102 98 102a3.11 3.11 0 01-.193-.005c-3.1-.149-5.87-.576-8.556-1.887-2.665-1.299-4.894-3.295-7.249-5.96a4 4 0 115.996-5.296c2.044 2.313 3.454 3.43 4.76 4.066 1.266.618 2.773.951 5.332 1.082 3.145-.034 7.921-1.832 14.549-5.293 4.197-2.19 8.745-4.825 13.687-7.688 2.775-1.608 5.674-3.287 8.706-5.001 8.372-4.733 14.979-7.88 21.52-9.956 6.572-2.086 12.908-3.037 20.683-3.553a4 4 0 014.256 3.726z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M174.009 66.235a4 4 0 003.726 4.256c7.386.49 13.037 1.369 18.793 3.196 5.788 1.837 11.851 4.687 20.004 9.295a936.675 936.675 0 018.401 4.827c5.063 2.931 9.886 5.725 14.225 7.99C245.711 99.22 252.144 102 257.5 102c.064 0 .129-.001.193-.005 3.1-.149 5.87-.576 8.556-1.887 2.665-1.299 4.894-3.295 7.249-5.96a4 4 0 00-5.996-5.296c-2.044 2.313-3.454 3.43-4.759 4.066-1.267.618-2.774.951-5.333 1.082-3.145-.034-7.921-1.832-14.549-5.293-4.197-2.19-8.745-4.825-13.687-7.688-2.775-1.608-5.674-3.287-8.706-5.001-8.372-4.733-14.979-7.88-21.52-9.956-6.572-2.086-12.908-3.037-20.683-3.553a4 4 0 00-4.256 3.726z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M165 65s5-30 13-30 13 30 13 30h-26z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M165 65l-1.973-.329A2.002 2.002 0 00165 67v-2zm26 0v2a2 2 0 001.973-2.329L191 65zm-13-32c-1.664 0-3.076.786-4.201 1.81-1.12 1.018-2.094 2.39-2.945 3.892-1.705 3.007-3.13 6.926-4.27 10.723-1.146 3.822-2.034 7.63-2.635 10.474a149.41 149.41 0 00-.861 4.418l-.045.26-.011.068-.004.019v.005l-.001.001L165 65l1.973.329v-.004a.091.091 0 00.003-.014l.01-.06.042-.242a141.883 141.883 0 01.835-4.283c.587-2.781 1.449-6.473 2.553-10.151 1.11-3.703 2.435-7.284 3.918-9.902.743-1.31 1.472-2.282 2.156-2.904.679-.618 1.174-.769 1.51-.769v-4zm0 4c.336 0 .831.151 1.51.769.684.622 1.413 1.593 2.156 2.904 1.483 2.618 2.808 6.199 3.918 9.902 1.104 3.678 1.966 7.37 2.553 10.15a141.883 141.883 0 01.835 4.284l.042.242.01.06a.091.091 0 00.003.014v.004L191 65l1.973-.33-.001-.001v-.005l-.004-.019-.011-.068-.045-.26a136.769 136.769 0 00-.861-4.418c-.601-2.844-1.489-6.652-2.635-10.474-1.14-3.797-2.565-7.716-4.27-10.723-.851-1.502-1.825-2.874-2.945-3.893C181.076 33.786 179.664 33 178 33v4zm13 26h-13v4h13v-4zm-26 4h13v-4h-13v4z"
        ></path>
        <circle
          cx="178"
          cy="66.5"
          r="13"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <g filter="url(#filter0_dd_5248_12346)">
          <circle cx="178.5" cy="131.5" r="41.5" fill="#fff"></circle>
          <circle
            cx="178.5"
            cy="131.5"
            r="39.5"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M159.397 140.696c0-7.859 7.088-18.505 14.877-22.315h8.115c7.792 3.815 14.877 14.462 14.877 22.32 0 9.605-8.482 14.205-18.94 14.205-10.458 0-18.929-4.606-18.929-14.21zM178.433 118.376s-1.717-1.853-3.544-3.747a182.86 182.86 0 00-3.834-3.862 1.357 1.357 0 01-.268-1.567 1.356 1.356 0 011.407-.74 43.701 43.701 0 0012.479 0 1.353 1.353 0 011.135 2.312 159.448 159.448 0 00-3.832 3.863c-1.824 1.888-3.543 3.741-3.543 3.741z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M181.416 115.22a14.643 14.643 0 011.713-1.293c2.372-1.098 3.45-.582 3.91.431.461 1.013.148 2.164-2.219 3.246a12.247 12.247 0 01-4.707.771h-1.678s2.466-2.614 2.981-3.155zM178.435 118.377c.539.473 4.342 3.989 4.031 8.144M175.453 115.22a14.643 14.643 0 00-1.713-1.293c-2.372-1.098-3.45-.582-3.91.431-.461 1.013-.148 2.164 2.218 3.246 1.504.563 3.103.825 4.708.771h1.678s-2.466-2.614-2.981-3.155zM178.434 118.377c-.539.473-4.342 3.989-4.031 8.144"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M183.189 143.347c-.042-.001-.085 0-.127.001l-13.626.302c.398.158.824.236 1.252.23h.847c.852 1.6 2.074 2.98 3.573 4.018a11.282 11.282 0 006.413 2.012h1.668a3.264 3.264 0 002.267-.996l-1.438-1.39 1.438 1.39a3.287 3.287 0 000-4.571l-1.038 1.004 1.038-1.004a3.268 3.268 0 00-2.267-.996zm-.728-3.837c.1-.029.199-.063.296-.102h0a3.267 3.267 0 001.814-1.784h0a3.288 3.288 0 000-2.536h0a3.282 3.282 0 00-.725-1.074l-1.404 1.424 1.404-1.424a3.28 3.28 0 00-.513-.413 3.26 3.26 0 002.123-.989 3.288 3.288 0 000-4.571 3.263 3.263 0 00-2.267-.995l-.049-.001h-1.619a11.282 11.282 0 00-6.413 2.012 11.4 11.4 0 00-3.573 4.019h-.847a3.251 3.251 0 00-2.34.939h0a3.28 3.28 0 00-.98 2.342 3.29 3.29 0 00.774 2.121 3.29 3.29 0 001.294 5.172l13.025-4.14z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_5248_12346"
          width="115"
          height="115"
          x="121"
          y="82"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5248_12346"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_5248_12346"
            result="effect2_dropShadow_5248_12346"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_5248_12346"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationInsuranceLegalTaxAssistance;
