import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskAnalysisSubjectsHealth: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.05 9.891a5.29 5.29 0 013.74-1.549h1.894a1.5 1.5 0 010 3H10.79a2.29 2.29 0 00-2.29 2.29v6.631a8.921 8.921 0 1017.842 0v-6.632a2.29 2.29 0 00-2.29-2.289h-1.894a1.5 1.5 0 110-3h1.895a5.29 5.29 0 015.29 5.29v6.631a11.921 11.921 0 01-23.843 0v-6.632a5.29 5.29 0 011.55-3.74z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M39.21 23.5a1.5 1.5 0 011.5 1.5v5.684a12.868 12.868 0 11-25.736 0 1.5 1.5 0 013 0 9.867 9.867 0 1019.737 0V25a1.5 1.5 0 011.5-1.5zM22.158 6.447a1.5 1.5 0 011.5 1.5v3.79a1.5 1.5 0 11-3 0v-3.79a1.5 1.5 0 011.5-1.5zM12.684 6.447a1.5 1.5 0 011.5 1.5v3.79a1.5 1.5 0 11-3 0v-3.79a1.5 1.5 0 011.5-1.5z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M39.21 18.92a2.29 2.29 0 100 4.58 2.29 2.29 0 000-4.58zm-5.289 2.29a5.29 5.29 0 1110.579 0 5.29 5.29 0 01-10.579 0z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisSubjectsHealth;
