import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBargeBoat: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_1657_14939"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_1657_14939)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M65.744 188.715v13.244c0 1.055.82 1.929 1.875 1.996l45.939 2.887a2 2 0 002.125-1.996v-16.131a2 2 0 00-2-2H67.744a2 2 0 00-2 2z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M74.02 193.278h9.987M97.42 193.278h9.988M208.998 208.117V31.19"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M324 196.132c-114.681 7.541-178.738 7.555-292.5 0l34.244 30.249h224.012L324 196.132zM289.756 194.99c-22.891-67.445-40.827-102.32-77.048-162.088 6.223 59.155 9.496 92.34 0 152.956l77.048 9.132z"
        ></path>
        <mask
          id="mask1_1657_14939"
          style={{ maskType: 'alpha' }}
          width="82"
          height="167"
          x="210"
          y="30"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M289.756 194.99c-22.891-67.445-40.827-102.32-77.048-162.088 6.223 59.155 9.496 92.34 0 152.956l77.048 9.132z"
          ></path>
        </mask>
        <g
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_1657_14939)"
        >
          <path
            d="M206.308 207.842H334.98699999999997V238.657H206.308z"
            transform="rotate(-30 206.308 207.842)"
          ></path>
          <path
            d="M179.069 148.802H339.2V179.617H179.069z"
            transform="rotate(-30 179.069 148.802)"
          ></path>
          <path
            d="M151.83 89.763H311.961V120.578H151.83z"
            transform="rotate(-30 151.83 89.763)"
          ></path>
        </g>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M289.756 194.99c-22.891-67.445-40.827-102.32-77.048-162.088 6.223 59.155 9.496 92.34 0 152.956l77.048 9.132zM208.997 185.288h-89.604"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M125.956 185.288h83.042c-13.435-56.136-12.478-87.664 0-144.11l-60.783-9.132c-14.545 59.582-19.935 93.109-22.259 153.242z"
        ></path>
        <mask
          id="mask2_1657_14939"
          style={{ maskType: 'alpha' }}
          width="88"
          height="158"
          x="123"
          y="30"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M125.956 185.288h83.042c-13.435-56.136-12.478-87.664 0-144.11l-60.783-9.132c-14.545 59.582-19.935 93.109-22.259 153.242z"
          ></path>
        </mask>
        <g
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask2_1657_14939)"
        >
          <path
            d="M132.382 11.664H236.821V42.479H132.382z"
            transform="rotate(30 132.382 11.664)"
          ></path>
          <path
            d="M132.382 83.576H236.821V114.39099999999999H132.382z"
            transform="rotate(30 132.382 83.576)"
          ></path>
          <path
            d="M111.39 143.368H240.06900000000002V174.183H111.39z"
            transform="rotate(30 111.39 143.368)"
          ></path>
        </g>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M125.956 185.288h83.042c-13.435-56.136-12.478-87.664 0-144.11l-60.783-9.132c-14.545 59.582-19.935 93.109-22.259 153.242zM139.939 30.62l69.059 10.558"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBargeBoat;
