import { AnyAction, combineReducers } from '@reduxjs/toolkit';

import productFactorySlice, {
  resetProductFactory,
} from '../slices/productFactorySlice';
import offersSlice from '../slices/offersSlice';
import selectionSlice from '../slices/selectionSlice';
import riskAnalysisSelectionSlice from '../../../../../risk-analysis/src/store/redux/slices/selectionSlice';
import recommendationSlice from '../../../../../risk-analysis/src/store/redux/slices/recommendationSlice';

const appReducer = combineReducers({
  productFactory: productFactorySlice,
  offers: offersSlice,
  selection: selectionSlice,
  riskAnalysisSelection: riskAnalysisSelectionSlice,
  recommendations: recommendationSlice,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction,
) => {
  // State should not be reset when Jest is running
  if (
    process.env.JEST_WORKER_ID === undefined &&
    ((!window.location.href.match(/.*\/product\/session.*/) &&
      !window.location.href.match(/.*\/risk-analysis.*/) &&
      !window.location.href.match(/.*\/flows.*/) &&
      !window.location.href.match(/.*\/sales-conversation.*/)) ||
      action.type === resetProductFactory.type)
  ) {
    state = undefined;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
