import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';

const IllustrationPersonal: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="180"
      fill="none"
      viewBox="0 0 180 180"
      {...otherProps}
    >
      <circle cx="90" cy="90" r="90" fill="#E2DBE9"></circle>
      <mask id="path-2-inside-1_1306_20045" fill="#fff">
        <rect width="100.5" height="105" x="40.5" y="37.5" rx="2"></rect>
      </mask>
      <rect
        width="100.5"
        height="105"
        x="40.5"
        y="37.5"
        fill="#fff"
        stroke="#6F558A"
        strokeWidth="6"
        mask="url(#path-2-inside-1_1306_20045)"
        rx="2"
      ></rect>
      <mask id="path-3-inside-2_1306_20045" fill="#fff">
        <rect width="76.5" height="70.5" x="52.5" y="49.5" rx="1"></rect>
      </mask>
      <rect
        width="76.5"
        height="70.5"
        x="52.5"
        y="49.5"
        fill="#BCACCC"
        stroke="#6F558A"
        strokeWidth="6"
        mask="url(#path-3-inside-2_1306_20045)"
        rx="1"
      ></rect>
      <mask
        id="mask0_1306_20045"
        style={{ maskType: 'alpha' }}
        width="77"
        height="72"
        x="52"
        y="49"
        maskUnits="userSpaceOnUse"
      >
        <mask id="path-4-inside-3_1306_20045" fill="#fff">
          <rect width="76.5" height="70.5" x="52.5" y="49.5" rx="1"></rect>
        </mask>
        <rect
          width="76.5"
          height="70.5"
          x="52.5"
          y="49.5"
          fill="#FFE6CC"
          stroke="#FF8000"
          strokeWidth="6"
          mask="url(#path-4-inside-3_1306_20045)"
          rx="1"
        ></rect>
      </mask>
      <g mask="url(#mask0_1306_20045)">
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#6F558A"
          strokeWidth="3"
          d="M97.62 97.954c5.619 1.322 9.274 3.148 13.261 6.625 3.437 2.998 5.135 5.47 6.479 9.433.455 1.345.478 2.004.556 16.067.08 14.563.077 14.671-.412 15.649-.911 1.824-2.715 2.834-5.059 2.834-1.684 0-2.787-.401-3.937-1.434-1.513-1.359-1.466-.887-1.466-14.566 0-6.824-.063-12.551-.141-12.725-.082-.184-.413-.317-.789-.317-.377 0-.707.133-.79.317-.077.174-.141 5.815-.141 12.535v12.218l-1.743 18.861c-.959 10.373-1.845 19.389-1.97 20.034-.534 2.757-2.99 4.806-6.422 5.357-2.003.322-7.824.121-9.24-.319-1.513-.469-3.491-2.067-4.234-3.419-.58-1.056-.652-1.67-2.453-20.962l-1.855-19.873v-12.057c0-6.632-.063-12.201-.14-12.375-.199-.444-1.19-.396-1.48.071-.158.255-.24 4.603-.24 12.725 0 13.6.046 13.136-1.467 14.495-1.262 1.134-2.32 1.486-4.212 1.401-1.407-.062-1.812-.17-2.75-.727-.608-.363-1.387-1.076-1.744-1.599l-.645-.945-.071-13.52c-.046-8.841.016-14.175.182-15.412 1.097-8.214 8.876-15.562 19.198-18.137 3.076-.767 4.277-.885 8.142-.802 2.791.06 3.909.173 5.583.567z"
          clipRule="evenodd"
        ></path>
        <rect
          width="20.338"
          height="25.524"
          x="81"
          y="66"
          fill="#fff"
          stroke="#6F558A"
          strokeWidth="3"
          rx="10.169"
        ></rect>
        <mask id="path-7-inside-4_1306_20045" fill="#fff">
          <rect width="76.5" height="70.5" x="52.5" y="49.5" rx="1"></rect>
        </mask>
        <rect
          width="76.5"
          height="70.5"
          x="52.5"
          y="49.5"
          stroke="#6F558A"
          strokeWidth="6"
          mask="url(#path-7-inside-4_1306_20045)"
          rx="1"
        ></rect>
      </g>
    </svg>
  );
};

export default IllustrationPersonal;
