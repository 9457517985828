import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleCityEmergencyBraking: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M24.5 27.64a1.861 1.861 0 11-1.86 1.86 1.869 1.869 0 011.86-1.86zm0-4.64a6.5 6.5 0 100 13 6.5 6.5 0 000-13z"
    ></path>
    <path
      fill="currentColor"
      d="M41 29.87a16.171 16.171 0 01-4.187 10.821 16.156 16.156 0 01-27.8-7.603.77.77 0 01.77-.909h1.538c.554 0 .862.255.939.609a12.943 12.943 0 009.035 9.537 12.93 12.93 0 0012.717-3.287 12.942 12.942 0 00-6.247-21.763.769.769 0 01-.616-.77V14.79a.77.77 0 01.94-.77 16.158 16.158 0 019.278 5.624A16.171 16.171 0 0141 29.87z"
    ></path>
    <path
      fill="currentColor"
      d="M24.227 13A17 17 0 008 29.227a.773.773 0 00.773.773h3.268a.773.773 0 00.773-.726 12.194 12.194 0 0111.46-11.46.772.772 0 00.726-.773v-3.268a.773.773 0 00-.773-.773zM15.865 7.886l-.178-2.039c-4.581.693-8.738 3.516-11.114 7.557a15.062 15.062 0 00-1.71 4.241l2.022.34a13.013 13.013 0 011.464-3.63c2.04-3.465 5.596-5.881 9.516-6.47z"
    ></path>
    <path
      fill="currentColor"
      d="M9.32 16.385c1.592-2.707 4.378-4.597 7.448-5.06l-.178-2.038c-3.73.56-7.114 2.861-9.051 6.151a12.365 12.365 0 00-1.393 3.458l2.024.33c.23-.985.62-1.94 1.15-2.841zM33.086 7.96l.091-2.045c4.607.497 8.88 3.14 11.427 7.075a15.062 15.062 0 011.89 4.164l-2.006.425a13.014 13.014 0 00-1.618-3.563c-2.186-3.375-5.842-5.636-9.784-6.056z"
    ></path>
    <path
      fill="currentColor"
      d="M39.99 16.171c-1.707-2.636-4.572-4.405-7.658-4.736l.09-2.045c3.751.4 7.23 2.555 9.306 5.759a12.364 12.364 0 011.54 3.395l-2.008.417a10.22 10.22 0 00-1.27-2.79z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleCityEmergencyBraking;
