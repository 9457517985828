import { Image } from '@wegroup/design-system';
import React from 'react';

const WeGroupLogo: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <Image
      src="https://files.wegroup.be/logos/wegroup/wegroup-logo.svg"
      alt="WeGroup Logo"
      w="100%"
      h="100%"
    />
  );
};

export default WeGroupLogo;
