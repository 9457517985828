import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersMoney: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M35.89 20H14.11A6.75 6.75 0 0110 24.2v4.61A6.75 6.75 0 0114.11 33h21.78A6.75 6.75 0 0140 28.8v-4.6a6.75 6.75 0 01-4.11-4.2zM25 30a4 4 0 110-8 4 4 0 010 8z"
    ></path>
    <path
      fill="currentColor"
      d="M4.88 15v23h40.24V15H4.88zM42 30.36A4.78 4.78 0 0037.47 35H12.53A4.78 4.78 0 008 30.36v-7.72A4.78 4.78 0 0012.53 18h24.94A4.78 4.78 0 0042 22.64v7.72z"
    ></path>
  </Icon>
);

export default GeneralOthersMoney;
