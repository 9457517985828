import React from 'react';
import { Box, SlideFade, IconButton, Icon } from '@wegroup/design-system';

interface Props {
  direction: 'left' | 'right';
  isVisible: boolean;
  onClick: () => void;
}

const TabListScrollButton: React.FC<Props> = ({
  isVisible,
  direction,
  onClick,
}) => {
  const isLeft = direction === 'left';
  return (
    <Box
      position="absolute"
      zIndex={1}
      left={isLeft ? '2' : 'unset'}
      right={isLeft ? 'unset' : '2'}
      top="50%"
      transform="translateY(-50%)"
    >
      <SlideFade
        in={isVisible}
        offsetX={isLeft ? -20 : 20}
        offsetY={0}
        unmountOnExit
      >
        <IconButton
          aria-label="scrollRight"
          icon={
            <Icon
              name={isLeft ? 'IconActionChevronLeft' : 'IconActionChevronRight'}
              boxSize="5"
            />
          }
          borderRadius="full"
          variant="white"
          size="sm"
          onClick={onClick}
        />
      </SlideFade>
    </Box>
  );
};

export default TabListScrollButton;
