import { FormOption } from '../../../../types/Form';
import {
  FlowQuestionRules,
  FlowsQuestion,
  FlowsQuestionAnswer,
  FlowsQuestionnaire,
} from '../types/FlowsTypes';

/**
 * Looks for the group and question index of the given question
 * WARNING: DOES NOT TAKE INTO ACCOUNT INDEX OF THE QUESTIONS
 * @param questionId The question id to search
 * @param questionnaire The questionnaire to search in
 * @returns An array like this: [groupIndex, questionIndex]
 */
export const getGroupAndQuestionIndexByQuestionId = (
  questionnaire: FlowsQuestionnaire,
  questionId: string,
): [number, number] => {
  let questionIndex = -1;
  const groupIndex = questionnaire.groups.findIndex(({ questions }) => {
    const localQuestionId = questions.findIndex(({ id }) => id === questionId);
    if (localQuestionId > -1) questionIndex = localQuestionId;
    return localQuestionId > -1;
  });

  return [groupIndex, questionIndex];
};

/**
 * Looks for the index and group for the given questions
 * @param questionnaire The questionnaire to search in
 * @param groupId The group id to search
 * @param questionId The question id to search
 * @returns An array like this: [groupIndex, questionIndex]. If it returns -1 for any items in the array it means it was not found.
 */
export const getGroupAndQuestionIndex = (
  questionnaire: FlowsQuestionnaire,
  groupId: string,
  questionId: string,
  index?: number,
): [number, number] => {
  const groupIndex = questionnaire.groups.findIndex(
    ({ id, index: _index }) =>
      id === groupId && (index == null || index === _index),
  );
  const questionIndex = questionnaire.groups[groupIndex].questions.findIndex(
    ({ id, index: _index }) =>
      id === questionId && (index == null || index === _index),
  );

  return [groupIndex, questionIndex];
};

/**
 * Looks for the index of the given group
 * @param questionnaire The questionnaire to search in
 * @param groupId The group id to search
 * @param index Optional: Index of the group to search
 * @returns The index of the group. If it returns -1 it means it was not found.
 */
export const getGroupIndex = (
  questionnaire: FlowsQuestionnaire,
  groupId: string,
  index?: number,
): number => {
  const groupIndex = questionnaire.groups.findIndex(
    ({ id, index: _index }) =>
      id === groupId && (index == null || index === _index),
  );
  return groupIndex;
};

/**
 *
 * @param answers The list of answers to search in
 * @param questionId The question id to search
 * @param questionIndex Optional: Index of the question to search
 * @returns The index of the question.  If it returns -1 it means it was not found.
 */
export const getIndexOfAnswerForQuestionId = (
  answers: FlowsQuestionAnswer[],
  questionId: string,
  questionIndex?: number,
): number => {
  return answers?.findIndex(
    (item) =>
      item.id === questionId &&
      (questionIndex == null || item.index === questionIndex),
  );
};

/**
 * Gets all the questions for the current questionnaire in
 * a simple arary.
 * @param questionnaire The questionnaire to be flattened
 * @returns A flattened questions array
 */
export const flattenQuestionaire = (
  questionnaire: FlowsQuestionnaire,
): FlowsQuestion[] => {
  if (questionnaire.groups.some((group) => group.groups)) {
    return questionnaire.groups
      .map(({ groups }) =>
        (groups?.map(({ questions }) => questions) || []).flat(),
      )
      .flat();
  }
  return questionnaire.groups.map(({ questions }) => questions).flat();
};

/**
 * Sorts the list of options depending on the rule that is given
 * @returns A sorted list of options ('OTHER' will always be last)
 */
export const getSortedQuestionOptions = (
  options: FormOption[],
  rule: FlowQuestionRules['sort'],
): FormOption[] => {
  if (rule === 'asc') {
    return options.sort((a, b) => {
      // Other should always be shown as last option
      if (a.value === 'OTHER') return 1;
      return a.label.localeCompare(b.label);
    });
  } else if (rule === 'desc') {
    return options.sort((a, b) => {
      if (a.value === 'OTHER') return -1;
      return b.label.localeCompare(a.label);
    });
  }

  return options;
};

/**
 * Will check if the index is equal and if it is defined.
 * The order of the parameters matters here
 * @param sourceIndex The index where the comparison is coming from. This will mostly come from functions triggered by the user.
 * @param targetIndex The index that is to be checked. This is mostly the index of a question or answers that appears in the redux state.
 * @returns `false` if the indices are not equal. `true` if the indices are equal or if the sourceIndex is undefined.
 */
export const isIndexEqual = (
  sourceIndex?: number,
  targetIndex?: number,
): boolean => {
  return sourceIndex == null || sourceIndex === targetIndex;
};
