import useIsAuthorized from '../../hooks/useIsAuthorized';
import { isSocialLoginUrl } from '../../constants/SocialAuth';
import { ssoApi } from '../../../api/ssoApi';
import { UserInfo } from '../../types/UserInfo';
import { useQuery, UseQueryResult } from 'react-query';

const getUserInfo = async () => {
  const { data, isError } = await ssoApi.get<UserInfo>('userinfo');
  if (isError) throw new Error();
  return data;
};

export const getUserInfoQKey = (): string[] => ['user', 'info'];

const USER_INFO_REFETCH_INTERVAL = 30 * 60 * 1000;

export const useUserInfo = (): UseQueryResult<UserInfo> => {
  const isAuthorized = useIsAuthorized();

  return useQuery<UserInfo>(getUserInfoQKey(), getUserInfo, {
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: USER_INFO_REFETCH_INTERVAL,
    enabled: isAuthorized && !isSocialLoginUrl,
  });
};
