import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusDocsRejected: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      d="M46.452 38.452a1.87 1.87 0 0 1-2.645 0L39 33.645l-4.807 4.807a1.87 1.87 0 1 1-2.645-2.645L36.355 31l-4.807-4.807a1.87 1.87 0 1 1 2.645-2.645L39 28.355l4.807-4.807a1.87 1.87 0 1 1 2.645 2.645L41.645 31l4.807 4.807c.73.73.73 1.915 0 2.645Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 9a2 2 0 0 0-2 2v29a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2v-1.234l2-2 2 2V40a6 6 0 0 1-6 6H15a6 6 0 0 1-6-6V11a6 6 0 0 1 6-6h12.586a3 3 0 0 1 2.121.879l10.414 10.414A3 3 0 0 1 41 18.414v4.82l-2 2-2-2V21h-7a4 4 0 0 1-4-4V9H15Zm20.172 8H30v-5.172L35.172 17Z"
      fill="currentColor"
    />
  </Icon>
);

export default GeneralStatusDocsRejected;
