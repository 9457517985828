import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationFamilyWithChildren: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161.001c0 88.366-71.634 160-160 160s-160-71.634-160-160 71.634-160 160-160 160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M162.228 100.965c6.485 1.773 10.703 4.22 15.305 8.882 3.967 4.019 5.927 7.334 7.477 12.647.526 1.802.553 2.686.642 21.539.093 19.524.089 19.668-.475 20.979-1.051 2.445-3.134 3.8-5.838 3.8-1.945 0-3.218-.538-4.545-1.923-1.746-1.822-1.692-1.19-1.692-19.527 0-9.149-.073-16.826-.163-17.059-.094-.247-.476-.425-.911-.425-.434 0-.816.178-.911.425-.089.233-.163 7.795-.163 16.804v16.38l-2.012 25.285c-1.106 13.907-2.13 25.993-2.274 26.858-.616 3.697-3.45 6.443-7.411 7.182-2.312.432-9.031.162-10.666-.428-1.746-.629-4.029-2.77-4.886-4.583-.67-1.417-.753-2.239-2.831-28.102l-2.141-26.643v-16.164c0-8.891-.074-16.356-.163-16.589-.229-.595-1.372-.532-1.708.094-.182.342-.277 6.172-.277 17.059 0 18.233.054 17.612-1.692 19.433-1.458 1.521-2.679 1.992-4.863 1.879-1.623-.084-2.091-.228-3.173-.976-.703-.485-1.601-1.442-2.014-2.143l-.744-1.267-.082-18.125c-.053-11.852.019-19.003.21-20.662 1.267-11.011 10.245-20.863 22.158-24.314 3.551-1.029 4.938-1.188 9.398-1.076 3.222.08 4.512.232 6.445.76Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <rect
          x={141.372}
          y={57}
          width={27.11}
          height={34.024}
          rx={13.555}
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M209.228 100.965c6.485 1.773 10.703 4.22 15.305 8.882 3.967 4.019 5.927 7.334 7.477 12.647.526 1.802.553 2.686.642 21.539.093 19.524.089 19.668-.475 20.979-1.051 2.445-3.134 3.8-5.838 3.8-1.945 0-3.218-.538-4.545-1.923-1.746-1.822-1.692-1.19-1.692-19.527 0-9.149-.073-16.826-.163-17.059-.094-.247-.476-.425-.911-.425-.434 0-.816.178-.911.425-.089.233-.163 7.795-.163 16.804v16.38l-2.012 25.285c-1.106 13.907-2.13 25.993-2.274 26.858-.616 3.697-3.45 6.443-7.411 7.182-2.312.432-9.031.162-10.666-.428-1.746-.629-4.029-2.77-4.886-4.583-.67-1.417-.753-2.239-2.831-28.102l-2.141-26.643v-16.164c0-8.891-.074-16.356-.163-16.589-.229-.595-1.372-.532-1.708.094-.182.342-.277 6.172-.277 17.059 0 18.233.054 17.612-1.692 19.433-1.458 1.521-2.679 1.992-4.863 1.879-1.623-.084-2.091-.228-3.173-.976-.703-.485-1.601-1.442-2.014-2.143l-.744-1.267-.082-18.125c-.053-11.852.019-19.003.21-20.662 1.267-11.011 10.245-20.863 22.158-24.314 3.551-1.029 4.938-1.188 9.398-1.076 3.222.08 4.512.232 6.445.76Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <rect
          x={188.372}
          y={57}
          width={27.11}
          height={34.024}
          rx={13.555}
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H19a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M127.804 143.825c4.209 1.15 6.945 2.738 9.931 5.763 2.575 2.608 3.846 4.758 4.852 8.206.342 1.169.359 1.743.417 13.976.06 12.668.058 12.762-.308 13.613-.683 1.586-2.034 2.465-3.789 2.465-1.262 0-2.088-.349-2.949-1.248-1.133-1.182-1.097-.772-1.097-12.67 0-5.937-.048-10.918-.106-11.069-.062-.16-.309-.276-.591-.276s-.53.116-.591.276c-.059.151-.106 5.058-.106 10.904v10.628l-1.306 16.406c-.718 9.024-1.382 16.866-1.475 17.428-.4 2.398-2.239 4.18-4.809 4.66-1.5.28-5.86.105-6.921-.278-1.133-.408-2.614-1.798-3.17-2.974-.435-.919-.489-1.453-1.837-18.234l-1.39-17.288v-10.488c0-5.769-.047-10.613-.105-10.764-.148-.386-.891-.345-1.108.061-.119.222-.18 4.005-.18 11.069 0 11.831.035 11.427-1.098 12.609-.946.987-1.738 1.293-3.155 1.219-1.053-.054-1.357-.147-2.059-.632-.456-.316-1.039-.936-1.307-1.392l-.483-.822-.053-11.76c-.035-7.69.013-12.33.136-13.407.822-7.145 6.648-13.537 14.378-15.776 2.304-.668 3.204-.771 6.098-.699 2.09.052 2.927.151 4.181.494Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <rect
          x={114.974}
          y={119}
          width={16.186}
          height={20.672}
          rx={8.093}
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M239.804 143.825c4.209 1.15 6.945 2.738 9.931 5.763 2.575 2.608 3.846 4.758 4.852 8.206.342 1.169.359 1.743.417 13.976.06 12.668.058 12.762-.308 13.613-.683 1.586-2.034 2.465-3.789 2.465-1.262 0-2.088-.349-2.949-1.248-1.133-1.182-1.097-.772-1.097-12.67 0-5.937-.048-10.918-.106-11.069-.062-.16-.309-.276-.591-.276s-.53.116-.591.276c-.059.151-.106 5.058-.106 10.904v10.628l-1.306 16.406c-.718 9.024-1.382 16.866-1.475 17.428-.4 2.398-2.239 4.18-4.809 4.66-1.5.28-5.86.105-6.921-.278-1.133-.408-2.614-1.798-3.17-2.974-.435-.919-.489-1.453-1.837-18.234l-1.39-17.288v-10.488c0-5.769-.047-10.613-.105-10.764-.148-.386-.891-.345-1.108.061-.119.222-.18 4.005-.18 11.069 0 11.831.035 11.427-1.098 12.609-.946.987-1.738 1.293-3.155 1.219-1.053-.054-1.357-.147-2.059-.632-.456-.316-1.039-.936-1.307-1.392l-.483-.822-.053-11.76c-.035-7.69.013-12.33.136-13.407.822-7.145 6.648-13.537 14.378-15.776 2.304-.668 3.204-.771 6.098-.699 2.09.052 2.927.151 4.181.494Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <rect
          x={226.974}
          y={119}
          width={16.186}
          height={20.672}
          rx={8.093}
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
      </g>
    </svg>
  );
};

export default IllustrationFamilyWithChildren;
