import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsChevronLeftRounded: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M32.414 10.586a2 2 0 010 2.828L20.828 25l11.586 11.586a2 2 0 11-2.828 2.828l-13-13a2 2 0 010-2.828l13-13a2 2 0 012.828 0z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsChevronLeftRounded;
