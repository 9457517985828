import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsLegalGroup: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M38.5 26a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM11.5 26a4.5 4.5 0 100-9 4.5 4.5 0 000 9z"
    ></path>
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M11.5 26a4.5 4.5 0 100-9 4.5 4.5 0 000 9z"
    ></path>
    <path
      fill="currentColor"
      d="M20.882 25h8.236c1.56 0 3.056.766 4.16 2.13C34.38 28.494 35 30.344 35 32.273V41H15v-8.727c0-1.93.62-3.779 1.723-5.143 1.103-1.364 2.6-2.13 4.16-2.13zM12 41v-8.727c0-1.23.167-2.439.487-3.58H9.706c-1.248 0-2.445.589-3.328 1.638C5.496 31.38 5 32.803 5 34.287V41h7z"
    ></path>
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M12 41v-8.727c0-1.23.167-2.439.487-3.58H9.706c-1.248 0-2.445.589-3.328 1.638C5.496 31.38 5 32.803 5 34.287V41h7z"
    ></path>
    <path
      fill="currentColor"
      d="M38 41v-8.727c0-1.23-.167-2.439-.487-3.58h2.781c1.248 0 2.445.589 3.328 1.638.882 1.05 1.378 2.472 1.378 3.956V41h-7z"
    ></path>
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M38 41v-8.727c0-1.23-.167-2.439-.487-3.58h2.781c1.248 0 2.445.589 3.328 1.638.882 1.05 1.378 2.472 1.378 3.956V41h-7z"
    ></path>
    <path
      fill="currentColor"
      d="M25.5 22a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"
    ></path>
  </Icon>
);

export default RiskObjectsLegalGroup;
