import { getDistributionDataFromQStore } from '../../../app/src/features/authentication/api/get/getDistribution';
import { getUserDataFromQStore } from '../../../app/src/features/authentication/api/get/getUser';
import useAuth from '../../../app/src/features/authentication/hooks/useAuth';
import { Distribution } from '../../../app/src/features/authentication/types/Distribution';
import { User } from '../../../app/src/features/authentication/types/User';
import { isSocialLoginUrl } from '../../../sales-conversation/src/services/productFactoryService';

interface IsNLplan {
  isNL: boolean;
  country: 'BE' | 'NL';
}

const isNLPlan = (user: User, distribution: Distribution): IsNLplan => {
  const NLPlans = [
    'TRIAL_BROKER_NL',
    'ESSENTIAL_NL',
    'PIA_SUBAGENT_NL',
    'RISK_ANALYSIS_NL',
    'RISK_ANALYSIS_LOUISE_QA_NL',
  ];

  let isNL;

  if (isSocialLoginUrl) {
    isNL = NLPlans.includes(distribution.plan || '') || false;
  } else {
    isNL = NLPlans.includes(user?.plan || '') || false;
  }

  return {
    isNL,
    country: isNL ? 'NL' : 'BE',
  };
};

const useIsNLplan = (): IsNLplan => {
  const { user, distribution } = useAuth();

  return isNLPlan(user!, distribution);
};

export const getIsNLPlan = (): IsNLplan => {
  const user = getUserDataFromQStore();
  const distribution = getDistributionDataFromQStore();

  return isNLPlan(user, distribution);
};

export default useIsNLplan;
