import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  FormErrorMessage,
} from '@wegroup/design-system';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useElementBounds from '../../../../../common/src/hooks/useElementBounds';
import { object, InferType, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import cogoToast from 'cogo-toast';

export interface LinkUploadProps {
  onLinkAccepted: (url: LinkUploadFile) => Promise<void>;
  isLoading?: boolean;
}

export interface LinkUploadFile {
  href: string;
  filename: string;
}

const linkUploadSchema = object({
  url: string()
    .required('INVALID_FIELD_URL')
    .url('INVALID_FIELD_URL')
    .matches(/\.[a-zA-Z0-9]+(\?[^\s]*)?$/, 'ERRORS_URL-DOES-NOT-CONTAIN-FILE'),
});

export type LinkUploadFormValues = InferType<typeof linkUploadSchema>;

const LinkUpload: React.FC<LinkUploadProps> = ({
  onLinkAccepted,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { setRef, width } = useElementBounds();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<LinkUploadFormValues>({
    defaultValues: {
      url: '',
    },
    resolver: yupResolver(linkUploadSchema),
  });

  const handleFileAccepted = handleSubmit(async (values) => {
    try {
      await onLinkAccepted({
        href: values.url,
        // Get last item of url split by /
        filename: values.url.split('/').slice(-1)[0].split('?')[0],
      });
      reset({ url: '' });
    } catch (e) {
      console.error(e);
      cogoToast.error(t('FILE-NOT-FOUND'));
    }
  });

  return (
    <FormControl isInvalid={!!errors.url}>
      <FormLabel>
        {t('DESIGN-SYSTEM_COMPONENTS_UPLOAD_LINK-UPLOAD_INPUT_LABEL')}
      </FormLabel>
      <InputGroup>
        {/* Hardcoded px value because there is no spacing value */}
        <Input
          placeholder={t(
            'DESIGN-SYSTEM_COMPONENTS_UPLOAD_LINK-UPLOAD_INPUT_PLACEHOLDER',
          )}
          pr={`${width + 8}px`}
          {...register('url')}
        />
        <InputRightElement mr="1" w="fit-content" ref={setRef}>
          <Button
            size="sm"
            variant="secondary"
            onClick={handleFileAccepted}
            isLoading={isLoading}
          >
            {t('ADD')}
          </Button>
        </InputRightElement>
      </InputGroup>
      {errors.url?.message && (
        <FormErrorMessage>{t(errors.url.message)}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default LinkUpload;
