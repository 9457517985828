import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationGroupInsurance: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_682_2542"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_682_2542)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M163.398 117.019c5.897 1.612 9.732 3.837 13.916 8.076 3.608 3.654 5.39 6.667 6.799 11.498.478 1.639.502 2.442.584 19.584.084 17.752.081 17.884-.432 19.076-.956 2.223-2.85 3.455-5.309 3.455-1.768 0-2.925-.49-4.132-1.749-1.588-1.656-1.538-1.081-1.538-17.755 0-8.318-.067-15.298-.148-15.511-.086-.224-.434-.386-.829-.386-.395 0-.742.162-.828.386-.082.213-.148 7.088-.148 15.28v14.893l-1.83 22.99c-1.006 12.645-1.936 23.634-2.067 24.421-.56 3.361-3.137 5.858-6.739 6.53-2.102.392-8.211.147-9.698-.389-1.587-.572-3.663-2.52-4.443-4.167-.609-1.289-.684-2.036-2.574-25.552l-1.946-24.224v-14.698c0-8.083-.067-14.871-.149-15.084-.207-.541-1.247-.483-1.552.086-.166.311-.252 5.612-.252 15.511 0 16.579.049 16.013-1.539 17.669-1.325 1.383-2.435 1.812-4.421 1.709-1.476-.077-1.901-.208-2.885-.887-.639-.442-1.456-1.312-1.831-1.949l-.677-1.152-.074-16.48c-.049-10.776.017-17.278.191-18.787 1.152-10.012 9.315-18.969 20.147-22.107 3.229-.936 4.489-1.08 8.545-.979 2.929.073 4.102.212 5.859.692z"
          clipRule="evenodd"
        ></path>
        <rect
          width="24.287"
          height="30.573"
          x="144.616"
          y="77.225"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="12.143"
        ></rect>
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M206.132 117.019c5.897 1.612 9.732 3.837 13.916 8.076 3.608 3.654 5.39 6.667 6.799 11.498.479 1.639.503 2.442.584 19.584.084 17.752.081 17.884-.432 19.076-.956 2.223-2.849 3.455-5.309 3.455-1.768 0-2.925-.49-4.132-1.749-1.588-1.656-1.538-1.081-1.538-17.755 0-8.318-.067-15.298-.148-15.511-.086-.224-.433-.386-.828-.386-.396 0-.743.162-.829.386-.081.213-.148 7.088-.148 15.28v14.893l-1.829 22.99c-1.007 12.645-1.937 23.634-2.068 24.421-.56 3.361-3.137 5.858-6.739 6.53-2.102.392-8.211.147-9.698-.389-1.587-.572-3.663-2.52-4.442-4.167-.61-1.289-.685-2.036-2.575-25.552l-1.946-24.224v-14.698c0-8.083-.067-14.871-.148-15.084-.208-.541-1.248-.483-1.553.086-.166.311-.252 5.612-.252 15.511 0 16.579.049 16.013-1.539 17.669-1.325 1.383-2.435 1.812-4.421 1.709-1.475-.077-1.901-.208-2.885-.887-.639-.442-1.456-1.312-1.831-1.949l-.677-1.152-.074-16.48c-.049-10.776.017-17.278.191-18.787 1.152-10.012 9.315-18.969 20.147-22.107 3.229-.936 4.489-1.08 8.545-.979 2.929.073 4.102.212 5.859.692z"
          clipRule="evenodd"
        ></path>
        <rect
          width="24.287"
          height="30.573"
          x="187.351"
          y="77.225"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="12.143"
        ></rect>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M174.255 6.026V72.5h7.326V6.026a3.662 3.662 0 00-7.326 0z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M105 95.07c0-7.744 10.675-14.021 23.845-14.021 13.17 0 23.845 6.277 23.845 14.021 0-7.744 11.298-14.021 25.231-14.021 13.933 0 25.231 6.277 25.231 14.021 0-7.744 10.675-14.021 23.845-14.021 13.17 0 23.845 6.277 23.845 14.021v-5.609a72.922 72.922 0 00-124.484-51.563A72.922 72.922 0 00105 89.461v5.61z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M177.918 16.546s-25.231 14.989-25.231 78.524M177.918 16.546s25.231 14.989 25.231 78.524"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationGroupInsurance;
