import { stringify } from 'query-string';
import { useQuery, UseQueryResult } from 'react-query';
import { getI18nLanguageCode } from '../../../../../../../i18n';
import { request } from '../../../../../../common/src/legacy/services/httpSessionService';
import { AddressSearchParams } from '../types/API';
import { MINIMUM_REQUIRED_CHARS } from './getAddress';

export interface Street {
  country_code?: string;
  name: string;
  zipcode?: number;
}

interface StreetSearchResponse {
  items: Street[];
}

type StreetSearchParams = { qCity?: string } & AddressSearchParams;

export const getStreets = async ({
  query,
  qCity,
  language,
  countryCode = 'BE',
}: StreetSearchParams): Promise<[StreetSearchResponse, number]> => {
  const qParamsObject = {
    q: query,
    q_city: qCity || '',
    lang: language || getI18nLanguageCode(),
    country_code: countryCode,
  };
  const qString = stringify(qParamsObject);

  const path = `v1/api/address/streets?${qString}`;
  const [resp, status] = await request({ path });
  return [resp, status];
};

export const useStreetSearch = (
  params: StreetSearchParams,
): UseQueryResult<Street[]> => {
  const { query } = params;

  return useQuery(
    ['address', 'search', 'streets', ...Object.values(params)],
    async () => {
      const [resp, status] = await getStreets(params);

      if (status >= 400) throw new Error();

      return resp.items;
    },
    {
      enabled: query.length > MINIMUM_REQUIRED_CHARS,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
};
