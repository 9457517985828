import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';

const IllustrationBusiness: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="180"
      height="180"
      fill="none"
      viewBox="0 0 180 180"
      {...otherProps}
    >
      <g clipPath="url(#clip0_1306_20017)">
        <path fill="#fff" d="M0 0H180V180H0z"></path>
        <circle cx="90" cy="90" r="90" fill="#FFF2E5"></circle>
        <path stroke="#FF8000" strokeWidth="3" d="M90 137.25v21"></path>
        <circle
          cx="90"
          cy="157.5"
          r="6"
          fill="#FFF2E5"
          stroke="#FF8000"
          strokeWidth="3"
        ></circle>
        <mask id="path-4-inside-1_1306_20017" fill="#fff">
          <path d="M24 42h132v97a2 2 0 01-2 2H26a2 2 0 01-2-2V42z"></path>
        </mask>
        <path
          fill="#fff"
          stroke="#FF8000"
          strokeWidth="6"
          d="M24 42h132v97a2 2 0 01-2 2H26a2 2 0 01-2-2V42z"
          mask="url(#path-4-inside-1_1306_20017)"
        ></path>
        <mask id="path-5-inside-2_1306_20017" fill="#fff">
          <rect width="144" height="15" x="18" y="34.5" rx="2"></rect>
        </mask>
        <rect
          width="144"
          height="15"
          x="18"
          y="34.5"
          fill="#FFE6CC"
          stroke="#FF8000"
          strokeWidth="6"
          mask="url(#path-5-inside-2_1306_20017)"
          rx="2"
        ></rect>
        <mask
          id="mask0_1306_20017"
          style={{ maskType: 'alpha' }}
          width="58"
          height="58"
          x="61"
          y="64"
          maskUnits="userSpaceOnUse"
        >
          <circle
            cx="90"
            cy="93"
            r="27"
            fill="#FFE6CC"
            stroke="#FF8000"
            strokeWidth="3"
          ></circle>
        </mask>
        <g stroke="#FF8000" strokeWidth="3" mask="url(#mask0_1306_20017)">
          <path
            fill="#fff"
            strokeLinejoin="round"
            d="M90 93.75l3-40.5 27.75 7.5-30.75 33z"
          ></path>
          <path
            fill="#FFE6CC"
            strokeLinejoin="round"
            d="M125.25 111L90 93.75 115.5 66l13.5 6.75-3.75 38.25z"
          ></path>
          <path
            fill="#fff"
            strokeLinejoin="round"
            d="M53.25 114L90 93.75l33 16.5-9 17.25-29.25 5.25L53.25 114z"
          ></path>
          <path
            fill="#FC9"
            strokeLinejoin="round"
            d="M93 53.25l-3 40.5-30 16.5-24 3L45 66l48-12.75z"
          ></path>
          <circle cx="90" cy="93" r="27"></circle>
        </g>
        <path
          stroke="#FF8000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M47.25 72.75v5.37a2 2 0 001.29 1.87L69 87.75M99 73.5l9.151-12.67a2 2 0 011.622-.83h7.977M111.75 97.5h17.922a2 2 0 001.414-.585L135 93M88.5 110.25l-11.406 17.11a2 2 0 01-1.664.89h-5.68M42 128.25h18M38.25 66h18M126 60h17.25M130.5 89.25h12.75"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1306_20017">
          <path fill="#fff" d="M0 0H180V180H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationBusiness;
