import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersHandshake: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.46 19.374l2.931-2.873c-.702.057-1.664.293-2.64.531-1.155.283-2.33.57-3.11.57-.285 0-.753-.157-1.302-.399-2.425-1.064-5.354-.283-6.467 2.12a4.394 4.394 0 001.345 5.357l1.543 1.162 1.585-1.89a3.381 3.381 0 015.79 1.076c.623.078 1.232.33 1.75.764.524.44.882 1.007 1.065 1.618a3.365 3.365 0 011.848.775c.497.418.844.948 1.034 1.521a3.381 3.381 0 012.191 5.532l-1.102 1.314a1.717 1.717 0 002.683-1.47h.001a1.717 1.717 0 002.66-1.79l.045-.03.011-.008a1.717 1.717 0 002.846-1.678 1.717 1.717 0 101.652-3l-7.17-6.85a1.283 1.283 0 00-.322.24l-.089.085-2.695 2.035-.05.034c-.708.471-1.527.547-2.061.538a5.152 5.152 0 01-1.901-.399c-.615-.26-1.333-.72-1.825-1.494-.532-.84-.665-1.841-.394-2.844l.148-.547zm4.822 15.895l1.147-1.368-1.148 1.367zm.004-17.711l-2.965 2.906c-.099.365-.08.68.022.946-.101-.267-.12-.581-.022-.946l2.965-2.906zM21.46 29.779a1.296 1.296 0 000 0zm-2.913-2.393a1.296 1.296 0 00-.391-.226 1.3 1.3 0 01.39.226zm-2.775-2.257a1.3 1.3 0 000 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M39.72 22.763l.712-.582a3.403 3.403 0 00.895-4.149l-1.03-2.075a3.113 3.113 0 00-4.577-1.164c-2.003 1.349-3.617 1.327-7.283 0-1.605-.434-2.235-.315-2.913.573l-5.202 5.098c-.52 1.925 2.237 2.445 3.017 1.925l2.549-1.925c1.249-1.197 2.549-1.04 3.693 0l2.313 2.097a6 6 0 007.826.202z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.72 14.793c-2.003 1.349-3.617 1.327-7.283 0-1.605-.434-2.235-.315-2.913.573l-5.202 5.098c-.381 1.41.998 2.067 2.07 2.112.39.016.739-.049.947-.188l2.549-1.924c.223-.214.449-.385.674-.515 1.035-.598 2.08-.34 3.02.515l2.312 2.097a6 6 0 007.826.202l.712-.582a3.403 3.403 0 00.895-4.149l-1.03-2.075a3.113 3.113 0 00-4.577-1.164zm-1.135 4.808a2 2 0 002.605.064l.343-.28-.385-.775c-.79.445-1.634.79-2.563.991zm2.129-1.865v.001zm1.03 2.075z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M12.092 29.512a1.3 1.3 0 01-.16-1.832l2.006-2.391a1.3 1.3 0 011.992 1.672l-2.006 2.39a1.3 1.3 0 01-1.832.161z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.417 27.102l-.486.578a1.3 1.3 0 101.993 1.672l.485-.579a2.687 2.687 0 01-1.21-.58 2.687 2.687 0 01-.782-1.091zm3.028.437a2.687 2.687 0 00-.782-1.091 2.687 2.687 0 00-1.21-.58l.485-.58a1.3 1.3 0 011.992 1.673l-.485.578z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M14.867 31.77a1.3 1.3 0 01-.16-1.833l2.006-2.39a1.3 1.3 0 111.992 1.671L16.7 31.61a1.3 1.3 0 01-1.832.16z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.192 29.359l-.486.578A1.3 1.3 0 1016.7 31.61l.485-.578a2.688 2.688 0 01-1.21-.58 2.688 2.688 0 01-.782-1.092zm3.028.438a2.688 2.688 0 00-.782-1.092 2.688 2.688 0 00-1.21-.58l.485-.579a1.3 1.3 0 111.992 1.672l-.485.579z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M17.78 34.163a1.3 1.3 0 01-.16-1.833l2.006-2.39a1.3 1.3 0 111.992 1.671l-2.006 2.391a1.3 1.3 0 01-1.832.16z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.105 31.752l-.485.578a1.3 1.3 0 101.992 1.672l.486-.578a2.688 2.688 0 01-1.211-.58 2.688 2.688 0 01-.782-1.092zm3.028.438a2.687 2.687 0 00-.782-1.092 2.688 2.688 0 00-1.21-.58l.485-.579a1.3 1.3 0 111.992 1.672l-.485.579z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M20.59 36.452a1.3 1.3 0 01-.161-1.833l2.006-2.39a1.3 1.3 0 111.993 1.671l-2.007 2.391a1.3 1.3 0 01-1.832.16z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.914 34.04l-.485.58a1.3 1.3 0 101.992 1.671l.486-.578a2.686 2.686 0 01-1.21-.58 2.686 2.686 0 01-.783-1.092zm3.028.439a2.687 2.687 0 00-.782-1.092 2.687 2.687 0 00-1.21-.58l.485-.579a1.3 1.3 0 111.993 1.672l-.486.579z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersHandshake;
