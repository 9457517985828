import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusApproved: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M42.473 12.927a1.8 1.8 0 010 2.546l-21.6 21.6a1.8 1.8 0 01-2.546 0l-10.8-10.8a1.8 1.8 0 112.546-2.546l9.527 9.527 20.327-20.327a1.8 1.8 0 012.546 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M39.574 12.574a2.3 2.3 0 113.252 3.252l-21.6 21.6a2.3 2.3 0 01-3.252 0l-10.8-10.8a2.3 2.3 0 013.252-3.252l9.174 9.173 19.974-19.973zm2.545.707a1.3 1.3 0 00-1.838 0L19.954 33.608a.5.5 0 01-.708 0L9.72 24.081a1.3 1.3 0 10-1.838 1.838l10.8 10.8a1.3 1.3 0 001.838 0l21.6-21.6a1.3 1.3 0 000-1.838z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralStatusApproved;
