import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationHistory: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M29 28h-5a2 2 0 01-2-2v-5a2 2 0 014 0v3h3a2 2 0 010 4z"
    ></path>
    <path
      fill="currentColor"
      d="M24.27 10A15.11 15.11 0 0010 24.34 15 15 0 0014.84 36h.05a2 2 0 00.11 4h5a2 2 0 002-2v-5a2 2 0 10-4 0v.28a10.84 10.84 0 118.76 2.44 2 2 0 00-1.76 2v.13a2.001 2.001 0 002.34 2A15.003 15.003 0 1024.27 10z"
    ></path>
  </Icon>
);

export default GeneralNavigationHistory;
