const hillewaereBlue = {
  primary: {
    main: '#21193F',
    50: '#B2A6DC',
    100: '#9D8FD2',
    150: '#8877C9',
    200: '#745FC0',
    300: '#533F9E',
    400: '#3A2C6E',
    500: '#21193F',
    600: '#191330',
    700: '#120D22',
    750: '#0E0B1A',
    800: '#0A0813',
    850: '#06050C',
    900: '#020205',
  },
  secondary: {
    main: '#3989C4',
    50: '#EAF2F9',
    100: '#D6E7F3',
    150: '#C2DBED',
    200: '#AECFE8',
    300: '#87B8DC',
    400: '#5FA1D1',
    500: '#3989C4',
    600: '#2E6D9C',
    700: '#225275',
    750: '#1C4461',
    800: '#17364D',
    850: '#11283A',
    900: '#0B1B26',
  },
};

export default hillewaereBlue;
