import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsFlightCancel: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M27.9 19.74l-7.6 5.7a5.001 5.001 0 014.7-6.65 4.93 4.93 0 012.9.95zM29.7 22.14a5.001 5.001 0 01-4.7 6.65 4.93 4.93 0 01-2.9-.95l7.6-5.7z"
    ></path>
    <path
      fill="currentColor"
      d="M25 5.77L8 13.6a35 35 0 0017 30.19A34.998 34.998 0 0042 13.6L25 5.77zm0 26a8 8 0 118-8 8.001 8.001 0 01-8 8.02v-.02z"
    ></path>
  </Icon>
);

export default RiskObjectsFlightCancel;
