import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationHoverBoard: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_702_2495"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_702_2495)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M109 178.293h41a8 8 0 018 8h-49v-8zM248 178.293h-41a8 8 0 00-8 8h49v-8z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M112 191.293H248V203.293H112z"
        ></path>
        <rect
          width="21"
          height="55"
          x="90"
          y="168.293"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10.5"
        ></rect>
        <rect
          width="21"
          height="55"
          x="246"
          y="168.293"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10.5"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M241.543 177.824v.567a6 6 0 01-6 6h-56.137a1 1 0 00-1 1v10.383a1 1 0 001 1h58.384c6.292-.188 9.683-1.179 15.386-5.066l12.195-10.507c4.321-3.759 5.59-6.326 5.629-11.82 0-5.181-4.2-9.381-9.381-9.381h-6.942c-10.32.552-13.493 4.08-13.134 17.824z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M115.457 177.824v.567a6 6 0 006 6h56.137a1 1 0 011 1v10.383a1 1 0 01-1 1H119.21c-6.292-.188-9.683-1.179-15.386-5.066l-12.195-10.507c-4.321-3.759-5.59-6.326-5.63-11.82C86 164.2 90.2 160 95.382 160h6.942c10.32.552 13.493 4.08 13.134 17.824z"
        ></path>
        <rect
          width="19"
          height="32"
          x="169"
          y="180.293"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="9.5"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationHoverBoard;
