import React from 'react';
import {
  useRadioGroupContext,
  useRadio,
  Flex,
  Text,
  FlexProps,
} from '@wegroup/design-system';
import Bullet from '../Bullet';
import IconContainer from '../IconContainer';

interface Props {
  children?: string | ChildNode;
  bullet?: boolean;
  icon?: React.ReactNode;
  disabled?: boolean;
  isChecked?: boolean;
  value?: string;
  description?: string;
}

const RadioButton = React.forwardRef<HTMLInputElement, Props & FlexProps>(
  (
    { children, bullet = true, icon, value, disabled, description, ...rest },
    ref,
  ) => {
    const { value: groupValue, name, onChange } = useRadioGroupContext();
    const { getInputProps, getLabelProps } = useRadio({
      ...rest,
      isChecked: groupValue === value,
      onChange: onChange,
      isDisabled: disabled,
      name: name,
      value: value,
    });

    return (
      <Flex
        as="label"
        bgColor="white"
        alignItems="center"
        cursor="pointer"
        p="4"
        borderColor="gray.50"
        borderRadius="8px"
        borderStyle="solid"
        borderWidth="1px"
        w="100%"
        boxShadow="level1"
        userSelect="none"
        _checked={{
          p: '15px',
          borderColor: 'primary.500',
          borderWidth: '2px',
        }}
        _disabled={{
          opacity: '0.75',
          cursor: 'default',
          _hover: {
            boxShadow: 'none',
          },
        }}
        _hover={{
          boxShadow: 'level2',
        }}
        transition="box-shadow .1s, border-color .1s"
        sx={{
          '& > *': {
            mr: '5',
            '&:last-child': {
              mr: '0',
            },
          },
        }}
        {...rest}
        {...getLabelProps()}
        data-test-id="DesignSystem_RadioButton"
      >
        {icon && <IconContainer {...getLabelProps()}>{icon}</IconContainer>}
        <input {...getInputProps({}, ref)} />
        <Flex flexGrow={1} flexDir="column" justifyContent="center">
          <Text as="h4" textStyle="smallBody" fontSize="sm">
            {children}
          </Text>
          {description && (
            <Text textStyle="smallBody" color="text.gray">
              {description}
            </Text>
          )}
        </Flex>
        {bullet && <Bullet {...getLabelProps()} />}
      </Flex>
    );
  },
);

export default RadioButton;
