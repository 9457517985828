import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleCarAndMotorcycle: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M36.282 33.805a6.19 6.19 0 00-1.224.123L34.75 32.9c.069.01.138.017.208.017h1.332V30.25h-1.34c-.338 0-.664.13-.91.363l-.715-2.32a.623.623 0 00-.595-.44h-2.665a.625.625 0 000 1.25h2.205l.902 2.932h-2.89a2.667 2.667 0 00-1.884.78L26.512 34.7l-.887.887h-6.308a6.216 6.216 0 101.268 7.105h10.08a6.216 6.216 0 105.617-8.882v-.005zm-4.084 6.218a4.046 4.046 0 01.325-1.598h1.632a2.648 2.648 0 000 3.198h-1.633a4.065 4.065 0 01-.325-1.6zm2.5 0a1.597 1.597 0 01.552-1.223l.432 1.405a.625.625 0 00.778.413.622.622 0 00.413-.778l-.433-1.408a1.6 1.6 0 11-1.75 1.59h.008zm-1.5-2.666c.365-.418.811-.759 1.312-1l.31 1h-1.623zm-11.118 0h-1.5a6.368 6.368 0 00-.397-.71h1.884l.013.71zm-7.105 6.75a4.085 4.085 0 113.092-6.75h-3.1a2.665 2.665 0 000 5.33h3.093a4.073 4.073 0 01-3.092 1.42h.007zm4.085-4.084c.001.55-.11 1.094-.325 1.6h-1.64a2.647 2.647 0 000-3.198h1.633c.215.505.326 1.049.324 1.598h.008zm-2.487 0a1.6 1.6 0 11-1.598-1.598 1.6 1.6 0 011.59 1.598h.008zm4.41 1.6a6.19 6.19 0 000-3.198h1.097v3.198h-1.097zm15.307 2.485a4.072 4.072 0 01-3.093-1.42h3.093a2.665 2.665 0 100-5.33h-.17l-.422-1.378a4.085 4.085 0 11.593 8.128zM37.5 10h-3.75l-3.387-5.637a1.25 1.25 0 00-1.07-.613H14.598a1.25 1.25 0 00-1.126.698L11.25 10h-2a1.75 1.75 0 00-1.75 1.75v5.75a1.25 1.25 0 001.25 1.25H10a5 5 0 0110 0h8.75a5 5 0 0110 0h2.5a1.25 1.25 0 001.25-1.25V15a5 5 0 00-5-5zM20 10h-7.123l.595-1.5 1.3-3.25H20V10zm3.567 2.318a.626.626 0 01-.442.182h-1.21a.652.652 0 01-.665-.57.625.625 0 01.625-.68h1.21a.653.653 0 01.665.59.63.63 0 01-.183.488v-.01zM27.5 10h-6.25V5.25h6.25V10zm1.25 0V5.25h.4L32.015 10H28.75z"
    ></path>
    <path
      fill="currentColor"
      d="M15 17a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zm0-2a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zM33.75 17a1.75 1.75 0 110 3.5 1.75 1.75 0 010-3.5zm0-2a3.75 3.75 0 100 7.5 3.75 3.75 0 000-7.5zM25.625 23.76h-1.25v5h1.25v-5z"
    ></path>
    <path fill="currentColor" d="M22.5 25.635v1.25h5v-1.25h-5z"></path>
  </Icon>
);

export default RiskObjectsVehicleCarAndMotorcycle;
