import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyShed: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M27.5 23h-5a1.5 1.5 0 000 3h5a1.5 1.5 0 000-3zM27.5 29h-5a1.5 1.5 0 000 3h5a1.5 1.5 0 000-3zM27.5 35h-5a1.5 1.5 0 000 3h5a1.5 1.5 0 000-3zM27.5 41h-5a1.5 1.5 0 000 3h5a1.5 1.5 0 000-3z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.56 7.19l-11.37 7.66a.5.5 0 00-.22.42V43.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-22a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v22a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-28a.48.48 0 00-.22-.41l-11.63-7.9a.48.48 0 00-.56 0z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyShed;
