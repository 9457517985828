import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsRetirement: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.002 15a2 2 0 012 2v11.395l3.664 5.496c.127.19.22.402.276.624l2 8a2 2 0 01-3.88.97l-1.917-7.664-3.808-5.711a2 2 0 01-.335-1.11V17a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.8 15.4a2 2 0 012.31-.064l5.642 3.762 5.577.93a2 2 0 01-.658 3.945l-6-1a1.999 1.999 0 01-.78-.309l-4.823-3.215-5.715 4.286 1.544 4.633a2 2 0 11-3.794 1.265l-2-6A2 2 0 018.8 21.4l8-6zM14 9a4 4 0 118 0 4 4 0 01-8 0zM16.896 33.211a2 2 0 01.894 2.683l-4 8a2 2 0 01-3.577-1.788l4-8a2 2 0 012.683-.895zM37 25a1 1 0 00-1 1v17a2 2 0 11-4 0V26a5 5 0 1110 0v1a2 2 0 01-4 0v-1a1 1 0 00-1-1z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsRetirement;
