import { useMutation } from 'react-query';
import { StatusResponse } from '../../../../../../types/API';
import { wgApi } from '../../../../../app/src/features/api/wgApi';

interface Params {
  conversation_id: string;
  is_positive: boolean;
  remark?: string;
}

const postInsuranceTermsBotFeedback = async (payload: Params) => {
  const { data, isError } = await wgApi.post<StatusResponse>(
    `/v1/api/insurance-terms-bot/feedback`,
    payload,
  );
  if (isError) throw new Error();
  return data;
};

export const usePostInsuranceTermsBotFeedback = () => {
  return useMutation(postInsuranceTermsBotFeedback);
};
