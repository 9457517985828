import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralRiskAnalysisInsurance: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.089 16.12A31 31 0 0025 39.013a31.001 31.001 0 0012.911-22.891L25 10.174 12.089 16.12zm11.656-9.772a3 3 0 012.51 0l14.01 6.452c1.058.488 1.75 1.546 1.692 2.71a35 35 0 01-15.439 27.315 2.72 2.72 0 01-3.036 0A34.998 34.998 0 018.042 15.51c-.057-1.164.635-2.222 1.693-2.71l14.01-6.452z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralRiskAnalysisInsurance;
