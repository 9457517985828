import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleCamper: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M43.396 24.576l-5.013-1.102-2.757-5.063h4.411l2.456-2.205V14H5v15.589l1.704 3.258H8.81c.1-1.955 1.705-3.56 3.71-3.56s3.609 1.605 3.71 3.56H33.12c.1-1.955 1.704-3.56 3.71-3.56 2.004 0 3.608 1.605 3.709 3.56h3.308L45 26.53c-.15.05.3-1.403-1.605-1.955zm-26.116-.15c0 .25-.25.502-.5.502H7.605c-.25 0-.501-.251-.501-.502v-1.854c0-.251.25-.502.501-.502h9.173c.25 0 .502.25.502.502v1.854zm0-3.659c0 .25-.25.502-.5.502H7.605c-.25 0-.501-.251-.501-.502v-1.854c0-.251.25-.502.501-.502h9.173c.25 0 .502.25.502.502v1.854zm7.018 9.975c0 .3-.25.501-.501.501h-3.259c-.3 0-.5-.2-.5-.501v-11.83c0-.25.2-.5.5-.5h3.259c.25 0 .501.25.501.5v11.83zm3.66-6.366v-5.113h6.114l2.657 5.163h-8.772v-.05z"
    ></path>
    <path
      fill="currentColor"
      d="M12.466 30.3c-1.5 0-2.708 1.167-2.75 2.625v.125c0 1.542 1.25 2.75 2.75 2.75 1.542 0 2.75-1.25 2.75-2.75v-.125c-.042-1.458-1.25-2.625-2.75-2.625zM36.726 30.3c-1.5 0-2.708 1.167-2.75 2.625v.125c0 1.542 1.25 2.75 2.75 2.75 1.542 0 2.75-1.25 2.75-2.75v-.125c-.041-1.458-1.25-2.625-2.75-2.625z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleCamper;
