import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationOfficeInfo: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 43a2 2 0 012-2h36a2 2 0 110 4H7a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.052 5.299A2 2 0 0129 7v36a2 2 0 11-4 0V10.236l-12 6V43a2 2 0 11-4 0V15a2 2 0 011.106-1.789l16-8a2 2 0 011.945.088z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.336 13.89a2 2 0 012.773-.554l12 8A2 2 0 0141 23v20a2 2 0 01-4 0V24.07l-11.11-7.406a2 2 0 01-.554-2.773zM19 17a2 2 0 012 2v.02a2 2 0 11-4 0V19a2 2 0 012-2zM19 23a2 2 0 012 2v.02a2 2 0 11-4 0V25a2 2 0 012-2zM19 29a2 2 0 012 2v.02a2 2 0 11-4 0V31a2 2 0 012-2zM19 35a2 2 0 012 2v.02a2 2 0 11-4 0V37a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationOfficeInfo;
