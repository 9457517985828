import { VStack } from '@wegroup/design-system';
import React from 'react';
import { Distribution } from '../../../../../../../app/src/features/authentication/types/Distribution';
import BrokerContactInfoCard from './BrokerContactInfoCard';
import BrokerContactInfoDetails from './BrokerContactInfoDetails';
import BrokerContactInfoHeader from './BrokerContactInfoHeader';

interface Props {
  isLoading: boolean;
  distribution?: Distribution;
}

const BrokerContactInfo: React.FC<React.PropsWithChildren<Props>> = ({
  distribution,
  isLoading,
}) => {
  return (
    <VStack spacing="4" align="stretch">
      <BrokerContactInfoHeader />
      <BrokerContactInfoCard
        distribution={distribution}
        isLoading={isLoading}
      />
      <BrokerContactInfoDetails
        distribution={distribution}
        isLoading={isLoading}
      />
    </VStack>
  );
};

export default BrokerContactInfo;
