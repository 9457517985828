import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyStorageRoom: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.07 33H18V6.49a1.5 1.5 0 10-3 0V33h-3.07a.93.93 0 00-.93.92v1.16a.93.93 0 00.93.92h9.14a.93.93 0 00.93-.92v-1.16a.93.93 0 00-.93-.92zM21.44 45h-9.88c-.38 0-.53-.46-.56-.78a.172.172 0 010-.07c0-.07.11-.14.16-.19.05-.05.13-.16.19-.25.122-.162.232-.333.33-.51.203-.355.348-.74.43-1.14a4 4 0 00-.2-2.26 4.112 4.112 0 00-.34-.66 3.289 3.289 0 01-.46-.89l-.11-.39c0-.47.25-.86.56-.86h9.88c.31 0 .56.39.56.86l-.09.31a11.23 11.23 0 01-.5 1.25 4.54 4.54 0 00.2 4l.15.27.12.22a.63.63 0 01.12.23v.07c0 .33-.19.79-.56.79z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M39 33.35L36.37 44a1.51 1.51 0 01-1.5 1h-6.74a1.49 1.49 0 01-1.5-1L24 33.36a1.08 1.08 0 010-.31 15 15 0 006.62 1.32A27.83 27.83 0 0039 33a.86.86 0 010 .35z"
    ></path>
    <path
      fill="currentColor"
      d="M31 26a6.281 6.281 0 00-6.66 6.1c1 .55 5.82 2.59 14.24 0C38.19 30 36.72 26 31 26zm-5.61 5.81A5.22 5.22 0 0131 27c4.6 0 6 2.92 6.48 4.77a20.82 20.82 0 00-12.11.04h.02z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyStorageRoom;
