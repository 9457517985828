import { useTheme } from '@wegroup/design-system';
import { useLayoutEffect, useMemo, useState } from 'react';
import { Swatch } from '../../../design-system/src/theme/foundations/baseColors';

const useToggleIllustrationColors = (isActive: boolean): Swatch => {
  const theme = useTheme();

  const grayColors = useMemo(() => {
    const primaryColors = {
      ...theme.colors.primary,
    };
    primaryColors[50] = theme.colors.gray.ultraLight;
    primaryColors[100] = theme.colors.gray[50];
    Object.keys(primaryColors).forEach((key) => {
      const keyInt = parseInt(key);
      if (keyInt && keyInt > 100) {
        primaryColors[key] = theme.colors.gray[keyInt - 100];
      }
    });
    return { ...theme.colors, primary: primaryColors };
  }, []);

  const [colors, setColors] = useState(
    isActive ? { ...theme.colors } : grayColors,
  );

  useLayoutEffect(() => {
    if (isActive) {
      setColors({ ...theme.colors });
    } else {
      setColors(grayColors);
    }
  }, [isActive]);

  return colors;
};

export default useToggleIllustrationColors;
