import React from 'react';
import { Flex } from '@wegroup/design-system';

const IconContainer: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Flex
    {...props}
    justifyContent="center"
    data-test-id="DesignSystem_RadioButton_Icon"
    alignItems="center"
    bgColor="gray.50"
    borderRadius="100%"
    transition="all .1s"
    flexShrink={0}
    w="10"
    h="10"
    sx={{
      'svg path': {
        fill: 'gray.300',
        stroke: 'gray.300',
      },
    }}
    _checked={{
      bgColor: 'primary.50',
      'svg path': {
        fill: 'primary.500',
        stroke: 'primary.500',
      },
    }}
  >
    {props.children}
  </Flex>
);

export default IconContainer;
