import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';

const IllustrationBoatBACasco: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="96"
      fill="none"
      viewBox="0 0 100 96"
    >
      <path
        stroke="#B7CEDF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M8 80a9.6 9.6 0 008 4 9.6 9.6 0 008-4 9.6 9.6 0 018-4 9.6 9.6 0 018 4 9.6 9.6 0 008 4 9.6 9.6 0 008-4 9.6 9.6 0 018-4 9.6 9.6 0 018 4 9.6 9.6 0 008 4 9.6 9.6 0 008-4"
      ></path>
      <path
        stroke="#365E7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M16 72l-4-12h72l-4 12M44 48h28L44 12v36zM32 28l-8 20"
      ></path>
      <circle cx="6.5" cy="41.5" r="2.5" fill="#F7A82A"></circle>
      <circle cx="86.5" cy="32.5" r="2.5" fill="#F7A82A"></circle>
      <rect width="2" height="8" x="95" y="47" fill="#F7A82A" rx="1"></rect>
      <rect
        width="2"
        height="8"
        x="92"
        y="52"
        fill="#F7A82A"
        rx="1"
        transform="rotate(-90 92 52)"
      ></rect>
      <rect
        width="2"
        height="8"
        x="15.121"
        y="22.536"
        fill="#F7A82A"
        rx="1"
        transform="rotate(-45 15.121 22.536)"
      ></rect>
      <rect
        width="2"
        height="8"
        x="16.535"
        y="28.192"
        fill="#F7A82A"
        rx="1"
        transform="rotate(-135 16.535 28.192)"
      ></rect>
    </svg>
  );
};

export default IllustrationBoatBACasco;
