import React, { forwardRef, ComponentPropsWithoutRef } from 'react';

import { Text, Radio, HStack } from '@wegroup/design-system';
import { SearchSelectRootClassName } from '../SearchSelectInput';

interface SearchSelectDrawerItemProps extends ComponentPropsWithoutRef<'div'> {
  label: string;
  customElement?: React.ReactElement;
  'data-selected': boolean;
}

const SearchSelectDrawerItem = forwardRef<
  HTMLDivElement,
  SearchSelectDrawerItemProps
>(
  (
    {
      label,
      className,
      customElement,
      'data-selected': dataSelected,
      ...otherProps
    }: SearchSelectDrawerItemProps,
    ref,
  ) => {
    const onMouseDownCaptureHandler: React.MouseEventHandler<HTMLDivElement> = (
      e,
    ) => {
      // To prevent keyboard popping on mobile
      e.currentTarget.closest(SearchSelectRootClassName)?.remove();
    };

    return (
      <HStack
        ref={ref}
        className={className}
        data-test-id="SEARCH-SELECT-INPUT_OPTION"
        justify="space-between"
        border="none"
        borderRadius="md"
        mb="2"
        _last={{ mb: '0' }}
        // To prevent scroll from locking on an item when you're swiping
        onTouchMoveCapture={(e) => e.stopPropagation()}
        onMouseDownCapture={onMouseDownCaptureHandler}
        {...otherProps}
      >
        {customElement ? customElement : <Text>{label}</Text>}
        <Radio
          borderColor="gray.300"
          color="primary.500"
          isChecked={dataSelected}
        />
      </HStack>
    );
  },
);

export default SearchSelectDrawerItem;
