import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyGlassBreakage: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M26 32.37v8.13a.5.5 0 00.5.5h3a2.5 2.5 0 012.5 2.5.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5 2.5 2.5 0 012.5-2.5h3a.5.5 0 00.5-.5v-8.13a.5.5 0 00-.43-.49A9 9 0 0116 23V9.5a.5.5 0 01.5-.5h5.19a.51.51 0 01.45.28l1.75 3.5a.49.49 0 010 .44l-1.77 3.54a.49.49 0 000 .47l2.57 4.5a.5.5 0 00.93-.32l-.6-4.2a.51.51 0 01.09-.38l2.78-3.7a.51.51 0 00.1-.3V9.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5V23a9 9 0 01-7.57 8.88.5.5 0 00-.42.49z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyGlassBreakage;
