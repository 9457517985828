import { useQuery } from 'react-query';
import useAuth from '../features/authentication/hooks/useAuth';
import { User } from '../features/authentication/types/User';
import { Distribution } from '../features/authentication/types/Distribution';
import * as configcat from 'configcat-js';
import { IConfigCatClient } from 'configcat-common';
import { CONFIGCAT_KEY } from '../../../../env.config';
import { isStaging } from '../../../common/src/utils/globalVariables';
import { isStorybookUrl } from '../features/authentication/constants/SocialAuth';

let configCatClient: IConfigCatClient;
if (!isStorybookUrl) configCatClient = configcat.createClient(CONFIGCAT_KEY!);

export const getFeatureFlags = async (
  distribution: Distribution,
  user?: User,
) => {
  const payload = {
    identifier: user?.id || distribution.id,
    country: distribution.address?.country_code?.toUpperCase() || 'BE',
    email: user?.email,
    custom: {
      plan: user?.plan || '',
      type: user?.type || '',
      admin_distro_id:
        distribution?.admin_distribution || distribution?.id || '',
      origin: window.location.origin,
    },
  };
  const featureFlags = await configCatClient.getAllValuesAsync(payload);
  const featureFlagsObject = Object.assign(
    {},
    ...featureFlags.map(({ settingKey, settingValue }) => ({
      [settingKey]: settingValue,
    })),
  );
  if (isStaging) console.info(featureFlagsObject);
  return featureFlagsObject;
};

export const getUseFeatureFlagsQKey = () => ['user', 'feature-flags'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useFeatureFlags = (): { [key: string]: any } => {
  const { user, distribution, isAuthorized, isAllBrokerDataFetched } =
    useAuth();

  const { data: featureFlags } = useQuery(
    getUseFeatureFlagsQKey(),
    () => getFeatureFlags(distribution, user),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: isAuthorized && isAllBrokerDataFetched,
    },
  );

  return featureFlags || {};
};

export default useFeatureFlags;
