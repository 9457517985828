import { productFlowStorage } from '../../../common/src/legacy/services/localforageService';

// Types
import { OffersController } from '../store/redux/slices/offersSlice';

export interface DataObject {
  offersFlow?: OffersController;
}

const getStoreInstance = (dbName: string) => {
  switch (dbName) {
    case 'product_flow':
      return productFlowStorage;
    default:
      console.error('you forgot assign a database');
      break;
  }
};

export const newSession = (dbName: string, id: string): Promise<unknown> => {
  const lf = getStoreInstance(dbName);
  if (!lf) return Promise.reject();
  return lf.setItem(id, {});
};

export const retrieveStorageById = (
  dbName: string,
  id: string,
): Promise<DataObject | null> => {
  const lf = getStoreInstance(dbName);
  if (!lf) return Promise.reject();
  return lf.getItem(id);
};

export const patchStorage = async (
  dbName: string,
  id: string,
  payload: unknown,
): Promise<unknown> => {
  const lf = getStoreInstance(dbName);
  if (!lf) return;
  const storedData = await lf.getItem<Record<string, unknown>>(id);
  if (!storedData) return;
  const updatedData = Object.assign(storedData, payload);
  return lf.setItem(id, updatedData);
};
