import React from 'react';

import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  VStack,
  useBoolean,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import LouiseAvatar from '../../../../dashboard/src/assets/LouiseAvatar';

interface Props {
  isOpen: boolean;
}

const JWTMismatchModal: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useBoolean();

  const handleClick = () => {
    setIsLoading.on();
    window.location.replace(`/dashboard`);
    window.location.reload();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => null} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent maxW="860px">
        <ModalHeader>
          {t('DASHBOARD_JWT-MISMATCH_MODAL-HEADER_TITLE')}
        </ModalHeader>
        <ModalBody>
          <VStack spacing="6">
            <LouiseAvatar />

            <VStack spacing="2">
              <Text textStyle="h5Headline" textAlign="center">
                {t('DASHBOARD_JWT-MISMATCH_MODAL_BODY_TITLE')}
              </Text>
              <Text color="gray.400" textAlign="center">
                {t('DASHBOARD_JWT-MISMATCH_MODAL-BODY_DESCRIPTION')}
              </Text>
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleClick}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            {t('DASHBOARD_JWT-MISMATCH_MODAL-FOOTER_BUTTON-TEXT')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default JWTMismatchModal;
