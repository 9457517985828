import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationKids: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_2163_12453"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_2163_12453)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke={colors.primary[100]}
          strokeWidth="4"
          d="M185.228 100.965c6.485 1.773 10.703 4.22 15.305 8.882 3.967 4.019 5.927 7.333 7.477 12.646.526 1.803.553 2.686.642 21.539.093 19.524.089 19.669-.475 20.98-1.051 2.445-3.134 3.8-5.838 3.8-1.945 0-3.218-.539-4.545-1.923-1.746-1.822-1.692-1.19-1.692-19.528 0-9.149-.073-16.825-.163-17.059-.094-.247-.476-.425-.911-.425-.434 0-.816.178-.911.425-.089.234-.163 7.796-.163 16.805v16.379l-2.012 25.285c-1.106 13.907-2.13 25.994-2.274 26.859-.616 3.696-3.45 6.443-7.411 7.182-2.312.431-9.031.162-10.666-.428-1.746-.629-4.029-2.771-4.886-4.583-.67-1.417-.753-2.239-2.831-28.102l-2.141-26.643v-16.164c0-8.891-.074-16.356-.163-16.59-.229-.595-1.372-.531-1.708.095-.182.342-.277 6.171-.277 17.059 0 18.233.054 17.611-1.692 19.433-1.458 1.52-2.679 1.992-4.863 1.879-1.623-.084-2.091-.228-3.173-.976-.703-.486-1.601-1.442-2.014-2.144l-.744-1.267-.082-18.124c-.053-11.852.019-19.003.21-20.663 1.267-11.011 10.245-20.862 22.158-24.314 3.551-1.028 4.938-1.187 9.398-1.076 3.222.08 4.512.233 6.445.761z"
          clipRule="evenodd"
        ></path>
        <rect
          width="27.11"
          height="34.024"
          x="164.372"
          y="57"
          fill="#fff"
          stroke={colors.primary[100]}
          strokeWidth="4"
          rx="13.555"
        ></rect>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M151.804 143.825c4.209 1.15 6.945 2.738 9.931 5.763 2.575 2.608 3.846 4.758 4.852 8.206.342 1.169.359 1.743.417 13.976.06 12.668.058 12.762-.308 13.612-.683 1.587-2.034 2.466-3.789 2.466-1.262 0-2.088-.349-2.949-1.248-1.133-1.182-1.097-.772-1.097-12.67 0-5.937-.048-10.918-.106-11.069-.062-.16-.309-.276-.591-.276s-.53.116-.591.276c-.059.151-.106 5.058-.106 10.903v10.628l-1.306 16.407c-.718 9.024-1.382 16.866-1.475 17.427-.4 2.399-2.239 4.181-4.809 4.661-1.5.279-5.86.104-6.921-.278-1.133-.409-2.614-1.798-3.17-2.974-.435-.919-.489-1.453-1.837-18.234l-1.39-17.288v-10.488c0-5.769-.047-10.613-.105-10.764-.148-.386-.891-.345-1.108.061-.119.222-.18 4.004-.18 11.069 0 11.831.035 11.427-1.098 12.609-.946.987-1.738 1.293-3.155 1.219-1.053-.054-1.357-.148-2.059-.633-.456-.315-1.039-.935-1.307-1.391l-.483-.822-.053-11.76c-.035-7.69.013-12.33.136-13.407.822-7.145 6.648-13.537 14.378-15.776 2.304-.668 3.204-.771 6.098-.699 2.09.052 2.927.151 4.181.494z"
          clipRule="evenodd"
        ></path>
        <rect
          width="16.186"
          height="20.672"
          x="138.974"
          y="119"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="8.093"
        ></rect>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M214.804 143.825c4.209 1.15 6.945 2.738 9.931 5.763 2.575 2.608 3.846 4.758 4.852 8.206.342 1.169.359 1.743.417 13.976.06 12.668.058 12.762-.308 13.612-.683 1.587-2.034 2.466-3.789 2.466-1.262 0-2.088-.349-2.949-1.248-1.133-1.182-1.097-.772-1.097-12.67 0-5.937-.048-10.918-.106-11.069-.062-.16-.309-.276-.591-.276s-.53.116-.591.276c-.059.151-.106 5.058-.106 10.903v10.628l-1.306 16.407c-.718 9.024-1.382 16.866-1.475 17.427-.4 2.399-2.239 4.181-4.809 4.661-1.5.279-5.86.104-6.921-.278-1.133-.409-2.614-1.798-3.17-2.974-.435-.919-.489-1.453-1.837-18.234l-1.39-17.288v-10.488c0-5.769-.047-10.613-.105-10.764-.148-.386-.891-.345-1.108.061-.119.222-.18 4.004-.18 11.069 0 11.831.035 11.427-1.098 12.609-.946.987-1.738 1.293-3.155 1.219-1.053-.054-1.357-.148-2.059-.633-.456-.315-1.039-.935-1.307-1.391l-.483-.822-.053-11.76c-.035-7.69.013-12.33.136-13.407.822-7.145 6.648-13.537 14.378-15.776 2.304-.668 3.204-.771 6.098-.699 2.09.052 2.927.151 4.181.494z"
          clipRule="evenodd"
        ></path>
        <rect
          width="16.186"
          height="20.672"
          x="201.974"
          y="119"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="8.093"
        ></rect>
      </g>
    </svg>
  );
};

export default IllustrationKids;
