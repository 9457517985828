import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationDebtBalance: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3771_10810"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0-.001h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3771_10810)">
        <path
          fill={colors.primary[50]}
          d="M337 160.999c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.635 160 160z"
        ></path>
        <g stroke={colors.primary[200]} strokeWidth="4" opacity="0.9">
          <path
            fill="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M147.037 63.123l-71.303 71.296v91.131h142.599v-91.131l-71.296-71.296z"
          ></path>
          <path
            fill={colors.primary[50]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M218.333 55.984a7.313 7.313 0 00-7.312-7.312h-12.084a7.311 7.311 0 00-7.312 7.312v14.464l26.734 26.422-.026-40.886z"
          ></path>
          <path
            fill={colors.primary[100]}
            strokeLinejoin="round"
            d="M241.153 119.43l-81.586-80.642a17.826 17.826 0 00-25.066 0L52.915 119.43a13.368 13.368 0 0018.904 18.904l75.218-75.211 75.212 75.211a13.367 13.367 0 0018.904-18.904z"
          ></path>
          <path
            fill={colors.primary[50]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M160.384 115.541a13.375 13.375 0 01-15.985 13.116 13.377 13.377 0 01-10.506-10.511 13.372 13.372 0 0113.124-15.979 13.38 13.38 0 019.453 3.919 13.377 13.377 0 013.914 9.455zM164.857 155.616h35.649v35.648h-35.649v-35.648z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M164.857 173.443h35.649"
          ></path>
          <path
            fill={colors.primary[100]}
            strokeLinejoin="round"
            d="M138.124 170.839a17.821 17.821 0 00-10.922-16.782 17.842 17.842 0 00-13.804 0 17.84 17.84 0 00-9.665 9.854 17.813 17.813 0 00-1.257 6.928v35.648h35.648v-35.648z"
          ></path>
          <path
            fill={colors.primary[50]}
            d="M94.252 208.947a5 5 0 015-5h44.162a5 5 0 015 5v15.927H94.252v-15.927z"
          ></path>
        </g>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M154.18 235.87a4.052 4.052 0 003.298 4.677l115.639 19.961a4.05 4.05 0 004.674-3.301l22.37-129.597-28.042-39.732-85.734-14.798a4.044 4.044 0 00-4.675 3.298L154.18 235.87z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M300.163 127.611l-30.171-5.208a3.78 3.78 0 01-3.081-4.366l5.206-30.16 28.046 39.734z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M245.346 182.349l24.874 4.293M225.893 201.354l40.579 7.004M177.471 215.359l85.252 14.716"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M230.319 172.422l-5.745-.992a24.368 24.368 0 01-11.566-5.406 24.678 24.678 0 01-7.296-10.541l13.466 2.325a4.562 4.562 0 003.359-.835 4.646 4.646 0 001.833-2.961 4.65 4.65 0 00-.734-3.405 4.56 4.56 0 00-2.885-1.912l-16.473-2.844a22.645 22.645 0 011.03-5.971l23.997 4.142a4.551 4.551 0 003.487-.732 4.632 4.632 0 001.925-3.026 4.664 4.664 0 00-.049-1.831 4.617 4.617 0 00-2.083-2.906 4.545 4.545 0 00-1.707-.618l-20.989-3.623a24.664 24.664 0 0110.407-7.482 24.365 24.365 0 0112.708-1.214l5.745.991a4.558 4.558 0 003.359-.834 4.646 4.646 0 001.833-2.961 4.65 4.65 0 00-.734-3.405 4.562 4.562 0 00-2.885-1.912l-5.745-.992a33.517 33.517 0 00-19.776 2.649 34 34 0 00-14.876 13.446l-7.633-1.318a4.569 4.569 0 00-3.487.731 4.649 4.649 0 00-1.925 3.027 4.664 4.664 0 00.049 1.831 4.617 4.617 0 002.083 2.905 4.53 4.53 0 001.707.619l5.509.951c-.255.969-.491 1.95-.663 2.948a35.325 35.325 0 00-.366 3.013l-5.509-.951a4.546 4.546 0 00-3.487.731 4.632 4.632 0 00-1.924 3.027 4.642 4.642 0 00.798 3.497 4.58 4.58 0 003.04 1.858l7.633 1.317a34.012 34.012 0 009.509 17.654 33.525 33.525 0 0017.743 9.126l5.745.991a4.56 4.56 0 003.358-.834 4.645 4.645 0 001.833-2.962 4.643 4.643 0 00-.734-3.404 4.558 4.558 0 00-2.884-1.912l-.001.005z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationDebtBalance;
