import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationDashboard: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M20.2 11.8v8.4h-8.4v-8.4h8.4zM21 9H11a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V11a2 2 0 00-2-2zM38.2 11.8v8.4h-8.4v-8.4h8.4zM39 9H29a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V11a2 2 0 00-2-2zM20.2 29.8v8.4h-8.4v-8.4h8.4zM21 27H11a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V29a2 2 0 00-2-2zM39 27H29a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V29a2 2 0 00-2-2z"
    ></path>
  </Icon>
);

export default GeneralNavigationDashboard;
