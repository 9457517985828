import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyFurniture: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M31.5 25a.5.5 0 00.5-.5V20a1 1 0 00-1-1H6a1 1 0 00-1 1v4.5a.5.5 0 00.5.5H8a3 3 0 013 3v3.5a.5.5 0 00.5.5h14a.5.5 0 00.5-.5V28a3 3 0 013-3h2.5z"
    ></path>
    <path
      fill="currentColor"
      d="M8 27H4a1 1 0 00-1 1v15a1 1 0 001 1h4a1 1 0 001-1V28a1 1 0 00-1-1zM33 27h-4a1 1 0 00-1 1v15a1 1 0 001 1h4a1 1 0 001-1V28a1 1 0 00-1-1zM25.5 34h-14a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h14a.5.5 0 00.5-.5v-3a.5.5 0 00-.5-.5zM48 17.93L44.32 6.56a.8.8 0 00-.77-.56h-5.1a.8.8 0 00-.77.56L34 17.93a.819.819 0 00.82 1.07H39v22h-2a1 1 0 00-1 1v1a1 1 0 001 1h8a1 1 0 001-1v-1a1 1 0 00-1-1h-2V19h4.18a.82.82 0 00.82-1.07z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyFurniture;
