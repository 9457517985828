import React, { ReactElement } from 'react';
import {
  VStack,
  Text,
  useNumberInput,
  HStack,
  IconButton,
  Input,
  Box,
  useTheme,
} from '@wegroup/design-system';
import { IconPlusFilled, IconMinus } from 'wg-fe-ui';

interface AmountPickerProps {
  name?: string;
  step?: number;
  defaultValue?: number;
  value?: number;
  min?: number;
  max?: number;
  precision?: number;
  readOnly?: boolean;
  illustration?: ReactElement;
  onChange?: (stringValue: string, numberValue: number) => void;
  dataTestId?: string;
}

const AmountPicker: React.FC<React.PropsWithChildren<AmountPickerProps>> = ({
  step = 1,
  defaultValue = 0,
  value,
  min,
  max = 99,
  precision,
  readOnly = false,
  children,
  onChange,
  name,
  illustration,
  dataTestId = 'DESIGN-SYSTEM_AMOUNTPICKER',
}) => {
  const { colors } = useTheme();
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step,
      defaultValue,
      min,
      max,
      value,
      name,
      precision,
      onChange,
      focusInputOnChange: false,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps({
    readOnly: readOnly,
  });

  return (
    <VStack
      width="unset"
      p="4"
      border="1px"
      borderColor="gray.strokeAndBg"
      borderRadius="md"
      boxShadow="level2"
      maxW="56"
      spacing="5"
    >
      {illustration && (
        <Box
          w="100%"
          maxW="44"
          sx={{
            svg: { w: '100%', h: '100%', path: { transition: '0.1s' } },
          }}
          transition="0.2s"
        >
          {illustration}
        </Box>
      )}
      <VStack spacing="3">
        <Text textStyle="h5Headline">{children}</Text>

        <HStack maxW="320px">
          <IconButton
            aria-label="decrease"
            bg="gray.strokeAndBg"
            _hover={{ bg: 'gray.50' }}
            borderRadius="full"
            w="12"
            h="12"
            icon={
              <IconMinus
                color={dec['disabled'] ? colors.gray[100] : colors.gray[200]}
              />
            }
            {...dec}
          />
          <Input
            textStyle="h3Headline"
            fontSize="2xl"
            border="none"
            w="10"
            p="0"
            textAlign="center"
            {...input}
            data-test-id={dataTestId}
          />
          <IconButton
            aria-label="increase"
            borderRadius="full"
            w="12"
            h="12"
            variant="secondary"
            icon={
              <IconPlusFilled
                color={inc['disabled'] ? colors.gray[100] : colors.primary[500]}
              />
            }
            {...inc}
          />
        </HStack>
      </VStack>
    </VStack>
  );
};

export default AmountPicker;
