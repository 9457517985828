import React from 'react';
import {
  Flex,
  Text,
  Button,
  CloseButton,
  HStack,
  VStack,
} from '@wegroup/design-system';
import RocketLouise from '../../../../../common/src/assets/illustrations/RocketLouise';
import { useTranslation } from 'react-i18next';

interface Props {
  onClose: () => void;
}

const ToasterNewVersionAvailable: React.FC<React.PropsWithChildren<Props>> = ({
  onClose,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    localStorage.setItem('version-change', 'version-change' + Math.random());
    window.location.reload();
  };

  return (
    <HStack
      ml="3"
      mb="3"
      p="4"
      boxShadow="level3"
      bg="white"
      borderRadius="md"
      border="solid 1px"
      borderColor="gray.strokeAndBg"
      spacing="8"
    >
      <Flex alignItems="center">
        <RocketLouise />
      </Flex>
      <VStack spacing="2" align="flex-start">
        <HStack
          justifyContent="space-between"
          alignItems="center"
          spacing="2"
          w="100%"
        >
          <Text textStyle="smallBodyMedium">
            {t('APP_NEW-VERSION_TOAST_TITLE')}
          </Text>
          <CloseButton onClick={onClose} />
        </HStack>
        <Text textStyle="smallBody" color="text.gray">
          {t('APP_NEW-VERSION_TOAST_DESCRIPTION')}
        </Text>
        <Button onClick={handleClick} variant="link">
          {t('APP_NEW-VERSION_TOAST_BUTTON')}
        </Button>
      </VStack>
    </HStack>
  );
};

export default ToasterNewVersionAvailable;
