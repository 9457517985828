import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyWellnessRoom: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M24.26 12.033a.117.117 0 00-.074-.033.115.115 0 00-.077.025.145.145 0 00-.048.073.166.166 0 000 .092c.315.603.443 1.311.36 2.009a3.267 3.267 0 01-.812 1.826c-1.43 1.427 0 4.5.809 5.903.015.03.04.053.068.064a.11.11 0 00.088-.003.135.135 0 00.065-.069.164.164 0 00.01-.101c-.252-1.21-.494-3.508.83-4.839 1.324-1.33-.168-3.762-1.219-4.947zM29.791 14.031a.177.177 0 00-.19-.007.143.143 0 00-.062.07.124.124 0 000 .089c.4.58.56 1.262.457 1.934-.104.672-.467 1.292-1.03 1.758-1.873 1.444-.557 3.342.08 4.076.02.02.047.035.076.043a.18.18 0 00.09 0 .164.164 0 00.076-.041.133.133 0 00.038-.072 4.077 4.077 0 01.637-1.726 4.697 4.697 0 011.37-1.36c1.728-1.328-.306-3.61-1.542-4.764zM18.658 14.031a.108.108 0 01.07-.03.106.106 0 01.071.023.14.14 0 01.045.07.163.163 0 010 .088c-.3.578-.42 1.26-.341 1.93.079.672.352 1.29.775 1.749 1.396 1.438.423 3.33-.06 4.06a.121.121 0 01-.052.067.093.093 0 01-.077.007.112.112 0 01-.061-.054.147.147 0 01-.016-.089 5.035 5.035 0 00-.487-1.723c-.26-.529-.61-.989-1.028-1.352-1.288-1.323.227-3.597 1.161-4.746z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.002 28.069a.437.437 0 00-.444.32.442.442 0 00-.012.182L14.032 31h22.491l.491-2.43a.44.44 0 00-.456-.502l-.034.002H14.036l-.034-.002zm2.164 13.593L14.432 33H18v9.04h-1.337a.5.5 0 01-.497-.378zM20 42.04h4V33h-4v9.04zm10 0h-4V33h4v9.04zm2 0h1.873a.5.5 0 00.493-.369L36.12 33H32v9.04zM14.1 25.07a3.44 3.44 0 00-3.508 4.027l.003.019.004.018 2.63 13.14.004.018.004.018a3.5 3.5 0 003.479 2.73h17.11a3.5 3.5 0 003.464-2.695l.005-.024.005-.024 2.66-13.16.004-.02.004-.02a3.441 3.441 0 00-3.507-4.027H14.099z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M14.953 22.472a.98.98 0 01-.253.324.89.89 0 01-.355.18.848.848 0 01-.391.003.885.885 0 01-.357-.175c-1.079-.887-1.878-2.115-2.288-3.515a7.704 7.704 0 01-.004-4.305c.408-1.401 1.205-2.63 2.281-3.52a6.244 6.244 0 013.734-1.45 8.479 8.479 0 012.708-3.494A7.54 7.54 0 0124 5.02a7.41 7.41 0 014.121.913 8.215 8.215 0 013.112 3.073 5.013 5.013 0 012.274.498 6.593 6.593 0 012.735 1.895 7.388 7.388 0 011.58 3.077c.329 1.5.2 3.076-.366 4.49-.567 1.413-1.54 2.59-2.776 3.35a1.138 1.138 0 01-1.05.06 1.23 1.23 0 01-.44-.336 1.362 1.362 0 01-.264-.51.849.849 0 01.032-.549.76.76 0 01.355-.392 4.488 4.488 0 001.929-1.727 5.052 5.052 0 00.746-2.59 5.07 5.07 0 00-.68-2.613 4.518 4.518 0 00-1.882-1.785 3.449 3.449 0 00-2.612-.598l-.714.11-.234-.498c-.449-.874-1.021-1.713-1.768-2.334a5.903 5.903 0 00-2.537-1.243 5.513 5.513 0 00-4.007.778c-1.209.767-2.127 1.975-2.592 3.409l-.255.797-.775-.067h-.327a4.27 4.27 0 00-2.601.86 4.868 4.868 0 00-1.676 2.326 5.305 5.305 0 00-.143 2.949 4.98 4.98 0 001.442 2.503c.212.19.358.452.414.744.056.293.017.597-.108.862h.02z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyWellnessRoom;
