import { stringify } from 'query-string';
import { useQuery, UseQueryResult } from 'react-query';
import { getI18nLanguageCode } from '../../../../../../../i18n';
import { request } from '../../../../../../common/src/legacy/services/httpSessionService';
import { AddressSearchParams } from '../types/API';
import { MINIMUM_REQUIRED_CHARS } from './getAddress';

interface SubCity {
  country_code?: string;
  name: string;
  zipcode?: string | number;
}

export type City = SubCity & { country_code?: string };

interface CitySearchResponse {
  items: City[];
}

export const getCities = async ({
  query,
  language,
  countryCode = 'BE',
}: AddressSearchParams): Promise<[CitySearchResponse, number]> => {
  const qParamsObject = {
    q: query,
    lang: language || getI18nLanguageCode(),
    country_code: countryCode,
  };
  const qString = stringify(qParamsObject);

  const path = `v1/api/address/cities?${qString}`;
  const [resp, status] = await request({ path });
  return [resp, status];
};

export const useCitySearch = (
  params: AddressSearchParams,
): UseQueryResult<City[]> => {
  const { query } = params;

  return useQuery(
    ['address', 'search', 'cities', ...Object.values(params)],
    async () => {
      const [resp, status] = await getCities(params);

      if (status >= 400) throw new Error();

      return resp.items;
    },
    {
      enabled: query.length > MINIMUM_REQUIRED_CHARS,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
};
