const Modal = {
  baseStyle: {
    dialog: { border: '1px solid', borderColor: 'gray.strokeAndBg', mx: '2', borderRadius: '12px', },
    header: {
      textAlign: 'left',
      fontSize: '18px',
      fontWeight: '500',
      paddingY: '6',
      borderBottom: '1px solid',
      borderColor: 'gray.strokeAndBg',
    },
    footer: {
      borderBottomRightRadius: '12px',
      borderBottomLeftRadius: '12px',
      bg: 'white',
      paddingY: '6',
      borderTop: '1px solid',
      borderColor: 'gray.strokeAndBg',
    },
    body: {
      paddingY: '8',
    },
    closeButton: {
      top: '4',
    },
  },
};

export default Modal;
