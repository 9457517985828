import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersPowerOn: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path fill="currentColor" d="M27 11h-4v11h4V11z"></path>
    <path
      fill="currentColor"
      d="M33.13 15.87l-2.85 2.85a9 9 0 11-10.56 0l-2.85-2.85a13 13 0 1016.26 0z"
    ></path>
  </Icon>
);

export default GeneralOthersPowerOn;
