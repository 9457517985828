import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleDriver: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25 5a20 20 0 100 40 20 20 0 000-40zm-4 33.76a.51.51 0 01-.65.49 15.06 15.06 0 01-8.75-7.53.5.5 0 01.45-.72H16a5 5 0 015 5v2.76zM25 30a3 3 0 110-5.999A3 3 0 0125 30zm13.4 1.72a15.07 15.07 0 01-9.78 7.84.5.5 0 01-.62-.49V36a5 5 0 015-5h5a.5.5 0 01.4.72zm.85-7.47l-7.14-2.69a17 17 0 00-14.22 0l-7.14 2.69a.5.5 0 01-.68-.5 15 15 0 0129.86 0 .5.5 0 01-.68.5z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleDriver;
