import { TimeInput as MantineTimeInput } from '@mantine/dates';
import { Icon, useTheme } from '@wegroup/design-system';
import React from 'react';

interface Props {
  value: Date | null;
  onChange: (date: Date | null) => void;
}

const TimeInput: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
}) => {
  const { radii, colors } = useTheme();

  return (
    <MantineTimeInput
      sx={{
        flexGrow: 1,
        '.mantine-TimeInput-input': {
          borderRadius: radii.md,
          height: 40,
          '&:focus-within, &:focus': {
            borderColor: colors.primary[500],
          },
        },
      }}
      icon={<Icon name="GeneralOthersClock" />}
      styles={{
        controls: { justifyContent: 'center', marginLeft: -20 },
      }}
      value={value}
      onChange={onChange}
    />
  );
};

export default TimeInput;
