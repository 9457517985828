import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationOldtimer: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M61.58 116.376a5.13 5.13 0 0 1 5.125 5.128v17.936H56.453v-17.936a5.128 5.128 0 0 1 5.128-5.128Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx={56.997}
          cy={190.5}
          r={33.5}
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <circle
          cx={56.997}
          cy={190.5}
          r={21.5}
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <circle
          cx={296.997}
          cy={190.5}
          r={33.5}
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <circle
          cx={296.997}
          cy={190.5}
          r={21.5}
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M133.329 183.004h103.968a25.362 25.362 0 0 0 21.972-13.38 43.45 43.45 0 0 1 53.692-19.587 43.44 43.44 0 0 1 16.912 11.751 5.124 5.124 0 0 0 7.656-6.8 53.705 53.705 0 0 0-70.027-9.051 53.699 53.699 0 0 0-17.205 18.739 14.89 14.89 0 0 1-13 8.092H133.329c-11.66 0-20.444-7.248-30.608-15.64-11.488-9.484-24.508-20.236-43.704-20.236a65.412 65.412 0 0 0-38.8 13.72 5.123 5.123 0 0 0-1.312 7.128 5.126 5.126 0 0 0 7.128 1.312A55.64 55.64 0 0 1 59 147.144c15.512 0 26.036 8.688 37.176 17.888 10.72 8.828 21.804 17.972 37.152 17.972Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M297.329 136.876a53.807 53.807 0 0 0-47.052 27.784 14.89 14.89 0 0 1-13 8.092H133.329c-11.66 0-20.444-7.248-30.608-15.64-9.88-8.156-20.92-17.2-36-19.6v-16h61.204a15.659 15.659 0 0 0 15.656-15.656V67.5c-.065-1.544 2.777-7.755 7.688-7.496h143.5a10.249 10.249 0 0 1 10.248 10.244v67.256a53.498 53.498 0 0 0-7.688-.628Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M251.205 121.504v15.512a13.274 13.274 0 0 1-3.284 8.736l-10.688 12.22a13.279 13.279 0 0 1-10 4.532h-24.716v-41h48.688Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M251.205 70.252h33.292a7.712 7.712 0 0 1 7.712 7.712v28.156a15.384 15.384 0 0 1-15.384 15.384h-25.6l-.02-51.252Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M251.205 70.252h-48.688V121.5h48.688V70.252ZM153.829 121.504V77.96a7.71 7.71 0 0 1 7.708-7.708h40.98v51.252h-48.688Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M202.517 121.504v41h-40.996a7.687 7.687 0 0 1-7.11-4.757 7.678 7.678 0 0 1-.582-2.947v-33.296h48.688ZM220.453 131.752h-7.684M192.269 131.752h-7.688"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationOldtimer;
