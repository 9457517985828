import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralRiskObjectsFlight: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M42.67 20.94a1.294 1.294 0 01-.74-2.48l3-.91a15.566 15.566 0 00-3.83-.47 16.21 16.21 0 00-5.58 1l-4.92 1.78a2.76 2.76 0 01-1.94 0L16.06 15h-.12l-2.67.89a.2.2 0 00-.09.31l6.53 7.64a1.35 1.35 0 01-.4 2.07l-7.61 4a.67.67 0 01-.72-.07L6 26.06a.17.17 0 00-.17 0l-2.67.9a.18.18 0 00-.11.26c.49 1 2.93 6.24 4 8A3.83 3.83 0 0010.94 37l8.42-1.51A19.761 19.761 0 0024 34l16.67-6.89a10.8 10.8 0 006.26-7v-.07c.04-.133.06-.271.06-.41l-4.32 1.31zm-11.13 4.78a.47.47 0 01-.63-.26l-.75-1.79a.47.47 0 01.26-.63.429.429 0 01.19 0 .46.46 0 01.44.3l.75 1.79a.47.47 0 01-.26.59zm3.58-1.5a.47.47 0 01-.63-.26l-.75-1.79a.47.47 0 01.26-.63.41.41 0 01.19 0 .47.47 0 01.44.3l.75 1.79a.47.47 0 01-.26.59zm3.58-1.5a.47.47 0 01-.63-.26l-.75-1.79a.47.47 0 01.26-.63.41.41 0 01.19 0 .47.47 0 01.44.3l.79 1.75a.471.471 0 01-.3.63z"
    ></path>
  </Icon>
);

export default GeneralRiskObjectsFlight;
