import React from 'react';
import { Skeleton, Flex, Box, Spacer } from '@wegroup/design-system';
import GeneralPageLayout from '../../../../common/src/components/GeneralPageLayout';
import useHasVisitedModule from '../../../../common/src/hooks/useHasVisitedModule';
import LoadingSpinner from '../../../../common/src/components/LoadingSpinner';

const ClaimsLoading: React.FC = () => {
  const hasVisited = useHasVisitedModule('claims');
  if (!hasVisited) return <LoadingSpinner />;
  return (
    <GeneralPageLayout>
      <Flex w="100%" alignItems="center">
        <Skeleton w="200px" h="45px" />
        <Spacer />
        <Skeleton w="200px" h="50px" mr="4" />
        <Skeleton w="200px" h="50px" />
      </Flex>
      <Flex w="100%" mt="16">
        <Skeleton w="160px" h="30px" mr="12" />
        <Skeleton w="160px" h="30px" mr="12" />
        <Skeleton w="120px" h="30px" />
      </Flex>
      <Flex w="100%" mt="10">
        <Box>
          <Skeleton w="200px" h="30px" mr="4" />
          <Skeleton w="200px" h="75px" mt="6" />
          <Skeleton w="200px" h="75px" mt="6" />
          <Skeleton w="200px" h="45px" mt="6" />
          <Skeleton w="200px" h="45px" mt="6" />
          <Skeleton w="200px" h="45px" mt="6" />
          <Skeleton w="200px" h="30px" mt="6" />
        </Box>
        <Skeleton w="100%" h="50px" />
      </Flex>
    </GeneralPageLayout>
  );
};

export default ClaimsLoading;
