import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleVan: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M46.2 26.66l-5.56-4.47a3.26 3.26 0 01-.8-1l-3.26-5.87A4.38 4.38 0 0032.79 13H6.33a1.87 1.87 0 00-1.75 1.49l-1.24 5.84A15.65 15.65 0 003 23.6v9.3A2.08 2.08 0 005.05 35H7a4.5 4.5 0 109 0h16a4.5 4.5 0 109 0h4a2.08 2.08 0 002-2.1v-4.5a2.231 2.231 0 00-.8-1.74zM11.5 36.75A2.25 2.25 0 019.31 35a2.07 2.07 0 01-.06-.5 2.25 2.25 0 014.5 0c0 .169-.02.336-.06.5a2.25 2.25 0 01-2.19 1.75zm25 0A2.25 2.25 0 0134.31 35a2.07 2.07 0 01-.06-.5 2.25 2.25 0 114.5 0c0 .169-.02.336-.06.5a2.25 2.25 0 01-2.19 1.75z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleVan;
