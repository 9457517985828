import React from 'react';
import { useTranslation } from 'react-i18next';
import { AvatarPickerOption } from '.';
import { getInitialsByName } from '../../../../../common/src/legacy/services/stringService';
import { Text, Link, HStack, MenuButton } from '../../../../index';
import AvatarPickerAvatars from './AvatarPickerAvatars';

interface Props {
  selectedOptions: AvatarPickerOption[];
  placeholder?: string;
}

const MultipleAvatarPickerMenuButton: React.FC<Props> = ({
  selectedOptions,
  placeholder,
}) => {
  const { t } = useTranslation();

  const getLabel = () => {
    if (selectedOptions.length) {
      if (selectedOptions.length > 1) {
        return `${selectedOptions.length} ${t('OFFICE_EMPLOYEES')}`;
      }
      return getInitialsByName(selectedOptions[0].label).firstNameWithInitials;
    }
    return placeholder || t('ASSIGN-SOMEONE');
  };

  return (
    <MenuButton>
      <HStack spacing="2">
        <AvatarPickerAvatars selectedOptions={selectedOptions} />
        <Link as={Text} variant="gray" textStyle="normalBody">
          {getLabel()}
        </Link>
      </HStack>
    </MenuButton>
  );
};

export default MultipleAvatarPickerMenuButton;
