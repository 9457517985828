import { NotificationPopUp, IconNotificationBellFilled } from 'wg-fe-ui';
import React, { useRef, useState } from 'react';
import { handleResponseLanguage } from '../../../../../../i18n';
import styled from 'styled-components';
import useNewNotification from '../hooks/useNewNotification';
import { IMessage } from '@novu/notification-center';
import { getNotificationPayload } from '../hooks/useNotificationPayload';

const NotificationsPopUp: React.FC = () => {
  const [shownNotifications, setShownNotifications] = useState<
    Record<string, IMessage>
  >({});
  const shownRef = useRef({});

  const showPushNotification = (notification: IMessage) => {
    setShownNotifications((prevValue) => {
      const items = { ...prevValue };
      items[notification._id] = notification;
      return items;
    });
  };

  useNewNotification(showPushNotification);

  function truncate(str: string, n: number) {
    return str.length > n ? str.substr(0, n - 1) + ' ...' : str;
  }

  const hidePopUp = (id: string) => {
    setShownNotifications(() => {
      delete shownRef.current?.[id];
      return shownRef.current;
    });
  };

  const getPopUpIcon = (notificationType: string) => {
    switch (notificationType) {
      case 'prospect':
        return <IconNotificationBellFilled color="white" />;
      default:
        return undefined;
    }
  };

  const handleDescLangNotFound = (desc: string) => {
    if (typeof desc === 'string') return desc;
  };

  return (
    <NotificationsPopUpContainer>
      {Object.values(shownNotifications)?.map((notification: IMessage) => {
        const novuId = notification._id;

        const { action_url, custom_attributes, title } =
          getNotificationPayload(notification);
        const { description, title_content, type } = custom_attributes || {};
        return (
          <NotificationPopUp
            to={action_url || '#'}
            title={truncate(
              handleResponseLanguage(title_content) ||
                title ||
                title_content ||
                '',
              45,
            )}
            key={novuId}
            hideDelay={5}
            icon={getPopUpIcon(type)}
            hidePopup={() => hidePopUp(novuId)}
            description={truncate(
              handleResponseLanguage(description) ||
                handleDescLangNotFound(description) ||
                '',
              85,
            )}
          />
        );
      })}
    </NotificationsPopUpContainer>
  );
};

const NotificationsPopUpContainer = styled.div`
  position: fixed;
  display: flex;
  right: 2rem;
  top: 6rem;
  max-width: 45rem;
  z-index: 999999;
  flex-direction: column;
`;

export default NotificationsPopUp;
