/**
 * Retrieves the session id used by flows to identify the session
 * @returns The session id included in the url
 */
const useSessionId = (): string => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  const session_id = params.get('session_id');
  if (session_id && typeof session_id === 'string') {
    return session_id;
  }

  const sessionId = params.get('sessionId');
  if (sessionId && typeof sessionId === 'string') {
    return sessionId;
  }

  return '';
};

export default useSessionId;
