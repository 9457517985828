import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationShoppingBag: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 24.5a1.5 1.5 0 011.5 1.5v2a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-2a1.5 1.5 0 013 0v2a3.5 3.5 0 01-3.5 3.5H19a3.5 3.5 0 01-3.5-3.5v-2a1.5 1.5 0 011.5-1.5z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M36.5 14.229A5.002 5.002 0 0140 19v19a5 5 0 01-5 5H14a5 5 0 01-5-5V19a5 5 0 014.5-4.975V13A3.5 3.5 0 0117 9.5h16a3.5 3.5 0 013.5 3.5v1.229zm-3-.229v-1a.5.5 0 00-.5-.5H17a.5.5 0 00-.5.5v1h17zM12 19a2 2 0 012-2h21a2 2 0 012 2v19a2 2 0 01-2 2H14a2 2 0 01-2-2V19z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationShoppingBag;
