import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsKeymanInsurance: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 25.875a7 7 0 100 14 7 7 0 000-14zm-11 7c0-6.075 4.925-11 11-11s11 4.925 11 11-4.925 11-11 11-11-4.925-11-11z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M42.164 6.71a2 2 0 010 2.83L23.827 27.876a2 2 0 11-2.829-2.829L39.336 6.711a2 2 0 012.828 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M37.086 8.96a2 2 0 012.828 0l4.5 4.5a2 2 0 11-2.828 2.829l-4.5-4.5a2 2 0 010-2.828zM30.336 15.71a2 2 0 012.828 0l4.5 4.5a2 2 0 11-2.828 2.829l-4.5-4.5a2 2 0 010-2.829z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsKeymanInsurance;
