import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralLabelsQuality: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M43.45 26.44a2.69 2.69 0 01-.56-1.64 2.72 2.72 0 01.53-1.6 2.699 2.699 0 00-.83-3.93 2.71 2.71 0 01-1.34-2.33c-.002-.22.025-.438.08-.65a2.8 2.8 0 00.08-.64A2.7 2.7 0 0039.06 13a2.71 2.71 0 01-2.31-2.27 2.69 2.69 0 00-2.66-2.29 2.8 2.8 0 00-.7.09 2.7 2.7 0 01-3-1.21 2.71 2.71 0 00-3.95-.75 2.69 2.69 0 01-3.23.04 2.69 2.69 0 00-3.94.82 2.71 2.71 0 01-2.33 1.34 2.34 2.34 0 01-.64-.08 2.94 2.94 0 00-.65-.07A2.69 2.69 0 0013 10.94a2.71 2.71 0 01-2.27 2.31 2.69 2.69 0 00-2.2 3.36 2.7 2.7 0 01-1.21 3A2.7 2.7 0 006 21.92c0 .594.196 1.17.56 1.64a2.74 2.74 0 01.55 1.64 2.78 2.78 0 01-.52 1.6 2.64 2.64 0 00-.53 1.59 2.72 2.72 0 001.35 2.34 2.71 2.71 0 011.34 2.33 2.34 2.34 0 01-.08.64 2.94 2.94 0 00-.07.65A2.69 2.69 0 0010.94 37a2.71 2.71 0 012.31 2.27 2.7 2.7 0 003.36 2.2 2.7 2.7 0 013 1.21 2.71 2.71 0 003.95.75 2.69 2.69 0 011.64-.56 2.78 2.78 0 011.6.52 2.64 2.64 0 001.59.53 2.7 2.7 0 002.34-1.35 2.7 2.7 0 013-1.26A2.69 2.69 0 0037 39.06a2.71 2.71 0 012.27-2.31 2.69 2.69 0 002.29-2.66c0-.236-.03-.471-.09-.7a2.7 2.7 0 011.21-3 2.71 2.71 0 00.75-3.95h.02zM25 39.53A14.53 14.53 0 1139.53 25 14.54 14.54 0 0125 39.53z"
    ></path>
    <path
      fill="currentColor"
      d="M25 13a12 12 0 100 24 12 12 0 000-24zm3.35 17.22L25 28.46l-3.35 1.76a.201.201 0 01-.21-.015.2.2 0 01-.08-.195l.64-3.73-2.71-2.64a.2.2 0 01.11-.34l3.75-.54 1.67-3.4a.21.21 0 01.36 0l1.67 3.4 3.75.54a.2.2 0 01.164.245.2.2 0 01-.054.095L28 26.28l.64 3.72a.2.2 0 01-.29.22z"
    ></path>
  </Icon>
);

export default GeneralLabelsQuality;
