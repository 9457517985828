import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleAttentionAssistance: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M18.57 6.47a1.66 1.66 0 00-2.14-.53 1.481 1.481 0 00-.75.91 1.33 1.33 0 00.17 1.08L17 9.79a.61.61 0 01.08.52.74.74 0 01-.37.43A3.64 3.64 0 0014.86 13a3.34 3.34 0 00.42 2.66l1.18 1.86a1.6 1.6 0 001.36.72c.272.002.54-.063.78-.19a1.44 1.44 0 00.75-.91 1.33 1.33 0 00-.17-1.08L18 14.21a.6.6 0 01-.08-.5.719.719 0 01.37-.45 3.64 3.64 0 001.85-2.18 3.31 3.31 0 00-.39-2.75l-1.18-1.86zM26.05 6.47a1.65 1.65 0 00-2.13-.53 1.41 1.41 0 00-.782 1.467c.027.186.093.364.192.523l1.19 1.86a.6.6 0 01.07.52.71.71 0 01-.37.43A3.63 3.63 0 0022.35 13a3.291 3.291 0 00.41 2.66L24 17.53a1.59 1.59 0 001.36.72 1.57 1.57 0 00.77-.19 1.442 1.442 0 00.75-.91 1.33 1.33 0 00-.17-1.08l-1.18-1.86a.6.6 0 01-.08-.5.72.72 0 01.37-.45 3.66 3.66 0 001.86-2.18 3.28 3.28 0 00-.4-2.75l-1.23-1.86zM34.72 8.33l-1.19-1.86a1.65 1.65 0 00-2.13-.53 1.439 1.439 0 00-.75.91 1.33 1.33 0 00.17 1.08L32 9.79a.64.64 0 01.08.52.78.78 0 01-.37.43A3.61 3.61 0 0029.83 13a3.34 3.34 0 00.42 2.66l1.18 1.86a1.59 1.59 0 001.36.72 1.57 1.57 0 00.77-.19 1.459 1.459 0 00.76-.91 1.33 1.33 0 00-.17-1.08L33 14.21a.7.7 0 01-.08-.51.74.74 0 01.38-.44 3.68 3.68 0 001.85-2.18 3.31 3.31 0 00-.43-2.75zM38.31 26H37v-4a1 1 0 00-1-1H14a1 1 0 00-1 1v10.62A12.25 12.25 0 0024.87 45a12 12 0 0012-10h1.63a4.509 4.509 0 004.5-4.94A4.63 4.63 0 0038.31 26zm.19 6H37v-3h1.5a1.5 1.5 0 110 3z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleAttentionAssistance;
