import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationActivePV: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M40 19v-3a2 2 0 00-2-2H27a3 3 0 00-3-3H10a2 2 0 00-2 2v27a2 2 0 002 2h30a2 2 0 002-2V21a2 2 0 00-2-2zM11 38V15a1 1 0 011-1h12a3 3 0 003 3h9a1 1 0 011 1v1H16a2 2 0 00-2 2v18h-2a1 1 0 01-1-1z"
    ></path>
  </Icon>
);

export default GeneralNavigationActivePV;
