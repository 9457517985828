import { Campaign } from '../../../../types/Campaigns';
import { ToastSeverity } from '../../../../types/Common';

import i18n, { handleResponseLanguage } from '../../../../i18n';
import { TextOption } from '../../../../types/ProductFactory';
import { retrieveAutomobileBrandsREST } from '../../../common/src/legacy/services/apiRouterService';
import { AutomobileBrandProps } from '../../../../types/VehicleResponse';
import {
  ProductsQualityRespWithPagination,
  ProductWithQuality,
  QuoteCoverageGroup,
  QuoteGuaranteeContent,
  QuoteQuality,
} from '../../../../types/Quotes';
import { Broker } from '../../../../types/Broker';
import cogoToast from 'cogo-toast';
import { matchPath } from 'react-router';
import { addDays, differenceInDays } from 'date-fns';
import { uniqBy } from 'lodash';
import { OfferState } from './offersService';
import { toEuro } from '../utils/stringUtils';
import { isRiskAnalysisFlow } from '../../../common/src/utils/globalVariables';

export const CAMPAIGN_DATA = 'campaign_data';
export const BROKER_DATA = 'broker_data';

export const hasCampaignNotStarted = (campaignStartDate?: string): boolean =>
  !!campaignStartDate && new Date() < new Date(`${campaignStartDate} 00:00`);

export const hasCampaignEnded = (campaignEndDate?: string): boolean =>
  !!campaignEndDate && new Date() > new Date(`${campaignEndDate} 00:00`);

export const daysUntilCampaign = (campaignStartDate?: string): number =>
  campaignStartDate
    ? differenceInDays(addDays(new Date(campaignStartDate), 1), new Date())
    : 0;

export const isCampaignInProgress = (
  campaignStartDate?: string,
  campaignEndDate?: string,
): boolean =>
  !hasCampaignNotStarted(campaignStartDate) &&
  !hasCampaignEnded(campaignEndDate);

export const getCampaignData = (): Campaign =>
  sessionStorage.getItem(CAMPAIGN_DATA) &&
  JSON.parse(sessionStorage.getItem(CAMPAIGN_DATA) || '');

export const getBrokerData = (): Broker =>
  sessionStorage.getItem(BROKER_DATA) &&
  JSON.parse(sessionStorage.getItem(BROKER_DATA) || '');

export const isSocialUser = Boolean(getCampaignData());

export const isSocialLoginUrl = Boolean(
  window.location.href.match(/\.campaigns|conversations\./),
);

export const isCampaignsUrl = Boolean(
  window.location.href.match(/\.campaigns\./),
);

export const isConversationUrl = Boolean(
  window.location.href.match(/conversations\./),
);

export const isTextOptions = (options: unknown): options is TextOption[] => {
  return (options as TextOption) !== undefined;
};

export const getBrandFilter = (campaign: Campaign): string[] => {
  if (campaign) {
    const { specifications } = campaign;
    return specifications?.car_brand_filters || [];
  }
  return [];
};

export const getBrandNameFilter = async (
  campaign: Campaign,
): Promise<string[] | undefined> => {
  const [resp, status] = await retrieveAutomobileBrandsREST();
  if (status === 200) {
    const { items } = resp;
    return items
      .filter(({ brand }: AutomobileBrandProps) =>
        getBrandFilter(campaign).includes(brand.key),
      )
      .map(({ brand }: AutomobileBrandProps) => brand.name);
  }
};

export const getTextForRiskObject = (riskObject: string): string => {
  switch (riskObject) {
    case 'home':
      return i18n.t('HOME-INSURANCE');
    case 'family':
      return i18n.t('Family insurance');
    case 'car':
      return i18n.t('Car insurance');
    case 'legal':
      return i18n.t('Legal insurance');
    case 'funeral':
      return i18n.t('Funeral insurance');
    case 'two_wheeler':
      return i18n.t('TWO_WHEELER');
    case 'bicycle':
      return i18n.t('BICYCLE');
    case 'boat':
      return i18n.t('BOAT-INSURANCE');
    default:
      return i18n.t(riskObject);
  }
};

export const getCoverageRatingForBase = (rating: number, base = 5): number => {
  const MAX_VALUE = 100;
  return Number(((rating / MAX_VALUE) * base).toFixed(2));
};

export const getCoveragesGroups = (
  quality?: QuoteQuality[],
): [QuoteCoverageGroup[], QuoteCoverageGroup[]] | [undefined, undefined] => {
  if (quality != null) {
    const sortedQualities: QuoteCoverageGroup[] = uniqBy(
      quality
        .map(({ coverage_groups }) =>
          coverage_groups
            .filter((cg) => cg.is_important_to_customer)
            .map((group) => ({
              ...group,
              rating: getCoverageRatingForBase(group.rating),
            })),
        )
        .flat()
        .sort((a, b) => b.rating - a.rating),
      (group: QuoteCoverageGroup) => group.key,
    );
    const goodSort = sortedQualities
      .filter(({ rating }) => rating >= 3)
      .slice(0, 4);
    const badSort = sortedQualities
      .filter(({ rating }) => rating < 3)
      .reverse()
      .slice(0, 4);

    return [goodSort, badSort];
  }
  return [undefined, undefined];
};

export const toastForSeverity = (
  reason: string,
  severity?: ToastSeverity,
): void => {
  const calculatedDuration = reason
    ? 3 + handleResponseLanguage(reason)?.length / 25
    : 10;

  switch (severity) {
    case 'NORMAL':
      cogoToast.info(reason, {
        hideAfter: calculatedDuration,
      });
      break;
    case 'WARNING':
      cogoToast.warn(reason, {
        hideAfter: calculatedDuration,
      });
      break;
    case 'ERROR':
      cogoToast.error(reason, {
        hideAfter: calculatedDuration,
      });
      break;
    default:
      cogoToast.info(reason, {
        hideAfter: calculatedDuration,
      });
      break;
  }
};

export const getCampaignIdFromURL = (url: string): string => {
  // replace https:// to an empty sting so we can safely extract the campaigns short id
  const replacedUrl = url.replace(/(^\w+:|^)\/\//, '');
  const prefix = replacedUrl.split('.')[0];

  return prefix;
};

export const getRouteMatch = (
  pathname: string,
):
  | {
      position?: string;
      sessionId?: string;
    }
  | undefined => {
  const origin = isRiskAnalysisFlow() ? `/risk-analysis` : '/product/session';
  if (pathname.includes('selection'))
    return matchPath<{ position: string }>(pathname, {
      path: `${origin}/selection/:position`,
    })?.params;
  else if (pathname.includes('questions'))
    return matchPath<{ position: string; sessionId: string }>(pathname, {
      path: `${origin}/questions/:sessionId/:position`,
    })?.params;
  else if (pathname.includes('finalize'))
    return matchPath<{ sessionId: string }>(pathname, {
      path: `${origin}/finalize/:sessionId`,
    })?.params;
  else if (pathname.includes('offers'))
    return matchPath<{ sessionId: string }>(pathname, {
      path: `${origin}/offers/:sessionId`,
    })?.params;
  else if (pathname.includes('sign'))
    return matchPath<{ sessionId: string }>(pathname, {
      path: `${origin}/sign/:sessionId`,
    })?.params;
  else if (pathname.includes('/inalize-offers'))
    return matchPath<{ sessionId: string }>(pathname, {
      path: `${origin}/finalize-offers/:sessionId`,
    })?.params;
  else if (pathname.includes('devtools'))
    return matchPath<{ sessionId: string }>(pathname, {
      path: `${origin}/devtools/:sessionId`,
    })?.params;
  return undefined;
};

export const getGuaranteeNameForKey = (
  guarantee: string,
  guarantee_content?: QuoteGuaranteeContent[] | null,
): string => {
  if (guarantee === 'fire') return i18n.t('anon_pack_types.fire');

  const fullVersion = guarantee_content?.find(
    ({ key }) => key === guarantee,
  )?.name;

  if (fullVersion) {
    return fullVersion;
  }

  const translationString = `anon_pack_types.${guarantee}`;
  if (i18n.exists(translationString)) {
    return i18n.t(translationString);
  }

  return guarantee;
};

export const getGuaranteeDescriptionForKey = (
  guarantee: string,
  guarantee_content?: QuoteGuaranteeContent[] | null,
): string => {
  if (guarantee === 'fire') return i18n.t('building_description');
  return (
    guarantee_content?.find(({ key }) => key === guarantee)?.description ||
    guarantee
  );
};

/**
 * Ugly function for now as BE isn't up-to-date yet
 */
export const groupByInsuranceCompanyForId = (
  products: ProductsQualityRespWithPagination,
  riskObject: string,
  selectedGuarantees: string[],
  selectedCompanies: string[],
  filterByGuarantee?: string,
): string[] => {
  const newResp = { ...products };

  // Will filter out quotes if filterByGuarantee is given for car offers
  if (filterByGuarantee) {
    newResp[riskObject] = products[riskObject].filter(
      ({ info }: ProductWithQuality) =>
        info.insurance_name.match(new RegExp(`^${filterByGuarantee}.*`, 'i')),
    );

    // Clear omnium and mini-omnium tags
    if (selectedGuarantees) {
      const carGuarantees = ['omnium', 'mini_omnium'];
      selectedGuarantees = selectedGuarantees.filter(
        (guarantee) => !carGuarantees.includes(guarantee.toLowerCase()),
      );
    }
  }

  // Will put all quotes for a company in a separate variable
  const quotesForCompany = {};
  selectedCompanies.forEach((company) => {
    quotesForCompany[company] = newResp[riskObject].filter(
      ({ info }: ProductWithQuality) => info.company.key === company,
    );
  });

  // Search for the most fitting quote if no qualis available
  // Currently hardcoded

  // if (
  //   selectedGuarantees
  //   // &&
  //   // ['family', 'legal', 'funeral'].includes(riskObject)
  // ) {
  //   newResp[riskObject].forEach(
  //     ({ selected_guarantee_tags, hash_id }: ProductWithQuality) => {
  //       scoreForHashId[hash_id] = 0;

  //       return selectedGuarantees.forEach((guarantee) => {
  //         if (selected_guarantee_tags.includes(guarantee)) {
  //           scoreForHashId[hash_id]++;
  //         }
  //       });
  //     },
  //   );

  //   console.log(scoreForHashId);

  //   // Sort by highest score
  //   // newResp[riskObject].sort(
  //   //   (a, b) => scoreForHashId[b.hash_id] - scoreForHashId[a.hash_id],
  //   // );

  //   // Make sure at least 1 quote is returned per company
  //   // selectedCompanies.forEach((company) => {
  //   //   const filteredQuotesForCompany = newResp[riskObject].filter(
  //   //     ({ info }: ProductWithQuality) => info.company.key === company,
  //   //   );
  //   //   if (
  //   //     filteredQuotesForCompany.length === 0 &&
  //   //     quotesForCompany[company][0]
  //   //   ) {
  //   //     newResp[riskObject].push(quotesForCompany[company][0]);
  //   //   }
  //   // });
  // }

  // Max 1 quote per company => unique by company key
  newResp[riskObject] = uniqBy(
    // Sort the array to keep the one with highest matching score
    newResp[riskObject].sort(
      (a, b) =>
        (b?.info?.insurance_name === 'fire' ? 1 : 0) +
        (b?.selected_guarantees?.length || 0) -
        ((a?.info?.insurance_name === 'fire' ? 1 : 0) +
          (a?.selected_guarantees?.length || 0)),
    ),
    // .sort((a, b) => {
    //   if (a.matching_score && b.matching_score) {
    //     return b.matching_score - a.matching_score;
    //   }
    //   return 1;
    // }),
    (item: ProductWithQuality) => item.info.company.key,
  );

  // Return the hash_id's only as these will be used as identifiers
  return newResp[riskObject]
    .sort((a, b) => {
      if (a.matching_score && b.matching_score) {
        return b.matching_score - a.matching_score;
      }
      return 1;
    })
    .map(({ hash_id }) => hash_id);
};

export const getFieldValue = (
  offerState: OfferState,
  value?: string | number,
): string | number | undefined => {
  if (offerState === OfferState.ERROR) {
    return '-';
  } else if (offerState === OfferState.LOADING) {
    return undefined;
  } else if (value != null) {
    return value;
  }

  return '-';
};

export const getEuroValue = (
  offerState: OfferState,
  value?: number,
): string | number | undefined => {
  const mappedValue = getFieldValue(offerState, value);
  if (mappedValue && mappedValue !== '-') {
    // Since we are the context of a euro, we are sure this is a number
    return toEuro(mappedValue as number);
  }
  return mappedValue;
};
