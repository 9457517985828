import { BoxProps, ComponentWithAs, TextProps } from '@wegroup/design-system';
import GrayedCard from './grayed';
import DefaultCard from './default';
import InsideCard from './inside';

type CardVariants = 'default' | 'inside' | 'grayed';

export interface CardProps {
  variant?: CardVariants;
  textStyle?: TextProps['textStyle'];
  title?: string;
  rightElement?: JSX.Element;
}

const Card: ComponentWithAs<'div', CardProps & BoxProps> = ({
  variant = 'default',
  ...props
}) => {
  switch (variant) {
    case 'grayed':
      return <GrayedCard {...props} />;

    case 'inside':
      return <InsideCard {...props} />;

    default:
      return <DefaultCard {...props} />;
  }
};

export default Card;
