import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMotorcycle: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2ZM201.099 142.041a1.998 1.998 0 0 1 2.36 1.558l8.5 41.5a1.999 1.999 0 1 1-3.918.802l-8.5-41.5a1.998 1.998 0 0 1 1.558-2.36ZM234.106 144.211a2 2 0 0 1 2.683.895l16 32a2 2 0 0 1-3.578 1.788l-16-32a2 2 0 0 1 .895-2.683Z"
          fill={colors.primary[500]}
        />
        <path
          d="M133 191c0 18.778-15.222 34-34 34s-34-15.222-34-34 15.222-34 34-34 34 15.222 34 34Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99 221c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30Zm0 4c18.778 0 34-15.222 34-34s-15.222-34-34-34-34 15.222-34 34 15.222 34 34 34Z"
          fill={colors.primary[500]}
        />
        <path
          d="M117.308 191c0 10.111-8.197 18.308-18.308 18.308S80.692 201.111 80.692 191 88.89 172.693 99 172.693s18.308 8.196 18.308 18.307Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99 205.308c7.902 0 14.308-6.406 14.308-14.308S106.902 176.693 99 176.693 84.692 183.098 84.692 191 91.098 205.308 99 205.308Zm0 4c10.111 0 18.308-8.197 18.308-18.308S109.111 172.693 99 172.693 80.692 180.889 80.692 191c0 10.111 8.197 18.308 18.308 18.308Z"
          fill={colors.primary[500]}
        />
        <path
          d="M292 191c0 18.778-15.222 34-34 34s-34-15.222-34-34 15.222-34 34-34 34 15.222 34 34Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M258 221c16.569 0 30-13.431 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30Zm0 4c18.778 0 34-15.222 34-34s-15.222-34-34-34-34 15.222-34 34 15.222 34 34 34Z"
          fill={colors.primary[500]}
        />
        <path
          d="M276.308 191c0 10.111-8.197 18.308-18.308 18.308s-18.308-8.197-18.308-18.308 8.197-18.307 18.308-18.307 18.308 8.196 18.308 18.307Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M258 205.308c7.902 0 14.308-6.406 14.308-14.308s-6.406-14.307-14.308-14.307-14.308 6.405-14.308 14.307 6.406 14.308 14.308 14.308Zm0 4c10.111 0 18.308-8.197 18.308-18.308s-8.197-18.307-18.308-18.307-18.308 8.196-18.308 18.307c0 10.111 8.197 18.308 18.308 18.308ZM130.93 130.729a2 2 0 0 1 .84 2.701l-31 59a1.999 1.999 0 1 1-3.54-1.86l30.999-59a2.001 2.001 0 0 1 2.701-.841Z"
          fill={colors.primary[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M97 149a2 2 0 0 1 2-2c10.864 0 26.071 4.115 35.083 15.777a2.001 2.001 0 0 1-3.166 2.446C122.929 154.885 109.136 151 99 151a2 2 0 0 1-2-2ZM222.563 144.929c-6.808.366-13.974-1.574-21.914-7.147a2 2 0 1 0-2.298 3.274c8.56 6.008 16.595 8.288 24.426 7.868 7.777-.418 15.141-3.487 22.4-7.784a2.001 2.001 0 0 0-2.038-3.442c-6.997 4.142-13.713 6.863-20.576 7.231Z"
          fill={colors.primary[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M211.972 185.833c5.779-34.19 37.503-47.825 62.953-36.241a2.001 2.001 0 0 0 1.657-3.641c-27.814-12.659-62.333 2.407-68.554 39.216a2 2 0 1 0 3.944.666Z"
          fill={colors.primary[500]}
        />
        <path
          d="M117 125c3.5 0 7.5 2 14 7.5-6 5.5-9 8-14 8-3 1.5-3.5-15.5 0-15.5Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114.221 124.6c.506-.741 1.413-1.6 2.779-1.6 4.284 0 8.775 2.459 15.292 7.973a2.001 2.001 0 0 1 .06 3.001c-5.778 5.297-9.31 8.399-14.998 8.522a2.69 2.69 0 0 1-1.374.017c-.658-.165-1.137-.545-1.46-.898-.605-.662-.962-1.557-1.194-2.33-.487-1.628-.73-3.816-.763-5.942-.033-2.133.143-4.402.6-6.202.225-.886.552-1.801 1.058-2.541Zm3.057 13.897c3.541-.076 5.894-1.636 10.668-5.924-5.069-4.062-8.122-5.418-10.515-5.56-.115.221-.256.581-.391 1.112-.34 1.34-.507 3.228-.478 5.157.03 1.936.256 3.718.596 4.855.043.142.083.261.12.36Z"
          fill={colors.primary[500]}
        />
        <path
          d="M132 137.59c0-12.103 11.212-21.09 23.025-18.456l39.684 8.848A14.433 14.433 0 0 1 206 142.068c0 7.971-6.461 14.432-14.432 14.432H150.91c-10.444 0-18.91-8.466-18.91-18.91Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m193.839 131.886-39.685-8.848C144.84 120.961 136 128.047 136 137.59c0 8.235 6.675 14.91 14.91 14.91h40.658a10.433 10.433 0 0 0 2.271-20.614Zm-38.814-12.752C143.212 116.5 132 125.487 132 137.59c0 10.444 8.466 18.91 18.91 18.91h40.658c7.971 0 14.432-6.461 14.432-14.432 0-6.76-4.693-12.614-11.291-14.086l-39.684-8.848Z"
          fill={colors.primary[500]}
        />
        <path
          d="M209 174.817c0-9.721-9.556-16.561-18.757-13.426l-23.409 7.975A10.087 10.087 0 0 0 170.086 189h24.731c7.833 0 14.183-6.35 14.183-14.183Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m168.124 173.153 23.409-7.976c6.606-2.25 13.467 2.661 13.467 9.64 0 5.624-4.559 10.183-10.183 10.183h-24.731a6.086 6.086 0 0 1-1.962-11.847Zm22.119-11.762c9.201-3.135 18.757 3.705 18.757 13.426 0 7.833-6.35 14.183-14.183 14.183h-24.731a10.087 10.087 0 0 1-3.252-19.634l23.409-7.975Z"
          fill={colors.primary[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M160.838 164.212a2 2 0 0 1-1.05 2.626l-8.626 3.697c2.564 5.676 6.874 15.211 8.659 19.137 1.104 2.43 2.313 3.524 3.484 4.089 1.251.602 2.729.739 4.695.739h44.5v4H168c-2.034 0-4.306-.113-6.43-1.135-2.204-1.061-3.995-2.967-5.391-6.037-2.001-4.403-7.169-15.839-9.502-21.005a1.999 1.999 0 0 1 1.035-2.661l10.5-4.5a2 2 0 0 1 2.626 1.05ZM156.857 106.257a2 2 0 0 1-1.114 2.6c-2.41.964-8.9 3.912-15.183 7.839-2.711 1.694-4.664 4.607-5.991 7.766-1.319 3.141-1.924 6.306-2.075 8.198a2 2 0 0 1-3.988-.319c.183-2.276.877-5.861 2.375-9.428 1.49-3.549 3.87-7.303 7.559-9.609 6.518-4.073 13.227-7.125 15.817-8.161a2 2 0 0 1 2.6 1.114Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationMotorcycle;
