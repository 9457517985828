import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsCloudDownload: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M10 29.047a8.857 8.857 0 014.178-7.526 10.91 10.91 0 0121.641 0 8.863 8.863 0 01-4.003 16.362l-13.635.026C13.605 37.536 10 33.712 10 29.047zm21.609 6.118a6.136 6.136 0 002.767-11.33l-1.1-.686-.164-1.284a8.182 8.182 0 00-16.23 0l-.163 1.285-1.097.685a6.135 6.135 0 002.766 11.33l.236.017h12.749l.236-.017zm-5.247-9.527h4.09l-5.454 6.817-5.454-6.817h4.09v-5.454h2.728v5.454z"
    />
  </Icon>
);

export default GeneralActionsCloudDownload;
