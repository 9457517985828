import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCat: React.FC<React.PropsWithChildren<IllustrationProps>> = ({
  isActive = true,
  ...otherProps
}) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M151 79.508a166.878 166.878 0 0 0-35.681-17.33 5.095 5.095 0 0 0-2.248-.096 5.035 5.035 0 0 0-2.061.88 4.897 4.897 0 0 0-1.456 1.677 4.78 4.78 0 0 0-.554 2.132A213.155 213.155 0 0 0 116.118 110c9.421-12.347 21.283-22.716 34.882-30.492ZM204 79.51a166.63 166.63 0 0 1 35.691-17.33c.731-.2 1.5-.234 2.246-.099a5.024 5.024 0 0 1 2.061.88 4.892 4.892 0 0 1 1.453 1.678c.347.661.535 1.391.549 2.134A213.696 213.696 0 0 1 238.892 110c-9.424-12.35-21.29-22.719-34.892-30.49Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M177.497 186C139.117 186 108 162.051 108 132.5S150.475 79 150.475 79h54.062S247 102.954 247 132.5 215.878 186 177.497 186Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M177.487 94c-24.467 0-54.39 18.639-48.807 49.163a50.973 50.973 0 0 0 8.278 21.306A50.856 50.856 0 0 0 153.704 180c7.123-3.655 23.121-13.256 23.8-26.111.673 12.855 16.682 22.456 23.8 26.111a50.856 50.856 0 0 0 16.746-15.531 50.986 50.986 0 0 0 8.278-21.306C231.878 112.644 201.949 94 177.487 94Z"
          fill={colors.primary[100]}
        />
        <path
          d="M205 182s-26.622-11.282-27.5-28c-.884 16.718-27.5 28-27.5 28"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M170.41 140a6.342 6.342 0 0 0-3.65 1.158 6.495 6.495 0 0 0-2.351 3.064 6.604 6.604 0 0 0-.215 3.877 6.532 6.532 0 0 0 1.998 3.312l6.482 5.751A7.274 7.274 0 0 0 177.5 159a7.274 7.274 0 0 0 4.826-1.838l6.482-5.751a6.532 6.532 0 0 0 1.998-3.312 6.604 6.604 0 0 0-.215-3.877 6.495 6.495 0 0 0-2.351-3.064 6.342 6.342 0 0 0-3.65-1.158h-14.18Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M141 121.5a10.502 10.502 0 0 0 10.5 10.5 10.502 10.502 0 0 0 10.5-10.5 10.502 10.502 0 0 0-10.5-10.5 10.502 10.502 0 0 0-10.5 10.5ZM193 121.5a10.502 10.502 0 0 0 10.5 10.5 10.502 10.502 0 0 0 10.5-10.5 10.502 10.502 0 0 0-10.5-10.5 10.502 10.502 0 0 0-10.5 10.5Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationCat;
