import React from 'react';
import useAuth from '../../features/authentication/hooks/useAuth';
import { NovuProvider } from '@novu/notification-center';
import NotificationsPopUp from '../../features/notifications/components/NotificationsPopUp';
import { useDispatch } from 'react-redux';
import { setIsInitializingNotifications } from '../../../../common/src/store/redux/slices/generalDataSlice';
import useFeatureFlags from '../../hooks/useFeatureFlags';

export const NOVU_READ_NOTIFICATIONS = 'NOVU_READ_NOTIFICATIONS';
export const NOVU_UNREAD_NOTIFICATIONS = 'NOVU_UNREAD_NOTIFICATIONS';

export const NotificationProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const { jwtData } = useAuth();
  const { isForeignDataTrackingDisabled } = useFeatureFlags();
  const dispatch = useDispatch();

  const handleLoad = () => {
    dispatch(setIsInitializingNotifications(false));
  };

  return (
    <NovuProvider
      stores={[
        { storeId: NOVU_READ_NOTIFICATIONS, query: { read: true, seen: true } },
        {
          storeId: NOVU_UNREAD_NOTIFICATIONS,
          query: { read: false, seen: false },
        },
      ]}
      subscriberId={
        isForeignDataTrackingDisabled ? undefined : jwtData?.ext.bid
      }
      applicationIdentifier={process.env.REACT_APP_NOVU_APPLICATION_ID!}
      // Novu excepts use to handle loading ourselves, otherwise it could crash
      onLoad={handleLoad}
      socketUrl={isForeignDataTrackingDisabled ? '' : undefined}
    >
      <NotificationsPopUp />
      {children}
    </NovuProvider>
  );
};
