import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersMailResent: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 9a6 6 0 00-6 6v18a6 6 0 006 6h11.097a13.899 13.899 0 01-1.374-4H12a2 2 0 01-2-2V15.73l13.508 9.542C25.198 22.474 29 19.5 33 19l5-3.27v2.993c1.42.255 2.764.725 4 1.374V15a6 6 0 00-6-6H12zm.94 4L24 20.27 35.06 13H12.94z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.5 20C28.596 20 23 25.596 23 32.5S28.596 45 35.5 45 48 39.404 48 32.5 42.404 20 35.5 20zM27 32.5a8.5 8.5 0 1117 0 8.5 8.5 0 01-17 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M35.154 26.5c-.926 0-1.654.766-1.654 1.684v5.724c0 .63.346 1.212.905 1.502l3.692 1.907a1.64 1.64 0 002.228-.748 1.699 1.699 0 00-.73-2.255l-2.787-1.44v-4.69c0-.918-.729-1.684-1.654-1.684z"
    ></path>
  </Icon>
);

export default GeneralOthersMailResent;
