import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCompanies: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3952_10268"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3952_10268)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M107 47h101.672L249 87.328V242a4 4 0 01-4 4H107a4 4 0 01-4-4V51a4 4 0 014-4z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M209 83.5V50.188c0-.888 1.072-1.335 1.703-.71l36.703 36.311c.635.629.19 1.711-.704 1.711H213a4 4 0 01-4-4z"
        ></path>
        <circle
          cx="156.5"
          cy="125.5"
          r="33.5"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <mask
          id="mask1_3952_10268"
          style={{ maskType: 'alpha' }}
          width="71"
          height="71"
          x="121"
          y="90"
          maskUnits="userSpaceOnUse"
        >
          <circle
            cx="156.5"
            cy="125.5"
            r="33.5"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_3952_10268)"
        >
          <path
            fill="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M153.631 127.058L100 151l5-42 51-37.5v51.905a4 4 0 01-2.369 3.653z"
          ></path>
          <circle cx="156.5" cy="125.5" r="33.5"></circle>
        </g>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M206.5 149h15M222 170h-36.5M222 191.5h-95.5M222 212.5h-95.5"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationCompanies;
