import React from 'react';
import { Box, HStack, Icon, Link, Text, VStack } from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import { OfferDocument } from '../../../../../conversations/src/features/overview/types/DetailTypes';

interface Props {
  documents: OfferDocument[];
}

const OfferCardBodyDocuments: React.FC<Props> = ({ documents }) => {
  const { t } = useTranslation();
  if (!documents) return null;
  return (
    <Box p="4" bg="gray.strokeAndBg" borderRadius="md">
      <Text mb="4" textStyle="normalBodyMedium">
        {t('DOCUMENTS')}
      </Text>
      <VStack align="stretch">
        {documents.map((doc) => (
          <Link isExternal variant="gray" key={doc.id} href={doc.link}>
            <HStack>
              <Icon name="IconDocumentFilled" color="currentColor" />
              <Text>{t(`document_types.${doc.type}`)}</Text>
            </HStack>
          </Link>
        ))}
      </VStack>
    </Box>
  );
};

export default OfferCardBodyDocuments;
