import {
  Icon,
  Text,
  HStack,
  useTheme,
  Box,
  Collapse,
  useBoolean,
} from '@wegroup/design-system';
import React from 'react';
import { compareProps } from '../../../../../tarifications/src/legacy/services/proFlowService';
import { TreeItem } from '.';
import TreeListLine from './TreeListLine';
import TreeListItemActions from './TreeListItemActions';

interface Props {
  isParentOpen: boolean;
  dataItem: TreeItem;
  onView?: (dataItemKey: string) => void;
  onRemove?: (dataItemKey: string) => void;
  isOpenMapping: { [x: string]: boolean };
  onOpen: (dataItemKey: string) => void;
  onClose: (dataItemKey: string) => void;
  level: number;
  isRemoveEnabled?: boolean;
}

const TreeListItem: React.FC<React.PropsWithChildren<Props>> = ({
  isParentOpen,
  dataItem,
  onView,
  onRemove,
  isOpenMapping,
  onOpen,
  onClose,
  level: levelProp,
  isRemoveEnabled,
}) => {
  const { sizes } = useTheme();
  const [isHovered, setIsHovered] = useBoolean();

  const { key, value, children } = dataItem;
  const isOpen = isOpenMapping[key];

  // Element bounds
  const level = levelProp - 1;
  const marginUnit = parseInt(sizes[2]);
  const itemMarginLeft = marginUnit * level;

  const handleOnItemClick = () => {
    if (isOpen) {
      onClose(key);
    } else {
      onOpen(key);
    }
  };

  const handleViewButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onView?.(key);
  };

  const handleRemoveButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onRemove?.(key);
  };

  return (
    <Collapse in={isParentOpen || level === 0}>
      <Box pt="2" pos="relative">
        {[...Array(level).keys()].map((index) => (
          <TreeListLine
            key={index}
            isFirst={index === level - 1}
            left={marginUnit * index}
          />
        ))}
        <HStack
          spacing="5"
          pl={itemMarginLeft}
          onClick={handleOnItemClick}
          cursor="pointer"
          _hover={{
            div: { color: 'primary.500' },
            button: { opacity: '1' },
          }}
          transition="color 3s"
          justify="space-between"
          minH="8"
          borderRadius="sm"
          onMouseEnter={setIsHovered.on}
          onMouseLeave={setIsHovered.off}
        >
          <HStack my="1.5" spacing="2" color="text.gray" align="flex-start">
            <Box
              transform={isOpen ? 'rotate(90deg)' : 'unset'}
              transition="transform 0.2s"
              display={children && children.length > 0 ? 'block' : 'none'}
            >
              <Icon name="GeneralActionsChevronRight" boxSize={4} />
            </Box>
            <Text textStyle="smallBody">{value}</Text>
          </HStack>
          {!children && isRemoveEnabled && (
            <TreeListItemActions
              onView={handleViewButtonClick}
              onRemove={handleRemoveButtonClick}
              isHovered={isHovered}
            />
          )}
        </HStack>
        {children &&
          children.length > 0 &&
          isParentOpen &&
          children.map((dataItem) => (
            <TreeListItem
              key={dataItem.key}
              dataItem={dataItem}
              isParentOpen={isOpen}
              isOpenMapping={isOpenMapping}
              onOpen={onOpen}
              onClose={onClose}
              level={levelProp + 1}
              onView={onView}
              onRemove={onRemove}
              isRemoveEnabled={isRemoveEnabled}
            />
          ))}
      </Box>
    </Collapse>
  );
};

export default React.memo(TreeListItem, compareProps);
