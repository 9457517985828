import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyDressing2: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M15.94 24c0 .2.06.41.08.61.05.39.08.77.11 1.16.05.84.08 1.68.08 2.51v1.54c0 .552-.448.999-.998.948-1.295-.119-3.133-.473-3.552-1.348-.71-1.41 1.4-9.14 2.11-9.84 0-.51 3.25-1.73 5.09-2.39a2.81 2.81 0 012.25.2 8.58 8.58 0 007.89-.07 2.798 2.798 0 012.18-.13l3.53 1.23a3.51 3.51 0 012.16 2.21c.78 2.37 2 6.6 1.53 8.08-.438 1.306-2.214 1.808-3.506 1.998-.566.083-1.044-.376-1.044-.947V28.28c0-.84 0-1.7.08-2.54.022-.543.078-1.084.17-1.62a2.349 2.349 0 010-.26c.007-.076.013-.153.017-.23.028-.515-.45-.814-.631-.33-.246.651-.456 2.036-.456 4.98v15.14a2.27 2.27 0 01-1.59 2.17 24.36 24.36 0 01-13-.07 2.08 2.08 0 01-1.53-2V28.28c0-3.221-.252-4.576-.528-5.146-.178-.368-.552-.083-.552.326M31.12 10.85l-2.31-1.16-2.88-1.41a2.74 2.74 0 01.62-1.92A1.67 1.67 0 0027 4.91a1.42 1.42 0 00-.72-1.09A2.67 2.67 0 0025 3.55a2.09 2.09 0 00-1.63.64 2.14 2.14 0 00-.5 1.55l1.49-.16a.58.58 0 01.12-.38.72.72 0 01.52-.15 1.32 1.32 0 01.48.07.3.3 0 010 .17 4.1 4.1 0 00-1.06 2.78l-.38.19-1.04.55-4.07 2a1.43 1.43 0 00-.7 1.77 1.47 1.47 0 001.39.93h10.8a1.46 1.46 0 001.44-1.07 1.4 1.4 0 00-.74-1.59zm-9.67.82L25 9.89l3.55 1.78h-7.1z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyDressing2;
