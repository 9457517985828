import React from 'react';
import useInitHubspotChat from '../../../../common/src/hooks/tracking/useInitHubspotChat';

export const HubspotProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  useInitHubspotChat();

  return <>{children}</>;
};
