import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationResidenceSemiDetached: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3818_10174"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0-.001h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3818_10174)">
        <path
          fill={colors.primary[50]}
          d="M337 160.999c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.635 160 160z"
        ></path>
        <g opacity="0.33">
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M92 113H264V226H92z"
          ></path>
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M92 226H205V237H92z"
            transform="rotate(-90 92 226)"
          ></path>
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M253 226H366V237H253z"
            transform="rotate(-90 253 226)"
          ></path>
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M92 113H264V124H92z"
          ></path>
          <path
            fill={colors.primary[200]}
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M268 115a2.001 2.001 0 001.752-2.965l-27.496-49.93A6 6 0 00237 59H119a6 6 0 00-5.256 3.106l1.681.925-1.681-.925-27.496 49.929A2 2 0 0088 115h180z"
          ></path>
          <rect
            width="26"
            height="20"
            x="112"
            y="138.637"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <path
            fill={colors.primary[500]}
            d="M123 138.637H127V159.637H123z"
          ></path>
          <rect
            width="26"
            height="20"
            x="112"
            y="173.637"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <path
            fill={colors.primary[500]}
            d="M123 173.637H127V194.637H123z"
          ></path>
          <rect
            width="26"
            height="20"
            x="147"
            y="138.637"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <path
            fill={colors.primary[500]}
            d="M158 138.637H162V159.637H158z"
          ></path>
          <rect
            width="26"
            height="20"
            x="183"
            y="138.637"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <path
            fill={colors.primary[500]}
            d="M194 138.637H198V159.637H194z"
          ></path>
          <rect
            width="26"
            height="20"
            x="218"
            y="138.637"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <path
            fill={colors.primary[500]}
            d="M229 138.637H233V159.637H229z"
          ></path>
          <rect
            width="26"
            height="20"
            x="218"
            y="173.637"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <path
            fill={colors.primary[500]}
            d="M229 173.637H233V194.637H229z"
          ></path>
          <rect
            width="17"
            height="38"
            x="152"
            y="174"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <rect
            width="25"
            height="13"
            x="148"
            y="204"
            fill={colors.primary[200]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <rect
            width="17"
            height="38"
            x="187"
            y="174"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <rect
            width="25"
            height="13"
            x="183"
            y="204"
            fill={colors.primary[200]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M92 212H264V226H92z"
          ></path>
        </g>
        <mask
          id="mask1_3818_10174"
          style={{ maskType: 'alpha' }}
          width="98"
          height="187"
          x="178"
          y="46"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#C4C4C4" d="M178 46H276V233H178z"></path>
        </mask>
        <g mask="url(#mask1_3818_10174)">
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M92 113H264V226H92z"
          ></path>
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M253 226H366V237H253z"
            transform="rotate(-90 253 226)"
          ></path>
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M92 113H264V124H92z"
          ></path>
          <path
            fill={colors.primary[200]}
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M268 115a2.001 2.001 0 001.752-2.965l-27.496-49.93A6 6 0 00237 59H119a6 6 0 00-5.256 3.106l1.681.925-1.681-.925-27.496 49.929A2 2 0 0088 115h180z"
          ></path>
          <rect
            width="26"
            height="20"
            x="183"
            y="138.637"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <path
            fill={colors.primary[500]}
            d="M194 138.637H198V159.637H194z"
          ></path>
          <rect
            width="26"
            height="20"
            x="218"
            y="138.637"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <path
            fill={colors.primary[500]}
            d="M229 138.637H233V159.637H229z"
          ></path>
          <rect
            width="26"
            height="20"
            x="218"
            y="173.637"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <path
            fill={colors.primary[500]}
            d="M229 173.637H233V194.637H229z"
          ></path>
          <rect
            width="17"
            height="38"
            x="187"
            y="174"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <rect
            width="25"
            height="13"
            x="183"
            y="204"
            fill={colors.primary[200]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M92 212H264V226H92z"
          ></path>
        </g>
        <path
          stroke={colors.primary[500]}
          strokeDasharray="8 12"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M177.5 43v198"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationResidenceSemiDetached;
