const verzekerje = {
  primary: {
    main: '#0f5980',
    50: '#E9EEF2',
    100: '#90acc1',
    150: '#83a3b9',
    200: '#6089A6',
    300: '#4d7089',
    400: '#46667c',
    500: '#0f5980',
    600: '#3e5b6f',
    700: '#304655',
    750: '#283b48',
    800: '#21303b',
    850: '#1a252e',
    900: '#0b1014',
  },
  secondary: {
    main: '#A5C6D5',
    50: '#F3F8FA',
    100: '#EBF2F5',
    150: '#E2EDF1',
    200: '#D9E7ED',
    300: '#C8DCE5',
    400: '#B6D1DD',
    500: '#A5C6D5',
    600: '#74A7BE',
    700: '#4B86A0',
    750: '#3F7188',
    800: '#0f5980',
    850: '#1a252e',
    900: '#0b1014',
  },
};

export default verzekerje;
