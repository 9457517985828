import { omit, isEqual, get, startsWith } from 'lodash';
import regex from '../../../common/src/legacy/services/regexService';
import {
  DuplicateQuestionMapping,
  FlowsQuestionGroup,
  FlowsCondition,
  FlowsConditionsObject,
  FlowsQuestionAnswer,
  FlowsQuestionnaire,
  FlowsQuestion,
} from '../types/FlowsTypes';
import { getGroupAndQuestionIndexByQuestionId } from './flowsQuestionsUtils';

/**
 * This function filters out both the groups and questions that have certain
 * conditions defined on them. Both questions and groups have the same type of
 * conditions and therefore use the same function "handleCondition".
 * @param questionnaire The questionnaire to be filtered
 * @param answers The answers related to the questionnaire that are needed for the filtering
 * @param currentIndex Optional parameter if you want to specify a different index
 */
export const filterForConditions = (
  questionnaire: FlowsQuestionnaire,
  answers: FlowsQuestionAnswer[],
  questions: FlowsQuestion[],
  currentIndex?: number,
): FlowsQuestionnaire => {
  return {
    ...questionnaire,
    groups: questionnaire.groups
      .filter(({ conditions, index }) =>
        handleConditions(
          questions,
          answers,
          conditions,
          currentIndex != null ? currentIndex : index,
        ),
      )
      .map((group) => ({
        ...group,
        questions: group.questions.filter(({ conditions, index }) =>
          handleConditions(
            questions,
            answers,
            conditions,
            currentIndex != null ? currentIndex : index,
          ),
        ),
      }))
      .filter(({ questions }) => questions.length > 0),
  };
};
/**
 * This function checks if the condition is  true or not.
 * - A value NULL checks on existance of an answer, if it's not there, the question can be shown.
 * - An actual value will check if the value is equals, if it is, the question can be shown.
 * @param answers The answers related to the questionnaire that are needed for checking the condition
 * @param condition The condition that need to be evaluated
 * @returns Boolean if it the condition is correct or not
 */
export const handleSingleCondition = (
  questions: FlowsQuestion[],
  answers: FlowsQuestionAnswer[],
  condition: FlowsCondition,
  index?: number,
): boolean => {
  const question = questions?.find(({ id }) => id === condition.question_id);

  // We should first make sure to check the conditions of the question mentioned in the condition itself
  // It does not make sense to go further if that condition is false
  const result = handleConditions(
    questions,
    answers,
    question?.conditions,
    index,
  );

  // If the condition is false we should skip further checking
  if (!result) return false;

  const isIndexQuestion = question?.index === -1;

  let answer: FlowsQuestionAnswer | undefined;
  if (isIndexQuestion) {
    answer = answers.find(
      ({ id, index: answerIndex }) =>
        id === condition?.question_id &&
        (index == null || index === answerIndex),
    );
  } else {
    answer = answers.find(({ id }) => id === condition?.question_id);
  }

  if (answer != null) answer = { ...answer };

  // Check for check type
  if (
    answer?.answer != null &&
    condition.field != null &&
    condition.operation !== 'IN'
  ) {
    if (Array.isArray(answer.answer) && condition.check_type === 'FIELD')
      answer.answer = answer.answer.map(
        (objectValue) => objectValue[condition.field!],
      );
    else answer.answer = get(answer.answer, condition.field || '');
  } else if (answer?.answer != null && condition.check_type === 'LEN') {
    if (typeof answer.answer === 'string' || Array.isArray(answer.answer)) {
      answer.answer = answer.answer.length;
    }
  }

  // Set initial value
  let initialReturn = false;

  /**operation
   * Check for NULL condition
   * */
  if (condition.value == null && !answer) {
    initialReturn = true;

    /**operation
     * Check for IN condition
     * */
  } else if (condition.operation === 'ANYSTARTSWITH') {
    initialReturn = handleAnyStartsWith(condition, answer);

    /**operation
     * Check for IN condition
     * */
  } else if (condition.operation === 'IN') {
    initialReturn = handleIN(condition, answer);

    /**operation
     * Check for GE condition
     * */
  } else if (condition.operation === 'GE') {
    initialReturn = handleGE(condition, answer);

    /**operation
     * Check for GT condition
     * */
  } else if (condition.operation === 'GT') {
    initialReturn = handleGT(condition, answer);

    /**operation
     * Check for LE condition
     * */
  } else if (condition.operation === 'LE') {
    initialReturn = handleLE(condition, answer);

    /**operation
     * Check for LT condition
     * */
  } else if (condition.operation === 'LT') {
    initialReturn = handleLT(condition, answer);

    /**
     * Check for EQ condition
     * */
  } else if (condition.operation === 'EQ') {
    initialReturn = handleEQ(condition, answer);

    /**
     * DEPRECATED - Check for VALUE condition
     * */
  } else if (Array.isArray(answer?.answer)) {
    // When value and answer are both arrays, they should be equal
    if (
      Array.isArray(condition?.value) &&
      isEqual(answer?.answer, condition?.value)
    )
      initialReturn = true;
    // When answer is an array and the condition value is a primitive, the answer array should include the condition value
    else if (answer?.answer.includes(condition?.value)) initialReturn = true;
  } else if (isEqual(answer?.answer, condition?.value)) initialReturn = true;

  // If none apply, return false
  if (condition.inversed) return !initialReturn;
  return initialReturn;
};

//
// ─── COMPARISON HANDLERS ────────────────────────────────────────────────────────
//

// ISO Date
const isoRegex = new RegExp(regex.iso);
const isDateComparison = (value1: string, value2: string) =>
  isoRegex.test(value1) && isoRegex.test(value2);

//
// ─── CONDITIONAL HANDLERS ───────────────────────────────────────────────────────
//

const handleGE = (
  condition: FlowsCondition,
  answer?: FlowsQuestionAnswer,
): boolean => {
  if (answer == null) return false;

  const conditionValue = condition.value;
  const answerValue = answer.answer;

  // Strings
  if (typeof conditionValue === 'string' && typeof answerValue === 'string') {
    // Dates
    if (isDateComparison(conditionValue, answerValue)) {
      return new Date(conditionValue) >= new Date(answerValue);
    }

    // Default
    return conditionValue >= answerValue;
  }

  // Numbers
  if (typeof conditionValue === 'number' && typeof answerValue === 'number') {
    // Default
    return conditionValue >= answerValue;
  }

  return false;
};

const handleGT = (
  condition: FlowsCondition,
  answer?: FlowsQuestionAnswer,
): boolean => {
  if (answer == null) return false;

  const conditionValue = condition.value;
  const answerValue = answer.answer;

  // Strings
  if (typeof conditionValue === 'string' && typeof answerValue === 'string') {
    // Dates
    if (isDateComparison(conditionValue, answerValue)) {
      return new Date(conditionValue) > new Date(answerValue);
    }

    // Default
    return conditionValue > answerValue;
  }

  // Numbers
  if (typeof conditionValue === 'number' && typeof answerValue === 'number') {
    // Default
    return conditionValue > answerValue;
  }

  return false;
};

const handleLE = (
  condition: FlowsCondition,
  answer?: FlowsQuestionAnswer,
): boolean => {
  if (answer == null) return false;

  const conditionValue = condition.value;
  const answerValue = answer.answer;

  // Strings
  if (typeof conditionValue === 'string' && typeof answerValue === 'string') {
    // Dates
    if (isDateComparison(conditionValue, answerValue)) {
      return new Date(conditionValue) <= new Date(answerValue);
    }

    // Default
    return conditionValue <= answerValue;
  }

  // Numbers
  if (typeof conditionValue === 'number' && typeof answerValue === 'number') {
    // Default
    return conditionValue <= answerValue;
  }

  return false;
};

const handleLT = (
  condition: FlowsCondition,
  answer?: FlowsQuestionAnswer,
): boolean => {
  if (answer == null) return false;

  const conditionValue = condition.value;
  const answerValue = answer.answer;

  // Strings
  if (typeof conditionValue === 'string' && typeof answerValue === 'string') {
    // Dates
    if (isDateComparison(conditionValue, answerValue)) {
      return new Date(conditionValue) < new Date(answerValue);
    }

    // Default
    return conditionValue < answerValue;
  }

  // Numbers
  if (typeof conditionValue === 'number' && typeof answerValue === 'number') {
    // Default
    return conditionValue < answerValue;
  }

  return false;
};

const handleIN = (
  condition: FlowsCondition,
  answer?: FlowsQuestionAnswer,
): boolean => {
  if (condition.field && Array.isArray(answer?.answer)) {
    return Boolean(
      answer?.answer.find((item) =>
        isEqual(condition?.value, get(item, condition.field || '')),
      ),
    );
  } else if (
    typeof answer?.answer === 'string' &&
    typeof condition?.value === 'string'
  )
    return answer?.answer.includes(condition?.value);
  else if (Array.isArray(answer?.answer))
    return answer?.answer.includes(condition?.value) || false;

  return false;
};

const handleEQ = (
  condition: FlowsCondition,
  answer?: FlowsQuestionAnswer,
): boolean => {
  if (answer == null) return false;

  if (Array.isArray(answer?.answer)) {
    // When value and answer are both arrays, they should be equal
    if (
      Array.isArray(condition?.value) &&
      isEqual(answer?.answer, condition?.value)
    )
      return true;
  } else if (isEqual(answer?.answer, condition?.value)) return true;

  return false;
};

const handleAnyStartsWith = (
  condition: FlowsCondition,
  answer?: FlowsQuestionAnswer,
): boolean => {
  if (answer == null) return false;

  const conditionValue = condition.value;
  const answerValue = answer.answer;

  // Strings
  if (
    typeof conditionValue === 'string' &&
    typeof answerValue === 'string' &&
    answerValue
  ) {
    return startsWith(answerValue, conditionValue);
  } else if (
    Array.isArray(answer?.answer) &&
    typeof conditionValue === 'string'
  ) {
    return answer?.answer.some((item) => startsWith(item, conditionValue));
  }

  return false;
};

export const exportedForTesting = {
  handleSingleCondition,
  handleEQ,
  handleGE,
  handleGT,
  handleLE,
  handleLT,
  handleIN,
  handleAnyStartsWith,
};

/**
 * This wille handle the conditions depending on the type that is given.
 * @param answers The answers related to the questionnaire that are needed for checking the condition
 * @param conditionsObject The conditions that need to be evaluated
 * @returns Boolean if the question should be shown
 */
export const handleConditions = (
  questions: FlowsQuestion[],
  answers: FlowsQuestionAnswer[],
  conditionsObject?: FlowsConditionsObject,
  index?: number,
): boolean => {
  const { items, type } = conditionsObject || {};
  if ((items || []).length === 0) return true;

  if (type?.toLowerCase() === 'and') {
    return !!items?.every((condition) =>
      handleSingleCondition(questions, answers, condition, index),
    );
  } else if (type?.toLowerCase() === 'or') {
    return !!items?.some((condition) =>
      handleSingleCondition(questions, answers, condition, index),
    );
  }
  console.error(`Unsupported condition type ${conditionsObject?.type}`);
  return true;
};

/**
 * A function that builds the mapping that can be used in the flow
 * of the questionnaire to render the correct amount of duplicate questions.
 * @param questionnaire The questionnaire used to build the mapping
 * @returns A mapping for the duplicate questions
 */
export const buildDuplicateQuestionMapping = (
  questionnaire: FlowsQuestionnaire,
): DuplicateQuestionMapping => {
  const mapping: DuplicateQuestionMapping = {};

  questionnaire.groups.forEach((group) => {
    if (group.duplicate_questions) {
      group.duplicate_questions.forEach((duplicateQuestion) => {
        if (mapping[duplicateQuestion])
          mapping[duplicateQuestion].push(group.id);
        else mapping[duplicateQuestion] = [group.id];
      });
    }
  });

  return mapping;
};

/**
 * This function will check if there are any questions answered that are present
 * in the duplicate mapping and if so duplicate them correctly in the questionnaire.
 * @param questionnaire The questionnaire to be used for duplicating
 * @param answers The answers related to the questionnaire that are needed for duplication
 */
export const drawDuplicateQuestionMapping = (
  mapping: DuplicateQuestionMapping,
  questionnaire: FlowsQuestionnaire,
  answers: FlowsQuestionAnswer[],
  questions: FlowsQuestion[],
  withAdd = true,
): FlowsQuestionnaire => {
  const updatedQuestionnaire = { ...questionnaire };

  Object.entries(mapping)
    .reverse()
    .forEach(([amountId, duplicateQuestionArray]) => {
      const amountQuestionAnswer = answers.find(({ id }) => amountId === id);
      const [amountQuestionGroupIndex] = getGroupAndQuestionIndexByQuestionId(
        updatedQuestionnaire,
        amountId,
      );

      const amountQuestionConditions = questions.find(
        ({ id }) => id === amountId,
      )?.conditions;
      const isAmountAsked = handleConditions(
        questions,
        answers,
        amountQuestionConditions,
      );

      // Delete from questionnaire
      updatedQuestionnaire.groups = updatedQuestionnaire.groups.filter(
        ({ id, duplicateQuestionData }) => {
          if (duplicateQuestionData && duplicateQuestionData.id !== amountId)
            return true;
          return !duplicateQuestionArray.includes(id);
        },
      );

      // If the amount condition is not satisfied the questions can be skipped
      if (amountQuestionAnswer && isAmountAsked && withAdd) {
        const amount = amountQuestionAnswer.answer as number;
        if (amountQuestionGroupIndex > -1 && amount > 0) {
          // Copy amount of times to questionnaire
          const toBeDuplicatedGroups = duplicateQuestionArray.map((_id) =>
            // Impossible that id is not found in the questionnaire since
            // mapping is build based on the questionnaire itself
            omit(
              questionnaire.groups.find(({ id }) => id === _id),
              'duplicate_questions',
            ),
          ) as FlowsQuestionGroup[];

          // Reversed for loop so that amountQuestionGroupIndex can be reused
          for (let i = amount - 1; i >= 0; i--) {
            const indexedDuplicatedGroup = toBeDuplicatedGroups.map(
              (group) => ({
                ...group,
                index: i,
                duplicateQuestionData: {
                  id: amountQuestionAnswer.id,
                  amount: amountQuestionAnswer.answer as number,
                },
                questions: group.questions.map((question) => ({
                  ...question,
                  index: i,
                })),
              }),
            );

            updatedQuestionnaire.groups.splice(
              amountQuestionGroupIndex + 1,
              0,
              ...indexedDuplicatedGroup,
            );
          }
        }
      }
    });

  return updatedQuestionnaire;
};

export const convertToSubgroupedQuestionnaire = (
  questionnaire: FlowsQuestionnaire,
): FlowsQuestionnaire => {
  const newGroups: FlowsQuestionGroup[] = [];

  questionnaire.groups.forEach((group) => {
    const newGroupindex = newGroups.findIndex(({ tag }) => tag === group.tag);
    if (newGroupindex === -1) {
      newGroups.push({
        id: group.tag.toLowerCase(),
        tag: group.tag,
        questions: [],
        groups: [group],
      });
    } else {
      newGroups[newGroupindex].groups = [
        ...(newGroups[newGroupindex].groups || []),
        group,
      ];
    }
  });

  return {
    ...questionnaire,
    groups: newGroups,
  };
};
