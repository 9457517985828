import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsCompanyDamageInsurance: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29 5a2 2 0 012 2v8h8a2 2 0 110 4h-8a4 4 0 01-4-4V7a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 9a2 2 0 00-2 2v28a2 2 0 002 2h20a2 2 0 002-2V17.828L28.172 9H15zm-4.243-2.243A6 6 0 0115 5h14a2 2 0 011.414.586l10 10A2 2 0 0141 17v22a6 6 0 01-6 6H15a6 6 0 01-6-6V11a6 6 0 011.757-4.243z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 29a2 2 0 012-2h6a2 2 0 110 4h-6a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.91 21.075a8 8 0 013.766.384 2 2 0 11-1.336 3.77 4 4 0 10-.001 7.541 2 2 0 111.334 3.771 8 8 0 11-3.764-15.466z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsCompanyDamageInsurance;
