import React, { PropsWithChildren } from 'react';
import { useLocation } from 'react-router';
import AppLoadingDone from '../../components/AppLoadingDone';
import useAuth from '../../features/authentication/hooks/useAuth';
import useAuthListener from '../../features/authentication/hooks/useAuthListener';
import useAuthMigration from '../../features/authentication/hooks/useAuthMigration';
import UserDataError from '../components/UserDataError';

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isReturningFromSSO, isError } = useAuth();

  // For some reason calling this hook trigger a re-render on location change which is what we need for detecting the `isReturningFromSSO`
  useLocation();

  useAuthListener();
  useAuthMigration();

  if (isReturningFromSSO) return null;

  if (isError)
    return (
      <>
        <AppLoadingDone />
        <UserDataError />
      </>
    );

  return <>{children}</>;
};
