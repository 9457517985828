import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';

const IllustrationBoatBAExtendedCasco: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="64"
      fill="none"
      viewBox="0 0 107 64"
    >
      <path
        stroke="#F7A82A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M71 13a2 2 0 100-4 2 2 0 000 4zM69 28v-5l-1-1v-4a1 1 0 011-1h4a1 1 0 011 1v4l-1 1v5"
      ></path>
      <g clipPath="url(#clip0_5362_57639)">
        <path
          stroke="#365E7B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5"
          d="M14.25 48.95l6.625-11H38.612a15 15 0 005.588-1.088l13.35-5.325a15.002 15.002 0 015.588-1.087H86.5l-8.625 18.475M26.5 37.95l5.625-18.75M26.5 19.2h30L64 30.45"
        ></path>
        <path
          fill="#B7CEDF"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M14.084 54a3.902 3.902 0 00-.168 0 5.6 5.6 0 01-4.666-2.332 4 4 0 10-6.5 4.665A13.6 13.6 0 0014 62a13.598 13.598 0 0011.25-5.668 5.6 5.6 0 014.666-2.334h.168a5.6 5.6 0 014.667 2.334A13.6 13.6 0 0046 62a13.6 13.6 0 0011.249-5.668 5.6 5.6 0 014.666-2.334h.168a5.6 5.6 0 014.667 2.334A13.6 13.6 0 0078 62a13.598 13.598 0 0011.249-5.668 4 4 0 10-6.5-4.665A5.599 5.599 0 0178.085 54a3.902 3.902 0 00-.168 0 5.6 5.6 0 01-4.666-2.333A13.599 13.599 0 0062 45.999a13.6 13.6 0 00-11.25 5.669A5.599 5.599 0 0146.085 54a3.902 3.902 0 00-.168 0 5.6 5.6 0 01-4.666-2.333A13.6 13.6 0 0030 45.999a13.6 13.6 0 00-11.25 5.669A5.599 5.599 0 0114.085 54zm0 0l-.031 1.5.031-1.5z"
        ></path>
        <circle
          cx="14.991"
          cy="26.991"
          r="2.298"
          fill="#F7A82A"
          transform="rotate(-158 14.991 26.991)"
        ></circle>
        <rect
          width="1.4"
          height="5.6"
          x="-0.067"
          y="37.368"
          fill="#F7A82A"
          rx="0.7"
          transform="rotate(-23 -.067 37.368)"
        ></rect>
        <rect
          width="1.4"
          height="5.6"
          x="-0.633"
          y="41.41"
          fill="#F7A82A"
          rx="0.7"
          transform="rotate(-113 -.633 41.41)"
        ></rect>
      </g>
      <circle
        cx="101.093"
        cy="29.093"
        r="2.275"
        fill="#F7A82A"
        transform="rotate(61 101.093 29.093)"
      ></circle>
      <circle
        cx="44.342"
        cy="11.341"
        r="2.366"
        fill="#F7A82A"
        transform="rotate(-138 44.342 11.341)"
      ></circle>
      <circle
        cx="88.273"
        cy="21.27"
        r="1.82"
        fill="#F7A82A"
        transform="rotate(-17 88.273 21.27)"
      ></circle>
      <rect
        width="2.16"
        height="8.64"
        x="24.007"
        y="10.034"
        fill="#F7A82A"
        rx="1.08"
        transform="rotate(-158 24.007 10.034)"
      ></rect>
      <rect
        width="2.16"
        height="8.64"
        x="29.033"
        y="6.241"
        fill="#F7A82A"
        rx="1.08"
        transform="rotate(112 29.033 6.24)"
      ></rect>
      <rect
        width="1.86"
        height="7.44"
        x="98.486"
        y="42.111"
        fill="#F7A82A"
        rx="0.93"
        transform="rotate(-27 98.486 42.111)"
      ></rect>
      <rect
        width="1.86"
        height="7.44"
        x="98.111"
        y="47.521"
        fill="#F7A82A"
        rx="0.93"
        transform="rotate(-117 98.111 47.52)"
      ></rect>
      <defs>
        <clipPath id="clip0_5362_57639">
          <path
            fill="#fff"
            d="M0 0H92V47H0z"
            transform="translate(0 17)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationBoatBAExtendedCasco;
