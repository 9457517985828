import store from '../store/redux/store';

import {
  AcknowledgementPayload,
  LouiseSessionObject,
  ToolbarItemStatus,
} from '../../../../types/ProductFactory';
import {
  CancelledInsuranceValues,
  FailedLouiseQuote,
  isFailedQuote,
  LouiseOffer,
  LouiseQuote,
  ProductWithQuality,
  QuoteGuaranteeContent,
} from '../../../../types/Quotes';
import { createOffer as createOfferApiCall } from '../../../common/src/legacy/services/apiRouterService';
import { ErrorResponse } from '../../../../types/API';
import { OfferCreationResponse } from '../../../../types/Offer';
import { arrayToWords } from '../utils/stringUtils';
import { isSocialLoginUrl } from './productFactoryService';
import i18n, { handleResponseLanguage } from '../../../../i18n';
import { addMonths } from 'date-fns';
import { Distribution } from '../../../app/src/features/authentication/types/Distribution';
import { User } from '../../../app/src/features/authentication/types/User';
import { queryClient } from '../../../app/src/providers/queryClient';

interface SelectedOffers {
  [riskType: string]: string[];
}

export const getOfferRiskStatus = (
  riskType: string,
  selectedOffers: SelectedOffers,
  visitedOverviewPages: string[],
): ToolbarItemStatus | undefined => {
  // Check if offer of type {riskType} is in selected offers
  if (Object.keys(selectedOffers)?.includes(riskType)) {
    return ToolbarItemStatus.CHECKED;
  }
  // Check if page has been visited
  if (visitedOverviewPages?.includes(riskType)) {
    return ToolbarItemStatus.SKIPPED;
  }
  return;
};

interface CreateOfferObject {
  prospectId: string;
  distributionId: string;
  quoteId: string;
  riskObject: string;
  guarantees: string[];
  riskObjectRevisions?: {
    [x: string]: string[];
  };
  startDate: string;
  endDate: string;
  commencementDate: string;
  assignedTo: string;
  language: string;
  acknowledgements: AcknowledgementPayload[];
  paymentSpread: string;
  exportToCrm?: boolean;
  sendMailToProspect?: boolean;
  customerSheet?: string;
  specificNeeds: string;
  reasonForContact?: string;
  motivation?: string;
  noOtherContract?: boolean;
  notice?: CancelledInsuranceValues | string;
  withInformationRequirement?: boolean;
}

export const hasTerminationInfo = (
  terminationInfo: CancelledInsuranceValues | string | undefined,
): terminationInfo is CancelledInsuranceValues =>
  Boolean(terminationInfo && terminationInfo !== 'none');

export const createOffer = async ({
  prospectId,
  distributionId,
  quoteId,
  riskObject,
  guarantees,
  riskObjectRevisions,
  startDate,
  endDate,
  commencementDate,
  assignedTo,
  language,
  acknowledgements,
  paymentSpread,
  exportToCrm,
  sendMailToProspect,
  customerSheet,
  specificNeeds,
  reasonForContact = '',
  motivation = '',
  noOtherContract = false,
  withInformationRequirement = true,
  notice,
}: CreateOfferObject): Promise<
  [OfferCreationResponse | ErrorResponse, number]
> => {
  const [resp, status] = await createOfferApiCall(distributionId, prospectId, {
    quote_id: quoteId,
    guarantees: guarantees,
    risk_object_revisions: riskObjectRevisions,
    start_date: startDate,
    end_date: endDate,
    commencement_date: commencementDate,
    assigned_to: assignedTo,
    language,
    acknowledgements,
    payment_spread: paymentSpread,
    export_to_crm: exportToCrm,
    send_mail_to_prospect: sendMailToProspect,
    origin: isSocialLoginUrl ? 'Campaigns' : 'Louise Conversation',
    ...(withInformationRequirement && {
      information_requirement_sheet_specification: {
        specific_needs: specificNeeds,
        reason_for_contact: reasonForContact,
        broker_gives_advise: !!motivation,
        independant_advice: !!motivation,
        prospect_follows_advice: !!motivation,
        no_other_contract: noOtherContract,
        motivation: motivation,
      },
    }),
    ...(customerSheet && {
      customer_sheet: {
        acceptations: {
          process_medical_records: true,
          process_criminal_records: true,
          access_all_other_insurances: true,
          access_other_insurances: false,
        },
        know_our_office: customerSheet,
      },
    }),
    ...(notice &&
      hasTerminationInfo(notice) && {
        notice: {
          previous_insurance_company_key: notice.insuranceCompany,
          main_expiry_date: notice.expiryDate,
          current_policy_nr: notice.policyNumber,
          notice_type: notice.reasonOfTermination,
          reason: riskObject,
        },
      }),
    signature_redirect_url: `${window.location.origin}/thank-you-for-signing`,
  });

  return Promise.resolve([resp, status]);
};

export const formatSpecificNeeds = (
  insurance: string,
  guaranteeContent: QuoteGuaranteeContent[],
): string =>
  `${insurance}: ${arrayToWords(guaranteeContent.map(({ name }) => name))}`;

const mapForSmtType = (sessionObject: LouiseSessionObject, smtType: string) =>
  sessionObject.risk_objects_revisions
    .filter(({ type }) => type.toLowerCase() === smtType)
    .map(({ id }) => id);

/**
 * This function creates an object of the risk object revisions for a quote
 * that can be used to create the offer in SMT.
 * @param quoteType The type of risk object that the quote is for
 */
export const getQuoteRiskObjectRevisions = (
  quoteType: string,
  sessionObject: LouiseSessionObject,
): { [x: string]: string[] } | undefined => {
  // These names are prone to changes in the future. It's possible that at one point
  // "car" will become "vehicle" for SMT.
  switch (quoteType.toLowerCase()) {
    case 'car':
      return {
        cars: mapForSmtType(sessionObject, 'car'),
      };
    case 'home':
      return {
        residences: mapForSmtType(sessionObject, 'residence'),
      };
    case 'family':
      return {
        families: mapForSmtType(sessionObject, 'family'),
      };
    case 'legal':
      // LEGAL DOES NOT HAVE A RISK OBJECT
      return undefined;
    case 'funeral':
      return {
        party_groups: mapForSmtType(sessionObject, 'party_group'),
      };
    case 'two_wheeler':
      return {
        party_groups: mapForSmtType(sessionObject, 'two_wheeler'),
      };
    default:
      console.error(`Quote type "${quoteType}" is not supported`);
      return {};
  }
};

export enum OfferState {
  FETCHED,
  LOADING,
  ERROR,
}

export const getOfferState = (
  quote?: LouiseQuote,
  productHashId?: string,
  isFetchingQuote?: boolean,
): OfferState => {
  const combinationError =
    store.getState().offers.offersInfo.offerCombinationError[
      productHashId || quote?.hash_id || ''
    ];
  if (
    !quote ||
    queryClient.isFetching({ queryKey: [productHashId || quote?.hash_id] }) !==
      0 ||
    isFetchingQuote
  )
    return OfferState.LOADING;
  // If quote does not contain a hash id it means it failed to calculate
  if (!quote.hash_id || combinationError) return OfferState.ERROR;
  return OfferState.FETCHED;
};

export const getOfferIndex = (
  hashId: string,
  riskObject: string,
): number | undefined => {
  const activeCarQuoteFilter = store.getState().offers.activeCarQuoteFilter;
  const pageId = ['car', 'two_wheeler'].includes(riskObject)
    ? activeCarQuoteFilter
    : riskObject;

  const index = store.getState().offers.shownProductIds[pageId].indexOf(hashId);

  if (index > -1) return index + 1;
  return undefined;
};

export const getOfferForProductAndQuote = (
  product: ProductWithQuality,
  quote?: LouiseQuote | FailedLouiseQuote,
  isNewRoute?: boolean,
  isFetchingQuote?: boolean,
): LouiseOffer => {
  let productInfo;

  if (isFailedQuote(quote)) {
    productInfo = product.info;
  } else {
    productInfo = quote?.info || product.info;
  }

  if (isSocialLoginUrl && !isNewRoute) {
    const offerIndex =
      getOfferIndex(product.hash_id, product.info.insurance_type) || '0';
    productInfo = {
      ...productInfo,
      company: {
        ...productInfo.company,
        name: `${i18n.t('Insurer')} ${offerIndex}`,
      },
    };
  }

  if (isFailedQuote(quote)) {
    return {
      code: quote.code,
      errorMessage: handleResponseLanguage(quote.detail),
      state: OfferState.ERROR,
      hash_id: product.hash_id,
      self: product.self,
      info: productInfo,
      selected_guarantee_tags: product.selected_guarantee_tags,
      selected_guarantees: product.selected_guarantees,
      possible_guarantees: product.possible_guarantees,
      possible_insurances: product.possible_insurances,
      guarantee_content: product.guarantee_content,
      linked_quotes: product.linked_quotes || [],
      matching_score: product.matching_score,
      quality: product.quality,
    };
  }

  return {
    id: quote?.id,
    state: getOfferState(quote, product.hash_id, isFetchingQuote),
    hash_id: product.hash_id,
    self: product.self,
    info: productInfo,
    selected_guarantees:
      quote?.selected_guarantees || product.selected_guarantees,
    selected_guarantee_tags:
      quote?.selected_guarantee_tags || product.selected_guarantee_tags,
    possible_guarantees: product.possible_guarantees,
    possible_insurances: product.possible_insurances,
    guarantee_content: quote?.guarantee_content || product.guarantee_content,
    // Quality related
    matching_score: quote?.matching_score || product.matching_score,
    quality: quote?.quality || product.quality,
    // Price related
    premium: quote?.premium,
    guarantees: quote?.guarantees,
    details: quote?.details,
    promotions: quote?.promotions,
    // Others
    linked_quotes: product.linked_quotes || [],
  };
};

/**
 * Function that only needs the general params and will fill in the required offer details
 */
export const createLouiseOffer = (
  quote: LouiseQuote,
  sessionState: LouiseSessionObject,
  distribution: Distribution,
  userLang: string,
  user?: User,
): Promise<[OfferCreationResponse | ErrorResponse, number]> => {
  const offersInfo = store.getState().offers.offersInfo;
  const campaign = store.getState().productFactory.campaign;
  const preferredOffers = store.getState().offers.preferredOffers;

  const contractStartDate =
    offersInfo.contractStartDate[quote.hash_id] || new Date().toISOString();
  const startDate = new Date().toISOString();
  const endDate = addMonths(new Date(), 1).toISOString();

  const isPreferredOffer =
    preferredOffers[quote.info.insurance_type] === quote.hash_id;

  const noInformationRequirement = ['funeral'];

  return createOffer({
    prospectId: sessionState.prospect.id,
    distributionId: distribution.id,
    quoteId: quote.id,
    riskObject: quote.info.insurance_type,
    guarantees: quote.selected_guarantees,
    riskObjectRevisions: getQuoteRiskObjectRevisions(
      quote.info.insurance_type,
      sessionState,
    ),
    startDate: startDate,
    endDate: endDate,
    commencementDate: contractStartDate,
    assignedTo: campaign
      ? campaign.assigned_to || campaign.created_by
      : user!.id,
    language: userLang.toUpperCase(),
    acknowledgements: [
      ...(offersInfo.acknowledgements[quote.info.insurance_type] || []),
      ...(offersInfo.acknowledgements.general || []),
    ],
    paymentSpread: quote.details.payment_interval.toUpperCase(),
    customerSheet: offersInfo.customerSheet,
    specificNeeds: formatSpecificNeeds(
      quote.info.company.key,
      quote.guarantee_content,
    ),
    reasonForContact: offersInfo.reasonOfContact,
    motivation: offersInfo.requiredInformation[quote.hash_id],
    noOtherContract:
      !offersInfo.insuranceTerminationInfo[quote.info.insurance_type],
    notice: offersInfo.insuranceTerminationInfo[quote.info.insurance_type],
    sendMailToProspect:
      isPreferredOffer && (isSocialLoginUrl || offersInfo.sendMailToProspect),
    withInformationRequirement: !noInformationRequirement.includes(
      quote.info.insurance_type,
    ),
  });
};

export const getProductKey = (
  product: ProductWithQuality | LouiseOffer,
): string => {
  /** Disabling the activeCarQuoteFilter becuase sometimes the offer state is still empty which results in bugs*/
  // const { activeCarQuoteFilter } = store.getState().offers;
  return product.info.company.key + product.info.insurance_type;
};
