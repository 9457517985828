import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsTransportedGoods: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 8.8a2 2 0 012-2h1.8a5.6 5.6 0 015.6 5.6v18A1.6 1.6 0 0016 32h27a2 2 0 010 4H16a5.6 5.6 0 01-5.6-5.6v-18a1.6 1.6 0 00-1.6-1.6H7a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 16.2a3.4 3.4 0 00-3.4 3.4v3.6a3.4 3.4 0 003.4 3.4h7.2a3.4 3.4 0 003.4-3.4v-3.6a3.4 3.4 0 00-3.4-3.4H25zm-7.4 3.4a7.4 7.4 0 017.4-7.4h7.2a7.4 7.4 0 017.4 7.4v3.6a7.4 7.4 0 01-7.4 7.4H25a7.4 7.4 0 01-7.4-7.4v-3.6zM19.6 36a1.6 1.6 0 100 3.2 1.6 1.6 0 000-3.2zM14 37.6a5.6 5.6 0 1111.2 0 5.6 5.6 0 01-11.2 0zM35.8 36a1.6 1.6 0 100 3.2 1.6 1.6 0 000-3.2zm-5.6 1.6a5.6 5.6 0 1111.2 0 5.6 5.6 0 01-11.2 0z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsTransportedGoods;
