import React, {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  HStack,
  Box,
  Input,
  CloseButton,
  Tooltip,
  useFormControlContext,
  BoxProps,
  Icon,
  Spinner,
  Text,
} from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import SearchInputTagList from './SearchInputTagList';

interface Option {
  label: string;
  value: string;
  icon?: React.FC<React.PropsWithChildren<{ color: string }>>;
}

interface Props {
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  selectedOptions?: Option[];
  onChangeSelected?: (option: Option) => void;
  onClose?: () => void;
  onClosePopover?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  boxProps?: BoxProps;
  isDisabled?: boolean;
  icon?: React.ReactElement;
  onPressEnter?: () => void;
  maxLength?: number;
  isLoading?: boolean;
  showIcon?: boolean;
}

const SearchInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      onChange,
      onBlur = () => null,
      onFocus = () => null,
      onClosePopover,
      placeholder,
      selectedOptions,
      onChangeSelected = () => null,
      onClose,
      boxProps,
      isDisabled,
      icon,
      onPressEnter,
      maxLength,
      isLoading,
      showIcon = true,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);
    const measureRef = useRef<HTMLDivElement>(null);
    const [inputWidth, setInputWidth] = useState(0);

    const { t } = useTranslation();
    const { isInvalid = false } = useFormControlContext() || {};

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && e.currentTarget.value) {
        onPressEnter?.();
      }
    };

    useLayoutEffect(() => {
      if (measureRef.current) setInputWidth(measureRef.current.scrollWidth);
    }, [value]);

    const handleContainerClick = () => {
      if (inputRef.current) inputRef.current.focus();
    };

    return (
      <HStack
        spacing="0"
        px="2"
        h={!onClose ? '45px' : '14'}
        border={!onClose ? '1px solid' : 'unset'}
        borderRadius={!onClose ? '5px' : 'unset'}
        borderBottom="1px solid"
        borderColor={isInvalid ? 'danger.main' : 'gray.strokeAndBg'}
        boxShadow={!onClose ? 'unset' : 'level2'}
        cursor="text"
        _focusVisible={{ outline: 'none' }}
        _focus={{ outline: 'none' }}
        bg="white"
        pos="relative"
        overflow="hidden"
        onClick={handleContainerClick}
        {...boxProps}
      >
        {selectedOptions?.length === 0 && !value && (
          <Text
            pos="absolute"
            height="min-content"
            margin="auto"
            top="0"
            bottom="0"
            color="gray.300"
            ml={showIcon ? '32px' : '0'}
          >
            {placeholder || t('Search')}
          </Text>
        )}
        {showIcon && (
          <Box
            flexShrink={0}
            w="28px"
            h="28px"
            sx={{ svg: { w: '100%', h: '100%' } }}
            color="gray.300"
          >
            {icon || <Icon name="GeneralActionsSearch" />}
          </Box>
        )}

        <SearchInputTagList
          onClosePopover={onClosePopover}
          onClick={onChangeSelected}
          selectedOptions={selectedOptions || []}
          isDisabled={isDisabled}
          suffix={
            <Box
              width={`${inputWidth}px`}
              pos="relative"
              ml={showIcon ? '4px' : '0'}
            >
              <Input
                h="7"
                flexShrink={1}
                ref={inputRef}
                p="0"
                _invalid={{ borderColor: 'unset' }}
                border="none"
                value={value}
                type="text"
                onBlur={onBlur}
                onFocus={onFocus}
                onChange={onChange}
                isDisabled={isDisabled}
                onKeyDown={handleKeyDown}
                maxLength={maxLength}
                background="transparent"
                fontSize="16px"
              />
              {/* This span is used to measure how long the input is so that the width can be adapted */}
              <Box
                as="span"
                pos="absolute"
                top="0"
                visibility="hidden"
                ref={measureRef}
                insetInlineStart={0}
                insetInlineEnd="auto"
                zIndex="999"
                whiteSpace="pre"
                aria-hidden
              >
                {value}&nbsp;
              </Box>
            </Box>
          }
        />

        {isLoading && (
          <Spinner pos="absolute" right="4" size="sm" color="primary.500" />
        )}
        {onClose && (
          <Tooltip hasArrow label={t('Close')} placement="bottom">
            <CloseButton onClick={onClose} />
          </Tooltip>
        )}
      </HStack>
    );
  },
);

export default SearchInput;
