import React, { PropsWithChildren, UIEvent } from 'react';
import { useIsOverflow, Box, BoxProps } from '@wegroup/design-system';
import TabListScrollButton from './TabListScrollButton';
import TabListShadow from '../WideButton/TabListShadow';

const TabListContainer: React.FC<PropsWithChildren<BoxProps>> = ({
  children,
  ...props
}) => {
  const {
    isOverflowingLeft,
    isOverflowingRight,
    checkOverflow,
    ref: customRef,
  } = useIsOverflow();

  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    checkOverflow();
  };

  const handleScrollButtonClick = (direction: 'left' | 'right') => {
    const isLeft = direction === 'left';
    const { current } = customRef;
    const incrementValue = isLeft ? -150 : 150;
    if (current) {
      current.scrollTo({
        left: current.scrollLeft + incrementValue,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box position="relative" {...props}>
      <Box
        h="100%"
        onScroll={handleScroll}
        ref={customRef}
        overflowX="auto"
        sx={{
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {children}
      </Box>
      {/* Shadows */}
      <TabListShadow position="left" isVisible={isOverflowingLeft} />
      <TabListShadow position="right" isVisible={isOverflowingRight} />

      {/* ScrollButton */}
      <TabListScrollButton
        direction="left"
        isVisible={isOverflowingLeft}
        onClick={() => handleScrollButtonClick('left')}
      />
      <TabListScrollButton
        direction="right"
        isVisible={isOverflowingRight}
        onClick={() => handleScrollButtonClick('right')}
      />
    </Box>
  );
};

export default TabListContainer;
