import { MenuItem } from '@chakra-ui/react';
import React from 'react';
import SearchPickerOption from '../SearchPickerList/SearchPickerOption';
import { AvatarPickerOption } from './index';
import { Text, Center } from './../../../../index';
import { useTranslation } from 'react-i18next';

interface Props {
  items: AvatarPickerOption[];
  selectedOptions?: AvatarPickerOption[];
  onClick: (options: AvatarPickerOption) => void;
}

const AvatarPickerMenuItems: React.FC<Props> = ({
  items,
  selectedOptions,
  onClick,
}) => {
  const { t } = useTranslation();
  if (items.length) {
    return (
      <>
        {items.map((option, index) => (
          <MenuItem
            as={SearchPickerOption}
            key={index}
            option={option}
            onClick={() => onClick(option)}
            selected={selectedOptions?.some(
              ({ value }) => value === option.value,
            )}
            hideIcon
          />
        ))}
      </>
    );
  }
  return (
    <Center p="3">
      <Text>{t('NO-RESULTS-FOUND')}</Text>
    </Center>
  );
};

export default AvatarPickerMenuItems;
