import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleAccident: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M32 29.62a1.44 1.44 0 00-1-.84l-2.09-.45-16.22-3.52a1.32 1.32 0 01-.82-.53l-3.72-5a.84.84 0 00-.44-.28l-3.26-.6a.391.391 0 00-.45.39V40h10a6.5 6.5 0 1013 0h4.51a1.5 1.5 0 00.5-.79c.505-1.973.88-3.978 1.12-6A13.75 13.75 0 0032 29.62zM24 40a3.5 3.5 0 11-7 0 2.747 2.747 0 010-.5 3.5 3.5 0 117 0c.015.166.015.334 0 .5zM41.55 12.78a1.52 1.52 0 00-1.87-.26L34 15.84 31.45 8a1.54 1.54 0 00-3 .19l-1.67 8.56-5.47-3.19a1.54 1.54 0 00-1.91.3 1.58 1.58 0 00-.15 1.93l4.19 6.43a1.53 1.53 0 001 .67 1.56 1.56 0 001.17-.23 1.6 1.6 0 00.39-2.15l-.57-.88 1.65 1a1.55 1.55 0 001.42.07 1.579 1.579 0 00.87-1.12l1-4.87 1.3 4a1.59 1.59 0 00.92 1 1.499 1.499 0 001.32-.11l2.42-1.41-1.39 2.49a1.57 1.57 0 00.57 2.13c.233.139.499.211.77.21a1.55 1.55 0 001.35-.79l4.19-7.5a1.58 1.58 0 00-.27-1.95z"
    ></path>
    <path
      fill="currentColor"
      d="M45.33 25.14l-12.57 2.3c.48.37.858.855 1.1 1.41 1.11 2.68 1.25 3.73 1.25 4.34a44.753 44.753 0 01-1.18 6.46 3.51 3.51 0 01-1.16 1.84 6.19 6.19 0 0012.1-1.17h.62a.55.55 0 00.55-.59V25.68a.559.559 0 00-.71-.54zM42 40.35a3.45 3.45 0 01-6.78 0 5.211 5.211 0 01-.06-.69 3.462 3.462 0 015.967-2.566 3.46 3.46 0 01.943 2.566 3.937 3.937 0 01-.07.69z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleAccident;
