import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsEditDocument: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.817 15.483a6.205 6.205 0 014.388-1.817h6.308a2 2 0 110 4h-6.308A2.206 2.206 0 009 19.87v18.924A2.205 2.205 0 0011.205 41H30.13a2.205 2.205 0 002.205-2.205v-6.308a2 2 0 014 0v6.308A6.205 6.205 0 0130.13 45H11.205A6.205 6.205 0 015 38.795V19.87c0-1.646.654-3.224 1.817-4.388z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M33.971 6.892a6.46 6.46 0 119.137 9.137L25.235 33.9a2 2 0 01-1.414.586h-6.308a2 2 0 01-2-2v-6.308a2 2 0 01.586-1.414L33.971 6.892zM38.54 9a2.46 2.46 0 00-1.74.72L19.513 27.008v3.48h3.48L40.279 13.2A2.46 2.46 0 0038.54 9z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M30.817 10.046a2 2 0 012.829 0l6.308 6.308a2 2 0 11-2.829 2.829l-6.308-6.308a2 2 0 010-2.829z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralActionsEditDocument;
