import React, {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';

interface Option {
  label: string;
  value: string;
  icon?: FC<React.PropsWithChildren<{ color: string }>>;
}

export interface Category {
  label: string;
  option_keys: string[];
}

interface ComponentProps {
  options?: Option[];
  searchValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedOptions: Option[];
  setSelectedOptions: Dispatch<SetStateAction<Option[]>>;
}

type SearchPickerFunction = (props: Props) => {
  selectedOptions: Option[];
  componentProps: ComponentProps;
};

interface Props {
  searchFunction: (value: string) => Option[];
  initialSelectedOptions?: Option[];
  update?: boolean;
}

const useSearchPicker: SearchPickerFunction = ({
  searchFunction,
  initialSelectedOptions = [],
  update,
}) => {
  const [value, setValue] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    initialSelectedOptions,
  );

  useLayoutEffect(() => {
    if (update) {
      setSelectedOptions(initialSelectedOptions);
      setValue('');
    }
  }, [update]);

  useEffect(() => {
    setValue('');
  }, [selectedOptions]);

  const componentProps: ComponentProps = {
    searchValue: value,
    onChange: (e: ChangeEvent<HTMLInputElement>) =>
      setValue(e.currentTarget.value),
    options: searchFunction(value),
    setSelectedOptions,
    selectedOptions,
  };

  /**
   * Pass the componentProps to the SearchPicker component
   * Use the selectedOptions for submitions.. etc
   */
  return {
    selectedOptions,
    componentProps,
  };
};

export default useSearchPicker;
