import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationKnowledgeBase: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21 5C12.163 5 5 12.163 5 21s7.163 16 16 16c3.697 0 7.102-1.254 9.811-3.36l10.775 10.774a2 2 0 102.828-2.828L33.64 30.81A15.931 15.931 0 0037 21c0-8.837-7.163-16-16-16zM9 21c0-6.627 5.373-12 12-12s12 5.373 12 12-5.373 12-12 12S9 27.627 9 21z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21 25a2 2 0 012 2v.02a2 2 0 11-4 0V27a2 2 0 012-2zM19.225 13.326A5 5 0 1120.999 23a2 2 0 01.002-4 1.001 1.001 0 10-.76-1.65 2 2 0 01-3.042-2.597 5 5 0 012.026-1.427z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationKnowledgeBase;
