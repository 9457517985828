import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ThemeName } from '../../../../../design-system/src/theme/themes';
import { bp } from '../../../utils/styledComponentsUtils';

interface generalData {
  isCollapsedNavForced: boolean;
  isNavCollapsed: boolean;
  themeName: ThemeName;
  sessionRecordingUrl: string;
  manifestVersion: string;
  isLoadingApp: boolean;
  isInitializingNotifications: boolean;
}

const generalDataSlice = createSlice({
  name: 'generalData',
  initialState: {
    isNavCollapsed: window.innerWidth < bp.laptop,
    isCollapsedNavForced: false,
    isLoadingApp: true,
    isInitializingNotifications: true,
  } as generalData,
  reducers: {
    setIsCollapsedNavForced(state, action: PayloadAction<boolean>) {
      state.isCollapsedNavForced = action.payload;
    },
    setIsNavCollapsed(state, action: PayloadAction<boolean>) {
      state.isNavCollapsed = action.payload;
    },
    setThemeName(state, action: PayloadAction<ThemeName>) {
      state.themeName = action.payload;
    },
    setSessionRecordingUrl(state, action: PayloadAction<string>) {
      state.sessionRecordingUrl = action.payload;
    },
    setIsLoadingApp(state, action: PayloadAction<boolean>) {
      state.isLoadingApp = action.payload;
    },
    setIsInitializingNotifications(state, action: PayloadAction<boolean>) {
      state.isInitializingNotifications = action.payload;
    },
  },
});

export const {
  setIsCollapsedNavForced,
  setIsNavCollapsed,
  setSessionRecordingUrl,
  setThemeName,
  setIsLoadingApp,
  setIsInitializingNotifications,
} = generalDataSlice.actions;

export default generalDataSlice.reducer;
