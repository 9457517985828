import { useMutation, useQueryClient } from 'react-query';
import { getUserDataQKey } from '../authentication/api/get/getUserData';
import { UserData } from '../authentication/types/UserData';
import { wgApi } from './wgApi';

interface Payload {
  metadata: Record<string, unknown>;
}

export const patchUserMetaData = async (payload: Payload) => {
  const { data, isError } = await wgApi.patch(`/v1/api/users/me`, payload);
  if (isError) throw new Error();
  return data;
};

export const usePatchUserMetaData = () => {
  const queryClient = useQueryClient();

  return useMutation(patchUserMetaData, {
    onMutate: async (payload) => {
      const userQKey = getUserDataQKey();

      await queryClient.cancelQueries(userQKey);

      const userData = queryClient.getQueryData<UserData>(userQKey);

      if (userData) {
        queryClient.setQueryData(userQKey, {
          ...userData,
          metadata: {
            ...userData.metadata,
            ...payload.metadata,
          },
        });
      }
    },
  });
};
