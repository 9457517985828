import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationUsufruct: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="356"
      height="229"
      fill="none"
      viewBox="0 0 356 229"
    >
      <mask
        id="mask0_5104_12052"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_5104_12052)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeDasharray="8 12"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M177.881 65.123l-71.303 71.296v91.131h142.599v-91.131l-71.296-71.296z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M182.306 157.356c3.784 1.055 6.245 2.511 8.93 5.286 2.315 2.391 3.459 4.364 4.363 7.526.307 1.073.323 1.599.375 12.819.054 11.62.052 11.706-.277 12.486-.614 1.455-1.829 2.262-3.407 2.262-1.135 0-1.877-.321-2.652-1.145-1.019-1.084-.987-.708-.987-11.621 0-5.445-.043-10.014-.095-10.153-.055-.147-.278-.253-.532-.253-.253 0-.476.106-.531.253-.053.139-.095 4.639-.095 10.001v9.748l-1.174 15.049c-.646 8.277-1.243 15.47-1.327 15.985-.36 2.2-2.013 3.834-4.325 4.274-1.349.257-5.269.096-6.223-.254-1.019-.375-2.351-1.65-2.851-2.728-.391-.843-.44-1.333-1.652-16.725l-1.25-15.857v-9.62c0-5.291-.042-9.734-.095-9.873-.133-.354-.8-.317-.996.056-.107.204-.162 3.673-.162 10.153 0 10.851.032 10.481-.987 11.565-.85.905-1.563 1.186-2.837 1.119-.947-.05-1.22-.136-1.852-.581-.41-.289-.934-.858-1.175-1.276l-.434-.754-.048-10.787c-.031-7.054.011-11.31.123-12.297.739-6.553 5.977-12.417 12.929-14.471 2.072-.612 2.881-.706 5.483-.64 1.88.048 2.633.138 3.761.453z"
          clipRule="evenodd"
        ></path>
        <rect
          width="14.153"
          height="18.63"
          x="170.969"
          y="132"
          fill={colors.primary[500]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          rx="7.076"
        ></rect>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeDasharray="8 12"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M249.177 64.984a7.313 7.313 0 00-7.312-7.312H229.78a7.313 7.313 0 00-7.311 7.312v14.464l26.734 26.422-.026-40.886z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeDasharray="8 12"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M271.997 124.43l-81.586-80.642a17.828 17.828 0 00-25.066 0L83.759 124.43a13.368 13.368 0 0018.904 18.904l75.218-75.211 75.211 75.211a13.367 13.367 0 0018.905-18.904z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationUsufruct;
