import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationPaintings: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M132 39h98v113.077h-98V39Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M141.046 48.046h79.908v94.985h-79.908V48.046Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M153.108 76.692a15.075 15.075 0 0 0 15.077 15.077 15.08 15.08 0 0 0 15.077-15.077 15.079 15.079 0 0 0-15.077-15.077 15.075 15.075 0 0 0-15.077 15.077Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M166.677 81.216h37.692v37.692h-37.692V81.216Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M166.677 91.694a15.077 15.077 0 0 0 15.894-10.478h-9.863a6.031 6.031 0 0 0-6.031 6.03v4.448Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="m176.528 108.39 9.553 16.549a3.02 3.02 0 0 1 0 3.015 3.013 3.013 0 0 1-2.611 1.508h-19.109a3.016 3.016 0 0 1-2.611-4.523l9.555-16.549a3.018 3.018 0 0 1 5.223 0Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M176.528 108.39a3.01 3.01 0 0 0-2.611-1.507 3.016 3.016 0 0 0-2.612 1.507l-4.064 7.05a6.028 6.028 0 0 0 5.467 3.468h9.893l-6.073-10.518Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.27 114.542h98.221a3.508 3.508 0 0 1 3.509 3.509v63.136a3.504 3.504 0 0 1-3.506 3.506H52.272a3.509 3.509 0 0 1-3.508-3.509v-63.136a3.508 3.508 0 0 1 3.506-3.506Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="m118.422 145.296-9.805 11.764 5.035 6.586h18.31a2.92 2.92 0 0 0 2.897-3.291 2.922 2.922 0 0 0-.649-1.496l-11.298-13.557a2.914 2.914 0 0 0-2.243-1.055 2.924 2.924 0 0 0-2.247 1.049Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M68.831 158.79 88.5 136.497a2.921 2.921 0 0 1 4.513.16l20.639 26.988H71.023a2.916 2.916 0 0 1-2.664-1.719 2.924 2.924 0 0 1 .472-3.136ZM134.696 133.832a5.266 5.266 0 0 1-3.247 4.86 5.264 5.264 0 0 1-7.173-3.834 5.26 5.26 0 1 1 10.42-1.026Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M121.454 219.409a5.109 5.109 0 0 0 6.19 3.714 5.105 5.105 0 0 0 3.714-6.19l-7.183-28.732h-10.523l7.802 31.208ZM116.282 198.723H86.468l-2.63 10.521h35.074l-2.63-10.521Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M81.303 219.409a5.106 5.106 0 0 1-6.19 3.714 5.104 5.104 0 0 1-3.714-6.19l7.183-28.732h10.523l-7.802 31.208Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M101.375 111.033V100.51"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M141.713 182.936a5.264 5.264 0 0 1-5.263 5.265H66.3a5.262 5.262 0 1 1 0-10.525h70.15a5.264 5.264 0 0 1 5.263 5.26Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M113.652 114.542a3.512 3.512 0 0 1-3.509 3.506H92.607a3.504 3.504 0 0 1-3.32-2.144 3.51 3.51 0 0 1 1.95-4.629 3.523 3.523 0 0 1 1.37-.242h17.536a3.513 3.513 0 0 1 3.243 2.166c.176.426.266.882.266 1.343Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M113.652 114.542a3.512 3.512 0 0 1-3.509 3.506H92.607a3.504 3.504 0 0 1-3.32-2.144 3.51 3.51 0 0 1 1.95-4.629 3.523 3.523 0 0 1 1.37-.242h17.536a3.513 3.513 0 0 1 3.243 2.166c.176.426.266.882.266 1.343Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="m273.793 191 6.696 29.736a2.903 2.903 0 0 0 2.832 2.264h3.856a2.907 2.907 0 0 0 2.853-2.356 2.891 2.891 0 0 0-.053-1.324L282.085 191h-8.292ZM243.918 191l-7.892 28.32a2.905 2.905 0 0 0 2.8 3.68h3.864a2.9 2.9 0 0 0 2.828-2.264L252.214 191h-8.296ZM259 191v29.096a2.902 2.902 0 0 0 2.9 2.904h2.196c.77 0 1.509-.306 2.053-.851a2.901 2.901 0 0 0 .851-2.053V191h-8ZM263 81a3.998 3.998 0 0 0-4 4v10h8V85a3.997 3.997 0 0 0-4-4Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M303 191h-80a3.995 3.995 0 0 1-2.828-1.172 3.995 3.995 0 0 1 0-5.656A3.995 3.995 0 0 1 223 183h80c1.061 0 2.078.421 2.828 1.172a3.995 3.995 0 0 1 0 5.656A3.995 3.995 0 0 1 303 191Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M295 95h-64a3.998 3.998 0 0 0-4 4v84h72V99a3.997 3.997 0 0 0-4-4Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M239 106.924h24v24.024h-24v-24.024ZM290.248 144.56a12.001 12.001 0 0 1-20.485 8.485 12.003 12.003 0 0 1 1.818-18.463 12 12 0 0 1 18.667 9.978ZM248.872 170.72a2 2 0 0 1-2.244-1.6l-2.828-14.152a2.003 2.003 0 0 1 1.774-2.382c.372-.035.748.035 1.082.202l22.616 11.316a2 2 0 0 1 .89.878 1.998 1.998 0 0 1-1.502 2.894l-19.788 2.844Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationPaintings;
