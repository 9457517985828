import {
  Button,
  SearchSelect,
  RedAsterix,
  FormControl,
  FormErrorMessage,
  FormLabel,
  InputGroup,
  Input,
  InputRightAddon,
} from '@wegroup/design-system';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { annexOptions } from './constants/inputs';

interface Props {
  onSave: () => void;
}

const AnnexInputCardForm: React.FC<Props> = ({ onSave }) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name="type"
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl isInvalid={!!error}>
            <FormLabel>
              {t('Type')}
              <RedAsterix />
            </FormLabel>
            <SearchSelect
              clearable
              data={annexOptions}
              value={value}
              placeholder={t('CHOOSE_OPTION')}
              onChange={onChange}
            />
            {error?.message && (
              <FormErrorMessage>{t(error.message)}</FormErrorMessage>
            )}
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name="surface"
        render={({ field, fieldState: { error } }) => (
          <FormControl isInvalid={!!error}>
            <FormLabel>
              {t('Surface')}
              <RedAsterix />
            </FormLabel>
            <InputGroup>
              <Input type="number" {...field} />
              <InputRightAddon bg="white" fontWeight="400">
                m²
              </InputRightAddon>
            </InputGroup>
            {error?.message && (
              <FormErrorMessage>{t(error.message)}</FormErrorMessage>
            )}
          </FormControl>
        )}
      />
      <Button onClick={onSave} size="sm">
        {t('SAVE')}
      </Button>
    </>
  );
};

export default AnnexInputCardForm;
