import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsCloseDrawer: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <rect
      width="38"
      height="38"
      x="6"
      y="6"
      stroke="currentColor"
      fill="none"
      strokeWidth="4"
      rx="4"
    ></rect>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.113 31.272a1.615 1.615 0 010-2.284l2.373-2.373h-7.833a1.615 1.615 0 110-3.23h7.833l-2.373-2.373a1.615 1.615 0 112.284-2.284l5.13 5.13c.63.63.63 1.653 0 2.284l-5.13 5.13c-.63.63-1.653.63-2.284 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M28.264 5.593H32.263999999999996V44.408H28.264z"
    ></path>
  </Icon>
);

export default GeneralActionsCloseDrawer;
