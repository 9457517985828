import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersMegaphone: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M34 12.361l-.69.574-9.233 2.476 7.889-6.56a3.683 3.683 0 015.46.853c.375.59.574 1.274.574 1.973l-4 1.073v-.389z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M34 12.75l-.69.185-.001.001-9.232 2.475H9.867A3.869 3.869 0 006 19.274v7.452a3.861 3.861 0 003.867 3.863H12v7.578c0 1.193.607 2.208 1.448 2.869.83.651 1.871.964 2.885.964h2.334c1.013 0 2.056-.313 2.885-.964.841-.661 1.448-1.676 1.448-2.87V30.59h1.077l7.889 6.56a3.684 3.684 0 005.46-.853c.375-.59.574-1.274.574-1.974v-3.385a8 8 0 000-15.874v-3.385l-4 1.072zM23 26.59h1.8a2 2 0 011.279.462L34 33.639V12.75l-.691.186-7.22 6.005a2.002 2.002 0 01-1.302.47H23v7.178zm-4 0V19.41h-9v7.178h9zm0 4h-3v7.347a.836.836 0 00.333.064h2.334c.147 0 .26-.032.333-.064V30.59zm19-3.716v-7.746a4 4 0 010 7.746z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersMegaphone;
