import Button from './button';
import Accordion from './accordion';
import FormLabel from './form-label';
import Input from './input';
import Alert from './alert';
import Tooltip from './tooltip';
import Table from './table';
import Breadcrumb from './breadcrumb';
import Select from './select';
import Badge from './badge';
import Switch from './switch';
import Link from './link';
import Popover from './popover';
import Tag from './tag';
import Menu from './menu';
import NumberInput from './number-input';
import Modal from './modal';
import Checkbox from './checkbox';
import Tabs from './tabs';
import Textarea from './textarea';
import Drawer from './drawer';
import Radio from './radio';
import FormError from './form-error';

const Components = {
  Accordion,
  Button,
  FormLabel,
  FormError,
  Input,
  Alert,
  Tooltip,
  Table,
  Breadcrumb,
  Select,
  Badge,
  Switch,
  Link,
  Popover,
  Tag,
  Menu,
  NumberInput,
  Modal,
  Checkbox,
  Tabs,
  Textarea,
  Radio,
  Drawer,
};

export default Components;
