import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
} from '@wegroup/design-system';
import React, { ReactElement } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Change } from '../../../../../../types/Form';
import { prefilledInputComponentStyling } from '../../../../../flows/src/constants/InputComponentStyling';

interface Props {
  name?: string;
  prefilled?: boolean;
  value?: number | null;
  onChange?: (e: Change) => void;
  onBlur?: () => void;
  rightAddon?: ReactElement;
  symbol?: string;
}

const CurrencyInput: React.FC<
  React.PropsWithChildren<
    Props & Omit<InputProps, 'onChange' | 'onBlur' | 'name'>
  >
> = ({
  name,
  prefilled,
  value,
  onChange,
  onBlur,
  rightAddon,
  symbol,
  ...props
}) => {
  return (
    <InputGroup>
      <InputLeftAddon
        bg="white"
        color="text.gray"
        {...(prefilled && { sx: prefilledInputComponentStyling })}
        {...(props.isDisabled && { opacity: '0.4', cursor: 'not-allowed' })}
      >
        {symbol || '€'}
      </InputLeftAddon>
      <Input
        name={name}
        as={NumberFormat}
        thousandSeparator="."
        decimalSeparator=","
        value={value}
        onValueChange={(values: NumberFormatValues) => {
          const { formattedValue } = values;
          onChange?.({
            name: name || '',
            value: Number(formattedValue.replaceAll('.', '').replace(',', '.')),
          });
        }}
        onBlur={onBlur}
        {...(prefilled && { sx: prefilledInputComponentStyling })}
        {...props}
      />
      {rightAddon}
    </InputGroup>
  );
};

export default CurrencyInput;
