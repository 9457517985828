import React, { PropsWithChildren } from 'react';
import { buildAbilityFor } from '../../features/permissions/ability';
import { AbilityContext } from '../../features/permissions/Can';
import useFeatureFlags from '../../hooks/useFeatureFlags';

export const SocialPermissionsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { featureFlags } = useFeatureFlags();

  /**
   * Since a social user can't have any permissions (no plan) we set them manually
   */
  const ability = buildAbilityFor(
    {
      digital_signatures: {
        member_of: 'digital_sales_conversation',
        status: 'ACTIVE',
      },
    },
    featureFlags,
  );

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};
