import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleCompanyCar: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M39.286 21.143H35L31.129 14.7a1.429 1.429 0 00-1.223-.7H6.429A1.429 1.429 0 005 15.429v14.285a1.428 1.428 0 001.429 1.429h1.428a5.714 5.714 0 1111.429 0h10a5.715 5.715 0 0111.428 0h2.857A1.429 1.429 0 0045 29.714v-2.857a5.714 5.714 0 00-5.714-5.714zm-26-5.429h6v5.429H7c-.096 0-.191.008-.286.023v-5.452h6.572zm10.077 8.089a.714.714 0 01-.506.197h-1.383a.746.746 0 01-.76-.651.713.713 0 01.715-.778h1.382a.746.746 0 01.76.675.72.72 0 01-.208.557zm4.494-2.66h-7.143v-5.429h7.143v5.429zm1.429 0v-5.429h.457l3.274 5.429h-3.731z"
    ></path>
    <path
      fill="currentColor"
      d="M17.857 31.143a4.286 4.286 0 10-8.572 0 4.286 4.286 0 008.572 0zm-6.286 0a2 2 0 114 0 2 2 0 01-4 0zM35 35.429a4.285 4.285 0 100-8.57 4.285 4.285 0 000 8.57zm0-6.286a2 2 0 110 3.998 2 2 0 010-3.998zM13.571 18.286v-1.252a.18.18 0 00-.18-.177h-2.5a.177.177 0 00-.177.177v1.252h-2.68a.177.177 0 00-.177.177v2.68h8.572v-2.68a.18.18 0 00-.18-.177H13.57zm-2.142-.715h1.428v.715H11.43v-.715z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleCompanyCar;
