import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationBell: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M28.4 37c0 1.1-1.6 2-3.5 2s-3.5-.9-3.5-2h7zM37.2 34.5c-1.4-2-3.6-5.7-3.6-8.5 0-4 1-11.8-5.8-12v-.5c0-2-1.8-2.4-2.5-2.5h-.2c-.7.1-2.5.5-2.5 2.5v.5c-6.8.2-5.8 8-5.8 12 0 2.7-2.2 6.4-3.6 8.5-.5.6 0 1.5.8 1.5h22.4c.8 0 1.2-.9.8-1.5z"
    ></path>
  </Icon>
);

export default GeneralNavigationBell;
