import React, { forwardRef, ComponentPropsWithoutRef } from 'react';

import { Box, Text } from '@wegroup/design-system';

interface SearchSelectInputItemProps extends ComponentPropsWithoutRef<'div'> {
  label: string;
  customElement?: React.ReactElement;
}

const SearchSelectInputItem = forwardRef<
  HTMLDivElement,
  SearchSelectInputItemProps
>(
  (
    {
      label,
      className,
      customElement,
      ...otherProps
    }: SearchSelectInputItemProps,
    ref,
  ) => {
    return (
      <Box
        ref={ref}
        className={className}
        data-test-id="SEARCH-SELECT-INPUT_OPTION"
        {...otherProps}
      >
        {customElement ? customElement : <Text>{label}</Text>}
      </Box>
    );
  },
);

export default SearchSelectInputItem;
