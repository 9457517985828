const flowsInputFieldStyling = {
  bg: 'white',
  height: '4.5rem',
  boxShadow: 'base',
  border: '1px',
  borderRadius: 'md',
  borderColor: '#e4e4e4;',
  _focus: {
    border: '1px',
    borderColor: 'primary.main',
  },
  _invalid: {
    border: '1px',
    borderColor: 'danger.main',
  },
  _disabled: {
    cursor: 'not-allowed',
    pointerEvents: 'none',
    opacity: '0.4',
  },
};

const Input = {
  variants: {
    outline: {
      field: {
        bg: 'white',
        borderColor: 'gray.100',
        _focus: {
          borderColor: 'primary.main',
          boxShadow: 'none',
        },
        _placeholder: {
          color: 'gray.200',
        },
        _invalid: {
          border: '1px solid danger.main',
          boxShadow: 'none',
        },
      },
      addon: {
        borderColor: 'gray.100',
        fontWeight: '500',
        bg: 'gray.50',
        color: 'gray.300',
      },
    },
    flows: {
      field: flowsInputFieldStyling,
      addon: {
        ...flowsInputFieldStyling,
        bg: 'white',
        fontWeight: '500',
        color: 'text.gray',
      },
    },
    flowsPrefilled: {
      field: {
        ...flowsInputFieldStyling,
        borderColor: 'prefilled.borderColor',
        backgroundColor: 'prefilled.backgroundColor',
      },
      addon: {
        ...flowsInputFieldStyling,
        fontWeight: '500',
        borderColor: 'prefilled.borderColor',
        backgroundColor: 'prefilled.backgroundColor',
      },
    },
    unstyled: {
      field: {
        _placeholder: {
          color: 'gray.200',
        },
      },
    },
  },
};

export default Input;
