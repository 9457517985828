import { useBoolean } from '@wegroup/design-system';
import { isEqual, merge } from 'lodash';
import { useEffect, useLayoutEffect } from 'react';
import {
  Control,
  DeepPartial,
  useForm,
  UseFormHandleSubmit,
  UseFormProps,
  UseFormRegister,
} from 'react-hook-form';
import useFiltersPersistence from './useFiltersPersistence';

interface UseOverviewFilter<T> {
  /** Current filters state */
  filters: T;
  /** Checks if the current filter values are different from the default ones */
  haveFiltersChangedFromDefault: boolean;
  /** Check if the current filter values are different from the previous */
  haveFiltersChanged: boolean;
  /** React hook form control */
  control: Control<T>;
  /** React hook form register */
  register: UseFormRegister<T>;
  /** Resets the filters to their default values */
  clearFilters: () => void;
  /**
   * Validates the filters, pass a callback for the onSuccess and onError:
   *
   *
   * ```
   * applyFilters(
   *   (values) => handleFilterApply(values),
   *   (errors) => handleFilterErrors(values)
   * )
   * ```
   */
  applyFilters: UseFormHandleSubmit<T>;

  /**
   * Contains the functions that can be spread
   * in the `OverviewFilter` component
   */
  spreadable: OverviewFilterSpreadable;
}

export interface OverviewFilterSpreadable {
  onReset: () => void;
  haveFiltersChangedFromDefault: boolean;
  haveFiltersChanged: boolean;
}

interface OverviewFilter<T> {
  /** React hook form props */
  useFormProps?: UseFormProps<T>;
  /** Initial filters are what is initially selected */
  initialSelectedFilters?: T;
  /** A name used for storing the filters in the session storage */
  name: string;
}

export const useOverviewFilter = <T>({
  useFormProps,
  initialSelectedFilters,
  name,
}: OverviewFilter<T>): UseOverviewFilter<T> => {
  const [haveFiltersChanged, setHaveFiltersChanged] = useBoolean();

  const { clearPersistedFilters, persistFilters, sessionFilters } =
    useFiltersPersistence<T>(name);

  const filters = merge(initialSelectedFilters, sessionFilters);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isDirty },
    getValues,
  } = useForm<T>({
    ...useFormProps,
    defaultValues: filters || useFormProps?.defaultValues,
  });

  useEffect(() => {
    if (filters) {
      reset(filters);
    }
  }, []);

  useLayoutEffect(() => {
    if (isDirty) {
      setHaveFiltersChanged.on();
    } else {
      setHaveFiltersChanged.off();
    }
  }, [isDirty]);

  const clearFilters = () => {
    // Because of the new version of react-hook-form, we need to cast the defaultValues to DeepPartial<T>
    // This will be fixed in the next major version of react-hook-form
    reset(useFormProps?.defaultValues as DeepPartial<T>);
    clearPersistedFilters();
    setHaveFiltersChanged.off();
  };

  const applyFilters: UseFormHandleSubmit<T> = (onValid, onError) =>
    handleSubmit(
      (data, e) => {
        setHaveFiltersChanged.off();
        onValid(data, e);
        persistFilters(data);
        reset(getValues());
      },
      (errors, e) => {
        onError?.(errors, e);
      },
    );

  const haveFiltersChangedFromDefault = !isEqual(
    useFormProps?.defaultValues,
    getValues(),
  );

  return {
    filters: getValues(),
    haveFiltersChangedFromDefault,
    haveFiltersChanged,
    control,
    register,
    clearFilters,
    applyFilters,

    spreadable: {
      onReset: clearFilters,
      haveFiltersChangedFromDefault,
      haveFiltersChanged,
    },
  };
};
