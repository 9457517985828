import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyKitchen: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M40.49 43.49h-31a.87.87 0 01-.88-.88V18a.87.87 0 00-.87-.88.87.87 0 01-.88-.87v-1.54a.87.87 0 01.88-.87h3.29a.87.87 0 01.87.87v24.61a.88.88 0 00.88.87H37.2a.88.88 0 00.88-.87V14.71a.87.87 0 01.87-.87h3.29a.87.87 0 01.88.87v1.55a.87.87 0 01-.88.87.87.87 0 00-.87.88v24.6a.87.87 0 01-.88.88z"
    ></path>
    <path
      fill="currentColor"
      d="M33.24 26.47H16.77a2.2 2.2 0 00-2.2 2.2v6.58a2.2 2.2 0 002.2 2.2h16.47a2.2 2.2 0 002.2-2.2v-6.58a2.2 2.2 0 00-2.2-2.2zM18.74 21.965a2.085 2.085 0 10-4.17 0 2.085 2.085 0 004.17 0zM27.08 21.965a2.085 2.085 0 10-4.17 0 2.085 2.085 0 004.17 0zM35.43 21.965a2.085 2.085 0 10-4.17 0 2.085 2.085 0 004.17 0zM36.56 10.22h-.85v-.37H21.32v.37h-.85a.47.47 0 00-.46.47v.91a.47.47 0 00.46.47h.85v1.87a2.53 2.53 0 002.49 2.57h9.41a2.531 2.531 0 002.49-2.57v-1.87h.85a.46.46 0 00.44-.47v-.91a.46.46 0 00-.44-.47zM35.28 8.16h-4v-.73a.91.91 0 00-.89-.92h-3.73a.9.9 0 00-.88.92v.73h-4.24a.51.51 0 00-.51.52.52.52 0 00.51.52h13.74a.51.51 0 00.5-.52.509.509 0 00-.5-.52z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyKitchen;
