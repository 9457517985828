export type Subject =
  | 'MOBILITY'
  | 'HOME_AND_FAMILY'
  | 'TRAVEL_AND_LEISURE'
  | 'HOBBIES_AND_INTERESTS'
  | 'LIFE_AND_FUTURE'
  | 'HEALTH'
  | 'LEGAL';

export enum SubjectTypes {
  MOBILITY = 'MOBILITY',
  HOME_AND_FAMILY = 'HOME_AND_FAMILY',
  TRAVEL_AND_LEISURE = 'TRAVEL_AND_LEISURE',
  HOBBIES_AND_INTERESTS = 'HOBBIES_AND_INTERESTS',
  LIFE_AND_FUTURE = 'LIFE_AND_FUTURE',
  HEALTH = 'HEALTH',
  LEGAL = 'LEGAL',
}
