import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationConnections: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="260"
      height="201"
      fill="none"
      viewBox="0 0 260 201"
    >
      <path
        fill="url(#paint0_linear_1053_7133)"
        d="M167.003 3.29L82.145 195.008l89.433-.015h16.065l48.037-110.1V3.29h-68.677z"
        opacity="0.8"
      ></path>
      <path
        stroke={colors.primary[500]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2.4"
        d="M222.639 195.008H28.996"
      ></path>
      <path
        stroke={colors.primary[500]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M64.469 120.294a1.256 1.256 0 100-2.512 1.256 1.256 0 000 2.512zM93.838 19.138a2.331 2.331 0 100-4.663 2.331 2.331 0 000 4.663z"
      ></path>
      <path
        stroke={colors.primary[500]}
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M213.333 110.944a.985.985 0 100-1.97.985.985 0 000 1.97zM50.925 45.172a1.53 1.53 0 100-3.06 1.53 1.53 0 000 3.06z"
      ></path>
      <path
        stroke={colors.primary[500]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.8"
        d="M184.205 145.915a2.552 2.552 0 100-5.103 2.552 2.552 0 000 5.103zM195.135 120.596a1.275 1.275 0 10.001-2.55 1.275 1.275 0 00-.001 2.55z"
      ></path>
      <path
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        d="M166.718 65.595l-83.596 10.38-27.865 69.937 151.346-2.185-39.885-78.132zM82.03 75.43l126.759 67.75m-155.17 4.371l108.728-79.77"
      ></path>
      <circle
        cx="129.564"
        cy="100.016"
        r="12.605"
        fill="#fff"
        stroke={colors.primary[500]}
        strokeWidth="3.201"
      ></circle>
      <rect
        width="24.731"
        height="21.763"
        fill={colors.primary[100]}
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 .5 0 1 143.918 51.682)"
      ></rect>
      <rect
        width="24.731"
        height="21.763"
        fill={colors.primary[200]}
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 -.5 0 1 165.682 64.047)"
      ></rect>
      <rect
        width="24.731"
        height="23.741"
        fill="#fff"
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 .5 -.86603 .5 165.187 38.822)"
      ></rect>
      <rect
        width="18.979"
        height="16.702"
        fill={colors.primary[100]}
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 .5 0 1 65.742 65.082)"
      ></rect>
      <rect
        width="18.979"
        height="16.702"
        fill={colors.primary[200]}
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 -.5 0 1 82.445 74.573)"
      ></rect>
      <rect
        width="18.979"
        height="18.22"
        fill="#fff"
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 .5 -.86603 .5 82.065 55.213)"
      ></rect>
      <rect
        width="18.979"
        height="16.702"
        fill={colors.primary[100]}
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 .5 0 1 38.866 135.019)"
      ></rect>
      <rect
        width="18.979"
        height="16.702"
        fill={colors.primary[200]}
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 -.5 0 1 55.57 144.509)"
      ></rect>
      <rect
        width="18.979"
        height="18.22"
        fill="#fff"
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 .5 -.86603 .5 55.19 125.15)"
      ></rect>
      <rect
        width="17.829"
        height="15.69"
        fill={colors.primary[100]}
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 .5 0 1 189.579 133.328)"
      ></rect>
      <rect
        width="17.829"
        height="15.69"
        fill={colors.primary[200]}
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 -.5 0 1 205.271 142.243)"
      ></rect>
      <rect
        width="17.829"
        height="17.116"
        fill="#fff"
        stroke={colors.primary[500]}
        strokeWidth="3.201"
        rx="0.8"
        transform="matrix(.86603 .5 -.86603 .5 204.912 124.057)"
      ></rect>
      <defs>
        <linearGradient
          id="paint0_linear_1053_7133"
          x1="128.002"
          x2="238.517"
          y1="161.042"
          y2="22.278"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0.142"
            stopColor={colors.primary[50]}
            stopOpacity="0.6"
          ></stop>
          <stop
            offset="0.798"
            stopColor={colors.primary[50]}
            stopOpacity="0"
          ></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default IllustrationConnections;
