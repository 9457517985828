import { configureStore } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import rootReducer from './reducers/rootReducer';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage: storage,
  timeout: 1,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: {
    name: 'WeGroup | Company scan',
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers/rootReducer', () => {
    const newRootReducer = require('./reducers/rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type CompanyScanDispatch = typeof store.dispatch;
export type CompanyScanStore = typeof store.getState;

export default store;

export const persistor = persistStore(store);
