import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyVacationHoliday: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M43.83 41.19L39 30H26V16h10a.5.5 0 00.49-.64 12.05 12.05 0 00-23 0A.5.5 0 0014 16h10v14h-9.43L8.88 17.21A2.09 2.09 0 006.39 16a2 2 0 00-1.22 2.72l6.45 14.48L10 37H7a1 1 0 100 2h2.12l-.95 2.19a2 2 0 001.22 2.71 2.07 2.07 0 002.49-1.19L13.53 39h24.94l1.66 3.71a2.06 2.06 0 002.49 1.19 1.999 1.999 0 001.21-2.71zM14.42 37l1.34-3h20.48l1.34 3H14.42z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyVacationHoliday;
