import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsDownload: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 43a2 2 0 012-2h36a2 2 0 110 4H7a2 2 0 01-2-2zM25 5a2 2 0 012 2v22.5a2 2 0 11-4 0V7a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.414 19.086a2 2 0 010 2.828l-9 9a2 2 0 11-2.828-2.828l9-9a2 2 0 012.828 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.586 19.086a2 2 0 012.828 0l9 9a2 2 0 11-2.828 2.828l-9-9a2 2 0 010-2.828z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsDownload;
