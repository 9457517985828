import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyHail: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M23.464 4a14 14 0 00-13.85 12.086A8.167 8.167 0 004 23.833 8.167 8.167 0 0012.167 32h25.666a8.167 8.167 0 10-.56-16.315A14 14 0 0023.464 4zM18 36.668a2.334 2.334 0 11-4.667 0 2.334 2.334 0 014.667 0zM36.667 36.668a2.334 2.334 0 11-4.668 0 2.334 2.334 0 014.668 0zM27.333 36.668a2.333 2.333 0 11-4.666 0 2.333 2.333 0 014.666 0zM22.667 43.667a2.333 2.333 0 11-4.667 0 2.333 2.333 0 014.667 0zM32 43.667a2.334 2.334 0 11-4.667 0 2.334 2.334 0 014.667 0z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyHail;
