import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsChevronDown: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 29.172L13.414 17.586l-2.828 2.828L25 34.828l14.414-14.414-2.828-2.828L25 29.172z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsChevronDown;
