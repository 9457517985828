import React from 'react';
import { Box } from '@wegroup/design-system';
import { IconStatusCheck } from 'wg-fe-ui';

interface Props {
  isDisabled?: boolean;
}

const Checkbox: React.FC<React.PropsWithChildren<Props>> = ({
  isDisabled,
  ...otherProps
}) => (
  <Box
    {...otherProps}
    data-test-id="DesignSystem_Checkbox_Box"
    bgColor="white"
    w="8"
    h="8"
    flexShrink={0}
    borderRadius="md"
    borderWidth="2px"
    borderStyle="solid"
    borderColor="gray.100"
    _checked={{
      borderColor: isDisabled ? 'primary.200' : 'primary.500',
      bgColor: isDisabled ? 'primary.200' : 'primary.500',
    }}
    _disabled={{
      opacity: '0.3',
    }}
    transition="0.1s"
  >
    <Box
      {...otherProps}
      w="100%"
      h="100%"
      transition="0.1s"
      sx={{
        svg: {
          w: '100%',
          h: '100%',
        },
      }}
      ml="-1px"
    >
      <IconStatusCheck color="white" />
    </Box>
  </Box>
);

export default Checkbox;
