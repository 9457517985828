import { Flex } from '@wegroup/design-system';
import React from 'react';

const TableFiller: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Flex
      flexGrow={1}
      p="10"
      width="100%"
      bg="white"
      borderRadius="md"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Flex>
  );
};

export default TableFiller;
