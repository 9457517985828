import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMotorboat: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M58 156v22a3 3 0 0 0 3 3h49.644c1.79 0 3.183-1.557 2.966-3.333-1.494-12.253-3.251-20.356-6.914-27.646-2.319-4.613-7.337-7.021-12.5-7.021H71c-7.18 0-13 5.82-13 13Z"
          fill={colors.primary[200]}
        />
        <path
          d="m106.696 150.021-1.787.898 1.787-.898Zm6.914 27.646 1.986-.243-1.986.243ZM60 178v-22h-4v22h4Zm11-33h23.196v-4H71v4Zm33.909 5.919c3.503 6.971 5.228 14.792 6.716 26.99l3.971-.485c-1.502-12.308-3.29-20.693-7.113-28.301l-3.574 1.796ZM110.644 179H61v4h49.644v-4Zm.981-1.091a.979.979 0 0 1-.981 1.091v4c2.977 0 5.315-2.594 4.952-5.576l-3.971.485ZM94.195 145c4.601 0 8.814 2.138 10.714 5.919l3.574-1.796c-2.737-5.446-8.56-8.123-14.287-8.123v4ZM60 156c0-6.075 4.925-11 11-11v-4c-8.284 0-15 6.716-15 15h4Zm-4 22a5 5 0 0 0 5 5v-4a1 1 0 0 1-1-1h-4Z"
          fill={colors.primary[500]}
        />
        <path
          d="M58 180v40.15c.314 10.445 4.853 13.141 18.61 13.85h124.682c55.978-6.77 81.855-18.394 112.346-52.071 1.725-1.905.366-4.929-2.205-4.929H61a3 3 0 0 0-3 3Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <mask
          id="b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={56}
          y={175}
          width={261}
          height={61}
        >
          <path
            d="M58 180v40.15c.314 10.445 4.853 13.141 18.61 13.85h124.682c55.978-6.77 81.855-18.394 112.346-52.071 1.725-1.905.366-4.929-2.205-4.929H61a3 3 0 0 0-3 3Z"
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth={4}
          />
        </mask>
        <g mask="url(#b)">
          <path d="M58 195h258" stroke={colors.primary[500]} strokeWidth={16} />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationMotorboat;
