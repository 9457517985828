import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskAnalysisSubjectsBoat: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.325 38.015a2.602 2.602 0 00-2.17 1.084 5.373 5.373 0 01-4.447 2.24 5.372 5.372 0 01-4.448-2.24 1.385 1.385 0 012.25-1.614 2.603 2.603 0 002.227 1.084 2.603 2.603 0 002.169-1.085 5.372 5.372 0 014.448-2.239 5.373 5.373 0 014.448 2.24 2.603 2.603 0 002.227 1.084 2.603 2.603 0 002.17-1.085 5.373 5.373 0 014.447-2.239 5.373 5.373 0 014.448 2.24 2.604 2.604 0 002.227 1.084 2.604 2.604 0 002.17-1.085 1.385 1.385 0 012.25 1.615 5.372 5.372 0 01-4.449 2.24 5.372 5.372 0 01-4.448-2.24 2.603 2.603 0 00-2.227-1.084 2.602 2.602 0 00-2.17 1.084A5.372 5.372 0 0125 41.34a5.372 5.372 0 01-4.448-2.24 2.603 2.603 0 00-2.227-1.084z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.923 29.175c.26-.361.678-.575 1.123-.575h29.908a1.385 1.385 0 011.313 1.822l-1.661 4.985a1.385 1.385 0 01-2.627-.876l1.054-3.162H11.967l1.054 3.162a1.385 1.385 0 01-2.627.876l-1.661-4.985c-.141-.422-.07-.886.19-1.247zM22.89 8.736a1.385 1.385 0 011.541.46L36.062 24.15a1.385 1.385 0 01-1.093 2.234H23.34c-.765 0-1.385-.62-1.385-1.384V10.046c0-.592.376-1.118.935-1.31zm1.833 5.345v9.534h7.415l-7.415-9.534zM18.868 15.406c.71.284 1.056 1.09.772 1.8l-3.324 8.308a1.385 1.385 0 11-2.57-1.029l3.322-8.307a1.385 1.385 0 011.8-.772z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisSubjectsBoat;
