import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehiclePassenger: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M21.08 14.1c1.95 0 3.53-1.59 3.53-3.55 0-1.96-1.58-3.55-3.53-3.55-1.95 0-3.53 1.59-3.53 3.55 0 1.96 1.58 3.55 3.53 3.55zM43.78 42.74l-4.07-9a4.72 4.72 0 00-3.32-2.68L26.7 29l-.57-8.1a5.06 5.06 0 00-4.52-4.77 4.89 4.89 0 00-5.23 4.9v10.52a4.72 4.72 0 004.7 4.74H34.5a1.18 1.18 0 011 .61l4.39 8a2.19 2.19 0 003.26.68 2.34 2.34 0 00.63-2.84z"
    ></path>
    <path
      fill="currentColor"
      d="M29 40.89A2.102 2.102 0 0126.89 43H11.06A1.07 1.07 0 0110 41.94V17.11A2.1 2.1 0 0112.11 15a2.101 2.101 0 012.11 2.11v21.15a.52.52 0 00.52.52h12.15A2.101 2.101 0 0129 40.89z"
    ></path>
  </Icon>
);

export default RiskObjectsVehiclePassenger;
