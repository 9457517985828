import { Stack, StackDivider } from '@wegroup/design-system';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useFeatureFlags from '../../../../../../app/src/hooks/useFeatureFlags';
import { useSupportedInsuranceCompanies } from '../../api/getSupportedInsuranceCompanies';
import { useSupportedInsuranceSubjects } from '../../api/getSupportedInsuranceSubjects';
import { LouiseQuestionFormValues } from '../../types/Form';
import SelectWithLabel from './SelectWithLabel';

interface Props {
  isDisabled?: boolean;
  isAvailable?: boolean;
}

// TEMPORARY DISABLE COMPANIES WITH FEATUREFLAG
const onlySGVCompanies = ['sgv'];

// TEMPORARY DISABLE COMPANIES WITH FEATUREFLAG
const onlyKBCCompanies = ['kbc'];

// TEMPORARY DISABLE COMPANIES WITH FEATUREFLAG (Only WeGroup)
const exclusiveCompanies = ['wegroup'];

const QuestionBodyHeader: React.FC<Props> = ({ isDisabled }) => {
  const { t } = useTranslation();
  const { register, watch, setValue } =
    useFormContext<LouiseQuestionFormValues>();
  const {
    isExclusiveCompaniesEnabled,
    isKBCCompaniesEnabeled,
    isSGVCompaniesEnabeled,
  } = useFeatureFlags();

  const insuranceCompany = watch('insuranceCompany');

  const { data: insuranceCompanies, isLoading: isLoadingInsuranceCompanies } =
    useSupportedInsuranceCompanies();
  const { data: subjects, isLoading: isLoadingSubjects } =
    useSupportedInsuranceSubjects(insuranceCompany);

  const filteredCompanies = insuranceCompanies?.items.filter(({ value }) => {
    if (
      !isExclusiveCompaniesEnabled &&
      exclusiveCompanies.includes(value.toLowerCase())
    )
      return false;
    if (
      !isKBCCompaniesEnabeled &&
      onlyKBCCompanies.includes(value.toLowerCase())
    )
      return false;
    if (
      !isSGVCompaniesEnabeled &&
      onlySGVCompanies.includes(value.toLowerCase())
    )
      return false;
    return true;
  });

  return (
    <Stack
      direction={{ base: 'column', tablet: 'row' }}
      divider={
        <StackDivider
          w={{ base: undefined, tablet: '1px' }}
          h={{ base: '1px', tablet: undefined }}
          bg="gray.50"
        />
      }
      spacing="0"
    >
      <SelectWithLabel
        {...register('insuranceCompany')}
        onChange={(e) => {
          register('insuranceCompany').onChange(e);
          setValue('subject', '');
        }}
        label={t('TOOLKIT_INSURANCE-TERMS-BOT_SELECT_INSURANCE-COMPANY_LABEL')}
        options={filteredCompanies}
        isLoading={isLoadingInsuranceCompanies}
        isDisabled={isDisabled}
        pl="3"
      />
      <SelectWithLabel
        {...register('subject')}
        label={t('TOOLKIT_INSURANCE-TERMS-BOT_SELECT_SUBJECT_LABEL')}
        options={subjects?.items}
        isLoading={isLoadingSubjects}
        isAvailable={!!insuranceCompany}
        isDisabled={isDisabled || !watch('insuranceCompany')}
        pl="3"
      />
    </Stack>
  );
};

export default QuestionBodyHeader;
