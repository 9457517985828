import { parse } from 'query-string';

export const isReturningFromSSO = () => {
  const params = parse(window.location.search);
  if (params.code && params.scope && params.state) return true;
  return false;
};

export const isReturningFromSSOError = () => {
  const params = parse(window.location.search);
  if (params.error && params.error_description && params.state) return true;
  return false;
};

export const isAuthenticatingViaLoginToken = () => {
  const params = parse(window.location.search);
  if (params.login_token) return true;
  return false;
};
