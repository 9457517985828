import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSmokeDetectors: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M124.811 81.757a4 4 0 0 1 4-4h99.378a4 4 0 0 1 4 4v27.54c0 5.523-4.477 10-10 10h-87.378c-5.523 0-10-4.477-10-10v-27.54Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M139.311 109.108V89.514M154.987 109.108V89.514M170.662 109.108V89.514M186.338 109.108V89.514M202.013 109.108V89.514M218.081 109.108V89.514"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M106 41a4 4 0 0 1 4-4h137a4 4 0 0 1 4 4v32.135c0 13.807-11.193 25-25 25h-95c-13.807 0-25-11.193-25-25V41Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M106 77.757h145c0 11.254-9.124 20.378-20.378 20.378H126.378c-11.254 0-20.378-9.124-20.378-20.378Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M160.48 119.229a8.065 8.065 0 0 1-16.13 0h16.13Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M152.409 156.697a3.33 3.33 0 0 1-2.351-.974 3.33 3.33 0 0 1-.973-2.352v-14.558a3.326 3.326 0 1 1 6.65 0v14.558a3.326 3.326 0 0 1-3.326 3.326ZM176.674 146.991a3.33 3.33 0 0 1-2.352-.973l-9.707-9.707a3.325 3.325 0 0 1 4.703-4.7l9.707 9.705a3.32 3.32 0 0 1 .722 3.623 3.322 3.322 0 0 1-3.073 2.052ZM128.146 146.991a3.329 3.329 0 0 1-3.073-2.052 3.326 3.326 0 0 1 .722-3.623l9.705-9.705a3.314 3.314 0 0 1 2.35-.974 3.32 3.32 0 0 1 3.325 3.323 3.31 3.31 0 0 1-.973 2.351l-9.704 9.707a3.33 3.33 0 0 1-2.352.973Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M249.538 151.299c-2.715.005-5.41.465-7.975 1.361a22.58 22.58 0 0 0-10.404-10.39 22.512 22.512 0 0 0-14.58-1.768 22.546 22.546 0 0 0-12.576 7.602 22.641 22.641 0 0 0-5.242 13.751 15.036 15.036 0 0 0-14.096 1.439A15.1 15.1 0 0 0 178 175.826a15.1 15.1 0 0 0 6.665 12.532 15.024 15.024 0 0 0 14.096 1.439 16.978 16.978 0 0 0 4.63 10.92 16.888 16.888 0 0 0 22.076 2.145 16.96 16.96 0 0 0 6.638-9.825 24.454 24.454 0 0 0 10.174 6.208 24.412 24.412 0 0 0 11.893.657 24.447 24.447 0 0 0 10.794-5.049 24.524 24.524 0 0 0 7.136-9.559 24.579 24.579 0 0 0-2.198-23.054 24.493 24.493 0 0 0-8.813-8.032 24.422 24.422 0 0 0-11.553-2.909ZM141.078 196.775a15.847 15.847 0 0 0 .148-10.254c-1.088-3.335-3.252-6.188-6.133-8.085a14.67 14.67 0 0 0-9.706-2.346c-3.401.379-6.574 1.942-8.993 4.43-2.419 2.488-3.938 5.751-4.307 9.249a15.784 15.784 0 0 0 2.281 9.984c1.844 2.963 4.618 5.189 7.861 6.308a14.603 14.603 0 0 0 9.969-.153 7.783 7.783 0 0 0 1.826 3.623 7.464 7.464 0 0 0 3.37 2.153 7.282 7.282 0 0 0 3.966.079 7.434 7.434 0 0 0 3.448-2.017 7.754 7.754 0 0 0 1.961-3.547 7.903 7.903 0 0 0-.076-4.079 7.734 7.734 0 0 0-2.094-3.466 7.413 7.413 0 0 0-3.521-1.879Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
      </g>
    </svg>
  );
};

export default IllustrationSmokeDetectors;
