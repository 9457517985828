import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationServiceFlat: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3944_10746"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3944_10746)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M76.15 81.928h66.762v145.446H76.15V81.928z"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M90.453 96.234h19.074v104.912H90.453V96.234z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M142.912 105.773h-72.17A1.74 1.74 0 0169 104.032v-6.056a1.742 1.742 0 011.741-1.742h72.171v9.539zM142.912 129.616h-72.17A1.744 1.744 0 0169 127.874v-6.053a1.743 1.743 0 011.741-1.741h72.171v9.536zM142.912 153.461h-72.17A1.744 1.744 0 0169 151.72v-6.057a1.74 1.74 0 011.741-1.741h72.171v9.539zM142.912 177.303h-72.17A1.74 1.74 0 0169 175.562v-6.053a1.74 1.74 0 011.741-1.741h72.171v9.535zM142.912 201.148h-72.17A1.74 1.74 0 0169 199.407v-6.056a1.744 1.744 0 011.741-1.742h72.171v9.539z"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M85.686 210.686h57.227v16.692H85.685v-16.692zM130.643 60.47h-6.06a1.74 1.74 0 00-1.741 1.742v19.715h9.542V62.212a1.74 1.74 0 00-1.741-1.741z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M142.656 60.47h81.069v166.907h-81.069V60.471z"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M157.218 79.545h14.307v11.921h-14.307V79.545zM202.521 79.545h11.921v11.921h-11.921V79.545zM157.218 103.387h14.307v11.921h-14.307v-11.921zM202.521 103.387h11.921v11.921h-11.921v-11.921zM157.218 127.232h14.307v11.922h-14.307v-11.922zM202.521 127.232h11.921v11.922h-11.921v-11.922zM157.218 151.074h14.307v11.921h-14.307v-11.921zM202.521 151.074h11.921v11.921h-11.921v-11.921zM157.218 174.922h14.307v11.921h-14.307v-11.921zM202.521 174.922h11.921v11.921h-11.921v-11.921z"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M187.931 187.187V79.225"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M165.011 39h-6.052a1.744 1.744 0 00-1.742 1.741v19.72h9.536V40.74A1.744 1.744 0 00165.011 39zM215.085 46.164h-6.057a1.743 1.743 0 00-1.741 1.741v12.566h9.539V47.905a1.74 1.74 0 00-1.741-1.74zM198.22 206.676a2.456 2.456 0 00-2.45-2.453h-24.54a2.453 2.453 0 00-2.454 2.453v20.814h29.444v-20.814z"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M183.497 204.223v23.265"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M242 169.444c-34.578-24.592-45-35.466-45-54.153 0-20.974 26.695-37.182 45-9.915 18.305-27.267 45-11.06 45 9.915 0 18.687-10.422 29.561-45 54.153z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M197 115.291h-2 2zm45 54.153h2c0-.648-.313-1.255-.841-1.63l-1.159 1.63zm0-64.068h2c0-.397-.118-.785-.339-1.115L242 105.376zm0 0h-2a2 2 0 003.661 1.115L242 105.376zm45 9.915h2-2zm-45 54.153h-2a2 2 0 003.159 1.63l-1.159-1.63zm-47-54.153c0 9.839 2.788 17.684 10.011 26.159 7.111 8.343 18.559 17.341 35.83 29.624l2.318-3.26c-17.307-12.309-28.359-21.045-35.104-28.958-6.632-7.782-9.055-14.717-9.055-23.565h-4zm48.661-11.03c-4.752-7.077-10.151-11.484-15.686-13.651-5.547-2.171-11.103-2.04-16.024-.246C202.178 93.924 195 103.986 195 115.29h4c0-9.669 6.169-18.199 14.32-21.169 4.041-1.472 8.591-1.59 13.197.213 4.619 1.808 9.421 5.6 13.822 12.156l3.322-2.23zm.339 1.115h-4 4zm-.339 1.115c4.401-6.556 9.203-10.348 13.822-12.156 4.606-1.803 9.156-1.685 13.197-.213 8.151 2.97 14.32 11.5 14.32 21.169h4c0-11.305-7.178-21.366-16.951-24.927-4.921-1.793-10.477-1.925-16.024.246-5.535 2.167-10.934 6.574-15.686 13.651l3.322 2.23zm41.339 8.8c0 8.848-2.423 15.783-9.055 23.565-6.745 7.913-17.797 16.649-35.104 28.958l2.318 3.26c17.271-12.283 28.719-21.281 35.83-29.624 7.223-8.474 10.011-16.32 10.011-26.159h-4zm-41 54.153h-4 4z"
        ></path>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          d="M241.242 146.803h-4.958v-9.534h-9.534v-11.44h9.534v-9.534h11.441v9.534h9.533v11.44h-9.533v9.534H241.242z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M236.284 146.803h-2a2 2 0 002 2v-2zm0-9.534h2a2 2 0 00-2-2v2zm-9.534 0h-2a2 2 0 002 2v-2zm0-11.44v-2a2 2 0 00-2 2h2zm9.534 0v2a2 2 0 002-2h-2zm0-9.534v-2a2 2 0 00-2 2h2zm11.441 0h2a2 2 0 00-2-2v2zm0 9.534h-2a2 2 0 002 2v-2zm9.533 0h2a2 2 0 00-2-2v2zm0 11.44v2a2 2 0 002-2h-2zm-9.533 0v-2a2 2 0 00-2 2h2zm0 9.534v2a2 2 0 002-2h-2zm-11.441 2h4.958v-4h-4.958v4zm-2-11.534v9.534h4v-9.534h-4zm-7.534 2h9.534v-4h-9.534v4zm-2-13.44v11.44h4v-11.44h-4zm11.534-2h-9.534v4h9.534v-4zm-2-7.534v9.534h4v-9.534h-4zm6.958-2h-4.958v4h4.958v-4zm0 4h1.525v-4h-1.525v4zm1.525 0h4.958v-4h-4.958v4zm2.958-2v9.534h4v-9.534h-4zm2 11.534h9.533v-4h-9.533v4zm7.533-2v11.44h4v-11.44h-4zm2 9.44h-9.533v4h9.533v-4zm-11.533 2v9.534h4v-9.534h-4zm2 7.534h-4.958v4h4.958v-4zm-4.958 0h-1.525v4h1.525v-4z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationServiceFlat;
