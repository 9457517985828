import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycleRecumbent: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3627_10082"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3627_10082)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <circle
          r="12.065"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 130.615 170.352)"
        ></circle>
        <circle
          r="26.333"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 98.327 196.999)"
        ></circle>
        <circle
          r="32.166"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 250.826 191.166)"
        ></circle>
        <circle
          r="18.884"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 98.224 196.776)"
        ></circle>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M225.953 191.236c0 13.459 11.095 24.424 24.85 24.424s24.851-10.965 24.851-24.424-11.096-24.424-24.851-24.424c-13.755 0-24.85 10.965-24.85 24.424z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M232.976 131.015l-26.874 50.815M227.491 141.167l22.5 49.165M249.991 190.332L144.16 169.5M160.829 113.667h-13.333M147.497 113.667l-48.333 82.499"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBicycleRecumbent;
