import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilityDisability: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M29.9 36.88A9.15 9.15 0 1115.71 26a1.91 1.91 0 00.09-2.81 1.91 1.91 0 00-2.57-.14 13 13 0 1020.14 15.51 1.911 1.911 0 00-.37-2.18 1.91 1.91 0 00-3.1.5zM22.52 11a3 3 0 100-6 3 3 0 000 6z"
    ></path>
    <path
      fill="currentColor"
      d="M41.81 35.44l-3.45-7.59a4 4 0 00-2.83-2.26l-8.23-1.72-.14-1.87H32a1.5 1.5 0 100-3h-5l-.14-2A4.28 4.28 0 0023 13a4.14 4.14 0 00-4.45 4.13V26a4 4 0 004 4h11.38a1 1 0 01.87.51l3.74 6.72a1.85 1.85 0 002.76.57 2 2 0 00.51-2.36z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilityDisability;
