import React, { ReactElement } from 'react';

import { Skeleton, Flex, SimpleGrid, VStack } from '@wegroup/design-system';
import GeneralPageLayout from '../../../../common/src/components/GeneralPageLayout';
import { useLocation } from 'react-router';
import useHasVisitedModule from '../../../../common/src/hooks/useHasVisitedModule';
import LoadingSpinner from '../../../../common/src/components/LoadingSpinner';

export default function ProflowLoading(): ReactElement | null {
  const location = useLocation<{ skipIntro: boolean }>();
  const hasVisited = useHasVisitedModule('proflow');
  if (!hasVisited && !location?.state?.skipIntro) return <LoadingSpinner />;
  return (
    <GeneralPageLayout>
      <Skeleton w="400px" h="45px" />
      <VStack align="stretch" ml="230">
        <Flex justifyContent="space-between" mt="12">
          <Skeleton w="215px" h="25px" />
          <Skeleton w="100px" h="25px" />
        </Flex>
        <SimpleGrid
          columns={{ base: 3 }}
          spacingX="4"
          spacingY="8"
          mt="4"
          mb="2"
          sx={{
            '@media (min-width: 1700px)': {
              gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
            },
          }}
        >
          {Array.from({ length: 5 }, (i: number) => (
            <ProflowLoadingItem key={i} />
          ))}
        </SimpleGrid>
        <Flex justifyContent="flex-end" mt="16">
          <Skeleton w="140px" h="10" />
        </Flex>
      </VStack>
    </GeneralPageLayout>
  );
}

const ProflowLoadingItem = () => {
  return <Skeleton h="130px" />;
};
