/**
 * This file is used to define functions that
 * persist and load the product factory state
 * to and from indexedDB.
 */

import { omit } from 'lodash';

// Services
import {
  retrieveStorageById,
  patchStorage,
  newSession,
} from '../../../services/storeService';

// Types
import { RootState } from './rootReducer';

export const persistState: (
  stateName: string,
  sessionId: string,
  getState: () => unknown,
) => Promise<unknown> = async <T>(
  stateName: string,
  sessionId: string,
  getState: () => unknown,
) => {
  const DB_NAME = 'product_flow';
  const state = (getState() as RootState)[stateName] as T;

  const storage = await retrieveStorageById(DB_NAME, sessionId);
  // return
  if (!storage) {
    await newSession(DB_NAME, sessionId);
  }
  return patchStorage(
    DB_NAME,
    sessionId,
    omit({ [stateName + 'Flow']: state }, 'appendStatus'),
  );
};

export const loadState: (
  stateName: string,
  sessionId: string,
) => Promise<unknown> = async (stateName: string, sessionId: string) => {
  const DB_NAME = 'product_flow';
  const dataObject = await retrieveStorageById(DB_NAME, sessionId);
  return dataObject?.[stateName + 'Flow'];
};
