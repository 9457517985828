import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationNewAdvisoryReport: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.951 12.268A5.268 5.268 0 0116.22 7H27.27c.7 0 1.37.278 1.863.772l9.144 9.144c.494.494.772 1.164.772 1.862v8.333a11.915 11.915 0 00-3.512-1.014V21.05H29.39a3.512 3.512 0 01-3.512-3.512v-7.025H16.22c-.97 0-1.756.786-1.756 1.756v25.464c0 .97.787 1.756 1.757 1.756h5.871c.154 1.239.496 2.42.997 3.512H16.22a5.268 5.268 0 01-5.268-5.268V12.268zm18.44.728l4.54 4.54h-4.54v-4.54z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M34 48c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm.923-10.923H40v1.846h-5.077V44h-1.846v-5.077H28v-1.846h5.077V32h1.846v5.077z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationNewAdvisoryReport;
