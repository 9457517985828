import { getI18nLanguageCode } from '../../../../../i18n';
import generalStore from '../../../../common/src/store/redux/store';
import { WgOAuth } from '../authentication/classes/WgAuthState';
import { getManifestFromQCache } from '../new-version/api/getManifest';
import API from './classes/API';

export const wgApi = new API({
  baseURL: process.env.REACT_APP_API_HOST!,
  defaultHeadersFn: () => {
    return {
      'Accept-Language': getI18nLanguageCode(),
      'x-logrocket':
        generalStore.getState().generalData.sessionRecordingUrl || '',
      'x-app-version': getManifestFromQCache()?.version || '',
      'x-app-module': window.location.pathname.split('/')?.[1] || '',
    };
  },
  oauthObject: WgOAuth,
});
