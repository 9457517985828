import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationRacingBike: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <circle
          r={37.5}
          transform="matrix(-1 0 0 1 239.5 186.5)"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <circle
          r={29.5}
          transform="matrix(-1 0 0 1 239.5 186.5)"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <circle
          r={37.5}
          transform="matrix(-1 0 0 1 116.5 186.5)"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <circle
          r={29.5}
          transform="matrix(-1 0 0 1 116.5 186.5)"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="m240 188-55.5 7M184.5 195l-43-56.5M116.5 188l32-64.5M208.5 136.5 145 132M184.5 195l36-86M240 188l-36-39.5M148.5 123.5h-26.581c-.52 0-.954.4-.991.918-.44 6.232.382 9.679 6.572 13.582"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
        />
        <rect
          width={36}
          height={8}
          rx={4}
          transform="matrix(-1 0 0 1 237 105)"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationRacingBike;
