import {
  Divider,
  Grid,
  VStack,
  Box,
  Button,
  useDisclosure,
} from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../../../../../app/src/features/authentication/hooks/useAuth';
import { mq } from '../../../../../../../common/src/utils/styledComponentsUtils';
import StrokeBox from '../../../../../../../dashboard/src/components/common/StrokeBox';
import BrokerContactForm from './BrokerContactForm';
import BrokerContactInfo from './BrokerContactInfo';
import BrokerMap from './BrokerMap';
import BrokerMapModal from './BrokerMapModal';

const BrokerCardContainer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { distribution } = useAuth();

  return (
    <>
      <BrokerMapModal isOpen={isOpen} onClose={onClose} />
      <StrokeBox
        as={VStack}
        divider={<Divider borderColor="gray.50" />}
        spacing="6"
        p={{ base: '3', laptop: '6' }}
        align="stretch"
      >
        <BrokerContactForm distributionName={distribution?.name} />
        <Grid
          gap="6"
          templateColumns={{ base: '1fr', tablet: '1fr 1fr', laptop: '1fr' }}
          sx={{
            [mq.custom(1140)]: {
              gridTemplateColumns: '1fr 1fr',
            },
          }}
        >
          <BrokerContactInfo
            distribution={distribution}
            isLoading={!distribution}
          />
          <Box
            w="100%"
            h="100%"
            display={{ base: 'none', tablet: 'block', laptop: 'none' }}
            sx={{
              [mq.custom(1140)]: {
                display: 'block',
              },
            }}
          >
            <BrokerMap />
          </Box>
          <Button
            display={{ base: 'block', tablet: 'none', laptop: 'block' }}
            sx={{
              [mq.custom(1140)]: {
                display: 'none',
              },
            }}
            onClick={onOpen}
            variant="secondary"
          >
            {t('SHOW-ON-MAP')}
          </Button>
        </Grid>
      </StrokeBox>
    </>
  );
};

export default BrokerCardContainer;
