import React, { ReactElement } from 'react';
import { Text, Circle, Card, HStack } from '@wegroup/design-system';

interface Props {
  label: string;
  icon?: ReactElement;
  value?: number | null;
}

const RoomLayoutBox: React.FC<Props> = ({ value, label, icon }) => {
  const isActive = value && value > 0;
  return (
    <Card borderColor={isActive ? 'primary.500' : 'gray.50'} boxShadow="none">
      <HStack spacing="4">
        <Circle
          bg={isActive ? 'primary.50' : 'gray.50'}
          color={isActive ? 'primary.500' : 'gray.300'}
          p="1"
        >
          {icon}
        </Circle>
        <Text flexGrow="1">{label}</Text>
        <Text>{value}</Text>
      </HStack>
    </Card>
  );
};

export default RoomLayoutBox;
