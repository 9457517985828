import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMovingHome: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_2163_10387"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_2163_10387)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M114 205.639a17.485 17.485 0 0017.494 17.494 17.501 17.501 0 0012.37-5.123 17.5 17.5 0 003.792-19.065 17.5 17.5 0 00-9.467-9.468 17.501 17.501 0 00-19.065 3.792 17.485 17.485 0 00-5.124 12.37z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M131.205 211.077c.35.017.7.002 1.047-.046l22.906-3.219-.108 2.059a9.084 9.084 0 008.575 9.55l71.668 3.806a5.446 5.446 0 00.576-10.874l-66.228-3.524a3.63 3.63 0 01-3.433-3.816l5.817-109.522a41.834 41.834 0 00-8.123-27.034l-9.967-13.474a5.454 5.454 0 00-3.578-2.194 5.444 5.444 0 00-5.177 8.668l9.968 13.474a30.919 30.919 0 016.003 19.983l-3.642 68.582-30.304 38.793a5.444 5.444 0 004 8.788v0zm12.875-12.702l12.422-15.903-.755 14.265-11.667 1.638z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M166.016 208.638l2.905-54.706 58.614 3.113-2.905 54.706-58.614-3.113z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M191.067 179.622a2.892 2.892 0 00.743 2.103 2.903 2.903 0 002.013.961l5.902.314a2.914 2.914 0 003.064-2.756l1.299-24.441-11.723-.624-1.298 24.443z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M168.922 153.934l2.905-54.706 58.614 3.113-2.905 54.705-58.614-3.112z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M193.977 124.918a2.914 2.914 0 002.756 3.064l5.903.314a2.925 2.925 0 002.106-.748 2.912 2.912 0 00.957-2.021l1.299-24.441-11.722-.624-1.299 24.456z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationMovingHome;
