import React from 'react';
import { useHistory } from 'react-router';
import LouiseGPTAcknowledgementModal from './LouiseGPTAcknowledgementModal';

const LouiseGPTAcknowledgementPage: React.FC = () => {
  const history = useHistory();

  const handleClose = () => {
    history.push('/toolkit');
  };

  return <LouiseGPTAcknowledgementModal isOpen={true} onClose={handleClose} />;
};

export default LouiseGPTAcknowledgementPage;
