import { object, string, InferType } from 'yup';

export const QUESTION_MAX_LENGTH = 255;

export const louiseQuestionSchema = object({
  question: string().max(QUESTION_MAX_LENGTH, 'too long').required('Required'),
  insuranceCompany: string().required('Required'),
  subject: string().required('Required'),
});

export type LouiseQuestionFormValues = InferType<typeof louiseQuestionSchema>;
