import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationAthletic: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3824_10677"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3824_10677)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M110.784 132.389H123.86600000000001V216.907H110.784z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M231.137 132.389H244.219V216.907H231.137z"
        ></path>
        <rect
          width="167.6"
          height="37.153"
          x="93.698"
          y="99"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <mask
          id="mask1_3824_10677"
          style={{ maskType: 'alpha' }}
          width="173"
          height="42"
          x="91"
          y="97"
          maskUnits="userSpaceOnUse"
        >
          <rect
            width="167.6"
            height="37.153"
            x="93.695"
            y="99"
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
            rx="2"
          ></rect>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_3824_10677)"
        >
          <path
            fill={colors.primary[200]}
            d="M78.166 99H108.33099999999999V136.153H78.166z"
          ></path>
          <path
            fill={colors.primary[200]}
            d="M134.072 99H164.237V136.153H134.072z"
          ></path>
          <path
            fill={colors.primary[200]}
            d="M189.978 99H220.143V136.153H189.978z"
          ></path>
          <path
            fill={colors.primary[200]}
            d="M245.884 99H276.049V136.153H245.884z"
          ></path>
          <rect width="167.6" height="37.153" x="93.695" y="99" rx="2"></rect>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M100.69 215.141a2 2 0 012-2h29.271a2 2 0 012 2v11.858H100.69v-11.858zM221.043 215.141a2 2 0 012-2h29.271a2 2 0 012 2v11.858h-33.271v-11.858z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationAthletic;
