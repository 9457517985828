import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationEcoVouchers: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_685_2426"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_685_2426)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M102 199.145l34.211-172.146 136.702 27.168-34.21 172.145L102 199.145z"
        ></path>
        <rect
          width="163"
          height="85"
          x="117.683"
          y="110.987"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="3"
          transform="rotate(-10 117.683 110.987)"
        ></rect>
        <g
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          clipPath="url(#clip0_685_2426)"
        >
          <path d="M265.593 101.361c-1.998-1.607-4.33-2.52-6.706-2.627-2.375-.106-4.688.6-6.648 2.03-1.96 1.43-3.48 3.52-4.369 6.009-.889 2.488-1.108 5.264-.629 7.978.478 2.715 1.633 5.249 3.32 7.283 1.687 2.034 3.83 3.478 6.16 4.152 2.331.673 4.746.546 6.942-.367 2.196-.912 4.075-2.569 5.402-4.762M243.994 119.386l15.757-2.779m-1.389-7.878l-15.757 2.778 15.757-2.778z"></path>
        </g>
        <rect
          width="60"
          height="20"
          x="129.238"
          y="119.312"
          fill={colors.primary[200]}
          rx="2"
          transform="rotate(-10 129.238 119.312)"
        ></rect>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M137.806 156.387L217.576 142.321"
        ></path>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M140.585 172.144L220.354 158.079"
        ></path>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M139.196 164.265L218.965 150.2"
        ></path>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M141.974 180.022L221.743 165.957"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M58 100.419a51.42 51.42 0 10102.84.001A51.42 51.42 0 0058 100.42z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M84.786 73.685s-6.89 30.823 8.274 47.25c8.328 9.023 23.111 9.368 28.982-6.878 16.672-4.533 17.778-23.102 7.15-29.255-11.882-6.868-24.879 5.688-44.406-11.117z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M139.971 141.785c-3.626-10.954-12.994-32.17-33.388-40.97"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_685_2426">
          <path
            fill="#fff"
            d="M0 0H28V32H0z"
            transform="rotate(-10 690.99 -1313.312)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationEcoVouchers;
