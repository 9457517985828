import React from 'react';
import { Box, Tooltip, Icon, Text } from '@wegroup/design-system';

interface Props {
  label: string;
  tooltip?: string;
  className?: string;
}

const LabeledParam: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  tooltip,
  children,
  className,
}) => {
  return (
    <Box width="100%" className={className}>
      <Text
        textStyle="smallBody"
        color="gray.300"
        marginBottom="1"
        sx={{
          '@media print': {
            color: 'gray.700',
          },
        }}
      >
        {label}
        {tooltip && (
          <Tooltip shouldWrapChildren label={tooltip}>
            <Icon name="GeneralStatusInfo" />
          </Tooltip>
        )}
      </Text>
      {typeof children === 'object' ? (
        children
      ) : (
        <Text
          textStyle="normalBody"
          color="gray.700"
          sx={{
            '@media print': {
              color: 'black',
            },
          }}
        >
          {children}
        </Text>
      )}
    </Box>
  );
};

export default LabeledParam;
