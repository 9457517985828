import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/rootReducer';

const preloadedState = {};

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  devTools: {
    name: 'WeGroup | Broker-data',
  },
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers/rootReducer', () => {
    const newRootReducer = require('./reducers/rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store.getState;

export default store;
