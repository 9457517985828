import {
  BoxProps,
  ComponentWithAs,
  VStack,
  Divider,
  TextProps,
  Box,
} from '@wegroup/design-system';
import React from 'react';
import CardHeader from '../common/CardHeader';
import StrokeBox from '../common/StrokeBox';

interface Props {
  textStyle?: TextProps['textStyle'];
  title?: string;
  rightElement?: JSX.Element;
}

const InsideCard: ComponentWithAs<'div', Props & BoxProps> = ({
  title,
  rightElement,
  children,
  textStyle,
  ...props
}) => {
  return (
    <StrokeBox
      as={VStack}
      align="stretch"
      spacing="4"
      {...props}
      divider={<Divider borderColor="gray.50" />}
    >
      {(title != null || rightElement != null) && (
        <CardHeader
          textStyle={textStyle}
          title={title}
          rightElement={rightElement}
        />
      )}
      <Box>{children}</Box>
    </StrokeBox>
  );
};

export default InsideCard;
