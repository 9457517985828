import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsImport: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.95 13.173a4.894 4.894 0 014.894-4.894h10.265c.649 0 1.271.258 1.73.717l8.495 8.494c.458.46.716 1.082.716 1.73v17.607a4.894 4.894 0 01-4.894 4.894H16.844a4.894 4.894 0 01-4.894-4.894v-2.95h3.262v2.95c0 .9.73 1.631 1.632 1.631h16.312c.901 0 1.632-.73 1.632-1.631V21.33h-5.71a3.263 3.263 0 01-3.262-3.263v-6.525h-8.972c-.901 0-1.632.73-1.632 1.631V26.44H11.95V13.173zm17.128.676l4.218 4.218h-4.218v-4.218z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.548 24.48a1.631 1.631 0 012.307 0l4.656 4.657.011.011c.637.637.637 1.67 0 2.307l-4.667 4.668a1.631 1.631 0 01-2.307-2.307l1.882-1.883H6.466a1.631 1.631 0 110-3.263H19.43l-1.882-1.882a1.631 1.631 0 010-2.307z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralActionsImport;
