import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersEnvelopeMail: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path fill="currentColor" d="M25 28L8.18 13h33.64L25 28z"></path>
    <path
      fill="currentColor"
      d="M42 16.96V37H8V16.96l17 15.18 17-15.18z"
    ></path>
  </Icon>
);

export default GeneralOthersEnvelopeMail;
