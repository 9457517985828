import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralCompanyLogoFacebook: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M30.967 13.476h3.384V7.752a47 47 0 00-4.932-.252c-4.896 0-8.244 2.988-8.244 8.46v4.716h-5.526v6.408h5.526V43.5h6.624V27.084h5.508l.828-6.408h-6.336V16.59c0-1.89.504-3.114 3.168-3.114z"
    ></path>
  </Icon>
);

export default GeneralCompanyLogoFacebook;
