import React, { PropsWithChildren } from 'react';
import { Card } from '@wegroup/design-system';

const LouiseChatIntroCard: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Card textAlign="center" boxShadow="none">
      {children}
    </Card>
  );
};

export default LouiseChatIntroCard;
