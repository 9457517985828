import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCaravan: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3799_10381"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3799_10381)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M88.522 146.946c2.11-16.015 13.436-34.546 28.562-49.136C132.226 83.203 150.703 73 166.726 73h86.286c21.539 0 39 17.46 39 39v51c0 21.539-17.461 39-39 39H123.935c-20.438 0-37.665-15.787-36.953-35.889.258-7.262.806-13.595 1.54-19.165z"
        ></path>
        <mask
          id="mask1_3799_10381"
          style={{ maskType: 'alpha' }}
          width="211"
          height="133"
          x="84"
          y="71"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M88.522 146.946c2.11-16.015 13.436-34.546 28.562-49.136C132.226 83.203 150.703 73 166.726 73h86.286c21.539 0 39 17.46 39 39v51c0 21.539-17.461 39-39 39H123.935c-20.438 0-37.665-15.787-36.953-35.889.258-7.262.806-13.595 1.54-19.165z"
          ></path>
        </mask>
        <g mask="url(#mask1_3799_10381)">
          <path fill={colors.primary[100]} d="M71 161H316V176H71z"></path>
          <path
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M88.522 146.946c2.11-16.015 13.436-34.546 28.562-49.136C132.226 83.203 150.703 73 166.726 73h86.286c21.539 0 39 17.46 39 39v51c0 21.539-17.461 39-39 39H123.935c-20.438 0-37.665-15.787-36.953-35.889.258-7.262.806-13.595 1.54-19.165z"
          ></path>
        </g>
        <circle
          cx="214"
          cy="201"
          r="23"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="214"
          cy="201"
          r="11"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <mask id="path-10-inside-1_3799_10381" fill="#fff">
          <rect width="47" height="94" x="130" y="110" rx="2"></rect>
        </mask>
        <rect
          width="47"
          height="94"
          x="130"
          y="110"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-10-inside-1_3799_10381)"
          rx="2"
        ></rect>
        <mask id="path-11-inside-2_3799_10381" fill="#fff">
          <rect width="45" height="45" x="201" y="112" rx="2"></rect>
        </mask>
        <rect
          width="45"
          height="45"
          x="201"
          y="112"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-11-inside-2_3799_10381)"
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M62 184v17.5h70.5"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationCaravan;
