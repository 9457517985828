import React from 'react';

import { VStack, FormLabel, Box, Button, Icon } from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import { CustomerSegmentGoal } from '../../../../dashboard/src/types/goals';
import orange from '../../../../design-system/src/theme/foundations/colors/orange';

interface Props {
  title: string;
  goal: CustomerSegmentGoal;
  illustration: JSX.Element;
  onClick: () => void;
}

const GoalItem: React.FC<React.PropsWithChildren<Props>> = ({
  goal,
  title,
  illustration,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <FormLabel htmlFor={goal} w="100%" my="0">
      <VStack
        p="4"
        border="1px solid"
        borderColor="gray.50"
        boxShadow="level1"
        spacing={{ base: '3', tablet: '6' }}
        borderRadius="md"
        bg="white"
        data-test-id="GoalsSelection_GoalBox_SingleGoal"
      >
        <Box
          flexShrink={0}
          display={{ base: 'none', mobileS: 'block' }}
          color="text.gray"
        >
          {illustration}
        </Box>

        <Button
          onClick={onClick}
          width="full"
          textStyle="normalBody"
          mb="1"
          _hover={{
            bg:
              goal === CustomerSegmentGoal.PERSONAL_LINES
                ? orange.secondary[100]
                : orange.primary[100],
          }}
          color={
            goal === CustomerSegmentGoal.PERSONAL_LINES
              ? orange.secondary[500]
              : orange.primary[500]
          }
          bg={
            goal === CustomerSegmentGoal.PERSONAL_LINES
              ? orange.secondary[50]
              : orange.primary[50]
          }
          rightIcon={<Icon name="GeneralActionsArrowRight" />}
        >
          {t(title)}
        </Button>
      </VStack>
    </FormLabel>
  );
};

export default GoalItem;
