import { useBoolean } from '@chakra-ui/hooks';
import { useState } from 'react';
import useAuth from '../../../app/src/features/authentication/hooks/useAuth';
import { CreateSignedDistUploadId } from '../legacy/services/apiRouterService';

export interface UploadResponse {
  document_id: string;
  location: string;
  filename: string;
  size: number;
  filetype: string;
  public_bucket_link: string;
  public_url: string;
  type?: string;
  tags?: string[];
}

interface UseUpload {
  file?: File;
  uploadFile: (file: File) => Promise<UploadResponse>;
  clearFile: () => void;
  isLoading: boolean;
}

const useUpload = (): UseUpload => {
  const [isLoading, setIsLoading] = useBoolean();
  const [file, setFile] = useState<File>();
  const { distribution } = useAuth();

  const uploadFile = async (file: File) => {
    setIsLoading.on();
    setFile(file);
    const [resp, status] = await CreateSignedDistUploadId(distribution.id, {
      filename: file.name,
    });

    if (status !== 200) {
      try {
        await fetch(resp.link, {
          method: 'PUT',
          headers: { 'Content-Type': file.type || 'application/pdf' },
          body: new Blob([file], { type: file.type }),
        });

        setIsLoading.off();
        return {
          document_id: resp.document_id,
          location: resp.location,
          filename: file.name,
          size: file.size,
          filetype: file.type,
          public_bucket_link: resp.public_bucket_link,
          public_url: resp.public_url,
        };
      } catch (err) {
        setIsLoading.off();
        return Promise.reject();
      }
    } else {
      setIsLoading.off();
      return Promise.reject();
    }
  };

  const clearFile = () => setFile(undefined);

  return { uploadFile, clearFile, file, isLoading };
};

export default useUpload;
