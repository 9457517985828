import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersComment: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.875 11.613a4.275 4.275 0 0 0-4.275 4.274v12.15a4.275 4.275 0 0 0 4.275 4.276h4.05a1.8 1.8 0 0 1 1.273.527L25 37.642l4.802-4.802a1.8 1.8 0 0 1 1.273-.528h4.05a4.275 4.275 0 0 0 4.275-4.274v-12.15a4.275 4.275 0 0 0-4.275-4.275h-20.25Zm-5.568-1.294a7.875 7.875 0 0 1 5.568-2.306h20.25A7.875 7.875 0 0 1 43 15.887v12.15a7.875 7.875 0 0 1-7.875 7.876h-3.304l-5.548 5.547a1.8 1.8 0 0 1-2.546 0l-5.548-5.547h-3.304A7.875 7.875 0 0 1 7 28.038v-12.15c0-2.09.83-4.092 2.307-5.569Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1 17.913a1.8 1.8 0 0 1 1.8-1.8h16.2a1.8 1.8 0 0 1 0 3.6H16.9a1.8 1.8 0 0 1-1.8-1.8ZM15.1 26.013a1.8 1.8 0 0 1 1.8-1.8h12.15a1.8 1.8 0 0 1 0 3.6H16.9a1.8 1.8 0 0 1-1.8-1.8Z"
      fill="currentColor"
    />
  </Icon>
);

export default GeneralOthersComment;
