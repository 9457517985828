import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleSportscar: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M43 26.665v1.521a.761.761 0 01-.76.708h-4.703c.092-.243.139-.5.137-.76a2.283 2.283 0 00-4.565 0c-.002.26.045.517.137.76h-14.73c.092-.243.139-.5.137-.76a2.283 2.283 0 00-4.565 0c-.002.26.045.517.137.76H9.949a.762.762 0 01-.723-.48L8.2 25.593a3.226 3.226 0 01-.122-1.818l.487-1.385a.875.875 0 01.83-.563c1.095.002 2.186-.131 3.248-.396l8.103-1.986c.487-.125.98-.224 1.476-.296a16.198 16.198 0 018.37 1.08l3.363 1.522c.45.204.92.367 1.4.487l2.32.57c3.044.761 4.565 2.283 5.06 3.165.148.203.24.442.266.693z"
    ></path>
    <path
      fill="currentColor"
      d="M16.37 24.33a3.804 3.804 0 00-3.728 4.564 3.804 3.804 0 007.533-.76 3.804 3.804 0 00-3.805-3.805zm0 6.086a2.282 2.282 0 01-2.145-1.522 2.108 2.108 0 01-.137-.76 2.282 2.282 0 014.565 0c.002.26-.045.517-.137.76a2.283 2.283 0 01-2.145 1.522zM35.392 24.33a3.804 3.804 0 00-3.729 4.564 3.804 3.804 0 007.533-.76 3.804 3.804 0 00-3.804-3.805zm0 6.086a2.282 2.282 0 01-2.146-1.522 2.106 2.106 0 01-.137-.76 2.282 2.282 0 114.565 0c.002.26-.045.517-.137.76a2.282 2.282 0 01-2.145 1.522z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleSportscar;
