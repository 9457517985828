import { Box, Text } from '@wegroup/design-system';
import React from 'react';

interface Props {
  message?: string;
}

const LouiseSingleChatMessage: React.FC<Props> = ({ message = '' }) => {
  return (
    <Box p="3">
      <Text
        sx={{
          '@media print': {
            fontSize: 'xs',
            lineHeight: 1.2,
          },
        }}
      >
        {message}
      </Text>
    </Box>
  );
};

export default LouiseSingleChatMessage;
