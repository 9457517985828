import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceHomeContent: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_1833_6606"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_1833_6606)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <rect
          width="11.917"
          height="49.068"
          x="225.036"
          y="64.638"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="1"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M225.036 75.853H236.953V85.667H225.036z"
        ></path>
        <rect
          width="11.917"
          height="49.068"
          x="249.571"
          y="64.638"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="1"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M249.571 75.853H261.488V85.667H249.571z"
        ></path>
        <rect
          width="11.917"
          height="49.068"
          x="262.038"
          y="66.108"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="1"
          transform="rotate(-14 262.038 66.108)"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M264.751 76.991H276.66799999999995V86.805H264.751z"
          transform="rotate(-14 264.751 76.991)"
        ></path>
        <rect
          width="11.917"
          height="49.068"
          x="237.654"
          y="70.948"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="1"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M237.654 82.164H249.571V91.97800000000001H237.654z"
        ></path>
        <mask id="path-12-inside-1_1833_6606" fill="#fff">
          <rect
            width="75.705"
            height="115.661"
            x="215.924"
            y="112.305"
            rx="2"
          ></rect>
        </mask>
        <rect
          width="75.705"
          height="115.661"
          x="215.924"
          y="112.305"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-12-inside-1_1833_6606)"
          rx="2"
        ></rect>
        <mask id="path-13-inside-2_1833_6606" fill="#fff">
          <rect
            width="51.872"
            height="36.451"
            x="227.84"
            y="127.726"
            rx="2"
          ></rect>
        </mask>
        <rect
          width="51.872"
          height="36.451"
          x="227.84"
          y="127.726"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-13-inside-2_1833_6606)"
          rx="2"
        ></rect>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M243.262 129.951c0 5.807 4.707 10.514 10.514 10.514 5.807 0 10.515-4.707 10.515-10.514h-21.029z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M243.262 129.951v-2h-2v2h2zm21.029 0h2v-2h-2v2zm-10.515 8.514a8.514 8.514 0 01-8.514-8.514h-4c0 6.912 5.603 12.514 12.514 12.514v-4zm8.515-8.514a8.514 8.514 0 01-8.515 8.514v4c6.912 0 12.515-5.602 12.515-12.514h-4zm2-2h-21.029v4h21.029v-4z"
        ></path>
        <mask id="path-16-inside-3_1833_6606" fill="#fff">
          <rect
            width="51.872"
            height="36.451"
            x="227.84"
            y="182.402"
            rx="2"
          ></rect>
        </mask>
        <rect
          width="51.872"
          height="36.451"
          x="227.84"
          y="182.402"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-16-inside-3_1833_6606)"
          rx="2"
        ></rect>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M243.261 184.628c0 5.807 4.708 10.515 10.515 10.515s10.515-4.708 10.515-10.515h-21.03z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M243.261 184.628v-2h-1.999l-.001 2h2zm21.03 0h2v-2h-2v2zm-19.03 0h-4 4zm8.515 8.515a8.515 8.515 0 01-8.515-8.515h-4c0 6.912 5.603 12.515 12.515 12.515v-4zm8.515-8.515a8.515 8.515 0 01-8.515 8.515v4c6.912 0 12.515-5.603 12.515-12.515h-4zm0 0h4-4zm2-2h-21.03v4h21.03v-4z"
        ></path>
        <mask id="path-19-inside-4_1833_6606" fill="#fff">
          <rect
            width="77.443"
            height="118.316"
            x="132.715"
            y="109.65"
            rx="2"
          ></rect>
        </mask>
        <rect
          width="77.443"
          height="118.316"
          x="132.715"
          y="109.65"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-19-inside-4_1833_6606)"
          rx="2"
        ></rect>
        <mask id="path-20-inside-5_1833_6606" fill="#fff">
          <rect
            width="53.063"
            height="37.287"
            x="144.905"
            y="125.426"
            rx="2"
          ></rect>
        </mask>
        <rect
          width="53.063"
          height="37.287"
          x="144.905"
          y="125.426"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-20-inside-5_1833_6606)"
          rx="2"
        ></rect>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M160.681 127.684c0 5.94 4.815 10.755 10.756 10.755 5.94 0 10.755-4.815 10.756-10.755h-21.512z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M160.681 127.684v-2h-2v2h2zm21.512 0h2v-2h-2v2zm-10.756 8.755a8.756 8.756 0 01-8.756-8.756l-4 .001c0 7.044 5.711 12.755 12.756 12.755v-4zm8.756-8.756a8.757 8.757 0 01-8.756 8.756v4c7.044 0 12.755-5.711 12.756-12.755l-4-.001zm2-1.999h-21.512v4h21.512v-4z"
        ></path>
        <mask id="path-23-inside-6_1833_6606" fill="#fff">
          <rect
            width="53.063"
            height="37.287"
            x="144.905"
            y="181.356"
            rx="2"
          ></rect>
        </mask>
        <rect
          width="53.063"
          height="37.287"
          x="144.905"
          y="181.356"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-23-inside-6_1833_6606)"
          rx="2"
        ></rect>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M160.681 183.614c0 5.94 4.815 10.756 10.756 10.756 5.94 0 10.755-4.816 10.756-10.756h-21.512z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M160.681 183.614v-2h-2v2h2zm21.512 0h2v-2h-2v2zm-10.756 8.756a8.756 8.756 0 01-8.756-8.756h-4c0 7.045 5.711 12.756 12.756 12.756v-4zm8.756-8.756a8.757 8.757 0 01-8.756 8.756v4c7.044 0 12.755-5.711 12.756-12.756h-4zm2-2h-21.512v4h21.512v-4z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M156.354 102.187H182.9V109.957H156.354z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M184.842 87.618c0 8.417-2.266 14.568-2.266 14.568h-25.575s-3.237-5.18-3.237-14.568c0-1.942 1.763-1.942 3.237-1.942h24.604c1.474 0 3.237.58 3.237 1.942z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M167.745 48.163l-9.443 35.934a1 1 0 00.967 1.254h21.345a1 1 0 00.963-1.267l-9.971-35.948c-.545-1.967-3.342-1.948-3.861.027z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M153.555 54.685l4.294 29.808a1 1 0 00.99.858h21.056a1 1 0 00.791-1.611l-23.568-30.561c-1.256-1.629-3.857-.53-3.563 1.506z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M185.375 54.685l-4.294 29.808a1 1 0 01-.99.858h-21.056a1 1 0 01-.791-1.611l23.568-30.561c1.256-1.629 3.857-.53 3.563 1.506z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M187.13 71.405l-5.909 13.35a1 1 0 01-.914.596h-19.716c-1.012 0-1.383-1.332-.517-1.856l24.194-14.612c1.673-1.01 3.654.734 2.862 2.522z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M151.8 71.405l5.909 13.35a1 1 0 00.914.596h19.716c1.012 0 1.383-1.332.517-1.856l-24.194-14.612c-1.673-1.01-3.654.734-2.862 2.522z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M88.625 207.734H121v8.585c0 5.523-4.477 10-10 10H98.625c-5.523 0-10-4.477-10-10v-8.585z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M84.429 203.239a4.496 4.496 0 014.496-4.497H120.7a4.496 4.496 0 010 8.993H88.925a4.496 4.496 0 01-4.496-4.496z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M91.023 143.585c2.798.5 8.394 3.237 8.394 10.192M112.906 148.081c-7.494 6.295-12.89 11.99-12.89 23.082"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M101.815 199.04s-2.468-30.122-2.398-49.46c.045-12.534 1.199-32.074 1.199-32.074"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M76.036 148.082c7.34.816 13.887-1.498 14.988-4.496 1.32-3.597-7.794-10.791-13.19-12.29-5.466-1.519-11.893-.049-13.489 5.396-1.793 6.116 3.597 10.491 11.69 11.39zM84.438 121.436c6.414 3.662 13.342 4.135 15.542 1.821 2.64-2.778-2.871-12.998-7.23-16.515-4.414-3.564-10.896-4.765-14.522-.401-4.073 4.903-.862 11.058 6.21 15.095z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M117.181 121.721c-6.414 3.662-13.342 4.136-15.542 1.821-2.64-2.778 2.871-12.998 7.229-16.515 4.415-3.563 10.897-4.765 14.523-.401 4.073 4.903.862 11.058-6.21 15.095zM123.383 136.557c-6.765 2.963-11.277 8.241-10.732 11.388.655 3.775 12.145 5.448 17.567 4.049 5.494-1.419 10.325-5.905 8.985-11.418-1.506-6.194-8.361-7.287-15.82-4.019z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationInsuranceHomeContent;
