import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleSpeedAssistence: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.183 11.206a1.293 1.293 0 011.381-1.202c.481.038.955.102 1.421.19C33.255 11.382 38 16.972 38 23.692c0 .723-.576 1.308-1.286 1.308-.71 0-1.285-.585-1.285-1.308 0-5.438-3.841-9.966-8.914-10.927-.377-.07-.761-.123-1.15-.154a1.3 1.3 0 01-1.182-1.405zm-.669.035c.13.71-.33 1.392-1.029 1.524-1.218.231-2.366.668-3.407 1.274a1.274 1.274 0 01-1.754-.485 1.32 1.32 0 01.477-1.785 13.275 13.275 0 014.214-1.575 1.287 1.287 0 011.499 1.046zm-6.695 2.704c.5.514.495 1.341-.01 1.85-.447.45-.856.94-1.222 1.463a1.272 1.272 0 01-1.793.308 1.322 1.322 0 01-.302-1.824A13.74 13.74 0 0115 13.936a1.27 1.27 0 011.819.01zm-3.26 4.067c.662.26.992 1.016.737 1.69-.11.294-.21.593-.296.898a1.283 1.283 0 01-1.592.893 1.311 1.311 0 01-.879-1.62c.107-.377.23-.747.367-1.11a1.28 1.28 0 011.662-.75zm-1.23 3.996c.71.022 1.268.625 1.247 1.346-.003.113-.005.225-.005.338 0 .723-.575 1.308-1.285 1.308S11 24.415 11 23.692c0-.139.002-.277.006-.415a1.295 1.295 0 011.323-1.269z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.83 17.34l-.011.012-3.67 3.67a2.572 2.572 0 101.663 1.973l3.836-3.837c.413-.413.341-1.155-.16-1.657-.503-.503-1.245-.574-1.658-.16z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M30 40.37h.08l.67-3.71a3.12 3.12 0 011-1.52c.38-.34 1.22-1 1.64-1.27a5.75 5.75 0 013.11-1h.17L40 30.53a1.149 1.149 0 01.89-.19 1.163 1.163 0 01.76.49c.06.095.104.2.13.31.07.232.07.479 0 .71v.07a1.14 1.14 0 01-.41.56l-.37.22-3.71 2.58.38.55 4-2.79c.135-.108.256-.232.36-.37a1.82 1.82 0 00.39-.8 1.56 1.56 0 000-.57l2.15-7.72A1.232 1.232 0 1147 24l-1.82 10a2 2 0 01-.81 1.26l-6.69 4.65-.34 1.85h.07a.69.69 0 01.55.8l-.71 3.91a.691.691 0 01-.81.56L29 45.64a.689.689 0 01-.55-.81l.71-3.91a.69.69 0 01.84-.55zM20 40.37h-.08l-.67-3.71a3.12 3.12 0 00-1-1.52c-.38-.34-1.22-1-1.64-1.27a5.75 5.75 0 00-3.11-1h-.17L10 30.53a1.15 1.15 0 00-.89-.19 1.159 1.159 0 00-.76.49.999.999 0 00-.13.31 1.23 1.23 0 000 .71v.07c.075.225.219.42.41.56l.37.22 3.71 2.58-.38.55L8.26 33a2.104 2.104 0 01-.36-.37 1.82 1.82 0 01-.39-.8 1.56 1.56 0 010-.57l-2.16-7.71A1.196 1.196 0 103 24l1.82 10a2 2 0 00.81 1.26l6.69 4.65.34 1.85h-.07a.69.69 0 00-.55.8l.71 3.91a.69.69 0 00.81.56L21 45.64a.69.69 0 00.55-.81l-.71-3.91a.69.69 0 00-.84-.55z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleSpeedAssistence;
