import { Flex } from '@wegroup/design-system';
import React from 'react';
import { use100vh } from 'react-div-100vh';
import { chakraCustomScrollBar } from '../../../../../common/src/utils/styledComponentsUtils';

interface Props {
  nav?: React.ReactNode;
}

export const AppShellId = 'appContent';
export const AppShellClassName = 'appContent';

const AppShell: React.FC<React.PropsWithChildren<Props>> = ({
  nav,
  children,
}) => {
  // Needed for iOS fix
  const height = use100vh() || '100vh';

  return (
    <Flex h={height} w="100%">
      {nav}
      <Flex
        w="100%"
        flexDir="column"
        id={AppShellId}
        className={AppShellClassName}
        position="relative"
        flexGrow={1}
        overflowY="auto"
        sx={chakraCustomScrollBar}
        bg="gray.ultraLightBg"
      >
        {children}
      </Flex>
    </Flex>
  );
};

export default AppShell;
