import React from 'react';
import { HeaderGroup } from 'react-table';

import { Tfoot, Tr, Td } from '@wegroup/design-system';

interface Props {
  footerGroups: HeaderGroup[];
}

const ReactTableFooter: React.FC<React.PropsWithChildren<Props>> = ({
  footerGroups,
}) => {
  return (
    <Tfoot>
      {footerGroups.map((group, index) => (
        <Tr {...group.getFooterGroupProps()} key={index}>
          {group.headers.map((column, index) => (
            <Td {...column.getFooterProps()} key={index}>
              {column.render('Footer')}
            </Td>
          ))}
        </Tr>
      ))}
    </Tfoot>
  );
};

export default ReactTableFooter;
