import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationWinter: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3824_10593"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3824_10593)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M227.251 179.747l-15.958 61.751s1.954 12.225 11.163 14.106c9.094 1.858 15.549-8.429 15.549-8.429l9.376-63.149 11.438-53.81 20.014-82.737s-3.779-13.271-10.694-14.411c-6.578-1.085-14.469 9.063-14.469 9.063l-14.981 83.807-11.438 53.809z"
        ></path>
        <mask
          id="mask1_3824_10593"
          style={{ maskType: 'alpha' }}
          width="72"
          height="228"
          x="209"
          y="30"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M227.255 179.747l-15.958 61.751s1.953 12.225 11.163 14.106c9.093 1.858 15.548-8.429 15.548-8.429l9.377-63.149 11.438-53.81 20.014-82.737s-3.779-13.271-10.694-14.411c-6.579-1.085-14.469 9.063-14.469 9.063l-14.982 83.807-11.437 53.809z"
          ></path>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_3824_10593)"
        >
          <path
            fill={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M250.274 227.53l-32.918-31.678-15.967 56.083 38.252 14.2 10.633-38.605z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M220.509 181.126H278.24899999999997V213.537H220.509z"
            transform="rotate(-78 220.509 181.126)"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M274.105 100.09l-38.453-39.697 3.877-18.24 38.453 39.698-3.877 18.239z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M227.255 179.747l-15.958 61.751s1.953 12.225 11.163 14.106c9.093 1.858 15.548-8.429 15.548-8.429l9.377-63.149 11.438-53.81 20.014-82.737s-3.779-13.271-10.694-14.411c-6.579-1.085-14.469 9.063-14.469 9.063l-14.982 83.807-11.437 53.809z"
          ></path>
        </g>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M229.874 188.779a1 1 0 01-.726-.619l-3.191-8.301a1 1 0 011.141-1.337l21.702 4.613a1 1 0 01.499 1.685l-6.292 6.286a.997.997 0 01-.914.27l-12.219-2.597zM246.494 113.291c3.09-3.659 9.012-2.401 10.346 2.2l4.487 15.463c.421 1.451-.859 2.827-2.337 2.513l-21.771-4.628c-1.479-.314-2.088-2.091-1.113-3.246l10.388-12.302z"
        ></path>
        <rect
          width="23.704"
          height="4.707"
          x="238.062"
          y="167.053"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2.353"
          transform="rotate(-78 238.062 167.053)"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M183.694 182.349l-4.956 63.586s4.053 11.698 13.45 11.946c9.278.245 13.842-11.008 13.842-11.008l-1.768-63.817 1.889-54.98 5.294-84.958s-6.033-12.41-13.04-12.329c-6.667.078-12.67 11.445-12.67 11.445l-.152 85.136-1.889 54.979z"
        ></path>
        <mask
          id="mask2_3824_10593"
          style={{ maskType: 'alpha' }}
          width="38"
          height="232"
          x="176"
          y="28"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M183.698 182.349l-4.957 63.586s4.054 11.698 13.45 11.946c9.278.245 13.842-11.008 13.842-11.008l-1.767-63.817 1.888-54.98 5.295-84.958s-6.033-12.41-13.041-12.329c-6.667.078-12.669 11.445-12.669 11.445l-.153 85.136-1.888 54.979z"
          ></path>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask2_3824_10593)"
        >
          <path
            fill={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M214.689 225.391l-37.933-25.459-5.953 58.008 40.141 7.319 3.745-39.868z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M177.295 184.88H235.035V217.291H177.295z"
            transform="rotate(-88.033 177.295 184.88)"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M215.955 95.749l-44.781-32.392.64-18.636 44.781 32.392-.64 18.636z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M183.698 182.349l-4.957 63.586s4.054 11.698 13.45 11.946c9.278.245 13.842-11.008 13.842-11.008l-1.767-63.817 1.888-54.98 5.295-84.958s-6.033-12.41-13.041-12.329c-6.667.078-12.669 11.445-12.669 11.445l-.153 85.136-1.888 54.979z"
          ></path>
        </g>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M187.85 190.787a1 1 0 01-.822-.483l-4.589-7.619a1 1 0 01.891-1.515l22.174.762a1 1 0 01.785 1.573l-5.101 7.285a.999.999 0 01-.853.426l-12.485-.429zM191.065 113.559c2.406-4.142 8.456-3.935 10.572.363l7.111 14.445c.668 1.356-.352 2.934-1.863 2.882l-22.245-.764c-1.51-.052-2.419-1.696-1.66-3.003l8.085-13.923z"
        ></path>
        <rect
          width="23.704"
          height="4.707"
          x="192.128"
          y="167.966"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2.353"
          transform="rotate(-88.033 192.128 167.966)"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M158.661 64.644c-2.254-9.667-19.926-20.619-26.851-24.405l-1.192 6.245c2.749 7.32 13.162 20.384 18.475 24.603 6.721 5.337 11.823 3.224 9.568-6.443z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M148.44 250.865l-19.737-165.5 5.788-.664 16.793 165.837-2.844.327z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M125.062 53.716H130.923V77.023H125.062z"
          transform="rotate(-6.546 125.062 53.716)"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M124.642 76.669H136.571V82.53H124.642z"
          transform="rotate(-6.546 124.642 76.67)"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M124.526 53.291s-3.701-3.946-4.256-7.146c-.208-1.202-.048-2.734.179-4.016.278-1.573 1.621-2.68 3.208-2.863l2.654-.304.006-.001 2.654-.305c1.587-.182 3.146.593 3.773 2.062.512 1.198 1.015 2.653 1.084 3.87.184 3.244-2.526 7.926-2.526 7.926l-3.385.388-.007.001-3.384.388z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M120.27 46.145l-1.97.341 1.97-.341zm4.256 7.146l-1.459 1.369.688.733.999-.115-.228-1.987zm-4.077-11.162l1.969.349-1.969-.35zm5.862-3.167l-.228-1.987.228 1.987zm.006-.001l.228 1.987-.228-1.987zm2.654-.305l-.228-1.987.228 1.987zm3.773 2.062l1.84-.786-1.84.786zm1.084 3.87l-1.997.114 1.997-.113zm-2.526 7.926l.228 1.987.999-.115.503-.87-1.73-1.002zm-3.385.388l.228 1.987-.228-1.987zm-.007.001l-.228-1.987.228 1.987zm-9.61-6.417c.36 2.078 1.663 4.178 2.681 5.598a29.288 29.288 0 001.895 2.366l.135.149.038.041.012.013.004.004.001.002h.001l1.459-1.368 1.459-1.367h.001l-.004-.004a.824.824 0 01-.024-.026l-.102-.113a25.593 25.593 0 01-1.625-2.028c-.971-1.354-1.795-2.827-1.99-3.95l-3.941.683zm.179-4.706c-.234 1.327-.45 3.145-.179 4.706l3.941-.683c-.146-.842-.042-2.087.177-3.325l-3.939-.698zm4.95-4.5c-2.338.268-4.496 1.936-4.95 4.5l3.939.697c.103-.581.632-1.128 1.467-1.224l-.456-3.974zm2.654-.305l-2.654.304.456 3.974 2.654-.304-.456-3.974zm.456 3.974l.006-.001-.456-3.974h-.006l.456 3.975zm.006-.001l2.654-.305-.456-3.974-2.654.305.456 3.974zm2.654-.305c.835-.096 1.474.317 1.706.86l3.679-1.57c-1.023-2.395-3.502-3.532-5.841-3.264l.456 3.974zm1.706.86c.494 1.157.878 2.346.926 3.2l3.994-.228c-.09-1.582-.712-3.304-1.241-4.543l-3.679 1.572zm.926 3.2c.065 1.138-.405 2.759-1.044 4.297a25.55 25.55 0 01-1.122 2.344c-.033.06-.058.105-.075.133-.008.014-.013.025-.017.03l-.003.006c.001-.001.001-.001 1.732 1a455.987 455.987 0 001.731 1.003v-.001l.001-.002a.04.04 0 00.003-.005l.008-.015.029-.05.097-.174a29.502 29.502 0 001.31-2.734c.67-1.613 1.463-3.955 1.344-6.06l-3.994.227zm-.757 5.824l-3.385.388.456 3.974 3.385-.388-.456-3.974zm-3.385.388l-.007.001.456 3.974h.007l-.456-3.975zm-2.935 4.363l3.384-.388-.456-3.974-3.384.389.456 3.973z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M110.308 77.185c-3.294-8.654-21-16.565-27.875-19.199l-.32 5.922c3.457 6.422 14.717 17.194 20.157 20.43 6.88 4.091 11.331 1.5 8.038-7.153z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M124.264 250.435l-38.89-150.249 5.003-1.272 36.216 150.929-2.329.592z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M78.049 71.385H83.24700000000001V92.857H78.049z"
          transform="rotate(-14.257 78.049 71.385)"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M80.534 92.655H91.393V97.85300000000001H80.534z"
          transform="rotate(-14.257 80.534 92.655)"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M83.504 69.397s1.92-4.667 1.343-7.642c-.206-1.064-.802-2.277-1.396-3.29-.808-1.379-2.46-1.936-4.008-1.542l-2.25.571h.001l-2.241.57c-1.548.394-2.734 1.672-2.785 3.268-.038 1.175.017 2.525.344 3.558.913 2.889 4.828 6.073 4.828 6.073l3.086-.784 3.078-.782z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M84.847 61.755l-1.964.38 1.964-.38zm-1.343 7.642l.492 1.938.975-.248.382-.93-1.85-.76zm-.053-10.933l-1.726 1.011 1.726-1.01zm-4.008-1.541l.493 1.938-.493-1.938zm-2.25.571l-.492-1.938-1.938.492.492 1.939 1.939-.493zm.001 0l.493 1.94 1.938-.493-.493-1.939-1.938.493zm-5.026 3.838l1.999.065-2-.065zm.344 3.558l1.907-.603-1.907.603zm4.828 6.073l-1.262 1.552.78.634.975-.247-.493-1.939zm3.086-.784l.493 1.939 1.938-.493-.492-1.938-1.939.492zm0 0l-.493-1.939-1.938.493.492 1.938 1.939-.492zm2.457-8.044c.197 1.012-.027 2.544-.425 4.044a23.476 23.476 0 01-.79 2.424l-.013.029a.214.214 0 01-.002.005l1.85.76 1.85.76v-.001l.002-.002.001-.005.007-.015a10.53 10.53 0 00.088-.225 27.508 27.508 0 00.873-2.705c.419-1.578.867-3.867.486-5.83l-3.927.761zm-1.158-2.66c.569.97 1.02 1.943 1.158 2.66l3.927-.76c-.273-1.41-1.014-2.864-1.634-3.922l-3.45 2.022zm-1.789-.614c.82-.208 1.499.12 1.79.614l3.45-2.022c-1.325-2.26-3.95-3.047-6.225-2.469l.985 3.877zm-2.25.572l2.25-.572-.985-3.877-2.25.572.986 3.877zm1.446-2.43v-.001l-3.877.985 3.877-.985zm-2.43-1.447l-2.242.57.986 3.877 2.24-.57-.985-3.877zm-2.242.57c-2.275.578-4.206 2.522-4.291 5.141l3.998.13c.018-.574.458-1.186 1.278-1.394l-.985-3.877zm-4.291 5.141c-.04 1.226.003 2.857.436 4.226l3.814-1.206c-.22-.697-.289-1.767-.252-2.89l-3.998-.13zm.436 4.226c.603 1.906 2.089 3.704 3.21 4.89a27.526 27.526 0 002.202 2.082l.042.034.012.01.004.004h.002v.001l1.263-1.55a623.316 623.316 0 011.263-1.552c0 .001 0 0 0 0s-.002 0-.004-.003l-.024-.02-.108-.09a23.494 23.494 0 01-1.745-1.662c-1.065-1.128-1.992-2.367-2.303-3.35l-3.814 1.206zm7.228 7.409l3.086-.784-.986-3.877-3.085.784.985 3.877zm4.532-3.215v-.001l-3.877.985 3.877-.984zm.646-2.229l-3.078.782.985 3.877 3.078-.782-.985-3.877z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationWinter;
