import { InputProps, Input } from '@wegroup/design-system';
import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

interface Props {
  name?: string;
  value?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  mask?: string;
  country?: string;
}

const CbeInput: React.FC<React.PropsWithChildren<Props & InputProps>> = ({
  name,
  value,
  onChange,
  onBlur,
  mask = '_',
  country = 'BE',
  ...props
}) => {
  return (
    <Input
      name={name}
      as={NumberFormat}
      format={country.toUpperCase() === 'NL' ? '########' : '####.###.###'}
      value={value}
      onValueChange={(values: NumberFormatValues) => {
        const { formattedValue } = values;
        onChange?.(formattedValue.replaceAll('.', ''));
      }}
      onBlur={onBlur}
      mask={mask}
      allowEmptyFormatting
      {...props}
    />
  );
};

export default CbeInput;
