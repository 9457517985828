const parts = [
  'content',
  'header',
  'body',
  'footer',
  'popper',
  'arrow',
  'closeButton',
];

const baseStyle = {
  content: {
    borderColor: 'gray.100',
    boxShadow: 'level5',
    _focus: {
      outline: 'none',
      boxShadow: 'level5',
    },
  },
};

const variants = {
  danger: {
    content: {
      bg: 'danger.light',
      minW: 'auto',
    },
    arrow: {
      bg: 'danger.light',
    },
    header: {
      color: 'danger.main',
    },
  },
};

const Popover = {
  parts,
  baseStyle,
  variants,
};

export default Popover;
