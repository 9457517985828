import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isNaN } from 'lodash';

export interface FlowsMetaDataController {
  isSessionActive: boolean;
  /** Used to track the last position when a flows route did not receive any position trough the url */
  lastPosition: number;
  showBrokerInfoModal: boolean;
  /** Tracks the amount of submits the user has done */
  successfulSubmitCount: number;
}

const initialState: FlowsMetaDataController = {
  isSessionActive: false,
  showBrokerInfoModal: false,
  lastPosition: 0,
  successfulSubmitCount: 0,
};

const flowsMetaDataSlice = createSlice({
  name: 'flowsMetaDataSlice',
  initialState: initialState,
  reducers: {
    setIsSessionActive(state, action: PayloadAction<boolean>) {
      state.isSessionActive = action.payload;
    },
    setLastPosition(state, action: PayloadAction<number>) {
      if (!isNaN(action.payload)) state.lastPosition = action.payload;
    },
    setShowBrokerInfoModal(state, action: PayloadAction<boolean>) {
      state.showBrokerInfoModal = action.payload;
    },
    incSubmitCount(state) {
      state.successfulSubmitCount++;
    },
    setFlowsMetaDataState(_, action: PayloadAction<FlowsMetaDataController>) {
      return action.payload;
    },
    resetFlowsMetaData() {
      return initialState;
    },
  },
});

export const {
  setIsSessionActive,
  setLastPosition,
  setShowBrokerInfoModal,
  incSubmitCount,
  setFlowsMetaDataState,
  resetFlowsMetaData,
} = flowsMetaDataSlice.actions;

export default flowsMetaDataSlice.reducer;
