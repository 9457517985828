import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyWashingLaundry: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M22.8 18.24a10 10 0 100 20 10 10 0 000-20zm0 17.5a7.5 7.5 0 110-15 7.5 7.5 0 010 15z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.5 8.81A4.31 4.31 0 018.81 4.5h32.38a4.31 4.31 0 014.31 4.31v32.38a4.31 4.31 0 01-4.31 4.31H8.81a4.31 4.31 0 01-4.31-4.31V8.81zm3 32.38V14h35v27.19a1.31 1.31 0 01-1.31 1.31H8.81a1.31 1.31 0 01-1.31-1.31zM26.6 9.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm5.5 1.48a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM19.6 9.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M36.157 25h-.314a.835.835 0 00-.843.826v5.348c0 .456.377.826.843.826h.314c.466 0 .843-.37.843-.826v-5.348a.835.835 0 00-.843-.826z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyWashingLaundry;
