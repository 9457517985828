import React, { PropsWithChildren } from 'react';
import { Td, Box, TableCellProps } from '@wegroup/design-system';

interface Props {
  isSticky: boolean;
}

const ReactTableBodyCell: React.FC<
  PropsWithChildren & Props & TableCellProps
> = ({ isSticky, children, ...otherProps }) => {
  return (
    <Td
      zIndex={isSticky ? '1' : 'unset'}
      transition="0.2s"
      position={isSticky ? 'sticky' : 'relative'}
      {...otherProps}
      sx={{
        a: {
          color: 'unset',
        },
        // Important is needed because table styling cant be overwritten otherwise
        '@media print': {
          color: 'gray.700 !important',
        },
      }}
      _last={
        isSticky
          ? {
              right: 0,
              borderLeft: '1px',
              borderColor: 'gray.50',
              boxShadow: '-1px 0 10px -2px rgba(40, 41, 61, 0.08)',
            }
          : {}
      }
      _first={
        isSticky
          ? {
              left: '0',
              borderRight: '1px',
              boxShadow: '1px 0 10px -2px rgba(40, 41, 61, 0.08)',
              borderColor: 'gray.50',
            }
          : {}
      }
    >
      <Box>{children}</Box>
    </Td>
  );
};

export default ReactTableBodyCell;
