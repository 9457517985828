import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskAnalysisSubjectsHobbies: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19 10.5a.5.5 0 00-.5.5 5.5 5.5 0 01-5.5 5.5h-2A2.5 2.5 0 008.5 19v18a2.5 2.5 0 002.5 2.5h28a2.5 2.5 0 002.5-2.5V19a2.5 2.5 0 00-2.5-2.5h-2a5.5 5.5 0 01-5.5-5.5.5.5 0 00-.5-.5H19zm-8 3A5.5 5.5 0 005.5 19v18a5.5 5.5 0 005.5 5.5h28a5.5 5.5 0 005.5-5.5V19a5.5 5.5 0 00-5.5-5.5h-2a2.5 2.5 0 01-2.5-2.5A3.5 3.5 0 0031 7.5H19a3.5 3.5 0 00-3.5 3.5 2.5 2.5 0 01-2.5 2.5h-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 22.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9zM17.5 27a7.5 7.5 0 1115 0 7.5 7.5 0 01-15 0z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisSubjectsHobbies;
