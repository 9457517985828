import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycleChildren: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_893_3669"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_893_3669)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M203.807 152.315l-21.6 44.51-3.599-1.747 21.6-44.509 3.599 1.746z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M159.982 196.876c0 14.078-11.413 25.491-25.491 25.491S109 210.954 109 196.876c0-14.079 11.413-25.491 25.491-25.491s25.491 11.412 25.491 25.491z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M152.782 196.876c0 10.102-8.189 18.291-18.291 18.291s-18.291-8.189-18.291-18.291 8.189-18.291 18.291-18.291 18.291 8.189 18.291 18.291z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M247 196.876c0 14.078-11.412 25.491-25.491 25.491-14.078 0-25.49-11.413-25.49-25.491 0-14.079 11.412-25.491 25.49-25.491 14.079 0 25.491 11.412 25.491 25.491z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M239.8 196.876c0 10.102-8.189 18.291-18.291 18.291s-18.291-8.189-18.291-18.291 8.189-18.291 18.291-18.291 18.291 8.189 18.291 18.291z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M135.473 196.875l16.819-43.475a1 1 0 00-.933-1.361h-10.323"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="2"
          d="M152.695 157.93c-1.611 0-2.561 1.807-1.648 3.134l25.393 36.909c.374.542.99.866 1.648.866h42.927c1.61 0 2.56-1.806 1.647-3.133l-25.393-36.909a1.998 1.998 0 00-1.648-.867h-42.926zm4.979 2.618c-1.611 0-2.561 1.807-1.648 3.134l21.791 31.673c.373.542.99.866 1.648.866h36.57c1.611 0 2.561-1.807 1.648-3.134l-21.791-31.672a1.998 1.998 0 00-1.647-.867h-36.571z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M186.855 196.876a7.855 7.855 0 11-15.71-.002 7.855 7.855 0 0115.71.002z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="2"
          d="M179 202.112a5.237 5.237 0 10-.001-10.473 5.237 5.237 0 00.001 10.473zm0 2.618a7.855 7.855 0 100-15.71 7.855 7.855 0 000 15.71z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M186.854 151.712a1.31 1.31 0 011.31-1.309h18.327a1.309 1.309 0 110 2.618h-18.327a1.31 1.31 0 01-1.31-1.309z"
          clipRule="evenodd"
        ></path>
        <circle
          cx="222"
          cy="214"
          r="12"
          fill={colors.primary[200]}
          stroke="#fff"
          strokeWidth="4"
        ></circle>
        <circle
          cx="222"
          cy="214"
          r="8"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <rect
          width="4"
          height="21"
          x="220"
          y="195"
          fill={colors.primary[500]}
          rx="2"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBicycleChildren;
