import { Link } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onManualModeOff: () => void;
}

const ManualAddressInputFooter: React.FC<Props> = ({ onManualModeOff }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    onManualModeOff();
  };

  return (
    <Link variant="gray" onClick={handleClick}>
      {t('DESIGN-SYSTEM_ADDRESS-INPUT_BUTTON_SEARCH-ADDRESS')}
    </Link>
  );
};

export default ManualAddressInputFooter;
