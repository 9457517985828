import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleLaneCenteringAssist: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M7 6.455H3v10h4v-10zM7 20.455H3v10h4v-10zM7 34.455H3v10h4v-10zM47 6.455h-4v10h4v-10zM47 20.455h-4v10h4v-10zM47 34.455h-4v10h4v-10zM25 19.635c-6.62 0-11.98 5.36-11.98 11.98s5.36 11.98 11.98 11.98 11.98-5.36 11.98-11.98-5.36-11.98-11.98-11.98zm-2.4 20.22c0 .2-.2.35-.39.29a9.03 9.03 0 01-5.24-4.51c-.1-.2.05-.43.27-.43h2.37c1.65 0 2.99 1.34 2.99 2.99v1.66zm2.4-5.25a1.799 1.799 0 110-3.6c1 0 1.8.8 1.8 1.8s-.81 1.8-1.8 1.8zm8.03 1.03a9.048 9.048 0 01-5.86 4.7c-.19.05-.37-.1-.37-.29v-1.84c0-1.65 1.34-2.99 2.99-2.99h2.96c.23-.01.38.22.28.42zm.5-4.47l-4.28-1.61c-2.68-1.18-5.35-1.31-8.52 0l-4.28 1.61c-.2.08-.43-.08-.41-.3.38-4.61 4.24-8.24 8.94-8.24 4.7 0 8.56 3.63 8.94 8.24.04.21-.18.38-.39.3zM37.07 12.095l1.96-2.84c-6.27-4.34-14.3-5.44-21.48-2.93-2.37.82-4.59 2.01-6.59 3.53l2.08 2.75c1.71-1.3 3.61-2.31 5.64-3.02a20.494 20.494 0 0118.39 2.51z"
    ></path>
    <path
      fill="currentColor"
      d="M20.1 15.605c4.81-1.68 10.19-.94 14.39 1.97l1.96-2.84a19.505 19.505 0 00-17.49-2.39 19.44 19.44 0 00-5.37 2.88l2.09 2.74c1.33-1.01 2.82-1.8 4.42-2.36z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleLaneCenteringAssist;
