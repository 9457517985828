import React, { ChangeEvent } from 'react';
import {
  Link,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalProps,
} from '@wegroup/design-system';
import SearchInput from '../SearchPickerInputField';
import SearchSelectDetailedList from './SearchSelectDetailedList';

export interface Option {
  label: string;
  description: string;
  value: string;
  tags?: string[];
}

export interface SearchSelectDetailedProps {
  isOpen: boolean;
  placeholder?: string;
  onClose: () => void;
  options?: Option[];
  searchValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSelect: (value: string) => void;
  onEdit?: (value: string) => void;
  onRemove?: (value: string) => void;
  extraActionLabel?: string;
  extraActionOnClick?: () => void;
  isLoading?: boolean;
}

const SearchSelectDetailed: React.FC<
  React.PropsWithChildren<
    SearchSelectDetailedProps & Omit<ModalProps, 'children'>
  >
> = ({
  placeholder,
  isOpen,
  onClose,
  options = [],
  searchValue,
  onChange,
  onSelect,
  onEdit,
  onRemove,
  extraActionLabel,
  extraActionOnClick,
  isLoading,
  ...props
}) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      scrollBehavior="inside"
      size="2xl"
      {...props}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={{ base: '0', tabletS: 'md' }}
        overflow="hidden"
        h={{ base: '100%', tabletS: 'unset' }}
        m={{ base: '0', tabletS: '10' }}
      >
        <SearchInput
          onClose={onClose}
          value={searchValue}
          onChange={onChange}
          placeholder={placeholder}
        />
        <SearchSelectDetailedList
          isLoading={isLoading}
          onSelect={onSelect}
          options={options}
          searchValue={searchValue}
          onEdit={onEdit}
          onRemove={onRemove}
        />
        {extraActionLabel && (
          <Box p="4" borderTop="1px" borderColor="gray.strokeAndBg">
            <Link
              variant="gray"
              data-test-id="EXTRA-ACTION-LABEL"
              fontSize="sm"
              onClick={extraActionOnClick}
            >
              {extraActionLabel}
            </Link>
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SearchSelectDetailed;
