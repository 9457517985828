import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceLegalAssistance: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M207 201.5h-64s-8.25-1-12.5 7.5c-3.5 7-9 17-9 17H229l-9-17c-2.8-6.4-9.833-7.667-13-7.5Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="m168.5 77-6 124.5h25L181 77l-6-6-6.5 6ZM165 55c.329 3.69 3 9 3 9h13.5s3.044-5.257 3.5-9c1.036-8.495-10-19.5-10-19.5S164.242 46.475 165 55Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M131.5 85.5C145 77 159.5 72 165 72c2.833-2.833 6.7-8.5-.5-8.5-9 0-26 8.5-36.5 14.5s-25 13.5-30.5 13.5-8-3-9.5-4.5c-1-1-4-3-7 0-2.4 2.4-1.667 5.333-1 6.5 2 3 8.3 8 17.5 8 11.5 0 20.5-7.5 34-16Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.555 147c-.846 0-1.555.654-1.555 1.5 0 14.636 15.222 26.5 34 26.5s34-11.864 34-26.5c0-.846-.709-1.5-1.555-1.5h-64.89Z"
          fill={colors.primary[200]}
        />
        <path
          d="M97 173c-18.166 0-32-11.404-32-24.5h-4c0 16.175 16.61 28.5 36 28.5v-4Zm32-24.5c0 13.096-13.834 24.5-32 24.5v4c19.39 0 36-12.325 36-28.5h-4Zm.445-3.5h-64.89v4h64.89v-4Zm3.555 3.5c0-2.043-1.699-3.5-3.555-3.5v4a.417.417 0 0 1-.284-.118.54.54 0 0 1-.161-.382h4Zm-68 0c0 .156-.07.296-.161.382a.417.417 0 0 1-.284.118v-4c-1.856 0-3.555 1.457-3.555 3.5h4Z"
          fill={colors.primary[500]}
        />
        <path
          stroke={colors.primary[500]}
          strokeWidth={4}
          d="m121.227 147.925-24-46M73.235 146.059l24-45"
        />
        <path
          d="M218.112 85.5c-13.5-8.5-28-13.5-33.5-13.5-2.833-2.833-6.699-8.5.501-8.5 9 0 26 8.5 36.5 14.5s25 13.5 30.499 13.5c5.5 0 8.001-3 9.501-4.5 1-1 3.999-3 6.999 0 2.4 2.4 1.667 5.333 1 6.5-2 3-8.3 8-17.5 8-11.5 0-20.5-7.5-34-16Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M285.057 147c.846 0 1.555.654 1.555 1.5 0 14.636-15.222 26.5-34 26.5-18.777 0-34-11.864-34-26.5 0-.846.709-1.5 1.555-1.5h64.89Z"
          fill={colors.primary[200]}
        />
        <path
          d="M252.612 173c18.166 0 32-11.404 32-24.5h4c0 16.175-16.61 28.5-36 28.5v-4Zm-32-24.5c0 13.096 13.835 24.5 32 24.5v4c-19.389 0-36-12.325-36-28.5h4Zm-.445-3.5h64.89v4h-64.89v-4Zm-3.555 3.5c0-2.043 1.699-3.5 3.555-3.5v4a.417.417 0 0 0 .284-.118.54.54 0 0 0 .161-.382h-4Zm68 0a.54.54 0 0 0 .161.382.42.42 0 0 0 .284.118v-4c1.856 0 3.555 1.457 3.555 3.5h-4Z"
          fill={colors.primary[500]}
        />
        <path
          stroke={colors.primary[500]}
          strokeWidth={4}
          d="m228.385 147.925 24-46M276.377 146.059l-24-45"
        />
        <circle
          cx={175}
          cy={66}
          r={12}
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
      </g>
    </svg>
  );
};

export default IllustrationInsuranceLegalAssistance;
