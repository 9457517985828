import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyGarden: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M36.64 38.72c-4 0-5.36 2.65-5.36 2.65 0-1.8-3-5.31-5.35-5.31v-5.28l8-8h9.36c0-2.67-1.91-4-2.66-4H46a7 7 0 00-6.68-6.63c0-2-4.42-3.6-6.68-2.66C32.63 6.86 28.58 5 25.25 5s-7.36 1.9-7.36 4.52c0 0-5-.24-6.69 4a5.36 5.36 0 00-4 5.34h9.38l6.7 6.61v8c-8 0-9.36 5.31-9.36 5.31-4 0-8 2.69-8 5.31H42s0-5.36-5.36-5.37zM25.93 18.78h5.35a4.32 4.32 0 00-2.68 4h2.68l-5.35 5.29v-9.29zm-6.68 0h4v4l-4-4z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyGarden;
