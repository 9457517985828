import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsLegalConsumer: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25 12a4 4 0 100-8 4 4 0 000 8zM29 13h-8a5 5 0 00-5 5v9.88A2.08 2.08 0 0017.84 30a1.91 1.91 0 001.16-.28v13.64A2.6 2.6 0 0021.24 46 2.502 2.502 0 0024 43.5V33a1 1 0 012 0v10.36A2.6 2.6 0 0028.24 46 2.502 2.502 0 0031 43.5V29.72a1.91 1.91 0 001.16.27A2.08 2.08 0 0034 27.88V18a5 5 0 00-5-5z"
    ></path>
  </Icon>
);

export default RiskObjectsLegalConsumer;
