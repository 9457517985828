import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationFireProofDoor: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_5236_12052"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_5236_12052)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <rect
          width="82.808"
          height="159"
          x="110"
          y="68"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <mask id="path-5-inside-1_5236_12052" fill="#fff">
          <rect
            width="63.701"
            height="140.517"
            x="119.866"
            y="77.242"
            rx="2"
          ></rect>
        </mask>
        <rect
          width="63.701"
          height="140.517"
          x="119.866"
          y="77.242"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-5-inside-1_5236_12052)"
          rx="2"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M162.831 105.657c0 1.656-.97 3.372-2.977 4.758-1.999 1.38-4.87 2.298-8.137 2.298-3.268 0-6.139-.918-8.138-2.298-2.007-1.386-2.977-3.102-2.977-4.758 0-1.656.97-3.371 2.977-4.757 1.999-1.38 4.87-2.298 8.138-2.298 3.267 0 6.138.917 8.137 2.298 2.007 1.386 2.977 3.101 2.977 4.757z"
        ></path>
        <rect
          width="11.494"
          height="5.249"
          x="164.081"
          y="147.061"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2.625"
        ></rect>
        <g filter="url(#filter0_dd_5236_12052)">
          <circle cx="205.5" cy="72.5" r="42.5" fill="#fff"></circle>
          <circle
            cx="205.5"
            cy="72.5"
            r="40.5"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <g
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          opacity="0.66"
        >
          <path
            fill={colors.primary[300]}
            d="M193.692 60.886a.931.931 0 011.419.807 12.626 12.626 0 002.433 7.947.922.922 0 001.229.225c1.506-.94 4.996-3.894 6.739-11.74a31.384 31.384 0 00.447-12.04.921.921 0 01.88-1.085.924.924 0 01.504.129 38.205 38.205 0 0118.669 32.466c.265 11.638-8.927 21.592-20.567 21.555A20.51 20.51 0 01185 78.64c.002-8.441 3.819-14.589 8.692-17.754z"
          ></path>
          <path
            fill="#fff"
            d="M210.96 72.847a.933.933 0 00-1.289.975c.105.704.145 1.415.12 2.126-.162 4.647-3.062 7.487-6.316 7.374a5.112 5.112 0 01-4.576-3.054.935.935 0 00-1.498-.293.93.93 0 00-.274.519 10.266 10.266 0 005.087 10.84 10.26 10.26 0 0011.896-1.372 10.264 10.264 0 003.353-7.572 10.253 10.253 0 00-6.503-9.543z"
          ></path>
        </g>
        <path
          fill={colors.primary[500]}
          d="M233.391 41.98H237.391V123.138H233.391z"
          transform="rotate(45 233.391 41.98)"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_5236_12052"
          width="117"
          height="117"
          x="147"
          y="22"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5236_12052"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_5236_12052"
            result="effect2_dropShadow_5236_12052"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_5236_12052"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationFireProofDoor;
