import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleJeep: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M45.21 27.67l-1-3.12c-.64-.43-12.68-2-12.68-2l-3.19-6.12a.86.86 0 00-.76-.45H8.34a.85.85 0 00-.84.64c-.48 2.11-2 8.81-2.45 11.28a1.71 1.71 0 000 .87l.79 4.39a1 1 0 001 .82H9a5 5 0 1010 0h12a5 5 0 0010 0h2.75a1.4 1.4 0 001.36-1.09l.89-2.56v-2.66h-.79zM14 37a3 3 0 110-6.001 3 3 0 010 6zm22 0a3 3 0 110-6.001 3 3 0 010 6z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleJeep;
