export type Variants = 'solid' | 'dashed';

const WideButton = {
  baseStyle: {
    width: '100%',
    textStyle: 'normalBodyMedium',
    p: '6',
  },
  variants: {
    solid: {
      bg: 'primary.50',
      color: 'primary.500',
      _hover: {
        bg: 'primary.100',
      },
    },
    dashed: {
      border: '2px dashed',
      borderColor: 'gray.50',
      bg: 'gray.ultraLight',
      color: 'gray.400',
      _hover: {
        bg: 'primary.50',
        borderColor: 'primary.200',
        color: 'primary.500',
      },
    },
  },
};

export default WideButton;
