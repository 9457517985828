import React, { useState, createContext } from 'react';
import { node } from 'prop-types';

export const TopNavigationItems = createContext({
  navTabs: [],
  setNavTabs: (arg) => arg,
});

export const TopNavigationItemsProvider = ({ children }) => {
  const [navTabs, setNavTabs] = useState([]);

  return (
    <TopNavigationItems.Provider value={{ navTabs, setNavTabs }}>
      {children}
    </TopNavigationItems.Provider>
  );
};

TopNavigationItemsProvider.propTypes = {
  children: node.isRequired,
};
