import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsArchive: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.565 4.681H40.435c.777 0 1.44-.001 2.033.117a6.071 6.071 0 014.77 4.77c.117.592.117 1.256.116 2.032v.34c.001.776.002 1.44-.116 2.032a6.072 6.072 0 01-3.955 4.548v15.112c0 1.64 0 2.99-.09 4.09-.093 1.142-.293 2.189-.793 3.17a8.106 8.106 0 01-3.543 3.543c-.982.5-2.028.7-3.17.794-1.1.09-2.451.09-4.09.09H18.403c-1.64 0-2.99 0-4.09-.09-1.143-.093-2.19-.293-3.171-.794A8.106 8.106 0 017.6 40.893c-.5-.982-.7-2.029-.794-3.17-.09-1.1-.09-2.452-.09-4.091V18.52a6.071 6.071 0 01-3.954-4.548c-.117-.593-.117-1.256-.116-2.033a189.12 189.12 0 000-.339c-.001-.776-.001-1.44.116-2.032a6.07 6.07 0 014.77-4.77c.593-.118 1.256-.118 2.033-.117zm1.152 14.177v14.69c0 1.744.001 2.93.076 3.849.073.894.206 1.356.371 1.68a4.106 4.106 0 001.795 1.794c.324.165.785.298 1.68.371.918.075 2.105.077 3.848.077h13.026c1.743 0 2.93-.002 3.848-.077.895-.073 1.356-.206 1.68-.37a4.107 4.107 0 001.795-1.795c.165-.324.298-.786.37-1.68.076-.918.077-2.105.077-3.848v-14.69H10.717zm30.4-4H8.883a1.998 1.998 0 00-.116-.006 2.87 2.87 0 01-.455-.034 2.07 2.07 0 01-1.627-1.627c-.03-.15-.04-.38-.04-1.421 0-1.042.01-1.27.04-1.422a2.07 2.07 0 011.627-1.627c.15-.03.38-.04 1.421-.04h30.532c1.041 0 1.27.01 1.422.04a2.07 2.07 0 011.627 1.627c.03.151.04.38.04 1.422 0 1.041-.01 1.27-.04 1.421a2.07 2.07 0 01-1.627 1.627 2.87 2.87 0 01-.456.034 2 2 0 00-.116.006zM18.928 27.035a2 2 0 012-2h8.142a2 2 0 010 4h-8.142a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsArchive;
