import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceArt: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M116 47h98v113.077h-98V47Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M125.046 56.046h79.908v94.985h-79.908V56.046Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M137.108 84.692a15.075 15.075 0 0 0 15.077 15.077 15.08 15.08 0 0 0 15.077-15.077 15.079 15.079 0 0 0-15.077-15.077 15.075 15.075 0 0 0-15.077 15.077Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M150.677 89.216h37.692v37.692h-37.692V89.216Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M150.677 99.694a15.077 15.077 0 0 0 15.894-10.478h-9.863a6.031 6.031 0 0 0-6.031 6.03v4.448Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="m160.528 116.39 9.553 16.549a3.02 3.02 0 0 1 0 3.015 3.013 3.013 0 0 1-2.611 1.508h-19.109a3.016 3.016 0 0 1-2.611-4.523l9.555-16.549a3.018 3.018 0 0 1 5.223 0Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M160.528 116.39a3.01 3.01 0 0 0-2.611-1.507 3.016 3.016 0 0 0-2.612 1.507l-4.064 7.05a6.028 6.028 0 0 0 5.467 3.468h9.893l-6.073-10.518ZM240.329 225.404h-39.542v-66.431a3.164 3.164 0 0 1 3.163-3.163h33.215a3.164 3.164 0 0 1 3.164 3.163v66.431ZM232.42 146.32h-23.725a3.167 3.167 0 0 0-3.163 3.164v6.326h30.052v-6.326a3.165 3.165 0 0 0-3.164-3.164Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M211.858 166.882h17.399v7.908h-17.399v-7.908Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M197 97.398a17.398 17.398 0 1 0 34.796 0 17.398 17.398 0 0 0-34.796 0Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M197 97.398a17.398 17.398 0 1 0 34.796 0 17.398 17.398 0 0 0-34.796 0v0Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M216.604 122.594a11.071 11.071 0 1 0 22.142.002 11.071 11.071 0 0 0-22.142-.002Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M216.604 122.594a11.071 11.071 0 1 0 22.142.002 11.071 11.071 0 0 0-22.142-.002Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M215.022 139.993a6.326 6.326 0 1 0 12.652 0 6.326 6.326 0 0 0-12.652 0Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M215.022 139.993a6.326 6.326 0 1 0 12.652 0 6.326 6.326 0 0 0-12.652 0Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M229.067 108.021a4.746 4.746 0 1 0 9.491 0 4.746 4.746 0 0 0-9.491 0Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationInsuranceArt;
