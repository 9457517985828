import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BrokerMap from './BrokerMap';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const BrokerMapModal: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent minH="calc(100% - 16px)">
        <ModalHeader>
          {t('SALES-CONVERSATION_FINALIZATION_BROKER-INFO_HEADER-TITLE')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pos="relative">
          <Box pos="absolute" top="0" left="0" right="0" bottom="0" p="4">
            <BrokerMap />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            {t('CLOSE')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BrokerMapModal;
