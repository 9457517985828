import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationNotifications: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 9a2 2 0 00-2 1.969 2.008 2.008 0 01-1.182 1.857A12 12 0 0015 23.049V29c0 .08-.005.16-.014.24A10 10 0 0113.74 33h22.52a10 10 0 01-1.245-3.76A1.99 1.99 0 0135 29v-5.951a12 12 0 00-6.855-10.24A2 2 0 0127 11a2 2 0 00-2-2zm-5.86.711a6 6 0 0111.72 0A16 16 0 0139 23v5.87a6.002 6.002 0 002.986 4.39A2 2 0 0141 37H9a2 2 0 01-.986-3.74A6 6 0 0011 28.87V23a16 16 0 018.14-13.289z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19 33a2 2 0 012 2v2a4 4 0 108 0v-2a2 2 0 114 0v2a8 8 0 01-16 0v-2a2 2 0 012-2zM35.902 5.689a2 2 0 012.822-.199 24.1 24.1 0 016.093 8.129 2 2 0 01-3.634 1.67A20.101 20.101 0 0036.1 8.51a2 2 0 01-.199-2.821zM14.094 5.688a2 2 0 01-.198 2.821 20.1 20.1 0 00-5.079 6.78 2 2 0 11-3.634-1.67 24.1 24.1 0 016.089-8.128 2 2 0 012.822.197z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationNotifications;
