import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBus: React.FC<React.PropsWithChildren<IllustrationProps>> = ({
  isActive = true,
  ...otherProps
}) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3799_10402"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3799_10402)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M17.532 163.593l-.475.264A4 4 0 0015 167.354V203a6 6 0 006 6h252l64.807-13.343A3.999 3.999 0 00341 191.74V130c0-8.837-7.163-16-16-16H72.94c-.293 0-.584-.032-.869-.095l-3.642-.81a4.019 4.019 0 00-.868-.095H22.5c-8.5 0-6 10.5-1.5 10.5l-1.413 36.75a4 4 0 01-2.055 3.343z"
        ></path>
        <circle
          cx="88"
          cy="209"
          r="18"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="88"
          cy="209"
          r="6"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="256"
          cy="209"
          r="18"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="256"
          cy="209"
          r="6"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <rect
          width="19"
          height="66"
          x="35"
          y="131"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <rect
          width="55"
          height="26"
          x="70"
          y="131"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <rect
          width="55"
          height="26"
          x="139"
          y="131"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <rect
          width="55"
          height="26"
          x="207"
          y="131"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M275 133a2 2 0 012-2h43c5.523 0 10 4.477 10 10v14a2 2 0 01-2 2h-51a2 2 0 01-2-2v-22z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBus;
