import React, { useLayoutEffect, useState } from 'react';
import {
  HStack,
  StackProps,
  useBreakpointValue,
  useIsOverflow,
} from '@wegroup/design-system';
import ProgressBarStep from './ProgressBarStep';

interface Step {
  title: string;
  key: string;
  url?: string;
}
interface Props {
  steps: Step[];
  currentStep: string | number;
  isAlwaysClickable?: boolean;
  onStepClick?: (index: number) => void;
}

const ProgressBar: React.FC<React.PropsWithChildren<Props & StackProps>> = ({
  steps,
  currentStep,
  isAlwaysClickable = false,
  onStepClick = () => null,
  ...stackProps
}) => {
  const isMobile = useBreakpointValue({ base: true, tablet: false });
  const [barWidth, setBarWidth] = useState<number>();
  const { isOverflowing, ref: overflowRef } = useIsOverflow();
  const keys = steps.map(({ key }) => key);
  const isSmallBar =
    (overflowRef.current?.getBoundingClientRect().width || 0) < (barWidth || 0);

  const currentValue =
    typeof currentStep === 'number' ? currentStep : keys.indexOf(currentStep);

  useLayoutEffect(() => {
    if (isOverflowing && !barWidth && overflowRef.current) {
      setBarWidth(overflowRef.current.scrollWidth + 10);
    }
  }, [isOverflowing]);

  return (
    <HStack
      w="100%"
      justify="center"
      spacing="6"
      overflowX="auto"
      {...stackProps}
      ref={overflowRef}
      sx={{
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {isMobile ? (
        <ProgressBarStep position={currentValue + 1} active>
          {steps[currentValue]?.title}
        </ProgressBarStep>
      ) : (
        steps.map((step, i) => {
          const showTitle = i === currentValue || !isSmallBar;
          // items are only clickable when the step has passed
          const canClick = isAlwaysClickable || i < currentValue;
          return (
            <ProgressBarStep
              key={step.key}
              position={i + 1}
              active={i === currentValue}
              passed={currentValue > i}
              to={canClick ? step.url : undefined}
              tooltip={!showTitle ? step.title : undefined}
              onClick={() => (canClick ? onStepClick(i) : null)}
            >
              {showTitle && step.title}
            </ProgressBarStep>
          );
        })
      )}
    </HStack>
  );
};

export default ProgressBar;
