import { useToast } from '@chakra-ui/react';
import { IMessage, useSocket } from '@novu/notification-center';
import { useEffect } from 'react';

const useNewNotification = (callback: (data: IMessage) => void) => {
  const { socket } = useSocket();
  const toast = useToast();

  useEffect(() => {
    if (socket) {
      socket.on('notification_received', (data) => {
        callback(data.message);
      });
    }

    return () => {
      if (socket) {
        socket.off('notification_received');
      }
    };
  }, [socket, toast]);
};

export default useNewNotification;
