import { useBoolean } from '@wegroup/design-system';
import { isEmpty, isEqual, omit } from 'lodash';
import React, { useState } from 'react';
import { FieldErrors } from 'react-hook-form';
import { useLocale } from '../../../../../../i18n';
import { Address } from '../../../../../../types/Common';
import AddressSearchPicker from './components/AddressSearchPicker';
import ManualAddressInput from './components/manual-address-search/ManualAddressInput';

export interface AddressInputProps {
  /** HTML name of the div */
  name?: string;
  /** Current address value */
  value?: Address;
  /** Returns the address when once is selected from the list or the one or more vlaues are changed in the manual input */
  onChange: (address?: Address) => void;
  /** Triggered when the input field is blurred */
  onBlur?: () => void;
  /** General error for the address search */
  error?: string;
  /** Individual errors for each address field. Must be used with react-hook-form. */
  errors?: FieldErrors<Address>;
  /** Is the country select shown */
  isWithCountry?: boolean;
  /** If there should be no API lookup for manual input but only plain text fields */
  isFullManual?: boolean;
  /** If the field should have prefilled styling */
  isPrefilled?: boolean;
  /** Disabling the input (or all individual inputs) */
  isDisabled?: boolean;
  /** The country used for lookup */
  country?: string;
  /** If manual input is allowed */
  isManualAllowed?: boolean;
  /** If lookup via API is allowed  */
  isLookupAllowed?: boolean;
  /** Triggered when a user searched for an address in NL, use `rightElement` for other countries. */
  onSearch?: (address?: Address) => void;
  /** Extra element shown on the right (not available if country is NL) */
  rightElement?: React.ReactElement;
  /** Id used for testing purposes */
  'data-test-id'?: string;
  /** Array of language codes that will show in the countrypicker */
  supportedCountries?: string[];
  /** If the label should be included for the address lookup */
  withLabel?: boolean;
}

/**
 * High adaptable address input that automatically changes depending on the user plan (NL or BE).
 */
const AddressInput: React.FC<AddressInputProps> = ({
  name,
  onChange,
  onBlur = () => null,
  country: countryProp,
  value,
  error,
  errors,
  isWithCountry = true,
  isFullManual,
  isPrefilled,
  isManualAllowed = true,
  isDisabled,
  onSearch,
  rightElement,
  'data-test-id': dataTestId,
  supportedCountries,
  withLabel = true,
}) => {
  const { userCountry } = useLocale();
  const valueWithoutCountry = omit(value || {}, ['country_code', '_local']);
  const [isManualMode, setIsManualMode] = useBoolean(
    () => !isEmpty(valueWithoutCountry),
  );
  const handleOnManualModeOn = () => setIsManualMode.on();
  const handleOnManualModeOff = () => setIsManualMode.off();

  const country = value?.country_code || countryProp || userCountry;

  /** https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes  */
  const [prevAddressState, setPrevAddressState] = useState(valueWithoutCountry);
  if (
    !isEmpty(valueWithoutCountry) &&
    !isEqual(prevAddressState, valueWithoutCountry)
  ) {
    setPrevAddressState(valueWithoutCountry);
    setIsManualMode.on();
  }

  if (isManualAllowed && isManualMode)
    return (
      <ManualAddressInput
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onManualModeOff={handleOnManualModeOff}
        error={error}
        errors={errors}
        country={country}
        isWithCountry={isWithCountry}
        isFullManual={isFullManual}
        isPrefilled={!isEmpty(valueWithoutCountry) && isPrefilled}
        isDisabled={isDisabled}
        rightElement={rightElement}
        data-test-id={dataTestId}
        supportedCountries={supportedCountries}
      />
    );

  return (
    <AddressSearchPicker
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      isManualAllowed={isManualAllowed}
      onManualModeOn={handleOnManualModeOn}
      error={error}
      isPrefilled={!isEmpty(valueWithoutCountry) && isPrefilled}
      isDisabled={isDisabled}
      rightElement={rightElement}
      data-test-id={dataTestId}
      errors={errors}
      country={country}
      isWithCountry={isWithCountry}
      isFullManual={isFullManual}
      supportedCountries={supportedCountries}
      withLabel={withLabel}
    />
  );
};

export * from './api';
export * from './types';

export default AddressInput;
