import React from 'react';
import { Redirect, useLocation } from 'react-router';
import { EXTEND } from '../../../../../../types/Campaigns';
import { FlowType } from '../../../../../campaigns/src/types/campaignTypes';
import { flowIdCyclingVlaanderenOfferFlowId } from '../../../../../flows/src/constants/flowIds';
import { isConversationUrl } from '../../authentication/constants/SocialAuth';
import useAuth from '../../authentication/hooks/useAuth';

const CampaignRedirector: React.FC = () => {
  const { campaign } = useAuth();
  const location = useLocation();
  const { search: hashRouteSearch } = location;

  if (isConversationUrl)
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/company-scan',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );

  if (!campaign) return null;

  if (campaign.insurances[0]?.insurance_company === 'dna_yachtinsurance')
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/sales-conversation/boat/offers',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );
  if (
    campaign.insurances[0]?.insurance_company === 'villasure' ||
    campaign.insurances[0]?.insurance_company === 'belvilla'
  )
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/sales-conversation/villasure/offers',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );

  if (campaign.insurances[0]?.insurance_company === 'bikmo') {
    if (campaign.flow_id === flowIdCyclingVlaanderenOfferFlowId) {
      return (
        <Redirect
          from="/"
          to={{
            pathname: '/sales-conversation/cycling-vlaanderen/offers',
            search: hashRouteSearch || window.location.search,
          }}
          exact
        />
      );
    }
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/sales-conversation/bikmo/offers',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );
  }

  if (campaign.extend === EXTEND.RISK_ANALYSIS)
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/risk-analysis',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );
  if (campaign.extend === EXTEND.COMPANY_SCAN)
    return (
      <Redirect
        from="/"
        to={{
          pathname: '/company-scan',
          search: hashRouteSearch || window.location.search,
        }}
        exact
      />
    );

  switch (campaign.template?.flow_type) {
    case FlowType.COMPANY_SCAN:
      return (
        <Redirect
          from="/"
          to={{
            pathname: '/company-scan',
            search: hashRouteSearch || window.location.search,
          }}
          exact
        />
      );
    case FlowType.RISK_ANALYSIS_LONG:
    case FlowType.RISK_ANALYSIS_SHORT:
      return (
        <Redirect
          from="/"
          to={{
            pathname: '/risk-analysis',
            search: hashRouteSearch || window.location.search,
          }}
          exact
        />
      );
    case FlowType.ADVISORY_CONVERSATION:
      return (
        <Redirect
          from="/"
          to={{
            pathname: '/product/session',
            search: hashRouteSearch || window.location.search,
          }}
          exact
        />
      );
    default:
      return null;
  }
};

export default CampaignRedirector;
