import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsAdd: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path fill="currentColor" d="M38 23H27V12h-4v11H12v4h11v11h4V27h11v-4z" />
  </Icon>
);

export default GeneralActionsAdd;
