import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyJewelry: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M30 18l3.09-4.64A.2.2 0 0033 13h-3.3a.21.21 0 00-.18.1l-2.37 4.08A13.85 13.85 0 0025 17c-.67.01-1.338.063-2 .16l-2.5-4.06a.21.21 0 00-.17-.1H17a.2.2 0 00-.16.31L20 18a14 14 0 1010 0zm-5 23a10 10 0 110-20 10 10 0 010 20zM26.7 10.68L25.22 8.8a.2.2 0 00-.31 0l-1.53 1.88a.2.2 0 00.043.298.2.2 0 00.107.032h3a.2.2 0 00.217-.223.201.201 0 00-.047-.107zM23.38 7h-2.79a.18.18 0 00-.15.07l-3.24 3.6a.201.201 0 00-.03.213.2.2 0 00.18.117h3.09a.21.21 0 00.15-.07l2.94-3.6a.2.2 0 00-.15-.33zM32.8 10.67l-3.24-3.6a.18.18 0 00-.15-.07h-2.64a.2.2 0 00-.16.32l2.82 3.6a.209.209 0 00.16.08h3.06a.2.2 0 00.15-.33z"
    ></path>
    <path
      fill="currentColor"
      d="M25.24 16.49l1.86-3.19a.2.2 0 00-.18-.3h-3.79a.2.2 0 00-.18.3l1.95 3.19a.2.2 0 00.34 0z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyJewelry;
