import React from 'react';

import {
  HStack,
  Center,
  Text,
  Checkbox,
  CheckboxProps,
} from '@wegroup/design-system';

interface Props {
  label: string;
  icon?: React.ReactElement;
}

const IconCheckbox: React.FC<
  React.PropsWithChildren<Props & CheckboxProps>
> = ({ label, icon, ...props }) => {
  return (
    <HStack as="label" justify="space-between" cursor="pointer">
      <HStack spacing="3">
        {icon && (
          <Center w="8" h="8" p="2" borderRadius="lg" bg="primary.50">
            {icon}
          </Center>
        )}
        <Text>{label}</Text>
      </HStack>
      <Checkbox {...props} />
    </HStack>
  );
};

export default IconCheckbox;
