import { HStack, Text, VStack } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useTracking from '../../../../../../common/src/hooks/tracking/useTracking';
import { useLouiseChatFeedbackModal } from '../../hooks/useLouiseChatFeedbackModal';
import ThumbsRadioGroup, {
  YesNoString,
} from '../thumbs-radio/ThumbsRadioGroup';
import ChatFeedbackModal from './ChatFeedbackModal';

interface Props {
  conversationId: string;
}

const ChatFeedback: React.FC<Props> = ({ conversationId }) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const {
    formMethods,
    handleFeedbackClose,
    handleNegativeFeedback,
    handlePositiveFeedback,
    isOpen,
    onOpen,
  } = useLouiseChatFeedbackModal(conversationId);

  const handleFeedbackChange = (value: YesNoString) => {
    if (value === 'yes') {
      trackEvent('Click on positive feedback for Louise GPT answer');
      handlePositiveFeedback();
    } else {
      trackEvent('Click on negative feedback for Louise GPT answer');
      onOpen();
    }
  };

  const handleNegativeClick = (value: YesNoString) => {
    if (value === 'no') onOpen();
  };

  return (
    <>
      <ChatFeedbackModal
        isOpen={isOpen}
        onClose={handleFeedbackClose}
        onConfirm={handleNegativeFeedback}
        register={formMethods.register}
        handleSubmit={formMethods.handleSubmit}
      />
      <VStack spacing="4">
        <Text color="gray.400" textStyle="smallBody">
          {t('TOOLKIT_INSURANCE-TERMS-BOT_ANSWER_FEEDBACK_TITLE')}
        </Text>
        <HStack>
          <ThumbsRadioGroup
            onChange={handleFeedbackChange}
            onNegativeClick={handleNegativeClick}
          />
        </HStack>
      </VStack>
    </>
  );
};

export default ChatFeedback;
