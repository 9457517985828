import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralRiskObjectsBoat: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      d="M42.5353 31.7002H7.4476C7.10657 31.7002 6.87922 32.0791 7.06868 32.3822L11.161 38.8996C11.2368 39.0133 11.3883 39.0891 11.5399 39.0891H38.4809C38.6325 39.0891 38.7841 39.0133 38.8598 38.8996L42.9521 32.3822C43.1037 32.0791 42.8764 31.7002 42.5353 31.7002Z"
      fill="currentColor"
    />
    <path
      d="M11.4262 29.2373H21.3539C21.5812 29.2373 21.8086 29.0478 21.8086 28.7826V11.7313C21.8086 11.2766 21.2023 11.125 20.975 11.5039L11.0473 28.5552C10.8579 28.8584 11.0852 29.2373 11.4262 29.2373Z"
      fill="currentColor"
    />
    <path
      d="M24.7262 29.2372H38.5946C38.9735 29.2372 39.163 28.8204 38.9356 28.5173L25.0673 11.0871C24.802 10.7461 24.2715 10.9355 24.2715 11.3523V28.7825C24.2715 29.0478 24.461 29.2372 24.7262 29.2372Z"
      fill="currentColor"
    />
  </Icon>
);

export default GeneralRiskObjectsBoat;
