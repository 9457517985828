import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsSendTo: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.932 11a6 6 0 0 1 6-6h12.585a3 3 0 0 1 2.122.879l10.414 10.414a3 3 0 0 1 .879 2.121v8.85h-4V21h-7a4 4 0 0 1-4-4V9h-11a2 2 0 0 0-2 2v29a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2v-3.615h4V40a6 6 0 0 1-6 6h-20a6 6 0 0 1-6-6V11Zm21 .828L29.102 17h-5.172v-5.172Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.932 24.863a2 2 0 0 1 2.828 0l5.71 5.71.013.013a2 2 0 0 1 0 2.828l-5.723 5.722a2 2 0 1 1-2.828-2.828L40.24 34H27.863a2 2 0 1 1 0-4H40.24l-2.308-2.308a2 2 0 0 1 0-2.829Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default GeneralActionsSendTo;
