import { Flex, DotLoader } from '@wegroup/design-system';
import React from 'react';
import { LouiseChatBox } from './LouiseSingleChatWrapper';

const LoadingChatMessage: React.FC = () => {
  return (
    <LouiseChatBox>
      <Flex align="center" p="3" flexGrow={1}>
        <DotLoader />
      </Flex>
    </LouiseChatBox>
  );
};

export default LoadingChatMessage;
