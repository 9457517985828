import { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDistributionId } from '../api/get/getDistribution';
import { useSocialLogin } from '../api/post/postSocialLogin';
import { ACCESS_TOKEN_UNEXPIRED_EVENT_NAME } from '../constants/Jwt';
import { isSocialLoginUrl } from '../constants/SocialAuth';
import useAuth from './useAuth';

interface UseCaptcha {
  isAuthenticatingByCaptcha: boolean;
  executeCaptcha: (arg0: string) => Promise<void>;
  isCaptchaAvailable: boolean;
}

const useCaptchaV3 = (cb?: () => void): UseCaptcha => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { user } = useAuth();

  const [isExecutingCaptcha, setIsExecutingCaptcha] = useState<boolean>(false);

  const { mutateAsync: authenticateByCaptcha } = useSocialLogin();
  const distributionId = useDistributionId();

  const executeCaptcha = async (email: string) => {
    /** Disable captcha for normal flows env */
    if (!isSocialLoginUrl) return;
    try {
      const captchaKey = await executeRecaptcha?.();
      setIsExecutingCaptcha(true);
      if (!captchaKey) {
        return console.error(
          'Something went wrong while executing the captcha V3',
        );
      }
      await getAccessTokenByCaptchaKey(captchaKey, email);
      cb?.();
      window.dispatchEvent(new Event(ACCESS_TOKEN_UNEXPIRED_EVENT_NAME));
    } catch (error) {
      console.error('Something went wrong while executing the captcha V3');
      if (error) console.error(error);
    }
    setIsExecutingCaptcha(false);
  };

  /**
   * Does a call to backend to receive a social login token
   * @param captchaKey Key received by captcha
   */
  const getAccessTokenByCaptchaKey = async (
    captchaKey: string,
    email: string,
  ) => {
    await authenticateByCaptcha({
      token: captchaKey,
      email,
      askBrokerId: user?.id,
      askDistributionId: distributionId,
      version: 'V3',
    });
  };

  return {
    isAuthenticatingByCaptcha: isExecutingCaptcha,
    executeCaptcha,
    isCaptchaAvailable: !!executeRecaptcha,
  };
};

export default useCaptchaV3;
