import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersRevision: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.164 8.08a6.25 6.25 0 116.723 10.23 6.251 6.251 0 006.03 4.607h6.607a6.248 6.248 0 0110.312-2.336 6.25 6.25 0 11-10.312 6.502h-6.607c-2.267 0-4.458-.739-6.25-2.083v6.607a6.25 6.25 0 11-4.167 0V18.393A6.25 6.25 0 0110.164 8.08zm4.42 2.337a2.083 2.083 0 100 4.166 2.083 2.083 0 000-4.166zm20.833 12.5a2.083 2.083 0 100 4.166 2.083 2.083 0 000-4.166zm-20.834 12.5a2.083 2.083 0 100 4.166 2.083 2.083 0 000-4.166z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersRevision;
