import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralRiskObjectsCyberSecurity: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M36.964 13.32a2 2 0 012.745.682 17.98 17.98 0 012.485 11.126v1.894a9.987 9.987 0 001.331 4.992 2 2 0 11-3.466 1.996 13.986 13.986 0 01-1.865-6.992l2 .001h-2v-1.997c0-.073.004-.146.012-.218a13.98 13.98 0 00-1.923-8.738 2 2 0 01.681-2.745zM24.213 17.034a5.99 5.99 0 00-5.99 5.99 2 2 0 01-4 0 9.99 9.99 0 1119.98 0v1.998c0 3.89 1.262 7.674 3.596 10.785a2 2 0 11-3.2 2.4 21.975 21.975 0 01-4.396-13.185v-1.998a5.99 5.99 0 00-5.99-5.99z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.213 21.024a2 2 0 012 2v3.998a25.966 25.966 0 004.637 14.837 2 2 0 11-3.286 2.282 29.966 29.966 0 01-5.35-17.123v-3.994a2 2 0 012-2zM15.99 29.028a2 2 0 012.22 1.754A33.955 33.955 0 0021.604 42.1a2 2 0 01-3.573 1.798 37.955 37.955 0 01-3.796-12.652 2 2 0 011.754-2.219z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31.203 10.873a13.98 13.98 0 00-20.97 12.146v2.003c0 .022 0 .044-.002.066a41.95 41.95 0 001.716 13.347 2 2 0 11-3.834 1.14 45.947 45.947 0 01-1.88-14.586v-1.962a17.98 17.98 0 0126.97-15.618 2 2 0 11-2 3.464z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralRiskObjectsCyberSecurity;
