import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationTravelsAYearTwice: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_2628_9880"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_2628_9880)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <rect
          width="126"
          height="82.802"
          x="85"
          y="142.971"
          fill="#fff"
          rx="2"
        ></rect>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M138.999 128.572h18.002a7.201 7.201 0 017.2 7.2H131.8a7.2 7.2 0 017.199-7.2z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M167.805 142.972h17.999v82.8h-17.999v-82.8z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M173.205 157.373a3.6 3.6 0 107.201.001 3.6 3.6 0 00-7.201-.001zM173.205 211.373a3.6 3.6 0 107.201.001 3.6 3.6 0 00-7.201-.001z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M110.199 142.972h17.999v82.8h-17.999v-82.8z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M115.599 157.373a3.601 3.601 0 006.146 2.546 3.6 3.6 0 10-6.146-2.546zM115.599 211.373a3.601 3.601 0 006.146 2.546 3.6 3.6 0 10-6.146-2.546zM125.894 177.245a1.801 1.801 0 002.233 1.224l13.822-4.032a1.802 1.802 0 001.226-2.232l-4.324-14.836a1.82 1.82 0 00-.324-.627 1.818 1.818 0 00-.541-.454l-5.388-2.955a1.8 1.8 0 00-1.371-.149l-5.48 1.597a1.787 1.787 0 00-.627.325c-.186.148-.34.332-.454.54l-2.95 5.392a1.804 1.804 0 00-.149 1.371l4.327 14.836z"
        ></path>
        <rect
          width="126"
          height="82.802"
          x="85"
          y="142.971"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M164.2 135.772v7.2M131.8 135.772v7.2M129.999 159.172s-6.514-20.183-1.799-22.551a3.1 3.1 0 013.6.681"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M178 103.097h94v107.13h-94v-107.13z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M178 103.097h94v107.13h-94v-107.13z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M263.606 210.227v10.071a6.714 6.714 0 11-13.427 0v-10.071h13.427zM199.82 210.227v10.071a6.714 6.714 0 01-6.715 6.589 6.718 6.718 0 01-6.715-6.589v-10.071h13.43z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M191.426 150.098h67.142v46.701h-67.142v-46.701z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M191.426 150.098h67.142v46.701h-67.142v-46.701z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M191.426 116.526h67.142v20.144h-67.142v-20.144z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M191.426 116.526h67.142v20.144h-67.142v-20.144z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M238.43 53.04h10.072v50.057H238.43V53.039z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M238.43 53.04h10.072v50.057H238.43V53.039z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M201.498 53.04h10.071v50.057h-10.071V53.039z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M201.498 53.04h10.071v50.057h-10.071V53.039z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M201.498 53.039a6.714 6.714 0 010-13.428h46.999a6.716 6.716 0 016.714 6.714 6.713 6.713 0 01-6.714 6.714h-46.999z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M201.498 53.039a6.714 6.714 0 010-13.428h46.999a6.716 6.716 0 016.714 6.714 6.713 6.713 0 01-6.714 6.714h-46.999z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationTravelsAYearTwice;
