import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleBaPlusPlus: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M42.31 26A3.64 3.64 0 0039 29.65v5.81a.5.5 0 00.5.5H41v9.5a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V36h1.5a.5.5 0 00.5-.5v-6a3.51 3.51 0 00-3.69-3.5zM36 29.62a1.44 1.44 0 00-1-.84l-2.09-.45-16.22-3.52a1.32 1.32 0 01-.82-.53l-3.72-5a.84.84 0 00-.44-.28l-3.22-.6c-.93-.16-1.86-.32-2.8-.44a.61.61 0 00-.69.61V40h13a6.5 6.5 0 1013 0h4.51a1.5 1.5 0 00.5-.79c.505-1.973.88-3.977 1.12-6-.21-1.243-.59-2.45-1.13-3.59zM24.5 43a3.5 3.5 0 01-3.5-3 2.747 2.747 0 010-.5 3.5 3.5 0 017 0c.015.166.015.334 0 .5a3.5 3.5 0 01-3.5 3zM42.05 13.32a1.53 1.53 0 00-1.87-.25l-5.69 3.32L32 8.58a1.54 1.54 0 00-3 .18l-1.68 8.57-5.46-3.19a1.53 1.53 0 00-1.91.29 1.59 1.59 0 00-.15 1.94l4.2 6.42a1.519 1.519 0 002.15.44 1.59 1.59 0 00.43-2.17l-.58-.88 1.66 1a1.5 1.5 0 001.41.06 1.57 1.57 0 00.88-1.12l1-4.87 1.3 4a1.55 1.55 0 00.92 1 1.53 1.53 0 001.32-.1l2.42-1.42-1.39 2.49a1.58 1.58 0 00.48 2.07c.233.139.499.211.77.21a1.56 1.56 0 001.35-.8l4.19-7.5a1.59 1.59 0 00-.26-1.88z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleBaPlusPlus;
