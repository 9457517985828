import {
  BoxProps,
  ComponentWithAs,
  VStack,
  TextProps,
  StackProps,
} from '@wegroup/design-system';
import React from 'react';
import CardHeader from '../common/CardHeader';
import StrokeBox from '../common/StrokeBox';

interface Props {
  textStyle?: TextProps['textStyle'];
  title?: string;
  rightElement?: JSX.Element;
  containerProps?: StackProps;
}

const DefaultCard: ComponentWithAs<'div', Props & BoxProps> = ({
  title,
  rightElement,
  textStyle = 'normalBodyMedium',
  children,
  containerProps,
  ...props
}) => {
  return (
    <VStack align="stretch" {...containerProps}>
      {(title != null || rightElement != null) && (
        <CardHeader
          textStyle={textStyle}
          title={title}
          rightElement={rightElement}
        />
      )}
      <StrokeBox {...props}>{children}</StrokeBox>
    </VStack>
  );
};

export default DefaultCard;
