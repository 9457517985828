import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralCompanyLogoLinkedIn: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M7 12.45c0-1.145.405-2.09 1.216-2.834.811-.744 1.865-1.116 3.162-1.116 1.274 0 2.305.366 3.093 1.099.81.755 1.216 1.74 1.216 2.954 0 1.098-.394 2.014-1.181 2.747-.811.755-1.877 1.133-3.197 1.133h-.035c-1.274 0-2.305-.378-3.093-1.133C7.394 14.544 7 13.594 7 12.45zm.452 30.05V19.559h7.714v22.94H7.452zm11.988 0h7.714V29.69c0-.802.093-1.42.278-1.855a4.985 4.985 0 011.477-1.974c.66-.538 1.489-.808 2.485-.808 2.594 0 3.892 1.73 3.892 5.186V42.5H43V29.347c0-3.388-.81-5.958-2.432-7.71-1.622-1.751-3.765-2.627-6.429-2.627-2.988 0-5.317 1.27-6.985 3.812v.069h-.034l.034-.069V19.56H19.44c.046.732.07 3.01.07 6.834 0 3.823-.024 9.193-.07 16.107z"
    ></path>
  </Icon>
);

export default GeneralCompanyLogoLinkedIn;
