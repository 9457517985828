import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationDoubleGlass: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_5237_12196"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_5237_12196)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M62 50H163V202H62z"
        ></path>
        <path
          stroke={colors.primary[300]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M153 108v40"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M74 62H143V118H74z"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M74 134H143V190H74z"
        ></path>
        <mask
          id="mask1_5237_12196"
          style={{ maskType: 'alpha' }}
          width="73"
          height="132"
          x="72"
          y="60"
          maskUnits="userSpaceOnUse"
        >
          <mask id="path-8-inside-1_5237_12196" fill="#fff">
            <path
              fillRule="evenodd"
              d="M145 60H72v60h73V60zm0 72H72v60h73v-60z"
              clipRule="evenodd"
            ></path>
          </mask>
          <path
            fill={colors.primary[50]}
            fillRule="evenodd"
            d="M145 60H72v60h73V60zm0 72H72v60h73v-60z"
            clipRule="evenodd"
          ></path>
          <path
            fill={colors.primary[500]}
            d="M72 60v-4h-4v4h4zm73 0h4v-4h-4v4zm-73 60h-4v4h4v-4zm73 0v4h4v-4h-4zm-73 12v-4h-4v4h4zm73 0h4v-4h-4v4zm-73 60h-4v4h4v-4zm73 0v4h4v-4h-4zM72 64h73v-8H72v8zm4 56V60h-8v60h8zm69-4H72v8h73v-8zm-4-56v60h8V60h-8zm-69 76h73v-8H72v8zm4 56v-60h-8v60h8zm69-4H72v8h73v-8zm-4-56v60h8v-60h-8z"
            mask="url(#path-8-inside-1_5237_12196)"
          ></path>
        </mask>
        <g mask="url(#mask1_5237_12196)">
          <path
            stroke={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="20"
            d="M158 62L53 167M158 148L53 253"
          ></path>
          <path
            stroke={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="10"
            d="M158 91L53 196"
          ></path>
          <mask id="path-13-inside-2_5237_12196" fill="#fff">
            <path
              fillRule="evenodd"
              d="M76 64h65v52H76V64zm-4-4h73v60H72V60zm4 76h65v52H76v-52zm-4-4h73v60H72v-60z"
              clipRule="evenodd"
            ></path>
          </mask>
          <path
            fill={colors.primary[300]}
            d="M141 64h4v-4h-4v4zm-65 0v-4h-4v4h4zm65 52v4h4v-4h-4zm-65 0h-4v4h4v-4zm-4-56v-4h-4v4h4zm73 0h4v-4h-4v4zm0 60v4h4v-4h-4zm-73 0h-4v4h4v-4zm69 16h4v-4h-4v4zm-65 0v-4h-4v4h4zm65 52v4h4v-4h-4zm-65 0h-4v4h4v-4zm-4-56v-4h-4v4h4zm73 0h4v-4h-4v4zm0 60v4h4v-4h-4zm-73 0h-4v4h4v-4zm69-132H76v8h65v-8zm4 56V64h-8v52h8zm-69 4h65v-8H76v8zm-4-56v52h8V64h-8zm4-8h-4v8h4v-8zm65 0H76v8h65v-8zm4 0h-4v8h4v-8zm4 8v-4h-8v4h8zm0 52V64h-8v52h8zm0 4v-4h-8v4h8zm-8 4h4v-8h-4v8zm-65 0h65v-8H76v8zm-4 0h4v-8h-4v8zm-4-8v4h8v-4h-8zm0-52v52h8V64h-8zm0-4v4h8v-4h-8zm73 72H76v8h65v-8zm4 56v-52h-8v52h8zm-69 4h65v-8H76v8zm-4-56v52h8v-52h-8zm4-8h-4v8h4v-8zm65 0H76v8h65v-8zm4 0h-4v8h4v-8zm4 8v-4h-8v4h8zm0 52v-52h-8v52h8zm0 4v-4h-8v4h8zm-8 4h4v-8h-4v8zm-65 0h65v-8H76v8zm-4 0h4v-8h-4v8zm-4-8v4h8v-4h-8zm0-52v52h8v-52h-8zm0-4v4h8v-4h-8z"
            mask="url(#path-13-inside-2_5237_12196)"
          ></path>
        </g>
        <path
          fill="#fff"
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M-2 2H99V154H-2z"
          transform="matrix(-1 0 0 1 262 48)"
        ></path>
        <path
          stroke={colors.primary[300]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M173 108v40"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M-2 2H67V58H-2z"
          transform="matrix(-1 0 0 1 250 60)"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M-2 2H67V58H-2z"
          transform="matrix(-1 0 0 1 250 132)"
        ></path>
        <mask
          id="mask2_5237_12196"
          style={{ maskType: 'alpha' }}
          width="73"
          height="132"
          x="181"
          y="60"
          maskUnits="userSpaceOnUse"
        >
          <mask id="path-19-inside-3_5237_12196" fill="#fff">
            <path
              fillRule="evenodd"
              d="M181 60h73v60h-73V60zm0 72h73v60h-73v-60z"
              clipRule="evenodd"
            ></path>
          </mask>
          <path
            fill={colors.primary[50]}
            fillRule="evenodd"
            d="M181 60h73v60h-73V60zm0 72h73v60h-73v-60z"
            clipRule="evenodd"
          ></path>
          <path
            fill={colors.primary[500]}
            d="M254 60v-4h4v4h-4zm-73 0h-4v-4h4v4zm73 60h4v4h-4v-4zm-73 0v4h-4v-4h4zm73 12v-4h4v4h-4zm-73 0h-4v-4h4v4zm73 60h4v4h-4v-4zm-73 0v4h-4v-4h4zm73-128h-73v-8h73v8zm-4 56V60h8v60h-8zm-69-4h73v8h-73v-8zm4-56v60h-8V60h8zm69 76h-73v-8h73v8zm-4 56v-60h8v60h-8zm-69-4h73v8h-73v-8zm4-56v60h-8v-60h8z"
            mask="url(#path-19-inside-3_5237_12196)"
          ></path>
        </mask>
        <g mask="url(#mask2_5237_12196)">
          <path
            stroke={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="20"
            d="M281.5 45L163 163.5"
          ></path>
          <path
            stroke={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="10"
            d="M281.5 105L163 223.5"
          ></path>
          <mask id="path-23-inside-4_5237_12196" fill="#fff">
            <path
              fillRule="evenodd"
              d="M250 64h-65v52h65V64zm4-4h-73v60h73V60zm-4 76h-65v52h65v-52zm4-4h-73v60h73v-60z"
              clipRule="evenodd"
            ></path>
          </mask>
          <path
            fill={colors.primary[300]}
            d="M185 64h-4v-4h4v4zm65 0v-4h4v4h-4zm-65 52v4h-4v-4h4zm65 0h4v4h-4v-4zm4-56v-4h4v4h-4zm-73 0h-4v-4h4v4zm0 60v4h-4v-4h4zm73 0h4v4h-4v-4zm-69 16h-4v-4h4v4zm65 0v-4h4v4h-4zm-65 52v4h-4v-4h4zm65 0h4v4h-4v-4zm4-56v-4h4v4h-4zm-73 0h-4v-4h4v4zm0 60v4h-4v-4h4zm73 0h4v4h-4v-4zM185 60h65v8h-65v-8zm-4 56V64h8v52h-8zm69 4h-65v-8h65v8zm4-56v52h-8V64h8zm-4-8h4v8h-4v-8zm-65 0h65v8h-65v-8zm-4 0h4v8h-4v-8zm-4 8v-4h8v4h-8zm0 52V64h8v52h-8zm0 4v-4h8v4h-8zm8 4h-4v-8h4v8zm65 0h-65v-8h65v8zm4 0h-4v-8h4v8zm4-8v4h-8v-4h8zm0-52v52h-8V64h8zm0-4v4h-8v-4h8zm-73 72h65v8h-65v-8zm-4 56v-52h8v52h-8zm69 4h-65v-8h65v8zm4-56v52h-8v-52h8zm-4-8h4v8h-4v-8zm-65 0h65v8h-65v-8zm-4 0h4v8h-4v-8zm-4 8v-4h8v4h-8zm0 52v-52h8v52h-8zm0 4v-4h8v4h-8zm8 4h-4v-8h4v8zm65 0h-65v-8h65v8zm4 0h-4v-8h4v8zm4-8v4h-8v-4h8zm0-52v52h-8v-52h8zm0-4v4h-8v-4h8z"
            mask="url(#path-23-inside-4_5237_12196)"
          ></path>
        </g>
        <g filter="url(#filter0_dd_5237_12196)">
          <circle cx="252.5" cy="72.5" r="42.5" fill="#fff"></circle>
        </g>
        <mask
          id="mask3_5237_12196"
          style={{ maskType: 'alpha' }}
          width="85"
          height="85"
          x="210"
          y="30"
          maskUnits="userSpaceOnUse"
        >
          <circle
            cx="252.5"
            cy="72.5"
            r="40.5"
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </mask>
        <g mask="url(#mask3_5237_12196)">
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M269 59.236V156H73V-38.764l196 98z"
          ></path>
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M259 69.236V166H63V-28.764l196 98z"
          ></path>
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M249 79.236V176H53V-18.764l196 98z"
          ></path>
          <mask
            id="mask4_5237_12196"
            style={{ maskType: 'alpha' }}
            width="200"
            height="200"
            x="51"
            y="-22"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill={colors.primary[100]}
              stroke={colors.primary[500]}
              strokeWidth="4"
              d="M249 79.236V176H53V-18.764l196 98z"
            ></path>
          </mask>
          <g
            stroke={colors.primary[200]}
            strokeLinecap="round"
            strokeLinejoin="round"
            mask="url(#mask4_5237_12196)"
          >
            <path strokeWidth="20" d="M246 81L140 186"></path>
            <path strokeWidth="5" d="M235 67L129 172"></path>
          </g>
          <path
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M249 79.236V176H53V-18.764l196 98z"
          ></path>
        </g>
        <g filter="url(#filter1_dd_5237_12196)">
          <circle
            cx="252.5"
            cy="72.5"
            r="40.5"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M249 79.5L269.5 59"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_5237_12196"
          width="117"
          height="117"
          x="194"
          y="22"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5237_12196"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_5237_12196"
            result="effect2_dropShadow_5237_12196"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_5237_12196"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_dd_5237_12196"
          width="117"
          height="117"
          x="194"
          y="22"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5237_12196"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_5237_12196"
            result="effect2_dropShadow_5237_12196"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_5237_12196"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationDoubleGlass;
