import { useState } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useDebouncedCallback } from 'use-debounce';

import { SearchSelectItem } from '../types';

interface Props {
  dataFetcher?: (query: string) => Promise<SearchSelectItem[]>;
  name?: string;
}

type ReturnValues = UseQueryResult<SearchSelectItem[], unknown> & {
  setQuery: (query: string) => void;
  setQueryDebounced: (query: string) => void;
};

const useDataFetchQuery = ({ dataFetcher, name }: Props): ReturnValues => {
  const [query, setQuery] = useState('');
  const [setQueryDebounced] = useDebouncedCallback(setQuery, 200);

  return {
    ...useQuery<unknown, unknown, SearchSelectItem[]>(
      ['searchselect', 'data', query, name, dataFetcher],
      () => {
        if (dataFetcher) {
          return dataFetcher(query);
        }
      },
      {
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        enabled: !!dataFetcher,
      },
    ),
    setQuery,
    setQueryDebounced,
  };
};

export default useDataFetchQuery;
