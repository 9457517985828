import { createContext } from 'react';
import { ReactReduxContextValue } from 'react-redux';
import rootReducer from '../redux/reducers/rootReducer';
import store from '../redux/store';

const GlobalContext = createContext<ReactReduxContextValue>({
  store,
  storeState: rootReducer,
});
export default GlobalContext;
