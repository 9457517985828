import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMobilityBudget: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_697_3035"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_697_3035)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          d="M8 186.5c0 14.5 11 21 21 21h11.5L66 202h215.5c19 0 35.5 3.5 50 0 11.6-2.8 13.167-9.5 12.5-12.5V138c0-10.4-4.667-15.667-7-17l-37.5-33.5h-103c-28.5 0-30 0-57 18s-41 23.5-51 26S50 140 29 144.5C8 151 8 172 8 186.5z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M168.026 91.661c-6.978 2.147-13.847 6.457-27.417 15.503-27.104 18.069-41.32 23.7-51.624 26.276-8.205 2.051-28.765 6.439-47.448 10.426-4.155.886-8.217 1.753-12.03 2.57-9.726 3.038-14.535 9.346-16.985 16.696C10.017 170.647 10 179.191 10 186.5c0 6.693 2.515 11.38 6.053 14.42 3.587 3.082 8.353 4.58 12.947 4.58h11.287l25.5-5.5H281.5c6.686 0 13.112.431 19.172.839 2.611.175 5.154.346 7.621.476 8.276.435 15.784.419 22.738-1.26 5.469-1.32 8.296-3.488 9.706-5.412 1.425-1.946 1.516-3.784 1.311-4.71l-.048-.214V138c0-9.821-4.393-14.35-5.992-15.264l-.183-.105-37.088-33.132H196.5c-14.356 0-21.512.02-28.474 2.162zm28.13-6.162h104.107l37.926 33.88C341.266 121.271 346 127.251 346 138v51.299c.385 2.063-.023 4.958-2.037 7.708-2.107 2.875-5.863 5.457-11.994 6.937-7.546 1.821-15.538 1.805-23.887 1.365-2.58-.136-5.187-.311-7.833-.489-6.001-.403-12.202-.82-18.749-.82H66.213l-25.5 5.5H29c-5.406 0-11.14-1.753-15.553-5.546C8.985 200.119 6 194.306 6 186.5v-.135c0-7.171 0-16.314 2.728-24.498 2.793-8.379 8.464-15.806 19.68-19.278l.086-.026.087-.019c3.86-.827 7.97-1.704 12.17-2.601 18.682-3.987 39.135-8.351 47.264-10.384 9.695-2.424 23.48-7.793 50.376-25.724 13.43-8.953 20.811-13.644 28.458-15.997 7.601-2.339 15.38-2.339 29.307-2.338z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M29 187v20.5h18.5l18-6H103V187c0-16-19-26-27-26H59.5C40.3 161 29 176 29 187z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M59.5 163C41.368 163 31 177.14 31 187v18.499h16.175l18-5.999H101V187c0-7.154-4.25-13.135-9.733-17.425a36.043 36.043 0 00-8.43-4.912C80.064 163.53 77.645 163 76 163H59.5zM27 187c0-12.141 12.232-28 32.5-28H76c2.355 0 5.311.719 8.35 1.961a40.043 40.043 0 019.383 5.463C99.75 171.134 105 178.153 105 187v16.5H65.825l-18 5.999H27V187z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M96 199c0 16.568-13.431 30-30 30-16.569 0-30-13.432-30-30 0-16.569 13.431-30 30-30 16.569 0 30 13.431 30 30z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M66 225c14.36 0 26-11.641 26-26 0-14.36-11.64-26-26-26s-26 11.64-26 26c0 14.359 11.64 26 26 26zm0 4c16.569 0 30-13.432 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.568 13.431 30 30 30z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M83 199c0 9.388-7.611 17-17 17s-17-7.612-17-17c0-9.389 7.611-17 17-17s17 7.611 17 17z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M66 212c7.18 0 13-5.821 13-13 0-7.18-5.82-13-13-13s-13 5.82-13 13c0 7.179 5.82 13 13 13zm0 4c9.389 0 17-7.612 17-17 0-9.389-7.611-17-17-17s-17 7.611-17 17c0 9.388 7.611 17 17 17z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M318 187v16.5h-18.5l-18-2H244V187c0-16 19-26 27-26h16.5c19.2 0 30.5 15 30.5 26z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M287.5 163c18.132 0 28.5 14.14 28.5 23.999v14.5h-16.389l-18-2H246v-12.5c0-7.153 4.25-13.134 9.733-17.424a36.026 36.026 0 018.43-4.912C266.936 163.53 269.354 163 271 163h16.5zm32.5 23.999c0-12.14-12.232-27.999-32.5-27.999H271c-2.354 0-5.311.719-8.35 1.961a40.053 40.053 0 00-9.383 5.463C247.25 171.134 242 178.153 242 186.999v16.5h39.389l18 2H320v-18.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M311 199c0 16.568-13.431 30-30 30-16.569 0-30-13.432-30-30 0-16.569 13.431-30 30-30 16.569 0 30 13.431 30 30z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M281 225c14.359 0 26-11.641 26-26 0-14.36-11.641-26-26-26s-26 11.64-26 26c0 14.359 11.641 26 26 26zm0 4c16.569 0 30-13.432 30-30 0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.568 13.431 30 30 30z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M298 199c0 9.388-7.611 17-17 17s-17-7.612-17-17c0-9.389 7.611-17 17-17s17 7.611 17 17z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M281 212c7.18 0 13-5.821 13-13 0-7.18-5.82-13-13-13s-13 5.82-13 13c0 7.179 5.82 13 13 13zm0 4c9.389 0 17-7.612 17-17 0-9.389-7.611-17-17-17s-17 7.611-17 17c0 9.388 7.611 17 17 17z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M136 133.5h170.5c14.5 0 10.4-11.6 6-16-5.5-5.5-11.5-11-17-16-4.4-4-12.833-5-16.5-5h-92c-12.4 0-18.833 2.666-20.5 4C156.333 107 135 120.6 131 123c-5 3-4.5 10.5 5 10.5z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M173.206 99.94c-3.259.807-4.956 1.72-5.457 2.121l-.082.066-.09.058c-10.147 6.487-31.516 20.11-35.548 22.529-1.884 1.131-2.419 2.882-2.054 4.076.328 1.072 1.743 2.71 6.025 2.71h170.5c3.396 0 5.381-.685 6.495-1.498 1.043-.76 1.513-1.768 1.597-2.99.089-1.29-.265-2.813-.955-4.328-.684-1.504-1.633-2.852-2.551-3.77-5.463-5.463-11.431-10.934-16.931-15.935-1.834-1.667-4.658-2.815-7.648-3.535-2.951-.71-5.818-.945-7.507-.945h-92c-6.059 0-10.604.652-13.794 1.442zm-.962-3.882c3.544-.877 8.415-1.559 14.756-1.559h92c1.977 0 5.16.266 8.443 1.056 3.243.78 6.836 2.132 9.402 4.465 5.5 5 11.532 10.528 17.069 16.065 1.282 1.282 2.496 3.034 3.364 4.942.864 1.898 1.453 4.1 1.305 6.26-.154 2.228-1.102 4.395-3.231 5.947-2.058 1.5-4.998 2.266-8.852 2.266H136c-5.218 0-8.803-2.113-9.85-5.541-1.011-3.306.705-6.805 3.821-8.674 3.958-2.375 25.155-15.888 35.372-22.42 1.195-.916 3.516-1.969 6.901-2.807z"
          clipRule="evenodd"
        ></path>
        <path fill="#fff" d="M204 97h11l-9 36h-11l9-36z"></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M202.438 95h15.124l-10 40h-15.124l10-40zm3.124 4l-8 32h6.876l8-32h-6.876z"
          clipRule="evenodd"
        ></path>
        <path fill="#fff" d="M260 97h11l18 36h-11l-18-36z"></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M256.764 95h15.472l20 40h-15.472l-20-40zm6.472 4l16 32h6.528l-16-32h-6.528z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M26 165.5c-3.313 2.65-9 2.5-16.5 2.5 0-5.6 4.333-11.334 6.5-13.5h13.5c5.2 0 5.5 3.5 3.5 5s-4.5 4-7 6z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M15.172 152.5H29.5c2.953 0 5.332 1.01 6.276 3.121.93 2.081.015 4.285-1.576 5.479-.918.688-1.976 1.626-3.179 2.702l-.19.17c-1.123 1.006-2.35 2.103-3.582 3.089-2.103 1.683-4.813 2.364-7.718 2.671-2.601.276-5.624.272-8.876.268H7.5v-2c0-3.284 1.257-6.467 2.695-9.02 1.448-2.571 3.186-4.69 4.39-5.895l.587-.585zm1.688 4a25.624 25.624 0 00-3.18 4.444c-.91 1.617-1.636 3.351-1.974 5.057 2.845 0 5.29-.023 7.404-.246 2.641-.28 4.431-.85 5.64-1.817 1.144-.915 2.294-1.944 3.439-2.969l.165-.147c1.172-1.049 2.364-2.111 3.446-2.922.409-.307.344-.603.324-.647-.006-.014-.377-.753-2.624-.753H16.86z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M337 133.5h6.5v23H330c-3.2 0-3.333-2.667-3-4 1.5-4.167 4.8-13.1 6-15.5 1.2-2.4 3.167-3.334 4-3.5z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M336.608 131.538c.129-.025.26-.038.392-.038h6.5a2 2 0 012 2v23a2 2 0 01-2 2H330c-1.078 0-2.056-.226-2.885-.717a4.39 4.39 0 01-1.75-1.933c-.655-1.394-.538-2.905-.305-3.836.016-.065.035-.129.058-.192 1.477-4.102 4.826-13.183 6.093-15.717 1.493-2.987 3.997-4.287 5.397-4.567zm.651 3.962c-.451.158-1.666.786-2.47 2.394-1.123 2.246-4.327 10.899-5.867 15.171a2.437 2.437 0 00-.045.551c.008.239.054.418.108.533a.396.396 0 00.168.192c.086.051.325.159.847.159h11.5v-19h-4.241z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M343.5 144.5h-13v-4h13v4z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M142 171.236c0-29.16 26.3-68.664 55.2-82.8h30.112c28.912 14.156 55.2 53.66 55.2 82.82 0 35.636-31.472 52.704-70.276 52.704-38.804 0-70.236-17.088-70.236-52.724zM212.636 88.415s-6.372-6.876-13.152-13.904c-4.24-4.4-9.2-9.423-14.224-14.331a5.022 5.022 0 011.169-8.034 5.02 5.02 0 013.055-.526 161.955 161.955 0 0023.152 1.66c7.747 0 15.484-.554 23.152-1.66A5.02 5.02 0 01240 60.2a590.25 590.25 0 00-14.22 14.331c-6.768 7.008-13.144 13.884-13.144 13.884z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M223.7 76.704a54.04 54.04 0 016.356-4.8c8.8-4.072 12.8-2.16 14.508 1.6 1.708 3.76.548 8.027-8.236 12.043a45.4 45.4 0 01-17.464 2.86h-6.228s9.152-9.7 11.064-11.703zM212.636 88.415c2 1.756 16.112 14.801 14.96 30.221M201.576 76.704a54.333 54.333 0 00-6.356-4.8c-8.8-4.072-12.8-2.16-14.508 1.6-1.708 3.76-.548 8.027 8.232 12.043a45.411 45.411 0 0017.468 2.86h6.224s-9.148-9.7-11.06-11.703zM212.636 88.415c-2 1.756-16.108 14.801-14.956 30.221"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M230.1 188.493h-5.996a25.072 25.072 0 01-12.669-3.457 25.375 25.375 0 01-9.24-9.408h14.056a4.693 4.693 0 003.259-1.433 4.78 4.78 0 001.34-3.323 4.784 4.784 0 00-1.34-3.323 4.697 4.697 0 00-3.259-1.433h-17.195a23.23 23.23 0 010-6.233h25.048a4.69 4.69 0 003.407-1.351 4.8 4.8 0 001.051-5.253 4.759 4.759 0 00-1.051-1.557 4.712 4.712 0 00-1.568-1.023 4.672 4.672 0 00-1.839-.328h-21.909a25.377 25.377 0 019.24-9.406 25.07 25.07 0 0112.669-3.453h5.996c1.23-.03 2.4-.545 3.259-1.433a4.784 4.784 0 001.34-3.323 4.78 4.78 0 00-1.34-3.323A4.69 4.69 0 00230.1 128h-5.996a34.474 34.474 0 00-19.583 6.145 34.98 34.98 0 00-12.726 16.231h-7.967a4.69 4.69 0 00-3.407 1.352c-.45.443-.807.972-1.051 1.557a4.8 4.8 0 000 3.695 4.759 4.759 0 001.051 1.557c.45.443.983.791 1.568 1.023a4.672 4.672 0 001.839.328h5.751a35.579 35.579 0 00-.157 3.104c0 1.042.067 2.089.157 3.119h-5.751a4.672 4.672 0 00-1.839.328 4.712 4.712 0 00-1.568 1.023c-.45.443-.807.973-1.051 1.557a4.8 4.8 0 000 3.695 4.749 4.749 0 002.619 2.581 4.69 4.69 0 001.839.328h7.967a34.98 34.98 0 0012.727 16.23A34.488 34.488 0 00224.104 198h5.996a4.696 4.696 0 003.259-1.434 4.78 4.78 0 001.34-3.323 4.78 4.78 0 00-1.34-3.322 4.696 4.696 0 00-3.259-1.434v.006z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationMobilityBudget;
