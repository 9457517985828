import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusDocsAccepted: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 9a2 2 0 0 0-2 2v29a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2v-.017a4.052 4.052 0 0 0 3.383-1.323L41 37.98V40a6 6 0 0 1-6 6H15a6 6 0 0 1-6-6V11a6 6 0 0 1 6-6h12.586a3 3 0 0 1 2.121.879l10.414 10.414A3 3 0 0 1 41 18.414v7.179l-3.627 3.984-.373-.41V21h-7a4 4 0 0 1-4-4V9H15Zm20.172 8H30v-5.172L35.172 17Z"
      fill="currentColor"
    />
    <path
      d="M48.672 23.36a1.05 1.05 0 0 0-1.582 0l-9.717 10.673-4.463-4.902a1.05 1.05 0 0 0-1.582 0 1.316 1.316 0 0 0 0 1.738l5.254 5.771a1.05 1.05 0 0 0 1.582 0l10.508-11.542a1.316 1.316 0 0 0 0-1.738Z"
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default GeneralStatusDocsAccepted;
