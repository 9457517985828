import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBabysit: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_4211_10481"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4211_10481)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M268.779 82.618c-8.774 0-15.886 7.112-15.886 15.885v13.886a4.534 4.534 0 01-9.069 0V98.503c0-13.782 11.173-24.954 24.955-24.954h4.084a4.534 4.534 0 010 9.069h-4.084z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M87 112.756h165.859c-1.054 30.9-26.435 55.626-57.592 55.626H135c-26.51 0-48-21.491-48-48v-7.626z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M86.634 112.798h83.313V29.076c-15.739 7.796-23.883 10.353-41.248 11.435-8.428 14.405-17.402 20.975-30.63 30.222-1.667 18.544-4.201 27.73-11.435 42.065z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M98.07 70.732l71.877 42.065M128.699 40.512l41.248 72.286"
        ></path>
        <circle
          cx="169.947"
          cy="112.389"
          r="7.802"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <rect
          width="32.756"
          height="13.153"
          x="264.244"
          y="71.1"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="4"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M138.458 198.969c0-17.391 14.098-31.489 31.489-31.489 17.39 0 31.488 14.098 31.488 31.489v4.534h-62.977v-4.534z"
        ></path>
        <circle
          cx="136.865"
          cy="203.462"
          r="20.462"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="136.865"
          cy="203.463"
          r="9.844"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="203.029"
          cy="203.462"
          r="20.462"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="203.029"
          cy="203.463"
          r="9.844"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBabysit;
