import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersEmptyIcon: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 39c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14zm0 4c9.941 0 18-8.059 18-18S34.941 7 25 7 7 15.059 7 25s8.059 18 18 18z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersEmptyIcon;
