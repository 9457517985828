import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralRiskAnalysisPrevention: React.FC = (props) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M26.248 9.314a.911.911 0 00-.912.911V24.573a1.62 1.62 0 01-3.24 0V13.605v-.007a.912.912 0 00-1.823.003v12.66a1.616 1.616 0 01-1.472 1.613 1.63 1.63 0 01-.686-.084 1.616 1.616 0 01-.609-.383l-2.48-2.481a1.531 1.531 0 00-1.87-.23.911.911 0 00-.326 1.23c3.19 5.669 5.002 8.826 5.492 9.578l.001.002.326.498a8.507 8.507 0 007.103 3.828h3.028a8.507 8.507 0 008.507-8.507V16.977a.912.912 0 00-1.823-.005v7.6a1.62 1.62 0 01-3.24 0v-7.589-.006V13.6a.912.912 0 00-1.824 0v10.972a1.62 1.62 0 01-3.24 0V10.225a.911.911 0 00-.912-.911zm9.163 3.624a4.153 4.153 0 00-5.064-3.376 4.153 4.153 0 00-8.198 0 4.152 4.152 0 00-5.117 4.039v8.77a4.771 4.771 0 00-5.543-.454 4.152 4.152 0 00-1.484 5.597c3.152 5.603 5.035 8.89 5.6 9.757.112.172.223.343.336.514v.001a11.748 11.748 0 009.255 5.273c.068.01.137.014.208.014h3.376a11.749 11.749 0 0011.748-11.748V16.977a4.153 4.153 0 00-5.117-4.039z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralRiskAnalysisPrevention;
