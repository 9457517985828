import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralRiskObjectsCamper: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      d="M43.3956 24.5765L38.3831 23.4737L35.6262 18.4112H40.0373L42.4935 16.2056V14H5V29.5889L6.70416 32.8468H8.80941C8.90976 30.8919 10.5136 29.2878 12.5187 29.2878C14.5236 29.2878 16.1277 30.8919 16.2281 32.8468H33.1202C33.2206 30.8919 34.8244 29.2878 36.8295 29.2878C38.8344 29.2878 40.4385 30.8919 40.5389 32.8468H43.8472L45 26.5312C44.8493 26.5815 45.3003 25.1277 43.3955 24.5763L43.3956 24.5765ZM17.2805 24.4261C17.2805 24.6768 17.0298 24.9275 16.7791 24.9275H7.60627C7.35557 24.9275 7.10487 24.6768 7.10487 24.4261V22.5716C7.10487 22.3209 7.35557 22.0702 7.60627 22.0702H16.7791C17.0298 22.0702 17.2805 22.3209 17.2805 22.5716V24.4261ZM17.2805 20.7671C17.2805 21.0178 17.0298 21.2685 16.7791 21.2685H7.60627C7.35557 21.2685 7.10487 21.0178 7.10487 20.7671V18.9125C7.10487 18.6618 7.35557 18.4111 7.60627 18.4111H16.7791C17.0298 18.4111 17.2805 18.6618 17.2805 18.9125V20.7671ZM24.2978 30.7419C24.2978 31.0426 24.0471 31.2433 23.7965 31.2433H20.5385C20.2378 31.2433 20.0371 31.043 20.0371 30.7419L20.0375 18.9125C20.0375 18.6618 20.2378 18.4111 20.5389 18.4111H23.7968C24.0475 18.4111 24.2982 18.6618 24.2982 18.9125L24.2978 30.7419ZM27.9572 24.376V19.2631H34.0725L36.7291 24.426H27.9572V24.376Z"
      fill="currentColor"
    />
    <path
      d="M12.4659 30.3C10.9659 30.3 9.75777 31.4667 9.71588 32.9251V33.05C9.71588 34.5916 10.9659 35.8 12.4659 35.8C14.0075 35.8 15.2159 34.55 15.2159 33.05V32.9251C15.1743 31.4666 13.9659 30.3 12.4659 30.3Z"
      fill="currentColor"
    />
    <path
      d="M36.7263 30.3C35.2264 30.3 34.0182 31.4667 33.9763 32.9251V33.05C33.9763 34.5916 35.2264 35.8 36.7263 35.8C38.2679 35.8 39.4763 34.55 39.4763 33.05V32.9251C39.4345 31.4666 38.2263 30.3 36.7263 30.3Z"
      fill="currentColor"
    />
  </Icon>
);

export default GeneralRiskObjectsCamper;
