import React from 'react';
import { Category, Option } from '../../SearchPickerList/SearchPickerList';
import SearchPickerOption from '../../SearchPickerList/SearchPickerOption';
import { Box, Text } from '@wegroup/design-system';
import { VirtualItem } from 'react-virtual';

interface SearchPickerCategoriesProps {
  categories: Category[];
  options: Option[];
  selectedOptions: Option[];
  handleOptionClick: (option: Option) => void;
  virtualItems: VirtualItem[];
  hideIcons?: boolean;
}

const SearchPickerCategories: React.FC<
  React.PropsWithChildren<SearchPickerCategoriesProps>
> = ({
  categories,
  options,
  selectedOptions,
  handleOptionClick,
  virtualItems,
  hideIcons,
}) => {
  return (
    <>
      {categories.map((category) => {
        const filteredOptions = options.filter((o) =>
          category.option_keys.includes(o.value),
        );
        if (filteredOptions.length === 0) return null;
        return (
          <Box key={category.label}>
            <Box bg="gray.strokeAndBg" py="2" px="3">
              <Text textStyle="smallBodyMedium" color="gray.400">
                {category.label}
              </Text>
            </Box>
            {filteredOptions.map((option) => (
              <SearchPickerOption
                option={option}
                selected={selectedOptions.some((o) => o.value === option.value)}
                key={option.value}
                onClick={() => handleOptionClick(option)}
                hideIcon={hideIcons}
              />
            ))}
          </Box>
        );
      })}
    </>
  );
};

export default SearchPickerCategories;
