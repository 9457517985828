import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationDocumentsOrFiles: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M30 5a2 2 0 012 2v8h8a2 2 0 110 4h-8a4 4 0 01-4-4V7a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22 9a2 2 0 00-2 2v20a2 2 0 002 2h14a2 2 0 002-2V17.828L29.172 9H22zm-4.243-2.243A6 6 0 0122 5h8a2 2 0 011.414.586l10 10A2 2 0 0142 17v14a6 6 0 01-6 6H22a6 6 0 01-6-6V11a6 6 0 011.757-4.243z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 17a2 2 0 00-2 2v20a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 114 0v4a6 6 0 01-6 6H14a6 6 0 01-6-6V19a6 6 0 016-6h4a2 2 0 110 4h-4z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationDocumentsOrFiles;
