import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsChevronUpRounded: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M39.414 33.414a2 2 0 01-2.828 0L25 21.828 13.414 33.414a2 2 0 11-2.828-2.828l13-13a2 2 0 012.828 0l13 13a2 2 0 010 2.828z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsChevronUpRounded;
