import { Box, Textarea } from '@wegroup/design-system';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  LouiseQuestionFormValues,
  QUESTION_MAX_LENGTH,
} from '../../types/Form';
import QuestionBodyInputCount from './QuestionBodyInputCount';

interface Props {
  isDisabled?: boolean;
}

const QuestionBodyInput: React.FC<Props> = ({ isDisabled }) => {
  const { t } = useTranslation();
  const { register, watch } = useFormContext<LouiseQuestionFormValues>();

  const questionLength = watch('question')?.length || 0;

  return (
    <Box pos="relative">
      <Textarea
        {...register('question')}
        p="3"
        rows={5}
        variant="unstyled"
        maxLength={QUESTION_MAX_LENGTH}
        resize="none"
        placeholder={t(
          'TOOLKIT_INSURANCE-TERMS-BOT_QUESTION-INPUT_PLACEHOLDER',
        )}
        py="0"
        isDisabled={isDisabled}
        _disabled={{
          opacity: 'unset',
          bg: 'gray.ultraLight',
          color: 'gray.400',
        }}
      />
      <Box pos="absolute" right="4" bottom="1">
        <QuestionBodyInputCount
          isDisabled={isDisabled}
          currentLength={questionLength}
          maxLength={QUESTION_MAX_LENGTH}
        />
      </Box>
    </Box>
  );
};

export default QuestionBodyInput;
