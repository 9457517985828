import React from 'react';
import {
  Center,
  Card,
  VStack,
  Icon,
  Circle,
  Text,
} from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
}

const AnnexInputAddButton: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Card
      as={Center}
      flexGrow="1"
      bg="white"
      borderStyle="dashed"
      cursor="pointer"
      _hover={{ borderColor: 'primary.500' }}
      onClick={onClick}
      transition="0.2s"
      h="40"
    >
      <VStack spacing="3">
        <Circle p="1" bg="primary.50" color="primary.500">
          <Icon name="GeneralActionsAddPlusCircle" />
        </Circle>
        <Text>{t('Add annex')}</Text>
      </VStack>
    </Card>
  );
};

export default AnnexInputAddButton;
