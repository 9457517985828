import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleABS: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 12.5c-7.456 0-13.5 6.044-13.5 13.5S17.544 39.5 25 39.5 38.5 33.456 38.5 26 32.456 12.5 25 12.5zM8.5 26c0-9.113 7.387-16.5 16.5-16.5S41.5 16.887 41.5 26 34.113 42.5 25 42.5 8.5 35.113 8.5 26z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.565 9.33a1.5 1.5 0 01-.008 2.122A20.432 20.432 0 004.5 26a20.432 20.432 0 006.057 14.548 1.5 1.5 0 01-2.114 2.129A23.432 23.432 0 011.5 26c0-6.52 2.657-12.421 6.943-16.677a1.5 1.5 0 012.122.008zm28.87 0a1.5 1.5 0 012.122-.007A23.432 23.432 0 0148.5 26c0 6.52-2.657 12.421-6.943 16.677a1.5 1.5 0 11-2.114-2.129A20.432 20.432 0 0045.5 26a20.432 20.432 0 00-6.057-14.548 1.5 1.5 0 01-.008-2.121z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M19.732 30h1.8l-3.24-8.508h-1.92L13.108 30h1.752l.708-1.944h3.456L19.732 30zm-2.436-6.672l1.176 3.216H16.12l1.176-3.216zM22.644 21.492V30h3.288c1.596 0 2.628-.996 2.628-2.376 0-1.056-.672-1.812-1.5-2.016.708-.24 1.236-.948 1.236-1.824 0-1.32-.936-2.292-2.592-2.292h-3.06zm1.632 3.516v-2.124h1.164c.804 0 1.224.408 1.224 1.056 0 .648-.456 1.068-1.212 1.068h-1.176zm0 3.612v-2.244h1.368c.828 0 1.26.468 1.26 1.14 0 .684-.48 1.104-1.284 1.104h-1.344zM35.822 23.52c-.192-.96-.984-2.208-3.048-2.208-1.608 0-2.964 1.152-2.964 2.664 0 1.284.876 2.16 2.232 2.436l1.188.24c.66.132 1.032.516 1.032 1.008 0 .6-.492 1.044-1.38 1.044-1.2 0-1.788-.756-1.86-1.596l-1.536.408c.132 1.212 1.128 2.664 3.384 2.664 1.98 0 3.072-1.32 3.072-2.628 0-1.2-.828-2.208-2.376-2.508l-1.188-.228c-.624-.12-.924-.492-.924-.96 0-.564.528-1.08 1.332-1.08 1.08 0 1.464.732 1.548 1.2l1.488-.456z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleABS;
