import React from 'react';
import { Text } from '@wegroup/design-system';
import { useSelector } from 'react-redux';
import { RootState } from '../../../common/src/store/redux/reducers/rootReducer';
import cogoToast from 'cogo-toast';

interface Props {
  left?: string;
  right?: string;
  top?: string;
  bottom?: string;
}

const SessionRecordingUrOverlay: React.FC<React.PropsWithChildren<Props>> = ({
  left,
  right,
  top,
  bottom,
}) => {
  const url = useSelector(
    (store: RootState) => store.generalData.sessionRecordingUrl,
  );

  const handleClick = () => {
    navigator.clipboard.writeText(url?.split('?')[0] + `?t=${Date.now()}`);
    cogoToast.success('Session URL copied to clipboard!');
  };

  return url ? (
    <Text
      display={{ base: 'none', laptop: 'inline' }}
      fontSize="10px"
      position="fixed"
      color="gray"
      opacity="0.3"
      _hover={{
        opacity: '0.5',
        textDecoration: 'underline',
      }}
      zIndex="999999"
      mixBlendMode="difference"
      cursor="pointer"
      onClick={handleClick}
      {...{ left, right, top, bottom }}
    >
      {url.split('/').slice(-1) || ''}
    </Text>
  ) : null;
};

export default SessionRecordingUrOverlay;
