import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersSessions: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.464 4.666a20.833 20.833 0 0125.332 21.56 2.083 2.083 0 01-4.159-.245 16.668 16.668 0 10-13.985 15.473 2.083 2.083 0 01.663 4.113 20.834 20.834 0 01-7.85-40.901zM25 12.5c1.15 0 2.083.933 2.083 2.083v9.554l5.64 5.64a2.083 2.083 0 01-2.946 2.946l-6.25-6.25c-.39-.39-.61-.92-.61-1.473V14.583c0-1.15.932-2.083 2.083-2.083zm15.739 19.1a2.083 2.083 0 01.578 2.889L39.309 37.5h4.441a2.083 2.083 0 011.733 3.239l-4.166 6.25a2.083 2.083 0 11-3.467-2.311l2.007-3.011h-4.44a2.083 2.083 0 01-1.734-3.24l4.167-6.25a2.083 2.083 0 012.889-.577z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersSessions;
