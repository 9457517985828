export const LOUISE_CHAT_EXAMPLES_NL = [
  {
    insuranceCompany: 'de_goudse',
    subject: '874ebc0f-7767-42db-98f8-bdbd18231481',
    question: 'TOOLKIT_LOUISEQA_INTRO_EXAMPLE_GARDEN-FURNI',
  },
  {
    insuranceCompany: 'interpolis',
    subject: 'fbb19deb-5e22-42d8-bd1d-515ba7ce1a46',
    question: 'TOOLKIT_LOUISEQA_INTRO_EXAMPLE_CAR-REPAIR',
  },
  {
    insuranceCompany: 'lancyr',
    subject: '2253c141-e312-4306-851a-9dda433a465a',
    question: 'TOOLKIT_LOUISEQA_INTRO_EXAMPLE_JEWELRY-INSURED',
  },
];
