import { Box, Button, Text, VStack } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPageSVG from '../assets/images/ErrorPageSvg';
import WeGroupLogo from '../assets/WeGroupLogo';
import GeneralPageLayout from './GeneralPageLayout';
import { Link } from 'react-router-dom';

const RouteNotFoundPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <GeneralPageLayout h="100%">
      <VStack h="100%">
        <Box as={Link} w="80" cursor="pointer" to="/dashboard">
          <WeGroupLogo />
        </Box>

        <VStack justify="center" flexGrow={1} pb="32" spacing="10">
          <ErrorPageSVG />
          <VStack spacing="2">
            <Text textStyle="h3Headline">
              {t('COMMON_PAGE-NOT-FOUND_TITLE')}
            </Text>
            <Button as={Link} variant="ghost" to="/dashboard">
              {t('BACK-TO-DASHBOARD')}
            </Button>
          </VStack>
        </VStack>
      </VStack>
    </GeneralPageLayout>
  );
};

export default RouteNotFoundPage;
