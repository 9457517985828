import { Box, SimpleGrid, Text, TextProps } from '@wegroup/design-system';
import React from 'react';

interface Props {
  textStyle?: TextProps['textStyle'];
  title?: string;
  rightElement?: JSX.Element;
}

const CardHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  textStyle = 'normalBodyMedium',
  rightElement,
}) => {
  return (
    <SimpleGrid columns={2}>
      {title && (
        <Text justifySelf="flex-start" textStyle={textStyle}>
          {title}
        </Text>
      )}
      {rightElement && <Box justifySelf="flex-end">{rightElement}</Box>}
    </SimpleGrid>
  );
};

export default CardHeader;
