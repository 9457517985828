import React, { useState } from 'react';
import { Box, BoxProps } from '@wegroup/design-system';
import { Document, Page, pdfjs } from 'react-pdf';
import PdfViewerZoomControls from './PdfViewerZoomControls';
import LoadingScreen from '../../../../../common/src/components/LoadingScreen';
import { useTranslation } from 'react-i18next';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs?.version}/pdf.worker.js`;

const pdfStyling = {
  '.react-pdf__Page': {
    mb: '4',
    ':last-of-type': {
      mb: '0',
    },
  },
  '.react-pdf__Page__canvas': {
    margin: '0 auto',
    width: 'unset !important',
    overflow: 'hidden',
  },
};

interface Props {
  file: string;
  isLoading?: boolean;
  minZoom?: number;
  maxZoom?: number;
  isZoomDisabled?: boolean;
  onPdfLoaded?: (pdf: pdfjs.PDFDocumentProxy) => void;
}

const PdfViewer: React.FC<Props & BoxProps> = ({
  file,
  isLoading,
  isZoomDisabled,
  minZoom,
  maxZoom,
  onPdfLoaded = () => null,
  ...props
}) => {
  const { t } = useTranslation();
  const [numPages, setNumPages] = useState<number>(0);
  const [zoom, setZoom] = useState<number>(1);

  const handleLoadSuccess = (pdf: pdfjs.PDFDocumentProxy) => {
    setNumPages(pdf?.numPages);
    onPdfLoaded(pdf);
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <Box position="relative" w="100%" h="100%" bg="gray.strokeAndBg" {...props}>
      {!isZoomDisabled && (
        <PdfViewerZoomControls
          value={zoom}
          onChange={setZoom}
          min={minZoom}
          max={maxZoom}
        />
      )}
      <Box sx={pdfStyling} w="100%" h="100%" overflow="auto" p="5">
        <Document
          file={file}
          onLoadSuccess={handleLoadSuccess}
          loading={<LoadingScreen />}
          onLoadError={(error) => console.error('react-pdf error', error)}
        >
          {[...Array(numPages)].map((_, index) => (
            <Page
              loading={t('LOADING')}
              scale={zoom}
              key={index}
              pageNumber={index + 1}
            />
          ))}
        </Document>
      </Box>
    </Box>
  );
};

export default PdfViewer;
