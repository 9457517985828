import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSculptures: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M302.831 225.404h-39.542v-66.431a3.164 3.164 0 0 1 3.163-3.163h33.215a3.164 3.164 0 0 1 3.164 3.163v66.431ZM294.922 146.32h-23.725a3.165 3.165 0 0 0-3.163 3.164v6.326h30.052v-6.326a3.165 3.165 0 0 0-3.164-3.164Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M274.36 166.882h17.399v7.908H274.36v-7.908Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.502 97.398a17.398 17.398 0 1 0 34.796 0 17.398 17.398 0 0 0-34.796 0Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M259.502 97.398a17.398 17.398 0 1 0 34.796 0 17.398 17.398 0 0 0-34.796 0v0Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M279.105 122.594a11.072 11.072 0 1 0 22.144 0 11.072 11.072 0 0 0-22.144 0Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M279.105 122.594a11.072 11.072 0 1 0 22.144 0 11.072 11.072 0 0 0-22.144 0Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M277.524 139.993a6.324 6.324 0 0 0 10.8 4.473 6.326 6.326 0 1 0-10.8-4.473Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M277.524 139.993a6.324 6.324 0 0 0 10.8 4.473 6.326 6.326 0 1 0-10.8-4.473Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M291.569 108.021a4.746 4.746 0 1 0 9.491 0 4.746 4.746 0 0 0-9.491 0Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m233.813 131.108-11.861 66.04h-6.572l-15.869-59.949c-1.108-4.568-1.133-7.129.321-11.701l4.327-12.182c1.484-3.692 1.579-5.796.802-9.617l-3.526-16.03c-1.122-6.25 2.848-9.075 9.617-8.174 4.136.186 5.059-.505 3.847-3.366-8.015-15.708 0-29.137 12.502-24.845 9.297 3.191 9.778 17.953-2.564 26.608-3.654 2.148-3.596 3.278 1.122 5.13 5.397 2.656 6.123 4.97 3.687 10.418l-5.771 11.701c-2.096 3.49-1.504 5.152 2.084 7.694 7.181 5.371 8.893 9.375 7.854 18.273Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M181.879 201.621a8 8 0 0 1 8-8h53.245a8 8 0 0 1 8 8V226h-69.245v-24.379Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M154.52 185.199a53.022 53.022 0 0 1 3.56-51.288l3.76-5.724a5.226 5.226 0 0 0-.332-6.196l-22.032-26.8H87.908l-22.036 26.8a5.244 5.244 0 0 0-.332 6.2l3.76 5.72a53.032 53.032 0 0 1 3.548 51.288h81.672Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M128.004 158.7v-11.2h-28.64v11.2a9.679 9.679 0 0 1-6.772 9.5l-13.26 3.636a20.494 20.494 0 0 0-14.336 20.14v34.048h97.376v-34.036a20.496 20.496 0 0 0-14.34-20.14l-13.256-3.648a9.68 9.68 0 0 1-6.772-9.5Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M143.348 207.188h22.332a7.68 7.68 0 0 1 7.676 7.68v7.168a4 4 0 0 1-4 4H139.68a4 4 0 0 1-4-4v-7.168a7.68 7.68 0 0 1 7.668-7.68Zm-81.668 0h22.336a7.68 7.68 0 0 1 7.68 7.68v7.168a4 4 0 0 1-4 4H58a4 4 0 0 1-4-4v-7.168a7.68 7.68 0 0 1 7.68-7.68ZM113.68 155.4c-14.236 0-25.776-13.484-25.776-30.116v-30.1a33.463 33.463 0 0 1 51.552 0V125.3c.004 16.632-11.536 30.1-25.776 30.1Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationSculptures;
