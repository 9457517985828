import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyNaturalDisasters: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M24.25 34.42h-8.67a.53.53 0 01-.4-.88l11.47-13.21a.53.53 0 01.91.49l-2.38 8.73a.53.53 0 00.51.67h8.67a.53.53 0 01.39.89L22.53 44.2a.53.53 0 01-.88-.54l3.09-8.53a.519.519 0 00-.49-.71z"
    ></path>
    <path
      fill="currentColor"
      d="M42.46 25.34a1.05 1.05 0 01-1 .65H28.34l1.26-4.61a2.56 2.56 0 00-.44-2.3 2.65 2.65 0 00-4.11-.14l-6.11 7h-10A1.06 1.06 0 018 25.5a7.3 7.3 0 01-1-3.74 7.4 7.4 0 018.27-7.36 10.58 10.58 0 0120.11 2.19c.38-.071.764-.111 1.15-.12a6.22 6.22 0 016.32 5 6.34 6.34 0 01-.39 3.87z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyNaturalDisasters;
