import React from 'react';
import { Button, useTheme } from '@wegroup/design-system';
import { ButtonProps } from '@chakra-ui/react';
import { useBoolean } from '@chakra-ui/hooks';
import Styling, { Variants } from './variants';

export interface WideButtonProps {
  variant?: Variants;
  children?: Node | string;
}

const WideButton: React.FC<
  React.PropsWithChildren<WideButtonProps & ButtonProps>
> = ({ children, variant = 'solid', ...otherProps }) => {
  const { colors } = useTheme();
  const [hover, setHover] = useBoolean();
  let buttonProps = otherProps as ButtonProps;

  const iconColors = {
    solid: { base: colors.primary[500], hover: colors.primary[500] },
    dashed: { base: colors.gray[400], hover: colors.primary[500] },
  };

  // seems a bit dirty but this is the only way to edit the props of an icon. (object is read-only)
  if (buttonProps && buttonProps.leftIcon) {
    buttonProps = {
      ...buttonProps,
      leftIcon: {
        ...buttonProps.leftIcon,
        props: {
          ...buttonProps.leftIcon.props,
          color: hover ? iconColors[variant].hover : iconColors[variant].base,
        },
      },
    };
  }

  if (buttonProps && buttonProps.rightIcon) {
    buttonProps = {
      ...buttonProps,
      rightIcon: {
        ...buttonProps.rightIcon,
        props: {
          ...buttonProps.rightIcon.props,
          color: hover ? iconColors[variant].hover : iconColors[variant].base,
        },
      },
    };
  }

  return (
    <Button
      onMouseEnter={setHover.on}
      onMouseLeave={setHover.off}
      {...Styling.baseStyle}
      {...Styling.variants[variant]}
      {...buttonProps}
      sx={{ '>span>svg>path': { transition: '0.25s' } }}
    >
      {children}
    </Button>
  );
};

export default WideButton;
