import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCatamaran: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M191.685 170.799V23.755"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M237.606 175.854v3.214c0 8.837-7.164 16-16 16h-59.605c-8.837 0-16-7.163-16-16v-3.214c0-8.837 7.163-16 16-16h59.605c8.836 0 16 7.163 16 16Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M279.007 220v-29.401a6 6 0 0 0-6-6h-149.61c-29.515 0-39.645 12.69-44.896 34.333-.885 3.649 1.97 7.068 5.725 7.068h188.781a6 6 0 0 0 6-6Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M191.685 26.41v101.892a3 3 0 0 1-3 3H134.85c-5.21 0-9.029-4.918-7.664-9.946 13.779-50.781 30.41-81.51 54.297-100.304 4.284-3.371 10.202-.093 10.202 5.358Z"
          fill="#fff"
        />
        <path
          d="m127.186 121.356 1.93.524-1.93-.524Zm54.297-100.304 1.236 1.571-1.236-1.571Zm12.202 107.25V26.411h-4v101.891h4Zm-5 1H134.85v4h53.835v-4Zm-59.569-7.422c13.741-50.638 30.223-80.861 53.603-99.257l-2.473-3.143c-24.394 19.192-41.172 50.428-54.991 101.352l3.861 1.048Zm5.734 7.422c-3.913 0-6.748-3.687-5.734-7.422l-3.861-1.048c-1.715 6.321 3.089 12.47 9.595 12.47v-4Zm58.835-102.891c0-6.876-7.654-11.483-13.439-6.931l2.473 3.143c2.783-2.19 6.966-.24 6.966 3.787h4Zm-4 101.891a1 1 0 0 1-1 1v4a5 5 0 0 0 5-5h-4Z"
          fill={colors.primary[500]}
        />
        <mask
          id="b"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={124}
          y={17}
          width={70}
          height={117}
        >
          <path
            d="M191.685 26.41v101.892a3 3 0 0 1-3 3H134.85c-5.21 0-9.029-4.918-7.664-9.946 13.779-50.781 30.41-81.51 54.297-100.304 4.284-3.371 10.202-.093 10.202 5.358Z"
            fill={colors.primary[500]}
            stroke={colors.primary[500]}
            strokeWidth={4}
          />
        </mask>
        <g mask="url(#b)">
          <path
            fill={colors.primary[200]}
            stroke={colors.primary[500]}
            strokeWidth={4}
            d="M136.818 55.973h63.574v23.6h-63.574zM113.5 105.463h91.65v28.359H113.5z"
          />
          <path
            d="m127.186 121.356-1.931-.524 1.931.524Zm66.499 6.946V26.411h-4v101.891h4Zm-5 1H134.85v4h53.835v-4Zm-59.569-7.422c13.741-50.638 30.223-80.861 53.603-99.257l-2.473-3.143c-24.394 19.192-41.172 50.428-54.991 101.352l3.861 1.048Zm5.734 7.422c-3.913 0-6.748-3.687-5.734-7.422l-3.861-1.048c-1.715 6.321 3.089 12.47 9.595 12.47v-4Zm58.835-102.891c0-6.876-7.654-11.483-13.439-6.931l2.473 3.143c2.783-2.19 6.966-.24 6.966 3.787h4Zm-4 101.891a1 1 0 0 1-1 1v4a5 5 0 0 0 5-5h-4Z"
            fill={colors.primary[500]}
          />
        </g>
        <path
          d="M152.663 201.493h11.897M185.499 201.493h11.896M218.333 201.493h11.897"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationCatamaran;
