import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsViewGuide: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 14.695v21.893c4.3-.708 7.683-.652 12 .03v-21.87c-4.334-.916-7.766-1.056-12-.053zm16 .054v21.87c4.317-.683 7.7-.739 12-.031V14.695c-4.234-1.003-7.666-.863-12 .054zm-2-3.653c-5.844-1.356-10.436-1.61-16.548.131A2 2 0 007 13.151V39a2 2 0 002.412 1.957c5.762-1.212 9.28-1.126 15.213.008.248.047.502.047.75 0 5.933-1.134 9.45-1.22 15.213-.008A2 2 0 0043 39V13.15a2 2 0 00-1.452-1.923C35.436 9.487 30.844 9.74 25 11.096z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsViewGuide;
