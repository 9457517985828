import { Center, Box, BoxProps } from '@wegroup/design-system';
import React from 'react';

import '../../../lib/flag-icons.css';

interface Props {
  country?: string;
  square?: boolean;
}

const Flag: React.FC<React.PropsWithChildren<Props & BoxProps>> = ({
  country,
  square = false,
  ...otherProps
}) => {
  return (
    <Center>
      <Box
        w="6"
        h="6"
        className={`fib fi-${country?.toLowerCase()} fis`}
        borderRadius={square ? 'none' : 'full'}
        bg="gray.50"
        border="1px"
        borderColor="gray.300"
        {...otherProps}
      />
    </Center>
  );
};

export default Flag;
