import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersWriting: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M32.53 12.294a6.191 6.191 0 016.235-6.235A6.191 6.191 0 0145 12.294v25.412a2 2 0 01-.586 1.414l-4.235 4.235a2 2 0 01-2.829 0l-4.235-4.235a2 2 0 01-.586-1.414V12.294zm6.235-2.235c-1.27 0-2.236.966-2.236 2.235v24.583l2.236 2.236L41 36.877V12.294c0-1.27-.966-2.235-2.235-2.235z"
    />
    <path
      fill="currentColor"
      d="M32.53 16.53a2 2 0 012-2H43a2 2 0 110 4h-8.47a2 2 0 01-2-2zM11.353 25a2 2 0 012-2h6.353a6.235 6.235 0 110 12.47h-8.47a2.235 2.235 0 000 4.471h27.529a2 2 0 110 4h-27.53a6.236 6.236 0 010-12.47h8.47a2.235 2.235 0 000-4.471h-6.352a2 2 0 01-2-2z"
    />
  </Icon>
);

export default GeneralOthersWriting;
