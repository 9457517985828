import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsReset: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M18.16 13.264c-3.272 1.89-5.644 4.94-6.793 8.52l-.038.133-1.213-2.102c-.534-.925-1.746-1.235-2.693-.688-.948.547-1.286 1.752-.752 2.677l3.884 6.726c.534.925 1.746 1.235 2.693.688l6.028-3.48c.948-.547 1.286-1.752.752-2.677s-1.746-1.234-2.694-.687l-2.41 1.392c.026-.352.187-.669.262-.936.8-2.593 2.505-4.81 4.916-6.202 5.081-2.934 11.545-1.284 14.41 3.677 1.456 2.523 1.793 5.692.783 8.517-.849 2.508-2.565 4.508-4.804 5.8-2.583 1.492-5.49 1.825-8.232 1.054a2.05 2.05 0 00-2.46 1.308c-.3 1.07.282 2.08 1.273 2.404 3.819 1.046 7.868.502 11.313-1.486 3.1-1.79 5.483-4.624 6.595-8.068 1.31-3.896.918-8.154-1.121-11.686-3.835-6.643-12.723-8.911-19.699-4.884z"
    ></path>
  </Icon>
);

export default GeneralActionsReset;
