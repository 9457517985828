import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsLegalInjury: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M18.45 23l-5.18 19H11a1 1 0 01-1-1v-9a9 9 0 018.45-9zM34 41v1h-3.27l-1.36-5H30a4 4 0 014 4z"
    ></path>
    <path
      fill="currentColor"
      d="M40 32v9a1 1 0 01-1 1h-2v-1a7 7 0 00-7-7h-1.45l-3-11H31a9 9 0 019 9zM24.58 10.1L20 9.06A7 7 0 0125 7a6.89 6.89 0 013.23.79l-3.65 2.31zM31.61 11.69l-2.9-.69 1.91-1.21a6.65 6.65 0 01.99 1.9zM31.94 14.84A7 7 0 0118 14a6.88 6.88 0 01.37-2.24l13.57 3.08z"
    ></path>
  </Icon>
);

export default RiskObjectsLegalInjury;
