export const isSocialLoginUrl = Boolean(
  window.location.href.match(/\.campaigns|conversations|partners\./) ||
    window.location.pathname === '/louise',
);

export const isStorybookUrl = Boolean(
  window.location.href.match(/\.components\./),
);

export const isCampaignsUrl = Boolean(
  window.location.href.match(/\.campaigns\.|\.partners\./),
);

export const isConversationUrl = Boolean(
  window.location.href.match(/conversations\./),
);

export const isLouiseQAUrl = window.location.pathname === '/louise';

export const isSocialNLDomain =
  isSocialLoginUrl && Boolean(window.location.href.match(/\.nl\b/));
