const sidefishBlue = {
  primary: {
    main: '#00418E',
    50: '#B9D9FF',
    100: '#98C7FF',
    150: '#77B5FF',
    200: '#56A3FF',
    300: '#147FFF',
    400: '#005FD0',
    500: '#00418E',
    600: '#003575',
    700: '#002A5B',
    750: '#00244E',
    800: '#001E42',
    850: '#001835',
    900: '#001228',
  },
  secondary: {
    main: '#A5C6D5',
    50: '#F3F8FA',
    100: '#EBF2F5',
    150: '#E2EDF1',
    200: '#D9E7ED',
    300: '#C8DCE5',
    400: '#B6D1DD',
    500: '#A5C6D5',
    600: '#74A7BE',
    700: '#4B86A0',
    750: '#3F7188',
    800: '#345D70',
    850: '#294957',
    900: '#1D353F',
  },
};

export default sidefishBlue;
