const lightGreen = {
  primary: {
    main: '#BAD055',
    50: '#FCFDF7',
    100: '#F4F8E5',
    150: '#EDF3D3',
    200: '#E6EEC1',
    300: '#D7E49D',
    400: '#C9DA79',
    500: '#BAD055',
    600: '#A5BE34',
    700: '#829629',
    750: '#718224',
    800: '#5F6E1E',
    850: '#4E5A19',
    900: '#3D4613',
  },
  secondary: {
    main: '#88AB4D',
    50: '#F0F5E9',
    100: '#E5EDD7',
    150: '#D9E5C5',
    200: '#CEDDB4',
    300: '#B7CD91',
    400: '#A0BD6E',
    500: '#88AB4D',
    600: '#69843C',
    700: '#4A5E2A',
    750: '#3B4A21',
    800: '#2C3719',
    850: '#1C2410',
    900: '#0D1007',
  },
};

export default lightGreen;
