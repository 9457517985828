import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleSavings: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M45.67 18.82A2.08 2.08 0 0044 20.9v.87a1 1 0 01-1 1h-.53a11.5 11.5 0 00-11-8H24.6l-.46-1.14a12 12 0 00-3.51-4.83l-2.29-1.9a.62.62 0 00-1 .6l.34 1.67a8.41 8.41 0 010 3.24l-.58 2.89A11.54 11.54 0 009.85 22l-5.42.78a.49.49 0 00-.43.49v7.14a.49.49 0 00.43.49l5.92.85A11.56 11.56 0 0016 36.87v5.42a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-4.5h8v4.5a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-6.5a11.47 11.47 0 005-9 5.001 5.001 0 005-5v-1a2 2 0 00-2.33-1.97zM30.08 29.73a4.47 4.47 0 01-3.64 2.06 4.78 4.78 0 01-4.28-3.25H21a1 1 0 010-1.92h.73a3.872 3.872 0 010-.58H21a1 1 0 010-1.92h.9c.62-2.46 2.49-4.17 4.58-4.17a4.37 4.37 0 013.47 1.83.941.941 0 01.19.71.93.93 0 01-.36.63 1 1 0 01-.71.2 1 1 0 01-.64-.37 2.489 2.489 0 00-1.95-1.08 3 3 0 00-2.59 2.25h2.94a1 1 0 010 1.92h-3.27c0 .19 0 .39.05.58h.68a1 1 0 010 1.92 2.6 2.6 0 002.13 1.33 2.531 2.531 0 002.06-1.23 1 1 0 01.62-.4.999.999 0 011 1.49h-.02z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleSavings;
