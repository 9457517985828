import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsLegalCertificate: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path fill="currentColor" d="M25 27a7 7 0 100-14 7 7 0 000 14z"></path>
    <path
      fill="currentColor"
      d="M26.33 7.07a13 13 0 00-8.39 23.84V44L25 40l7.06 4V30.91a13 13 0 00-5.73-23.84zM25 30a10 10 0 110-20 10 10 0 010 20z"
    ></path>
  </Icon>
);

export default RiskObjectsLegalCertificate;
