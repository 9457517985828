import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Enterprise } from '../../../../../types/ProductFactory';
import { WelcomeData } from '../../../types/formTypes';

interface CompanyScanController {
  /** The party that is currently used for the flow */
  partyId?: string;
  /** Manually set company name */
  companyName?: string;
  /** The selected company for the flow */
  selectedCompany?: Enterprise;
  /** Data that is filled in on the initial page of company scan */
  welcomeData?: WelcomeData;
  /** This can be used to store a group id that the page needs to scroll to once it is rendered */
  queuedScrollId?: string;
}

const initialState = {};

const companyScanSlice = createSlice({
  name: 'companyScanSlice',
  initialState: initialState as CompanyScanController,
  reducers: {
    setPartyId(state, action: PayloadAction<string | undefined>) {
      state.partyId = action.payload;
    },
    setCompanyName(state, action: PayloadAction<string | undefined>) {
      state.companyName = action.payload;
    },
    setSelectedCompany(state, action: PayloadAction<Enterprise | undefined>) {
      state.selectedCompany = action.payload;
    },
    setWelcomeData(state, action: PayloadAction<WelcomeData>) {
      state.welcomeData = action.payload;
    },
    setQueuedScrollId(state, action: PayloadAction<string>) {
      state.queuedScrollId = action.payload;
    },
    resetCompanyScan() {
      return initialState;
    },
  },
});

export const {
  resetCompanyScan,
  setPartyId,
  setCompanyName,
  setSelectedCompany,
  setWelcomeData,
  setQueuedScrollId,
} = companyScanSlice.actions;

export default companyScanSlice.reducer;
