import React, { useEffect } from 'react';
import { setIsLoadingApp } from '../../../common/src/store/redux/slices/generalDataSlice';
import { useGlobalDispatch } from '../../../common/src/utils/globalVariables';
import usePosthogInit from '../hooks/usePosthogInit';

let didInit = false;

// This component should be triggered when the app is fully loaded
// The loading overlay will stop showing up in that case
const AppLoadingDone: React.FC = () => {
  const globalDispatch = useGlobalDispatch();

  usePosthogInit();

  useEffect(() => {
    if (!didInit) {
      didInit = true;
      globalDispatch(setIsLoadingApp(false));
    }
  }, []);

  return null;
};

export default AppLoadingDone;
