const FormLabel = {
  baseStyle: {
    color: 'gray.main',
    lineHeight: 1,
    fontSize: 'sm',
    fontWeight: 'regular',
  },
};

export default FormLabel;
