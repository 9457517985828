import { useQuery } from 'react-query';
import { queryClient } from '../../../providers/queryClient';

export interface Manifest {
  short_name: string;
  name: string;
  icons: [
    {
      src: string;
      type: string;
      sizes: string;
    },
    {
      src: string;
      type: string;
      sizes: string;
    },
  ];
  start_url: string;
  display: string;
  theme_color: string;
  background_color: string;
  version: string;
}

export const getManifestFromQCache = (): Manifest | undefined => {
  const manifest = queryClient.getQueryData<Manifest>(getUseManifestQKey());
  return manifest;
};

const getManifest = async (): Promise<Manifest> => {
  // We are using fetch here since the fetch is on ourselves not on any API
  // We also do not allow this request to be fetched so that we can always
  // check the latest version of this file.
  const resp = await fetch('/manifest.json', { cache: 'no-store' });
  return resp.json();
};

export const getUseManifestQKey = () => ['user', 'manifest'];

export const useManifest = (refetchInterval?: number) => {
  return useQuery(getUseManifestQKey(), getManifest, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval,
  });
};
