import React, { ChangeEvent, FocusEvent } from 'react';
import {
  Box,
  HStack,
  Input,
  Icon,
  Spinner,
  StackProps,
  ColorProps,
} from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';

export interface SearchInputProps {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  value?: string;
  placeholder?: string;
  isLoading?: boolean;
  iconColor?: ColorProps['color'];
  'data-test-id'?: string;
}

const SearchInput: React.FC<
  React.PropsWithChildren<
    Omit<StackProps, keyof SearchInputProps> & SearchInputProps
  >
> = ({
  onChange,
  onBlur,
  onFocus,
  value = '',
  placeholder,
  isLoading,
  iconColor,
  'data-test-id': dataTestId,
  ...otherProps
}) => {
  const { t } = useTranslation();
  return (
    <HStack {...otherProps}>
      {isLoading ? (
        <Box mx="1.5" mt="0.5">
          <Spinner color="primary.500" />
        </Box>
      ) : (
        <Icon name="IconSearch" color={iconColor || 'black'} />
      )}
      <Input
        border="none"
        placeholder={placeholder || `${t('Enter a search term')}...`}
        _placeholder={{ color: 'gray.600' }}
        onBlur={onBlur}
        onFocus={onFocus}
        value={value}
        onChange={onChange}
        paddingX="1"
        data-test-id={dataTestId}
      />
    </HStack>
  );
};

export default SearchInput;
