import {
  LOCAL_STORAGE_OAUTH_EVENT_NAME,
  LOCAL_STORAGE_OAUTH_TOKENS_KEY,
} from '../constants/Jwt';
import { JwtData, OAuthTokens } from '../types/OAuth';

export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const getJwtToken = (): JwtData | undefined => {
  const accessToken = getTokens()?.accessToken;
  return accessToken ? parseJwt(accessToken) : undefined;
};

export const getTokens = (): OAuthTokens | undefined => {
  const storageItem = localStorage.getItem(LOCAL_STORAGE_OAUTH_TOKENS_KEY);
  if (storageItem) return JSON.parse(storageItem);
};

export const setTokens = ({
  accessToken,
  refreshToken,
  idToken,
}: OAuthTokens) => {
  localStorage.setItem(
    LOCAL_STORAGE_OAUTH_TOKENS_KEY,
    JSON.stringify({
      accessToken,
      refreshToken,
      idToken,
    }),
  );
  window.dispatchEvent(new Event(LOCAL_STORAGE_OAUTH_EVENT_NAME));
};

export const clearTokens = () => {
  localStorage.removeItem(LOCAL_STORAGE_OAUTH_TOKENS_KEY);
  window.dispatchEvent(new Event(LOCAL_STORAGE_OAUTH_EVENT_NAME));
};

export const setSocialLoginToken = (token: string) =>
  setTokens({ accessToken: token });

export const waitForEvent = (event: string) => {
  return new Promise((resolve) => {
    const listener = () => {
      window.removeEventListener(event, listener);
      resolve(true);
    };
    window.addEventListener(event, listener);
  });
};
