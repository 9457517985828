import { parse, stringify } from 'query-string';
import { useHistory } from 'react-router';

interface QueryParams {
  [param: string]: string | undefined;
}

const useSetQueryParam = (): ((extraParamsObject: QueryParams) => void) => {
  const history = useHistory();

  return (extraParamsObject: QueryParams) => {
    const paramsObject = parse(window.location.search);
    const newParamsObject = {
      ...paramsObject,
      ...extraParamsObject,
    };

    const queryParamsString = stringify(newParamsObject);
    history.replace({
      pathname: window.location.pathname,
      search: queryParamsString,
    });
  };
};

export const setQueryParam = (
  url: string,
  extraParams: QueryParams | string,
): string => {
  const href = url.split('?')[0];
  const search = url.split('?')[1];

  const paramsObject = parse(search);
  let extraParamsObject;
  if (typeof extraParams === 'string') extraParamsObject = parse(extraParams);
  else extraParamsObject = extraParams;

  const newParamsObject = {
    ...paramsObject,
    ...extraParamsObject,
  };

  const queryParamsString = stringify(newParamsObject);
  return `${href}?${queryParamsString}`;
};

export default useSetQueryParam;
