import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationProfessionalBreeder: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_4212_10523"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4212_10523)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M167.085 41.283c-13.255 0-24 10.745-24 24v25.272c0 13.254 10.745 24 24 24h23.643c13.255 0 24-10.746 24-24V65.283c0-13.255-10.745-24-24-24h-23.643zm-3.788 12.212a8 8 0 00-8 8v32.848a8 8 0 008 8h31.219a8 8 0 008-8V61.495a8 8 0 00-8-8h-31.219z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M145.085 65.283c0-12.15 9.85-22 22-22v-4c-14.359 0-26 11.64-26 26h4zm0 25.272V65.283h-4v25.272h4zm22 22c-12.15 0-22-9.85-22-22h-4c0 14.359 11.641 26 26 26v-4zm23.643 0h-23.643v4h23.643v-4zm22-22c0 12.15-9.85 22-22 22v4c14.359 0 26-11.641 26-26h-4zm0-25.272v25.272h4V65.283h-4zm-22-22c12.15 0 22 9.85 22 22h4c0-14.36-11.641-26-26-26v4zm-23.643 0h23.643v-4h-23.643v4zm-9.788 18.212a6 6 0 016-6v-4c-5.523 0-10 4.477-10 10h4zm0 32.848V61.495h-4v32.848h4zm6 6a6 6 0 01-6-6h-4c0 5.522 4.477 10 10 10v-4zm31.219 0h-31.219v4h31.219v-4zm6-6a6 6 0 01-6 6v4c5.523 0 10-4.478 10-10h-4zm0-32.848v32.848h4V61.495h-4zm-6-6a6 6 0 016 6h4c0-5.523-4.477-10-10-10v4zm-31.219 0h31.219v-4h-31.219v4z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M85.302 206.626l-6.69-50.179h198.937l-7.282 50.417c-1.564 10.823-10.839 18.855-21.774 18.855H107.109c-11.026 0-20.35-8.163-21.807-19.093z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M85.546 94.413l-6.965 58.034h199.001l-7.572-58.249c-1.425-10.961-10.763-19.163-21.817-19.163H107.389c-11.136 0-20.517 8.321-21.843 19.378z"
        ></path>
        <rect
          width="215"
          height="10.654"
          x="71"
          y="149.119"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <rect
          width="119.747"
          height="48.104"
          x="118.219"
          y="99.457"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M117.851 123.918h120.491M141.054 99.494v48.034M165.885 99.494v48.034M190.309 99.494v48.034M214.732 99.494v48.034"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationProfessionalBreeder;
