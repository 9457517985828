import { useRef, useCallback, useState, useEffect } from 'react';
import { useViewportSize, useDebouncedValue } from '@mantine/hooks';

interface Bounds {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}

interface Return extends Bounds {
  setRef: (node: Element | null) => void;
}

const useElementBounds = (): Return => {
  const ref = useRef<Element | null>(null);
  const { width } = useViewportSize();
  const [debouncedWidth] = useDebouncedValue(width, 50);

  const [bounds, setBounds] = useState<Bounds>({
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0,
  });

  const setRef = useCallback((node: Element | null) => {
    if (node) {
      ref.current = node;
    }
  }, []);

  useEffect(() => {
    if (ref.current) setBounds(ref.current.getBoundingClientRect().toJSON());
  }, [debouncedWidth]);

  return { setRef, ...bounds };
};
export default useElementBounds;
