import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskAnalysisSubjectsHomeAndFamily: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.4 7.079a7.493 7.493 0 019.2 0l11.988 9.324a7.492 7.492 0 012.895 5.916v16.185a5.996 5.996 0 01-5.995 5.996H11.512a5.996 5.996 0 01-5.995-5.996V22.32a7.494 7.494 0 012.892-5.917l11.99-9.324zM25 8.5c-1 0-1.97.333-2.759.947l-11.99 9.324a4.491 4.491 0 00-1.734 3.547v16.186a2.996 2.996 0 002.995 2.996h26.976a2.995 2.995 0 002.995-2.996V22.32c0-1.387-.64-2.696-1.735-3.547l-11.99-9.325A4.493 4.493 0 0025 8.5z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.209 30.952a1.5 1.5 0 01-.51 2.059c-2.77 1.671-6.31 2.463-9.768 2.463-3.459 0-6.997-.792-9.765-2.463a1.5 1.5 0 011.55-2.569c2.195 1.325 5.165 2.032 8.215 2.032s6.021-.706 8.219-2.032a1.5 1.5 0 012.059.51z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisSubjectsHomeAndFamily;
