import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationResidenceFarmHouse: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M279 224H77v-58.753L178 92.5l101 72.747V224Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="m178 92.5 101 72.747H77L178 92.5Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M178 165V92.5M198 165v-56M158 165v-56M218 165.5v-56M138 165.5v-56M238 166v-32M118 166v-32M258 167v-19"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M172.155 84.386a10 10 0 0 1 11.689 0l101 72.746c4.482 3.228 5.498 9.478 2.27 13.959-3.228 4.482-9.477 5.498-13.959 2.27L178 104.824l-95.156 68.537c-4.481 3.228-10.73 2.212-13.959-2.27-3.227-4.481-2.211-10.731 2.27-13.959l101-72.746Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
          d="M158 188h40v36h-40z"
        />
        <path
          d="M178 188v36"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M116.872 208.531h-8.14a2.98 2.98 0 0 1-2.979-2.978V192.05a2.98 2.98 0 0 1 2.979-2.979h8.14v19.46ZM125.016 208.531h-8.143v-19.46h8.14a2.98 2.98 0 0 1 2.979 2.979v13.503a2.98 2.98 0 0 1-2.976 2.978ZM239.12 208.531h-8.141a2.98 2.98 0 0 1-2.979-2.978V192.05a2.98 2.98 0 0 1 2.979-2.979h8.141v19.46ZM247.263 208.531h-8.143v-19.46h8.14a2.98 2.98 0 0 1 2.979 2.979v13.503a2.98 2.98 0 0 1-2.976 2.978Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m304.94 228-.021-.042-.043.042h-56.318a7.559 7.559 0 0 1 .791-7.161 7.758 7.758 0 0 1 2.815-2.473 7.89 7.89 0 0 1 3.664-.88 7.634 7.634 0 0 1 1.759.346 16.15 16.15 0 0 1-.193-1.887 16.766 16.766 0 0 1 3.842-10.679 17.253 17.253 0 0 1 9.824-5.904c3.866-.8 7.894-.265 11.408 1.513a17.05 17.05 0 0 1 7.885 8.253 12.588 12.588 0 0 1 11.073.879 12.301 12.301 0 0 1 4.071 3.937 12.09 12.09 0 0 1 1.85 5.314 7.747 7.747 0 0 1 4.825-1.785c2.075 0 4.066.811 5.534 2.255a7.639 7.639 0 0 1 2.294 5.444 7.495 7.495 0 0 1-.558 2.815L304.94 228Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m228.267 228.426.013-.026.026.026h34.309a4.747 4.747 0 0 0-4.429-6.509 4.645 4.645 0 0 0-1.071.214c.061-.386.101-.776.117-1.168a10.478 10.478 0 0 0-8.325-10.265 10.482 10.482 0 0 0-11.753 6.045 7.56 7.56 0 0 0-10.353 6.272 4.674 4.674 0 0 0-2.94-1.106 4.77 4.77 0 0 0-4.768 4.766c.001.598.117 1.189.339 1.743l8.835.008ZM93.94 228l-.021-.042-.043.042H37.558a7.553 7.553 0 0 1 .791-7.161 7.75 7.75 0 0 1 2.815-2.473 7.89 7.89 0 0 1 3.664-.88 7.632 7.632 0 0 1 1.759.346 16.064 16.064 0 0 1-.193-1.887 16.766 16.766 0 0 1 3.842-10.679 17.256 17.256 0 0 1 9.824-5.904c3.866-.8 7.894-.265 11.408 1.513a17.045 17.045 0 0 1 7.885 8.253 12.59 12.59 0 0 1 11.073.879 12.313 12.313 0 0 1 4.071 3.937 12.087 12.087 0 0 1 1.85 5.314 7.747 7.747 0 0 1 4.825-1.785c2.075 0 4.066.811 5.534 2.255a7.639 7.639 0 0 1 2.294 5.444 7.495 7.495 0 0 1-.558 2.815L93.94 228Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.096 226.652v-16.156"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m55.818 142.598.003-.009.002-.008a.29.29 0 0 1 .277-.209.286.286 0 0 1 .277.209l.002.008.003.008c2.2 7.461 5.67 19.415 8.587 30.349 1.46 5.469 2.776 10.667 3.727 14.912.97 4.33 1.504 7.448 1.504 8.894a14.095 14.095 0 0 1-8.704 13.026 14.093 14.093 0 0 1-15.366-3.056 14.096 14.096 0 0 1-4.13-9.97c0-1.446.535-4.564 1.504-8.894.95-4.246 2.266-9.443 3.725-14.912 2.918-10.934 6.387-22.888 8.59-30.348Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M307.345 228.024v-12.55"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M300.889 186.42c2.174-8.151 4.744-17.031 6.459-22.855 1.714 5.824 4.284 14.705 6.459 22.855 1.133 4.246 2.154 8.276 2.89 11.566.756 3.374 1.158 5.746 1.158 6.811a10.509 10.509 0 0 1-17.936 7.43 10.505 10.505 0 0 1-2.278-3.409l-1.848.765 1.848-.765a10.509 10.509 0 0 1-.8-4.021c0-1.065.402-3.437 1.158-6.811.736-3.29 1.757-7.32 2.89-11.566Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationResidenceFarmHouse;
