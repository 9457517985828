import React from 'react';
import { Flex } from '@wegroup/design-system';
import { LoadingSpinner as LoadingSpinnerWG } from 'wg-fe-ui';

interface Props {
  id?: string;
}

const LoadingSpinner: React.FC<React.PropsWithChildren<Props>> = ({ id }) => {
  return (
    <Flex id={id} h="100%" w="100%" justifyContent="center" alignItems="center">
      <LoadingSpinnerWG />
    </Flex>
  );
};

export default LoadingSpinner;
