import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMotorcross: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3927_10122"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3927_10122)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <circle
          cx="105"
          cy="188"
          r="39"
          stroke={colors.primary[500]}
          strokeDasharray="4 12"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        ></circle>
        <circle
          cx="105"
          cy="188"
          r="37"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="105"
          cy="188"
          r="27"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="105"
          cy="188"
          r="12"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="251"
          cy="188"
          r="39"
          stroke={colors.primary[500]}
          strokeDasharray="4 12"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
        ></circle>
        <circle
          cx="251"
          cy="188"
          r="37"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="251"
          cy="188"
          r="27"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="251"
          cy="188"
          r="9"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <rect
          width="119.667"
          height="24"
          x="88.769"
          y="184.629"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="12"
          transform="rotate(-33.207 88.769 184.629)"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M105 188l52-34"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M157.598 171.979L137.415 136h82.349l-17.947 35.894a2 2 0 01-1.789 1.106h-40.685a2.002 2.002 0 01-1.745-1.021z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M205 144.5h-21l-5 21h15.5l10.5-21z"
        ></path>
        <rect
          width="42"
          height="12"
          x="96"
          y="121"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M85.5 114H135l42.5 22H137l-51.5-22z"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M192.919 111.738c7.555-11.28 16.745-14.268 29.368-17.738l7.213 22.575L219.195 137h-53.068l-35.36-23.429c-.826-.547-.438-1.833.553-1.833h61.599z"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M222.287 94l1.905-.609a2 2 0 00-2.435-1.32l.53 1.929zm-29.368 17.738v2h1.067l.594-.887-1.661-1.113zm-62.152 1.833l1.105-1.667-1.105 1.667zM166.127 137l-1.105 1.667.502.333h.603v-2zm53.068 0v2h1.232l.554-1.099-1.786-.901zm10.305-20.425l1.786.901.368-.73-.249-.78-1.905.609zm-7.743-24.504c-12.663 3.481-22.507 6.62-30.5 18.553l3.323 2.227c7.118-10.626 15.655-13.464 28.237-16.922l-1.06-3.858zm-28.838 17.667H131.32v4h61.599v-4zm-63.256 5.5l35.359 23.429 2.21-3.334-35.36-23.429-2.209 3.334zM166.127 139h53.068v-4h-53.068v4zm54.854-1.099l10.305-20.425-3.572-1.802-10.304 20.425 3.571 1.802zm10.424-21.935l-7.213-22.575-3.81 1.218 7.213 22.575 3.81-1.218zm-100.085-6.228c-2.973 0-4.135 3.858-1.657 5.5l2.209-3.334c.826.547.439 1.834-.552 1.834v-4z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M200.5 78h17L252 189.5"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M276.569 126.392c-12.833-8.666-25.214-12.17-48.069-12.392l4.5 15c9.645-4.97 21.234-3.629 42.071.705 2.074.431 3.254-2.128 1.498-3.313z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationMotorcross;
