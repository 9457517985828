import React from 'react';
import { merge, omit } from 'lodash';

import { SearchSelectInputProps } from '../types';
import useStyles from '../hooks/useStyles';

import { Box } from '@wegroup/design-system';
import SearchSelectInput from '../SearchSelectInput';
import SearchSelectDrawerDropdown from './SearchSelectDrawerDropdown';
import SearchSelectDrawerItem from './SearchSelectDrawerItem';
import BottomDrawer from '../../../../../../sales-conversation/src/components/default/BottomDrawer';

type Props = SearchSelectInputProps & {
  isOpen: boolean;
  onClose: () => void;
};

/**
 * This component is used for mobile responsiveness for the search select
 */

const SearchSelectDrawer: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  onChange,
  chakraProps,
  extraStyles,
  ...otherProps
}) => {
  const { drawerStyles } = useStyles();

  const handleOnChange = (value: string | null) => {
    onChange && onChange(value); // Pass value to native onChange function
    value && onClose(); // Close if something was picked
  };

  return (
    <Box
      sx={{
        '.drawer__content': {
          overflow: 'hidden',
          '*:focus': {
            outline: 'none',
          },
        },
      }}
    >
      <BottomDrawer onClose={onClose} isVisible={isOpen}>
        <SearchSelectInput
          initiallyOpened
          rightSectionHidden
          withinPortal={false}
          dropdownPosition="bottom"
          dropdownComponent={SearchSelectDrawerDropdown}
          itemComponent={SearchSelectDrawerItem}
          zIndex={1500} // zIndex higher than drawer
          extraStyles={
            extraStyles ? merge(drawerStyles, extraStyles) : drawerStyles
          }
          // Prevents dropdown from closing
          onBlurCapture={(e) => e.stopPropagation()}
          onChange={handleOnChange}
          drawer
          {...omit(otherProps, 'prefilled')}
        />
      </BottomDrawer>
    </Box>
  );
};

export default SearchSelectDrawer;
