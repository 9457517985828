import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyGarage: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M33.49 35.57a.619.619 0 00-.45-.38l-8.45-1.81a.59.59 0 01-.37-.25l-1.72-2.28a.251.251 0 00-.19-.12l-1.48-.28A30.744 30.744 0 0017 30v10.42h8a2.89 2.89 0 005.74 0h2.09a.64.64 0 00.63-.49c.243-.897.423-1.809.54-2.73a6.42 6.42 0 00-.51-1.63zm-4 4.85a1.6 1.6 0 01-3.14 0 1.66 1.66 0 010-.32 1.6 1.6 0 013.2 0c-.02.11-.05.217-.09.32h.03z"
    ></path>
    <path
      fill="currentColor"
      d="M9 10.5v32a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-24a.5.5 0 01.5-.5h19a.5.5 0 01.5.5v24a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-32a.5.5 0 00-.5-.5h-31a.5.5 0 00-.5.5z"
    ></path>
    <path
      fill="currentColor"
      d="M31.5 20h-13a1.5 1.5 0 000 3h13a1.5 1.5 0 000-3zM31.5 25h-13a1.5 1.5 0 000 3h13a1.5 1.5 0 000-3z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyGarage;
