import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleLaneDepartingWarning: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M21.094 7.547c-.423-.507-1.174-.697-1.829-.418l-6.798 2.955c-.655.28-.982.939-.832 1.573-1.638 1.611-2.143 4.06-1.065 6.19l5.678 11.29.178.355 5.678 11.29c1.079 2.13 3.413 3.336 5.802 3.209 0 0 5.378-.216 9.46-4.11 1.637-1.611 2.142-4.06 1.064-6.19L32.752 22.4l-.178-.355-5.678-11.29c-1.079-2.143-3.427-3.348-5.802-3.209zm-1.16 1.599c1.638-.546 3.535-.153 4.969 1.027.355.292.123.812-.314.71l-4.505-.926c-.45-.088-.546-.672-.15-.811zm10.101 11.137l1.174 2.347 1.351 2.701c.055.115-.846.66-.942.559l-1.2-2.385a2.057 2.057 0 01-.178-.558l-.45-2.6c-.028-.128.177-.178.245-.064zm-3.221-4.135c.177 0 .34.114.368.279l1.229 4.896c.054.203-.096.406-.328.431a16.326 16.326 0 00-4.982 1.256 15.308 15.308 0 00-4.232 2.74.39.39 0 01-.56-.038l-3.262-4.021a.342.342 0 01.014-.444c1.365-1.56 3.14-2.88 5.255-3.793a15.534 15.534 0 016.498-1.306zm-15.452-.102c-.055-1.776.832-3.374 2.334-4.198.369-.203.778.24.573.608l-2.13 3.819c-.177.38-.75.215-.777-.229zm6.415 12.85l-1.351-2.702c-.055-.114.11-.228.205-.127l1.802 1.941c.163.178.314.38.423.596l1.16 2.322c.027.139-.996.43-1.065.317l-1.174-2.347zm5.037 10.021l-3.221-6.406.996-.431s.806 1.357 1.707 3.742c.163.38.518 3.095.518 3.095zm13.255-4.389c-1.502 1.281-3.358 2.448-5.474 3.361-2.116.914-4.259 1.497-6.28 1.738-.163.026-.3-.038-.313-.152l-.45-3.336c-.028-.14.15-.305.368-.343a24.13 24.13 0 004.86-1.522 22.975 22.975 0 004.367-2.486c.178-.127.424-.152.52-.038l2.47 2.46c.082.077.04.216-.069.318zm.45-1.56s-2.007-2.004-2.239-2.36c-1.392-2.169-1.993-3.615-1.993-3.615l.997-.431 3.235 6.406zM7 6H3v11h4V6zM7 20H3v11h4V20zM7 34H3v11h4V34zM47 6h-3v11h3V6zM47 20h-3v11h3V20zM47 34h-3v11h3V34z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleLaneDepartingWarning;
