import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersMailSent: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9a6 6 0 0 0-6 6v18a6 6 0 0 0 6 6h11.097a13.899 13.899 0 0 1-1.374-4H12a2 2 0 0 1-2-2V15.73l13.508 9.542C25.198 22.474 29 19.5 33 19l5-3.27v2.993c1.42.255 2.764.725 4 1.374V15a6 6 0 0 0-6-6H12Zm.94 4L24 20.27 35.06 13H12.94Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.5 20C28.596 20 23 25.596 23 32.5S28.596 45 35.5 45 48 39.404 48 32.5 42.404 20 35.5 20ZM27 32.5a8.5 8.5 0 1 1 17 0 8.5 8.5 0 0 1-17 0Z"
      fill="currentColor"
    />
    <path
      d="M35.154 26.5c-.926 0-1.654.766-1.654 1.684v5.724c0 .63.346 1.212.905 1.502l3.692 1.907a1.64 1.64 0 0 0 2.228-.748 1.699 1.699 0 0 0-.73-2.255l-2.787-1.44v-4.69c0-.918-.729-1.684-1.654-1.684Z"
      fill="currentColor"
    />
  </Icon>
);

export default GeneralOthersMailSent;
