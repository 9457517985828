import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyStudent: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M36.21 24.69l-10.9 7.79a.48.48 0 01-.58 0l-12.94-9.24a.5.5 0 00-.79.4V37.5a.5.5 0 00.5.5h25a.5.5 0 00.5-.5V25.09a.5.5 0 00-.79-.4z"
    ></path>
    <path
      fill="currentColor"
      d="M43 37.94V16.25a.18.18 0 01.08-.16l1.61-1.15a.5.5 0 00-.29-.94H5.63a.5.5 0 00-.29.91l19.39 13.88a.48.48 0 00.58 0l14.37-10.27a.2.2 0 01.32.16v19.26a.2.2 0 010 .11l-2.82 4.65a.2.2 0 00.17.3h2.53a.211.211 0 00.18-.11l.76-1.53a.2.2 0 01.36 0l.76 1.53a.21.21 0 00.18.11h3.53a.2.2 0 00.17-.3L43 38.05a.2.2 0 010-.11z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyStudent;
