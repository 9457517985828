import { getI18nLanguageCode } from '../../../../i18n';

export const replaceNewLineWithHTML = (str?: string): string => {
  if (!str) return '';
  return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
};

export const replaceHTMLWithNewLine = (str?: string): string => {
  if (!str) return '';
  return str.replace(/<br>/g, '\n');
};

export const toCommaList = (str?: string[]): string => {
  if (!str || str.length === 0) return '';
  const lf = new Intl.ListFormat(getI18nLanguageCode());
  return lf.format(str);
};

export const isLowerCaseStringEqual = (str1?: string, str2?: string) => {
  return str1?.toLowerCase() === str2?.toLowerCase();
};
