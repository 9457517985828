import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationResidenceTownHouse: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="m304.94 230-.021-.042-.043.042h-56.318a7.559 7.559 0 0 1 .791-7.161 7.758 7.758 0 0 1 2.815-2.473 7.89 7.89 0 0 1 3.664-.88 7.634 7.634 0 0 1 1.759.346 16.15 16.15 0 0 1-.193-1.887 16.766 16.766 0 0 1 3.842-10.679 17.253 17.253 0 0 1 9.824-5.904c3.866-.8 7.894-.265 11.408 1.513a17.05 17.05 0 0 1 7.885 8.253 12.588 12.588 0 0 1 11.073.879 12.301 12.301 0 0 1 4.071 3.937 12.09 12.09 0 0 1 1.85 5.314 7.747 7.747 0 0 1 4.825-1.785c2.075 0 4.066.811 5.534 2.255a7.639 7.639 0 0 1 2.294 5.444 7.495 7.495 0 0 1-.558 2.815L304.94 230Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m228.267 230.426.013-.026.026.026h34.309a4.747 4.747 0 0 0-4.429-6.509 4.645 4.645 0 0 0-1.071.214c.061-.386.101-.776.117-1.168a10.478 10.478 0 0 0-8.325-10.265 10.482 10.482 0 0 0-11.753 6.045 7.56 7.56 0 0 0-10.353 6.272 4.674 4.674 0 0 0-2.94-1.106 4.77 4.77 0 0 0-4.768 4.766c.001.598.117 1.189.339 1.743l8.835.008ZM93.94 230l-.021-.042-.043.042H37.558a7.553 7.553 0 0 1 .791-7.161 7.75 7.75 0 0 1 2.815-2.473 7.89 7.89 0 0 1 3.664-.88 7.632 7.632 0 0 1 1.759.346 16.064 16.064 0 0 1-.193-1.887 16.766 16.766 0 0 1 3.842-10.679 17.256 17.256 0 0 1 9.824-5.904c3.866-.8 7.894-.265 11.408 1.513a17.045 17.045 0 0 1 7.885 8.253 12.59 12.59 0 0 1 11.073.879 12.313 12.313 0 0 1 4.071 3.937 12.087 12.087 0 0 1 1.85 5.314 7.747 7.747 0 0 1 4.825-1.785c2.075 0 4.066.811 5.534 2.255a7.639 7.639 0 0 1 2.294 5.444 7.495 7.495 0 0 1-.558 2.815L93.94 230Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.096 228.652v-16.156"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m55.818 144.598.003-.009.002-.008a.29.29 0 0 1 .277-.209.286.286 0 0 1 .277.209l.002.008.003.008c2.2 7.461 5.67 19.415 8.587 30.349 1.46 5.469 2.776 10.667 3.727 14.912.97 4.33 1.504 7.448 1.504 8.894a14.095 14.095 0 0 1-8.704 13.026 14.093 14.093 0 0 1-15.366-3.056 14.096 14.096 0 0 1-4.13-9.97c0-1.446.535-4.564 1.504-8.894.95-4.246 2.266-9.443 3.725-14.912 2.918-10.934 6.387-22.888 8.59-30.348Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M307.345 230.024v-12.55"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M300.889 188.42c2.174-8.151 4.744-17.031 6.459-22.855 1.714 5.824 4.284 14.705 6.459 22.855 1.133 4.246 2.154 8.276 2.89 11.566.756 3.374 1.158 5.746 1.158 6.811a10.509 10.509 0 0 1-17.936 7.43 10.505 10.505 0 0 1-2.278-3.409l-1.848.765 1.848-.765a10.509 10.509 0 0 1-.8-4.021c0-1.065.402-3.437 1.158-6.811.736-3.29 1.757-7.32 2.89-11.566Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M254.796 190.937h24.022v34.938h-24.022v-34.938ZM254.796 160.366h24.022v30.571h-24.022v-30.571Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M270.916 88.305h4.883a3.02 3.02 0 0 1 3.019 3.018v77.771h-7.902a3.019 3.019 0 0 1-3.018-3.018V91.323a3.018 3.018 0 0 1 3.018-3.018Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.918 160.366h24.022v30.571H77.918v-30.571Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.82 88.305h-4.883a3.018 3.018 0 0 0-3.019 3.018v77.771h7.902a3.018 3.018 0 0 0 3.018-3.018V91.323a3.018 3.018 0 0 0-3.018-3.018ZM259.164 111.125H97.573v68.059a3.017 3.017 0 0 0 3.018 3.018h155.555a3.019 3.019 0 0 0 3.018-3.018v-68.059Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.164 111.125H97.573v68.059a3.017 3.017 0 0 0 3.018 3.018h155.555a3.019 3.019 0 0 0 3.018-3.018v-68.059Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M256.146 103.601H100.591a3.018 3.018 0 0 0-3.018 3.018v4.527h161.591v-4.527a3.017 3.017 0 0 0-3.018-3.018Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.94 182.202h48.04v43.673h-48.04v-43.673Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M265.716 97.04H281"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.918 190.937h24.022v34.938H77.918v-34.938Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.023 97.04H75.736"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.757 133.426c0-.927-.368-1.816-1.023-2.472l-27.366-27.353-27.365 27.362a3.504 3.504 0 0 0-1.024 2.472v92.44h56.778v-92.449Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.757 133.426c0-.927-.368-1.816-1.023-2.472l-27.366-27.353-27.365 27.362a3.504 3.504 0 0 0-1.024 2.472v92.44h56.778v-92.449Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.94 182.202h48.04v43.673h-48.04v-43.673Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.757 182.202h48.04v43.673h-48.04v-43.673Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.757 182.202h48.04v43.673h-48.04v-43.673Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M179.557 182.202h-2.378a7.545 7.545 0 0 0-7.545 7.546v23.025h17.469v-23.025a7.56 7.56 0 0 0-2.21-5.336 7.544 7.544 0 0 0-5.336-2.21ZM190.637 219.325h-24.538a3.019 3.019 0 0 0-3.018 3.018v3.532h30.574v-3.532a3.019 3.019 0 0 0-3.018-3.018Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M186.269 212.772h-15.803a3.019 3.019 0 0 0-3.018 3.019v3.534h21.84v-3.534a3.02 3.02 0 0 0-3.019-3.019ZM119.409 188.755h13.101v21.836h-13.101v-21.836Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M119.409 188.755h13.101v21.836h-13.101v-21.836Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M171.381 136.492h13.977v21.836h-13.977v-21.836Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M171.381 136.492h13.977v21.836h-13.977v-21.836Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M224.226 188.755h13.101v21.836h-13.101v-21.836Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M224.226 188.755h13.101v21.836h-13.101v-21.836Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationResidenceTownHouse;
