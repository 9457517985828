import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationGymnastic: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3824_10698"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3824_10698)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M221.413 74.3h-88.827S147.557 57 176.833 57c31.938 0 44.58 17.3 44.58 17.3z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M128.351 80.203a6 6 0 015.951-5.234h85.762a6 6 0 015.948 5.208l18.498 139.031c.478 3.596-2.32 6.791-5.948 6.791H116.401c-3.618 0-6.413-3.178-5.951-6.766l17.901-139.03z"
        ></path>
        <rect
          width="30.599"
          height="9.307"
          x="161.87"
          y="82.953"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="4.654"
        ></rect>
        <rect
          width="30.599"
          height="9.307"
          x="161.87"
          y="112.895"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="4.654"
        ></rect>
        <rect
          width="30.599"
          height="9.307"
          x="161.87"
          y="144.166"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="4.654"
        ></rect>
        <rect
          width="30.599"
          height="9.307"
          x="161.87"
          y="175.439"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="4.654"
        ></rect>
        <rect
          width="30.599"
          height="9.307"
          x="161.87"
          y="206.711"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="4.654"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M126.277 102.244h100.8M122.949 133.516h109.45M117.628 164.789h117.1M114.3 195.729h125.41"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationGymnastic;
