const orange = {
  primary: {
    main: '#FF8000',
    50: '#FFF2E5',
    100: '#FFE6CC',
    150: '#FFD9B3',
    200: '#FFCC99',
    300: '#FFB366',
    400: '#FF9933',
    500: '#FF8000',
    600: '#C76400',
    700: '#8F4800',
    750: '#733A00',
    800: '#572C00',
    850: '#3B1D00',
    900: '#1F0F00',
  },
  secondary: {
    main: '#6F558A',
    50: '#CBBFD8',
    100: '#C1B2D0',
    150: '#B7A6C8',
    200: '#AC99C0',
    300: '#9880B1',
    400: '#8367A1',
    500: '#6F558A',
    600: '#58446E',
    700: '#413251',
    750: '#362943',
    800: '#2A2135',
    850: '#1F1827',
    900: '#140F18',
  },
};

export default orange;
