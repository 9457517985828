import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationChildren: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3939_10126"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3939_10126)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <circle
          cx="178"
          cy="65"
          r="33"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <mask
          id="mask1_3939_10126"
          style={{ maskType: 'alpha' }}
          width="70"
          height="70"
          x="143"
          y="30"
          maskUnits="userSpaceOnUse"
        >
          <circle
            cx="178"
            cy="65"
            r="33"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_3939_10126)"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M183.705 72.314c4.701 1.284 7.757 3.058 11.093 6.437 2.875 2.912 4.296 5.314 5.419 9.165.381 1.306.4 1.947.465 15.611.067 14.149.065 14.254-.344 15.204-.762 1.772-2.271 2.754-4.232 2.754-1.409 0-2.331-.39-3.293-1.393-1.266-1.321-1.226-.863-1.226-14.153 0-6.63-.053-12.194-.118-12.363-.069-.18-.346-.308-.661-.308-.315 0-.591.129-.66.308-.065.169-.118 5.65-.118 12.179v11.871l-1.458 18.325c-.802 10.079-1.544 18.838-1.649 19.466-.446 2.678-2.5 4.669-5.371 5.205-1.675.312-6.545.117-7.73-.311-1.265-.456-2.92-2.008-3.541-3.321-.486-1.027-.546-1.623-2.052-20.367l-1.552-19.309v-11.715c0-6.444-.053-11.854-.118-12.023-.165-.432-.994-.386-1.237.068-.133.248-.201 4.473-.201 12.364 0 13.214.039 12.763-1.227 14.084-1.056 1.101-1.941 1.443-3.523 1.361-1.177-.061-1.516-.165-2.3-.707-.51-.352-1.161-1.045-1.46-1.553l-.539-.919-.06-13.135c-.038-8.59.014-13.773.153-14.975.918-7.98 7.425-15.12 16.059-17.622 2.573-.745 3.578-.86 6.811-.78 2.335.058 3.269.169 4.67.552z"
            clipRule="evenodd"
          ></path>
          <rect
            width="18.547"
            height="23.557"
            x="169.141"
            y="41"
            fill="#fff"
            rx="9.274"
          ></rect>
          <circle cx="178" cy="65" r="33"></circle>
        </g>
        <circle
          cx="178"
          cy="169"
          r="33"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <mask
          id="mask2_3939_10126"
          style={{ maskType: 'alpha' }}
          width="70"
          height="70"
          x="143"
          y="134"
          maskUnits="userSpaceOnUse"
        >
          <circle
            cx="178"
            cy="169"
            r="33"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask2_3939_10126)"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M183.705 176.314c4.701 1.284 7.757 3.058 11.093 6.437 2.875 2.912 4.296 5.315 5.419 9.165.381 1.307.4 1.947.465 15.611.067 14.149.065 14.254-.344 15.204-.762 1.772-2.271 2.754-4.232 2.754-1.409 0-2.331-.39-3.293-1.393-1.266-1.321-1.226-.863-1.226-14.153 0-6.631-.053-12.194-.118-12.363-.069-.179-.346-.308-.661-.308-.315 0-.591.129-.66.308-.065.169-.118 5.649-.118 12.179v11.871l-1.458 18.325c-.802 10.079-1.544 18.838-1.649 19.466-.446 2.678-2.5 4.669-5.371 5.205-1.675.312-6.545.117-7.73-.311-1.265-.456-2.92-2.008-3.541-3.321-.486-1.027-.546-1.623-2.052-20.367l-1.552-19.309v-11.715c0-6.444-.053-11.854-.118-12.023-.165-.432-.994-.386-1.237.068-.133.248-.201 4.473-.201 12.364 0 13.214.039 12.763-1.227 14.084-1.056 1.101-1.941 1.443-3.523 1.361-1.177-.061-1.516-.165-2.3-.707-.51-.352-1.161-1.045-1.46-1.553l-.539-.919-.06-13.135c-.038-8.59.014-13.773.153-14.975.918-7.981 7.425-15.12 16.059-17.622 2.573-.745 3.578-.86 6.811-.78 2.335.058 3.269.169 4.67.552z"
            clipRule="evenodd"
          ></path>
          <rect
            width="18.547"
            height="23.557"
            x="169.141"
            y="145"
            fill="#fff"
            rx="9.274"
          ></rect>
          <circle cx="178" cy="169" r="33"></circle>
        </g>
        <circle
          cx="268"
          cy="169"
          r="33"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <mask
          id="mask3_3939_10126"
          style={{ maskType: 'alpha' }}
          width="70"
          height="70"
          x="233"
          y="134"
          maskUnits="userSpaceOnUse"
        >
          <circle
            cx="268"
            cy="169"
            r="33"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask3_3939_10126)"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M273.705 176.314c4.701 1.284 7.757 3.058 11.093 6.437 2.875 2.912 4.296 5.315 5.419 9.165.381 1.307.4 1.947.465 15.611.067 14.149.065 14.254-.344 15.204-.762 1.772-2.271 2.754-4.232 2.754-1.409 0-2.331-.39-3.293-1.393-1.266-1.321-1.226-.863-1.226-14.153 0-6.631-.053-12.194-.118-12.363-.069-.179-.346-.308-.661-.308-.315 0-.591.129-.66.308-.065.169-.118 5.649-.118 12.179v11.871l-1.458 18.325c-.802 10.079-1.544 18.838-1.649 19.466-.446 2.678-2.5 4.669-5.371 5.205-1.675.312-6.545.117-7.73-.311-1.265-.456-2.92-2.008-3.541-3.321-.486-1.027-.546-1.623-2.052-20.367l-1.552-19.309v-11.715c0-6.444-.053-11.854-.118-12.023-.165-.432-.994-.386-1.237.068-.133.248-.201 4.473-.201 12.364 0 13.214.039 12.763-1.227 14.084-1.056 1.101-1.941 1.443-3.523 1.361-1.177-.061-1.516-.165-2.3-.707-.51-.352-1.161-1.045-1.46-1.553l-.539-.919-.06-13.135c-.038-8.59.014-13.773.153-14.975.918-7.981 7.425-15.12 16.059-17.622 2.573-.745 3.578-.86 6.811-.78 2.335.058 3.269.169 4.67.552z"
            clipRule="evenodd"
          ></path>
          <rect
            width="18.547"
            height="23.557"
            x="259.141"
            y="145"
            fill="#fff"
            rx="9.274"
          ></rect>
          <circle cx="268" cy="169" r="33"></circle>
        </g>
        <circle
          cx="88"
          cy="169"
          r="33"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <mask
          id="mask4_3939_10126"
          style={{ maskType: 'alpha' }}
          width="70"
          height="70"
          x="53"
          y="134"
          maskUnits="userSpaceOnUse"
        >
          <circle
            cx="88"
            cy="169"
            r="33"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask4_3939_10126)"
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M93.705 176.314c4.7 1.284 7.757 3.058 11.093 6.437 2.875 2.912 4.296 5.315 5.419 9.165.381 1.307.4 1.947.465 15.611.067 14.149.065 14.254-.344 15.204-.762 1.772-2.271 2.754-4.232 2.754-1.409 0-2.331-.39-3.293-1.393-1.266-1.321-1.226-.863-1.226-14.153 0-6.631-.053-12.194-.118-12.363-.069-.179-.346-.308-.661-.308-.315 0-.591.129-.66.308-.065.169-.118 5.649-.118 12.179v11.871l-1.458 18.325c-.802 10.079-1.544 18.838-1.649 19.466-.446 2.678-2.5 4.669-5.371 5.205-1.675.312-6.545.117-7.73-.311-1.265-.456-2.92-2.008-3.541-3.321-.486-1.027-.546-1.623-2.052-20.367l-1.552-19.309v-11.715c0-6.444-.053-11.854-.118-12.023-.165-.432-.994-.386-1.237.068-.133.248-.201 4.473-.201 12.364 0 13.214.039 12.763-1.227 14.084-1.056 1.101-1.94 1.443-3.523 1.361-1.177-.061-1.516-.165-2.3-.707-.51-.352-1.16-1.045-1.46-1.553l-.54-.919-.059-13.135c-.038-8.59.014-13.773.153-14.975.918-7.981 7.425-15.12 16.059-17.622 2.573-.745 3.578-.86 6.81-.78 2.336.058 3.27.169 4.671.552z"
            clipRule="evenodd"
          ></path>
          <rect
            width="18.547"
            height="23.557"
            x="79.141"
            y="145"
            fill="#fff"
            rx="9.274"
          ></rect>
          <circle cx="88" cy="169" r="33"></circle>
        </g>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M178 107v19.5M88 117v9.5M268 117v9.5M88 116.5h180"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationChildren;
