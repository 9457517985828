import { CalendarProps } from '@mantine/dates';
import { useTheme } from '@wegroup/design-system';
import { datesAreOnSameDay } from '../services/dateHelper';

const useCalendarStyling = (): CalendarProps => {
  const { colors } = useTheme();
  return {
    styles: {
      day: {
        '&[data-selected]': {
          color: 'white !important',
          background: `${colors.primary[500]} !important`,
          borderRadius: '9999px !important',
          fontWeight: 700,
        },
      },
    },
    sx: {
      '.mantine-Calendar-weekday': {
        color: 'black',
        fontWeight: 500,
      },
      '.mantine-Calendar-monthPickerControlActive, .mantine-Calendar-yearPickerControlActive':
        {
          background: colors.primary[500],
          ':hover': {
            background: colors.primary[500],
            fontWeight: 500,
          },
        },
    },
    dayStyle: (date: Date) =>
      datesAreOnSameDay(date, new Date())
        ? {
            background: `${colors.primary[50]}`,
            borderRadius: '9999px',
            color: colors.primary[500],
            fontWeight: 700,
          }
        : {
            color: 'black',
            borderRadius: '9999px ',
          },
  };
};

export default useCalendarStyling;
