import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceCasco: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_1864_7052"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_1864_7052)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M219.609 114.095a2 2 0 00-1.295-2.514l-21.19-6.781a2 2 0 00-1.22 3.81l21.191 6.781a2 2 0 002.514-1.296z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M222 201.5c0 12.979 10.521 23.5 23.5 23.5s23.5-10.521 23.5-23.5-10.521-23.5-23.5-23.5-23.5 10.521-23.5 23.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M245.5 221c-10.77 0-19.5-8.73-19.5-19.5s8.73-19.5 19.5-19.5 19.5 8.73 19.5 19.5-8.73 19.5-19.5 19.5zm0 4c-12.979 0-23.5-10.521-23.5-23.5s10.521-23.5 23.5-23.5 23.5 10.521 23.5 23.5-10.521 23.5-23.5 23.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M235 201.5c0 5.799 4.701 10.5 10.5 10.5s10.5-4.701 10.5-10.5-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M245.5 208a6.5 6.5 0 110-13 6.5 6.5 0 010 13zm0 4c-5.799 0-10.5-4.701-10.5-10.5s4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5-4.701 10.5-10.5 10.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M103 201.5c0 12.979 10.521 23.5 23.5 23.5s23.5-10.521 23.5-23.5-10.521-23.5-23.5-23.5-23.5 10.521-23.5 23.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M126.5 221c-10.77 0-19.5-8.73-19.5-19.5s8.73-19.5 19.5-19.5 19.5 8.73 19.5 19.5-8.73 19.5-19.5 19.5zm0 4c-12.979 0-23.5-10.521-23.5-23.5s10.521-23.5 23.5-23.5 23.5 10.521 23.5 23.5-10.521 23.5-23.5 23.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M116 201.5c0 5.799 4.701 10.5 10.5 10.5s10.5-4.701 10.5-10.5-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M126.5 208a6.5 6.5 0 110-13 6.5 6.5 0 010 13zm0 4c-5.799 0-10.5-4.701-10.5-10.5s4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5-4.701 10.5-10.5 10.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M213.499 167v-48c0-8 9.201-7 12.001-1 3.5 7.5 15.999 31.5 19.499 37.5 3.5 6 5.5 15.5-5 15.5s-11 4.5-19.5 16-14.5 15-22.5 15h-84c-7 0-24.5 2.5-24.5-9s8-42 38.5-42h27.5c5 0 11.5 1.5 11.5 11.5v20h31c5 0 15.5-.5 15.5-15.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M218.451 115.25c1.85 0 4.156 1.28 5.237 3.596 3.523 7.549 16.051 31.603 19.585 37.662 1.665 2.855 2.79 6.267 2.492 8.754-.14 1.165-.573 2.01-1.308 2.596-.766.611-2.113 1.142-4.457 1.142-2.755 0-5.009.293-6.964 1.003-1.987.722-3.546 1.832-4.967 3.288-1.383 1.418-2.657 3.19-4.059 5.222-.303.44-.615.896-.937 1.367-1.19 1.739-2.529 3.696-4.181 5.931-4.192 5.672-7.649 9.202-10.918 11.324-3.202 2.079-6.315 2.865-9.974 2.865h-83.999c-1.083 0-2.316.05-3.641.103-3.355.135-7.305.294-10.929-.267-2.48-.384-4.49-1.075-5.847-2.147-1.256-.993-2.084-2.408-2.084-4.689 0-5.418 1.916-15.533 7.496-24.233 5.518-8.602 14.56-15.767 29.005-15.767h27.5c2.367 0 4.721.37 6.454 1.621 1.604 1.159 3.046 3.35 3.046 7.879v20a2 2 0 002 2H198c2.505 0 6.899-.099 10.674-2.422 3.97-2.443 6.826-7.049 6.826-15.078v-48c0-1.615.457-2.507.92-2.985.464-.481 1.147-.765 2.031-.765zm-4.907-2.015c-1.35 1.397-2.044 3.38-2.044 5.765v48c0 6.971-2.393 10.115-4.923 11.672-2.725 1.677-6.081 1.828-8.577 1.828h-28.999v-18c0-5.471-1.809-9.03-4.705-11.121-2.767-1.999-6.163-2.379-8.795-2.379h-27.5c-16.056 0-26.263 8.085-32.372 17.608-6.044 9.425-8.129 20.31-8.129 26.392 0 3.469 1.36 6.054 3.604 7.827 2.143 1.693 4.946 2.533 7.716 2.962 4.043.626 8.7.439 12.131.301 1.19-.048 2.232-.09 3.05-.09H198c4.342 0 8.229-.964 12.152-3.51 3.856-2.503 7.649-6.473 11.957-12.301 1.7-2.3 3.09-4.333 4.284-6.078.316-.463.619-.905.91-1.327 1.411-2.046 2.512-3.555 3.629-4.7 1.079-1.106 2.145-1.84 3.47-2.321 1.358-.494 3.104-.763 5.598-.763 2.907 0 5.248-.656 6.951-2.014 1.733-1.383 2.55-3.288 2.785-5.248.452-3.763-1.173-8.101-3.008-11.246-3.466-5.941-15.938-29.887-19.415-37.338-1.719-3.684-5.413-5.904-8.862-5.904-1.767 0-3.559.591-4.907 1.985z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M165 142.5a5.5 5.5 0 00-5.5-5.5h-36a5.5 5.5 0 100 11h36a5.5 5.5 0 005.5-5.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M123.5 141h36a1.5 1.5 0 010 3h-36a1.5 1.5 0 010-3zm36-4a5.5 5.5 0 110 11h-36a5.5 5.5 0 110-11h36z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          d="M243 128c0 1.104-.901 2.015-1.994 1.859C234.218 128.891 229 123.055 229 116s5.218-12.892 12.006-13.859c1.093-.156 1.994.754 1.994 1.859v24z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M245 104c0-2.119-1.786-4.194-4.276-3.839C232.965 101.267 227 107.935 227 116c0 8.065 5.965 14.733 13.724 15.839 2.49.355 4.276-1.72 4.276-3.839v-24zm-4-.002v.168l.288-.045a.333.333 0 01-.258-.078.113.113 0 01-.03-.043v-.002zm0 23.836v-23.668c-5.675.951-10 5.889-10 11.834 0 5.945 4.325 10.883 10 11.834zm0 0V128a.113.113 0 01.03-.043.333.333 0 01.258-.078 14.334 14.334 0 01-.288-.045z"
          clipRule="evenodd"
        ></path>
        <g filter="url(#filter0_dd_1864_7052)">
          <circle cx="133" cy="75" r="55" fill="#fff"></circle>
          <circle
            cx="133"
            cy="75"
            r="53"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M116.768 100.029a1.994 1.994 0 00-.268 1.386l1.394 8.126a2.001 2.001 0 003.911.149l1.71-6.791 27.798-45.168c1.469-2.388 2.702-5.36 2.527-8.436-.184-3.23-1.898-6.217-5.597-8.493-6.09-3.748-11.509-.727-13.631 1.41l-10.701 7.32a2 2 0 00.081 3.354l1.122.69a2 2 0 002.098 0l4.261-2.627-.492 4.915a1.998 1.998 0 00.942 1.903l1.346.828a2 2 0 003.019-1.363l1.021-5.91.986-1.603c2.008-3.262 4.698-3.327 6.236-2.411a3.504 3.504 0 011.652 2.358c.208 1.046.042 2.499-1.045 4.265l-28.37 46.098z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_1864_7052"
          width="142"
          height="142"
          x="62"
          y="12"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1864_7052"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_1864_7052"
            result="effect2_dropShadow_1864_7052"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1864_7052"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationInsuranceCasco;
