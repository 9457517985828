import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSavings: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3939_10210"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3939_10210)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M121.935 135.877c-3.457 8.687-7.514 11.41-15.232 12.226-2.455.259-4.279 2.486-3.813 4.91l4.817 25.051a1 1 0 00.982.811h15.013c.358 0 .69.194.871.503 4.953 8.442 10.071 12.796 18.461 19.131.251.189.401.487.401.801v13.691c0 6.627 5.372 12 12 12h8.053c6.627 0 12-5.373 12-12v-2.098c0-.607.538-1.073 1.139-.995 7.236.938 11.807.915 19.227-.007a1.007 1.007 0 011.133.995v2.105c0 6.627 5.372 12 12 12h6.88c6.628 0 12-5.373 12-12v-14.475c0-.313.151-.611.4-.801 23.705-18.107 31.217-36.946 23.054-57.548-12.449-28.788-44.258-45.574-84.25-38.026-.352.066-.72-.06-.955-.331-6.468-7.464-10.331-11.27-18.382-12.068-9.543.28-14.421.695-11.025 8.036.051.111.128.218.216.303 4.402 4.254 5.02 7.163 6.35 12.867.095.41-.079.839-.43 1.071-10.141 6.712-14.586 11.995-20.91 23.848z"
        ></path>
        <circle
          cx="143.825"
          cy="139.005"
          r="3.909"
          fill={colors.primary[500]}
        ></circle>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M168.843 118.288c12.776-3.558 21.029-3.507 32.835 0"
        ></path>
        <circle
          cx="218.096"
          cy="74.117"
          r="20.672"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[500]}
          d="M221.561 81.627h-1.6a6.458 6.458 0 01-3.378-.965 6.944 6.944 0 01-2.464-2.627h3.748c.328-.009.64-.152.869-.4a1.37 1.37 0 00.358-.928c0-.347-.129-.68-.358-.928a1.226 1.226 0 00-.869-.4h-4.585a6.76 6.76 0 010-1.74h6.679c.168.004.335-.027.491-.092a1.25 1.25 0 00.418-.286 1.377 1.377 0 00.28-1.466 1.331 1.331 0 00-.28-.435 1.252 1.252 0 00-.418-.286 1.199 1.199 0 00-.491-.091h-5.842a6.942 6.942 0 012.464-2.627 6.459 6.459 0 013.378-.964h1.6c.328-.008.639-.152.869-.4a1.37 1.37 0 00.357-.928c0-.346-.128-.68-.357-.927a1.228 1.228 0 00-.869-.4h-1.6a8.913 8.913 0 00-5.222 1.715c-1.537 1.115-2.72 2.695-3.394 4.532h-2.124a1.2 1.2 0 00-.491.092 1.252 1.252 0 00-.418.285 1.33 1.33 0 00-.28.435 1.396 1.396 0 000 1.032c.065.163.16.311.28.435.12.123.262.22.418.285.156.065.323.096.491.092h1.533a10.39 10.39 0 00-.042.867c0 .29.018.583.042.87h-1.533a1.197 1.197 0 00-.491.092 1.25 1.25 0 00-.418.286 1.33 1.33 0 00-.28.434 1.396 1.396 0 000 1.032c.065.163.16.311.28.435.12.124.262.22.418.285.156.065.323.096.491.092h2.124c.674 1.838 1.857 3.417 3.394 4.532a8.915 8.915 0 005.222 1.716h1.6c.328-.009.639-.152.869-.4a1.37 1.37 0 00.357-.928c0-.347-.128-.68-.357-.928a1.228 1.228 0 00-.869-.4v.002z"
        ></path>
        <circle
          cx="159.856"
          cy="44.799"
          r="27.317"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[500]}
          d="M164.334 54.509h-2.068a8.353 8.353 0 01-4.369-1.248 8.982 8.982 0 01-3.186-3.397h4.847c.424-.01.828-.197 1.124-.517.296-.321.462-.752.462-1.2 0-.448-.166-.879-.462-1.2-.296-.32-.7-.506-1.124-.517h-5.929a8.739 8.739 0 010-2.25h8.637c.217.005.433-.035.634-.12.202-.083.386-.209.541-.369.155-.16.279-.35.363-.562a1.805 1.805 0 000-1.334 1.726 1.726 0 00-.363-.562 1.614 1.614 0 00-.541-.37 1.544 1.544 0 00-.634-.118h-7.555a8.973 8.973 0 013.187-3.396 8.348 8.348 0 014.368-1.247h2.068c.424-.01.828-.196 1.124-.517.296-.321.462-.752.462-1.2 0-.448-.166-.879-.462-1.2-.296-.32-.7-.506-1.124-.517h-2.068a11.523 11.523 0 00-6.753 2.219c-1.988 1.441-3.517 3.484-4.388 5.86h-2.748a1.55 1.55 0 00-.634.118c-.201.084-.385.21-.54.37a1.71 1.71 0 00-.363.562 1.806 1.806 0 000 1.334c.084.211.207.402.363.563.155.16.339.285.54.369.202.084.418.124.634.118h1.983c-.03.371-.054.745-.054 1.121s.024.754.054 1.126h-1.983a1.55 1.55 0 00-.634.118c-.201.084-.385.21-.54.37a1.71 1.71 0 00-.363.562 1.806 1.806 0 000 1.334c.084.211.207.402.363.562.155.16.339.286.54.37.202.083.418.124.634.118h2.748c.871 2.376 2.401 4.419 4.389 5.86a11.523 11.523 0 006.752 2.22h2.068a1.587 1.587 0 001.124-.518c.296-.321.462-.752.462-1.2 0-.448-.166-.879-.462-1.2-.296-.32-.7-.506-1.124-.517v.002z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationSavings;
