import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilitySolarPanels: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M15.94 17.9a10.288 10.288 0 00-1.25 3.51.09.09 0 01-.14.06l-5.5-3.73a.1.1 0 01.06-.18l6.75.2a.09.09 0 01.095.093.09.09 0 01-.015.047zM20.68 13.61a10.26 10.26 0 00-3.14 2.17.09.09 0 01-.16 0l-2.3-6.42a.11.11 0 01.16-.12l5.46 4.23a.1.1 0 01-.02.14zM27 12.72a.1.1 0 01-.11.12 10.33 10.33 0 00-3.84 0 .1.1 0 01-.11-.12l1.96-6.65a.11.11 0 01.16-.047.11.11 0 01.04.047l1.9 6.65zM34.92 9.33l-2.3 6.42a.09.09 0 01-.16 0 10.261 10.261 0 00-3.14-2.17.099.099 0 01-.047-.085.1.1 0 01.047-.085l5.46-4.23a.11.11 0 01.14.15zM41 17.74l-5.5 3.73a.09.09 0 01-.14-.06 10.288 10.288 0 00-1.25-3.51.09.09 0 01-.003-.095.09.09 0 01.083-.045l6.75-.2a.1.1 0 01.095.068.1.1 0 01-.035.112zM33.1 22H16.9a.1.1 0 01-.1-.11 8.25 8.25 0 0116.4 0 .1.1 0 01-.1.11zM23 24H10a1 1 0 00-1 1v7a1 1 0 001 1h13a1 1 0 001-1v-7a1 1 0 00-1-1zM40 24H27a1 1 0 00-1 1v7a1 1 0 001 1h13a1 1 0 001-1v-7a1 1 0 00-1-1zM23 35H10a1 1 0 00-1 1v7a1 1 0 001 1h13a1 1 0 001-1v-7a1 1 0 00-1-1zM40 35H27a1 1 0 00-1 1v7a1 1 0 001 1h13a1 1 0 001-1v-7a1 1 0 00-1-1z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilitySolarPanels;
