import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';
import { Input, useTheme } from '@chakra-ui/react';

interface Props {
  date?: Date;
  onChange: (date: Date) => void;
  timeIntervals?: number;
  dateFormat?: string;
  minDate?: Date;
  maxDate?: Date;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  startDate?: Date;
  endDate?: Date;
  placeholder?: string;
  showTimeSelect?: boolean;
}

const DateTimePicker: React.FC<React.PropsWithChildren<Props>> = ({
  date,
  onChange,
  timeIntervals = 60,
  minDate,
  maxDate,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  placeholder,
  showTimeSelect = true,
  dateFormat = showTimeSelect ? 'dd/MM/yyyy - HH:mm' : 'dd/MM/yyyy',
}) => {
  const { colors } = useTheme();

  return (
    <Wrapper themeColors={colors}>
      <DatePicker
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate}
        selected={date}
        onChange={onChange}
        showTimeSelect={showTimeSelect}
        customInput={<Input />}
        dateFormat={dateFormat}
        timeIntervals={timeIntervals}
        minDate={minDate}
        maxDate={maxDate}
        placeholderText={placeholder}
      />
    </Wrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Wrapper = styled.div<{ themeColors: any }>`
  .react-datepicker-popper {
    z-index: 2;
  }

  .react-datepicker {
    font-family: 'Circular', 'Helvetica', 'Myriad', sans-serif;
    overflow: hidden;
    font-size: 1.5rem;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 115px;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    height: 8px;
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${(props) => props.themeColors.primary[600]};

    &:hover {
      border-right-color: ${(props) => props.themeColors.primary[700]};
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: ${(props) => props.themeColors.primary[600]};

    &:hover {
      border-left-color: ${(props) => props.themeColors.primary[700]};
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
    min-width: 180px;
    background: white;
  }

  .react-datepicker__header {
    border-radius: 0;
    background: ${(props) => props.themeColors.primary[50]};
  }

  .react-datepicker,
  .react-datepicker__header,
  .react-datepicker__time-container {
    border-color: ${(props) => props.themeColors.gray[100]};
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: inherit;
    font-weight: 600;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    margin: 0 1px 0 0;
    height: auto;
    padding: 7px 10px;

    &:hover {
      background: ${(props) => props.themeColors.primary[300]};
    }
  }

  .react-datepicker__day:hover {
    background: ${(props) => props.themeColors.primary[300]};
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    line-height: 1.7em;
    width: 1.7em;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background: ${(props) => props.themeColors.primary[500]};
    font-weight: 400;

    &:hover {
      background: ${(props) => props.themeColors.primary[300]};
    }
  }

  .react-datepicker__time-container,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 7em;
  }
`;

export default DateTimePicker;
