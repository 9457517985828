import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationEStep: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_701_2310"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_701_2310)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M146 203.5c0 11.874-9.626 21.5-21.5 21.5s-21.5-9.626-21.5-21.5c0-11.875 9.626-21.5 21.5-21.5s21.5 9.625 21.5 21.5z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M124.5 221c9.665 0 17.5-7.836 17.5-17.5 0-9.665-7.835-17.5-17.5-17.5s-17.5 7.835-17.5 17.5c0 9.664 7.835 17.5 17.5 17.5zm0 4c11.874 0 21.5-9.626 21.5-21.5 0-11.875-9.626-21.5-21.5-21.5s-21.5 9.625-21.5 21.5c0 11.874 9.626 21.5 21.5 21.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M135 203.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M124.5 210a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm0 4c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5 4.701 10.5 10.5 10.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M254 203.5c0 11.874-9.626 21.5-21.5 21.5s-21.5-9.626-21.5-21.5c0-11.875 9.626-21.5 21.5-21.5s21.5 9.625 21.5 21.5z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M232.5 221c9.665 0 17.5-7.836 17.5-17.5 0-9.665-7.835-17.5-17.5-17.5s-17.5 7.835-17.5 17.5c0 9.664 7.835 17.5 17.5 17.5zm0 4c11.874 0 21.5-9.626 21.5-21.5 0-11.875-9.626-21.5-21.5-21.5s-21.5 9.625-21.5 21.5c0 11.874 9.626 21.5 21.5 21.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M243 203.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M232.5 210a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm0 4c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5 4.701 10.5 10.5 10.5zM156.217 86.445c-.001 0-.002 0-.002-.002l-.001-.002-23.749-6.364a2 2 0 00-2.45 1.415l-.517 1.931a2 2 0 001.414 2.45l16.182 4.336a2 2 0 011.423 2.416l-15.033 60.295a2 2 0 001.457 2.425l1.94.484a2.001 2.001 0 002.425-1.457l16.931-67.908a.012.012 0 00-.009-.016l-.011-.003z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M137.196 159.731a2 2 0 00-2.732-.732l-1.732 1a2 2 0 00-.732 2.732l25.19 43.631a.998.998 0 00.582.459c.383.114.828.178 1.228.178h72a2 2 0 002-2v-2a2 2 0 00-2-2h-68.823a1.999 1.999 0 01-1.732-1l-23.249-40.268z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M130.09 155.397a2.001 2.001 0 012.425-1.457l6.792 1.693a2.001 2.001 0 011.457 2.425l-11.855 47.544a2 2 0 01-2.424 1.457l-6.792-1.693a2.001 2.001 0 01-1.457-2.425l11.854-47.544z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M128.15 154.913a4 4 0 014.849-2.914l6.792 1.694a4 4 0 012.913 4.849l-11.854 47.544a4 4 0 01-4.849 2.914l-6.792-1.694a4 4 0 01-2.913-4.849l11.854-47.544zm10.673 2.661l-6.792-1.693-11.854 47.544 6.792 1.694 11.854-47.545zM168.637 90.888a2 2 0 012.68.906l5.225 10.567 4.344-4.41a2 2 0 013.196.474l6.447 12.273a2 2 0 11-3.541 1.86l-5.174-9.849-4.394 4.461a1.999 1.999 0 01-3.218-.517l-6.471-13.086a2 2 0 01.906-2.68zM215.436 70.11a2 2 0 011.235 2.545l-3.861 11.138H219a2 2 0 011.905 2.609l-4.219 13.206a2 2 0 11-3.81-1.217l3.386-10.598H210a1.999 1.999 0 01-1.89-2.655l4.782-13.794a2 2 0 012.544-1.234zM285.353 120.142a2 2 0 01-2.362 1.557l-11.547-2.371.809 6.137a2 2 0 01-2.337 2.23l-13.645-2.456a2 2 0 11.709-3.936l10.95 1.97-.819-6.208a1.998 1.998 0 012.385-2.22l14.3 2.936a2 2 0 011.557 2.361zM268.492 157.477a2 2 0 01-2.807.347l-9.294-7.253-2 5.859a2 2 0 01-3.084.96l-11.136-8.259a2 2 0 012.383-3.213l8.936 6.628 2.023-5.926a2 2 0 013.123-.931l11.509 8.981a2 2 0 01.347 2.807zM204.573 137.264a2 2 0 01-.337 2.808l-14 11a1.843 1.843 0 01-.201.139c-3.324 2.01-7.137 5.776-8.224 10.465-1.034 4.458.258 10.412 8.473 17.29a2 2 0 11-2.568 3.067c-8.985-7.522-11.276-14.901-9.802-21.26 1.408-6.07 6.12-10.577 9.944-12.92l13.906-10.926a2 2 0 012.809.337z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M234.131 105.842a2.001 2.001 0 01-.442 2.794l-12.719 9.24a2.001 2.001 0 01-2.352-3.236l12.719-9.241a2 2 0 012.794.443zM244.131 118.842a2 2 0 01-.443 2.794l-12.719 9.24a2 2 0 11-2.351-3.236l12.719-9.241a2 2 0 012.794.443z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M223.797 144.441c-.001.001-.002.003-.001.004a.003.003 0 010 .004 16.502 16.502 0 01-23.045-3.652 16.497 16.497 0 013.644-23.043c.002-.001.002-.003.001-.004-.001-.002-.001-.004.001-.005l8.719-6.334a2 2 0 012.793.442l17.046 23.462a2 2 0 01-.443 2.793l-8.715 6.333z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M211.94 109.793a4 4 0 015.587.885l17.046 23.461a4.002 4.002 0 01-.885 5.588l-8.592 6.242c-.04.034-.082.067-.125.099a18.503 18.503 0 01-25.838-4.095 18.503 18.503 0 013.956-25.741c.042-.036.086-.071.132-.104l8.719-6.335zm-6.244 9.481c-.04.034-.081.067-.124.098a14.495 14.495 0 00-5.794 9.459 14.496 14.496 0 0012.053 16.589 14.502 14.502 0 0010.673-2.505c.038-.032.077-.063.117-.092l8.716-6.332-17.046-23.462-8.595 6.245zM20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationEStep;
