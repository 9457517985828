import { Box, VStack, Text, Center } from '@wegroup/design-system';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../../../common/src/components/LoadingSpinner';
import TreeListItem from './TreeListItem';

export interface TreeItem {
  key: string;
  value: string;
  children?: TreeItem[];
}

interface Props {
  data: TreeItem[];
  onView?: (dataItemKey: string) => void;
  onRemove?: (dataItemKey: string) => void;
  isLoading?: boolean;
  noItemsMessage?: string;
  initiallyOpen?: boolean;
  isRemoveEnabled?: boolean;
}

const TreeList: React.FC<Props> = ({
  data,
  onView,
  onRemove,
  isLoading,
  noItemsMessage,
  initiallyOpen,
  isRemoveEnabled,
}) => {
  const { t } = useTranslation();

  const getFullOpenMappingObject = useCallback(
    (data?: TreeItem[]) => {
      if (data?.length === 0) return {};

      let obj: Record<string, boolean> = {};

      data?.forEach((dataItem) => {
        // Recursively get children object
        const childObj = getFullOpenMappingObject(dataItem.children);

        // Merge the objects
        obj = {
          ...childObj,
          ...obj,
        };

        // Set the value for this entry
        obj[dataItem.key] = true;
      });

      return obj;
    },
    [data],
  );

  const [isOpenMapping, setIsOpenMapping] = useState(() =>
    initiallyOpen ? getFullOpenMappingObject(data) : {},
  );

  if (isLoading)
    return (
      <Center p="6">
        <LoadingSpinner />
      </Center>
    );

  if (data?.length === 0)
    return (
      <Center p="6">
        <Text textStyle="h4Headline">
          {noItemsMessage || t('No results found')}
        </Text>
      </Center>
    );

  const handleOpenDataItem = (dataItemKey: string) => {
    setIsOpenMapping((prevObj) => ({ ...prevObj, [dataItemKey]: true }));
  };

  const handleCloseDataItem = (dataItemKey: string) => {
    setIsOpenMapping((prevObj) => ({ ...prevObj, [dataItemKey]: false }));
  };

  return (
    <Box>
      {data?.map((dataItem, index) => {
        return (
          <VStack key={index} spacing="4" align="stretch">
            <TreeListItem
              isParentOpen={true}
              dataItem={dataItem}
              onView={onView}
              onRemove={onRemove}
              isOpenMapping={isOpenMapping}
              onOpen={handleOpenDataItem}
              onClose={handleCloseDataItem}
              level={1}
              isRemoveEnabled={isRemoveEnabled}
            />
          </VStack>
        );
      })}
    </Box>
  );
};

export default TreeList;
