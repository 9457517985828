import React, { ReactElement } from 'react';
import { VStack, Box, Center, Skeleton } from '@wegroup/design-system';

export default function NavigationLoading(): ReactElement | null {
  return (
    <VStack
      height="100vh"
      width="280px"
      zIndex="12"
      background="white"
      borderRightColor="gray.100"
      borderRightWidth="1px"
      borderRightStyle="solid"
      spacing="6"
    >
      <Box px={6}>
        <Center height="100px" width="100%" my={6} mb="10">
          <Skeleton height="2" width="4" />
        </Center>
        {[...Array(5).keys()].map((_, index) => (
          <NavigationLoadingSkeleton key={index} />
        ))}
      </Box>
    </VStack>
  );
}

const NavigationLoadingSkeleton = () => {
  return <Skeleton marginBottom="1.8rem" height="4.8rem" width="22rem" />;
};
