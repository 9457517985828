import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationFunds: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3952_10352"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3952_10352)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M54.156 42h201a6 6 0 016 6v189h-207V42z"
        ></path>
        <rect
          width="90.034"
          height="13"
          x="47.77"
          y="171.864"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6.5"
          transform="rotate(-48 47.77 171.864)"
        ></rect>
        <rect
          width="78.694"
          height="13"
          x="112.18"
          y="104.828"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6.5"
          transform="rotate(45 112.18 104.828)"
        ></rect>
        <rect
          width="70.618"
          height="13"
          x="211.578"
          y="59.998"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6.5"
          transform="rotate(41.833 211.578 59.998)"
        ></rect>
        <rect
          width="114.757"
          height="13"
          x="152.646"
          y="164.794"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6.5"
          transform="rotate(-61.758 152.646 164.794)"
        ></rect>
        <rect
          width="96.867"
          height="13"
          x="254.865"
          y="111.804"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6.5"
          transform="rotate(-61.758 254.865 111.804)"
        ></rect>
        <circle
          cx="110.156"
          cy="113"
          r="13"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="160.156"
          cy="163"
          r="13"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="212.156"
          cy="69"
          r="13"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="261.156"
          cy="110"
          r="13"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M43.156 38.5a8.5 8.5 0 0117 0V237h-17V38.5z"
        ></path>
        <rect
          width="59"
          height="20"
          x="106.156"
          y="207"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <rect
          width="59"
          height="20"
          x="106.156"
          y="187"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <rect
          width="59"
          height="20"
          x="177.156"
          y="207"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <rect
          width="59"
          height="20"
          x="177.156"
          y="187"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <rect
          width="59"
          height="20"
          x="187.156"
          y="167"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <rect
          width="59"
          height="20"
          x="187.156"
          y="147"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <rect
          width="59"
          height="20"
          x="254.156"
          y="207"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <rect
          width="59"
          height="20"
          x="254.156"
          y="187"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <rect
          width="59"
          height="20"
          x="264.156"
          y="167"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <rect
          width="59"
          height="20"
          x="264.156"
          y="147"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <rect
          width="59"
          height="20"
          x="255.156"
          y="127"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationFunds;
