import { configureStore } from '@reduxjs/toolkit';
import localforage from 'localforage';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import rootReducer, { RootState } from './reducers/rootReducer';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: localforage,
  whitelist: ['offers'],
  timeout: 1000,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: {
    name: 'WeGroup | Product Factory',
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers/rootReducer', () => {
    const newRootReducer = require('./reducers/rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store.getState;

export const useLouiseDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useLouiseSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

export const persistor = persistStore(store);
