import React, { useState } from 'react';
import { AddressInputProps } from '..';
import AddressSearchNL from './address-search-nl/AddressSearchNL';
import AddressSearch from './address-search/AddressSearch';

export interface AddressSearchProps {
  onManualModeOn: () => void;
}

const AddressSearchPicker: React.FC<AddressInputProps & AddressSearchProps> = ({
  country,
  ...props
}) => {
  const [searchInputCountry, setSearchInputCountry] = useState(country);

  const handleCountryChange = (_country: string) => {
    setSearchInputCountry(_country);
    props.onChange();
  };

  if (searchInputCountry === 'NL')
    return (
      <AddressSearchNL
        {...props}
        onCountryChange={handleCountryChange}
        country={searchInputCountry}
      />
    );

  return (
    <AddressSearch
      {...props}
      onCountryChange={handleCountryChange}
      country={searchInputCountry}
    />
  );
};

export default AddressSearchPicker;
