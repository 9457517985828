import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import { RecommendationLevel } from '../../../../../../types/Recommendations';

interface FilterObject {
  q?: string;
  level?: RecommendationLevel;
}
export interface SelectionController {
  selectedRecommendations: string[];
  filter: FilterObject;
}

const initialState: SelectionController = {
  selectedRecommendations: [],
  filter: {},
};
const recommendationSlice = createSlice({
  name: 'RecommendationSlice',
  initialState: initialState,
  reducers: {
    addToSelection(state, action: PayloadAction<string>) {
      const { payload } = action;
      state.selectedRecommendations = uniq([
        ...state.selectedRecommendations,
        payload,
      ]);
    },
    removeFromSelection(state, action: PayloadAction<string>) {
      const { payload } = action;
      state.selectedRecommendations = state.selectedRecommendations.filter(
        (id) => id !== payload,
      );
    },
    changeFilter(state, action: PayloadAction<FilterObject>) {
      state.filter = action.payload;
    },
    clearSelection(state) {
      state.selectedRecommendations = [];
    },
    resetRecommendations() {
      return initialState;
    },
  },
});

export const {
  addToSelection,
  removeFromSelection,
  clearSelection,
  changeFilter,
  resetRecommendations,
} = recommendationSlice.actions;

export default recommendationSlice.reducer;
