import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyDressing: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M39.81 24.507l-3.063 3.095a.557.557 0 01-.407.18.549.549 0 01-.406-.18l-1.416-1.429c-.116-.106-.243 0-.243.13v17.106a.596.596 0 01-.17.418.575.575 0 01-.41.173H16.286a.575.575 0 01-.41-.173.596.596 0 01-.17-.418V26.28c0-.153-.128-.236-.244-.13l-1.415 1.43a.558.558 0 01-.406.179.548.548 0 01-.407-.18l-3.063-3.07a.591.591 0 01-.172-.42.601.601 0 01.172-.42l4.932-4.973a2.3 2.3 0 011.613-.685h3.632a.23.23 0 01.137.046c.04.03.069.071.084.12a4.646 4.646 0 001.638 2.434 4.508 4.508 0 002.748.938c.992 0 1.957-.33 2.749-.938a4.645 4.645 0 001.638-2.435.239.239 0 01.11-.145.229.229 0 01.18-.02H33.3a2.3 2.3 0 011.613.685l4.932 4.974a.592.592 0 01.154.426.6.6 0 01-.189.412z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 6a1 1 0 00-1 1h-2a3 3 0 114 2.83v.52l6.253 2.78c1.48.657 1.011 2.87-.61 2.87H18.357c-1.62 0-2.09-2.213-.61-2.87L24 10.35V9a1 1 0 011-1 1 1 0 100-2zm-4.288 8h8.576L25 12.094 20.712 14z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyDressing;
