import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycleElectricMountain: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3628_10477"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3628_10477)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M57.115 206.513L24.36 227.986c-1.64 1.075-.914 3.623 1.047 3.672l295.651 7.306a2 2 0 001.931-1.322l32.046-88.965a2 2 0 00-1.881-2.677h-24.743a20.007 20.007 0 00-10.873 3.213l-35.007 22.675a19.993 19.993 0 01-8.808 3.107l-46.051 4.779a20 20 0 01-4.341-.023l-38.425-4.403a20.006 20.006 0 00-13.561 3.357l-20.813 14.223a20.011 20.011 0 01-6.603 2.931L97.817 206.96a20.004 20.004 0 01-8.668.155l-17.082-3.475a20 20 0 00-14.951 2.873z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M196.8 72.708l-19.046 73.146-3.871-1.008L192.929 71.7l3.871 1.008z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M147.549 152.904c4.326 21.664-9.729 42.732-31.393 47.058-21.663 4.326-42.732-9.729-47.058-31.393-4.326-21.664 9.73-42.733 31.393-47.058 21.664-4.326 42.733 9.729 47.058 31.393z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M136.762 155.058c3.137 15.706-7.054 30.981-22.76 34.117-15.706 3.136-30.98-7.054-34.117-22.76-3.136-15.706 7.054-30.981 22.76-34.117 15.706-3.136 30.981 7.054 34.117 22.76z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M280.917 126.274c4.326 21.664-9.73 42.732-31.393 47.058-21.664 4.326-42.733-9.729-47.058-31.393-4.326-21.664 9.729-42.732 31.393-47.058 21.664-4.326 42.732 9.729 47.058 31.393z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M270.13 128.428c3.136 15.706-7.054 30.981-22.76 34.117-15.707 3.136-30.981-7.054-34.117-22.76-3.137-15.706 7.053-30.981 22.76-34.117 15.706-3.136 30.981 7.054 34.117 22.76z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M109.795 160.443l12.324-70.925a1 1 0 00-1.181-1.152l-16.221 3.239"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M121.977 97.336c-1.579.315-2.157 2.273-1.002 3.395l50.924 49.487a1.998 1.998 0 001.785.527l66.826-13.343c1.579-.316 2.157-2.274 1.002-3.396l-50.924-49.487a2 2 0 00-1.785-.527l-66.826 13.344zm8.243 2.433c-1.579.315-2.157 2.273-1.002 3.395l43.96 42.72c.472.458 1.14.655 1.785.527l57.303-11.442c1.58-.316 2.158-2.274 1.003-3.396l-43.96-42.72a2 2 0 00-1.786-.526L130.22 99.769z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M186.775 145.072c1.298 6.499-2.919 12.819-9.418 14.117-6.499 1.298-12.819-2.919-14.117-9.418-1.298-6.499 2.919-12.82 9.418-14.117 6.499-1.298 12.82 2.919 14.117 9.418z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M176.574 155.267a8 8 0 10-3.134-15.692 8 8 0 003.134 15.692zm.783 3.922c6.499-1.298 10.716-7.618 9.418-14.117-1.297-6.499-7.618-10.716-14.117-9.418-6.499 1.297-10.716 7.618-9.418 14.117 1.298 6.499 7.618 10.716 14.117 9.418zM173.264 77.408a2 2 0 011.57-2.353l27.458-5.483a2 2 0 01.783 3.923l-27.458 5.482a2 2 0 01-2.353-1.57zM20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2zM129.306 21.035a1.248 1.248 0 011.672.565l3.26 6.594 2.711-2.752a1.249 1.249 0 011.994.296l4.024 7.659a1.249 1.249 0 01-2.21 1.16l-3.229-6.146-2.742 2.784a1.248 1.248 0 01-2.008-.323l-4.038-8.165a1.249 1.249 0 01.566-1.672zM158.509 8.07c.651.225.996.936.77 1.587l-2.409 6.95h3.862a1.247 1.247 0 011.189 1.628l-2.632 8.241a1.248 1.248 0 01-2.378-.76l2.112-6.612h-3.907a1.25 1.25 0 01-1.179-1.657l2.984-8.607a1.248 1.248 0 011.588-.77zM202.137 39.29a1.247 1.247 0 01-1.473.971l-7.206-1.48.505 3.83a1.246 1.246 0 01-1.458 1.392l-8.515-1.533a1.248 1.248 0 01.442-2.456l6.833 1.23-.511-3.874a1.246 1.246 0 011.488-1.386l8.924 1.832c.675.139 1.11.798.971 1.474zM191.616 62.587a1.248 1.248 0 01-1.752.217l-5.8-4.526-1.247 3.656a1.25 1.25 0 01-1.925.599l-6.949-5.154a1.249 1.249 0 011.487-2.005l5.576 4.136 1.263-3.698a1.246 1.246 0 011.949-.58l7.181 5.604c.544.424.641 1.208.217 1.751zM151.73 49.974a1.248 1.248 0 01-.21 1.753l-8.736 6.864c-.04.031-.082.06-.126.086-2.074 1.254-4.453 3.604-5.132 6.53-.645 2.782.161 6.498 5.288 10.79a1.248 1.248 0 01-1.603 1.914c-5.606-4.694-7.036-9.299-6.116-13.267.878-3.788 3.818-6.6 6.205-8.062l8.678-6.818a1.248 1.248 0 011.752.21z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M170.175 30.366a1.249 1.249 0 01-.276 1.743l-7.937 5.767a1.25 1.25 0 01-1.467-2.02l7.936-5.766a1.25 1.25 0 011.744.276zM176.414 38.479a1.247 1.247 0 01-.276 1.743l-7.937 5.766a1.248 1.248 0 11-1.467-2.019l7.937-5.767a1.248 1.248 0 011.743.277z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M163.726 54.452l-.001.003v.003A10.296 10.296 0 01151.619 37.8l.001-.003v-.003l5.441-3.952a1.248 1.248 0 011.743.276l10.637 14.64a1.248 1.248 0 01-.277 1.743l-5.438 3.951z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M156.327 32.832a2.495 2.495 0 013.486.552l10.637 14.64a2.496 2.496 0 01-.552 3.487l-5.362 3.895a1.279 1.279 0 01-.078.062 11.542 11.542 0 01-16.123-2.556 11.547 11.547 0 012.469-16.062c.026-.023.054-.044.082-.065l5.441-3.953zm-3.896 5.916a1.273 1.273 0 01-.078.061 9.046 9.046 0 00-1.998 12.636 9.046 9.046 0 0012.564 2.055c.024-.02.048-.039.073-.057l5.439-3.952-10.637-14.64-5.363 3.897z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBicycleElectricMountain;
