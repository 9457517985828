import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyFireplace: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M39.17 10.48h-28.3a3.48 3.48 0 00-3.62 3.28V38.5a1 1 0 001 1H9.7a1 1 0 001-1V21.89c0-3.81 3.45-6.93 7.66-6.93h13.33c4.2 0 7.65 3.12 7.65 6.93V38.5a1 1 0 001 1h1.46a1 1 0 001-1V13.76a3.48 3.48 0 00-3.63-3.28z"
    ></path>
    <path
      fill="currentColor"
      d="M21.52 18.48s.25 1.92.26 3.12a8.2 8.2 0 01-1.91 5.8 6.66 6.66 0 00-1.81 4.51c0 7 5.77 7.59 5.77 7.59a8.16 8.16 0 01-1.15-4.09 6.47 6.47 0 011.73-4.08s-.58 3.5 1.15 3.5c.78 0 1.15-1.17 1.15-1.17a3 3 0 01.58 1.75 6.3 6.3 0 01-1.15 4.09s5.76-.58 5.76-7.59c0-3.5-3.46-5.84-3.46-5.84v1.75A1.14 1.14 0 0127.29 29c-1.52 0-1.15-2.34-1.15-3.5a9.94 9.94 0 00-.58-4.09c-.67-1.73-4.04-2.93-4.04-2.93zM43 41.5H7a1.5 1.5 0 000 3h36a1.5 1.5 0 000-3zM38.85 8.42h-27.7a5.56 5.56 0 01-2.06-.36 1.08 1.08 0 01-.3-.16c-.16-.14-.3-.31-.46-.45l-.43-.4-.84-.76-.16-.15A1.23 1.23 0 017.69 4h34.62a1.23 1.23 0 01.79 2.14l-1.56 1.44a3.251 3.251 0 01-.54.44 4.53 4.53 0 01-2.15.4z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyFireplace;
