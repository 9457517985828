import React, { ReactElement } from 'react';
import { Skeleton, SkeletonCircle, Flex, Spacer } from '@wegroup/design-system';
import GeneralPageLayout from '../../../../common/src/components/GeneralPageLayout';

export default function NotificationCenterLoading(): ReactElement | null {
  return (
    <GeneralPageLayout>
      <Skeleton w="300px" h="45px" />
      <Flex w="100%" mt="16">
        <Skeleton w="120px" h="30px" mr="8" />
        <Skeleton w="140px" h="30px" mr="8" />
        <Spacer />
        <Skeleton w="140px" h="50px" />
      </Flex>

      <Flex mt="12" gridGap="8">
        <Skeleton w="100%" h="140px" />
        <SkeletonCircle size="30px" minWidth="30px" />
      </Flex>
      <Flex mt="12" gridGap="8">
        <Skeleton w="100%" h="140px" />
        <SkeletonCircle size="30px" minWidth="30px" />
      </Flex>
      <Flex mt="12" gridGap="8">
        <Skeleton w="100%" h="140px" />
        <SkeletonCircle size="30px" minWidth="30px" />
      </Flex>
    </GeneralPageLayout>
  );
}
