import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskAnalysisGeneralAdvisoryReport: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.089 16.12a31 31 0 0012.585 22.656c.303 1.462 1.683 2.622 3.445 2.908a35.02 35.02 0 01-1.6 1.141 2.72 2.72 0 01-3.037 0A35 35 0 018.042 15.51c-.057-1.164.635-2.222 1.693-2.71l14.01-6.452a3 3 0 012.51 0l14.01 6.452c1.058.488 1.75 1.546 1.692 2.71a35.008 35.008 0 01-.565 4.76l-3.557-3.297c.03-.284.055-.568.076-.852L25 10.174 12.089 16.12z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.99 18.758a2.5 2.5 0 00-2.5 2.5v16.007a2.5 2.5 0 002.5 2.5h11.627a2.5 2.5 0 002.5-2.5v-11.24a2.5 2.5 0 00-.732-1.767l-4.768-4.768a2.5 2.5 0 00-1.768-.732h-6.86zm.5 18.007V21.758h3.813v4.667a2.5 2.5 0 002.5 2.5h4.314v7.84H29.49zm10.32-10.84l-3.507-3.506v3.506h3.507z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisGeneralAdvisoryReport;
