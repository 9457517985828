import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsCaretRight: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M32.29 24.292a1 1 0 010 1.416L21.68 36.29c-.63.629-1.706.182-1.706-.708V14.419c0-.89 1.075-1.337 1.706-.708l10.61 10.58z"
    />
  </Icon>
);

export default GeneralActionsCaretRight;
