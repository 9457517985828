import { VStack, HStack, Text, Spacer, useTheme, Link } from '@chakra-ui/react';
import { IconActionClose } from 'wg-fe-ui';
import React from 'react';
import { formatBytes } from '../../../../../common/src/utils/commonUtils';
import { Theme } from '../../../hooks/useTheme';

export interface FileWithExternalLink {
  file: File;
  externalLink: string;
}

interface SingleFileProps {
  file: FileWithExternalLink;
  onRemove: (index: number) => void;
}

const SingleFileLink: React.FC<React.PropsWithChildren<SingleFileProps>> = ({
  file,
  onRemove,
}) => {
  const { colors, space } = useTheme<Theme>();

  return (
    <HStack spacing="2" bg="gray.ultraLight" px="4" py="3" alignItems="center">
      <Link color="black" variant="gray" href={file.externalLink} isExternal>
        {file.file.name}
      </Link>
      <Text textStyle="smallBody" color="text.gray">
        ({formatBytes(file.file.size)})
      </Text>
      <Spacer />
      <IconActionClose
        color={colors.gray[300]}
        size={space[6]}
        cursor="pointer"
        onClick={onRemove}
      />
    </HStack>
  );
};

export interface UploadLinkListProps {
  files?: FileWithExternalLink[];
  file?: FileWithExternalLink;
  onRemove: ((index: number) => void) | (() => void);
}

const UploadLinkList: React.FC<
  React.PropsWithChildren<UploadLinkListProps>
> = ({ files = [], file, onRemove }) => {
  if (file) {
    return <SingleFileLink file={file} onRemove={onRemove} />;
  }

  return (
    <VStack spacing="2" alignItems="stretch">
      {files.map((file, index) => {
        return (
          <SingleFileLink
            key={index}
            file={file}
            onRemove={() => onRemove(index)}
          />
        );
      })}
    </VStack>
  );
};

export default UploadLinkList;
