import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyBaggageLuggage: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M10 16h5v23h-5a1 1 0 01-1-1V17a1 1 0 011-1zM35 16h5a1 1 0 011 1v21a1 1 0 01-1 1h-5V16zM29 16v-4a1 1 0 00-1-1h-6a1 1 0 00-1 1v4h-4v23h16V16h-4zm-6-2.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V16h-4v-2.5z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyBaggageLuggage;
