import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersNote: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M42.473 25.752a1.8 1.8 0 010 2.546L28.298 42.473a1.8 1.8 0 01-2.546-2.546l14.175-14.175a1.8 1.8 0 012.546 0z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.713 8.713A5.85 5.85 0 0112.85 7h24.3A5.85 5.85 0 0143 12.85v14.175a1.8 1.8 0 01-1.8 1.8H29.05a.225.225 0 00-.225.225V41.2a1.8 1.8 0 01-1.8 1.8H12.85A5.85 5.85 0 017 37.15v-24.3a5.85 5.85 0 011.713-4.137zM12.85 10.6a2.25 2.25 0 00-2.25 2.25v24.3a2.25 2.25 0 002.25 2.25h12.375V29.05a3.825 3.825 0 013.825-3.825H39.4V12.85a2.25 2.25 0 00-2.25-2.25h-24.3z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralOthersNote;
