import { snakeCase } from 'lodash';
import {
  orange,
  lightBlue,
  oceanBlue,
  darkBlue,
  lightRed,
  darkRed,
  pinkPurple,
  darkGreen,
  lightGreen,
  oceanGreen,
  darkGrey,
  hillewaereBlue,
  sidefishBlue,
  immothekerOrange,
} from 'wg-fe-ui/dist/themes';
import deepPurple from '../../constants/heinenoordPurpleTheme';
import verzekerjeTheme from '../../constants/verzekerjeTheme';
import grayBlue from '../../constants/grayBlue';

export const getThemeObject = (name) => {
  switch (snakeCase(name)) {
    case 'orange':
      return orange();
    case 'light_blue':
      return lightBlue();
    case 'ocean_blue':
      return oceanBlue();
    case 'dark_blue':
      return darkBlue();
    case 'gray_blue':
      return grayBlue();
    case 'sidefish_blue':
      return sidefishBlue();
    case 'light_red':
      return lightRed();
    case 'dark_red':
      return darkRed();
    case 'pink_purple':
      return pinkPurple();
    case 'dark_green':
      return darkGreen();
    case 'light_green':
      return lightGreen();
    case 'ocean_green':
      return oceanGreen();
    case 'dark_grey':
      return darkGrey();
    case 'hillewaere_blue':
      return hillewaereBlue();
    case 'immotheker_orange':
      return immothekerOrange();
    case 'deep_purple':
      return deepPurple();
    case 'verzekerje':
      return verzekerjeTheme();
    default:
      return orange();
  }
};

export function retrieveAllThemes() {
  return {
    orange: orange(),
    light_blue: lightBlue(),
    ocean_blue: oceanBlue(),
    dark_blue: darkBlue(),
    gray_blue: grayBlue(),
    sidefish_blue: sidefishBlue(),
    light_red: lightRed(),
    dark_red: darkRed(),
    pink_purple: pinkPurple(),
    dark_green: darkGreen(),
    light_green: lightGreen(),
    ocean_green: oceanGreen(),
    dark_grey: darkGrey(),
    hillewaere_blue: hillewaereBlue(),
    immotheker_orange: immothekerOrange(),
    deep_purple: deepPurple(),
    verzekerje: verzekerjeTheme(),
  };
}
