import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycleHybrid: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_893_3590"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_893_3590)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2zM214 114.367l-33 68-3.599-1.746 33-68 3.599 1.746z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M150 183.367c0 22.091-17.909 40-40 40s-40-17.909-40-40c0-22.092 17.909-40 40-40s40 17.908 40 40z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M139 183.367c0 16.016-12.984 29-29 29s-29-12.984-29-29c0-16.017 12.984-29 29-29s29 12.983 29 29z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M286 183.367c0 22.091-17.909 40-40 40s-40-17.909-40-40c0-22.092 17.909-40 40-40s40 17.908 40 40z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M275 183.367c0 16.016-12.984 29-29 29s-29-12.984-29-29c0-16.017 12.984-29 29-29s29 12.983 29 29z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M111.5 183.367l25.974-67.139a1 1 0 00-.933-1.361H120"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M135.804 123.867c-1.611 0-2.561 1.806-1.648 3.133l40.248 58.5c.373.543.989.867 1.648.867h68.144c1.611 0 2.561-1.807 1.648-3.134l-40.248-58.5a2 2 0 00-1.648-.866h-68.144zm7.607 4c-1.611 0-2.561 1.806-1.648 3.133l34.744 50.5a2 2 0 001.648.867h58.434c1.611 0 2.561-1.807 1.648-3.134l-34.744-50.5a2.001 2.001 0 00-1.648-.866h-58.434z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M190 183.367c0 6.627-5.373 12-12 12s-12-5.373-12-12c0-6.628 5.373-12 12-12s12 5.372 12 12z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M178 191.367a8 8 0 100-16 8 8 0 000 16zm0 4c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12zM190 114.367a2 2 0 012-2h28a2 2 0 110 4h-28a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBicycleHybrid;
