import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceValuables: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_1833_6777"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_1833_6777)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <rect
          width="150"
          height="150"
          x="103"
          y="72"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          rx="4"
        ></rect>
        <rect
          width="104"
          height="104"
          x="126"
          y="95"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          rx="4"
        ></rect>
        <rect
          width="10"
          height="30"
          x="121"
          y="109"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          rx="2"
        ></rect>
        <rect
          width="10"
          height="30"
          x="121"
          y="155"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          rx="2"
        ></rect>
        <rect
          width="61"
          height="50"
          x="148"
          y="107"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="4"
        ></rect>
        <path
          fill={colors.primary[300]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M148 111a4 4 0 014-4h53a4 4 0 014 4v8h-61v-8z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M158 127.5h9.5M158 137.5h9.5M158 147.5h9.5M190 127.5h9.5M190 137.5h9.5M190 147.5h9.5M174 127.5h9.5M174 137.5h9.5M174 147.5h9.5"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M188 182l-37.439-1.526a3.655 3.655 0 01-2.522-1.161 3.877 3.877 0 01-1.039-2.636v-.016a3.873 3.873 0 011.018-2.645 3.66 3.66 0 012.512-1.183L187.953 171l.047 11z"
        ></path>
        <circle
          cx="197"
          cy="176"
          r="12"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
      </g>
    </svg>
  );
};

export default IllustrationInsuranceValuables;
