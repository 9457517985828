import styled from 'styled-components';
import { DateInputV4 as DateInput } from 'wg-fe-ui';

const ChakraDateInput = styled(DateInput)`
  > label,
  > label > div {
    height: 40px;
    border-radius: 6px;
  }
`;

export default ChakraDateInput;
