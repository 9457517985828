import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusSuccessCheckmark: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M22.565 36.287L10 23.72l2.826-2.825 9.479 9.479L36.934 13 40 15.585 22.565 36.287z"
    ></path>
  </Icon>
);

export default GeneralStatusSuccessCheckmark;
