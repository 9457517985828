import { Box } from '@wegroup/design-system';
import React from 'react';

interface Props {
  left: number;
  isFirst?: boolean;
}

const TreeListLine: React.FC<React.PropsWithChildren<Props>> = ({
  left,
  isFirst,
}) => {
  return (
    <Box h="100%" w="20px" pos="absolute" left={left} top="0">
      <Box
        borderLeft="1px"
        borderColor="gray.100"
        h="100%"
        w="100%"
        pos="absolute"
        top="0"
        left="5px"
      />
      {isFirst && (
        <Box
          pt="2"
          borderLeft="1px"
          borderBottom="1px"
          borderColor="gray.100"
          w="100%"
          h="22px"
          borderBottomLeftRadius="16px"
          pos="absolute"
          top="0"
          left="5px"
        />
      )}
    </Box>
  );
};

export default TreeListLine;
