import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersWallet: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.904 6.904A6.5 6.5 0 0111.5 5H34a4.25 4.25 0 014.25 4.25V14h.25a4.25 4.25 0 014.25 4.25V25a2 2 0 01-3.789.894V18H11.5a6.498 6.498 0 01-2.5-.5v21a2.5 2.5 0 002.5 2.5h27a.25.25 0 00.25-.25V34a2 2 0 114 0v6.75A4.25 4.25 0 0138.5 45h-27A6.5 6.5 0 015 38.5v-27a6.5 6.5 0 011.904-4.596zM9 11.5a2.5 2.5 0 002.5 2.5h22.75V9.25A.25.25 0 0034 9H11.5A2.5 2.5 0 009 11.5z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M34 27a2.5 2.5 0 000 5h7v-5h-7zm-4.596-2.096A6.5 6.5 0 0134 23h9a2 2 0 012 2v9a2 2 0 01-2 2h-9a6.5 6.5 0 01-4.596-11.096z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersWallet;
