const fidus = {
  primary: {
    main: '#FF4810',
    50: '#FFEBE5',
    100: '#FFDCD1',
    200: '#FFB59E',
    300: '#FF9270',
    400: '#FF6A3D',
    500: '#FF4810',
    600: '#D63200',
    700: '#A32600',
    750: '#A32600',
    800: '#6B1900',
    850: '#6B1900',
    900: '#380D00',
  },
  secondary: {
    main: '#A32600',
    50: '#FFE4DB',
    100: '#FFC8B8',
    200: '#FF9575',
    300: '#FF5F2E',
    400: '#EB3700',
    500: '#A32600',
    600: '#851F00',
    700: '#611700',
    750: '#611700',
    800: '#420F00',
    850: '#420F00',
    900: '#1F0700',
  },
};

export default fidus;
