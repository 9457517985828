import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleAdaptiveLighting: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25.904 38c-.851 0-1.532 0-2.127-.085-1.276-.085-2.297-.938-2.807-2.387-2.892-7.843-1.276-15.43-.425-19.521.085-.597.255-1.023.255-1.45.34-1.704 1.956-2.898 3.657-2.642.256.085.596.085.936.17.936.17 2.212.341 3.488.597.935.17 1.445 1.108 1.275 1.96-.17.939-1.02 1.45-1.956 1.28-1.276-.256-2.382-.427-3.402-.597-.372-.062-.766 0-.766 0-.085.426-.17.852-.255 1.449-.765 3.75-2.211 10.74.255 17.646 0 .085.085.17.085.17 3.062.17 10.037.085 13.524-3.921.596-.682 1.617-.767 2.382-.17.68.596.765 1.62.17 2.386C36.451 37.233 29.986 38 25.903 38z"
    ></path>
    <path
      fill="currentColor"
      d="M38.38 17.325c.765-.17 1.36-.853 1.36-1.62 0-.938-.765-1.705-1.7-1.705H33.87c-.935 0-1.616.767-1.7 1.62a1.61 1.61 0 00-.171.682v3.324c0 .938.766 1.705 1.701 1.705.936 0 1.701-.767 1.701-1.705v-.17C39.74 22 40.103 24.5 39.74 26.5c-.17.938.34 1.79 1.276 1.96h.34c.766 0 1.363-.534 1.616-1.278 1.255-3.682-.745-7.182-4.593-9.857zM7.701 21.974c-.595 0-1.19-.341-1.53-.938-.426-.852-.086-1.79.765-2.216l8.335-4.177c.766-.341 1.787-.086 2.212.767.425.852.085 1.79-.766 2.216l-8.335 4.177c-.256.17-.426.17-.68.17zM7.701 29.902c-.595 0-1.19-.341-1.53-.938-.426-.852-.086-1.79.765-2.216l8.335-4.177c.85-.427 1.787-.086 2.212.767.425.852.085 1.79-.766 2.216l-8.335 4.177c-.256.17-.426.17-.68.17zM7.701 37.83c-.595 0-1.19-.341-1.53-.938-.426-.853-.086-1.79.765-2.217l8.335-4.177c.85-.426 1.787-.085 2.212.768.425.852.085 1.79-.766 2.216l-8.335 4.177c-.256.085-.426.17-.68.17z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleAdaptiveLighting;
