import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleParkingAssistant: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25 2.94a15 15 0 100 30 15 15 0 000-30zm-3 25.32a.38.38 0 01-.49.37A11.32 11.32 0 0115 23a.38.38 0 01.34-.54h3A3.75 3.75 0 0122 26.19v2.07zm3-6.57a2.25 2.25 0 110-4.499 2.25 2.25 0 010 4.499zm10 1.29a11.248 11.248 0 01-7.34 5.88.368.368 0 01-.46-.37v-2.3a3.75 3.75 0 013.8-3.75h3.71a.371.371 0 01.34.56l-.05-.02zm.64-5.6l-5.36-2a12.741 12.741 0 00-10.66 0l-5.36 2a.38.38 0 01-.46-.38 11.24 11.24 0 0122.4 0 .38.38 0 01-.51.38h-.05zM30 40.37h.08l.67-3.71a3.12 3.12 0 011-1.52c.38-.34 1.22-1 1.64-1.27a5.75 5.75 0 013.11-1h.17L40 30.53a1.15 1.15 0 01.89-.19 1.158 1.158 0 01.76.49 1 1 0 01.13.31c.07.231.07.478 0 .71v.07a1.14 1.14 0 01-.41.56l-.37.22-3.71 2.58.38.55 4-2.79c.135-.108.256-.232.36-.37a1.82 1.82 0 00.39-.8 1.56 1.56 0 000-.57l2.15-7.72A1.233 1.233 0 1147 24l-1.82 10a2 2 0 01-.81 1.26l-6.69 4.65-.34 1.85h.07a.69.69 0 01.55.8l-.71 3.91a.69.69 0 01-.81.56L29 45.64a.69.69 0 01-.55-.81l.71-3.91a.69.69 0 01.84-.55zM20 40.37h-.08l-.67-3.71a3.12 3.12 0 00-1-1.52c-.38-.34-1.22-1-1.64-1.27a5.75 5.75 0 00-3.11-1h-.17L10 30.53a1.15 1.15 0 00-.89-.19 1.159 1.159 0 00-.76.49.999.999 0 00-.13.31 1.23 1.23 0 000 .71v.07c.075.225.218.42.41.56l.37.22 3.71 2.58-.38.55L8.26 33a2.104 2.104 0 01-.36-.37 1.82 1.82 0 01-.39-.8 1.56 1.56 0 010-.57l-2.16-7.71A1.196 1.196 0 103 24l1.82 10a2 2 0 00.81 1.26l6.69 4.65.34 1.85h-.07a.69.69 0 00-.55.8l.71 3.91a.69.69 0 00.81.56L21 45.64a.69.69 0 00.55-.81l-.71-3.91a.69.69 0 00-.84-.55z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleParkingAssistant;
