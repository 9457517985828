import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleHighSpeedEmergencyBraking: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M46 25a21 21 0 01-41.57 4.18 1 1 0 011-1.18h2c.72 0 1.12.33 1.22.79A16.81 16.81 0 1028.8 8.64a1 1 0 01-.8-1V5.41a1.002 1.002 0 011.22-1A21 21 0 0146 25z"
    ></path>
    <path
      fill="currentColor"
      d="M24 3A22 22 0 003 24a1 1 0 001 1h4.23a1 1 0 001-.94A15.78 15.78 0 0124.06 9.23a1 1 0 00.94-1V4a1 1 0 00-1-1zM29.596 24.213l5.967-5.966c.643-.643.532-1.799-.25-2.58-.78-.78-1.935-.892-2.578-.25l-5.727 5.727a4 4 0 102.588 3.07z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleHighSpeedEmergencyBraking;
