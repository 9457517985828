import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralRiskObjectsLegal: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M36 10.98a1.243 1.243 0 00-.24-.31 1.63 1.63 0 00-.93-.28h-8.76V8.13a1.13 1.13 0 10-2.26 0v2.26h-8.76a1.63 1.63 0 00-.93.28c-.096.09-.177.195-.24.31L8 21.15v.57a6.2 6.2 0 006.82 6.17 6.37 6.37 0 005.6-6.45v-.29l-4.25-7.37h7.64v23.71h-7.34a.56.56 0 00-.56.56v3.39a.549.549 0 00.56.56h16.94a.549.549 0 00.56-.56v-3.39a.56.56 0 00-.56-.56h-7.34V13.78h7.64l-4.25 7.37v.29a6.37 6.37 0 005.6 6.45 6.2 6.2 0 006.82-6.17v-.57L36 10.98zM17.82 21.15h-7.21l3.6-6.23 3.61 6.23zm14.24 0l3.61-6.23 3.6 6.23h-7.21z"
    ></path>
  </Icon>
);

export default GeneralRiskObjectsLegal;
