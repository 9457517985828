import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleExtraObjectDamage: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M37 29.07a4 4 0 00-3.38 6.13L31 38v-3l-2 3-1-1-1 1v4.43a5.24 5.24 0 00-7.66 4.64H31V41l4.09-4.39A4 4 0 1037 29.07z"
    ></path>
    <path
      fill="currentColor"
      d="M21 32.23l5.51 2.48.49 1.4.62-1.28L29 36l1-3-2.44-7.45a11 11 0 10-15.83-5.93c.199.575.45 1.13.75 1.66A7.5 7.5 0 1021 32.23zm4.61-.12l-3.86-1.73A7.287 7.287 0 0022 28.5c0-.504-.05-1.006-.15-1.5.646.015 1.291-.026 1.93-.12l1.83 5.23z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleExtraObjectDamage;
