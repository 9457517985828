const Table = {
  baseStyle: {
    table: {
      fontVariantNumeric: 'unset',
      textStyle: 'normalBody',
      borderCollapse: 'separate',
      borderRadius: '6px',
      border: '1px solid',
      letterSpacing: 'unset',
      borderColor: 'gray.50',
      boxShadow: 'level1',
      fontFeatureSettings: 'unset',
      borderSpacing: 0,
    },
    thead: {
      bg: 'gray.ultraLight',
      th: {
        bg: 'gray.ultraLight',
        fontFamily: 'Circular',
        textStyle: 'smallBodyMedium',
        borderBottom: '1px solid',
        borderColor: 'gray.50',
        px: '3.5',
        paddingTop: '4',
        paddingBottom: '4',
        letterSpacing: 'unset',
        color: 'gray.500',
        textTransform: 'unset',
      },
    },
    tbody: {
      tr: {
        bg: 'white',
        td: {
          bg: 'white',
          borderBottom: '1px solid',
          borderColor: 'gray.50',
          verticalAlign: 'middle',
          color: 'text.gray',
          p: '3.5',
        },
        _last: {
          td: {
            borderBottom: 'none',
          },
        },
      },
    },
    tfoot: {
      bg: 'white',
      td: {
        textStyle: 'normalBodyMedium',
        borderTop: '1px solid',
        borderColor: 'gray.50',
        p: '3.5',
        letterSpacing: 'unset',
        color: 'text.gray',
        textTransform: 'unset',
      },
    },
  },
};

export default Table;
