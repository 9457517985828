import React from 'react';
import {
  useTheme,
  Grid,
  GridItem,
  Button,
  Icon,
  Text,
} from '@wegroup/design-system';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchBar from './SearchBar';
export interface ModuleTopBarProps {
  onSearch?: (value: string) => void;
  backRef?: string;
  searchPlaceholder?: string;
  title?: string;
  backText?: string;
  rightElement?: React.ReactElement;
  defaultSearchValue?: string;
}

const ModuleTopBar: React.FC<React.PropsWithChildren<ModuleTopBarProps>> = ({
  backRef,
  onSearch,
  defaultSearchValue,
  searchPlaceholder,
  title,
  backText,
  rightElement,
}) => {
  const { t } = useTranslation();
  const { sizes } = useTheme();

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        tablet: '1fr 1fr',
        laptop: title ? '1fr 1fr 1fr' : `1fr minmax(0px, ${sizes.xl}) 1fr`,
      }}
      px="4"
      py="2"
      bg="white"
      borderBottom="1px"
      borderColor="gray.50"
      gridGap="3"
    >
      <GridItem>
        {backRef && (
          <Button
            variant="grayedGhost"
            leftIcon={<Icon name="GeneralActionsArrowLeft" />}
            as={Link}
            to={backRef}
          >
            {backText || t('GO-BACK')}
          </Button>
        )}
      </GridItem>
      {/* This  component cannot have both a title and search bar */}
      {title ? (
        <GridItem justifySelf="center" alignSelf="center">
          <Text textStyle="largeBodyMedium">{title}</Text>
        </GridItem>
      ) : onSearch ? (
        <GridItem>
          <SearchBar
            defaultValue={defaultSearchValue}
            onSearch={onSearch}
            searchPlaceholder={searchPlaceholder}
          />
        </GridItem>
      ) : (
        <GridItem />
      )}
      {rightElement && (
        <GridItem justifySelf={{ base: 'flext-start', laptop: 'flex-end' }}>
          {rightElement}
        </GridItem>
      )}
    </Grid>
  );
};

export default ModuleTopBar;
