import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import rootReducer, { RootState } from './reducers/rootReducer';

const persistConfig = {
  key: 'root',
  storage: storage,
  timeout: 1,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: {
    name: 'WeGroup | Campaigns',
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducers/rootReducer', () => {
    const newRootReducer = require('./reducers/rootReducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store.getState;

export const useCampaignDispatch = (): AppDispatch =>
  useDispatch<AppDispatch>();
export const useCampaignSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

export const persistor = persistStore(store);
