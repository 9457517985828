import {
  Text,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
  VStack,
  Flex,
  Button,
  useBreakpointValue,
  Alert,
  AlertIcon,
  AlertTitle,
} from '@wegroup/design-system';
import cogoToast from 'cogo-toast';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import useSessionId from '../../../../../hooks/useSessionId';
import { postConversationFeedback } from '../../../../../../../sales-conversation/src/services/apiService';

interface Props {
  distributionName?: string;
}
interface FormValues {
  comment: string;
}

const BrokerContactForm: React.FC<React.PropsWithChildren<Props>> = ({
  distributionName,
}) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      comment: '',
    },
  });
  const sessionId = useSessionId();
  const isFullWidth =
    useBreakpointValue({ base: true, laptop: false }) || false;

  const handleOnError = () => {
    cogoToast.error(
      t('SALES-CONVERSATION_FINALIZATION_BROKER-INFO_FEEDBACK-FAILED'),
    );
  };

  const handleOnSuccess = () => {
    cogoToast.success(
      t('SALES-CONVERSATION_FINALIZATION_BROKER-INFO_FEEDBACK-SENT'),
    );
  };

  const { mutateAsync, isLoading, isSuccess } = useMutation(
    async (feedback: string) => {
      if (!sessionId) throw new Error();
      const [resp, status] = await postConversationFeedback(
        sessionId,
        feedback,
      );
      if (status >= 400) throw new Error();

      return resp;
    },
    {
      onSuccess: handleOnSuccess,
      onError: handleOnError,
    },
  );

  const handleOnSendFeedback = handleSubmit(async (values) => {
    await mutateAsync(values.comment);
  }, handleOnError);

  return (
    <VStack spacing="3" align="stretch">
      <Text textStyle="largeBodyBold">
        {t('SALES-CONVERSATION_FINALIZATION_BROKER-INFO_FORM-TITLE')}
      </Text>
      {!isSuccess ? (
        <>
          <FormControl isInvalid={!!errors.comment}>
            <FormLabel>
              {t(
                'SALES-CONVERSATION_FINALIZATION_BROKER-INFO_FORM-DESCRIPTION',
              )}
            </FormLabel>
            <Textarea
              {...register('comment')}
              placeholder={t(
                'SALES-CONVERSATION_FINALIZATION_BROKER-INFO_FORM-DESCRIPTION',
              )}
            />
            <FormErrorMessage>{errors.comment?.message}</FormErrorMessage>
          </FormControl>
          <Flex justify="flex-end">
            <Button
              width={isFullWidth ? 'full' : 'auto'}
              onClick={handleOnSendFeedback}
              isLoading={isLoading}
              isDisabled={!watch('comment') || isLoading}
              borderRadius="full"
            >
              {t('SEND')}
            </Button>
          </Flex>
        </>
      ) : (
        <Alert status="success" p="3">
          <AlertIcon />
          <AlertTitle
            color="success.dark"
            sx={{ strong: { fontWeight: 'bold' } }}
            dangerouslySetInnerHTML={{
              __html: t(
                'SALES-CONVERSATION_FINALIZATION_BROKER-INFO_FORM-SUCCESS',
                {
                  distribution: distributionName,
                },
              ),
            }}
          ></AlertTitle>
        </Alert>
      )}
    </VStack>
  );
};

export default BrokerContactForm;
