import { Userpilot } from 'userpilot';
import useAuth from '../../../../app/src/features/authentication/hooks/useAuth';
import useFeatureFlags from '../../../../app/src/hooks/useFeatureFlags';
import { isSocialLoginUrl } from '../../../../sales-conversation/src/services/productFactoryService';

interface UserpilotObject {
  [key: string]: unknown;
}

interface UserpilotReturnVal {
  shouldTrackUserpilot: boolean;
  update: (props: UserpilotObject) => void;
  trackEvent: (event: string, meta?: UserpilotObject) => void;
}

const useUserpilot = (): UserpilotReturnVal => {
  const {
    user,
    ssoData: { info },
  } = useAuth();
  const { isForeignDataTrackingDisabled } = useFeatureFlags();

  const isStagingUrl = window.location.href.includes('staging');
  const filteredOrigin = ['random'];

  const shouldTrackUserpilot = Boolean(
    user &&
      user.id &&
      user.email &&
      !isStagingUrl &&
      !isSocialLoginUrl &&
      info &&
      !filteredOrigin.includes(info.origin) &&
      !isForeignDataTrackingDisabled,
  );

  const update = (props: UserpilotObject) => {
    if (user && shouldTrackUserpilot) {
      updateUserpilot(user.id, props);
    }
  };

  return {
    shouldTrackUserpilot,
    update,
    trackEvent: trackUserpilotEvent,
  };
};

export const updateUserpilot = (id: string, props: UserpilotObject): void => {
  Userpilot.identify(id, props);
};

export const trackUserpilotEvent = (
  event: string,
  meta?: UserpilotObject | undefined,
): void => {
  Userpilot.track(event, meta);
};

export default useUserpilot;
