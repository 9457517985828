import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersThumbsUp: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M37.63 31c1.84-3.16.1-4.55.1-4.55 1.39-6.36-6.9-4.32-8.02-4.28-1.12.03-.88-.34-.68-1.46 3.6-6.29 2.41-10.3.14-10.67-2.27-.37-2.11 3.2-2.82 5-.71 1.8-2.89 3.91-3.47 4.96-.58 1.05-1.9 4.14-3.04 4.78-.43.22-.87.34-1.23.4v-1.77c0-.36-.28-.63-.63-.63h-6.35c-.35 0-.63.28-.63.63V39.9c0 .36.28.63.63.63h6.34c.36 0 .63-.28.63-.63v-1.77a8.6 8.6 0 012.08-.51c.68-.08 1.37 0 2.03.17 5.12 1.32 8.16 1.12 11.1.9 3.09-.24 2.79-3.5 2.79-3.5 2.22-2.32 1.03-4.19 1.03-4.19z"
    ></path>
  </Icon>
);

export default GeneralOthersThumbsUp;
