import React from 'react';
import styled from 'styled-components';

// Components
import { Modal } from 'wg-fe-ui';
import BrokerInfoContainer from './BrokerInfoContainer';

interface Props {
  setShowModal: (arg0: boolean) => void;
  showModal: boolean;
}

const BrokerInfoModal: React.FC<React.PropsWithChildren<Props>> = ({
  setShowModal,
  showModal,
}) => {
  return (
    <StyledModal
      setShowModal={setShowModal}
      showModal={showModal}
      canClose
      data-test-id="ProdFac_BrokerInfoModal_Modal"
    >
      <BrokerInfoContainer />
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  width: unset;
  padding: 0;
  > div {
    padding: 3.6rem 0 2.4rem;
  }

  > a {
    top: 0.8rem;
    right: 0.8rem;
  }

  ::-webkit-scrollbar {
    width: 0;
    background: transparent; /* make scrollbar transparent */
  }
`;
export default BrokerInfoModal;
