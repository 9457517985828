import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsRecallContamination: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.014 9.186a2 2 0 010 2.828L11.828 18.2H33.4a11.6 11.6 0 110 23.2H31a2 2 0 110-4h2.4a7.6 7.6 0 000-15.2H11.828l6.186 6.186a2 2 0 11-2.828 2.828l-9.6-9.6a2 2 0 010-2.828l9.6-9.6a2 2 0 012.828 0z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsRecallContamination;
