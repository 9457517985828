import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleLightScooter: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M13 27a5.714 5.714 0 100 11.428A5.714 5.714 0 0013 27zm0 8a2.286 2.286 0 110-4.572A2.286 2.286 0 0113 35zM39.286 33.857a1.143 1.143 0 100-2.285 1.143 1.143 0 000 2.285z"
    ></path>
    <path
      fill="currentColor"
      d="M39.286 27a5.714 5.714 0 100 11.428 5.714 5.714 0 000-11.428zm0 8a2.286 2.286 0 110-4.572 2.286 2.286 0 010 4.572zM17.571 17.857h-8A1.143 1.143 0 008.43 19v1.143h11.428a2.286 2.286 0 00-2.286-2.286z"
    ></path>
    <path
      fill="currentColor"
      d="M39.286 25.629a7.023 7.023 0 012.907.626.686.686 0 00.923-.853.686.686 0 00-.379-.409 8.405 8.405 0 00-4.235-.7l-3.875-7.579h1.23v-3.428h-1.714a1.698 1.698 0 00-1.074.38l-1.173-2.287a.686.686 0 00-.61-.379h-4.572c-.599 0-1.017.766-.331 1.371h4.482l2.793 5.486h-2.372V27H25.57a5.714 5.714 0 01-5.714-5.714H9.571A4.571 4.571 0 005 25.857v3.429h2.057a6.857 6.857 0 0112.8 3.428H32.2a7.086 7.086 0 017.086-7.085zM13 33.857a1.143 1.143 0 100-2.285 1.143 1.143 0 000 2.285z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleLightScooter;
