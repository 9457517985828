import {
  AUDIENCE_TYPE,
  EXTEND,
  FLOW_VERSION,
  Insurance,
  LANGUAGE,
  Specifications,
  STATUS,
  Theme,
  TYPE,
} from '../../../../types/Campaigns';
import { Address } from '../../../../types/Common';
import { SmtFile } from '../../../../types/Form';
import { Subject } from '../../../../types/RiskAnalaysis';

export type LanguageString = 'EN' | 'NL' | 'FR';

export interface CampaignsRouteParams {
  type?: string;
  page?: string;
  campaignId?: string;
}

export interface CampaignPayload {
  id?: string;
  type?: TYPE | null;
  dns_prefix?: string;
  active: boolean;
  insurances?: Insurance[];
  name: string;
  display_name?: string;
  extend: EXTEND;
  language: LANGUAGE;
  end_date?: string;
  start_date?: string;
  theme?: string | Theme;
  url?: string;
  short_id?: string;
  assigned_to?: string | null;
  assigned_to_name?: string | null;
  created_by: string;
  distribution_id?: string;
  google_analytics_tracking_id?: string | null;
  flow_version?: FLOW_VERSION;
  specifications?: Specifications;
  assistant_name?: string;
  assistant_avatar?: SmtFile | null;
  logo?: SmtFile | null;
  send_reminders?: boolean;
  status?: STATUS;
  targets?: TargetPayload[];
  notifications?: string[];
  risk_analysis_subjects?: Subject[];
  code?: number;
  template_id?: string; // THIS KEY TEMPORARY
  flow_id?: string;
  text?: string;
  show_offers?: boolean;
  send_target_mails?: boolean;
  flat_commission_discount?: number;
}

export interface TargetPayload {
  party_id: string;
  risk_object_revision_id?: string;
}

export enum CampaignTemplate {
  WEBSITE = 'WEBISTE',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  PROMOTION_MATERIAL = 'PROMOTION_MATERIAL',
  PRICE_COMPARISON = 'PRICE_COMPARISON',
  EMAIL_SIGNATURE = 'EMAIL_SIGNATURE',
  CONTACT_FORM = 'CONTACT_FORM',
  PARTNERS = 'PARTNERS',
  NEW_CLIENT = 'NEW_CLIENT',
  UPSELL_CLIENT = 'UPSELL_CLIENT',
  REQUEST_DOCUMENTATION = 'REQUEST_DOCUMENTATION',
  INTAKE_CUSTOMER_BE = 'INTAKE_CUSTOMER_BE',
  REVISION_CUSTOMER_BE = 'REVISION_CUSTOMER_BE',
  INTAKE_COMPANY_BE = 'INTAKE_COMPANY_BE',
  REVISION_COMPANY_BE = 'REVISION_COMPANY_BE',
}

export enum FlowType {
  ADVISORY_CONVERSATION = 'ADVISORY_CONVERSATION',
  FORM = 'FORM',
  RISK_ANALYSIS_SHORT = 'RISK_ANALYSIS_SHORT',
  RISK_ANALYSIS_LONG = 'RISK_ANALYSIS_LONG',
  COMPANY_SCAN = 'COMPANY_SCAN',
}

export enum SharingOption {
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  LINK_BROAD_AUDIENCE = 'LINK_BROAD_AUDIENCE',
  LINK_KNOWN_TARGETS = 'LINK_KNOWN_TARGETS',
  WEBSITE_POPUP = 'WEBSITE_POPUP',
}

export type Template = {
  id: string;
  name: string;
  description: string;
  flow_id: string;
  flow_type: FlowType;
  flow_title: string;
  tooltip: string;
  extend: EXTEND;
  audience: AUDIENCE_TYPE;
  personalisation: boolean;
  price: number | null;
  href?: string;
  key?: CampaignTemplate;
  category: 'PROSPECTING' | 'CUSTOMER_FOLLOW_UP' | 'LEAD_GENERATION';
  sharing_option: SharingOption;
};

export interface CampaignTemplateFormValues {
  template?: Template;
}

export interface CampaignTargetAudienceFormValues {
  partyIds?: string[];
  prefilledIds?: string[];
}

export interface CampaignAdvisoryFormValues {
  showOffers?: boolean;
  selectedInsuranceTypes?: string[];
  selectedInsuranceCompanies?: string[];
  partyRiskObjects?: CampaignProspectRiskObjectRevisionIds;
}

export interface CampaignRiskAnalysisFormValues {
  selectedSubjects?: Subject[];
  partyRiskObjects?: CampaignProspectRiskObjectRevisionIds;
}

export interface CampaignProspectRiskObjectRevisionIds {
  [partyId: string]: {
    [riskObject: string]: string;
  };
}

export interface CampaignPersonalisationFormValues {
  partnerName?: string;
  partnerTheme?: Theme;
  partnerLogoSrc?: SmtFile | null;
  assistantName?: string;
  avatarSrc?: SmtFile | null;
}

export interface CampaignConfigurationFormValues {
  name?: string;
  language?: LANGUAGE;
  duration?: string;
  assignee?: string;
  google_analytics_tracking_id?: string;
  notification_receivers?: string[];
  notifications?: boolean;
  reminder?: boolean;
  dns_prefix?: string;
  is_dns_valid?: boolean;
  car_brands?: string[];
  isBrandFilterEnabled?: boolean;
  vk?: number;
  ck?: number;
}

export interface CampaignFinalizationFormValues {
  type: 'LINK' | 'EMAIL';
  email?: string;
  emailContent?: string;
}

export interface CampaignCreateLead {
  email: string;
  telephonenr: string;
  first_name: string;
  last_name: string;
  address: Address;
}

export interface CampaignMetrics {
  amount_of_started_conversations: number;
  amount_of_completed_conversations: number;
  completion_rate: number;
}

export interface CreateCampaignResponse {
  id: string;
}

export interface SendExampleTargetMailV2 {
  extend?: string;
  language: string;
  targets: string[];
  text?: string;
  template_key?: CampaignTemplate;
}
