import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsImportUser: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.547 12.88a1.631 1.631 0 012.307 0l4.657 4.657.01.01c.638.638.638 1.67 0 2.308l-4.667 4.667a1.631 1.631 0 01-2.307-2.307l1.883-1.883H6.466a1.631 1.631 0 110-3.262H19.43l-1.883-1.883a1.631 1.631 0 010-2.307z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.756 9.803C20.51 8.307 22.71 7.697 24.7 7.697h.4c1.99 0 4.191.61 5.943 2.106 1.76 1.502 3.025 3.861 3.2 7.268a20.28 20.28 0 01-.086 4.269c-.271 2.145-.972 4.74-2.697 6.677-.318.37-.635.662-.974.928.672.864 2.638 2.505 8.118 3.875l.005.001c1.53.4 2.705 1.337 3.451 2.702.74 1.352 1.045 3.1.908 5.128v.007l-.002.008c-.088.887-.806 1.637-1.75 1.637h-.088l-.022-.002c-.982-.09-1.732-.91-1.639-1.927.064-.704.06-1.648-.189-2.461-.245-.802-.7-1.41-1.511-1.62-6.764-1.706-9.42-4.035-10.508-5.775-.711.16-1.44.263-2.226.263h-.266a8.58 8.58 0 01-1.895-.188c-1.056 1.686-3.79 3.94-10.507 5.634l-.012.004c-.803.18-1.254.765-1.498 1.565-.249.812-.254 1.772-.19 2.512.093 1.017-.657 1.837-1.639 1.926l-.022.002h-.088c-.96 0-1.633-.76-1.868-1.623l-.013-.047-.003-.048c-.138-1.995.168-3.727.908-5.072.746-1.355 1.92-2.29 3.45-2.692l.007-.001c4.82-1.189 6.92-2.558 7.83-3.468.086-.085.16-.16.224-.226a7.197 7.197 0 01-1.11-1.04 8.71 8.71 0 01-.67-.856h1.254a3 3 0 002.06-.82l.366-.345c.862.787 2.01 1.213 3.482 1.213h.133c1.68 0 2.938-.555 3.831-1.567l.002-.002c1.009-1.13 1.554-2.788 1.814-4.407.26-1.612.225-3.114.131-3.867l-.004-.03v-.086c-.13-2.887-1.243-4.365-2.413-5.142-1.2-.798-2.538-.91-3.16-.91h-.4c-.575 0-1.758.096-2.881.737l-.897-.861a3 3 0 00-2.079-.837h-.626c.153-.152.31-.298.472-.436z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralActionsImportUser;
