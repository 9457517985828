import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyRooms: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M42.47 17.362l-4-2.64v-6.48a1.12 1.12 0 00-1.12-1.12h-5.6a1.12 1.12 0 00-1.12 1.12v1.25l-5-3.29a1.18 1.18 0 00-1.32 0L7.53 17.362a1.19 1.19 0 00-.53 1v24.44a1.2 1.2 0 001.2 1.2h33.6a1.2 1.2 0 001.2-1.2v-24.45a1.19 1.19 0 00-.53-.99zm-9.91-4.08zm7.19 27.48H22v-7.76h17.765l-.015 7.76zM19 33.002v7.76h-8.75v-7.76H19zm9-3H10.25v-8H28v8zm11.77 0H31v-8h8.785l-.015 8zM25 9.832l-13.958 9.17h27.921L36 17.102a1.52 1.52 0 01-.75-1.31v-5.44h-1.37v2.43a1.57 1.57 0 01-2.43 1.31l-2.33-1.53L25 9.832z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyRooms;
