import React, { useMemo } from 'react';
import {
  IconButton,
  Icon,
  Text,
  HStack,
  Spacer,
  Image,
  ButtonGroup,
  Button,
  Skeleton,
  Link,
} from '../../../../index';
import { formatBytes } from '../../../../../common/src/utils/commonUtils';
import { useTranslation } from 'react-i18next';

interface Props {
  file?: File;
  filename?: string;
  onRemove: () => void;
  isEditing?: boolean;
  onEdit?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
}

const SingleDocumentHeader: React.FC<Props> = ({
  file,
  filename,
  isEditing,
  onEdit,
  onSave,
  onRemove,
  onCancel,
}) => {
  const { t } = useTranslation();
  // This useMemo is for memory management
  // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
  const preview = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return '';
  }, [file]);
  return (
    <HStack spacing="2" alignItems="center">
      <Link
        variant="gray"
        href={preview}
        isExternal
        mr="2"
        w="12"
        transition="width 0.5s"
      >
        <Image objectFit="contain" src={preview} h="6" />
      </Link>
      {file ? (
        <>
          <Text textStyle="smallBody" color="black" mr="1">
            {filename || file.name}
          </Text>
          <Text textStyle="smallBody" color="text.gray">
            ({formatBytes(file.size)})
          </Text>
        </>
      ) : (
        <Skeleton h="3" w="24" />
      )}
      <Spacer />
      {isEditing ? (
        <>
          <Button size="sm" variant="grayedGhost" onClick={onCancel}>
            {t('CANCEL')}
          </Button>
          <Button size="sm" variant="secondary" onClick={onSave}>
            {t('SAVE-CHANGES')}
          </Button>
        </>
      ) : (
        <ButtonGroup isDisabled={!file} variant="white" isAttached>
          {onEdit && (
            <IconButton
              size="sm"
              aria-label="edit"
              onClick={onEdit}
              icon={<Icon boxSize="5" name="GeneralActionsEditDocument" />}
            />
          )}
          <IconButton
            color="danger.main"
            size="sm"
            aria-label="remove"
            onClick={onRemove}
            icon={<Icon boxSize="5" name="GeneralActionsThrash" />}
          />
        </ButtonGroup>
      )}
    </HStack>
  );
};

export default SingleDocumentHeader;
