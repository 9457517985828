import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationShutters: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_5238_12355"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_5238_12355)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M77 50H178V202H77z"
        ></path>
        <path
          stroke={colors.primary[300]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M168 108v40"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M89 62H158V118H89z"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M89 134H158V190H89z"
        ></path>
        <mask
          id="mask1_5238_12355"
          style={{ maskType: 'alpha' }}
          width="73"
          height="132"
          x="87"
          y="60"
          maskUnits="userSpaceOnUse"
        >
          <mask id="path-8-inside-1_5238_12355" fill="#fff">
            <path
              fillRule="evenodd"
              d="M160 60H87v60h73V60zm0 72H87v60h73v-60z"
              clipRule="evenodd"
            ></path>
          </mask>
          <path
            fill={colors.primary[50]}
            fillRule="evenodd"
            d="M160 60H87v60h73V60zm0 72H87v60h73v-60z"
            clipRule="evenodd"
          ></path>
          <path
            fill={colors.primary[500]}
            d="M87 60v-4h-4v4h4zm73 0h4v-4h-4v4zm-73 60h-4v4h4v-4zm73 0v4h4v-4h-4zm-73 12v-4h-4v4h4zm73 0h4v-4h-4v4zm-73 60h-4v4h4v-4zm73 0v4h4v-4h-4zM87 64h73v-8H87v8zm4 56V60h-8v60h8zm69-4H87v8h73v-8zm-4-56v60h8V60h-8zm-69 76h73v-8H87v8zm4 56v-60h-8v60h8zm69-4H87v8h73v-8zm-4-56v60h8v-60h-8z"
            mask="url(#path-8-inside-1_5238_12355)"
          ></path>
        </mask>
        <g mask="url(#mask1_5238_12355)">
          <path
            stroke={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="20"
            d="M173 62L68 167M173 148L68 253"
          ></path>
          <path
            stroke={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="10"
            d="M173 91L68 196"
          ></path>
          <mask id="path-13-inside-2_5238_12355" fill="#fff">
            <path
              fillRule="evenodd"
              d="M91 64h65v52H91V64zm-4-4h73v60H87V60zm4 76h65v52H91v-52zm-4-4h73v60H87v-60z"
              clipRule="evenodd"
            ></path>
          </mask>
          <path
            fill={colors.primary[300]}
            d="M156 64h4v-4h-4v4zm-65 0v-4h-4v4h4zm65 52v4h4v-4h-4zm-65 0h-4v4h4v-4zm-4-56v-4h-4v4h4zm73 0h4v-4h-4v4zm0 60v4h4v-4h-4zm-73 0h-4v4h4v-4zm69 16h4v-4h-4v4zm-65 0v-4h-4v4h4zm65 52v4h4v-4h-4zm-65 0h-4v4h4v-4zm-4-56v-4h-4v4h4zm73 0h4v-4h-4v4zm0 60v4h4v-4h-4zm-73 0h-4v4h4v-4zm69-132H91v8h65v-8zm4 56V64h-8v52h8zm-69 4h65v-8H91v8zm-4-56v52h8V64h-8zm4-8h-4v8h4v-8zm65 0H91v8h65v-8zm4 0h-4v8h4v-8zm4 8v-4h-8v4h8zm0 52V64h-8v52h8zm0 4v-4h-8v4h8zm-8 4h4v-8h-4v8zm-65 0h65v-8H91v8zm-4 0h4v-8h-4v8zm-4-8v4h8v-4h-8zm0-52v52h8V64h-8zm0-4v4h8v-4h-8zm73 72H91v8h65v-8zm4 56v-52h-8v52h8zm-69 4h65v-8H91v8zm-4-56v52h8v-52h-8zm4-8h-4v8h4v-8zm65 0H91v8h65v-8zm4 0h-4v8h4v-8zm4 8v-4h-8v4h8zm0 52v-52h-8v52h8zm0 4v-4h-8v4h8zm-8 4h4v-8h-4v8zm-65 0h65v-8H91v8zm-4 0h4v-8h-4v8zm-4-8v4h8v-4h-8zm0-52v52h8v-52h-8zm0-4v4h8v-4h-8z"
            mask="url(#path-13-inside-2_5238_12355)"
          ></path>
        </g>
        <path
          fill="#fff"
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M-2 2H99V154H-2z"
          transform="matrix(-1 0 0 1 277 48)"
        ></path>
        <path
          stroke={colors.primary[300]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M188 108v40"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M-2 2H67V58H-2z"
          transform="matrix(-1 0 0 1 265 60)"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[300]}
          strokeWidth="4"
          d="M-2 2H67V58H-2z"
          transform="matrix(-1 0 0 1 265 132)"
        ></path>
        <mask
          id="mask2_5238_12355"
          style={{ maskType: 'alpha' }}
          width="73"
          height="132"
          x="196"
          y="60"
          maskUnits="userSpaceOnUse"
        >
          <mask id="path-19-inside-3_5238_12355" fill="#fff">
            <path
              fillRule="evenodd"
              d="M196 60h73v60h-73V60zm0 72h73v60h-73v-60z"
              clipRule="evenodd"
            ></path>
          </mask>
          <path
            fill={colors.primary[50]}
            fillRule="evenodd"
            d="M196 60h73v60h-73V60zm0 72h73v60h-73v-60z"
            clipRule="evenodd"
          ></path>
          <path
            fill={colors.primary[500]}
            d="M269 60v-4h4v4h-4zm-73 0h-4v-4h4v4zm73 60h4v4h-4v-4zm-73 0v4h-4v-4h4zm73 12v-4h4v4h-4zm-73 0h-4v-4h4v4zm73 60h4v4h-4v-4zm-73 0v4h-4v-4h4zm73-128h-73v-8h73v8zm-4 56V60h8v60h-8zm-69-4h73v8h-73v-8zm4-56v60h-8V60h8zm69 76h-73v-8h73v8zm-4 56v-60h8v60h-8zm-69-4h73v8h-73v-8zm4-56v60h-8v-60h8z"
            mask="url(#path-19-inside-3_5238_12355)"
          ></path>
        </mask>
        <g mask="url(#mask2_5238_12355)">
          <path
            stroke={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="20"
            d="M296.5 45L178 163.5"
          ></path>
          <path
            stroke={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="10"
            d="M296.5 105L178 223.5"
          ></path>
          <mask id="path-23-inside-4_5238_12355" fill="#fff">
            <path
              fillRule="evenodd"
              d="M265 64h-65v52h65V64zm4-4h-73v60h73V60zm-4 76h-65v52h65v-52zm4-4h-73v60h73v-60z"
              clipRule="evenodd"
            ></path>
          </mask>
          <path
            fill={colors.primary[300]}
            d="M200 64h-4v-4h4v4zm65 0v-4h4v4h-4zm-65 52v4h-4v-4h4zm65 0h4v4h-4v-4zm4-56v-4h4v4h-4zm-73 0h-4v-4h4v4zm0 60v4h-4v-4h4zm73 0h4v4h-4v-4zm-69 16h-4v-4h4v4zm65 0v-4h4v4h-4zm-65 52v4h-4v-4h4zm65 0h4v4h-4v-4zm4-56v-4h4v4h-4zm-73 0h-4v-4h4v4zm0 60v4h-4v-4h4zm73 0h4v4h-4v-4zM200 60h65v8h-65v-8zm-4 56V64h8v52h-8zm69 4h-65v-8h65v8zm4-56v52h-8V64h8zm-4-8h4v8h-4v-8zm-65 0h65v8h-65v-8zm-4 0h4v8h-4v-8zm-4 8v-4h8v4h-8zm0 52V64h8v52h-8zm0 4v-4h8v4h-8zm8 4h-4v-8h4v8zm65 0h-65v-8h65v8zm4 0h-4v-8h4v8zm4-8v4h-8v-4h8zm0-52v52h-8V64h8zm0-4v4h-8v-4h8zm-73 72h65v8h-65v-8zm-4 56v-52h8v52h-8zm69 4h-65v-8h65v8zm4-56v52h-8v-52h8zm-4-8h4v8h-4v-8zm-65 0h65v8h-65v-8zm-4 0h4v8h-4v-8zm-4 8v-4h8v4h-8zm0 52v-52h8v52h-8zm0 4v-4h8v4h-8zm8 4h-4v-8h4v8zm65 0h-65v-8h65v8zm4 0h-4v-8h4v8zm4-8v4h-8v-4h8zm0-52v52h-8v-52h8zm0-4v4h-8v-4h8z"
            mask="url(#path-23-inside-4_5238_12355)"
          ></path>
        </g>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M77 50H279V137H77z"
        ></path>
        <mask id="path-26-inside-5_5238_12355" fill="#fff">
          <rect width="214" height="15" x="71" y="135" rx="2"></rect>
        </mask>
        <rect
          width="214"
          height="15"
          x="71"
          y="135"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-26-inside-5_5238_12355)"
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeDasharray="4 10"
          strokeLinecap="round"
          strokeWidth="4"
          d="M80 114L277 114"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeDasharray="4 10"
          strokeLinecap="round"
          strokeWidth="4"
          d="M80 93L277 93"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeDasharray="4 10"
          strokeLinecap="round"
          strokeWidth="4"
          d="M80 72L277 72"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationShutters;
