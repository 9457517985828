import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationNewConversation: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.378 10H7.5a.5.5 0 00-.5.5v18a.5.5 0 00.5.5H11l2.4 5.61a.65.65 0 001.2 0L17 29h16.5a.5.5 0 00.5-.5v-4.183C29.34 22.67 26 18.225 26 13c0-1.036.131-2.041.378-3z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M43 25.5v12a.5.5 0 01-.5.5H40l-2 4.67a.54.54 0 01-1 0L35 38H22.34a.5.5 0 01-.5-.5V31H33.5a2.5 2.5 0 002.5-2.5V25h6.5a.5.5 0 01.5.5z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M38 23c5.523 0 10-4.477 10-10S43.523 3 38 3 28 7.477 28 13s4.477 10 10 10zm.923-10.923H44v1.846h-5.077V19h-1.846v-5.077H32v-1.846h5.077V7h1.846v5.077z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationNewConversation;
