import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationRoofFlat: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M178.303 134.42H107v91.131h142.599V134.42h-71.296Z"
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M224.384 176.542a13.375 13.375 0 0 1-15.985 13.116 13.377 13.377 0 0 1-10.506-10.511 13.372 13.372 0 0 1 13.124-15.979 13.38 13.38 0 0 1 9.453 3.919 13.377 13.377 0 0 1 3.914 9.455Z"
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M211 164v25M223.5 176.5h-25"
          stroke={colors.primary[200]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M226.334 85.985a7.313 7.313 0 0 0-7.312-7.312h-12.084a7.311 7.311 0 0 0-7.312 7.312v40.886h26.734l-.026-40.886Z"
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M169.124 170.84a17.821 17.821 0 0 0-10.922-16.782 17.842 17.842 0 0 0-13.804 0 17.84 17.84 0 0 0-9.665 9.854 17.813 17.813 0 0 0-1.257 6.928v35.648h35.648V170.84Z"
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M125.252 208.948a5 5 0 0 1 5-5h44.162a5 5 0 0 1 5 5v15.927h-54.162v-15.927Z"
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeWidth={4}
        />
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
          d="M92 109h173v26H92z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationRoofFlat;
