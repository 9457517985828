import { createGlobalStyle } from 'styled-components';

export const customMediaQuery = (minWidth: number): string =>
  `@media (min-width: ${minWidth}px)`;

const customMediaHeightQuery = (minHeight: number): string =>
  `@media (min-height: ${minHeight}px)`;

export const customMediaHWQuery = (
  minWidth: number,
  minHeight: number,
): string =>
  `@media (min-width: ${minWidth}px) and (min-height: ${minHeight}px)`;

export const bp = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tabletS: 520,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktopS: 1920,
  desktop: 2560,
};

export const mq = {
  custom: customMediaQuery,
  mobileS: customMediaQuery(bp.mobileS),
  mobileM: customMediaQuery(bp.mobileM),
  mobileL: customMediaQuery(bp.mobileL),
  tabletS: customMediaQuery(bp.tabletS),
  tablet: customMediaQuery(bp.tablet),
  laptop: customMediaQuery(bp.laptop),
  laptopL: customMediaQuery(bp.laptopL),
  desktopS: customMediaQuery(bp.desktopS),
  desktop: customMediaQuery(bp.desktop),
};

export const bph = {
  vga: 480,
  svga: 600,
  hd: 720,
  xga: 768,
  hdplus: 900,
  fhd: 1080,
  qhd: 1440,
  '4k': 2160,
};

export const mqh = {
  custom: customMediaHeightQuery,
  vga: customMediaHeightQuery(bph.vga),
  svga: customMediaHeightQuery(bph.svga),
  hd: customMediaHeightQuery(bph.hd),
  xga: customMediaHeightQuery(bph.xga),
  hdplus: customMediaHeightQuery(bph.hdplus),
  fhd: customMediaHeightQuery(bph.fhd),
  xlg: customMediaHeightQuery(bph.qhd),
  xxlg: customMediaHeightQuery(bph['4k']),
};

export const chakraCustomScrollBar = {
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',
  },
  '&::-webkit-scrollbar:vertical': {
    width: '14px',
  },
  '&::-webkit-scrollbar:horizontal': {
    height: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    visibility: 'unset',
    borderRadius: '8px',
    border: '2px solid white',
    backgroundColor: '#c7c7c7',
  },
  '&::-webkit-scrollbar-track-piece': {
    background: '#f0f1f3',
    borderRadius: '5px',
    width: '8px',
    border: '2px solid white',
  },
};

export const customScrollBar = `
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 12px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    visibility: unset;
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: #c7c7c7;
  }

  &::-webkit-scrollbar-track-piece {
    background: #f0f1f3;
    border-radius: 5px;
    width: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
  }`;

export const DisableBodyScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const hexToRGB = (hex: string, alpha: number): string => {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
  }
  return 'rgb(' + r + ', ' + g + ', ' + b + ')';
};
