import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusRejected: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M42.473 7.527a1.8 1.8 0 010 2.546l-32.4 32.4a1.8 1.8 0 01-2.546-2.546l32.4-32.4a1.8 1.8 0 012.546 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M39.574 7.174a2.3 2.3 0 013.252 0l-.353.353.353-.353a2.3 2.3 0 010 3.252l-32.4 32.4a2.3 2.3 0 01-3.252 0l.353-.353-.353.353a2.3 2.3 0 010-3.252l.353.353-.353-.353 32.4-32.4.353.353-.353-.353zm2.545.707a1.3 1.3 0 00-1.838 0l-32.4 32.4a1.3 1.3 0 101.838 1.838l32.4-32.4a1.3 1.3 0 000-1.838z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.527 7.527a1.8 1.8 0 012.546 0l32.4 32.4a1.8 1.8 0 01-2.546 2.546l-32.4-32.4a1.8 1.8 0 010-2.546z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.174 7.174a2.3 2.3 0 013.252 0l32.4 32.4a2.3 2.3 0 11-3.252 3.252l-32.4-32.4a2.3 2.3 0 010-3.252zm2.545.707a1.3 1.3 0 00-1.838 1.838l32.4 32.4a1.3 1.3 0 101.838-1.838L9.72 7.88z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralStatusRejected;
