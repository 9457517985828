import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleMotorcycle: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M33.21 30.842l-1.473-3.983 3.158-5.49h-8.842L25 22.42h-4.21v8.421h12.42zM12.368 22.842a4.843 4.843 0 110 9.685 4.843 4.843 0 010-9.685zm0-2.526a7.368 7.368 0 100 14.736 7.368 7.368 0 000-14.736z"
    ></path>
    <path
      fill="currentColor"
      d="M12.368 25.79a1.896 1.896 0 110 3.79 1.896 1.896 0 010-3.79zm0-1.264a3.158 3.158 0 100 6.316 3.158 3.158 0 000-6.316zM37.632 25.79a1.895 1.895 0 110 3.79 1.895 1.895 0 010-3.79zm0-1.264a3.158 3.158 0 100 6.317 3.158 3.158 0 000-6.317z"
    ></path>
    <path
      fill="currentColor"
      d="M37.632 22.842a4.842 4.842 0 110 9.685 4.842 4.842 0 010-9.685zm0-2.526a7.368 7.368 0 100 14.735 7.368 7.368 0 000-14.735z"
    ></path>
    <path
      fill="currentColor"
      d="M37.632 29.579H12.368v1.263h25.264v-1.263zM37.632 24.526H12.368v1.264h25.264v-1.264zM25 22.421h10.737l-.842-4.21h-4.377a3.16 3.16 0 00-2.234.924L25 22.42z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M37.632 27.684L33.42 14h-3.158"
    ></path>
    <path
      fill="currentColor"
      d="M25.21 22.421h-7.789v1.263h7.79v-1.263zM36.053 16.105h1.579v3.158h-1.58a1.579 1.579 0 110-3.158z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleMotorcycle;
