import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationFuneralInsurance: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="356"
      height="229"
      fill="none"
      viewBox="0 0 356 229"
    >
      <mask
        id="mask0_5204_12052"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_5204_12052)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <mask
          id="path-3-outside-1_5204_12052"
          width="187"
          height="169"
          x="84.492"
          y="41.977"
          fill="#000"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M84.492 41.977H271.492V210.977H84.492z"></path>
          <path
            fillRule="evenodd"
            d="M177.743 78.484c-29.956-44.482-55.128-33.172-73.54-18.442-27.692 22.154-27.402 88.521 72.939 145.587v.68l.601-.339.601.339v-.68c100.341-57.066 100.631-123.433 72.938-145.587-18.412-14.73-43.583-26.04-73.539 18.442z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M177.743 78.484c-29.956-44.482-55.128-33.172-73.54-18.442-27.692 22.154-27.402 88.521 72.939 145.587v.68l.601-.339.601.339v-.68c100.341-57.066 100.631-123.433 72.938-145.587-18.412-14.73-43.583-26.04-73.539 18.442z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M104.203 60.042l-2.499-3.123 2.499 3.123zm73.54 18.442l-3.318 2.234a4 4 0 006.636 0l-3.318-2.234zm-.601 127.145h4a4.003 4.003 0 00-2.023-3.478l-1.977 3.478zm0 .68h-4a4 4 0 005.963 3.485l-1.963-3.485zm.601-.339l1.97-3.481a4 4 0 00-3.941 0l1.971 3.481zm.601.339l-1.964 3.485a4 4 0 005.964-3.485h-4zm0-.68l-1.977-3.478a4 4 0 00-2.023 3.478h4zm72.938-145.587l-2.498 3.124 2.498-3.124zm-144.58 3.124c9.045-7.236 18.904-12.894 29.654-12.118 10.577.764 23.407 7.899 38.069 29.67l6.636-4.469c-15.295-22.71-30.028-32.161-44.129-33.18-13.928-1.006-25.86 6.356-35.228 13.85l4.998 6.247zm72.417 138.985c-49.651-28.237-73.946-58.439-82.678-83.714-8.736-25.284-1.969-45.488 10.261-55.271l-4.998-6.247c-15.462 12.37-22.396 36.427-12.825 64.131 9.575 27.714 35.595 59.227 86.285 88.056l3.955-6.955zm-5.977 3.478v.68h8v-.68h-8zm5.963 4.165l.608-.343-3.941-6.962-.594.335 3.927 6.97zm1.203-6.97l-.595-.335-3.941 6.962.608.343 3.928-6.97zm-5.964 2.805v.68h8v-.68h-8zm5.978 3.477c50.689-28.829 76.709-60.342 86.284-88.056 9.572-27.704 2.638-51.761-12.825-64.131l-4.997 6.247c12.229 9.783 18.997 29.987 10.261 55.271-8.732 25.275-33.027 55.477-82.678 83.714l3.955 6.955zm73.459-152.187c-9.367-7.494-21.299-14.856-35.228-13.85-14.101 1.019-28.834 10.47-44.128 33.18l6.636 4.47c14.661-21.772 27.492-28.907 38.069-29.67 10.749-.777 20.609 4.88 29.654 12.117l4.997-6.247z"
          mask="url(#path-3-outside-1_5204_12052)"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M119.119 130.959h24.001a1 1 0 00.867-.502l8.083-14.056a1 1 0 011.747.025l9.811 18.267a1 1 0 001.812-.108l12.583-32.156c.354-.905 1.661-.824 1.9.119l12.19 48.041c.237.935 1.528 1.026 1.894.134l7.863-19.144a1 1 0 01.925-.62h32.774"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationFuneralInsurance;
