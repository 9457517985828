import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleEmergencyBraking: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25 22.71A2.29 2.29 0 1122.71 25 2.3 2.3 0 0125 22.71zM25 17a8 8 0 100 16.001A8 8 0 0025 17z"
    ></path>
    <path
      fill="currentColor"
      d="M46 25a21 21 0 01-41.57 4.18 1 1 0 011-1.18h2c.72 0 1.12.33 1.22.79A16.81 16.81 0 1028.8 8.64a1 1 0 01-.8-1V5.41a1 1 0 011.22-1A21 21 0 0146 25z"
    ></path>
    <path
      fill="currentColor"
      d="M24 3A22 22 0 003 24a1 1 0 001 1h4.23a1 1 0 001-.94A15.78 15.78 0 0124.06 9.23a1 1 0 00.94-1V4a1 1 0 00-1-1z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleEmergencyBraking;
