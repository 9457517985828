import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleCruiseControl: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M31.99 16.238a3.765 3.765 0 00-.36-.24 12 12 0 00-16.64 16.62c.073.124.153.244.24.36a11.999 11.999 0 1016.76-16.74zm-7 13.18a3.44 3.44 0 01-3.43-3.43c0-.462.095-.918.28-1.34l-3.76-3.75a1.29 1.29 0 011.82-1.82l3.75 3.76c.422-.185.879-.28 1.34-.28a3.43 3.43 0 110 6.86z"
    ></path>
    <path
      fill="currentColor"
      d="M23.901 47a21 21 0 01-9.67-39h-1.18a2 2 0 110-4h6a2 2 0 012 2v6a2 2 0 01-4 0v-.76a16.78 16.78 0 109.92-1.93 2.101 2.101 0 11.15-4.2h.21A21.015 21.015 0 0123.901 47z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleCruiseControl;
