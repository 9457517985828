import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyTheft: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M39 20.88l-2 4.33-1.35-2.29-3.5 2.66a2.2 2.2 0 01-3.08-.43A1.429 1.429 0 0129 25l-1.77 2.7 3.52 2A2.49 2.49 0 0132 32v9.5a2.5 2.5 0 01-5 0v-8.16l-4.61-2.66a2.74 2.74 0 01-1-3.73c.208-.163.405-.34.59-.53l2.85-2.85a6.997 6.997 0 011.79-1.27 6.549 6.549 0 015.06-.36 1.003 1.003 0 00.64-1.9 8.73 8.73 0 00-8.9 2.12L20.57 25A6.67 6.67 0 0118 26.6l-.54.18c-5.63.93-8.74-5.72-4.43-10.89 3.38-4.07 8-5 13-3 3.83 1.53 7.73 6 7.73 6a1.71 1.71 0 012-.22 2.49 2.49 0 011.39 2.18c-.015.13-.045.257-.09.38l1.94-.35zM34 16a4 4 0 10-4-4 3.94 3.94 0 004 4z"
    ></path>
    <path
      fill="currentColor"
      d="M21.39 32.41l1.2.69L21 36.7a2.2 2.2 0 01-2 1.3 2.081 2.081 0 01-.88-.19l-7.61-3.4a2.5 2.5 0 01-1.06-3.67 2.58 2.58 0 013.2-.85l5 2.22 1.48-3.3c.02.25.064.498.13.74a4.67 4.67 0 002.13 2.86z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyTheft;
