import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCertificate: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="356"
      height="229"
      fill="none"
      viewBox="0 0 356 229"
      {...otherProps}
    >
      <mask
        id="mask0_5603_12392"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill={colors.primary[50]} d="M0 .812h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_5603_12392)">
        <path
          fill={colors.primary[200]}
          d="M337 161.812c0 88.365-71.634 160-160 160s-160-71.635-160-160c0-88.366 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M75.437 255.031a5.533 5.533 0 004.507 6.392l158.031 27.278a5.534 5.534 0 006.388-4.511l30.571-177.105-38.323-54.298-117.164-20.224a5.528 5.528 0 00-6.387 4.508L75.437 255.03z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M274.937 107.086l-41.23-7.117a5.164 5.164 0 01-4.21-5.967l7.114-41.215 38.326 54.299z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M200.026 181.89l33.993 5.867M173.441 207.862l55.456 9.573M107.269 227.001l116.505 20.11"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M121.14 171.125l14.199-80.718 49.968 8.79-14.199 80.717-20.139-31.941-29.829 23.152z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M175.903 54.684l6.056 7.032a6.006 6.006 0 002.427 1.698l8.683 3.278a2.002 2.002 0 011.258 1.5l1.728 9.117a6.007 6.007 0 001.253 2.684l5.88 7.18c.442.54.571 1.27.341 1.928l-3.063 8.76a6.013 6.013 0 00-.257 2.951l1.502 9.159a2 2 0 01-.668 1.839l-7.033 6.056a6.015 6.015 0 00-1.698 2.427l-3.278 8.682a1.998 1.998 0 01-1.499 1.258l-9.118 1.729a5.998 5.998 0 00-2.684 1.253l-7.18 5.88a2 2 0 01-1.927.34l-8.761-3.062a6.003 6.003 0 00-2.951-.257l-9.158 1.502a2 2 0 01-1.839-.669l-6.056-7.032a5.995 5.995 0 00-2.427-1.698l-8.682-3.279a2 2 0 01-1.259-1.498l-1.728-9.118a6.004 6.004 0 00-1.253-2.684l-5.88-7.181a2.004 2.004 0 01-.341-1.927l3.063-8.76a6.002 6.002 0 00.257-2.951l-1.502-9.159a2 2 0 01.668-1.839l7.033-6.056a5.998 5.998 0 001.698-2.427l3.278-8.682a2 2 0 011.499-1.258l9.118-1.729a6.001 6.001 0 002.684-1.253l7.18-5.88a2 2 0 011.927-.34l8.761 3.062a6 6 0 002.951.257l9.158-1.502a1.999 1.999 0 011.839.669z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M170.248 70.247l3.17 3.68a5.998 5.998 0 002.427 1.698l4.544 1.716a2 2 0 011.259 1.499l.904 4.772a6.007 6.007 0 001.253 2.684l3.078 3.758c.441.54.57 1.27.34 1.928l-1.602 4.585a5.991 5.991 0 00-.257 2.95l.786 4.794a2.001 2.001 0 01-.669 1.839l-3.681 3.17a6.012 6.012 0 00-1.698 2.427l-1.716 4.544a2 2 0 01-1.498 1.259l-4.773.905a5.996 5.996 0 00-2.684 1.252l-3.758 3.078a2.001 2.001 0 01-1.927.341l-4.585-1.603a5.991 5.991 0 00-2.951-.257l-4.794.786a2 2 0 01-1.839-.669l-3.169-3.68a6.002 6.002 0 00-2.427-1.698l-4.545-1.716a2 2 0 01-1.258-1.499l-.905-4.772a5.993 5.993 0 00-1.253-2.684l-3.077-3.759a1.998 1.998 0 01-.341-1.927l1.603-4.585a6.002 6.002 0 00.257-2.95l-.786-4.794a2 2 0 01.668-1.84l3.681-3.17a5.997 5.997 0 001.698-2.426l1.716-4.544a2 2 0 011.499-1.259l4.772-.905a6.001 6.001 0 002.684-1.252l3.758-3.078a2 2 0 011.927-.34l4.586 1.602c.946.331 1.961.42 2.951.257l4.793-.786a2 2 0 011.839.669z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="2"
          d="M149.067 89.675l22.107 3.73-3.443 20.407-22.107-3.731 3.443-20.406z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M170.19 88.868a9.482 9.482 0 00-4.316-9.615 9.485 9.485 0 00-14.39 6.459l-.718 4.251 5.102.861.717-4.251a4.314 4.314 0 014.969-3.534 4.308 4.308 0 013.534 4.968l-.717 4.252 5.102.86.717-4.25z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M161.093 101.325a2.587 2.587 0 10-4.579 1.169l-.399 2.363a1.727 1.727 0 002.702 1.693c.372-.265.625-.667.701-1.119l.399-2.363a2.565 2.565 0 001.176-1.743z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.812a2 2 0 012-2h310a2 2 0 110 3.999H22a2 2 0 01-2-1.999z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationCertificate;
