const parts = ['container', 'item', 'link', 'separator'];

const baseStyleLink = {
  fontSize: '14',
  color: 'text.gray',
};

const baseStyle = {
  link: baseStyleLink,
};

const breadcrumb = {
  parts,
  baseStyle,
};

export default breadcrumb;
