import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleTaxes: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25 35a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
    ></path>
    <path
      fill="currentColor"
      d="M30 22l-.89-.9-.83.9-1.56 1.69L25.46 25H36a3 3 0 003 3v7a3 3 0 00-3 3H14a3 3 0 00-3-3v-7a3 3 0 003-3h4.07l-3 3.06L15 32h2l13.49-14.49a4.49 4.49 0 10-2.49-4c.008.438.079.872.21 1.29l-2.71 2.72-2.71-2.73c.131-.418.202-.852.21-1.29a4.54 4.54 0 10-2.49 4L23 20l-2 2H8v19h34V22H30zm2.5-11a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm-14 5a2.5 2.5 0 110-5 2.5 2.5 0 010 5z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleTaxes;
