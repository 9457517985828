import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceFuneral: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M189 228h76v-19.29c0-2.84-1.144-5.564-3.179-7.573a10.93 10.93 0 0 0-7.676-3.137h-54.29a10.93 10.93 0 0 0-7.676 3.137A10.642 10.642 0 0 0 189 208.71V228Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M258 198h-61v-68.727c8.91-8.931 16.256-15.273 30.5-15.273 14.22 0 21.59 6.349 30.5 15.273V198Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M155.968 199.497H95.975c-1.32 0-2.585.533-3.518 1.482a5.111 5.111 0 0 0-1.457 3.58V228h70v-23.412a5.139 5.139 0 0 0-.376-1.96 5.08 5.08 0 0 0-1.091-1.659 4.968 4.968 0 0 0-1.637-1.101 4.888 4.888 0 0 0-1.928-.371ZM148.976 171h-46.001c-1.32 0-2.585.534-3.518 1.483a5.11 5.11 0 0 0-1.457 3.58v23.405h56v-23.376a5.143 5.143 0 0 0-.375-1.958 5.077 5.077 0 0 0-1.09-1.659 4.956 4.956 0 0 0-1.634-1.101 4.898 4.898 0 0 0-1.925-.374Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M155.995 100.995H140.02V79.978A4.977 4.977 0 0 0 135.043 75h-18.058a4.975 4.975 0 0 0-4.976 4.978v21.017H95.977A4.974 4.974 0 0 0 91 105.973v18.016a4.982 4.982 0 0 0 3.072 4.599c.604.25 1.251.379 1.905.379h16.003V171h28.04v-42.033h16.003a4.977 4.977 0 0 0 4.977-4.978v-17.994a4.982 4.982 0 0 0-5.005-5Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationInsuranceFuneral;
