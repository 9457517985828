import React from 'react';
import { CenterProps, HStack, Center, Text } from '../../../../index';
import { AvatarPickerOption } from '.';
import { getInitialsByName } from '../../../../../common/src/legacy/services/stringService';

interface Props {
  /** An array of initials ['MV', 'BP'] */
  selectedOptions: AvatarPickerOption[];
}

const AMOUNT_OF_AVATARS = 4;

const AvatarPickerAvatars: React.FC<Props> = ({ selectedOptions }) => {
  if (!selectedOptions.length) {
    return (
      <AvatarPickerAvatar
        bg="gray.strokeAndBg"
        borderColor="gray.300"
        borderStyle="dashed"
      />
    );
  }

  return (
    <HStack spacing="-5">
      {selectedOptions.slice(0, AMOUNT_OF_AVATARS).map((option, index) => (
        <AvatarPickerAvatar
          key={option.value}
          brokerInitials={getInitialsByName(option.label).initials}
          isLast={index === AMOUNT_OF_AVATARS - 1}
          selectedBrokersAmount={
            selectedOptions.slice(AMOUNT_OF_AVATARS - 1, -1).length
          }
        />
      ))}
    </HStack>
  );
};

interface AvatarProps {
  /** Initials of the broker name */
  brokerInitials?: string;
  /** Amount of extra selected brokers  */
  selectedBrokersAmount?: number;
  /** Enables showing the amount */
  isLast?: boolean;
}

const AvatarPickerAvatar: React.FC<AvatarProps & CenterProps> = ({
  brokerInitials,
  isLast,
  selectedBrokersAmount,
  ...props
}) => {
  return (
    <Center
      bg="primary.50"
      border="1px"
      borderColor="primary.200"
      color="primary.500"
      borderRadius="full"
      w="8"
      h="8"
      position="relative"
      overflow="hidden"
      flexShrink={0}
      {...props}
    >
      {isLast && !!selectedBrokersAmount ? (
        <Center
          position="absolute"
          left="0"
          top="0"
          w="100%"
          h="100%"
          bg="rgba(0,0,0, 0.45)"
          color="white"
        >
          <Text lineHeight="100%" textStyle="smallBodyMedium">
            +{selectedBrokersAmount}
          </Text>
        </Center>
      ) : (
        <Text lineHeight="0%" textStyle="smallBodyMedium">
          {brokerInitials}
        </Text>
      )}
    </Center>
  );
};

export default AvatarPickerAvatars;
