import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationFirePlace: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_5102_12004"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_5102_12004)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <mask id="path-4-inside-1_5102_12004" fill="#fff">
          <rect width="220" height="148" x="68" y="80" rx="2"></rect>
        </mask>
        <rect
          width="220"
          height="148"
          x="68"
          y="80"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-4-inside-1_5102_12004)"
          rx="2"
        ></rect>
        <mask id="path-5-inside-2_5102_12004" fill="#fff">
          <rect width="244" height="33" x="56" y="51" rx="2"></rect>
        </mask>
        <rect
          width="244"
          height="33"
          x="56"
          y="51"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-5-inside-2_5102_12004)"
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M68 98L288 98"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M68 114L288 114"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M68 130L288 130"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M68 146L288 146"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M68 162L288 162"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M68 178L288 178"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M68 194L288 194"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M68 210L288 210"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M118 82H162V98H118z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M246 82H278V98H246z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M190 98H222V114H190z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M106 114H154V130H106z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M238 130H270V146H238z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M82 162H118V178H82z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M70 194H106V210H70z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M250 194H286V210H250z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M202 80L202 100"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M98 96L98 116"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M138 96L138 116"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M262 96L262 116"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M254 112L254 132"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M202 112L202 132"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M86 128L86 148"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M254 144L254 164"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M102 144L102 164"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M90 176L90 196"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M86 208L86 228"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M270 208L270 228"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M266 176L266 196"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M274 160L274 180"
        ></path>
        <mask id="path-36-inside-3_5102_12004" fill="#fff">
          <rect width="124" height="101" x="116" y="127" rx="2"></rect>
        </mask>
        <rect
          width="124"
          height="101"
          x="116"
          y="127"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-36-inside-3_5102_12004)"
          rx="2"
        ></rect>
        <rect
          width="60"
          height="12"
          x="150"
          y="214"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6"
        ></rect>
        <path
          fill={colors.primary[300]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M163.759 163.492a1.197 1.197 0 011.222-.019 1.197 1.197 0 01.608 1.06 16.285 16.285 0 003.139 10.252 1.19 1.19 0 001.585.289c1.943-1.213 6.444-5.023 8.693-15.144a40.461 40.461 0 00.576-15.53 1.19 1.19 0 01.498-1.188 1.194 1.194 0 011.288-.046 49.28 49.28 0 0124.082 41.88c.343 15.012-11.515 27.852-26.53 27.804a26.454 26.454 0 01-26.373-26.457c.002-10.888 4.926-18.818 11.212-22.901z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M187.322 181.499a1.205 1.205 0 00-1.577.641 1.208 1.208 0 00-.085.617c.135.908.186 1.826.154 2.742-.208 5.995-3.949 9.658-8.146 9.513a6.6 6.6 0 01-5.904-3.94 1.204 1.204 0 00-1.932-.378c-.186.18-.31.414-.352.669a13.224 13.224 0 006.561 13.983 13.233 13.233 0 0015.345-1.77 13.228 13.228 0 004.326-9.767 13.23 13.23 0 00-8.39-12.31z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationFirePlace;
