import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationSettings: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M30.04 33.54v-5a.5.5 0 00-.5-.5h-3c-.18-.558-.408-1.1-.68-1.62l1.79-1.78a.51.51 0 000-.71l-3.54-3.54a.51.51 0 00-.71 0l-1.78 1.79a10.467 10.467 0 00-1.58-.68v-3a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v3c-.558.18-1.1.408-1.62.68l-1.78-1.79a.51.51 0 00-.71 0l-3.54 3.54a.51.51 0 000 .71l1.79 1.78c-.272.52-.5 1.062-.68 1.62h-3a.5.5 0 00-.5.5v5a.5.5 0 00.5.5h3c.172.533.386 1.051.64 1.55l-1.75 1.74a.51.51 0 000 .71l3.54 3.54a.51.51 0 00.71 0l1.7-1.71c.544.288 1.113.525 1.7.71v3a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-3a10.157 10.157 0 001.7-.71l1.7 1.71a.51.51 0 00.71 0l3.54-3.54a.51.51 0 000-.71l-1.75-1.74c.254-.499.468-1.017.64-1.55h3a.5.5 0 00.46-.5zm-13 1.79a4.29 4.29 0 110-8.58 4.29 4.29 0 010 8.58z"
    ></path>
    <path
      fill="currentColor"
      d="M45.54 15.04h-3c-.18-.558-.408-1.1-.68-1.62l1.79-1.78a.51.51 0 000-.71l-3.5-3.54a.51.51 0 00-.71 0l-1.78 1.79a10.47 10.47 0 00-1.62-.68v-3a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v3c-.558.18-1.1.408-1.62.68l-1.78-1.79a.51.51 0 00-.71 0l-3.54 3.54a.51.51 0 000 .71l1.79 1.78c-.272.52-.5 1.062-.68 1.62h-3a.5.5 0 00-.5.5v1.55a2.5 2.5 0 012 2.45v.43a2.46 2.46 0 011.76-.72c.663 0 1.3.262 1.77.73l3.53 3.53c.468.47.73 1.107.73 1.77a2.459 2.459 0 01-.68 1.76h.43a2.5 2.5 0 012.5 2.5v1.5h3.5a.5.5 0 00.5-.5v-3a10.157 10.157 0 001.7-.71l1.7 1.71a.51.51 0 00.71 0l3.54-3.5a.51.51 0 000-.71l-1.75-1.74c.254-.499.468-1.017.64-1.55h3a.5.5 0 00.5-.5v-5a.5.5 0 00-.54-.5zm-12.5 7.29a4.29 4.29 0 110-8.58 4.29 4.29 0 010 8.58z"
    ></path>
  </Icon>
);

export default GeneralNavigationSettings;
