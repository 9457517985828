import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCollections: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#a)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M278.725 66.868a9.006 9.006 0 00-6.648-2.484L177.76 77.476 83.442 64.384A9.017 9.017 0 0074 73.396v118.73a9 9 0 002.644 6.384 8.842 8.842 0 008.78 1.92l15.365-4.61a99.741 99.741 0 0154.895-.693l16.256 4.434a22.105 22.105 0 0011.646 0l16.243-4.434a99.741 99.741 0 0154.895.693l15.365 4.61a8.839 8.839 0 008.78-1.923 8.981 8.981 0 002.644-6.381V73.396a9.024 9.024 0 00-2.788-6.528z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M83.022 154.195v19.11a9.019 9.019 0 003.565 7.187 9.017 9.017 0 007.88 1.508c18.071-5.017 54.167-11.135 83.299 8.288v-18.046l-94.744-18.047zm189.601 0v19.11a9.014 9.014 0 01-3.565 7.187 9.024 9.024 0 01-7.881 1.508c-18.071-5.017-54.167-11.135-83.298 8.288v-18.046l94.744-18.047z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M177.759 190.288a170.897 170.897 0 00-76.437-18.046h-9.279a9.021 9.021 0 01-9.022-9.022V63.97a9.022 9.022 0 019.022-9.022h9.279c26.534 0 52.704 6.178 76.437 18.044v117.296zm.001 0a170.897 170.897 0 0176.437-18.046h9.288a9.023 9.023 0 009.022-9.022V63.97a9.02 9.02 0 00-9.022-9.022h-9.288a170.92 170.92 0 00-76.437 18.044v117.296z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M94.796 70.777a4.51 4.51 0 014.572-4.509c7.018.091 13.707.41 20.214 1.082a4.5 4.5 0 014.048 4.5v14.64a4.506 4.506 0 01-1.491 3.35 4.514 4.514 0 01-3.486 1.138c-6.249-.627-12.678-.94-19.404-1.016a4.509 4.509 0 01-4.453-4.506V70.777zm0 33.835a4.509 4.509 0 014.572-4.509c7.018.091 13.707.411 20.214 1.082a4.503 4.503 0 014.048 4.487v14.641a4.518 4.518 0 01-1.489 3.347 4.517 4.517 0 01-3.481 1.14c-6.25-.627-12.678-.94-19.404-1.016a4.51 4.51 0 01-4.453-4.509l-.007-14.663zm0 33.835a4.509 4.509 0 014.572-4.509c7.018.091 13.707.411 20.214 1.082a4.503 4.503 0 014.048 4.487v15.635a4.518 4.518 0 01-1.489 3.347 4.517 4.517 0 01-3.481 1.14c-6.25-.627-12.678-.94-19.404-1.016a4.509 4.509 0 01-4.453-4.509l-.007-15.657zm69.335-58.802a4.515 4.515 0 012.575 4.077v13.763a4.512 4.512 0 01-2.044 3.778 4.502 4.502 0 01-4.281.348 127.034 127.034 0 00-18.74-6.556 4.505 4.505 0 01-3.355-4.362V76.359a4.518 4.518 0 011.792-3.603 4.51 4.51 0 013.956-.737 128.814 128.814 0 0120.097 7.626zm-25.845 30.549a4.503 4.503 0 011.792-3.603 4.507 4.507 0 013.956-.737 128.655 128.655 0 0120.097 7.626 4.51 4.51 0 012.575 4.077v13.763a4.513 4.513 0 01-2.044 3.777 4.507 4.507 0 01-4.281.349 126.799 126.799 0 00-18.733-6.563 4.513 4.513 0 01-3.362-4.352v-14.337zm0 33.835a4.503 4.503 0 011.792-3.603 4.507 4.507 0 013.956-.737 128.655 128.655 0 0120.097 7.626 4.51 4.51 0 012.575 4.077v14.738a4.514 4.514 0 01-6.325 4.133 126.852 126.852 0 00-18.733-6.566 4.52 4.52 0 01-3.362-4.359v-15.309zm92.405-72.807a4.52 4.52 0 014.44-4.64c7.018-.11 13.716.018 20.239.504a4.497 4.497 0 014.167 4.368l.42 14.635a4.505 4.505 0 01-1.391 3.389 4.51 4.51 0 01-3.447 1.24c-6.272-.455-12.7-.568-19.426-.458a4.513 4.513 0 01-4.582-4.39l-.42-14.648zm.969 33.808a4.51 4.51 0 014.44-4.644c7.018-.11 13.716.015 20.238.501a4.51 4.51 0 014.171 4.368l.417 14.635a4.495 4.495 0 01-1.385 3.399 4.499 4.499 0 01-3.454 1.242c-6.271-.452-12.696-.564-19.426-.458a4.504 4.504 0 01-4.578-4.39l-.423-14.653z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M232.632 138.851a4.51 4.51 0 014.441-4.641c7.017-.109 13.712.019 20.235.505a4.498 4.498 0 014.17 4.368l.449 15.626a4.517 4.517 0 01-2.987 4.373 4.504 4.504 0 01-1.855.255c-6.271-.454-12.697-.567-19.423-.461a4.515 4.515 0 01-3.193-1.253 4.515 4.515 0 01-1.388-3.137l-.449-15.635z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M191.387 79.645a4.512 4.512 0 00-2.574 4.077v13.763a4.505 4.505 0 006.312 4.126 127.108 127.108 0 0118.749-6.556 4.501 4.501 0 003.358-4.362V76.359a4.503 4.503 0 00-3.683-4.437 4.505 4.505 0 00-2.064.097 128.725 128.725 0 00-20.098 7.626zm0 33.835a4.517 4.517 0 00-2.574 4.077v13.763a4.505 4.505 0 006.312 4.126 127.09 127.09 0 0118.737-6.566 4.504 4.504 0 003.37-4.349v-14.337a4.502 4.502 0 00-1.792-3.603 4.502 4.502 0 00-3.955-.737 128.704 128.704 0 00-20.098 7.626zm0 34.327a4.52 4.52 0 00-2.574 4.077v13.763a4.511 4.511 0 002.039 3.772 4.5 4.5 0 004.273.357 127.09 127.09 0 0118.737-6.566 4.505 4.505 0 003.358-4.362v-14.324a4.503 4.503 0 00-1.792-3.603 4.52 4.52 0 00-3.956-.737 129.023 129.023 0 00-20.085 7.623z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationCollections;
