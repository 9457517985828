import { isEmpty, isNaN } from 'lodash';
import { useFlowsSelector } from '../../../common/src/utils/globalVariables';
import { isLowerCaseStringEqual } from '../../../common/src/utils/stringUtils';
import { hasSubGroups } from '../config/flowsStructureConfig';
import store from '../store/redux/store';
import {
  FlowsQuestionAnswer,
  FlowsQuestionGroup,
  FlowsQuestionnaire,
} from '../types/FlowsTypes';
import {
  flattenQuestionaire,
  getGroupAndQuestionIndexByQuestionId,
  isIndexEqual,
} from '../utils/flowsQuestionsUtils';
import { useFlowsRouteParams } from './useFlowsRouteParams';

interface FlowsHelper {
  /** Functions */
  getGroupsForTag: (tag: string) => FlowsQuestionGroup[] | undefined;
  getAnswerForQuestion: (
    questionId: string,
    index?: number,
  ) => FlowsQuestionAnswer | undefined;
  getGroupPosition: (groupId: string) => number;
  getAnswersForGroup: (
    groupId: string,
    groupIndex?: number,
  ) => FlowsQuestionAnswer[];
  getVisibleAnswersForGroup: (
    groupId: string,
    groupIndex?: number,
  ) => FlowsQuestionAnswer[];

  getGroupByQuestionId: (questionId: string) => FlowsQuestionGroup;

  /** Variables */
  flowsBaseURL: string;
  currentTag: string | undefined;
}

/**
 * Exposes multiple functions that are too small to be a seperate hook but contain useful functionality
 */
const useFlowsHelper = (): FlowsHelper => {
  const { flowId, sessionId, position } = useFlowsRouteParams();

  const groups = useFlowsSelector(
    (state) => state.flows.initialQuestionnaire.groups,
  );
  const currentGroups = useFlowsSelector(
    (state) => state.flows.questionnaire.groups,
  );
  const answers = useFlowsSelector((state) => state.flows.answers);
  const questionnaire = useFlowsSelector((state) => state.flows.questionnaire);
  const initialQuestionnaire = useFlowsSelector(
    (state) => state.flows.initialQuestionnaire,
  );
  const lastPosition = useFlowsSelector(
    (state) => state.flowsMetaData.lastPosition,
  );

  /**
   * Will return all groups that have the given tag in `group.tag`
   * @param tag Tag to filter on
   * @returns All groups that have the given tag
   */
  const getGroupsForTag = (tag: string) => {
    return currentGroups.find(({ tag: _tag }) =>
      isLowerCaseStringEqual(_tag, tag),
    )?.groups;
  };

  /**
   * Will give the answer object for the given question if it is found
   * @param questionId Question id to look for
   * @param index Index should ALWAYS be passed to support duplicate questions
   * @returns The answer for the given question. Otherwise it reteurns undefined
   */
  const getAnswerForQuestion = (questionId: string, index?: number) => {
    return store
      .getState()
      .flows.answers.find(
        ({ id, index: answerIndex }) =>
          id === questionId && isIndexEqual(index, answerIndex),
      );
  };

  /**
   * Returns the position of the group in the questionnaire. This is the current questionnaire and NOT the initial questionnaire.
   * @param groupId The groupId
   * @returns The position of the group in the questionnaire
   */
  const getGroupPosition = (groupId: string) => {
    if (hasSubGroups(flowId)) {
      return questionnaire.groups.findIndex(({ groups }) =>
        groups?.find(({ id }) => groupId === id),
      );
    }

    return questionnaire.groups.map(({ id }) => id).indexOf(groupId);
  };

  const isAnswered = (answer: unknown) => {
    if (typeof answer === 'object') {
      if (Array.isArray(answer)) return true;
      return !isEmpty(answer);
    }
    return answer !== '' && answer != null && !isNaN(answer);
  };

  /**
   * Will return the answers of that specific group. Questions that do not contain a groupId will not be returned.
   * This is usefull to check the answered questions within a specific group.
   * @param groupId The id of the group for which the answers will be given
   * @returns An array of answers for that group
   */
  const getAnswersForGroup = (groupId: string, groupIndex?: number) => {
    return answers.filter(
      ({ groupId: _groupId, answer, index }) =>
        groupId === _groupId &&
        (groupIndex == null || index === groupIndex) &&
        isAnswered(answer),
    );
  };

  const getVisibleAnswersForGroup = (groupId: string, groupIndex?: number) => {
    const visibleQuestion = flattenQuestionaire(questionnaire).map(
      ({ id }) => id,
    );

    return getAnswersForGroup(groupId, groupIndex).filter(({ id }) =>
      visibleQuestion.includes(id),
    );
  };

  /**
   * Gets the group id by looking at the question id
   */
  const getGroupByQuestionId = (questionId: string) => {
    const [groupIndex] = getGroupAndQuestionIndexByQuestionId(
      initialQuestionnaire,
      questionId,
    );

    return groups[groupIndex];
  };

  const flowsBaseURL = `/flows/${flowId}/questions/${sessionId}`;

  const currentTag =
    position === 'overview'
      ? questionnaire.groups[lastPosition]?.tag
      : questionnaire.groups[position || '']?.tag;

  return {
    getGroupsForTag,
    getAnswerForQuestion,
    getGroupPosition,
    getAnswersForGroup,
    getVisibleAnswersForGroup,
    getGroupByQuestionId,
    flowsBaseURL,
    currentTag,
  };
};

export default useFlowsHelper;

interface Params {
  flowId: string;
  questionnaire: FlowsQuestionnaire;
  groupId: string;
}

export const getFlowsGroupPosition = ({
  flowId,
  questionnaire,
  groupId,
}: Params) => {
  if (hasSubGroups(flowId)) {
    return questionnaire.groups.findIndex(({ groups }) =>
      groups?.find(({ id }) => groupId === id),
    );
  }

  return questionnaire.groups.map(({ id }) => id).indexOf(groupId);
};
