import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersModal: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 15.91a.91.91 0 0 1 .91-.91h26.18a.91.91 0 0 1 .91.91v3.818H11v-3.819Zm-4 5.818v-5.819A4.91 4.91 0 0 1 11.91 11h26.18A4.91 4.91 0 0 1 43 15.91v17.454a4.91 4.91 0 0 1-4.91 4.909H11.91A4.91 4.91 0 0 1 7 33.363V21.729Zm32 2v9.636a.91.91 0 0 1-.91.909H11.91a.91.91 0 0 1-.91-.91v-9.635h28Z"
      fill="currentColor"
    />
  </Icon>
);

export default GeneralOthersModal;
