import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyThawing: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M23 37.18A5.66 5.66 0 0117.5 43a5.659 5.659 0 01-5.5-5.82C12 34 16.12 27 17.5 27S23 34 23 37.18z"
    ></path>
    <path
      fill="currentColor"
      d="M37 12H15a1 1 0 00-1 1v14.53C15.85 25 17 25 17.5 25s2.26 0 5 4.91A20.828 20.828 0 0124.89 36H37a1 1 0 001-1V13a1 1 0 00-1-1zm-1 7a1 1 0 01-2 0v-2.5a.5.5 0 00-.5-.5H31a1 1 0 010-2h4a1 1 0 011 1v4z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyThawing;
