import { parse } from 'date-fns';
import { clamp } from 'lodash';

const parseDay = (day: string): string => {
  if (day.length === 0) {
    return `${new Date().getDay()}`;
  }
  return clamp(parseInt(day), 1, 31).toString();
};

const parseMonth = (month: string): string => {
  if (month.length === 0) {
    return `${new Date().getMonth() + 1}`;
  }
  return clamp(parseInt(month), 1, 12).toString();
};

const parseYear = (year: string): string => {
  if (year.length === 0) {
    return `${new Date().getFullYear()}`;
  }
  if (year.length === 1) {
    return clampYear(`${year}000`).toString();
  }
  if (year.length === 2) {
    // if year is above 50 prefix it with 19. ex: 22 => 2022, 84 => 1984
    if (parseInt(year) > 50) {
      return `19${year}`;
    }
    return `20${year}`;
  }
  if (year.length === 3) {
    return `${year}0`;
  }
  return clampYear(year).toString();
};

const clampYear = (year: string) => clamp(parseInt(year), 0, 2099);

export const parseAnyStringToDate = (str: string): Date => {
  // if date is string, split all special charcaters and try to parse it to a valid date object
  const strippedDateString = (str as string).split(/[.\-_/]/);
  // replace all non numeric values in array, and assign them to day, month, year.
  const [day = '', month = '', year = ''] =
    strippedDateString.map((str) => str.replace(/\D/g, '')) || [];

  const parsedDate = parse(
    [parseDay(day), parseMonth(month), parseYear(year)].join('/'),
    'dd/MM/yyyy',
    new Date(),
  );
  if (parsedDate.toString() === 'Invalid Date') return new Date();

  return parsedDate;
};
export const datesAreOnSameDay = (first: Date, second: Date): boolean =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();
