import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersPhone: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M38.25 38.54l-6.36-5.76a.56.56 0 00-.72 0l-2.61 2a2 2 0 01-2.68-.29l-1.95-2.34A23.28 23.28 0 0119.25 23l-.76-2.93a1.9 1.9 0 011.35-2.3l3.2-.88a.54.54 0 00.4-.58l-.94-8.42a.54.54 0 00-.45-.47A16.12 16.12 0 0018.46 7a10.28 10.28 0 00-3 1.49c-1.38 1-3.86 4.16-3.43 10.32.38 5.47 3.54 11.75 3.54 11.75s3.27 6.23 7.53 9.78c4.78 4 8.87 3.91 10.47 3.38a9.688 9.688 0 003-1.49 15.7 15.7 0 001.81-3.08.54.54 0 00-.13-.61z"
    ></path>
  </Icon>
);

export default GeneralOthersPhone;
