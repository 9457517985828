import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsReturn: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.6 11.8a1.8 1.8 0 0 1 1.8-1.8h2.16a10.44 10.44 0 1 1 0 20.88H13.146l5.567 5.567a1.8 1.8 0 0 1-2.546 2.546l-8.64-8.64a1.8 1.8 0 0 1 0-2.546l8.64-8.64a1.8 1.8 0 0 1 2.546 2.546l-5.567 5.567H32.56a6.84 6.84 0 1 0 0-13.68H30.4a1.8 1.8 0 0 1-1.8-1.8Z"
      fill="currentColor"
    />
  </Icon>
);

export default GeneralActionsReturn;
