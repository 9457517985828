import React, { useRef, useState, useEffect } from 'react';
import { useTheme, Box } from '@wegroup/design-system';

// Components
import { IconActionMarker } from 'wg-fe-ui';
import {
  StaticMap as MapboxStaticMap,
  CanvasOverlay,
  Marker,
} from 'react-map-gl';

// Types
import { Coord, GeoViewPort, MapAlignment } from '../../../../../types/MapBox';
import { hexToRGB } from '../../utils/styledComponentsUtils';
import MapSkeleton from './MapSkeleton';
import { fadeIn } from '../../utils/cssAnimationsUtils';
import useForceUpdate from '../../hooks/useForceUpdate';
import styled from 'styled-components';

interface Props {
  showSatelliteAsDefault?: boolean;
  align?: MapAlignment;
  viewPort: GeoViewPort;
  propertyCoords?: Coord[][];
  isLoading?: boolean;
  detailImageSrc?: string;
}
interface RedrawObj {
  ctx: CanvasRenderingContext2D;
  width: number;
  height: number;
  project: (arg0: (number | undefined)[]) => [number, number];
}
const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN || '';
const LONG_OFFSET = 0.0021;

const StaticMap: React.FC<React.PropsWithChildren<Props>> = ({
  align = 'center',
  viewPort,
  showSatelliteAsDefault,
  propertyCoords = [],
  isLoading,
  detailImageSrc,
  ...otherProps
}) => {
  const { colors } = useTheme();
  const [isSatelliteView, setIsSatelliteView] = useState(
    showSatelliteAsDefault,
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const forceUpdate = useForceUpdate();

  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (showSatelliteAsDefault != null)
      setIsSatelliteView(showSatelliteAsDefault);
  }, [showSatelliteAsDefault]);

  const longitude = {
    left: viewPort.longitude + LONG_OFFSET,
    right: viewPort.longitude - LONG_OFFSET,
    center: viewPort.longitude,
  }[align];

  /**
   * Draws a property marking with coordinates passed as a prop
   */
  const drawPropertyMarking = (
    ctx: CanvasRenderingContext2D,
    width: number,
    height: number,
    project: (arg0: (number | undefined)[]) => [number, number],
    property: Coord[],
  ) => {
    // Clear canvas
    ctx.clearRect(0, 0, width, height);

    // Draw path around building
    ctx.beginPath();
    const [cx, cy] = project([property[0]?.lng, property[0]?.lat]);
    ctx.moveTo(cx, cy);
    property?.slice(1).forEach((coordObj) => {
      const [cx, cy] = project([coordObj.lng, coordObj.lat]);
      ctx.lineTo(cx, cy);
    });
    ctx.closePath();

    // Fill and stroke path with colors
    ctx.strokeStyle = colors.primary[500];
    ctx.stroke();
    ctx.fillStyle = hexToRGB(colors.primary[500], 0.25);
    ctx.fill();
  };

  if (isLoading) {
    return <MapSkeleton color={colors.gray.strokeAndBg} />;
  }
  return (
    <Box
      position="relative"
      w="100%"
      h="100%"
      animation={`${fadeIn} 2s`}
      data-test-id="Common_StaticMap"
      {...otherProps}
    >
      <MapboxStaticMap
        ref={mapRef}
        width="100%"
        height="100%"
        latitude={viewPort.latitude}
        longitude={longitude}
        zoom={viewPort.zoom || 16}
        mapStyle={
          isSatelliteView
            ? 'mapbox://styles/wegroup/ckm0qzpaj9i1r17qkuzpzdzsj'
            : 'mapbox://styles/wegroup/ckh535j7202zj19l4vom0279b'
        }
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onLoad={() => setIsLoaded(true)}
        onResize={forceUpdate}
      >
        {isLoaded &&
          propertyCoords.map((property, index) => (
            <CanvasOverlay
              key={`drawn_property_${index}`}
              redraw={({ ctx, project, width, height }: RedrawObj) =>
                drawPropertyMarking(ctx, width, height, project, property)
              }
            />
          ))}
        {propertyCoords.length === 0 && (
          <Marker
            latitude={viewPort.latitude}
            longitude={viewPort.longitude}
            offsetLeft={-20}
            offsetTop={-35}
          >
            <StyledIconActionMarker color={colors.primary[500]} />
          </Marker>
        )}
      </MapboxStaticMap>
    </Box>
  );
};

const StyledIconActionMarker = styled(IconActionMarker)`
  width: 4rem;
  height: 4rem;
`;

export default StaticMap;
