import React, { useRef } from 'react';
import {
  Box,
  useMediaQuery,
  SkeletonText,
  Skeleton,
  Text,
} from '@wegroup/design-system';
import { Option } from './index';
import SearchSelectDetailedOption from './SearchSelectDetailedOption';
import { chakraCustomScrollBar } from '../../../../../common/src/utils/styledComponentsUtils';
import { useVirtual } from 'react-virtual';
import { useTranslation } from 'react-i18next';

interface Props {
  options: Option[];
  onSelect: (value: string) => void;
  onEdit?: (value: string) => void;
  onRemove?: (value: string) => void;
  isLoading?: boolean;
  searchValue: string;
}

const SearchSelectDetailedList: React.FC<React.PropsWithChildren<Props>> = ({
  options,
  onSelect,
  onEdit,
  onRemove,
  isLoading,
  searchValue,
}) => {
  const { t } = useTranslation();
  const [bigHeight] = useMediaQuery(`(min-height: 500px`, { ssr: false });

  const highlightedOptions = options.map((option) => {
    const highlightedLabel = option.label.replace(
      new RegExp(searchValue, 'gi'),
      (match) => `<span>${match}</span>`,
    );
    return { ...option, label: highlightedLabel };
  });

  const parentRef = useRef(null);
  const rowVirtualizer = useVirtual({
    size: highlightedOptions.length,
    parentRef,
    keyExtractor: (index) => highlightedOptions[index].value,
  });

  return (
    <Box
      h="100%"
      maxH={bigHeight ? 'xl' : '60vh'}
      overflowY="auto"
      overflowX="hidden"
      sx={{ ...chakraCustomScrollBar }}
      data-test-id="General_SearchSelectDetailedList_List"
      ref={parentRef}
    >
      {isLoading ? (
        <SkeletonLoadingOptions />
      ) : rowVirtualizer.totalSize === 0 ? (
        <Text align="center" textStyle="largeBodyMedium" p="6">
          {t('NO-RESULTS-FOUND')}
        </Text>
      ) : (
        <Box h={rowVirtualizer.totalSize + 5} w="100%" position="relative">
          {rowVirtualizer.virtualItems.map(({ index, measureRef, start }) => {
            const { label, description, value, tags } =
              highlightedOptions[index];
            return (
              <Box
                key={index}
                ref={measureRef}
                position="absolute"
                top="0"
                left="0"
                width="100%"
                transform={`translateY(${start}px)`}
              >
                <SearchSelectDetailedOption
                  data-test-id={`General_SearchSelectDetailedList_List_${index}`}
                  key={value}
                  title={label}
                  description={description}
                  tags={tags}
                  onClick={() => onSelect(value)}
                  onEdit={onEdit && (() => onEdit(value))}
                  onRemove={onRemove && (() => onRemove(value))}
                />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

const SkeletonLoadingOptions: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return (
    <>
      <Box borderBottom="1px" borderColor="gray.strokeAndBg" p="4">
        <Skeleton h="3" maxW="40" w="100%" mb="3" />
        <SkeletonText />
      </Box>
      <Box p="4" borderBottom="1px" borderColor="gray.strokeAndBg">
        <Skeleton h="3" maxW="44" w="100%" mb="3" />
        <SkeletonText />
      </Box>
      <Box p="4">
        <Skeleton h="3" maxW="36" w="100%" mb="3" />
        <SkeletonText />
      </Box>
    </>
  );
};

export default SearchSelectDetailedList;
