import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralRiskObjectsRiskObject: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.089 16.12A31.001 31.001 0 0025 39.013a31 31 0 0012.911-22.891L25 10.174 12.089 16.12zm11.656-9.772a3 3 0 012.51 0l14.01 6.453c1.058.487 1.75 1.545 1.692 2.709a35 35 0 01-15.439 27.316 2.72 2.72 0 01-3.036 0A35 35 0 018.042 15.51c-.057-1.164.635-2.222 1.693-2.71l14.01-6.452z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.989 14.979c1.087 0 1.968.755 1.968 1.688v7.754c0 .932-.881 1.687-1.968 1.687-1.088 0-1.969-.755-1.969-1.687v-7.754c0-.933.881-1.688 1.969-1.688zM23.02 31.414c0-1.087.881-1.969 1.969-1.969h.022a1.969 1.969 0 010 3.938h-.022a1.969 1.969 0 01-1.969-1.969z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralRiskObjectsRiskObject;
