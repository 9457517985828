import {
  Tooltip,
  TooltipProps,
  Text,
  Icon,
  Box,
  IconName,
  ColorProps,
} from '@wegroup/design-system';
import React from 'react';

interface Props {
  label: string | React.ReactElement;
  iconName?: IconName;
  boxSize?: number;
  color?: ColorProps['color'];
}

const InfoTooltip: React.FC<Props & Omit<TooltipProps, 'children'>> = ({
  label,
  iconName,
  boxSize,
  color,
  ...tooltipProps
}) => {
  return (
    <Tooltip label={<Text>{label}</Text>} {...tooltipProps}>
      <Box cursor="pointer">
        <Icon
          name={iconName || 'GeneralActionsQuestion'}
          boxSize={boxSize || 7}
          color={color}
        />
      </Box>
    </Tooltip>
  );
};

export default InfoTooltip;
