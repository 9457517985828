import React, { ReactElement } from 'react';
import { useCheckbox, UseCheckboxProps } from '@wegroup/design-system';
import IllustratedButton from '../IllustratedButton';
import Checkbox from '../Checkbox';

export interface IllustratedCheckboxProps {
  children?: string | ChildNode | ReactElement;
  icon?: React.ReactNode;
  Illustration?: ReactElement;
  useCheckboxProps: UseCheckboxProps;
  isDisabled?: boolean;
}

const IllustratedCheckbox = React.forwardRef<
  HTMLInputElement,
  React.PropsWithChildren<IllustratedCheckboxProps>
>(
  (
    { children, icon, Illustration, useCheckboxProps, isDisabled, ...rest },
    ref,
  ) => {
    const { getInputProps, getLabelProps, state } =
      useCheckbox(useCheckboxProps);

    return (
      <IllustratedButton
        disabled={state.isDisabled}
        isDisabled={isDisabled}
        isChecked={state.isChecked}
        Illustration={Illustration}
        inputBox={
          (!isDisabled && <Checkbox {...getLabelProps()} />) || undefined
        }
        input={
          (!isDisabled && <input {...getInputProps({}, ref)} />) || undefined
        }
        dataTestId={`DESIGN-SYSTEM_ILLUSTRATED-CHECKBOX-BUTTON_${useCheckboxProps.value}`}
        {...getLabelProps()}
      >
        {children}
      </IllustratedButton>
    );
  },
);

export default IllustratedCheckbox;
