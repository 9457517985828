import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyHomeOffice: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M42.36 17.36l-4-2.64V8.24a1.12 1.12 0 00-1.12-1.12h-5.57a1.12 1.12 0 00-1.12 1.12v1.25L25.6 6.2a1.18 1.18 0 00-1.32 0L7.53 17.36a1.19 1.19 0 00-.53 1V42.8A1.2 1.2 0 008.2 44h33.48a1.2 1.2 0 001.2-1.2V18.35a1.2 1.2 0 00-.52-.99zm-9.89-4.08zm7.17 27.48h-29.4V19.52l14.7-9.69L29 12.56l2.33 1.53a1.56 1.56 0 002.42-1.31v-2.43h1.36v5.44a1.54 1.54 0 00.71 1.31l3.78 2.43.04 21.23z"
    ></path>
    <path
      fill="currentColor"
      d="M37 33v5.5a.5.5 0 01-.5.5h-23a.5.5 0 01-.5-.5V33h9a2 2 0 002 2h2a2 2 0 002-2h9zM29 23v-3.5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5V23h-7.5a.5.5 0 00-.5.5V31h9v-3h6v3h9v-7.5a.5.5 0 00-.5-.5H29zm-6-2h4v2h-4v-2z"
    ></path>
    <path
      fill="currentColor"
      d="M24 30h2v2.5a.5.5 0 01-.5.5h-1a.5.5 0 01-.5-.5V30z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyHomeOffice;
