import React from 'react';
import { Center } from '@wegroup/design-system';

interface Props {
  isChecked: boolean;
}

const SmallBullet: React.FC<React.PropsWithChildren<Props>> = ({
  isChecked,
}) => {
  return (
    <Center
      flexShrink={0}
      borderRadius="full"
      border="1px"
      borderColor={isChecked ? 'primary.500' : 'gray.100'}
      bg="white"
      w="6"
      h="6"
    >
      <Center
        borderRadius="full"
        bg={isChecked ? 'primary.500' : 'white'}
        w="3"
        h="3"
      />
    </Center>
  );
};
export default SmallBullet;
