import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationClaims: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M37.28 16h-7a.29.29 0 01-.3-.3v-7a.3.3 0 01.51-.21l7 7a.3.3 0 01-.21.51z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M37.7 18h-9.4a.29.29 0 01-.3-.3V8.3a.29.29 0 00-.3-.3H12.3a.29.29 0 00-.3.3v33.4a.29.29 0 00.3.3h25.4a.29.29 0 00.3-.3V18.3a.29.29 0 00-.3-.3zm-6.48 5.56l-5.83 7.09a.93.93 0 01-.68.35h-.05a1 1 0 01-.68-.28L20.28 27a1 1 0 111.35-1.35l3 3 5.16-6.28a.94.94 0 011.34-.13 1 1 0 01.09 1.32z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationClaims;
