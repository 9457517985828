import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsReconstructionCost: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.79 30.79a1.79 1.79 0 100 3.578 1.79 1.79 0 000-3.579zM5 32.578a5.79 5.79 0 1111.579 0 5.79 5.79 0 01-11.579 0zM27.842 30.79a1.79 1.79 0 100 3.578 1.79 1.79 0 000-3.579zm-5.79 1.789a5.79 5.79 0 1111.58 0 5.79 5.79 0 01-11.58 0zM37.316 23a2 2 0 012 2v9.368H43a2 2 0 010 4h-5.684a2 2 0 01-2-2V25a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.79 36.368a2 2 0 012-2h17.052a2 2 0 110 4H10.79a2 2 0 01-2-2zM8.79 28.79a2 2 0 012-2h17.052a2 2 0 110 4H10.79a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.368 13.632a2 2 0 012-2h3.79a7.684 7.684 0 017.684 7.684v9.474a2 2 0 11-4 0v-9.474a3.684 3.684 0 00-3.684-3.684h-1.79v7.473a2 2 0 01-4 0v-9.473z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.684 25.105h13.158a2 2 0 100-4H14.58A3.895 3.895 0 0010.684 25v3.79a2 2 0 104 0v-3.685zM29.632 32.579a2 2 0 012-2h5.684a2 2 0 110 4h-5.684a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsReconstructionCost;
