import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleBlindSpotMonitoring: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M23 23.85a8.25 8.25 0 00-4.76 1.51.37.37 0 00-.11.5l1.33 2.19a.39.39 0 00.25.17.42.42 0 00.29 0 5.29 5.29 0 016.18.13.35.35 0 00.22.08h.07a.42.42 0 00.25-.16l1.41-2.14a.37.37 0 00-.09-.5A8.24 8.24 0 0023 23.85z"
    ></path>
    <path
      fill="currentColor"
      d="M31.23 21.55a13.08 13.08 0 00-16.13-.29.39.39 0 00-.1.51l1.4 2.14a.35.35 0 00.25.17.39.39 0 00.29-.07 10.05 10.05 0 0112.37.22.33.33 0 00.23.09h.05a.37.37 0 00.26-.15l1.46-2.1a.38.38 0 00-.08-.52z"
    ></path>
    <path
      fill="currentColor"
      d="M45.46 18.39h-2.7a1.09 1.09 0 00-1.08 1.08v1.08a4.31 4.31 0 01-4.31 4.32v-4.32A7.55 7.55 0 0029.82 13h-8.64A16.19 16.19 0 005 29.18v1.08a3.24 3.24 0 003.24 3.24h29.13a4.31 4.31 0 014.31 4.32V40a1.08 1.08 0 001.08 1.08h2.7a.53.53 0 00.54-.54V18.93a.54.54 0 00-.54-.54zM34.13 30.26H8.24v-1.08a13 13 0 0112.94-12.94h8.64a4.31 4.31 0 014.31 4.31v9.71z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleBlindSpotMonitoring;
