import { AnyAction, combineReducers } from '@reduxjs/toolkit';
import companyScanSlice from '../slices/companyScanSlice';

const appReducer = combineReducers({
  companyScan: companyScanSlice,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = (
  state: ReturnType<typeof appReducer> | undefined,
  action: AnyAction,
) => {
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
