import orange from './colors/orange';

export interface ColorSwatch {
  main: string;
  50: string;
  100: string;
  150?: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  750?: string;
  800: string;
  850?: string;
  900: string;
  ultraLight?: string;
}

export interface Swatch {
  primary: ColorSwatch;
  secondary: ColorSwatch;
}

const baseColors = {
  primary: orange.primary,
  secondary: orange.secondary,
  gray: {
    main: '#6E7382',
    50: '#E9EAEC',
    100: '#DBDDE1',
    200: '#C0C2C9',
    300: '#A4A8B2',
    400: '#888D9B',
    500: '#6E7382',
    600: '#575B66',
    700: '#3F424B',
    800: '#282A2F',
    900: '#101113',
    strokeAndBg: '#F0F1F3',
    ultraLight: '#F7F8FB',
    ultraLightBg: '#FBFBFB',
  },
  text: {
    gray: '#8990A3',
  },
  black: '#050505',
  white: '#FFFFFF',
  success: {
    main: '#2ECC71',
    dark: '#1B7943',
    light: '#D1F5E0',
  },
  warning: {
    main: '#FFB800',
    dark: '#A87900',
    light: orange.primary[50],
  },
  danger: {
    main: '#F74040',
    dark: '#980606',
    light: '#FDD3D3',
  },
  info: {
    main: '#0073DD',
    dark: '#003E77',
    light: '#CEE7FF',
  },
  prefilled: {
    borderColor: '#FFBC0F',
    backgroundColor: '#FFF9EA',
  },
};

export default baseColors;
