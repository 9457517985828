import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationHolderTypeCoTenant: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3748_10145"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3748_10145)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M178.082 95.781l-57.517 57.512v73.511h115.029v-73.511l-57.512-57.512z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M235.594 90.023a5.899 5.899 0 00-5.898-5.898h-9.748a5.9 5.9 0 00-5.898 5.898v11.668l21.565 21.313-.021-32.98z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M254.002 141.202l-65.812-65.05a14.382 14.382 0 00-20.22 0l-65.812 65.05a10.784 10.784 0 0015.249 15.249l60.676-60.67 60.669 60.67a10.786 10.786 0 007.625 3.159 10.786 10.786 0 0010.783-10.783c0-2.86-1.136-5.603-3.158-7.625z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeDasharray="12 4"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M235.592 226.804h-57.514l.003-131.023 57.511 57.512v73.511z"
        ></path>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          stroke={colors.primary[100]}
          strokeWidth="4"
          d="M151.49 173.148c2.628.738 4.337 1.758 6.202 3.7 1.607 1.674 2.402 3.055 3.03 5.269.213.751.224 1.119.26 8.973.037 8.134.036 8.194-.193 8.74-.426 1.019-1.27 1.583-2.366 1.583-.787 0-1.303-.224-1.841-.801-.708-.759-.685-.495-.685-8.135 0-3.812-.03-7.01-.067-7.107-.038-.103-.193-.177-.369-.177s-.33.074-.369.177c-.036.097-.066 3.248-.066 7.001v6.824l-.815 10.534c-.449 5.793-.863 10.829-.922 11.189-.249 1.54-1.398 2.684-3.003 2.992-.937.18-3.659.068-4.322-.178-.707-.262-1.632-1.154-1.98-1.909-.271-.591-.305-.933-1.147-11.708l-.867-11.099v-6.735c0-3.704-.03-6.814-.066-6.911-.093-.248-.556-.221-.692.039-.074.143-.113 2.572-.113 7.107 0 7.596.022 7.337-.685 8.096-.591.634-1.085.83-1.97.783-.658-.035-.848-.095-1.286-.406a3.331 3.331 0 01-.816-.894l-.302-.527-.033-7.551c-.022-4.938.008-7.917.085-8.608.513-4.588 4.151-8.692 8.979-10.13 1.439-.428 2-.494 3.808-.448 1.305.033 1.828.097 2.611.317z"
          clipRule="evenodd"
        ></path>
        <rect
          width="8.606"
          height="11.841"
          x="144.238"
          y="155.998"
          fill={colors.primary[100]}
          stroke={colors.primary[100]}
          strokeWidth="4"
          rx="4.303"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M210.49 173.148c2.628.738 4.337 1.758 6.202 3.7 1.607 1.674 2.402 3.055 3.03 5.269.213.751.224 1.119.26 8.973.037 8.134.036 8.194-.193 8.74-.426 1.019-1.27 1.583-2.366 1.583-.787 0-1.303-.224-1.841-.801-.708-.759-.685-.495-.685-8.135 0-3.812-.03-7.01-.067-7.107-.038-.103-.193-.177-.369-.177s-.33.074-.369.177c-.036.097-.066 3.248-.066 7.001v6.824l-.815 10.534c-.449 5.793-.863 10.829-.922 11.189-.249 1.54-1.398 2.684-3.003 2.992-.937.18-3.659.068-4.322-.178-.707-.262-1.632-1.154-1.98-1.909-.271-.591-.305-.933-1.147-11.708l-.867-11.099v-6.735c0-3.704-.03-6.814-.066-6.911-.093-.248-.556-.221-.692.039-.074.143-.113 2.572-.113 7.107 0 7.596.022 7.337-.685 8.096-.591.634-1.085.83-1.97.783-.658-.035-.848-.095-1.286-.406a3.331 3.331 0 01-.816-.894l-.302-.527-.033-7.551c-.022-4.938.008-7.917.085-8.608.513-4.588 4.151-8.692 8.979-10.13 1.439-.428 2-.494 3.808-.448 1.305.033 1.828.097 2.611.317z"
          clipRule="evenodd"
        ></path>
        <rect
          width="8.606"
          height="11.841"
          x="203.238"
          y="155.998"
          fill={colors.primary[500]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="4.303"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationHolderTypeCoTenant;
