import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationHolderTypeCoOwner: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_2695_10410"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_2695_10410)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M178.082 95.781l-57.517 57.512v73.511h115.029v-73.511l-57.512-57.512z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M235.594 90.023a5.899 5.899 0 00-5.898-5.898h-9.748a5.9 5.9 0 00-5.898 5.898v11.668l21.565 21.313-.021-32.98z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M254.002 141.202l-65.812-65.05a14.382 14.382 0 00-20.22 0l-65.812 65.05a10.784 10.784 0 0015.249 15.249l60.676-60.67 60.669 60.67a10.786 10.786 0 007.625 3.159 10.786 10.786 0 0010.783-10.783c0-2.86-1.136-5.603-3.158-7.625z"
        ></path>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          stroke={colors.primary[100]}
          strokeWidth="4"
          d="M158.529 157.087c3.822 1.045 6.308 2.487 9.02 5.235 2.339 2.368 3.494 4.322 4.407 7.453.31 1.062.326 1.583.378 12.694.055 11.506.053 11.592-.279 12.364-.62 1.441-1.847 2.24-3.441 2.24-1.146 0-1.897-.318-2.679-1.134-1.029-1.074-.997-.701-.997-11.508 0-5.392-.043-9.916-.096-10.054-.056-.145-.281-.25-.537-.25s-.481.105-.537.25c-.053.138-.096 4.594-.096 9.904v9.653l-1.185 14.902c-.653 8.196-1.256 15.319-1.341 15.829-.363 2.178-2.033 3.797-4.368 4.232-1.362.254-5.322.096-6.285-.252-1.029-.371-2.375-1.633-2.88-2.701-.395-.835-.444-1.32-1.669-16.562l-1.261-15.702v-9.526c0-5.24-.044-9.639-.096-9.777-.135-.351-.809-.313-1.007.056-.107.201-.163 3.637-.163 10.054 0 10.745.032 10.379-.997 11.452-.859.896-1.579 1.174-2.866 1.108-.957-.05-1.232-.135-1.87-.575-.414-.287-.944-.85-1.187-1.264l-.439-.746-.048-10.682c-.031-6.985.011-11.199.124-12.177.747-6.49 6.038-12.296 13.059-14.33 2.092-.606 2.91-.7 5.538-.634 1.899.047 2.659.137 3.798.448z"
          clipRule="evenodd"
        ></path>
        <rect
          width="14.335"
          height="18.409"
          x="147.061"
          y="131.998"
          fill={colors.primary[100]}
          stroke={colors.primary[100]}
          strokeWidth="4"
          rx="7.167"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M206.529 157.087c3.822 1.045 6.308 2.487 9.02 5.235 2.339 2.368 3.494 4.322 4.407 7.453.31 1.062.326 1.583.378 12.694.055 11.506.053 11.592-.279 12.364-.62 1.441-1.847 2.24-3.441 2.24-1.146 0-1.897-.318-2.679-1.134-1.029-1.074-.997-.701-.997-11.508 0-5.392-.043-9.916-.096-10.054-.056-.145-.281-.25-.537-.25s-.481.105-.537.25c-.053.138-.096 4.594-.096 9.904v9.653l-1.185 14.902c-.653 8.196-1.256 15.319-1.341 15.829-.363 2.178-2.033 3.797-4.368 4.232-1.362.254-5.322.096-6.285-.252-1.029-.371-2.375-1.633-2.88-2.701-.395-.835-.444-1.32-1.669-16.562l-1.261-15.702v-9.526c0-5.24-.044-9.639-.096-9.777-.135-.351-.809-.313-1.007.056-.107.201-.163 3.637-.163 10.054 0 10.745.032 10.379-.997 11.452-.859.896-1.579 1.174-2.866 1.108-.957-.05-1.232-.135-1.87-.575-.414-.287-.944-.85-1.187-1.264l-.439-.746-.048-10.682c-.031-6.985.011-11.199.124-12.177.747-6.49 6.038-12.296 13.059-14.33 2.092-.606 2.91-.7 5.538-.634 1.899.047 2.659.137 3.798.448z"
          clipRule="evenodd"
        ></path>
        <rect
          width="14.335"
          height="18.409"
          x="195.061"
          y="131.998"
          fill={colors.primary[500]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="7.167"
        ></rect>
        <g filter="url(#filter0_dd_2695_10410)">
          <circle cx="243.5" cy="102.498" r="42.5" fill="#fff"></circle>
          <circle
            cx="243.5"
            cy="102.498"
            r="40.5"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M221.872 112.951c0-8.876 8.005-20.9 16.801-25.203h9.166c8.8 4.309 16.802 16.333 16.802 25.209 0 10.847-9.58 16.042-21.391 16.042-11.811 0-21.378-5.201-21.378-16.048zM243.373 87.741s-1.94-2.093-4.004-4.232a207.27 207.27 0 00-4.329-4.362 1.531 1.531 0 01-.303-1.77 1.527 1.527 0 011.589-.835 49.311 49.311 0 0014.094 0 1.53 1.53 0 011.282 2.611 180.858 180.858 0 00-4.329 4.362c-2.06 2.133-4 4.226-4 4.226z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M246.738 84.176a16.44 16.44 0 011.935-1.46c2.678-1.24 3.896-.658 4.416.486.52 1.145.167 2.444-2.507 3.666a13.822 13.822 0 01-5.316.87h-1.895s2.785-2.952 3.367-3.562zM243.371 87.742c.608.535 4.904 4.505 4.553 9.198"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M240.006 84.176a16.496 16.496 0 00-1.934-1.46c-2.679-1.24-3.896-.658-4.416.486-.52 1.145-.167 2.444 2.505 3.666 1.699.636 3.505.932 5.317.87h1.895s-2.785-2.952-3.367-3.562zM243.373 87.742c-.609.535-4.903 4.505-4.552 9.198"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M248.688 118.202h-1.826a7.644 7.644 0 01-3.856-1.052 7.727 7.727 0 01-2.812-2.864h4.278c.375-.009.731-.165.992-.436a1.455 1.455 0 000-2.023 1.428 1.428 0 00-.992-.436h-5.234a7.088 7.088 0 010-1.897h7.624a1.44 1.44 0 001.357-.886 1.446 1.446 0 000-1.124 1.444 1.444 0 00-.797-.786 1.446 1.446 0 00-.56-.1h-6.668a7.722 7.722 0 012.812-2.862 7.634 7.634 0 013.856-1.052h1.826c.374-.009.73-.165.991-.436a1.455 1.455 0 000-2.023 1.427 1.427 0 00-.991-.436h-1.826a10.488 10.488 0 00-5.96 1.871 10.645 10.645 0 00-3.874 4.94h-2.425a1.409 1.409 0 00-.559.1 1.456 1.456 0 00-.478.311 1.46 1.46 0 000 2.073 1.426 1.426 0 001.037.411h1.751a10.6 10.6 0 00-.048.945c0 .317.02.636.048.949h-1.751a1.443 1.443 0 00-1.469 1.448 1.464 1.464 0 00.432 1.036 1.443 1.443 0 001.037.412h2.425a10.645 10.645 0 003.874 4.94 10.503 10.503 0 005.96 1.871h1.826c.374-.01.73-.166.991-.437.262-.27.408-.633.408-1.011 0-.378-.146-.741-.408-1.011a1.424 1.424 0 00-.991-.437v.002z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_2695_10410"
          width="117"
          height="117"
          x="185"
          y="51.998"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2695_10410"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_2695_10410"
            result="effect2_dropShadow_2695_10410"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2695_10410"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationHolderTypeCoOwner;
