import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsLegalHeavyMachinery: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M34.46 14.15v-1A4.099 4.099 0 0030.37 9h-7.16a4.1 4.1 0 00-4.09 4.12v1a2 2 0 00-2 2.06v2.05a2 2 0 002 2.06V24c.73.087 1.4.444 1.88 1 .236-.052.478-.079.72-.08a3.001 3.001 0 012.16.9 3.09 3.09 0 01.82 2.89 3.16 3.16 0 011.06 1.87h4.65a4.11 4.11 0 004.09-4.12v-6.14a2.05 2.05 0 002-2.06v-2.05a2.05 2.05 0 00-2.04-2.06zm-12.27 4v-4a1 1 0 011-1h1.64l-2.64 5zM25 19.29l3.27-6.17h1.52l-3.27 6.17H25zm6.44-1a1 1 0 01-1 1h-2.19l3-5.64a1 1 0 01.14.5l.05 4.14zM26.28 38.85h-.48l-.09-.37a8.28 8.28 0 00-2.16-3.83l-1.15-1.16-.51-.49-.51-.52c0-.1.09-.2.12-.3h1.2a1.03 1.03 0 100-2.06h-1.2c0-.1-.08-.2-.12-.3l1-1a1 1 0 000-1.45.999.999 0 00-1.44 0l-1 1-.3-.13V27a1 1 0 10-2 0v1.21l-.3.13-1-1a1.002 1.002 0 00-1.45 0 1 1 0 000 1.45l1 1-.12.3h-1.2a1.03 1.03 0 100 2.06h1.2l.12.3-1 1a1 1 0 000 1.46 1 1 0 001.45 0l1-1 .3.12v1.21a1 1 0 002 0V34c.104-.03.204-.07.3-.12l1 1 1.15 1.16a6.23 6.23 0 011.57 2.74h-.47a1 1 0 00-1 1V44h5.11v-4.12a1 1 0 00-1.02-1.03zm-7.67-6.69a1 1 0 110-2 1 1 0 010 2z"
    ></path>
  </Icon>
);

export default RiskObjectsLegalHeavyMachinery;
