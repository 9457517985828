const oceanGreen = {
  primary: {
    main: '#5CADA8',
    50: '#F5FAF9',
    100: '#E4F1F0',
    150: '#D3E9E7',
    200: '#C2E0DE',
    300: '#A0CFCC',
    400: '#7EBEBA',
    500: '#5CADA8',
    600: '#468B87',
    700: '#336663',
    750: '#2A5350',
    800: '#20403E',
    850: '#172E2C',
    900: '#0E1B1A',
  },
  secondary: {
    main: '#7ACAC4',
    50: '#EDF8F7',
    100: '#E0F3F1',
    150: '#D3EEEC',
    200: '#C7E9E6',
    300: '#ADDEDB',
    400: '#94D4CF',
    500: '#7ACAC4',
    600: '#4BB7AF',
    700: '#378983',
    750: '#2D716C',
    800: '#245955',
    850: '#1A423F',
    900: '#112A28',
  },
};

export default oceanGreen;
