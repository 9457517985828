import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationElectricBicycle: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2ZM214 113l-33 68-3.599-1.746 33-68L214 113Z"
          fill={colors.primary[500]}
        />
        <path
          d="M152 182c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110 220c20.987 0 38-17.013 38-38s-17.013-38-38-38-38 17.013-38 38 17.013 38 38 38Zm0 4c23.196 0 42-18.804 42-42s-18.804-42-42-42-42 18.804-42 42 18.804 42 42 42Z"
          fill={colors.primary[500]}
        />
        <path
          d="M145 182c0 19.33-15.67 35-35 35s-35-15.67-35-35 15.67-35 35-35 35 15.67 35 35Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110 213c17.121 0 31-13.879 31-31 0-17.121-13.879-31-31-31-17.12 0-31 13.879-31 31 0 17.121 13.88 31 31 31Zm0 4c19.33 0 35-15.67 35-35s-15.67-35-35-35-35 15.67-35 35 15.67 35 35 35Z"
          fill={colors.primary[500]}
        />
        <path
          d="M288 182c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M246 220c20.987 0 38-17.013 38-38s-17.013-38-38-38-38 17.013-38 38 17.013 38 38 38Zm0 4c23.196 0 42-18.804 42-42s-18.804-42-42-42-42 18.804-42 42 18.804 42 42 42Z"
          fill={colors.primary[500]}
        />
        <path
          d="M281 182c0 19.33-15.67 35-35 35s-35-15.67-35-35 15.67-35 35-35 35 15.67 35 35Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M246 213c17.121 0 31-13.879 31-31 0-17.121-13.879-31-31-31-17.121 0-31 13.879-31 31 0 17.121 13.879 31 31 31Zm0 4c19.33 0 35-15.67 35-35s-15.67-35-35-35-35 15.67-35 35 15.67 35 35 35ZM118 113a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2c0 12.14-4.491 27.802-10.091 41.268-2.811 6.76-5.931 13.036-8.965 18.117-2.997 5.017-6.026 9.07-8.705 11.185a2 2 0 0 1-2.478-3.14c2.071-1.635 4.792-5.145 7.749-10.096 2.919-4.888 5.955-10.987 8.706-17.602 5.206-12.519 9.289-26.639 9.742-37.732H120a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m135 122.5 43 62.5h73l-43-62.5h-73Zm7.607 4 37.496 54.5h63.29l-37.496-54.5h-63.29Z"
          fill={colors.primary[500]}
        />
        <path
          d="M190 182c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M178 190a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0 4c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12ZM149.637 24.888a2 2 0 0 1 2.68.906l5.225 10.567 4.344-4.41a2.001 2.001 0 0 1 3.196.474l6.447 12.273a2 2 0 1 1-3.541 1.86l-5.174-9.848-4.394 4.46a2 2 0 0 1-3.218-.517l-6.471-13.086a2 2 0 0 1 .906-2.679ZM196.436 4.11a2 2 0 0 1 1.235 2.545l-3.861 11.139H200a2 2 0 0 1 1.905 2.608l-4.219 13.207a2 2 0 1 1-3.81-1.218l3.386-10.597H191a1.999 1.999 0 0 1-1.89-2.655l4.782-13.794a2 2 0 0 1 2.544-1.235ZM266.353 54.143a2 2 0 0 1-2.362 1.557l-11.547-2.371.809 6.136a1.998 1.998 0 0 1-2.337 2.23l-13.645-2.455a2 2 0 0 1 .709-3.937l10.95 1.97-.819-6.207a1.998 1.998 0 0 1 2.385-2.22l14.3 2.935a2 2 0 0 1 1.557 2.362ZM249.492 91.478a2 2 0 0 1-2.807.346l-9.294-7.252-2 5.858a2 2 0 0 1-3.084.96l-11.136-8.259a2 2 0 0 1 2.383-3.212l8.936 6.627 2.023-5.926a1.999 1.999 0 0 1 3.123-.93l11.509 8.98a2 2 0 0 1 .347 2.808ZM190 113a2 2 0 0 1 2-2h28a2 2 0 1 1 0 4h-28a2 2 0 0 1-2-2ZM185.573 71.264a2 2 0 0 1-.337 2.809l-14 11a1.96 1.96 0 0 1-.201.139c-3.324 2.01-7.137 5.775-8.224 10.465-1.034 4.458.258 10.412 8.473 17.29a2 2 0 1 1-2.568 3.067c-8.985-7.523-11.276-14.902-9.802-21.26 1.408-6.07 6.12-10.578 9.944-12.92l13.906-10.927a2 2 0 0 1 2.809.337Z"
          fill={colors.primary[500]}
        />
        <path
          d="M215.131 39.842a2 2 0 0 1-.442 2.794l-12.719 9.241a2 2 0 0 1-2.352-3.236l12.719-9.241a2 2 0 0 1 2.794.442ZM225.131 52.842a2 2 0 0 1-.443 2.794l-12.719 9.241a2 2 0 1 1-2.351-3.236l12.719-9.241a2 2 0 0 1 2.794.442Z"
          fill={colors.primary[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M204.797 78.441c-.001.001-.002.003-.001.004v.005a16.5 16.5 0 0 1-19.401-26.695c.002-.001.002-.003.001-.005-.001-.001-.001-.003.001-.004l8.719-6.335a2 2 0 0 1 2.793.443l17.046 23.461a2 2 0 0 1-.443 2.794l-8.715 6.332Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M192.94 43.794a4 4 0 0 1 5.587.884l17.046 23.462a4 4 0 0 1-.885 5.587l-8.592 6.243c-.04.034-.082.067-.125.098a18.5 18.5 0 0 1-21.882-29.835c.042-.037.086-.072.132-.105l8.719-6.334Zm-6.244 9.48c-.04.034-.081.067-.124.098a14.497 14.497 0 0 0-3.203 20.25 14.499 14.499 0 0 0 20.135 3.293 2.37 2.37 0 0 1 .117-.092l8.716-6.332-17.046-23.461-8.595 6.244Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationElectricBicycle;
