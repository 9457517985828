import React from 'react';
import { Button, HStack, IconButton, Icon, Text } from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';

interface Props {
  label?: string;
  setIsEditing: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  isEditing: boolean;
  onRemove: () => void;
  onCancel: () => void;
  onSave: () => void;
}

const AnnexInputCardHeader: React.FC<Props> = ({
  label,
  setIsEditing,
  isEditing,
  onRemove,
  onCancel,
}) => {
  const { t } = useTranslation();
  return (
    <HStack p="4" borderBottom="1px solid" borderColor="gray.50">
      <Text flexGrow="1" textStyle="normalBodyMedium">
        {label || t('Add a new annex')}
      </Text>
      {isEditing ? (
        <Button size="sm" variant="grayedGhost" onClick={onCancel}>
          {t('CANCEL')}
        </Button>
      ) : (
        <>
          <IconButton
            aria-label="edit"
            icon={<Icon name="GeneralActionsEditDocument" boxSize="5" />}
            size="sm"
            variant="white"
            onClick={setIsEditing.on}
          />
          <IconButton
            aria-label="edit"
            icon={<Icon name="GeneralActionsThrash" boxSize="5" />}
            size="sm"
            variant="dangerSecondary"
            onClick={onRemove}
          />
        </>
      )}
    </HStack>
  );
};

export default AnnexInputCardHeader;
