import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceCyber: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M299.72 56.632a7.997 7.997 0 0 0-8-8H56.192a8 8 0 0 0-8 8v151.684H299.72V56.632Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M321.912 226.071H26v-13.756a4 4 0 0 1 4-4h287.912a4 4 0 0 1 4 4v13.756Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M282 70a3.997 3.997 0 0 0-4-4H70a4 4 0 0 0-4 4v137.916h216V70Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m174 75 45.321 15.81A6.98 6.98 0 0 1 224 97.397v48.534c0 33.72-50 52.324-50 52.324s-50-18.604-50-52.324V97.397a6.979 6.979 0 0 1 4.679-6.587L174 75Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="m174 75 45.321 15.81A6.98 6.98 0 0 1 224 97.397v48.534c0 33.72-50 52.324-50 52.324s-50-18.604-50-52.324V97.397a6.979 6.979 0 0 1 4.679-6.587L174 75Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M140.278 134.304a33.731 33.731 0 0 0 9.877 23.845 33.742 33.742 0 0 0 10.94 7.31 33.739 33.739 0 0 0 25.81 0 33.732 33.732 0 0 0 18.25-18.251 33.712 33.712 0 0 0 0-25.809 33.732 33.732 0 0 0-18.25-18.25 33.721 33.721 0 0 0-46.627 31.155Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M140.278 134.304a33.731 33.731 0 0 0 9.877 23.845 33.742 33.742 0 0 0 10.94 7.31 33.739 33.739 0 0 0 25.81 0 33.732 33.732 0 0 0 18.25-18.251 33.712 33.712 0 0 0 0-25.809 33.732 33.732 0 0 0-18.25-18.25 33.721 33.721 0 0 0-46.627 31.155v0Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M158.886 128.489h30.232v27.906h-30.232v-27.906Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M186.791 122.676a12.788 12.788 0 0 0-3.747-9.045 12.8 12.8 0 0 0-4.149-2.773 12.81 12.81 0 0 0-9.79 0 12.793 12.793 0 0 0-7.896 11.818v5.813h6.977v-5.813a5.81 5.81 0 0 1 5.814-5.814 5.812 5.812 0 0 1 5.814 5.814v5.813h6.977v-5.813Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M158.886 128.489h30.232v27.906h-30.232v-27.906Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M177.49 141.28a3.489 3.489 0 1 0-5.826 2.582v3.232a2.327 2.327 0 1 0 4.653 0v-3.232a3.466 3.466 0 0 0 1.173-2.582Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationInsuranceCyber;
