import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsLegalAlone: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25.08 16a3 3 0 001.23-5.73 1.5 1.5 0 10-2.79-.77c.005.291.091.576.25.82A3 3 0 0025.08 16zM34.32 26.09l-4.86-8.42a.67.67 0 00-.27-.26 1 1 0 00-.79-.41h-.33a5 5 0 01-6 0h-.44a1.001 1.001 0 00-.77.37.79.79 0 00-.33.3l-4.86 8.42a1.501 1.501 0 102.59 1.5l2.88-5 .32 2.94c.03.29 0 .583-.09.86L19 33.68a1 1 0 00.91 1.32h1.61v6.5a1.5 1.5 0 103 0V35h1v6.5a1.5 1.5 0 103 0V35h1.61a1 1 0 00.95-1.32l-2.42-7.26a2.09 2.09 0 01-.09-.86l.32-2.87 2.84 4.9a1.5 1.5 0 102.59-1.5z"
    ></path>
  </Icon>
);

export default RiskObjectsLegalAlone;
