import React, { useEffect, useState } from 'react';
import { QueryClientProvider } from 'react-query';
import { WgOAuth } from './features/authentication/classes/WgAuthState';
import {
  isSocialLoginUrl,
  isStorybookUrl,
} from './features/authentication/constants/SocialAuth';
import MainRouter from './features/routing/MainRouter';
import MainProvider from './providers/global-providers/MainProvider';
import { queryClient } from './providers/queryClient';
import { getFeatureFlagBeforeInit } from './utils/getFeatureFlag';
import * as Sentry from '@sentry/react';

const App: React.FC = () => {
  const [isFetchingAuthData, setIsFetchingAuthData] = useState<boolean>(true);

  /**
   * Checks if data can be send to countries outside EU
   */
  const checkForeignDataExchange = async () => {
    const isForeignDataTrackingDisabled = await getFeatureFlagBeforeInit(
      'isForeignDataTrackingDisabled',
    );
    if (isForeignDataTrackingDisabled) {
      Sentry.close();
    }
  };

  // Top level await is not supported so have to manually trigger
  // the OAuth object initialization.
  const initAuth = async () => {
    if (!isSocialLoginUrl && !isStorybookUrl) {
      await WgOAuth!.init();
      await checkForeignDataExchange();
    }
    setIsFetchingAuthData(false);
  };

  useEffect(() => {
    if (!WgOAuth?.authorizationServer) initAuth();
  }, []);

  if (isFetchingAuthData) return null;

  return (
    <QueryClientProvider client={queryClient}>
      <MainProvider>
        <MainRouter />
      </MainProvider>
    </QueryClientProvider>
  );
};

export default App;
