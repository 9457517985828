import * as Sentry from '@sentry/react';
import { Replay } from '@sentry/replay';
import { ENVIRONMENT, SENTRY_DSN } from '../../../../env.config';
import { BrowserTracing } from '@sentry/tracing';
import manifest from '../../../../../public/manifest.json';

/**
 * Sentry is used for:
 * - Tracking errors
 * - Recording user sessions
 */
const initSentry = () => {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: manifest?.version,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      new BrowserTracing(),
      new Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
        // See below for all available options
      }),
    ],
  });
};

export default initSentry;
