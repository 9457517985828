import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationPublicTransport: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M93 214.5a9.5 9.5 0 0 1-9.5 9.5 9.5 9.5 0 0 1-9.5-9.5 9.5 9.5 0 0 1 9.5-9.5 9.5 9.5 0 0 1 9.5 9.5Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.5 220a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11Zm0 4a9.5 9.5 0 0 0 9.5-9.5 9.5 9.5 0 0 0-9.5-9.5 9.5 9.5 0 0 0-9.5 9.5 9.5 9.5 0 0 0 9.5 9.5Z"
          fill={colors.primary[500]}
        />
        <path
          d="M253 214.5a9.5 9.5 0 0 1-9.5 9.5 9.5 9.5 0 0 1-9.5-9.5 9.5 9.5 0 0 1 9.5-9.5 9.5 9.5 0 0 1 9.5 9.5Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.5 220a5.5 5.5 0 1 0 0-11 5.5 5.5 0 1 0 0 11Zm0 4a9.5 9.5 0 0 0 9.5-9.5 9.5 9.5 0 0 0-9.5-9.5 9.5 9.5 0 0 0-9.5 9.5 9.5 9.5 0 0 0 9.5 9.5Z"
          fill={colors.primary[500]}
        />
        <path
          d="M121 214.5a9.5 9.5 0 0 1-9.5 9.5 9.5 9.5 0 0 1-9.5-9.5 9.5 9.5 0 0 1 9.5-9.5 9.5 9.5 0 0 1 9.5 9.5Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.5 220a5.5 5.5 0 1 0 0-11 5.5 5.5 0 1 0 0 11Zm0 4a9.5 9.5 0 0 0 9.5-9.5 9.5 9.5 0 0 0-9.5-9.5 9.5 9.5 0 0 0-9.5 9.5 9.5 9.5 0 0 0 9.5 9.5Z"
          fill={colors.primary[500]}
        />
        <path
          d="M281 214.5a9.5 9.5 0 0 1-9.5 9.5 9.5 9.5 0 0 1-9.5-9.5 9.5 9.5 0 0 1 9.5-9.5 9.5 9.5 0 0 1 9.5 9.5Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M271.5 220a5.5 5.5 0 1 0 0-11 5.5 5.5 0 1 0 0 11Zm0 4a9.5 9.5 0 0 0 9.5-9.5 9.5 9.5 0 0 0-9.5-9.5 9.5 9.5 0 0 0-9.5 9.5 9.5 9.5 0 0 0 9.5 9.5ZM134.086 93.586a2 2 0 0 1 2.828 0l27 27a2 2 0 0 1 0 2.828l-28.5 28.5a2 2 0 1 1-2.828-2.828L159.672 122l-25.586-25.586a2 2 0 0 1 0-2.828Z"
          fill={colors.primary[500]}
        />
        <path
          d="M111 149a6 6 0 0 1 6-6h39a6 6 0 0 1 6 6v5a6 6 0 0 1-6 6h-39a6 6 0 0 1-6-6v-5Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M156 147h-39a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h39a2 2 0 0 0 2-2v-5a2 2 0 0 0-2-2Zm-39-4a6 6 0 0 0-6 6v5a6 6 0 0 0 6 6h39a6 6 0 0 0 6-6v-5a6 6 0 0 0-6-6h-39Z"
          fill={colors.primary[500]}
        />
        <path
          d="M37.5 200v11c.333 1.833 2.1 5.5 6.5 5.5h267.5c7.5 0 6.5-5.5 6.5-8v-12c0-5.5-16.5-36-18.5-40.5s-5-5-7.5-5H61.5c-3 0-5 3.5-6.5 7 0 0-17.5 37-17.5 42Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M59.242 154.446c-.883 1.09-1.67 2.63-2.404 4.342l-.03.067L55 158l1.808.855-.195.414a789.601 789.601 0 0 0-2.532 5.43 920.896 920.896 0 0 0-6.002 13.236c-2.184 4.929-4.358 9.992-5.983 14.125-.814 2.069-1.48 3.879-1.94 5.311-.492 1.533-.656 2.366-.656 2.629v10.798a5.457 5.457 0 0 0 1.21 2.3c.699.775 1.72 1.402 3.29 1.402h267.5c1.644 0 2.612-.303 3.18-.631a2.298 2.298 0 0 0 1.03-1.186c.214-.525.309-1.173.33-1.932.01-.373.002-.745-.011-1.124l-.007-.228a25.357 25.357 0 0 1-.022-.899v-12c0-.331-.156-1.135-.611-2.504-.43-1.296-1.059-2.9-1.838-4.728-1.557-3.651-3.662-8.081-5.845-12.509-2.169-4.4-4.438-8.843-6.287-12.463-1.826-3.575-3.242-6.348-3.747-7.484-.851-1.915-1.818-2.786-2.663-3.228-.886-.464-1.875-.584-3.009-.584H61.5c-.635 0-1.373.352-2.258 1.446Zm-6.066 2.732-.182.387-.557 1.186a977.433 977.433 0 0 0-1.987 4.269 917.395 917.395 0 0 0-6.029 13.295c-2.191 4.946-4.392 10.07-6.048 14.281-.827 2.103-1.528 4.004-2.025 5.552-.465 1.448-.848 2.865-.848 3.852v11c0 .12.01.24.032.358.223 1.227.89 2.958 2.208 4.419 1.368 1.517 3.43 2.723 6.26 2.723h267.5c2.106 0 3.825-.384 5.18-1.166a6.303 6.303 0 0 0 2.735-3.142c.473-1.163.597-2.358.623-3.333.014-.495.002-.963-.011-1.363a64.595 64.595 0 0 0-.009-.283c-.01-.283-.018-.508-.018-.713v-12c0-1.044-.36-2.397-.815-3.766-.479-1.442-1.155-3.159-1.954-5.034-1.6-3.754-3.745-8.262-5.937-12.709-2.276-4.618-4.58-9.126-6.418-12.72-1.745-3.414-3.069-6.005-3.548-7.083-1.149-2.585-2.682-4.214-4.462-5.147-1.739-.911-3.5-1.041-4.866-1.041H61.5c-2.365 0-4.127 1.398-5.367 2.929-1.235 1.525-2.193 3.47-2.957 5.249Z"
          fill={colors.primary[500]}
        />
        <path
          d="M82 166a6 6 0 0 1 6-6h60a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6H88a6 6 0 0 1-6-6v-24Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M148 164H88a2 2 0 0 0-2 2v24a2 2 0 0 0 2 2h60a2 2 0 0 0 2-2v-24a2 2 0 0 0-2-2Zm-60-4a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h60a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6H88Z"
          fill={colors.primary[500]}
        />
        <path
          d="M202 166a6 6 0 0 1 6-6h60a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6h-60a6 6 0 0 1-6-6v-24Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M268 164h-60a2 2 0 0 0-2 2v24a2 2 0 0 0 2 2h60a2 2 0 0 0 2-2v-24a2 2 0 0 0-2-2Zm-60-4a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h60a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6h-60Z"
          fill={colors.primary[500]}
        />
        <path
          d="M162 166a6 6 0 0 1 6-6h20a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6h-20a6 6 0 0 1-6-6v-24Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188 164h-20a2 2 0 0 0-2 2v24a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2v-24a2 2 0 0 0-2-2Zm-20-4a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h20a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6h-20Z"
          fill={colors.primary[500]}
        />
        <path
          d="M60.678 164.232a7 7 0 0 1 6.43-4.232H68a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6H56.116c-4.312 0-7.216-4.412-5.511-8.373l10.073-23.395Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68 164h-.893a3 3 0 0 0-2.755 1.814l-10.073 23.395A2 2 0 0 0 56.116 192H68a2 2 0 0 0 2-2v-24a2 2 0 0 0-2-2Zm-.893-4a7 7 0 0 0-6.429 4.232l-10.073 23.395c-1.705 3.961 1.2 8.373 5.51 8.373H68a6 6 0 0 0 6-6v-24a6 6 0 0 0-6-6h-.893Z"
          fill={colors.primary[500]}
        />
        <path
          d="M295.322 164.232a7 7 0 0 0-6.429-4.232H288a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h11.884c4.312 0 7.216-4.412 5.511-8.373l-10.073-23.395Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M288 164h.893a3 3 0 0 1 2.755 1.814l10.073 23.395a2 2 0 0 1-1.837 2.791H288a2 2 0 0 1-2-2v-24a2 2 0 0 1 2-2Zm.893-4a7 7 0 0 1 6.429 4.232l10.073 23.395c1.705 3.961-1.199 8.373-5.511 8.373H288a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h.893ZM339.41 71.407a2 2 0 0 1-1.317 2.503C286.399 89.97 218.198 99 180 99c-38.251 0-77.984-8.058-160.901-25.04a2 2 0 0 1 .802-3.92C102.984 87.059 142.25 95 180 95c37.801 0 105.601-8.97 156.906-24.91a2 2 0 0 1 2.504 1.317Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationPublicTransport;
