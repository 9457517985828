import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleGlassBreakage: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M45.46 18.3h-2.7a1.08 1.08 0 00-1.08 1.07v1.08a4.31 4.31 0 01-4.31 4.32v-4.32a7.55 7.55 0 00-7.55-7.55h-8.64A16.19 16.19 0 005 29.09v1.07a3.23 3.23 0 003.24 3.24h11l-1.32-4.52L22 26.42a2 2 0 001-2.14l-.05-.21L24 25.14 22.77 27a2 2 0 00.23 2.55l3.9 3.85h10.47a4.31 4.31 0 014.31 4.32v2.15A1.078 1.078 0 0042.76 41h2.7a.53.53 0 00.54-.54V18.84a.53.53 0 00-.54-.54zM34.13 30.16h-6.25L25.71 28 27 26.14a2 2 0 00-.25-2.55l-4.89-4.83a1.65 1.65 0 00-1.91-.28 1.6 1.6 0 00-.82 1.73l.82 4-4.24 2.49a2 2 0 00-.92 2.3l.33 1.16H8.24v-1.07a13 13 0 0112.94-12.95h8.64a4.31 4.31 0 014.31 4.31v9.71z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleGlassBreakage;
