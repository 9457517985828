import React, { PropsWithChildren } from 'react';
import { Icon, Center, Box, HStack, Text } from '@wegroup/design-system';
import { Row } from 'react-table';

interface Props {
  row: Row<Record<string, unknown>>;
  isLast: boolean;
}

const ExpandableRowCell: React.FC<PropsWithChildren & Props> = ({
  row,
  isLast,
  children,
}) => {
  // When the row has subRows
  if (row.canExpand) {
    return (
      <HStack {...row.getToggleRowExpandedProps()}>
        <HStack w="8" color="black">
          <Text textStyle="smallBodyMedium">{row.subRows.length}</Text>
          <Center
            as="span"
            transition="0.2s"
            transform={row.isExpanded ? `rotate(90deg)` : 'rotate(0)'}
          >
            <Icon boxSize="5" name={'GeneralActionsCaretRight'} />
          </Center>
        </HStack>
        <Box>{children}</Box>
      </HStack>
    );
  }
  // is subRow
  return (
    <HStack pl={`${(row.depth - 1) * 16}px`}>
      <Box h="100%" w="2.5" pos="absolute" top="0">
        {!isLast && (
          <Box
            borderLeft="1px"
            borderColor="gray.100"
            h="100%"
            w="100%"
            pos="absolute"
            top="0"
            left="5px"
          />
        )}
        <Box
          pt="2"
          borderLeft="1px"
          borderBottom="1px"
          borderColor="gray.100"
          w="100%"
          h="24px"
          borderBottomLeftRadius="16px"
          pos="absolute"
          top="0"
          left="5px"
        />
      </Box>
      <Box pl="8">
        {children}
        {!isLast && (
          <Box
            position="absolute"
            h="1px"
            borderBottom="1px solid"
            borderColor="gray.50"
            w="100%"
            bottom="0"
            zIndex="1"
          />
        )}
      </Box>
    </HStack>
  );
};

export default ExpandableRowCell;
