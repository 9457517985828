import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import useTracking from './useTracking';
import useAuth from '../../../../app/src/features/authentication/hooks/useAuth';

const useInitTracking = (): void => {
  const { user, distribution, isAuthorized } = useAuth();
  const { trackEvent } = useTracking();

  useEffect(() => {
    if (isAuthorized) {
      const {
        email,
        name,
        created_at,
        language,
        id,
        distribution_name,
        assistant_name,
        is_admin,
        plan,
        phone,
      } = user!;

      if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
        window.gtag('config', 'G-0CN8KWVPCJ', {
          user_id: id,
          send_page_view: false,
        });
      }

      mixpanel.init(process.env.MIXPANEL_PROJECT_TOKEN || '');
      mixpanel.identify(id);

      mixpanel.people.set({
        $name: name,
        $email: email,
        $created: new Date(created_at || ''),
        'Language setting': language,
        'Company name': distribution_name,
        'Broker assistant name': assistant_name,
        'Broker role': is_admin ? 'admin' : 'user',
        'Broker plan': plan,
        'Broker phone': phone,
        'Broker bms': distribution?.crm,
      });

      trackEvent('Log in');
    }
  }, [isAuthorized]);
};

export default useInitTracking;
