import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import useAuth from '../features/authentication/hooks/useAuth';

const usePosthogInit = () => {
  const {
    distribution,
    ssoData: { info },
  } = useAuth();
  const posthog = usePostHog();

  const filteredBids = [
    // E2E staging bid
    'a4df0a39-dec1-4b97-a8b6-8624461fabb5',
    '5eb3fb28-7432-4c2e-ac66-0fc5be7e8fec',
    // E2E prod bid
    'dd3ce910-04a4-4c68-a569-8cbb8677a6f9',
  ];
  const filteredOrigin = ['random'];

  const optOutByUser =
    filteredBids.includes(info?.bid || '') ||
    filteredOrigin.includes(info?.origin || '');

  const optIn = () => {
    if (!optOutByUser) {
      posthog?.opt_in_capturing();
    } else {
      posthog?.opt_out_capturing();
    }
  };

  /**
   * Once app loading is done we identify the user in posthog
   */
  useEffect(() => {
    if (info) {
      posthog?.identify(info.uid, {
        email: info?.email,
        username: info?.username,
      });
      posthog?.group('company', info.did);
      optIn();
    } else if (distribution) {
      posthog?.identify(distribution.id, {
        username: distribution.name,
        email: distribution.email,
      });
      posthog?.group('company', distribution.id);
      optIn();
    }
  }, [
    posthog,
    info?.email,
    info?.uid,
    info?.username,
    distribution?.id,
    distribution?.name,
    distribution?.email,
  ]);
};

export default usePosthogInit;
