import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersCurrentLocationOrGPS: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M42.06 7.94a1.5 1.5 0 01.296 1.702l-15.158 32a1.5 1.5 0 01-2.811-.278l-3.15-12.6-12.6-3.15a1.5 1.5 0 01-.28-2.812l32-15.158a1.5 1.5 0 011.704.295zM13.43 23.72l9.407 2.351a1.5 1.5 0 011.092 1.091l2.352 9.408 11.565-24.416L13.43 23.719z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersCurrentLocationOrGPS;
