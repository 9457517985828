import { MenuItem } from '@chakra-ui/react';
import React from 'react';
import SearchPickerOption from '../SearchPickerList/SearchPickerOption';
import { AvatarPickerOption } from './index';

interface Props {
  items: AvatarPickerOption[];
  selectedOption?: AvatarPickerOption;
  onClick: (options: AvatarPickerOption) => void;
}

const AvatarPickerMenuItems: React.FC<Props> = ({
  items,
  selectedOption,
  onClick,
}) => {
  return (
    <>
      {items.map((option, index) => (
        <MenuItem
          as={SearchPickerOption}
          key={index}
          option={option}
          onClick={() => onClick(option)}
          selected={option.value === selectedOption?.value}
          hideIcon
        />
      ))}
    </>
  );
};

export default AvatarPickerMenuItems;
