import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyPrivateHomeOffice: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M42.47 17.36l-4-2.64V8.24a1.12 1.12 0 00-1.12-1.12h-5.6a1.12 1.12 0 00-1.12 1.12v1.25l-5-3.29a1.18 1.18 0 00-1.32 0L7.53 17.36a1.19 1.19 0 00-.53 1V42.8A1.2 1.2 0 008.2 44h33.6a1.2 1.2 0 001.2-1.2V18.35a1.19 1.19 0 00-.53-.99zm-9.91-4.08zm7.19 27.48h-29.5V19.52L25 9.83l4.12 2.73 2.33 1.53a1.57 1.57 0 002.43-1.31v-2.43h1.37v5.44A1.52 1.52 0 0036 17.1l3.79 2.43-.04 21.23z"
    ></path>
    <path
      fill="currentColor"
      d="M37 26.36v-1a.28.28 0 00-.12-.22h-4.13c0-.05-.08-5.11-7.71-5.11s-7.71 5.06-7.71 5.11h-4.21a.28.28 0 00-.12.22V38.48a.5.5 0 00.5.51h23a.5.5 0 00.5-.51V37.2 26.45a.289.289 0 000-.09zm-8.3 8.43a1 1 0 01-1 1h-5a1 1 0 01-1-1v-6.16a1 1 0 011-1h5a1 1 0 011 1v6.16zm-9.37-9.68c0-.23.27-3.07 5.71-3.07 5.44 0 5.7 2.84 5.71 3.07H19.33z"
    ></path>
    <path
      fill="currentColor"
      d="M26.7 30.36a1.49 1.49 0 01-1 1.41v2.59a.5.5 0 11-1 0v-2.59a1.47 1.47 0 01-1-1.41 1.5 1.5 0 113 0z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyPrivateHomeOffice;
