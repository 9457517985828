import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersIframe: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.056 9.017a2 2 0 10-4-.034l-.026 3.046-3.047.027a2 2 0 10.034 4l2.978-.027-.146 16.91v.018a5.194 5.194 0 005.194 5.194h17.108V41a2 2 0 004 0v-2.848h2.85a2 2 0 100-4h-2.85V17.042a5.194 5.194 0 00-5.194-5.194h-.017l-16.91.146.026-2.977zm-.06 6.978l-.147 16.969a1.194 1.194 0 001.194 1.187h17.108V17.042a1.194 1.194 0 00-1.187-1.194l-16.968.147z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersIframe;
