import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationDog: React.FC<React.PropsWithChildren<IllustrationProps>> = ({
  isActive = true,
  ...otherProps
}) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M155.489 69.42a21.196 21.196 0 0 1-39.066 16.411c-3.346-7.991 0-30.05 1.963-40.98a4.224 4.224 0 0 1 2.519-3.18 4.216 4.216 0 0 1 4.035.415c9.139 6.256 27.203 19.35 30.549 27.334ZM201.177 69.42a21.186 21.186 0 0 0-.077 16.224 21.199 21.199 0 0 0 39.17.187c3.346-7.992 0-30.051-1.963-40.98a4.214 4.214 0 0 0-2.516-3.176 4.22 4.22 0 0 0-4.031.41c-9.166 6.257-27.237 19.35-30.583 27.334Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M178.335 186.865c-42.39 0-92.137-113.028 0-113.028s42.384 113.028 0 113.028Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M189.7 175.472c0 6.291-11.385 11.393-11.385 11.393s-11.393-5.102-11.393-11.393a11.39 11.39 0 0 1 19.345-7.873 11.389 11.389 0 0 1 3.433 7.873Z"
          fill={colors.primary[500]}
        />
        <path
          d="M218.666 155.846c18.623-31.993 19.965-77.73-32.644-81.767a55.659 55.659 0 0 0 32.644 81.767Z"
          fill={colors.primary[100]}
        />
        <path
          d="M146.169 112.688a10.599 10.599 0 1 0 21.198 0 10.599 10.599 0 0 0-21.198 0ZM189.3 112.688a10.595 10.595 0 0 0 10.597 10.598 10.595 10.595 0 0 0 10.598-10.598 10.594 10.594 0 0 0-10.598-10.597 10.594 10.594 0 0 0-10.597 10.597Z"
          fill={colors.primary[500]}
        />
        <path
          d="M178.335 186.865c-42.39 0-92.137-113.028 0-113.028s42.384 113.028 0 113.028Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationDog;
