import { useEffect, useState } from 'react';
import { LOCAL_STORAGE_OAUTH_EVENT_NAME } from '../constants/Jwt';
import { getTokens } from '../utils/jwt';
import {
  isAuthenticatingViaLoginToken,
  isReturningFromSSO,
  isReturningFromSSOError,
} from '../utils/sso';

/**
 * Hook which tracks the state of the access token expiration.
 * This is triggered in the OAuth.ts file.
 */
const useIsAuthorized = () => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(getIsAuthorized());

  const setAuthorizedState = () => {
    const authorizationValue = getIsAuthorized();
    setIsAuthorized(authorizationValue);
  };

  useEffect(() => {
    window.addEventListener(LOCAL_STORAGE_OAUTH_EVENT_NAME, setAuthorizedState);

    return () => {
      window.removeEventListener(
        LOCAL_STORAGE_OAUTH_EVENT_NAME,
        setAuthorizedState,
      );
    };
  }, []);

  return isAuthorized;
};

export const getIsAuthorized = (): boolean => {
  // By checking these, we make sure that the access token currently
  // stored is a valid one. If app might be in the process of authenticating,
  // it could give a false value of the authenticatoin state as the user might
  // already have older tokens still stored in the storage. Because the
  // isAuthorized state triggers a few API calls, it can cause the user to logout
  // again and come stuck in a login/logout loop.

  if (
    !isReturningFromSSO() &&
    !isReturningFromSSOError() &&
    !isAuthenticatingViaLoginToken()
  ) {
    return !!getTokens()?.accessToken;
  }

  return false;
};

export default useIsAuthorized;
