import React, { PropsWithChildren } from 'react';
import { Center, HStack, Text, Tooltip } from '@wegroup/design-system';
import { Link } from 'react-router-dom';

interface Props {
  position: number;
  active?: boolean;
  passed?: boolean;
  to?: string;
  tooltip?: string;
  onClick?: () => void;
}

const ProgressBarStep: React.FC<PropsWithChildren<Props>> = ({
  children,
  active,
  passed,
  position,
  tooltip,
  to,
  onClick = () => null,
}) => {
  const stepBoxStyle = {
    bg: active ? 'primary.100' : passed ? 'green.100' : 'gray.50',
    color: active ? 'primary.600' : passed ? 'green.500' : 'gray.500',
  };

  const handleClick = () => {
    onClick();
  };

  return (
    <HStack
      as={to ? Link : undefined}
      to={to ? to : '/#'}
      spacing="2"
      onClick={handleClick}
    >
      <Tooltip shouldWrapChildren label={tooltip}>
        <Center
          borderRadius="sm"
          w="5"
          h="5"
          transition="0.2s"
          {...stepBoxStyle}
        >
          <Text textStyle="smallBody">{position}</Text>
        </Center>
      </Tooltip>
      {children && (
        <Text
          textStyle="smallBody"
          color={active ? 'primary.500' : 'gray.500'}
          transition="0.2s"
        >
          {children}
        </Text>
      )}
    </HStack>
  );
};

export default ProgressBarStep;
