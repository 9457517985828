import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationWater: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3805_10445"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3805_10445)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <rect
          width="20.471"
          height="19.772"
          x="94"
          y="52.188"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <rect
          width="20.471"
          height="19.772"
          x="175.106"
          y="52.188"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <rect
          width="82"
          height="45.642"
          x="103.789"
          y="41.699"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M138.676 74.61l-9.619 12.026h32.162l-10.156-12.158c-3.243-3.882-9.228-3.818-12.387.132z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M219.95 99.83v0c-11.971 0-21.675 9.698-21.675 21.668v34.267c0 15.382-14.683 46.496-46.496 46.496-51.74 0-51.74-51.74-51.74-51.74v0c0-23.362 18.939-42.301 42.301-42.301h2.797"
        ></path>
        <circle
          cx="145.137"
          cy="108.22"
          r="8.488"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <rect
          width="21.171"
          height="9.984"
          x="216.356"
          y="93.439"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <rect
          width="14.878"
          height="24.667"
          x="231.738"
          y="88.545"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M216.356 130.944c0-12.604 10.218-22.821 22.821-22.821 12.604 0 22.821 10.217 22.821 22.821v74.35c0 5.523-4.477 10-10 10h-25.642c-5.523 0-10-4.477-10-10v-74.35z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M216.356 181.537h45.642v23.756c0 5.523-4.477 10-10 10h-25.642c-5.523 0-10-4.477-10-10v-23.756z"
        ></path>
        <circle
          cx="231.84"
          cy="63.47"
          r="4.293"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="247.222"
          cy="41.097"
          r="6.39"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
      </g>
    </svg>
  );
};

export default IllustrationWater;
