import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

// Types
import { Company } from '../../../../../../types/ProductFactory';
import { Guarantee, Tags } from '../../../../../../types/Quotes';
import { loadState, persistState } from '../reducers/statePersistence';

export interface SelectionData {
  [x: string]: {
    type: string;
    items?: unknown[];
  } | null;
}

export interface SelectionType {
  type: string;
  riskObject?: string;
}

export interface SelectionController {
  /************************
   * Possible selections
   ************************/
  riskObjects: string[];
  guarantees: {
    [x: string]: Guarantee[];
  };
  companies: Company[];

  /************************
   * Actual selections
   ************************/
  selectedRiskObjects: string[];
  selectedCompanies: string[];
  selectedGuarantees: { [x: string]: string[] };

  /************************
   * JSON data (DefaultSelection.json)
   ************************/
  selectionData: SelectionData;
  selectionTypes: SelectionType[];

  /************************
   * Extra
   ************************/
  prefilledSessionId?: string;
  partyId?: string;
}
interface GuaranteesAction {
  items: string | string[];
  riskObject: string;
}

const pushTo = (array: string[], data: string | string[]) => {
  if (!array) array = [];
  if (Array.isArray(data)) array.push(...data);
  else array.push(data);
  return uniq(array);
};

const popFrom = (array: string[], data: string | string[]) => {
  if (!array) array = [];
  if (Array.isArray(data)) return array.filter((name) => !data.includes(name));
  return array.filter((name) => name !== data);
};

/**
 * Builds an array of views that should be shown to the user based on
 * the data contained in the selection JSON.
 * @param data Data from the JSON
 * @returns Returns what views should be shown
 */
const buildSelectionTypes = (data: SelectionData) => {
  const selectionViews = Object.entries(data).map(([key]) => ({
    type: key,
  }));
  return [...selectionViews];
};

export const persistSelectionState = createAsyncThunk(
  'selection/persistSelectionState',
  (sessionId: string, { getState }) => {
    return persistState('selection', sessionId, getState);
  },
);

/**
 * Load the current question state from the indexedDB
 */
export const loadSelectionState = createAsyncThunk(
  'selection/loadSelectionState',
  (sessionId: string) => {
    return loadState('selection', sessionId);
  },
);

const initialState: SelectionController = {
  riskObjects: [],
  selectedRiskObjects: [],
  selectedCompanies: [],
  selectedGuarantees: {},
  selectionData: {},
  selectionTypes: [],
  guarantees: {},
  companies: [],
};
const selectionController = createSlice({
  name: 'selection',
  initialState: initialState,
  reducers: {
    setSelectionData(state, action: PayloadAction<SelectionData>) {
      state.selectionData = action.payload;

      state.riskObjects =
        (action.payload.riskObjects?.items as { name: string }[])?.map(
          ({ name }) => name,
        ) || [];

      if (!state.selectionTypes || state.selectionTypes.length === 0)
        state.selectionTypes = buildSelectionTypes(action.payload);
    },
    setCompanies(state, action: PayloadAction<Company[]>) {
      state.companies = uniq(action.payload);
    },
    setGuarantees(state, action: PayloadAction<Tags>) {
      state.guarantees = action.payload;

      Object.entries(state.guarantees).forEach(([riskObject, value]) => {
        state.selectedGuarantees[riskObject] = uniq([
          ...(state.selectedGuarantees[riskObject] || []),
          ...value.map(({ tag }) => tag),
        ]);
      });
    },
    pushToSelectedRiskObjects(state, action: PayloadAction<string | string[]>) {
      // Convert to lowercase
      const riskObjects = Array.isArray(action.payload)
        ? action.payload.map((riskObj) => riskObj?.toLowerCase())
        : action.payload?.toLowerCase();

      state.selectedRiskObjects = pushTo(
        state.selectedRiskObjects,
        riskObjects,
      );

      if (Array.isArray(riskObjects)) {
        riskObjects.forEach((riskObject) =>
          state.selectionTypes.push({
            type: 'guarantees',
            riskObject: riskObject,
          }),
        );
      } else {
        state.selectionTypes.push({
          type: 'guarantees',
          riskObject: riskObjects,
        });
      }

      state.selectedRiskObjects = uniq(state.selectedRiskObjects);
    },
    popFromSelectedRiskObjects(
      state,
      action: PayloadAction<string | string[]>,
    ) {
      state.selectedRiskObjects = popFrom(
        state.selectedRiskObjects,
        action.payload,
      );

      state.selectionTypes = state.selectionTypes.filter(({ riskObject }) =>
        Array.isArray(action.payload)
          ? !action.payload.includes(riskObject || '')
          : riskObject !== action.payload,
      );
    },
    pushToSelectedCompanies(state, action: PayloadAction<string | string[]>) {
      state.selectedCompanies = pushTo(state.selectedCompanies, action.payload);
    },
    popFromSelectedCompanies(state, action: PayloadAction<string | string[]>) {
      state.selectedCompanies = popFrom(
        state.selectedCompanies,
        action.payload,
      );
    },
    pushToSelectedGuarantees(state, action: PayloadAction<GuaranteesAction>) {
      state.selectedGuarantees[action.payload.riskObject] = pushTo(
        state.selectedGuarantees[action.payload.riskObject],
        action.payload.items,
      );
    },
    popFromSelectedGuarantees(state, action: PayloadAction<GuaranteesAction>) {
      state.selectedGuarantees[action.payload.riskObject] = popFrom(
        state.selectedGuarantees[action.payload.riskObject],
        action.payload.items,
      );
    },
    setPrefilledSessionId(state, action: PayloadAction<string>) {
      state.prefilledSessionId = action.payload;
    },
    setParty(state, action: PayloadAction<string>) {
      state.partyId = action.payload;
    },
    resetSelection() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSelectionState.fulfilled, (state, action) => {
      Object.entries(action?.payload as SelectionController).forEach(
        ([key, data]) => (state[key] = data),
      );
    });
  },
});

export const {
  setSelectionData,
  setGuarantees,
  setCompanies,
  setPrefilledSessionId,
  setParty,
  pushToSelectedRiskObjects,
  popFromSelectedRiskObjects,
  pushToSelectedCompanies,
  popFromSelectedCompanies,
  pushToSelectedGuarantees,
  popFromSelectedGuarantees,
  resetSelection,
} = selectionController.actions;

export default selectionController.reducer;
