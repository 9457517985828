import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationTravelsAYearMore: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_2628_9919"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_2628_9919)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M86 148.332h114.663v43H86v-43z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M125.814 98.165h71.666v50.165h-71.666V98.165z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M213.405 207.256a15.927 15.927 0 1031.854 0 15.927 15.927 0 00-31.854 0zM117.852 210.442a12.738 12.738 0 0012.74 12.74 12.74 12.74 0 10-12.74-12.74z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M213.405 204.073h-95.554a6.366 6.366 0 01-6.37-6.373 6.372 6.372 0 016.37-6.37h95.554v12.743zM200.665 191.33l15.923-130.59h12.743l-15.926 130.59h-12.74z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M86 148.332h114.663v43H86v-43zM170.713 83.832H152.58a5.265 5.265 0 00-5.266 5.268v9.065h28.667V89.1a5.268 5.268 0 00-5.268-5.268v0zM86 169.831h114.666M178.785 123.249h-34.278a4.361 4.361 0 01-4.361-4.361V98.165h42.99v20.723a4.36 4.36 0 01-4.351 4.361v0z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M111.481 164.256c0-1.266.504-2.482 1.4-3.377a4.774 4.774 0 016.755 0 4.774 4.774 0 011.399 3.377v12.741a4.776 4.776 0 01-9.554 0v-12.741zM165.625 164.256a4.774 4.774 0 014.777-4.777 4.778 4.778 0 014.777 4.777v12.741a4.776 4.776 0 01-9.554 0v-12.741z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M152.091 118.073h19.11v9.557h-19.11v-9.557z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M232.511 207.269H194.87a3.181 3.181 0 01-2.431-1.127 3.187 3.187 0 01-.712-2.583l5.31-31.862a3.182 3.182 0 013.14-2.661h18.278a3.186 3.186 0 012.252.932l14.061 14.068a3.18 3.18 0 01.934 2.25v17.787a3.198 3.198 0 01-1.968 2.954c-.388.16-.803.242-1.223.242z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M245.256 63.926a15.927 15.927 0 01-15.924 15.927 16.173 16.173 0 01-15.927-15.93A15.927 15.927 0 01229.332 48h35.041a6.372 6.372 0 016.372 6.37v3.186a6.37 6.37 0 01-6.372 6.37h-19.117z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationTravelsAYearMore;
