import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';

const IllustrationBoatBA: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="119"
      height="56"
      fill="none"
      viewBox="0 0 119 56"
    >
      <g
        strokeLinecap="round"
        strokeLinejoin="round"
        clipPath="url(#clip0_5362_57621)"
      >
        <path
          stroke="#365E7B"
          strokeWidth="5"
          d="M28.25 40.95l6.625-11H52.612a15 15 0 005.588-1.087l13.35-5.325a15.002 15.002 0 015.588-1.088H100.5l-8.625 18.475M40.5 29.95l5.625-18.75M40.5 11.2h30L78 22.45"
        ></path>
        <path
          fill="#B7CEDF"
          stroke="#fff"
          strokeWidth="3"
          d="M28.084 46a3.902 3.902 0 00-.168 0 5.6 5.6 0 01-4.666-2.332 4 4 0 00-6.5 4.665A13.6 13.6 0 0028 54a13.598 13.598 0 0011.25-5.668 5.6 5.6 0 014.666-2.334h.168a5.6 5.6 0 014.667 2.334A13.6 13.6 0 0060 54a13.6 13.6 0 0011.249-5.668 5.6 5.6 0 014.666-2.334h.168a5.6 5.6 0 014.667 2.334A13.6 13.6 0 0092 54a13.598 13.598 0 0011.249-5.668 4 4 0 00-6.5-4.665A5.599 5.599 0 0192.085 46a3.902 3.902 0 00-.168 0 5.6 5.6 0 01-4.666-2.333A13.599 13.599 0 0076 37.999a13.6 13.6 0 00-11.25 5.669A5.599 5.599 0 0160.085 46a3.902 3.902 0 00-.168 0 5.6 5.6 0 01-4.666-2.333A13.6 13.6 0 0044 37.999a13.6 13.6 0 00-11.25 5.669A5.599 5.599 0 0128.085 46zm0 0l-.032 1.5.032-1.5z"
        ></path>
      </g>
      <rect width="2" height="8" x="90" y="1" fill="#F7A82A" rx="1"></rect>
      <rect
        width="2"
        height="8"
        x="87"
        y="6"
        fill="#F7A82A"
        rx="1"
        transform="rotate(-90 87 6)"
      ></rect>
      <rect
        width="1.28"
        height="5.12"
        x="33.073"
        y="1.592"
        fill="#F7A82A"
        rx="0.64"
        transform="rotate(56 33.073 1.592)"
      ></rect>
      <rect
        width="1.28"
        height="5.12"
        x="29.347"
        y="1.789"
        fill="#F7A82A"
        rx="0.64"
        transform="rotate(-34 29.347 1.79)"
      ></rect>
      <circle
        cx="115.093"
        cy="21.093"
        r="2.275"
        fill="#F7A82A"
        transform="rotate(61 115.093 21.093)"
      ></circle>
      <circle
        cx="12.179"
        cy="30.179"
        r="1.7"
        fill="#F7A82A"
        transform="rotate(-160 12.18 30.179)"
      ></circle>
      <circle
        cx="109.498"
        cy="39.498"
        r="2.475"
        fill="#F7A82A"
        transform="rotate(43 109.498 39.498)"
      ></circle>
      <rect
        width="2.1"
        height="8.4"
        x="8.683"
        y="41.133"
        fill="#F7A82A"
        rx="1.05"
        transform="rotate(84 8.683 41.133)"
      ></rect>
      <rect
        width="2.1"
        height="8.4"
        x="3.132"
        y="38.549"
        fill="#F7A82A"
        rx="1.05"
        transform="rotate(-6 3.132 38.549)"
      ></rect>
      <circle
        cx="26.783"
        cy="19.783"
        r="2.675"
        fill="#F7A82A"
        transform="rotate(-135 26.783 19.783)"
      ></circle>
      <defs>
        <clipPath id="clip0_5362_57621">
          <path
            fill="#fff"
            d="M0 0H92V47H0z"
            transform="translate(14 9)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationBoatBA;
