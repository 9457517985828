import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMountainBike: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M57.115 206.513 24.36 227.986c-1.64 1.076-.914 3.624 1.047 3.672l295.651 7.306a2 2 0 0 0 1.931-1.321l32.046-88.965a2 2 0 0 0-1.881-2.678h-24.743a20 20 0 0 0-10.873 3.214l-35.007 22.675a20.006 20.006 0 0 1-8.808 3.107l-46.051 4.779a20 20 0 0 1-4.341-.024l-38.425-4.402a19.996 19.996 0 0 0-13.561 3.357l-20.813 14.222a20.014 20.014 0 0 1-6.603 2.932l-46.112 11.101a20.004 20.004 0 0 1-8.668.154l-17.082-3.474a20 20 0 0 0-14.951 2.872Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m196.8 72.709-19.046 73.145-3.871-1.008 19.046-73.145 3.871 1.008Z"
          fill={colors.primary[500]}
        />
        <path
          d="M147.549 152.904c4.326 21.664-9.729 42.733-31.393 47.059-21.663 4.325-42.732-9.73-47.058-31.394-4.326-21.663 9.73-42.732 31.393-47.058 21.664-4.326 42.733 9.73 47.058 31.393Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M136.762 155.059c3.137 15.706-7.054 30.98-22.76 34.117-15.706 3.136-30.98-7.054-34.117-22.76-3.136-15.707 7.054-30.981 22.76-34.118 15.706-3.136 30.981 7.054 34.117 22.761Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M280.917 126.275c4.326 21.663-9.73 42.732-31.393 47.058-21.664 4.325-42.733-9.73-47.058-31.394-4.326-21.663 9.729-42.732 31.393-47.058 21.664-4.325 42.732 9.73 47.058 31.394Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M270.13 128.429c3.136 15.706-7.054 30.981-22.76 34.117-15.707 3.136-30.981-7.054-34.117-22.76-3.137-15.707 7.053-30.981 22.76-34.117 15.706-3.137 30.981 7.053 34.117 22.76Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="m109.795 160.443 12.324-70.925a1 1 0 0 0-1.181-1.152l-16.221 3.24"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121.977 97.336c-1.579.316-2.157 2.273-1.002 3.396l50.924 49.486a1.998 1.998 0 0 0 1.785.527l66.826-13.343c1.579-.315 2.157-2.273 1.002-3.396L190.588 84.52a2 2 0 0 0-1.785-.527l-66.826 13.343Zm8.243 2.433c-1.579.316-2.157 2.273-1.002 3.396l43.96 42.719c.472.459 1.14.656 1.785.527l57.303-11.442c1.58-.315 2.158-2.273 1.003-3.396l-43.96-42.719a2 2 0 0 0-1.786-.527L130.22 99.77Z"
          fill={colors.primary[500]}
        />
        <path
          d="M186.775 145.072c1.298 6.499-2.919 12.82-9.418 14.118-6.499 1.297-12.819-2.919-14.117-9.418-1.298-6.5 2.919-12.82 9.418-14.118 6.499-1.298 12.82 2.919 14.117 9.418Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M176.574 155.267a8 8 0 1 0-3.133-15.691 8 8 0 0 0 3.133 15.691Zm.783 3.923c6.499-1.298 10.716-7.619 9.418-14.118-1.297-6.499-7.618-10.716-14.117-9.418-6.499 1.298-10.716 7.618-9.418 14.118 1.298 6.499 7.618 10.715 14.117 9.418ZM173.264 77.408a2 2 0 0 1 1.57-2.353l27.458-5.483a2 2 0 0 1 .783 3.923l-27.458 5.483a2 2 0 0 1-2.353-1.57ZM20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationMountainBike;
