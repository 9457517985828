import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Flex } from '@wegroup/design-system';

// Components

import { IconActionPhone, IconActionMessage } from 'wg-fe-ui';
import { mq } from '../../../../../../../common/src/utils/styledComponentsUtils';

interface Props {
  logo: string;
  name: string;
  url: string;
  email: string;
  phone: string;
}

const BrokerInfoHeader: React.FC<React.PropsWithChildren<Props>> = ({
  logo,
  name,
  url,
  email,
  phone,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Wrapper data-test-id="ProdFac_BrokerInfoHeader">
      <LogoWrapper data-test-id="ProdFac_BrokerInfoHeader_Logo">
        <Logo src={logo} alt={name + ' logo'} />
      </LogoWrapper>
      <TextWrapper>
        <Label data-test-id="ProdFac_BrokerInfoHeader_Name">{name}</Label>
        <Link
          href={url}
          target="_blank"
          data-test-id="ProdFac_BrokerInfoHeader_Link"
        >
          {url}
        </Link>
      </TextWrapper>
      <Flex>
        <Action
          href={`mailto:${email}`}
          data-test-id="ProdFac_BrokerInfoHeader_Email"
        >
          <IconActionMessage color={theme.primary[500]} />
        </Action>
        <Action
          href={`tel:${phone}`}
          data-test-id="ProdFac_BrokerInfoHeader_Phone"
        >
          <IconActionPhone color={theme.primary[500]} />
        </Action>
      </Flex>
    </Wrapper>
  );
};

const Logo = styled.img`
  display: block;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
`;

const LogoWrapper = styled.div`
  display: none;
  ${mq.tabletS} {
    display: flex;
    align-items: center;
    margin-right: 1.6rem;
    min-width: 5rem;
    max-width: 10rem;
    height: 5rem;
  }
`;

const Action = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: ${({ theme }) => theme.primary[100]};
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 1.7rem;
  margin-right: 0.8rem;
  margin-top: 1.6rem;
  &:last-child {
    margin-right: 0;
  }

  ${mq.mobileM} {
    margin-top: 0;
  }
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 120%;
`;

const Link = styled.a`
  text-decoration: underline;
  font-size: 1.4rem;
  line-height: 120%;
  color: #8990a3;
`;

const TextWrapper = styled.div`
  margin-right: 1.6rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const Wrapper = styled.div`
  display: flex;
  background: #f7f8fb;
  border-radius: 6px;
  padding: 1.6rem;
  flex-direction: column;

  ${mq.mobileM} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export default BrokerInfoHeader;
