import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersOriginOrConnection: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      d="M12.105 36.193a4.972 4.972 0 004.975-4.975c0-1.113-.393-2.16-.982-3.01l5.957-7.528c.523.262 1.112.524 1.767.59v10.668c-2.16.59-3.731 2.488-3.731 4.844a4.972 4.972 0 004.974 4.974 4.972 4.972 0 004.975-4.974c0-2.291-1.57-4.255-3.73-4.844V21.335a7.476 7.476 0 001.7-.59l6.023 7.528c-.59.85-.982 1.832-.982 2.945a4.972 4.972 0 004.974 4.975A4.972 4.972 0 0043 31.218a4.972 4.972 0 00-4.974-4.974c-.72 0-1.44.13-2.095.458l-5.956-7.527a6.444 6.444 0 001.636-4.32A6.593 6.593 0 0025 8.244a6.593 6.593 0 00-6.61 6.61c0 1.637.588 3.142 1.57 4.32l-5.956 7.528c-.59-.262-1.31-.393-2.03-.393A4.972 4.972 0 007 31.284c.13 2.683 2.356 4.909 5.105 4.909zm25.92-7.397a2.486 2.486 0 012.488 2.488 2.486 2.486 0 01-2.487 2.487 2.486 2.486 0 01-2.488-2.487c.066-1.375 1.178-2.488 2.488-2.488zm-10.472 7.986a2.486 2.486 0 01-2.488 2.487 2.486 2.486 0 01-2.487-2.487c0-1.375 1.113-2.422 2.422-2.487h.131c1.309.065 2.422 1.178 2.422 2.487zM25.065 10.73c2.226 0 4.059 1.833 4.059 4.058 0 2.226-1.833 4.058-4.058 4.058-2.226 0-4.059-1.832-4.059-4.058 0-2.225 1.833-4.058 4.058-4.058zm-12.96 18.065a2.486 2.486 0 012.488 2.488 2.486 2.486 0 01-2.488 2.487 2.486 2.486 0 01-2.487-2.487 2.486 2.486 0 012.487-2.488z"
    ></path>
  </Icon>
);

export default GeneralOthersOriginOrConnection;
