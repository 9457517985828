import { HStack, ScaleFade, IconButton, Icon } from '@wegroup/design-system';
import React from 'react';

interface OptionalPropsA {
  onView?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onRemove: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface OptionalPropsB {
  onView: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onRemove?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

interface DefaultProps {
  isHovered?: boolean;
}

type Props = (OptionalPropsA | OptionalPropsB) & DefaultProps;

const TreeListItemActions: React.FC<Props> = ({
  onView,
  onRemove,
  isHovered,
}) => {
  return (
    <ScaleFade in={isHovered} unmountOnExit>
      <HStack spacing="2">
        {onView && (
          <IconButton
            onClick={onView}
            variant="ghost"
            size="xs"
            icon={<Icon name="GeneralActionsView" boxSize={5} />}
            aria-label="Remove data item"
          />
        )}
        {onRemove && (
          <IconButton
            onClick={onRemove}
            variant="dangerGhost"
            size="xs"
            icon={<Icon name="GeneralActionsThrash" boxSize={5} />}
            aria-label="Remove data item"
          />
        )}
      </HStack>
    </ScaleFade>
  );
};

export default TreeListItemActions;
