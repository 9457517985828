import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationResidenceVilla: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M245.876 80.914h-9.928V69.979A2.981 2.981 0 0 1 238.926 67h3.971a2.977 2.977 0 0 1 2.979 2.979v10.935Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M249.227 149.808h-16.681v-66.53a2.981 2.981 0 0 1 2.979-2.978h10.723a2.977 2.977 0 0 1 2.979 2.978v66.53Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x={190.638}
          y={150.106}
          width={89.362}
          height={55.851}
          rx={4}
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M190.638 205.213H280v22.34h-89.362v-22.34Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M218.644 205.412h22.242v22.242h-22.242v-22.242Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M229.767 205.412h11.122v22.242h-11.122v-22.242ZM218.644 205.412h11.123v22.242h-11.123v-22.242Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M238.107 170.236h-88.968v-66.723h86.883a2.086 2.086 0 0 1 2.085 2.085v64.638Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M232.34 194.292h-41.496v-24.056h44.475v21.078a2.98 2.98 0 0 1-2.979 2.978Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M138.016 138.686H67.817a2.085 2.085 0 0 0-2.085 2.085v50.739h72.284v-52.824Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="m195.574 177.156-43.031-71.716a3.971 3.971 0 0 0-3.404-1.93 3.97 3.97 0 0 0-3.405 1.93l-46.64 77.73h8.34v44.484h83.404V183.17h1.325a3.974 3.974 0 0 0 3.977-4.02 3.972 3.972 0 0 0-.566-1.994Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M160.262 202.633h16.681v25.021h-16.681v-25.021Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M143.578 155.369H154.7v19.463h-11.122v-19.463Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M132.458 191.511h58.386v11.122h-58.386v-11.122ZM195.574 177.156l-43.031-71.716a3.971 3.971 0 0 0-3.404-1.93 3.97 3.97 0 0 0-3.405 1.93l-46.64 77.73h11.119l38.926-63.944 37.764 62.037a3.967 3.967 0 0 0 3.393 1.907h1.873a3.97 3.97 0 0 0 3.405-6.014Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m138.016 191.51-36.14-52.824-36.144 52.824h5.561v36.144h61.165V191.51h5.558Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M101.872 216.531h-8.14a2.98 2.98 0 0 1-2.98-2.978V200.05a2.977 2.977 0 0 1 2.98-2.979h8.14v19.46ZM110.016 216.531h-8.143v-19.46h8.14a2.98 2.98 0 0 1 2.979 2.979v13.503a2.98 2.98 0 0 1-2.976 2.978Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.096 223.652v-16.156"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m57.818 139.598.003-.009.002-.008a.29.29 0 0 1 .277-.209.286.286 0 0 1 .277.209l.002.008.003.008c2.2 7.461 5.67 19.415 8.587 30.349 1.46 5.469 2.776 10.667 3.727 14.912.97 4.33 1.504 7.448 1.504 8.894a14.095 14.095 0 0 1-8.704 13.026 14.093 14.093 0 0 1-15.366-3.056 14.096 14.096 0 0 1-4.13-9.97c0-1.446.535-4.564 1.504-8.894.95-4.246 2.266-9.443 3.725-14.912 2.918-10.934 6.387-22.888 8.59-30.348Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M302.345 225.024v-12.55"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M295.889 183.42c2.174-8.151 4.744-17.031 6.459-22.855 1.714 5.824 4.284 14.705 6.459 22.855 1.133 4.246 2.154 8.276 2.89 11.566.756 3.374 1.158 5.746 1.158 6.811a10.509 10.509 0 0 1-17.936 7.43 10.505 10.505 0 0 1-2.278-3.409l-1.848.765 1.848-.765a10.509 10.509 0 0 1-.8-4.021c0-1.065.402-3.437 1.158-6.811.736-3.29 1.757-7.32 2.89-11.566Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationResidenceVilla;
