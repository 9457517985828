import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMonowheel: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_700_2257"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_700_2257)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <circle
          cx="178.5"
          cy="166.5"
          r="57.5"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="178.5"
          cy="166.5"
          r="47.5"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <rect
          width="46"
          height="8"
          x="155"
          y="191"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="4"
        ></rect>
        <mask id="path-6-inside-1_700_2257" fill="#fff">
          <path
            fillRule="evenodd"
            d="M242.305 181a64.99 64.99 0 00.695-9.5c0-35.623-28.878-64.5-64.5-64.5S114 135.877 114 171.5c0 3.227.237 6.399.695 9.5h127.61z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          d="M242.305 181a64.99 64.99 0 00.695-9.5c0-35.623-28.878-64.5-64.5-64.5S114 135.877 114 171.5c0 3.227.237 6.399.695 9.5h127.61z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M242.305 181v4h3.453l.504-3.416-3.957-.584zm-127.61 0l-3.957.584.504 3.416h3.453v-4zM239 171.5a61.04 61.04 0 01-.652 8.915l7.914 1.169c.487-3.293.738-6.661.738-10.084h-8zM178.5 111c33.413 0 60.5 27.086 60.5 60.5h8c0-37.832-30.668-68.5-68.5-68.5v8zM118 171.5c0-33.414 27.087-60.5 60.5-60.5v-8c-37.832 0-68.5 30.668-68.5 68.5h8zm.652 8.915A61.04 61.04 0 01118 171.5h-8c0 3.423.251 6.791.738 10.084l7.914-1.169zM114.695 185h127.61v-8h-127.61v8z"
          mask="url(#path-6-inside-1_700_2257)"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationMonowheel;
