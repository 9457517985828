import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersBriefcase: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11 18a2 2 0 00-2 2v18a2 2 0 002 2h28a2 2 0 002-2V20a2 2 0 00-2-2H11zm-6 2a6 6 0 016-6h28a6 6 0 016 6v18a6 6 0 01-6 6H11a6 6 0 01-6-6V20z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21 10a2 2 0 00-2 2v4a2 2 0 11-4 0v-4a6 6 0 016-6h8a6 6 0 016 6v4a2 2 0 11-4 0v-4a2 2 0 00-2-2h-8zM25 24a2 2 0 012 2v.02a2 2 0 11-4 0V26a2 2 0 012-2z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.214 27.1a2 2 0 012.686-.886 38 38 0 0034.2 0 2 2 0 111.8 3.572 42 42 0 01-37.8 0 2 2 0 01-.886-2.686z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersBriefcase;
