import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsMachineBreakdown: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 43a2 2 0 012-2h12a2 2 0 110 4H13a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.765 5.152A2 2 0 0121 7v10h22a2 2 0 110 4H21v22a2 2 0 01-4 0V21H7a2 2 0 01-1.414-3.414l12-12a2 2 0 012.18-.434zM17 17V11.83L11.828 17H17z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.285 5.971a2 2 0 012.744-.686l20 12a2 2 0 11-2.058 3.43l-20-12a2 2 0 01-.686-2.744z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35 17a2 2 0 012 2v6.343a5.998 5.998 0 013.885 6.827A6 6 0 0129 31a2 2 0 114 0 2 2 0 102-2 2 2 0 01-2-2v-8a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsMachineBreakdown;
