import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyRiotProtest: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M14.876 43.126c-.354 1.15.31 2.343 1.459 2.697.22.044.398.088.619.088.928 0 1.812-.618 2.078-1.547l1.9-6.278-3.536-3.271-2.52 8.311zM34.859 11.118l-2.918 2.034h-3.625a.651.651 0 00-.663.663v2.608c0 .354.31.663.663.663h.796l.31 1.813c-.355.133-.664.398-.885.796l-1.37 2.431-1.371-.884a5.428 5.428 0 00-2.697-.884h-7.472l-.574-4.377c-.089-.575-.398-1.017-.885-1.326v-3.581h3.847a.887.887 0 00.884-.884V4.884A.887.887 0 0018.015 4H8.598a.887.887 0 00-.884.884v5.306c0 .486.398.884.884.884h3.846v3.58a1.857 1.857 0 00-.928 1.813l.751 5.925c.133.884.885 1.591 1.813 1.591h3.581l-.575 6.19c-.044.663.177 1.326.708 1.812l6.145 5.703.884 6.41C24.956 45.205 25.884 46 26.99 46h.31c1.194-.177 2.034-1.238 1.857-2.432l-.973-7.162c-.088-.486-.31-.972-.663-1.282l-4.244-4.023c0-.044.044-.088.044-.177l1.15-6.278 2.343 1.548c.31.177.619.31.972.31.133 0 .31 0 .442-.045a1.915 1.915 0 001.15-.884l2.299-4.112c.442-.796.22-1.768-.486-2.343l-.354-2.078h1.105l2.918 2.034a.64.64 0 001.017-.53v-6.898c0-.53-.62-.84-1.017-.53zM37.954 13.594c-.177-.31-.575-.354-.84-.133l-.044.044a.618.618 0 00-.133.663c.177.266.265.575.265.929 0 .354-.088.663-.265.928-.133.221-.089.487.133.663l.044.045c.265.22.663.177.84-.133a2.94 2.94 0 00.398-1.503 2.94 2.94 0 00-.398-1.503z"
    ></path>
    <path
      fill="currentColor"
      d="M39.413 12.267c-.177-.265-.575-.31-.84-.088l-.045.044c-.22.177-.265.486-.088.752.442.619.707 1.37.707 2.166 0 .796-.265 1.547-.707 2.166-.177.221-.133.53.088.752l.045.044c.265.221.619.177.84-.088.574-.796.884-1.813.884-2.874a5.145 5.145 0 00-.884-2.874z"
    ></path>
    <path
      fill="currentColor"
      d="M40.827 10.941c-.176-.265-.574-.31-.84-.088v.044c-.22.177-.22.53-.044.751.752.973 1.15 2.167 1.15 3.493s-.443 2.52-1.15 3.493c-.177.22-.133.574.044.751l.045.044c.22.222.619.177.84-.088a6.867 6.867 0 001.414-4.2c-.044-1.592-.574-3.05-1.459-4.2zM22.126 19.164a3.802 3.802 0 100-7.604 3.802 3.802 0 000 7.604z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyRiotProtest;
