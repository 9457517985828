import React from 'react';
import { Calendar as MantineCalendar, CalendarBaseProps } from '@mantine/dates';

import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import 'dayjs/locale/fr';

import { useLocale } from '../../../../../../i18n';
import useCalendarStyling from '../../../hooks/useCalendarStyling';

export interface DatePickerProps {
  amountOfMonths?: number;
  value: Date | null;
  onChange: (date: Date | null) => void;
  locale?: string;
  maxDate?: Date;
  minDate?: Date;
  closeOnChange?: boolean;
  onClose?: () => void;
}

const Calendar: React.FC<
  React.PropsWithChildren<
    DatePickerProps & Omit<CalendarBaseProps, 'value' | 'onChange'>
  >
> = ({
  amountOfMonths = 1,
  value,
  onChange,
  locale,
  maxDate,
  minDate,
  closeOnChange = true,
  onClose,
  ...otherProps
}) => {
  const { userLang } = useLocale();
  const calendarStyling = useCalendarStyling();

  const handleCalendarValue = (val: Date | null): Date => {
    if (val instanceof Date) return val as Date;
    return new Date();
  };

  const handleCalendarChange = (date: Date | null) => {
    onChange(date);
    if (closeOnChange) onClose?.();
  };

  return (
    <MantineCalendar
      maxDate={maxDate}
      minDate={minDate}
      amountOfMonths={amountOfMonths}
      value={handleCalendarValue(value)}
      onChange={handleCalendarChange}
      initialMonth={handleCalendarValue(value)}
      locale={locale || (userLang as string)}
      focusable={false}
      preventFocus
      {...calendarStyling}
      {...otherProps}
    />
  );
};

export default Calendar;
