const Switch = {
  defaultProps: {
    colorScheme: 'primary',
  },
  baseStyle: {
    track: {
      bg: 'gray.100',
      _checked: {
        bg: 'primary.500'
      },
    }
  },
  sizes: {
    xs: {
      container: {
        '--switch-track-width': 'var(--chakra-space-8)',
        '--switch-track-height': 'var(--chakra-space-4)',
      },
    },
    sm: {
      container: {
        '--switch-track-width': 'var(--chakra-space-10)',
        '--switch-track-height': 'var(--chakra-space-5)',
      },
    },
    md: {
      container: {
        '--switch-track-width': 'var(--chakra-space-12)',
        '--switch-track-height': 'var(--chakra-space-6)',
      },
    },
    lg: {
      container: {
        '--switch-track-width': 'var(--chakra-space-14)',
        '--switch-track-height': 'var(--chakra-space-7)',
      },
    },
  },
};

export default Switch;
