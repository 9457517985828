import React from 'react';
import { isEmail, ReactMultiEmail } from 'react-multi-email';

import { Box, HStack, Icon, Text, useTheme } from '@wegroup/design-system';

interface Props {
  value?: string[];
  onChange?: (emails: string[]) => void;
}

const MultiEmailInput: React.FC<Props> = ({ value, onChange }) => {
  const { colors, space, radii, textStyles } = useTheme();

  return (
    <Box
      sx={{
        '> .react-multi-email': {
          '> input': {
            ...textStyles['normalBody'],
            width: '100%',
            cursor: 'text',
            border: `1px solid ${colors.gray[100]}`,
            borderRadius: radii['md'],
            height: space['10'],
            padding: space['4'],
            outline: 'none',
            ':focus': {
              borderColor: colors.primary.main,
            },
          },
        },
      }}
    >
      <ReactMultiEmail
        emails={value}
        onChange={onChange}
        validateEmail={(email) => isEmail(email)}
        getLabel={(email, i, removeEmail) => (
          <HStack
            spacing="2"
            pr="1"
            pl="2"
            py="1"
            bg="gray.strokeAndBg"
            color="gray.500"
            borderRadius="sm"
            w="fit-content"
            my="2"
          >
            <Text textStyle="smallBodyMedium">{email}</Text>
            <Box onClick={() => removeEmail(i)} cursor="pointer">
              <Icon name="GeneralActionsCloseOrRemove" />
            </Box>
          </HStack>
        )}
      />
    </Box>
  );
};

export default MultiEmailInput;
