import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskAnalysisSubjectsTravel: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.91 9.212c.262-.346.67-.55 1.105-.55H25c.497 0 .956.266 1.202.697l6.248 10.933h5.842a4.708 4.708 0 010 9.416H32.45L26.202 40.64a1.385 1.385 0 01-1.202.698h-4.985a1.384 1.384 0 01-1.331-1.765l2.819-9.866h-4.237l-2.918 2.917c-.26.26-.612.406-.979.406H8.385a1.385 1.385 0 01-1.239-2.004L10.16 25l-3.014-6.027a1.385 1.385 0 011.239-2.004h4.984c.367 0 .72.146.98.406l2.917 2.917h4.237l-2.819-9.865a1.385 1.385 0 01.226-1.215zm2.941 2.219l2.819 9.866a1.385 1.385 0 01-1.332 1.765h-6.646c-.367 0-.72-.146-.979-.406l-2.917-2.918h-2.171l2.321 4.643c.195.39.195.849 0 1.238l-2.321 4.643h2.17l2.918-2.918c.26-.26.612-.405.98-.405h6.645a1.385 1.385 0 011.332 1.764L21.85 38.57h2.346l6.247-10.933a1.385 1.385 0 011.202-.697h6.646a1.939 1.939 0 100-3.877h-6.646c-.497 0-.956-.267-1.202-.698l-6.247-10.933H21.85z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisSubjectsTravel;
