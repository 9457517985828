import React, { ChangeEvent, useState } from 'react';
import { Icon, InputGroup, InputLeftElement, Input } from '../../../../index';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultValue?: string;
  onSearch?: (value: string) => void;
  searchPlaceholder?: string;
}

const SearchBar: React.FC<Props> = ({
  onSearch,
  defaultValue = '',
  searchPlaceholder,
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>(defaultValue);
  const [debounceSearch] = useDebouncedCallback(onSearch || (() => null), 500);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.currentTarget.value);
    debounceSearch(event.currentTarget.value);
  };

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Icon name="GeneralActionsSearch" />
      </InputLeftElement>
      <Input
        bg="gray.strokeAndBg"
        border="none"
        value={searchValue}
        _placeholder={{ color: 'text.gray' }}
        placeholder={searchPlaceholder || t('SEARCH')}
        onChange={handleSearch}
        data-test-id="GENERAL_MODULE-TOP-BAR_SEARCH-BAR"
      />
    </InputGroup>
  );
};

export default SearchBar;
