const darkGreen = {
  primary: {
    main: '#62962D',
    50: '#EBF6E1',
    100: '#DCEFC9',
    150: '#CDE8B1',
    200: '#BEE19A',
    300: '#9FD36B',
    400: '#81C43C',
    500: '#62962D',
    600: '#4B7322',
    700: '#344F18',
    750: '#283E13',
    800: '#1D2C0D',
    850: '#111A08',
    900: '#060903',
  },
  secondary: {
    main: '#7CBF39',
    50: '#EFF8E6',
    100: '#E2F2D2',
    150: '#D5ECBF',
    200: '#C9E6AB',
    300: '#AFDA84',
    400: '#96CF5C',
    500: '#7CBF39',
    600: '#60942C',
    700: '#44691F',
    750: '#365319',
    800: '#283D12',
    850: '#1A280C',
    900: '#0C1205',
  },
};

export default darkGreen;
