import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyMeasure: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M17 13V9.64A2.6 2.6 0 0014.76 7 2.5 2.5 0 0012 9.5V13H9.64A2.6 2.6 0 007 15.24 2.5 2.5 0 009.5 18H33v10.36A2.6 2.6 0 0035.24 31 2.5 2.5 0 0038 28.5V14a1 1 0 00-1-1H17z"
    ></path>
    <path
      fill="currentColor"
      d="M40.36 33H17V22.64A2.6 2.6 0 0014.76 20 2.5 2.5 0 0012 22.5V37a1 1 0 001 1h20v2.36A2.6 2.6 0 0035.24 43 2.5 2.5 0 0038 40.5V38h2.5a2.501 2.501 0 002.5-2.76A2.6 2.6 0 0040.36 33z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyMeasure;
