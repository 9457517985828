import { useMediaQuery } from '@wegroup/design-system';
import { bp } from '../../../design-system/src/theme/foundations/breakpoints';

interface Breakpoints {
  isMobileS: boolean;
  isMobileM: boolean;
  isMobileL: boolean;
  isTabletS: boolean;
  isTablet: boolean;
  isLaptop: boolean;
  isLaptopL: boolean;
  isDesktopS: boolean;
  isDesktop: boolean;
}

const useMobileBreakpoints = (): Breakpoints => {
  const mediaQueryOptions = { ssr: false };

  const [isMobileS] = useMediaQuery(
    `(min-width: ${bp.mobileS}px)`,
    mediaQueryOptions,
  );
  const [isMobileM] = useMediaQuery(
    `(min-width: ${bp.mobileM}px)`,
    mediaQueryOptions,
  );
  const [isMobileL] = useMediaQuery(
    `(min-width: ${bp.mobileL}px)`,
    mediaQueryOptions,
  );
  const [isTabletS] = useMediaQuery(
    `(min-width: ${bp.tabletS}px)`,
    mediaQueryOptions,
  );
  const [isTablet] = useMediaQuery(
    `(min-width: ${bp.tablet}px)`,
    mediaQueryOptions,
  );
  const [isLaptop] = useMediaQuery(
    `(min-width: ${bp.laptop}px)`,
    mediaQueryOptions,
  );
  const [isLaptopL] = useMediaQuery(
    `(min-width: ${bp.laptopL}px)`,
    mediaQueryOptions,
  );
  const [isDesktopS] = useMediaQuery(
    `(min-width: ${bp.desktopS}px)`,
    mediaQueryOptions,
  );
  const [isDesktop] = useMediaQuery(
    `(min-width: ${bp.desktop}px)`,
    mediaQueryOptions,
  );

  return {
    isMobileS,
    isMobileM,
    isMobileL,
    isTabletS,
    isTablet,
    isLaptop,
    isLaptopL,
    isDesktopS,
    isDesktop,
  };
};

export default useMobileBreakpoints;
