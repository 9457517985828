import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsPlayVideo: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M39 15H11a1 1 0 00-1 1v18a1 1 0 001 1h28a1 1 0 001-1V16a1 1 0 00-1-1zm-28-4a5 5 0 00-5 5v18a5 5 0 005 5h28a5 5 0 005-5V16a5 5 0 00-5-5H11z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M30.5 24.134a1 1 0 010 1.732l-7.5 4.33a1 1 0 01-1.5-.866v-8.66a1 1 0 011.5-.866l7.5 4.33z"
    ></path>
  </Icon>
);

export default GeneralActionsPlayVideo;
