import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationMembers: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.147 37.95c.105 1.173-.77 2.103-1.864 2.202l-.048.005h-.098c-1.147 0-1.85-.905-2.076-1.734l-.028-.102-.007-.105c-.11-1.589.129-3.031.766-4.19a4.75 4.75 0 013.023-2.358l.015-.004c2.919-.72 4.41-1.512 5.157-2.08a6.872 6.872 0 01-.306-.327c-1.43-1.608-1.983-3.733-2.193-5.404a15.758 15.758 0 01-.068-3.327c.143-2.712 1.162-4.663 2.643-5.927 1.463-1.249 3.285-1.747 4.912-1.747h.3c1.627 0 3.449.498 4.912 1.747 1.481 1.265 2.5 3.218 2.644 5.932.032.34.14 1.697-.069 3.341-.21 1.666-.762 3.774-2.19 5.381-.08.094-.161.182-.242.265.703.576 2.19 1.434 5.243 2.197l.012.003a4.738 4.738 0 013.024 2.368c.637 1.164.874 2.619.765 4.229v.017l-.003.016c-.098.981-.9 1.859-2.008 1.859h-.098l-.048-.005c-1.094-.1-1.97-1.03-1.864-2.206.045-.486.035-1.091-.114-1.578-.14-.459-.346-.664-.637-.74-4.711-1.19-6.946-2.807-8.022-4.234-.43.075-.878.121-1.355.121h-.2c-.355 0-.73-.018-1.105-.072-1.073 1.394-3.36 2.959-8.025 4.136l-.017.004-.009.002c-.273.062-.47.241-.608.696-.148.484-.16 1.1-.114 1.618zm14.359-17.167v-.065c-.098-2.165-.933-3.273-1.81-3.856-.901-.598-1.904-.682-2.371-.682h-.3c-.467 0-1.47.084-2.37.682-.878.583-1.713 1.691-1.81 3.856v.042l-.006.087c-.021.282-.09 1.183.017 2.32.122 1.322.476 2.837 1.347 3.847.67.758 1.613 1.174 2.872 1.174h.1c1.26 0 2.204-.418 2.875-1.177.756-.848 1.165-2.09 1.36-3.306.195-1.208.169-2.335.098-2.9l-.002-.022z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M22.656 30.6c.386-.148.748-.298 1.085-.45h-1.085v.45zm8.988-7.632c-.192 1.401-.515 3.126-1.429 4.474.718.933 1.935 1.944 4.015 2.862.207.071.406.155.597.25a26.53 26.53 0 003.375 1.075c.291.076.497.282.637.74.149.487.159 1.092.114 1.579-.107 1.175.77 2.106 1.863 2.206l.049.004h.098c1.107 0 1.91-.877 2.009-1.858l.001-.017.001-.017c.11-1.61-.128-3.064-.764-4.23a4.739 4.739 0 00-3.025-2.367l-.012-.003c-3.052-.763-4.54-1.62-5.243-2.197.081-.083.162-.17.242-.265 1.428-1.606 1.98-3.715 2.19-5.38.208-1.644.101-3.001.069-3.342-.143-2.714-1.163-4.667-2.644-5.931-1.463-1.25-3.285-1.748-4.912-1.748h-.3c-1.627 0-3.448.498-4.912 1.747a7.515 7.515 0 00-.131.116c1.227.08 2.796.742 4.09 1.578a4.704 4.704 0 011.004-.113h.299c.467 0 1.47.084 2.37.682.878.583 1.713 1.691 1.81 3.856v.065l.003.023c.07.564.097 1.691-.098 2.9-.195 1.214-.604 2.457-1.36 3.305l-.006.006z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationMembers;
