import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsChevronRight: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.172 25L17.586 36.586l2.828 2.828L34.828 25 20.414 10.586l-2.828 2.828L29.172 25z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsChevronRight;
