import { BoxProps, Box } from '@wegroup/design-system';
import React from 'react';
import LabeledParam from '../LabeledParam';

interface LabelParamFallbackProps {
  label: string;
  tooltip?: string;
}

const LabeledParamFallback: React.FC<
  React.PropsWithChildren<LabelParamFallbackProps & BoxProps>
> = ({ label, tooltip, children, ...props }) => (
  <Box {...props}>
    <LabeledParam label={label} tooltip={tooltip}>
      {children ? children : '-'}
    </LabeledParam>
  </Box>
);

export default LabeledParamFallback;
