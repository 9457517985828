import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleSafetySystems: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M23.14 37.33h-.09v-4.69a3.78 3.78 0 00-.88-2.07c-.4-.51-1.28-1.47-1.73-1.93a7.23 7.23 0 00-3.58-1.93l-.21-.05-3.59-3.6a1.45 1.45 0 00-1-.44 1.52 1.52 0 00-1 .44 1.229 1.229 0 00-.23.36c-.14.26-.2.556-.17.85a.44.44 0 010 .1c.043.293.176.566.38.78l.34.34 4 4-.59.59-4.31-4.32a2.122 2.122 0 01-.35-.53 2.36 2.36 0 01-.3-1.08 2 2 0 01.14-.68l-.91-9.94a1.47 1.47 0 10-2.94 0v12.6a2.43 2.43 0 00.72 1.73L13.86 35v2.36h-.09a.86.86 0 00-.86.86v4.94a.86.86 0 00.85.86h9.36a.86.86 0 00.86-.86v-5a.87.87 0 00-.84-.83zM26.86 37.33H27v-4.69a3.78 3.78 0 01.88-2.07c.4-.51 1.28-1.47 1.73-1.93a7.23 7.23 0 013.58-1.93l.21-.05 3.59-3.6a1.45 1.45 0 011-.44 1.52 1.52 0 011 .44c.097.106.175.228.23.36.14.26.2.556.17.85a.44.44 0 000 .1 1.43 1.43 0 01-.38.78l-.34.34-4 4 .59.59 4.31-4.32c.143-.158.26-.337.35-.53a2.36 2.36 0 00.3-1.08 2 2 0 00-.14-.68l.91-9.94a1.47 1.47 0 012.94 0v12.6a2.43 2.43 0 01-.72 1.73L36.14 35v2.36h.09a.86.86 0 01.86.86v4.94a.86.86 0 01-.85.86h-9.38a.86.86 0 01-.86-.86v-5a.87.87 0 01.86-.83zM33.61 6.9L25.11 5h-.22l-8.5 1.89a.51.51 0 00-.39.53l.54 7.58a12.061 12.061 0 005.63 9.37l2.56 1.6a.48.48 0 00.54 0l2.56-1.6A12.06 12.06 0 0033.46 15L34 7.43a.51.51 0 00-.39-.53z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleSafetySystems;
