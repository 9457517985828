import React, { PropsWithChildren, useEffect } from 'react';
import GoalsModal from '../../features/goals/GoalsModal';
import JWTMismatchModal from '../../features/jwt-mismatch/JWTMismatchModal';
import { useDisclosure } from '@wegroup/design-system';
import useAuth from '../../features/authentication/hooks/useAuth';
import { useLocale } from '../../../../../i18n';
import { getJwtToken } from '../../features/authentication/utils/jwt';
import { useHistory, useLocation } from 'react-router';
import useIsToolkitPlan from '../../hooks/useIsToolkitPlan';

const BrokerComponents: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, isAuthorized, ssoData, isAllBrokerDataFetched } = useAuth();
  const localStorageKey = `wg-answeredCustomerSegment-${user!.id}`;
  const { userCountry } = useLocale();
  const { pathname } = useLocation();
  const history = useHistory();
  const isToolkitPlan = useIsToolkitPlan();

  const goalsModal = useDisclosure();
  const jwtMismatchModal = useDisclosure();

  const handleOnClose = () => {
    localStorage.setItem(localStorageKey, 'true');
    ssoData.refetchUserData();
    goalsModal.onClose();
  };

  // Effect to open up the JWT modal if needed
  useEffect(() => {
    if (user) {
      window.addEventListener('storage', checkIfCurrentUserMatchesJWT);
      window.addEventListener('storage', checkIfUserLoggedOut);
      return () => {
        window.removeEventListener('storage', checkIfCurrentUserMatchesJWT);
        window.removeEventListener('storage', checkIfUserLoggedOut);
      };
    }
  }, [user]);

  // Effect to open up the goals modal
  useEffect(() => {
    if (isAllBrokerDataFetched) {
      const hasAnsweredGoals = localStorage.getItem(localStorageKey);

      // Will only show if userData has a response and if it is not defined or length is 0
      if (
        ssoData!.info?.origin !== 'random' &&
        !hasAnsweredGoals &&
        userCountry === 'BE' &&
        !ssoData.data?.metadata?.customer_segment &&
        !isToolkitPlan
      )
        goalsModal.onOpen();
    }
  }, [isAllBrokerDataFetched]);

  const checkIfUserLoggedOut = (e: StorageEvent) => {
    if (e.key === 'logout-event') {
      history.push('/logout');
    }
  };

  const checkIfCurrentUserMatchesJWT = () => {
    try {
      if (isAuthorized) {
        const jwtData = getJwtToken();
        // check if the current JWT object has the same distr_id as the one in memory
        if (
          jwtData?.ext.did !== user!.distribution_id &&
          pathname !== '/logout'
        )
          jwtMismatchModal.onOpen();
      }
    } catch {
      history.push('/dashboard');
    }
  };

  return (
    <>
      <GoalsModal isOpen={goalsModal.isOpen} onClose={handleOnClose} />
      <JWTMismatchModal isOpen={jwtMismatchModal.isOpen} />
      {children}
    </>
  );
};

export default BrokerComponents;
