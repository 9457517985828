import useAuth from '../../../app/src/features/authentication/hooks/useAuth';
import { useBrokerById } from './useDataQueries';

const useUserAvatar = (): string | undefined => {
  const { user, distribution, campaign } = useAuth();
  const { data: broker, isLoading: isLoadingBroker } = useBrokerById(
    campaign?.created_by,
  );

  const campaignAvatar = campaign?.assistant_avatar;
  const brokerAvatar = broker?.links?.find(
    ({ rel }) => rel === 'assistant_avatar',
  )?.href;
  const userAvatar = user?.links?.find(
    ({ rel }) => rel === 'assistant_avatar',
  )?.href;
  const distributionAvatar = distribution?.avatars?.assistant_avatar;
  const defaultAvatar = 'https://files.wegroup.be/avatars/louise.png';

  if (isLoadingBroker) return undefined;

  return (
    campaignAvatar ||
    brokerAvatar ||
    userAvatar ||
    distributionAvatar ||
    defaultAvatar
  );
};

export default useUserAvatar;
