const paardeKooper = {
  primary: {
    main: '#002548',
    50: '#e6e9ed',
    100: '#ccd3da',
    150: '#b3bec8',
    200: '#99a8b6',
    300: '#667c91',
    400: '#33516d',
    500: '#002548',
    600: '#001e3a',
    700: '#00162b',
    750: '#001324',
    800: '#000f1d',
    850: '#000b16',
    900: '#00070e',
  },
  secondary: {
    main: '#c6ac6b',
    50: '#f9f7f0',
    100: '#f4eee1',
    150: '#eee6d3',
    200: '#e8dec4',
    300: '#ddcda6',
    400: '#d1bd89',
    500: '#c6ac6b',
    600: '#b29b60',
    700: '#9e8a56',
    750: '#8b784b',
    800: '#c6ac6b',
    850: '#635636',
    900: '#4f452b',
  },
};

export default paardeKooper;
