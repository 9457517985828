import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBankAccount: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_3965_10286"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3965_10286)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M102.028 108.4H256.952V216.4H102.028z"
        ></path>
        <mask id="path-4-inside-1_3965_10286" fill="#fff">
          <path d="M70 212.719a6 6 0 016-6h204a6 6 0 016 6v14.6a1 1 0 01-1 1H71a1 1 0 01-1-1v-14.6z"></path>
        </mask>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          d="M70 212.719a6 6 0 016-6h204a6 6 0 016 6v14.6a1 1 0 01-1 1H71a1 1 0 01-1-1v-14.6z"
          mask="url(#path-4-inside-1_3965_10286)"
        ></path>
        <rect
          width="44.414"
          height="7.917"
          x="79.451"
          y="199.779"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="3.959"
        ></rect>
        <rect
          width="44.414"
          height="7.917"
          x="79.451"
          y="117.104"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="3.959"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M88.389 126.041H114.92699999999999V198.75799999999998H88.389z"
        ></path>
        <rect
          width="44.414"
          height="7.917"
          x="155.419"
          y="199.779"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="3.959"
        ></rect>
        <rect
          width="44.414"
          height="7.917"
          x="155.419"
          y="117.104"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="3.959"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M164.357 126.041H190.895V198.75799999999998H164.357z"
        ></path>
        <rect
          width="44.414"
          height="7.917"
          x="231.396"
          y="199.779"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="3.959"
        ></rect>
        <rect
          width="44.414"
          height="7.917"
          x="231.396"
          y="117.104"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="3.959"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M240.333 126.041H266.871V198.75799999999998H240.333z"
        ></path>
        <mask id="path-14-inside-2_3965_10286" fill="#fff">
          <path d="M81.917 101.719a3 3 0 013-3h186.166a3 3 0 013 3v16.365H81.917v-16.365z"></path>
        </mask>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          d="M81.917 101.719a3 3 0 013-3h186.166a3 3 0 013 3v16.365H81.917v-16.365z"
          mask="url(#path-14-inside-2_3965_10286)"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M87.804 97.88l83.271-58.852a12 12 0 0113.852 0l83.27 58.853c.797.563.399 1.816-.577 1.816H88.381c-.975 0-1.374-1.253-.577-1.816z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M118.222 97.885l52.784-37.866c4.181-3 9.808-3 13.989 0l52.784 37.866c.79.567.389 1.813-.583 1.813H118.805c-.972 0-1.373-1.246-.583-1.813z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBankAccount;
