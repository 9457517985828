import React from 'react';
import { Text, Box, IconButton, Tooltip, Icon } from '@wegroup/design-system';
import { Link } from 'react-router-dom';
import SmallToolbar from '../../../../flows/src/components/ui/common/components/small-toolbar/SmallToolbar';
import { useTranslation } from 'react-i18next';

interface Props {
  onBackUrl?: string;
  tooltipText: string;
}

const SmallToolbarWithBack: React.FC<React.PropsWithChildren<Props>> = ({
  onBackUrl,
  tooltipText,
}) => {
  const { t } = useTranslation();

  return (
    <SmallToolbar>
      {onBackUrl && (
        <Tooltip placement="right" label={tooltipText}>
          <IconButton
            as={Link}
            to={onBackUrl}
            w={{ base: '10', tablet: '12' }}
            p="2"
            h={{ base: '10', tablet: '12' }}
            aria-label="Back button"
            bg={{ base: 'primary.50', tablet: 'secondary.600' }}
            icon={
              <Icon
                color={{ base: 'primary.500', tablet: 'gray.ultraLight' }}
                boxSize="8"
                name="GeneralActionsReturn"
              />
            }
          />
        </Tooltip>
      )}
      {/* MOBILE TEXT */}
      <Box display={{ tablet: 'none' }}>
        <Text
          color="primary.500"
          textStyle={{ base: 'largeBodyBold', tablet: 'h5Headline' }}
          pl={{ base: '2' }}
        >
          {t('RISK-ANALYSIS_RECOMMENDATIONS_SMALL-TOOLBAR_TITLE')}
        </Text>
        {/* Box to align the title better on mobile */}
      </Box>
      <Box w="12" bg="red" display={{ tablet: 'none' }} />
    </SmallToolbar>
  );
};

export default SmallToolbarWithBack;
