import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersLocation: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25 10a12 12 0 00-12 12c0 9 12 19 12 19s12-10 12-19a12 12 0 00-12-12zm0 19a7 7 0 110-14 7 7 0 010 14z"
    ></path>
  </Icon>
);

export default GeneralOthersLocation;
