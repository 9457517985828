import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilityDrone: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M41.5 39H40v-7a1 1 0 00-1-1h-4v-5H15v5h-4a1 1 0 00-1 1v7H8.5a1.5 1.5 0 100 3h6a1.5 1.5 0 100-3H13v-5h2v1a1 1 0 001 1h18a1 1 0 001-1v-1h2v5h-1.5a1.5 1.5 0 100 3h6a1.5 1.5 0 100-3z"
    ></path>
    <path
      fill="currentColor"
      d="M46.5 10H42v-.5a1.5 1.5 0 10-3 0v.5h-4.5a1.5 1.5 0 100 3H39v5.5a2.5 2.5 0 00-1 2v.5H12v-.5a2.5 2.5 0 00-1-2V13h4.5a1.5 1.5 0 100-3H11v-.5a1.5 1.5 0 10-3 0v.5H3.5a1.5 1.5 0 100 3H8v5.51a2.5 2.5 0 00-1 2v3a2.512 2.512 0 005 .49h26.1a2.512 2.512 0 005-.5v-3a2.5 2.5 0 00-1-2V13h4.5a1.5 1.5 0 100-3h-.1z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilityDrone;
