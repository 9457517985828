import { request } from '../../../common/src/legacy/services/httpSessionService';
import { StatusResponse } from '../../../../types/API';

export const postConversationFeedback = async (
  conversationId: string,
  feedback: string,
): Promise<[StatusResponse, number]> => {
  const path = `v1/api/conversations/${conversationId}/feedback`;
  const [resp, status] = await request({
    path,
    method: 'POST',
    payload: {
      text: feedback,
    },
  });
  return [resp, status];
};
