import { useMemo } from 'react';
import { useLocation } from 'react-router';

const useQueryParams = (): URLSearchParams => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQueryParams;

export const getQueryParams = (): URLSearchParams => {
  const url = new URL(window.location.href);
  return new URLSearchParams(url.search);
};
