import React from 'react';

const RocketLouise: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="63"
      height="87"
      fill="none"
      viewBox="0 0 63 87"
    >
      <path
        fill="url(#paint0_linear_1123_37137)"
        d="M46.216 66.99a14.935 14.935 0 00-9.842 3.687V60.59h-8.778v21.25a12.268 12.268 0 00-5.376 3.346c-.725-4.832-4.886-8.53-9.912-8.53-5.54 0-10.029 4.493-10.029 10.04v.175H60.37a14.99 14.99 0 00.807-4.88c.011-8.284-6.698-15-14.961-15z"
        opacity="0.8"
      ></path>
      <path
        fill="#FF8000"
        fillRule="evenodd"
        d="M29.934 56.483c.065 0 .117.052.117.117v9.466a.117.117 0 11-.234 0V56.6c0-.065.052-.117.117-.117zM31.98 61.222c.064 0 .116.052.116.117v16.826a.117.117 0 11-.233 0V61.34c0-.065.052-.117.116-.117zM34.376 58.097c.064 0 .117.053.117.117v7.606a.117.117 0 01-.234 0v-7.605c0-.065.052-.118.117-.118zM29.934 66.815c.065 0 .117.052.117.117v1.533a.117.117 0 11-.234 0v-1.533c0-.065.052-.117.117-.117zM29.934 72.7c.065 0 .117.053.117.118v1.884a.117.117 0 11-.234 0v-1.884c0-.065.052-.117.117-.117zM34.376 66.862c.064 0 .117.052.117.117v4.2a.117.117 0 11-.234 0v-4.2c0-.065.052-.117.117-.117z"
        clipRule="evenodd"
      ></path>
      <path fill="#554561" d="M36.398 59.303H27.55v2.55h8.848v-2.55z"></path>
      <path
        fill="#31213C"
        d="M32.19 59.303h-.444v2.55h.444v-2.55z"
        opacity="0.6"
      ></path>
      <path
        fill="#FF8000"
        d="M37.929 39.82h-.257v17.283l5.107 12.556c.106.257.491.187.491-.094V45.167a5.343 5.343 0 00-5.341-5.347zM26.58 39.82h.257v17.283l-5.108 12.544c-.105.257-.491.187-.491-.094V45.156a5.34 5.34 0 015.341-5.336z"
      ></path>
      <path
        fill="#F4D3B8"
        d="M40.839 35.198c0 1.743-.058 3.463-.164 5.172-.35 5.406-1.25 10.613-2.63 15.504-.116.398-.221.796-.338 1.194H26.24C24.72 52 23.716 46.583 23.307 40.932a77.34 77.34 0 01-.21-5.734c0-9.057 1.53-17.634 4.265-25.322.27-.737.538-1.474.83-2.2.293-.737.596-1.463.9-2.176A62.182 62.182 0 0131.746.117a.253.253 0 01.444 0 63.91 63.91 0 013.553 7.56c.292.725.561 1.45.83 2.199 2.735 7.688 4.266 16.265 4.266 25.322z"
      ></path>
      <path
        fill="#FF8000"
        d="M37.683 57.068H26.275v2.235h11.408v-2.235z"
      ></path>
      <path
        fill="#31213C"
        d="M29.08 59.303h-.443v2.55h.444v-2.55zM35.053 59.303h-.444v2.55h.444v-2.55z"
        opacity="0.6"
      ></path>
      <path
        fill="#fff"
        d="M35.696 17.88a3.724 3.724 0 01-2.583 3.545 3.617 3.617 0 01-1.063.176h-.07a3.46 3.46 0 01-.772-.082c-.69-.14-1.309-.48-1.8-.948a3.712 3.712 0 01-1.157-2.691 3.733 3.733 0 013.729-3.733c.257 0 .502.023.748.07a3.715 3.715 0 012.349 1.592c.199.304.362.631.468.982.093.351.151.714.151 1.089z"
      ></path>
      <path
        fill="#FFEEE0"
        d="M34.528 15.177l-3.32 6.354c-.69-.14-1.309-.48-1.8-.948l3.32-6.354c.678.14 1.309.48 1.8.948zM35.533 16.791l-2.42 4.634a3.617 3.617 0 01-1.063.176l3.027-5.792c.199.304.35.632.456.982z"
      ></path>
      <path
        fill="#FF8000"
        fillRule="evenodd"
        d="M28.318 18.608a.117.117 0 01.14.088c.159.69.532 1.326 1.03 1.802m-1.17-1.89a.117.117 0 00-.088.14l.088-.14zm-.088.14a3.886 3.886 0 001.097 1.92l-1.097-1.92zm1.259 1.75a3.56 3.56 0 001.742.918h.003c.247.057.495.08.745.08h.07a3.5 3.5 0 001.877-.578.117.117 0 11.128.196 3.91 3.91 0 01-.904.434h-.002c-.347.109-.721.182-1.098.182h-.07c-.264 0-.53-.024-.797-.085a3.795 3.795 0 01-1.855-.977M31.968 14.276a3.608 3.608 0 00-3.487 2.697.117.117 0 11-.226-.06 3.842 3.842 0 013.713-2.871c.264 0 .517.024.77.072h.001a3.833 3.833 0 012.424 1.642 3.83 3.83 0 01.65 2.135c0 1.11-.47 2.112-1.217 2.812a.117.117 0 11-.16-.17 3.61 3.61 0 00.985-3.695v-.001a3.684 3.684 0 00-.453-.953 3.456 3.456 0 00-.52-.622h-.001a3.598 3.598 0 00-1.754-.918 3.84 3.84 0 00-.725-.068z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M26.112 57.033c0-.065.052-.117.117-.117h5.739a.117.117 0 010 .234h-5.74a.117.117 0 01-.116-.117zM35.194 57.033c0-.065.052-.117.116-.117h2.326a.117.117 0 010 .234H35.31a.117.117 0 01-.116-.117z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#554267"
        fillRule="evenodd"
        d="M10.905 38.533a.573.573 0 10.001 1.146.573.573 0 000-1.146zm-.806.573a.807.807 0 111.614 0 .807.807 0 01-1.614 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#EF7100"
        fillRule="evenodd"
        d="M47.689 5.523a1.03 1.03 0 100 2.058 1.03 1.03 0 000-2.058zm-1.263 1.03a1.263 1.263 0 112.526-.002 1.263 1.263 0 01-2.526.002zM50.622 16.932a.456.456 0 100 .912.456.456 0 000-.912zm-.69.456a.69.69 0 111.38 0 .69.69 0 01-1.38 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FF8000"
        d="M34.843 5.511h-5.739A62.154 62.154 0 0131.757.13a.253.253 0 01.444 0 65.64 65.64 0 012.642 5.382z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M28.987 5.511c0-.064.053-.117.117-.117h5.763a.117.117 0 010 .234h-5.763a.117.117 0 01-.117-.117z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FF8000"
        fillRule="evenodd"
        d="M24.148 49.567c0-.065.053-.117.117-.117h15.627a.117.117 0 010 .234H24.265a.117.117 0 01-.117-.117zM31.617 30.096a3.277 3.277 0 00-3.273 3.276v7.513a3.277 3.277 0 003.273 3.276h.491a3.27 3.27 0 003.273-3.276v-7.513a3.277 3.277 0 00-3.273-3.276h-.49zm-3.506 3.276a3.51 3.51 0 013.506-3.51h.491a3.51 3.51 0 013.507 3.51v7.513a3.503 3.503 0 01-3.507 3.51h-.49a3.51 3.51 0 01-3.507-3.51v-7.513z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FF8000"
        fillRule="evenodd"
        d="M34.364 49.45c.064 0 .117.052.117.117v5.055a.117.117 0 11-.234 0v-5.055c0-.065.052-.117.117-.117zM34.364 55.149c.064 0 .117.052.117.117v2.07a.117.117 0 11-.234 0v-2.07c0-.065.052-.117.117-.117zM29.98 51.568c.065 0 .117.053.117.117v5.652a.117.117 0 11-.233 0v-5.652c0-.065.052-.117.117-.117z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M32.599 57.033c0-.065.052-.117.117-.117h2.045a.117.117 0 010 .234h-2.045a.117.117 0 01-.117-.117z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FF8000"
        fillRule="evenodd"
        d="M0 86.883c0-.065.052-.117.117-.117h62.766a.117.117 0 010 .234H.117A.117.117 0 010 86.883z"
        clipRule="evenodd"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_1123_37137"
          x1="31.734"
          x2="31.734"
          y1="111.78"
          y2="28.292"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.351" stopColor="#FFE6D4" stopOpacity="0.6"></stop>
          <stop offset="0.798" stopColor="#FFE6D4" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RocketLouise;
