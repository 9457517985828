import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyBasementCellar: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M42.36 17.36l-4-2.64V8.24a1.12 1.12 0 00-1.12-1.12h-5.57a1.12 1.12 0 00-1.12 1.12v1.25L25.6 6.2a1.18 1.18 0 00-1.32 0L7.53 17.36a1.19 1.19 0 00-.53 1V42.8A1.2 1.2 0 008.2 44h33.48a1.2 1.2 0 001.2-1.2V18.35a1.2 1.2 0 00-.52-.99zm-9.89-4.08a.51.51 0 01-.24.07.44.44 0 00.24-.07zm7.17 27.48H36V37.5a.5.5 0 00-.5-.5H33v-3.5a.5.5 0 00-.5-.5H10.24V19.52l14.7-9.69L29 12.56l2.33 1.53a1.56 1.56 0 002.42-1.31v-2.43h1.36v5.44a1.54 1.54 0 00.71 1.31l3.78 2.43.04 21.23z"
    ></path>
    <path
      fill="currentColor"
      d="M29.64 23.27a1.21 1.21 0 00-1.71 0L26.21 25v-6.81a1.21 1.21 0 00-2.42 0V25l-1.72-1.69a1.21 1.21 0 00-1.71 0 1.17 1.17 0 000 1.69l3.78 3.73a1.23 1.23 0 001.72 0L29.64 25a1.17 1.17 0 000-1.73z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyBasementCellar;
