import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationFireExtinguishers: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M146.552 180.634c0 1.916 2.537 3.753 7.053 5.108 4.516 1.354 10.64 2.115 17.026 2.115 6.386 0 12.51-.761 17.026-2.115 4.516-1.355 7.052-3.192 7.052-5.108 0-1.916-2.536-3.753-7.052-5.108-4.516-1.355-10.64-2.116-17.026-2.116-6.386 0-12.51.761-17.026 2.116s-7.053 3.192-7.053 5.108Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M182.391 75.965V59.24h-15.569v16.725a27.216 27.216 0 0 0-19.465 26.092v112.884a7.79 7.79 0 0 0 2.28 5.507 7.79 7.79 0 0 0 5.507 2.28h38.925a7.786 7.786 0 0 0 7.782-7.787V102.057a27.241 27.241 0 0 0-19.46-26.092Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m131.787 43.666 35.034 15.574A42.822 42.822 0 0 0 124 102.061v97.306"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M230.354 43.704a1.793 1.793 0 0 1 1.844.93 5.29 5.29 0 0 1 .8 2.927v23.357a5.29 5.29 0 0 1-.8 2.927 1.794 1.794 0 0 1-1.844.93l-28.336-6.304c-2.321-.52-4.054-3.819-4.054-7.705v-3.044c0-3.9 1.733-7.223 4.054-7.705l28.336-6.313Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M182.39 59.24h15.57"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M201.85 199.367h-19.46a3.898 3.898 0 0 1-3.896-3.891v-77.851a3.894 3.894 0 0 1 3.896-3.891h19.46v85.633Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationFireExtinguishers;
