import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCarpets: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_2061_7099"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_2061_7099)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M76 63H172V201H76z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M87 74H161V190H87z"
        ></path>
        <rect
          width="14.144"
          height="14.144"
          x="95.5"
          y="171.5"
          fill={colors.primary[300]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
          transform="rotate(-45 95.5 171.5)"
        ></rect>
        <rect
          width="14.144"
          height="14.144"
          x="95.5"
          y="92.5"
          fill={colors.primary[300]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
          transform="rotate(-45 95.5 92.5)"
        ></rect>
        <rect
          width="14.144"
          height="14.144"
          x="132.5"
          y="171.5"
          fill={colors.primary[300]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
          transform="rotate(-45 132.5 171.5)"
        ></rect>
        <rect
          width="14.144"
          height="14.144"
          x="132.5"
          y="92.5"
          fill={colors.primary[300]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
          transform="rotate(-45 132.5 92.5)"
        ></rect>
        <circle
          cx="124"
          cy="132"
          r="23"
          fill={colors.primary[300]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[500]}
          d="M74 55a2 2 0 114 0v8h-4v-8zM85 55a2 2 0 114 0v8h-4v-8zM95 55a2 2 0 114 0v8h-4v-8zM106 55a2 2 0 114 0v8h-4v-8zM117 55a2 2 0 114 0v8h-4v-8zM127 55a2 2 0 114 0v8h-4v-8zM138 55a2 2 0 114 0v8h-4v-8zM149 55a2 2 0 114 0v8h-4v-8zM159 55a2 2 0 114 0v8h-4v-8zM170 55a2 2 0 114 0v8h-4v-8zM74 209a2 2 0 104 0v-8h-4v8zM85 209a2 2 0 104 0v-8h-4v8zM95 209a2 2 0 104 0v-8h-4v8zM106 209a2 2 0 104 0v-8h-4v8zM117 209a2 2 0 104 0v-8h-4v8zM127 209a2 2 0 104 0v-8h-4v8zM138 209a2 2 0 104 0v-8h-4v8zM149 209a2 2 0 104 0v-8h-4v8zM159 209a2 2 0 104 0v-8h-4v8zM170 209a2 2 0 104 0v-8h-4v8z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M184 63H280V201H184z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M195 74H269V190H195z"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M182 55a2 2 0 114 0v8h-4v-8zM193 55a2 2 0 114 0v8h-4v-8zM203 55a2 2 0 114 0v8h-4v-8zM214 55a2 2 0 114 0v8h-4v-8zM225 55a2 2 0 114 0v8h-4v-8zM235 55a2 2 0 114 0v8h-4v-8zM246 55a2 2 0 114 0v8h-4v-8zM257 55a2 2 0 114 0v8h-4v-8zM267 55a2 2 0 114 0v8h-4v-8zM278 55a2 2 0 114 0v8h-4v-8zM182 209a2 2 0 104 0v-8h-4v8zM193 209a2 2 0 104 0v-8h-4v8zM203 209a2 2 0 104 0v-8h-4v8zM214 209a2 2 0 104 0v-8h-4v8zM225 209a2 2 0 104 0v-8h-4v8zM235 209a2 2 0 104 0v-8h-4v8zM246 209a2 2 0 104 0v-8h-4v8zM257 209a2 2 0 104 0v-8h-4v8zM267 209a2 2 0 104 0v-8h-4v8zM278 209a2 2 0 104 0v-8h-4v8z"
        ></path>
        <mask
          id="mask1_2061_7099"
          style={{ maskType: 'alpha' }}
          width="74"
          height="116"
          x="195"
          y="74"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill={colors.primary[500]}
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="2"
            d="M196 75H268V189H196z"
          ></path>
        </mask>
        <g stroke={colors.primary[500]} mask="url(#mask1_2061_7099)">
          <circle
            cx="239.5"
            cy="111.5"
            r="10.5"
            fill={colors.primary[300]}
            strokeWidth="4"
          ></circle>
          <circle
            cx="269.5"
            cy="77.5"
            r="25.5"
            fill={colors.primary[300]}
            strokeWidth="4"
          ></circle>
          <circle
            cx="202"
            cy="80"
            r="17"
            fill={colors.primary[300]}
            strokeWidth="4"
          ></circle>
          <circle
            cx="216"
            cy="140"
            r="11"
            fill={colors.primary[300]}
            strokeWidth="4"
          ></circle>
          <circle
            cx="206"
            cy="182"
            r="19"
            fill={colors.primary[300]}
            strokeWidth="4"
          ></circle>
          <circle
            cx="267"
            cy="161"
            r="26"
            fill={colors.primary[300]}
            strokeWidth="4"
          ></circle>
          <path
            strokeLinejoin="round"
            strokeWidth="2"
            d="M196 75H268V189H196z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default IllustrationCarpets;
