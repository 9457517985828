import { parse, stringifyUrl } from 'query-string';
import { useEffect } from 'react';
import useAuth from './useAuth';
import {
  authenticateByLoginToken,
  onUserReceivedAuthorizationToken,
} from '../classes/WgAuthState';
import { useHistory } from 'react-router';

/** Listens for state changes related to auth */
const useAuthListener = () => {
  const {
    isReturningFromSSO,
    isReturningFromSSOError,
    isAuthenticatingViaLoginToken,
    login,
    authParams,
  } = useAuth();
  const search = window.location.search;
  const history = useHistory();

  const onReceiveAuthToken = async () => {
    const pathname = await onUserReceivedAuthorizationToken();
    if (pathname) history.push(pathname);
    else history.push('/');
  };

  useEffect(() => {
    if (isReturningFromSSO) {
      onReceiveAuthToken();
    }
  }, [isReturningFromSSO]);

  useEffect(() => {
    if (isReturningFromSSOError) {
      const stateParam = authParams.state;
      if (typeof stateParam === 'string') {
        const redirectUri = stateParam.split(':')[1];
        login(redirectUri);
      }
    }
  }, [isReturningFromSSOError]);

  useEffect(() => {
    if (isAuthenticatingViaLoginToken) {
      const loginToken = parse(search).login_token;
      const brokerId = parse(search).broker_id as string;
      const searchWithoutToken = {
        ...parse(search),
        login_token: undefined,
        broker_id: undefined,
      };

      if (loginToken && typeof loginToken === 'string')
        authenticateByLoginToken({
          loginToken,
          brokerId,
          redirectUri: stringifyUrl({
            query: searchWithoutToken,
            url: window.location.pathname,
          }),
        });
    }
  }, [isAuthenticatingViaLoginToken]);
};

export default useAuthListener;
