import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralCompanyLogoInstagram: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <g clipPath="url(#clip0_4044_906)">
      <path
        fill="currentColor"
        d="M25 10.745c4.806 0 5.376.018 7.275.105 4.878.222 7.157 2.536 7.379 7.378.086 1.897.103 2.467.103 7.273 0 4.808-.018 5.377-.103 7.274-.224 4.837-2.497 7.157-7.379 7.379-1.899.086-2.466.104-7.275.104-4.806 0-5.376-.018-7.273-.105-4.89-.223-7.157-2.548-7.379-7.38-.087-1.897-.105-2.466-.105-7.273 0-4.806.02-5.375.105-7.273.223-4.841 2.496-7.157 7.379-7.379 1.898-.086 2.467-.104 7.273-.104zM25 7.5c-4.889 0-5.5.021-7.42.108-6.537.3-10.17 3.927-10.47 10.47C7.02 20 7 20.612 7 25.5c0 4.889.021 5.502.108 7.422.3 6.537 3.927 10.17 10.47 10.47 1.922.087 2.534.108 7.422.108 4.889 0 5.502-.021 7.422-.108 6.531-.3 10.173-3.927 10.469-10.47.088-1.92.109-2.533.109-7.422 0-4.889-.021-5.5-.108-7.42-.294-6.531-3.925-10.17-10.469-10.47C30.502 7.52 29.888 7.5 25 7.5zm0 8.757a9.243 9.243 0 00-9.243 9.243 9.244 9.244 0 1018.486 0A9.243 9.243 0 0025 16.257zM25 31.5a6 6 0 110-12 6 6 0 010 12zm9.609-17.767a2.16 2.16 0 10-.001 4.321 2.16 2.16 0 00.001-4.321z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_4044_906">
        <path
          fill="currentColor"
          d="M0 0H36V36H0z"
          transform="translate(7 7.5)"
        ></path>
      </clipPath>
    </defs>
  </Icon>
);

export default GeneralCompanyLogoInstagram;
