import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyCracks: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M39.56 18.56L36 16.17v-5.36a1 1 0 00-1-1h-4a1 1 0 00-1 1v1.34l-4.45-3a1 1 0 00-1.1 0l-14 9.39a1 1 0 00-.45.84V40a1 1 0 001 1h13.34a.5.5 0 00.48-.64l-1.77-6.17a.5.5 0 010-.36l2.84-5.69a.49.49 0 00.05-.29l-.84-6.69a.2.2 0 01.38-.12l3.41 6.81a.56.56 0 010 .31L28 33.8a.49.49 0 00.09.37l4.73 6.62a.51.51 0 00.41.21H39a1 1 0 001-1V19.4a1 1 0 00-.44-.84z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyCracks;
