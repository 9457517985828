import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationResidenceClosed: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_1724_7157"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_1724_7157)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <mask
          id="mask1_1724_7157"
          style={{ maskType: 'alpha' }}
          width="320"
          height="321"
          x="17"
          y="1"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="url(#paint0_radial_1724_7157)"
            d="M337 161.001c0 88.366-71.634 160-160 160s-160-71.634-160-160 71.634-160 160-160 160 71.634 160 160z"
          ></path>
        </mask>
        <g
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          mask="url(#mask1_1724_7157)"
        >
          <path
            fill={colors.primary[100]}
            d="M308.381 102.914v123.305h87.41v-79.598l-87.41-43.707z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M376.603 189.975a4.262 4.262 0 00-4.257-4.263H329.7a4.263 4.263 0 00-4.263 4.263v36.17h51.166v-36.17zM406.45 145.051a4.266 4.266 0 01-6.172 3.815l-98.921-49.462A4.266 4.266 0 01299 95.589v-5.816a4.252 4.252 0 012.021-3.627 4.258 4.258 0 014.148-.185l98.924 49.459a4.269 4.269 0 012.357 3.815v5.816zM323.303 149.468a10.652 10.652 0 003.122 7.537 10.666 10.666 0 007.537 3.122 10.666 10.666 0 0010.66-10.659 10.652 10.652 0 00-3.122-7.537 10.666 10.666 0 00-7.538-3.122 10.658 10.658 0 00-10.659 10.659zM35.678 137.957h-20.47l4.895-42.278a4.266 4.266 0 014.237-3.773h2.207a4.266 4.266 0 014.237 3.773l4.894 42.278z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M42.074 117.491a4.267 4.267 0 00-2.63-3.942 4.27 4.27 0 00-1.633-.324H11.365v25.161h30.7l.01-20.895z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M-41.069 102.914v123.305h87.41v-79.598l-87.41-43.707z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M27.152 189.975a4.261 4.261 0 00-4.256-4.263h-42.647a4.261 4.261 0 00-4.263 4.263v36.17h51.166v-36.17zM57 145.051a4.27 4.27 0 01-2.023 3.627 4.27 4.27 0 01-4.149.188l-98.92-49.462a4.27 4.27 0 01-2.358-3.815v-5.816a4.26 4.26 0 016.169-3.812l98.924 49.459A4.276 4.276 0 0157 139.235v5.816zM57.322 137.957h20.47l-4.895-42.278a4.266 4.266 0 00-4.237-3.773h-2.207a4.266 4.266 0 00-4.237 3.773l-4.894 42.278z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M50.926 117.491a4.267 4.267 0 012.63-3.942 4.27 4.27 0 011.633-.324h26.446v25.161h-30.7l-.01-20.895z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M134.069 102.914v123.305h-87.41v-79.598l87.41-43.707z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M65.848 189.975a4.261 4.261 0 014.256-4.263h42.647a4.26 4.26 0 014.263 4.263v36.17H65.848v-36.17z"
          ></path>
          <path d="M91.432 185.712v40.43"></path>
          <path
            fill={colors.primary[100]}
            d="M36 145.051a4.27 4.27 0 002.023 3.627 4.27 4.27 0 004.149.188l98.921-49.462a4.275 4.275 0 002.357-3.815v-5.816a4.262 4.262 0 00-2.021-3.627 4.258 4.258 0 00-4.148-.185L38.357 135.42A4.276 4.276 0 0036 139.235v5.816zM119.147 149.468a10.652 10.652 0 01-3.122 7.537 10.666 10.666 0 01-7.537 3.122 10.666 10.666 0 01-9.848-6.58 10.658 10.658 0 1120.507-4.079zM231.321 137.957h20.47l-4.894-42.278a4.263 4.263 0 00-4.237-3.773h-2.207a4.267 4.267 0 00-4.238 3.773l-4.894 42.278z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M224.926 117.491a4.258 4.258 0 014.263-4.266h26.446v25.161h-30.7l-.009-20.895z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M308.069 102.914v123.305h-87.41v-79.598l87.41-43.707z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M239.848 189.975a4.261 4.261 0 014.256-4.263h42.647a4.26 4.26 0 014.263 4.263v36.17h-51.166v-36.17z"
          ></path>
          <path d="M265.432 185.712v40.43"></path>
          <path
            fill={colors.primary[100]}
            d="M210 145.051a4.269 4.269 0 006.172 3.815l98.921-49.462a4.275 4.275 0 002.357-3.815v-5.816a4.262 4.262 0 00-2.021-3.627 4.258 4.258 0 00-4.148-.185l-98.924 49.459a4.269 4.269 0 00-2.357 3.815v5.816zM293.147 149.468a10.67 10.67 0 01-3.122 7.537 10.666 10.666 0 01-7.537 3.122 10.67 10.67 0 01-7.537-3.122 10.666 10.666 0 01-3.122-7.537 10.65 10.65 0 013.122-7.537 10.645 10.645 0 017.537-3.122 10.65 10.65 0 017.537 3.122 10.645 10.645 0 013.122 7.537z"
          ></path>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M210.2 137.957h-20.47l4.894-42.278a4.268 4.268 0 014.238-3.773h2.207a4.264 4.264 0 014.237 3.773l4.894 42.278z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M216.596 117.491a4.258 4.258 0 00-4.263-4.266h-26.446v25.161h30.7l.009-20.895z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M133.453 102.914v123.305h87.41v-79.598l-87.41-43.707z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M201.674 189.975a4.261 4.261 0 00-4.256-4.263h-42.647a4.26 4.26 0 00-4.263 4.263v36.17h51.166v-36.17z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M176.09 185.712v40.43"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M231.522 145.051a4.269 4.269 0 01-6.172 3.815l-98.921-49.462a4.275 4.275 0 01-2.357-3.815v-5.816a4.262 4.262 0 012.021-3.627 4.258 4.258 0 014.148-.185l98.924 49.459a4.273 4.273 0 012.357 3.815v5.816zM148.375 149.468a10.67 10.67 0 003.122 7.537 10.666 10.666 0 007.537 3.122 10.67 10.67 0 007.537-3.122 10.666 10.666 0 003.122-7.537 10.65 10.65 0 00-3.122-7.537 10.645 10.645 0 00-7.537-3.122 10.65 10.65 0 00-7.537 3.122 10.645 10.645 0 00-3.122 7.537z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1724_7157"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="rotate(90 8 169) scale(212.499 243.788)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7F8FB"></stop>
          <stop offset="1" stopColor="#F7F8FB" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
};

export default IllustrationResidenceClosed;
