import { DeepPartial } from 'react-hook-form';
import useSetQueryParam from '../../../../../common/src/hooks/query-params/useAddQueryParam';
import { getQueryParams } from '../../../../../common/src/hooks/useQueryParams';

interface UseFiltersPersistence<T> {
  sessionFilters?: DeepPartial<T>;
  persistFilters: (data: T) => void;
  clearPersistedFilters: () => void;
}

const useFiltersPersistence = <T>(name: string): UseFiltersPersistence<T> => {
  const sessionStorageKey = `filters-${name}`;
  const setQueryParam = useSetQueryParam();

  const getInitialFilters = () => {
    const isFiltersEnabled = getQueryParams().get('filters') === 'true';
    const filtersJSON = getPersistedFilters();

    if (isFiltersEnabled && filtersJSON) {
      // We use a datetime reviver function to restore date objects
      return JSON.parse(filtersJSON, (_, value) => {
        const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,}|)Z$/;

        if (typeof value === 'string' && dateFormat.test(value)) {
          return new Date(value);
        }

        return value;
      });
    }
    return undefined;
  };

  const getPersistedFilters = () => {
    const persistedFilters = sessionStorage.getItem(sessionStorageKey);
    return persistedFilters;
  };

  const persistFilters = (data: T) => {
    setQueryParam({
      filters: 'true',
    });
    sessionStorage.setItem(sessionStorageKey, JSON.stringify(data));
  };

  const clearPersistedFilters = () => {
    setQueryParam({
      filters: undefined,
    });
    sessionStorage.removeItem(sessionStorageKey);
  };

  return {
    persistFilters,
    clearPersistedFilters,
    sessionFilters: getInitialFilters(),
  };
};

export default useFiltersPersistence;
