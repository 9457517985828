import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsChevronRightRounded: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.586 39.414a2 2 0 010-2.828L29.172 25 17.586 13.414a2 2 0 112.828-2.828l13 13a2 2 0 010 2.828l-13 13a2 2 0 01-2.828 0z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsChevronRightRounded;
