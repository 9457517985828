import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationTrain: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_4210_10343"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4210_10343)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask1_4210_10343"
        style={{ maskType: 'alpha' }}
        width="320"
        height="321"
        x="17"
        y="1"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
      </mask>
      <g mask="url(#mask1_4210_10343)">
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M171.5 219c-18.823 0-32.5-29-32.5-29h123s-7 29-27.5 29h-63z"
        ></path>
        <circle
          cx="190"
          cy="220"
          r="8"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="216"
          cy="220"
          r="8"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M194.974 109.5H470V210H235.227a4.09 4.09 0 01-2.287-.709c-6.326-4.276-11.724-6.791-16.966-6.791h-26c-5.895 0-10.207 3.171-14.677 6.513a4.96 4.96 0 01-2.973.987h-54.85c-14.976 0-29.974-24 0-44 10.177-9.215 16.02-15.193 26-27.5 14.291-22.093 28.5-29 51.5-29z"
        ></path>
        <mask
          id="mask2_4210_10343"
          style={{ maskType: 'alpha' }}
          width="374"
          height="105"
          x="98"
          y="107"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M194.974 109.5H470V210H235.227a4.09 4.09 0 01-2.287-.709c-6.326-4.276-11.724-6.791-16.966-6.791h-26c-5.895 0-10.207 3.171-14.677 6.513a4.96 4.96 0 01-2.973.987h-54.85c-14.976 0-29.974-24 0-44 10.177-9.215 16.02-15.193 26-27.5 14.291-22.093 28.5-29 51.5-29z"
          ></path>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask2_4210_10343)"
        >
          <path
            fill={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M261.511 149.5c-71.011 0-61.011 41.5-150.5 41.5-13.278 0 0 34 0 34h334v-75.5h-183.5z"
          ></path>
          <path
            fill="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M125 218c4.913-28.077 4.745-40.296-17.5-56-18.86 18.006-22.887 31.359-28.5 56h46z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M106 122h102c3.296 0 5.178 3.763 3.2 6.4L195 150a10 10 0 01-8 4h-81v-32z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M194.974 109.5H470V210H235.227a4.09 4.09 0 01-2.287-.709c-6.326-4.276-11.724-6.791-16.966-6.791h-26c-5.895 0-10.207 3.171-14.677 6.513a4.96 4.96 0 01-2.973.987h-54.85c-14.976 0-29.974-24 0-44 10.177-9.215 16.02-15.193 26-27.5 14.291-22.093 28.5-29 51.5-29z"
          ></path>
        </g>
        <path
          fill={colors.primary[200]}
          d="M52.096 224.65v-16.156 16.156z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M52.096 224.65v-16.156"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M51.818 140.597l.003-.009.002-.008a.29.29 0 01.277-.209.286.286 0 01.277.209l.002.008.003.008c2.2 7.461 5.67 19.415 8.587 30.349 1.46 5.469 2.776 10.667 3.727 14.912.97 4.33 1.504 7.448 1.504 8.894a14.095 14.095 0 01-8.704 13.026 14.093 14.093 0 01-15.366-3.056 14.096 14.096 0 01-4.13-9.97c0-1.446.535-4.564 1.504-8.894.95-4.246 2.266-9.443 3.725-14.912 2.918-10.934 6.387-22.888 8.59-30.348z"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M77.806 224.65v-12.855 12.855z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M77.806 224.65v-12.855"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M71.147 182.024c2.247-8.423 4.906-17.607 6.663-23.569 1.756 5.962 4.415 15.146 6.662 23.569 1.161 4.349 2.207 8.479 2.962 11.849.773 3.455 1.187 5.89 1.187 6.987a10.81 10.81 0 01-18.455 7.644l-1.414 1.414 1.414-1.414A10.817 10.817 0 0167 200.86c0-1.097.413-3.533 1.187-6.988.754-3.37 1.8-7.499 2.96-11.848z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationTrain;
