import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  VStack,
  Box,
  Center,
  Text,
  Flex,
  Button,
  HStack,
  useTheme,
  Icon,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
} from '@wegroup/design-system';
import { Upload, IconActionClose } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';

interface CustomerUploadProps {
  onFileAccepted: (file: File) => void;
  accept?: string | string[];
  buttonText?: string;
  file?: File;
  onRemove: () => void;
  errorMsg?: string;
  dataTestId?: string;
  isDisabled?: boolean;
}

const CustomerUpload: React.FC<
  React.PropsWithChildren<CustomerUploadProps>
> = ({
  onFileAccepted,
  accept,
  file,
  onRemove,
  buttonText,
  errorMsg,
  children,
  dataTestId = 'DESIGN-SYSTEM_CUSTOMER-UPLOAD',
  isDisabled,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colors, space } = useTheme();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFileAccepted(acceptedFiles[0]);
    },
    [onFileAccepted],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept,
  });

  useEffect(() => {
    if (errorMsg) onOpen();
    else onClose();
  }, [errorMsg]);

  return (
    <Flex
      px="6"
      py="4"
      border="1px"
      borderColor={
        isDragActive
          ? 'primary.500'
          : isOpen
          ? 'danger.main'
          : 'gray.strokeAndBg'
      }
      bg="white"
      boxShadow="level2"
      borderRadius="md"
      flexWrap="wrap"
      alignItems="center"
      gridGap="3"
      position="relative"
      {...getRootProps()}
      data-test-id={dataTestId}
    >
      {/* HoverOverlay */}
      <Center
        display={isDragActive ? 'flex' : 'none'}
        position="absolute"
        left="0"
        top="0"
        w="100%"
        zIndex="1"
        h="100%"
        bg="rgba(100%, 100%,100%, 0.95)"
      >
        <Text textStyle="largeBody" color="primary.400">
          {t('Drag and drop files here')}
        </Text>
      </Center>
      {/* StatusCircle */}
      <HStack spacing="3" onClick={(e) => e.stopPropagation()}>
        <Popover
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={onClose}
          placement="top-start"
          closeOnBlur={false}
          variant="danger"
        >
          <PopoverTrigger>
            <Center
              flexShrink={0}
              w="12"
              h="12"
              borderRadius="full"
              border="3px dashed"
              borderColor={
                !file ? 'gray.100' : errorMsg ? 'danger.main' : 'success.main'
              }
              bgColor={
                !file ? 'unset' : errorMsg ? 'danger.main' : 'success.main'
              }
              transition="0.1s"
            >
              <Box
                w="90%"
                h="90%"
                sx={{ svg: { w: '100%', h: '100%' } }}
                ml={errorMsg ? 'unset' : '-1'}
              >
                {file && (
                  <Icon
                    name={errorMsg ? 'IconActionClose' : 'IconStatusCheck'}
                    color={
                      errorMsg ? colors.danger.light : colors.success.light
                    }
                  />
                )}
              </Box>
            </Center>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverHeader fontWeight="semibold">{errorMsg}</PopoverHeader>
            <PopoverArrow bg="danger.light" />
            <PopoverCloseButton />
          </PopoverContent>
        </Popover>
        {/* Document title + info */}
        <VStack spacing="1" alignItems="stretch">
          <Text
            textStyle="largeBodyMedium"
            color={!file ? 'black' : errorMsg ? 'danger.main' : 'success.main'}
            transition="0.1s"
          >
            {children || t('Document label')}
          </Text>
          {file && (
            <Text textStyle="smallBody" fontSize="12px" color="black">
              {file.name}
            </Text>
          )}
        </VStack>
      </HStack>

      {/* Buttons */}
      {file ? (
        <Box ml="auto" _hover={{ path: { fill: 'white', transition: '0.3s' } }}>
          <Button
            variant="dangerSecondary"
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
            borderRadius="full"
            data-test-id="DESIGN-SYSTEM_CUSTOMER-UPLOAD_BUTTON-REMOVE"
            isDisabled={isDisabled}
          >
            <HStack spacing="3">
              <IconActionClose color={colors.danger.main} size={space[5]} />{' '}
              <Text>{t('Remove')}</Text>
            </HStack>
          </Button>
        </Box>
      ) : (
        <Button
          ml="auto"
          variant="secondary"
          borderRadius="full"
          data-test-id="DESIGN-SYSTEM_CUSTOMER-UPLOAD_BUTTON-UPLOAD"
        >
          <HStack spacing="3">
            <Upload color={colors.primary[500]} size={space[5]} />{' '}
            <Text>{buttonText || t('CUSTOMER-UPLOAD_BUTTON_TEXT')}</Text>
          </HStack>
        </Button>
      )}
      <input {...getInputProps()} />
    </Flex>
  );
};

export default CustomerUpload;
