import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationWork: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_4408_10378"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4408_10378)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <mask id="path-3-inside-1_4408_10378" fill="#fff">
          <rect
            width="132.303"
            height="97.116"
            x="79.106"
            y="121.496"
            rx="1"
            transform="rotate(-3 79.106 121.496)"
          ></rect>
        </mask>
        <rect
          width="132.303"
          height="97.116"
          x="79.106"
          y="121.496"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-3-inside-1_4408_10378)"
          rx="1"
          transform="rotate(-3 79.106 121.496)"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M95.21 132.984l3.812 72.737"
        ></path>
        <mask id="path-5-inside-2_4408_10378" fill="#fff">
          <rect
            width="35.891"
            height="13.371"
            x="93.896"
            y="134.814"
            rx="2"
            transform="rotate(-3 93.896 134.814)"
          ></rect>
        </mask>
        <rect
          width="35.891"
          height="13.371"
          x="93.896"
          y="134.814"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-5-inside-2_4408_10378)"
          rx="2"
          transform="rotate(-3 93.896 134.814)"
        ></rect>
        <mask id="path-6-inside-3_4408_10378" fill="#fff">
          <rect
            width="32.372"
            height="13.371"
            x="94.82"
            y="152.385"
            rx="2"
            transform="rotate(-3 94.82 152.385)"
          ></rect>
        </mask>
        <rect
          width="32.372"
          height="13.371"
          x="94.82"
          y="152.385"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-6-inside-3_4408_10378)"
          rx="2"
          transform="rotate(-3 94.82 152.385)"
        ></rect>
        <mask id="path-7-inside-4_4408_10378" fill="#fff">
          <rect
            width="9.852"
            height="13.371"
            x="95.815"
            y="171.359"
            rx="2"
            transform="rotate(-3 95.815 171.359)"
          ></rect>
        </mask>
        <rect
          width="9.852"
          height="13.371"
          x="95.815"
          y="171.359"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-7-inside-4_4408_10378)"
          rx="2"
          transform="rotate(-3 95.815 171.359)"
        ></rect>
        <mask id="path-8-inside-5_4408_10378" fill="#fff">
          <rect
            width="21.816"
            height="13.371"
            x="96.733"
            y="188.928"
            rx="2"
            transform="rotate(-3 96.733 188.928)"
          ></rect>
        </mask>
        <rect
          width="21.816"
          height="13.371"
          x="96.733"
          y="188.928"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-8-inside-5_4408_10378)"
          rx="2"
          transform="rotate(-3 96.733 188.928)"
        ></rect>
        <circle
          cx="177.783"
          cy="162.13"
          r="21.927"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="rotate(-3 177.783 162.13)"
        ></circle>
        <mask id="path-10-inside-6_4408_10378" fill="#fff">
          <path d="M201.677 160.878a23.929 23.929 0 01-39.905 19.034 23.935 23.935 0 01-6.544-25.769 23.924 23.924 0 0121.303-15.907l1.252 23.894 23.894-1.252z"></path>
        </mask>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          d="M201.677 160.878a23.929 23.929 0 01-39.905 19.034 23.935 23.935 0 01-6.544-25.769 23.924 23.924 0 0121.303-15.907l1.252 23.894 23.894-1.252z"
          mask="url(#path-10-inside-6_4408_10378)"
        ></path>
        <g filter="url(#filter0_dd_4408_10378)">
          <mask id="path-11-inside-7_4408_10378" fill="#fff">
            <rect
              width="132.303"
              height="97.116"
              x="71"
              y="107.412"
              rx="1"
            ></rect>
          </mask>
          <rect
            width="132.303"
            height="97.116"
            x="71"
            y="107.412"
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="8"
            mask="url(#path-11-inside-7_4408_10378)"
            rx="1"
          ></rect>
          <path
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeWidth="4"
            d="M86.482 119.727v72.836"
          ></path>
          <mask id="path-13-inside-8_4408_10378" fill="#fff">
            <rect
              width="35.891"
              height="13.371"
              x="85.075"
              y="121.486"
              rx="2"
            ></rect>
          </mask>
          <rect
            width="35.891"
            height="13.371"
            x="85.075"
            y="121.486"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="8"
            mask="url(#path-13-inside-8_4408_10378)"
            rx="2"
          ></rect>
          <mask id="path-14-inside-9_4408_10378" fill="#fff">
            <rect
              width="32.372"
              height="13.371"
              x="85.075"
              y="139.08"
              rx="2"
            ></rect>
          </mask>
          <rect
            width="32.372"
            height="13.371"
            x="85.075"
            y="139.08"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="8"
            mask="url(#path-14-inside-9_4408_10378)"
            rx="2"
          ></rect>
          <mask id="path-15-inside-10_4408_10378" fill="#fff">
            <rect
              width="42.224"
              height="13.371"
              x="85.075"
              y="158.082"
              rx="2"
            ></rect>
          </mask>
          <rect
            width="42.224"
            height="13.371"
            x="85.075"
            y="158.082"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="8"
            mask="url(#path-15-inside-10_4408_10378)"
            rx="2"
          ></rect>
          <mask id="path-16-inside-11_4408_10378" fill="#fff">
            <rect
              width="52.077"
              height="13.371"
              x="85.075"
              y="175.674"
              rx="2"
            ></rect>
          </mask>
          <rect
            width="52.077"
            height="13.371"
            x="85.075"
            y="175.674"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="8"
            mask="url(#path-16-inside-11_4408_10378)"
            rx="2"
          ></rect>
          <circle
            cx="167.412"
            cy="153.156"
            r="21.927"
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
          <mask id="path-18-inside-12_4408_10378" fill="#fff">
            <path d="M191.339 153.156a23.929 23.929 0 01-28.595 23.467 23.927 23.927 0 014.668-47.394v23.927h23.927z"></path>
          </mask>
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="8"
            d="M191.339 153.156a23.929 23.929 0 01-28.595 23.467 23.927 23.927 0 014.668-47.394v23.927h23.927z"
            mask="url(#path-18-inside-12_4408_10378)"
          ></path>
        </g>
        <rect
          width="35.409"
          height="12.186"
          x="256.276"
          y="141.909"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6.093"
          transform="rotate(45 256.276 141.909)"
        ></rect>
        <circle
          cx="246.94"
          cy="141.192"
          r="27.557"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="247"
          cy="141"
          r="20"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <rect
          width="57.929"
          height="82.56"
          x="182.35"
          y="13.24"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
          transform="rotate(7.365 182.35 13.24)"
        ></rect>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M182.093 15.223a2 2 0 012.24-1.727l53.484 6.913a2 2 0 011.727 2.24l-3.11 24.06-57.451-7.425 3.11-24.06z"
        ></path>
        <mask id="path-24-inside-13_4408_10378" fill="#fff">
          <rect
            width="43.632"
            height="14.778"
            x="188.705"
            y="19.85"
            rx="2"
            transform="rotate(7.365 188.705 19.85)"
          ></rect>
        </mask>
        <rect
          width="43.632"
          height="14.778"
          x="188.705"
          y="19.85"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-24-inside-13_4408_10378)"
          rx="2"
          transform="rotate(7.365 188.705 19.85)"
        ></rect>
        <mask id="path-25-inside-14_4408_10378" fill="#fff">
          <rect
            width="14.075"
            height="11.26"
            x="182.486"
            y="46.01"
            rx="2"
            transform="rotate(7.365 182.486 46.01)"
          ></rect>
        </mask>
        <rect
          width="14.075"
          height="11.26"
          x="182.486"
          y="46.01"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-25-inside-14_4408_10378)"
          rx="2"
          transform="rotate(7.365 182.486 46.01)"
        ></rect>
        <mask id="path-26-inside-15_4408_10378" fill="#fff">
          <rect
            width="14.075"
            height="11.26"
            x="180.32"
            y="62.762"
            rx="2"
            transform="rotate(7.365 180.32 62.762)"
          ></rect>
        </mask>
        <rect
          width="14.075"
          height="11.26"
          x="180.32"
          y="62.762"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-26-inside-15_4408_10378)"
          rx="2"
          transform="rotate(7.365 180.32 62.762)"
        ></rect>
        <mask id="path-27-inside-16_4408_10378" fill="#fff">
          <rect
            width="14.075"
            height="11.26"
            x="178.242"
            y="78.813"
            rx="2"
            transform="rotate(7.365 178.242 78.813)"
          ></rect>
        </mask>
        <rect
          width="14.075"
          height="11.26"
          x="178.242"
          y="78.813"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-27-inside-16_4408_10378)"
          rx="2"
          transform="rotate(7.365 178.242 78.813)"
        ></rect>
        <mask id="path-28-inside-17_4408_10378" fill="#fff">
          <rect
            width="14.075"
            height="11.26"
            x="199.931"
            y="48.266"
            rx="2"
            transform="rotate(7.365 199.931 48.266)"
          ></rect>
        </mask>
        <rect
          width="14.075"
          height="11.26"
          x="199.931"
          y="48.266"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-28-inside-17_4408_10378)"
          rx="2"
          transform="rotate(7.365 199.931 48.266)"
        ></rect>
        <mask id="path-29-inside-18_4408_10378" fill="#fff">
          <rect
            width="14.075"
            height="11.26"
            x="197.765"
            y="65.016"
            rx="2"
            transform="rotate(7.365 197.765 65.016)"
          ></rect>
        </mask>
        <rect
          width="14.075"
          height="11.26"
          x="197.765"
          y="65.016"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-29-inside-18_4408_10378)"
          rx="2"
          transform="rotate(7.365 197.765 65.016)"
        ></rect>
        <mask id="path-30-inside-19_4408_10378" fill="#fff">
          <rect
            width="14.075"
            height="11.26"
            x="195.692"
            y="81.068"
            rx="2"
            transform="rotate(7.365 195.692 81.068)"
          ></rect>
        </mask>
        <rect
          width="14.075"
          height="11.26"
          x="195.692"
          y="81.068"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-30-inside-19_4408_10378)"
          rx="2"
          transform="rotate(7.365 195.692 81.068)"
        ></rect>
        <mask id="path-31-inside-20_4408_10378" fill="#fff">
          <rect
            width="14.075"
            height="11.26"
            x="217.382"
            y="50.522"
            rx="2"
            transform="rotate(7.365 217.382 50.522)"
          ></rect>
        </mask>
        <rect
          width="14.075"
          height="11.26"
          x="217.382"
          y="50.522"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-31-inside-20_4408_10378)"
          rx="2"
          transform="rotate(7.365 217.382 50.522)"
        ></rect>
        <mask id="path-32-inside-21_4408_10378" fill="#fff">
          <rect
            width="14.075"
            height="11.26"
            x="215.216"
            y="67.272"
            rx="2"
            transform="rotate(7.365 215.216 67.272)"
          ></rect>
        </mask>
        <rect
          width="14.075"
          height="11.26"
          x="215.216"
          y="67.272"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-32-inside-21_4408_10378)"
          rx="2"
          transform="rotate(7.365 215.216 67.272)"
        ></rect>
        <mask id="path-33-inside-22_4408_10378" fill="#fff">
          <rect
            width="14.075"
            height="11.26"
            x="213.143"
            y="83.324"
            rx="2"
            transform="rotate(7.365 213.143 83.324)"
          ></rect>
        </mask>
        <rect
          width="14.075"
          height="11.26"
          x="213.143"
          y="83.324"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-33-inside-22_4408_10378)"
          rx="2"
          transform="rotate(7.365 213.143 83.324)"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_4408_10378"
          width="164.303"
          height="129.115"
          x="55"
          y="99.412"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4408_10378"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_4408_10378"
            result="effect2_dropShadow_4408_10378"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_4408_10378"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationWork;
