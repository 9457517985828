import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilityComputer: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M43 34V10.5a.5.5 0 00-.5-.5h-35a.5.5 0 00-.5.5V34H4a1 1 0 00-1 1v2a4 4 0 004 4h36a4 4 0 004-4v-2a1 1 0 00-1-1h-3zm-14 4h-8a1 1 0 010-2h8a1 1 0 010 2zm9.5-4h-27a.5.5 0 01-.5-.5v-19a.5.5 0 01.5-.5h27a.5.5 0 01.5.5v19a.5.5 0 01-.5.5z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilityComputer;
