import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyWheatFarm: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M20.5 7h-1.45V6a1 1 0 00-2 0v1h-1.5a1.5 1.5 0 00-1.5 1.5v19a1.5 1.5 0 001.5 1.5h1.5v9a7 7 0 00-7-7v6a7 7 0 007 7v1a1 1 0 102 0V29h1.45a1.5 1.5 0 001.5-1.5v-19A1.5 1.5 0 0020.5 7zM32 38V28h.5a1.5 1.5 0 001.5-1.5v-12a1.5 1.5 0 00-1.5-1.5H32v-1a1 1 0 00-2 0v1h-.5a1.5 1.5 0 00-1.5 1.5v12a1.5 1.5 0 001.5 1.5h.5v7a5 5 0 00-5-5v3a5 5 0 005 5v7a1 1 0 002 0v-2a6 6 0 006-6v-5a6 6 0 00-6 6z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyWheatFarm;
