import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyAccidentalLoss: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M19.793 7.36c.253-.805.625-1.453 1.115-1.943a4.339 4.339 0 011.85-1.089C23.485 4.11 24.33 4 25.292 4c1.907 0 3.312.472 4.213 1.417.901.945 1.352 2.205 1.352 3.78 0 .735-.092 1.413-.276 2.034a4.376 4.376 0 01-.957 1.693l-1.917 2.178c-.246.28-.407.64-.486 1.077a8.536 8.536 0 00-.118 1.496H22.85v-.735c0-.998.08-1.807.237-2.428a4.114 4.114 0 01.892-1.693l1.549-1.759c.35-.402.564-.809.643-1.22.078-.411.118-.774.118-1.09 0-.507-.097-.918-.289-1.233-.193-.315-.49-.472-.892-.472-.21 0-.394.039-.552.118-.157.079-.288.232-.393.46-.105.227-.189.555-.25.983-.06.43-.091.994-.091 1.693h-4.41c0-1.155.127-2.134.38-2.94zM40.39 25.312H29.874v-4.207a2.42 2.42 0 00-2.42-2.42H22.51a2.42 2.42 0 00-2.42 2.42v4.207H9.61a2.061 2.061 0 00-2.06 2.061V43.94c0 1.138.922 2.061 2.06 2.061h30.78a2.061 2.061 0 002.06-2.061V27.373a2.061 2.061 0 00-2.06-2.06zm-17.477-3.936h4.174v3.927h-4.174v-3.927z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyAccidentalLoss;
