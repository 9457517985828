import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskAnalysisGeneralRiskAnalysis: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.988 28.983a31 31 0 01-3.9-12.862L25 10.174l12.911 5.947a31.03 31.03 0 01-.413 3.21c1.905 1.366 2.903 2.405 3.42 3.08a34.998 34.998 0 001.04-6.901c.057-1.164-.635-2.222-1.694-2.71L26.255 6.348a3 3 0 00-2.51 0L9.735 12.8c-1.058.488-1.75 1.546-1.692 2.71a35 35 0 0015.439 27.315 2.72 2.72 0 003.036 0 35.004 35.004 0 004.174-3.29c-1.552-.3-3.051-.785-4.356-1.531A31.02 31.02 0 0125 39.012a31.001 31.001 0 01-9.012-10.029z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M43.704 40.435a1.913 1.913 0 000-2.706l-4.33-4.33a8.306 8.306 0 10-2.706 2.706l4.33 4.33a1.913 1.913 0 002.706 0zM27.826 29.037a4.48 4.48 0 118.959 0 4.48 4.48 0 01-8.959 0z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisGeneralRiskAnalysis;
