import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsTakeTour: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M35.125 38.375A4.275 4.275 0 0039.4 34.1V21.95a4.275 4.275 0 00-4.275-4.275h-4.05a1.8 1.8 0 01-1.273-.527L25 12.346l-4.802 4.802a1.8 1.8 0 01-1.273.527h-4.05A4.275 4.275 0 0010.6 21.95V34.1a4.275 4.275 0 004.275 4.275h20.25zm5.569 1.293a7.875 7.875 0 01-5.569 2.307h-20.25A7.875 7.875 0 017 34.1V21.95a7.875 7.875 0 017.875-7.875h3.304l5.548-5.548a1.8 1.8 0 012.546 0l5.548 5.548h3.304A7.875 7.875 0 0143 21.95V34.1c0 2.089-.83 4.092-2.306 5.568z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M25.691 30.668v-.126c0-.861.399-1.512 1.134-2.037l.861-.609c1.239-.882 2.079-2.058 2.079-3.717 0-2.121-1.701-4.179-4.956-4.179C21.701 20 20 22.184 20 24.452c0 .315.021.651.105.945l2.646.105a2.18 2.18 0 01-.084-.672c0-1.176.651-2.373 2.142-2.373 1.407 0 2.1.924 2.1 1.89 0 .63-.273 1.197-.903 1.659l-1.008.756c-1.197.903-1.659 1.932-1.659 3.045 0 .315.042.588.084.861h2.268zm-2.751 3.045a1.65 1.65 0 001.659 1.659c.924 0 1.68-.735 1.68-1.659s-.756-1.68-1.68-1.68c-.924 0-1.659.756-1.659 1.68z"
    ></path>
  </Icon>
);

export default GeneralActionsTakeTour;
