import { useQuery } from 'react-query';
import { FormOption } from '../../../../../../types/Form';
import { wgApi } from '../../../../../app/src/features/api/wgApi';
import useAuth from '../../../../../app/src/features/authentication/hooks/useAuth';

export interface SupportedInsuranceCompaniesResponse {
  items: FormOption[];
}

const getSupportedInsuranceCompanies = async (
  riskDomain?: string,
  language?: string,
  country?: string,
) => {
  const { data, isError } =
    await wgApi.get<SupportedInsuranceCompaniesResponse>(
      `/v1/api/insurance-terms-bot/supported-insurance-companies`,
      {
        query: {
          risk_domain: riskDomain,
          language,
          country,
        },
      },
    );
  if (isError) throw new Error();
  return data;
};

export const getUseSupportedInsuranceCompaniesQKey = (
  riskDomain?: string,
  language?: string,
  country?: string,
) => [
  'toolkit',
  'insurance-terms-bot',
  'supported-insurance-companies',
  riskDomain,
  language,
  country,
];

export const useSupportedInsuranceCompanies = (riskDomain?: string) => {
  const { user } = useAuth();
  return useQuery(
    getUseSupportedInsuranceCompaniesQKey(
      riskDomain,
      user?.language,
      user?.country_code,
    ),
    () =>
      getSupportedInsuranceCompanies(
        riskDomain,
        user?.language,
        user?.country_code,
      ),
  );
};
