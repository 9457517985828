export interface PreferenceExpressed {
  id: number;
  purposes: {
    [num: number]: boolean;
  };
  timestamp: string;
  version: string;
}

// Deze trackers worden gebruikt voor activiteiten die strikt noodzakelijk zijn om de door u gevraagde dienst uit te voeren of te leveren en daarom is er geen toestemming voor nodig.
export const NECESSARY_COOKIE = 1;
// Met deze trackers zijn basisinteracties en functionaliteiten mogelijk waarmee u toegang hebt tot geselecteerde functies van onze dienst en u met ons kunt communiceren.
export const BASICFUNC_COOKIE = 2;
// Met deze trackers kunnen wij u een gepersonaliseerde gebruikerservaring bieden door uw voorkeursbeheersopties uit te breiden en interactie met externe netwerken en platforms mogelijk te maken.
export const IMPROVEMENTS_COOKIE = 3;
// Met deze trackers kunnen wij bezoeken meten en uw gedrag analyseren om onze diensten te verbeteren.
export const TRACKING_COOKIE = 4;
// Gedragsgerichte targeting en advertenties
export const TARGETING_COOKIE = 5;
