import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationFurniture: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_265_1707"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="356"
        height="228"
      >
        <path d="M0 0H356V228H0V0Z" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_265_1707)">
        <path
          d="M337 161.001C337 249.367 265.366 321.001 177 321.001C88.6344 321.001 17 249.367 17 161.001C17 72.6354 88.6344 1.00098 177 1.00098C265.366 1.00098 337 72.6354 337 161.001Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 226C17 224.895 17.8954 224 19 224H329C330.105 224 331 224.895 331 226C331 227.104 330.105 228 329 228H19C17.8954 228 17 227.104 17 226Z"
          fill={colors.primary[500]}
        />
        <rect
          x="214.064"
          y="29.6997"
          width="14.4683"
          height="59.5752"
          rx="1"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        />
        <rect
          x="214.064"
          y="43.3169"
          width="14.4683"
          height="11.915"
          fill="white"
          stroke={colors.primary[500]}
          strokeWidth="4"
        />
        <rect
          x="243.852"
          y="29.6997"
          width="14.4683"
          height="59.5752"
          rx="1"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        />
        <rect
          x="243.852"
          y="43.3169"
          width="14.4683"
          height="11.915"
          fill="white"
          stroke={colors.primary[500]}
          strokeWidth="4"
        />
        <rect
          x="258.988"
          y="31.4834"
          width="14.4683"
          height="59.5752"
          rx="1"
          transform="rotate(-14 258.988 31.4834)"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        />
        <rect
          x="262.283"
          y="44.696"
          width="14.4683"
          height="11.915"
          transform="rotate(-14 262.283 44.696)"
          fill="white"
          stroke={colors.primary[500]}
          strokeWidth="4"
        />
        <rect
          x="229.383"
          y="37.3594"
          width="14.4683"
          height="59.5752"
          rx="1"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        />
        <rect
          x="229.383"
          y="50.9766"
          width="14.4683"
          height="11.915"
          fill="white"
          stroke={colors.primary[500]}
          strokeWidth="4"
        />
        <mask id="path-12-inside-1_265_1707" fill="white">
          <rect x="203" y="87.5728" width="91.916" height="140.427" rx="2" />
        </mask>
        <rect
          x="203"
          y="87.5728"
          width="91.916"
          height="140.427"
          rx="2"
          fill="white"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-12-inside-1_265_1707)"
        />
        <mask id="path-13-inside-2_265_1707" fill="white">
          <rect
            x="217.468"
            y="106.296"
            width="62.9795"
            height="44.2559"
            rx="2"
          />
        </mask>
        <rect
          x="217.468"
          y="106.296"
          width="62.9795"
          height="44.2559"
          rx="2"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-13-inside-2_265_1707)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M236.192 108.999L261.724 108.999C261.724 116.049 256.009 121.765 248.958 121.765C241.907 121.765 236.192 116.049 236.192 108.999"
          fill="white"
        />
        <path
          d="M261.724 108.999H263.724V106.999L261.724 106.999V108.999ZM236.192 108.999L236.192 106.999H234.192V108.999H236.192ZM261.724 106.999L236.192 106.999L236.192 110.999L261.724 110.999V106.999ZM259.724 108.999C259.724 114.944 254.904 119.765 248.958 119.765V123.765C257.113 123.765 263.724 117.154 263.724 108.999H259.724ZM248.958 119.765C243.012 119.765 238.192 114.944 238.192 108.999H234.192C234.192 117.154 240.803 123.765 248.958 123.765V119.765ZM238.192 108.999V108.999H234.192V108.999H238.192Z"
          fill={colors.primary[500]}
        />
        <mask id="path-16-inside-3_265_1707" fill="white">
          <rect
            x="217.468"
            y="172.68"
            width="62.9795"
            height="44.2559"
            rx="2"
          />
        </mask>
        <rect
          x="217.468"
          y="172.68"
          width="62.9795"
          height="44.2559"
          rx="2"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-16-inside-3_265_1707)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M236.192 175.382C236.192 175.382 236.192 175.382 236.192 175.382C236.192 182.433 241.907 188.148 248.958 188.148C256.009 188.148 261.724 182.433 261.724 175.382L236.192 175.382Z"
          fill="white"
        />
        <path
          d="M236.192 175.382L236.192 173.382L234.192 173.382V175.382H236.192ZM261.724 175.382H263.724V173.382L261.724 173.382V175.382ZM238.192 175.382C238.192 175.382 238.192 175.382 238.192 175.382C238.192 175.382 238.192 175.382 238.192 175.382H234.192C234.192 175.382 234.192 175.382 234.192 175.382C234.192 175.382 234.192 175.382 234.192 175.382H238.192ZM248.958 186.148C243.012 186.148 238.192 181.328 238.192 175.382H234.192C234.192 183.537 240.803 190.148 248.958 190.148V186.148ZM259.724 175.382C259.724 181.328 254.904 186.148 248.958 186.148V190.148C257.113 190.148 263.724 183.537 263.724 175.382H259.724ZM261.724 173.382L236.192 173.382L236.192 177.382L261.724 177.382V173.382Z"
          fill={colors.primary[500]}
        />
        <mask id="path-19-inside-4_265_1707" fill="white">
          <rect
            x="101.974"
            y="84.3494"
            width="94.0259"
            height="143.651"
            rx="2"
          />
        </mask>
        <rect
          x="101.974"
          y="84.3494"
          width="94.0259"
          height="143.651"
          rx="2"
          fill="white"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-19-inside-4_265_1707)"
        />
        <mask id="path-20-inside-5_265_1707" fill="white">
          <rect
            x="116.774"
            y="103.503"
            width="64.4251"
            height="45.2717"
            rx="2"
          />
        </mask>
        <rect
          x="116.774"
          y="103.503"
          width="64.4251"
          height="45.2717"
          rx="2"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-20-inside-5_265_1707)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.928 106.244C135.928 113.456 141.775 119.303 148.987 119.303C156.199 119.303 162.046 113.456 162.046 106.244L135.928 106.244Z"
          fill="white"
        />
        <path
          d="M135.928 106.244L135.928 104.244L133.928 104.244L133.928 106.244L135.928 106.244ZM162.046 106.244H164.046V104.244L162.046 104.244V106.244ZM148.987 117.303C142.879 117.303 137.928 112.352 137.928 106.244L133.928 106.244C133.928 114.561 140.67 121.303 148.987 121.303V117.303ZM160.046 106.244C160.046 112.352 155.095 117.303 148.987 117.303V121.303C157.304 121.303 164.046 114.561 164.046 106.244H160.046ZM162.046 104.244L135.928 104.244L135.928 108.244L162.046 108.244V104.244Z"
          fill={colors.primary[500]}
        />
        <mask id="path-23-inside-6_265_1707" fill="white">
          <rect
            x="116.774"
            y="171.41"
            width="64.4251"
            height="45.2717"
            rx="2"
          />
        </mask>
        <rect
          x="116.774"
          y="171.41"
          width="64.4251"
          height="45.2717"
          rx="2"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-23-inside-6_265_1707)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.928 174.152C135.928 181.364 141.775 187.211 148.987 187.211C156.199 187.211 162.046 181.364 162.046 174.152L135.928 174.152Z"
          fill="white"
        />
        <path
          d="M135.928 174.152L135.928 172.152L133.928 172.152L133.928 174.152L135.928 174.152ZM162.046 174.152H164.046V172.152L162.046 172.152V174.152ZM148.987 185.211C142.879 185.211 137.928 180.259 137.928 174.152L133.928 174.152C133.928 182.469 140.67 189.211 148.987 189.211V185.211ZM160.046 174.152C160.046 180.259 155.095 185.211 148.987 185.211V189.211C157.304 189.211 164.046 182.469 164.046 174.152H160.046ZM162.046 172.152L135.928 172.152L135.928 176.152L162.046 176.152V172.152Z"
          fill={colors.primary[500]}
        />
        <rect
          x="130.674"
          y="75.2869"
          width="32.2302"
          height="9.43324"
          fill="white"
          stroke={colors.primary[500]}
          strokeWidth="4"
        />
        <path
          d="M165.263 57.5995C165.263 67.8189 162.511 75.2868 162.511 75.2868H131.46C131.46 75.2868 127.53 68.998 127.53 57.5995C127.53 55.2412 129.67 55.2412 131.46 55.2412H161.332C163.122 55.2412 165.263 55.9463 165.263 57.5995Z"
          fill="white"
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M144.907 8.16261L132.969 53.5939C132.803 54.2279 133.281 54.8481 133.936 54.8481H160.41C161.071 54.8481 161.551 54.2179 161.374 53.5808L148.769 8.13634C148.223 6.16859 145.426 6.18762 144.907 8.16261Z"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M127.043 16.0027L132.516 53.9907C132.587 54.4828 133.008 54.8481 133.506 54.8481H159.691C160.521 54.8481 160.99 53.8949 160.483 53.2374L130.606 14.4962C129.35 12.8676 126.75 13.9672 127.043 16.0027Z"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M166.143 16.0027L160.67 53.9907C160.599 54.4828 160.177 54.8481 159.68 54.8481H133.494C132.664 54.8481 132.195 53.8949 132.702 53.2374L162.579 14.4962C163.835 12.8676 166.436 13.9672 166.143 16.0027Z"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M168.591 36.6732L160.81 54.2527C160.65 54.6146 160.291 54.848 159.895 54.848H135.051C134.038 54.848 133.667 53.5155 134.534 52.992L165.728 34.1517C167.402 33.1408 169.382 34.8852 168.591 36.6732Z"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M124.595 36.6732L132.376 54.2527C132.536 54.6146 132.895 54.848 133.29 54.848H158.135C159.148 54.848 159.519 53.5155 158.652 52.992L127.458 34.1517C125.784 33.1408 123.803 34.8852 124.595 36.6732Z"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M48.4436 203.436H87.7498V216C87.7498 221.523 83.2727 226 77.7498 226H58.4436C52.9208 226 48.4436 221.523 48.4436 216V203.436Z"
          fill="white"
          stroke={colors.primary[500]}
          strokeWidth="4"
        />
        <path
          d="M43.3484 197.976C43.3484 194.961 45.7926 192.517 48.8076 192.517H87.3859C90.4009 192.517 92.8451 194.961 92.8451 197.976C92.8451 200.991 90.4009 203.435 87.3859 203.435H48.8076C45.7926 203.435 43.3484 200.991 43.3484 197.976Z"
          fill="white"
          stroke={colors.primary[500]}
          strokeWidth="4"
        />
        <path
          d="M51.3552 125.551C54.7521 126.157 61.5457 129.481 61.5457 137.925"
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M77.9234 131.01C68.8247 138.653 62.2737 145.568 62.2737 159.034"
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M64.4573 192.881C64.4573 192.881 61.4606 156.309 61.5457 132.83C61.6009 117.611 63.0015 93.8875 63.0015 93.8875"
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M33.1579 131.01C42.0699 132 50.0188 129.19 51.3552 125.551C52.959 121.183 41.8926 112.449 35.3416 110.629C28.7044 108.785 20.9019 110.57 18.964 117.18C16.7868 124.607 23.3315 129.918 33.1579 131.01Z"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M43.3601 98.6584C51.1473 103.104 59.5585 103.679 62.2295 100.869C65.4348 97.4967 58.7435 85.0876 53.4526 80.8176C48.092 76.4913 40.2221 75.0328 35.8201 80.3312C30.8744 86.284 34.7738 93.7566 43.3601 98.6584Z"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M83.1131 99.0048C75.3259 103.45 66.9146 104.026 64.2436 101.215C61.0384 97.8432 67.7297 85.434 73.0206 81.164C78.3811 76.8377 86.251 75.3792 90.6531 80.6777C95.5987 86.6305 91.6994 94.103 83.1131 99.0048Z"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
        <path
          d="M90.6434 117.017C82.4303 120.616 76.9514 127.024 77.6137 130.844C78.4085 135.428 92.3596 137.459 98.9428 135.76C105.613 134.038 111.478 128.591 109.851 121.897C108.023 114.377 99.6993 113.05 90.6434 117.017Z"
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationFurniture;
