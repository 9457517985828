import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleReplacement: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M10 10v12a1 1 0 001 1h14a1 1 0 001-1V10a1 1 0 00-1-1H11a1 1 0 00-1 1zm12 6h-8a1 1 0 010-2h8a1 1 0 010 2zM18.57 31.19L21 32v3l-2.43.81a.2.2 0 000 .38L21 37v4a3 3 0 01-6 0V24h6v6l-2.43.81a.2.2 0 000 .38z"
    ></path>
    <path
      fill="currentColor"
      d="M42.4 35.81L28.64 42a1 1 0 01-1.35-.51L23 31.63V25h2a3 3 0 003-3V10a3 3 0 00-3-3h-4.1l.5-.25A8.46 8.46 0 0124.92 6a8.57 8.57 0 017.82 5l10.17 23.45a.999.999 0 01-.51 1.36z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleReplacement;
