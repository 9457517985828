const darkRed = {
  primary: {
    main: '#FA4A43',
    50: '#FFF6F6',
    100: '#FEE3E2',
    150: '#FED0CE',
    200: '#FDBDBA',
    300: '#FC9792',
    400: '#FB706B',
    500: '#FA4A43',
    600: '#F81107',
    700: '#BE0C05',
    750: '#A00A04',
    800: '#820803',
    850: '#640603',
    900: '#460402',
  },
  secondary: {
    main: '#FA6864',
    50: '#FEEAE9',
    100: '#FEDCDB',
    150: '#FDCDCC',
    200: '#FDBFBD',
    300: '#FCA29F',
    400: '#FB8582',
    500: '#FA6864',
    600: '#F82E29',
    700: '#DC0D07',
    750: '#BF0B06',
    800: '#A10905',
    850: '#840804',
    900: '#660603',
  },
};

export default darkRed;
