const pinkPurple = {
  primary: {
    main: '#D61D70',
    50: '#FDF2F7',
    100: '#FAD9E8',
    150: '#F7C1D9',
    200: '#F3A8CA',
    300: '#ED77AC',
    400: '#E6458D',
    500: '#D61D70',
    600: '#AE185B',
    700: '#851246',
    750: '#710F3B',
    800: '#5D0D31',
    850: '#490A26',
    900: '#34071B',
  },
  secondary: {
    main: '#FF1F76',
    50: '#FFEEF4',
    100: '#FFD7E6',
    150: '#FFC0D8',
    200: '#FFA9CA',
    300: '#FF7BAE',
    400: '#FF4D92',
    500: '#FF1F76',
    600: '#E60059',
    700: '#AE0044',
    750: '#920039',
    800: '#76002E',
    850: '#5A0023',
    900: '#3E0018',
  },
};

export default pinkPurple;
