import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationKarting: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3884_11417"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3884_11417)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M275.299 181.544l-11 14.144a3.999 3.999 0 00-.137 4.725l11.626 16.875a4 4 0 005.479 1.082l17.918-11.686a4.002 4.002 0 001.815-3.351V184a4 4 0 00-4-4h-18.544a4 4 0 00-3.157 1.544z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M130.5 195.5L173 153M165 144.5l17 17M159 167.5l16.5 24"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M102.696 219.319l45.214-63.533c2.535-3.562-1.664-8.026-5.375-5.714l-82.262 51.265a4 4 0 00-.713 6.223l12.268 12.268A3.997 3.997 0 0074.657 221h24.78a3.999 3.999 0 003.259-1.681zM214.4 179.194L131.5 206l10.299 13.434a4 4 0 003.175 1.566h79.945c1.571 0 2.997-.92 3.645-2.351L246.5 179h-30.869a3.99 3.99 0 00-1.231.194z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M244 116v63h-19v-44c0-10 5.5-19 19-19z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M269 146.5l-25-7v39l25-7.5v-24.5z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M269.5 150h11a9 9 0 019 9 9 9 0 01-9 9h-11v-18z"
        ></path>
        <circle
          cx="121.5"
          cy="205"
          r="19"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="121.5"
          cy="205"
          r="7"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="252"
          cy="199.5"
          r="23.5"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="252"
          cy="199.5"
          r="8.5"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
      </g>
    </svg>
  );
};

export default IllustrationKarting;
