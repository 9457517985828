const darkBlue = {
  primary: {
    main: '#3153B4',
    50: '#D7DEF4',
    100: '#C2CEEF',
    150: '#AEBEE9',
    200: '#9AADE4',
    300: '#728DD9',
    400: '#4A6CCE',
    500: '#3153B4',
    600: '#253F88',
    700: '#192A5C',
    750: '#132046',
    800: '#0D1630',
    850: '#070C1A',
    900: '#010204',
  },
  secondary: {
    main: '#3153B4',
    50: '#FBFBFE',
    100: '#E3E8F7',
    150: '#CAD4F1',
    200: '#B2C1EA',
    300: '#829ADD',
    400: '#5273D0',
    500: '#3153B4',
    600: '#284494',
    700: '#203574',
    750: '#1B2E64',
    800: '#172754',
    850: '#121F44',
    900: '#0E1834',
  },
};

export default darkBlue;
