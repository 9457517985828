import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsLegalWaitPolicy: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 34v-6h-4v6H8v4h6v6h4v-6h6v-4h-6z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M28.89 16c-1.058 0-1.89.905-1.89 1.99v6.764c0 .744.395 1.433 1.035 1.775l4.22 2.255c.942.503 2.08.094 2.545-.885.462-.972.099-2.165-.835-2.664l-3.185-1.702V17.99c0-1.085-.832-1.99-1.89-1.99z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M41 23c0 6.627-5.373 12-12 12-.681 0-1.35-.057-2-.166v4.042c.655.082 1.323.124 2 .124 8.837 0 16-7.163 16-16S37.837 7 29 7s-16 7.163-16 16c0 .677.042 1.345.124 2h4.042c-.11-.65-.166-1.319-.166-2 0-6.627 5.373-12 12-12s12 5.373 12 12z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskObjectsLegalWaitPolicy;
