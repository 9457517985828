import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyGuests: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M20 24h-2v-5a1 1 0 00-1-1h-5a1 1 0 00-1 1v5H9a1 1 0 00-1 1v17a1 1 0 001 1h1v1.5a1.5 1.5 0 103 0V43h3v1.5a1.5 1.5 0 103 0V43h1a1 1 0 001-1V25a1 1 0 00-1-1zm-7 0v-3.5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5V24h-3zM33 12a4 4 0 100-8 4 4 0 000 8zM37 13h-8a5 5 0 00-5 5v9.88A2.08 2.08 0 0025.84 30a1.91 1.91 0 001.16-.28v13.64A2.6 2.6 0 0029.24 46 2.502 2.502 0 0032 43.5V33a1 1 0 012 0v10.36A2.6 2.6 0 0036.24 46 2.502 2.502 0 0039 43.5V29.72a1.91 1.91 0 001.16.27A2.08 2.08 0 0042 27.88V18a5 5 0 00-5-5z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyGuests;
