import React from 'react';
import {
  Input,
  InputGroup,
  CountrySelectWithFlag,
} from '@wegroup/design-system';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useLocale } from '../../../../../../i18n';
import {
  CompanyRegistration,
  CompanyRegistrationType,
} from '../../../../../parties/src/types';

interface Props {
  value?: CompanyRegistration;
  onChange: (value: CompanyRegistration) => void;
  defaultCountry?: string;
}

export const companyNumberFormats: Record<
  CompanyRegistrationType,
  { mask: string; country: string; regex: string }
> = {
  KVK: {
    mask: '########',
    country: 'NL',
    regex: '^\\d{8}$',
  },
  CBE: {
    mask: '####.###.###',
    country: 'BE',
    regex: '^\\d{4}\\.\\d{3}\\.\\d{3}$',
  },
  KVK_AW: {
    mask: 'H#####.#',
    country: 'AW',
    regex: '^H\\d{5}\\.\\d$',
  },
};

export const checkCompanyNumberFormat = (inputNumber: string) => {
  for (const format of Object.keys(
    companyNumberFormats,
  ) as CompanyRegistrationType[]) {
    const regex = companyNumberFormats[format].regex;
    if (inputNumber.match(regex)) {
      return format;
    }
  }
};

export const getTypeForCountry = (
  country: string,
): CompanyRegistrationType | undefined =>
  Object.entries(companyNumberFormats).find(
    ([, { country: c }]) => c === country,
  )?.[0] as CompanyRegistrationType;

const CompanyNumberInput: React.FC<React.PropsWithChildren<Props>> = ({
  value,
  onChange,
  defaultCountry,
}) => {
  const { userCountry } = useLocale();

  const handleTypeChange = (type: CompanyRegistrationType) => {
    if (type) {
      onChange({ ...value, type });
    }
  };

  return (
    <InputGroup>
      <CompanyNumberInputMenu
        value={value?.type}
        onChange={handleTypeChange}
        defaultCountry={defaultCountry}
      />
      <Input
        as={NumberFormat}
        format={companyNumberFormats[value?.type || '']?.mask}
        value={value?.nr}
        onValueChange={(values: NumberFormatValues) => {
          const { formattedValue } = values;
          if (value?.type) {
            onChange?.({ ...value, nr: formattedValue.replaceAll('.', '') });
          } else {
            onChange?.({
              type: getTypeForCountry(defaultCountry || userCountry),
              nr: formattedValue.replaceAll('.', ''),
            });
          }
        }}
        borderLeftRadius="none"
        allowEmptyFormatting
      />
    </InputGroup>
  );
};

interface MenuProps {
  value?: CompanyRegistrationType;
  onChange: (type: CompanyRegistrationType) => void;
  defaultCountry?: string;
}

export const CompanyNumberInputMenu: React.FC<
  React.PropsWithChildren<MenuProps>
> = ({ value, onChange, defaultCountry }) => {
  const { userCountry, userLang } = useLocale();
  const country =
    companyNumberFormats[value || '']?.country || defaultCountry || userCountry;

  const handleOnChange = (country: string) => {
    const type = getTypeForCountry(country);
    if (type) {
      onChange(type as CompanyRegistrationType);
    }
  };

  return (
    <CountrySelectWithFlag
      lang={userLang}
      value={country}
      onChange={handleOnChange}
      supportedCountries={Object.values(companyNumberFormats).map(
        ({ country }) => country,
      )}
      onlyFlag
      isAttached
    />
  );
};

export default CompanyNumberInput;
