import {
  Stack,
  HStack,
  Image,
  VStack,
  Text,
  IconButton,
  Icon,
  Skeleton,
} from '@wegroup/design-system';
import React from 'react';
import { Distribution } from '../../../../../../../app/src/features/authentication/types/Distribution';
import StrokeBox from '../../../../../../../dashboard/src/components/common/StrokeBox';

interface Props {
  isLoading: boolean;
  distribution?: Distribution;
}

const BrokerContactInfoCard: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading,
  distribution,
}) => {
  return (
    <StrokeBox
      as={Stack}
      boxShadow="none"
      spacing="4"
      direction={{ base: 'column', laptop: 'row' }}
    >
      <HStack spacing="4" flexGrow={1}>
        {distribution?.logos.logo && (
          <Image
            src={distribution.logos.logo}
            objectFit="contain"
            h="12"
            w="12"
          />
        )}
        <VStack flexGrow={1} align="stretch">
          {isLoading ? (
            <Skeleton h="4" />
          ) : (
            <Text textStyle="normalBodyBold">{distribution?.name}</Text>
          )}
          {isLoading ? (
            <Skeleton h="4" />
          ) : (
            <Text textStyle="smallBody" color="gray.300">
              {distribution?.website}
            </Text>
          )}
        </VStack>
      </HStack>
      <HStack spacing="1">
        {distribution?.email && (
          <IconButton
            as="a"
            icon={<Icon name="GeneralOthersEnvelopeMail" />}
            aria-label="send-mail"
            href={`mailto:${distribution.email}`}
            borderRadius="full"
            variant="secondary"
          />
        )}

        {distribution?.phone && (
          <IconButton
            as="a"
            icon={<Icon name="GeneralOthersPhone" />}
            aria-label="send-mail"
            href={`tel:${distribution.phone}`}
            borderRadius="full"
            variant="secondary"
          />
        )}
      </HStack>
    </StrokeBox>
  );
};

export default BrokerContactInfoCard;
