import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsArrowLeft: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M39 25a2 2 0 01-2 2H13a2 2 0 110-4h24a2 2 0 012 2z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.414 38.414a2 2 0 01-2.828 0l-12-12a2 2 0 010-2.828l12-12a2 2 0 112.828 2.828L15.828 25l10.586 10.586a2 2 0 010 2.828z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsArrowLeft;
