import React from 'react';
import {
  HStack,
  Tooltip,
  Icon,
  IconButton,
  Textarea,
  useClipboard,
  Box,
} from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import { chakraCustomScrollBar } from '../../../../../common/src/utils/styledComponentsUtils';

interface Props {
  onCopy?: () => void;
  height?: string;
  children?: string;
}

const ClipboardTextArea: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onCopy = () => null,
  height = 'unset',
}) => {
  const { t } = useTranslation();
  const { onCopy: onCopyClipboard, hasCopied } = useClipboard(
    children?.toString() || '',
  );
  return (
    <HStack
      bg="white"
      border="1px solid"
      borderColor="gray.100"
      p="2"
      spacing="2"
      borderRadius="md"
      pos="relative"
    >
      <Textarea
        sx={chakraCustomScrollBar}
        fontFamily="Courier"
        fontSize="sm"
        overflow="auto"
        readOnly
        border="none"
        resize="none"
        height={height}
        value={children}
      />
      <Tooltip placement="left" label={t('Copy to clipboard')}>
        <IconButton
          zIndex="1"
          position="absolute"
          top="2"
          right="2"
          alignSelf="flex-start"
          variant="grayed"
          aria-label="Copy to clipboard"
          onClick={() => {
            onCopy();
            onCopyClipboard();
          }}
          {...(hasCopied && {
            bg: 'success.main',
            color: 'white',
            _hover: { bg: 'success.main', color: 'white' },
          })}
          _focus={{ boxShadow: '0' }}
          icon={
            <Box ml={hasCopied ? '-2px' : '0'}>
              <Icon
                name={hasCopied ? 'IconStatusCheck' : 'GeneralActionsDuplicate'}
                color="currentcolor"
              />
            </Box>
          }
        />
      </Tooltip>
    </HStack>
  );
};

export default ClipboardTextArea;
