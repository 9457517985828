import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersCalculator: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13 9a2 2 0 00-2 2v28a2 2 0 002 2h24a2 2 0 002-2V11a2 2 0 00-2-2H13zm-6 2a6 6 0 016-6h24a6 6 0 016 6v28a6 6 0 01-6 6H13a6 6 0 01-6-6V11z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 17a4 4 0 014-4h12a4 4 0 014 4v2a4 4 0 01-4 4H19a4 4 0 01-4-4v-2zm16 0H19v2h12v-2zM17 27a2 2 0 012 2v.02a2 2 0 11-4 0V29a2 2 0 012-2zM25 27a2 2 0 012 2v.02a2 2 0 11-4 0V29a2 2 0 012-2zM33 27a2 2 0 012 2v.02a2 2 0 11-4 0V29a2 2 0 012-2zM17 33a2 2 0 012 2v.02a2 2 0 11-4 0V35a2 2 0 012-2zM25 33a2 2 0 012 2v.02a2 2 0 11-4 0V35a2 2 0 012-2zM33 33a2 2 0 012 2v.02a2 2 0 11-4 0V35a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersCalculator;
