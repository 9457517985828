import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersStar: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 7.784a2 2 0 011.793 1.115l4.48 9.074 10.016 1.464a2 2 0 011.106 3.412l-7.246 7.058 1.71 9.97a2 2 0 01-2.902 2.11L25 37.275l-8.957 4.71a2 2 0 01-2.902-2.108l1.71-9.971-7.246-7.058a2 2 0 011.106-3.412l10.016-1.464 4.48-9.074A2 2 0 0125 7.784zm0 6.519l-3.15 6.382a2 2 0 01-1.505 1.094l-7.048 1.03 5.098 4.966a2 2 0 01.576 1.771l-1.203 7.013 6.301-3.313a2 2 0 011.862 0l6.3 3.313-1.202-7.013a2 2 0 01.576-1.77l5.098-4.967-7.048-1.03a2 2 0 01-1.504-1.094L25 14.303z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersStar;
