import React from 'react';
import {
  HStack,
  Icon,
  Box,
  ColorStatus,
  Tooltip,
  SimpleGrid,
} from '@wegroup/design-system';
import LabeledParam from '../../../../../common/src/components/LabeledParam';
import { useTranslation } from 'react-i18next';
import { Offer } from '../../../../../../types/Offer';
import { toEuro } from '../../../../../sales-conversation/src/utils/stringUtils';
import OfferCardBodyDocuments from './OfferCardBodyDocuments';
import convertKeyToIconName from '../../../../../common/src/utils/convertKeyToIconName';
import { formatDate } from '../../../../../common/src/utils/dateUtils';

const OFFER_STATUS_COLOR = {
  PENDING: 'warning.main',
  SIGNED: 'success.main',
  SEND: 'success.main',
  REJECTED: 'danger.main',
  ACCEPTED: 'success.main',
  CANCELLED: 'gray.main',
  EXPIRED: 'danger.main',
};

interface Props {
  offer: Offer;
}

const OfferCardBody: React.FC<Props> = ({ offer }) => {
  const { t } = useTranslation();

  const flattenedDocument = Object.values(offer.documents || {}).flat();

  return (
    <Box>
      <SimpleGrid mb="4" columns={2} gridGap="4">
        <LabeledParam label={t('Price (annually)')}>
          {toEuro(offer.premium.total_premium)}
        </LabeledParam>
        <LabeledParam label={t('Status')}>
          <ColorStatus color={OFFER_STATUS_COLOR[offer.status]}>
            {t(`OFFER_STATUS_${offer.status}`)}
          </ColorStatus>
        </LabeledParam>
        <LabeledParam label={t('CREATED-ON')}>
          {formatDate(offer.created_at)}
        </LabeledParam>
        <LabeledParam label={t('COVERAGES')}>
          <HStack>
            {offer.guarantees.map((guarantee) => (
              <Tooltip
                key={guarantee.id}
                label={t(`anon_pack_types.${guarantee.name}`)}
                shouldWrapChildren
              >
                <Icon name={convertKeyToIconName(guarantee.name)} />
              </Tooltip>
            ))}
          </HStack>
        </LabeledParam>
      </SimpleGrid>
      {!!flattenedDocument.length && (
        <OfferCardBodyDocuments documents={flattenedDocument} />
      )}
    </Box>
  );
};

export default OfferCardBody;
