import i18n from '../../../../i18n';
import { Address } from '../../../../types/Common';

export const formatAddress = (address: Address): string => {
  if (address == null) return '-';
  const { street, city, housenr, zipcode, boxnr } = address;
  if (
    street == null &&
    city == null &&
    housenr == null &&
    zipcode == null &&
    boxnr == null
  )
    return '-';

  const addressString = `${street || ''} ${housenr || ''}${
    boxnr
      ? ` ${i18n.t('AdresInput.manual.boxnr')?.toLowerCase()} ${boxnr || ''}`
      : ''
  }, ${zipcode || ''} ${city || ''}`;

  const trimmedAddress = addressString.replace(/\s,/g, ',');

  return trimmedAddress;
};

// Shamelessly stolen from https://stackoverflow.com/a/18650828
export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
export const sha256 = async (message: string): Promise<string> => {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
};

// https://dev.to/jorik/country-code-to-flag-emoji-a21
export const getFlagEmojiByCountryCode = (countryCode: string): string => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export const mod = (n: number, m: number): number => {
  return ((n % m) + m) % m;
};

/**
 * Compare 2 values. If the value is `null`, `undefined`, `''` or `NaN` they are seen as equal.
 */
export const isEqualDefined = (a: unknown, b: unknown): boolean => {
  const _a = a == null || a === '' ? null : a;
  const _b = b == null || b === '' ? null : b;

  return JSON.stringify(_a) === JSON.stringify(_b);
};

export const removeNullishFromObject = <Type extends Record<string, unknown>>(
  obj: Type,
): Partial<Type> => {
  Object.keys({ ...obj }).map((key) =>
    obj[key] === undefined || obj[key] === null ? delete obj[key] : obj[key],
  );
  return obj;
};

export const makeUrlSafe = (string?: string) =>
  string
    ?.replace(/[^a-zA-Z0-9\s\-_]/g, '')
    .replace(/\s/g, '-')
    .toLowerCase();
