import { Button, Icon } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onReset: () => void;
}

const LouiseChatResetButton: React.FC<Props> = ({ onReset }) => {
  const { t } = useTranslation();

  return (
    <Button
      onClick={onReset}
      size="sm"
      leftIcon={<Icon name="GeneralActionsReset" />}
    >
      {t('TOOLKIT_INSURANCE-TERMS-BOT_ACTIONS_ASK-NEW-QUESTION')}
    </Button>
  );
};

export default LouiseChatResetButton;
