import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyBathroom: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M43.45 32H5.54s-1 12 6.82 12h24a6.65 6.65 0 005.14-2.35 14.22 14.22 0 003-8.59.999.999 0 00-1.05-1.06zM43.55 29.1h-31V11.23a3.19 3.19 0 01.9-2.41A4.08 4.08 0 0116.24 8c2.14 0 3.17.66 3.66 1.32a.59.59 0 01-.26.89 3.84 3.84 0 00-1.77 1.86c-.19.44.18.9.74.9h6.61c.54 0 .93-.45.75-.88a4.11 4.11 0 00-3-2.31.68.68 0 01-.51-.43C21.91 7.79 20.2 6 16.24 6a6.44 6.44 0 00-4.53 1.49 5 5 0 00-1.5 3.85l.2 17.76h-4a.95.95 0 000 1.9h37.1a.95.95 0 000-1.9h.04z"
    ></path>
    <path
      fill="currentColor"
      d="M19.11 16.16c.613 0 1.11-.483 1.11-1.08 0-.597-.497-1.08-1.11-1.08-.613 0-1.11.483-1.11 1.08 0 .597.497 1.08 1.11 1.08zM21.34 18.33c.613 0 1.11-.483 1.11-1.08 0-.596-.497-1.08-1.11-1.08-.613 0-1.11.484-1.11 1.08 0 .596.497 1.08 1.11 1.08zM23.57 16.16c.613 0 1.11-.483 1.11-1.08 0-.597-.497-1.08-1.11-1.08-.613 0-1.11.483-1.11 1.08 0 .597.497 1.08 1.11 1.08zM23.57 20.5c.613 0 1.11-.484 1.11-1.08 0-.597-.497-1.08-1.11-1.08-.613 0-1.11.483-1.11 1.08 0 .596.497 1.08 1.11 1.08zM25.8 18.33c.613 0 1.11-.483 1.11-1.08 0-.596-.497-1.08-1.11-1.08-.613 0-1.11.484-1.11 1.08 0 .596.497 1.08 1.11 1.08z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyBathroom;
