import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationRiskAnalysis: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.988 28.983a31.001 31.001 0 0 1-3.9-12.862L25 10.174l12.911 5.947a31.03 31.03 0 0 1-.413 3.21c1.905 1.366 2.903 2.405 3.42 3.08a34.998 34.998 0 0 0 1.04-6.901c.057-1.164-.635-2.222-1.694-2.71L26.255 6.349a3 3 0 0 0-2.51 0l-14.01 6.453c-1.058.487-1.75 1.545-1.692 2.709a35 35 0 0 0 15.439 27.316 2.72 2.72 0 0 0 3.036 0 35.014 35.014 0 0 0 4.174-3.29c-1.552-.301-3.051-.786-4.356-1.532A31.02 31.02 0 0 1 25 39.012a31 31 0 0 1-9.012-10.029Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.704 40.435a1.913 1.913 0 0 0 0-2.706l-4.33-4.33a8.306 8.306 0 1 0-2.706 2.706l4.33 4.33a1.913 1.913 0 0 0 2.706 0ZM27.826 29.037a4.48 4.48 0 1 1 8.959 0 4.48 4.48 0 0 1-8.959 0Z"
      fill="currentColor"
    />
  </Icon>
);

export default GeneralNavigationRiskAnalysis;
