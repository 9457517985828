import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsAssemblyTasting: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 43a2 2 0 012-2h36a2 2 0 110 4H7a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.134 17.197a2 2 0 012.115.241l6.751 5.4V19a2 2 0 013.25-1.562L31.701 25H39a2 2 0 110 4h-8a2 2 0 01-1.25-.438l-6.75-5.4V27a2 2 0 01-3.25 1.562l-6.75-5.4V43a2 2 0 11-4 0V19a2 2 0 011.134-1.803z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M30.909 5.71a3 3 0 011.94-.71h.002l-.003 2V5h2.288l.002 2V5a3 3 0 012.968 2.555l-1.978.297 1.978-.296 2.872 19.147c.015.099.022.198.022.297v16a2 2 0 11-4 0V27.15l.022.147L39 27h-2v.15L34.278 9h.86a1 1 0 01-.988-.851l.128.851h-.584l.139-.835a1 1 0 01-.985.835h-.003.849l-2.721 16.329a2 2 0 11-3.946-.658l2.86-17.163 1.973.328-1.973-.329A3 3 0 0130.91 5.71zM17 35a2 2 0 012-2h2a2 2 0 110 4h-2a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M27 35a2 2 0 012-2h2a2 2 0 110 4h-2a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsAssemblyTasting;
