import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationResidenceCountryHouse: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M92 100.571h148.823v38.113H92v-38.113Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M153.706 225.799H95.629v-87.115l29.039-25.409 29.038 25.409v87.115Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M153.706 138.684h83.485v87.117h-83.485v-87.117Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M225.506 216.726H198.07a2.614 2.614 0 0 1-2.613-2.613v-26.427h32.662v26.427a2.614 2.614 0 0 1-2.613 2.613Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M204.523 187.686h14.521v29.038h-14.521v-29.038Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M228.926 186.176a.97.97 0 0 1-.807 1.51h-74.405v-10.891h67.339a3.023 3.023 0 0 1 2.519 1.346l5.354 8.035Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M180.931 225.799h-27.217v-30.852h24.612a2.612 2.612 0 0 1 2.613 2.613l-.008 28.239Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M173.669 225.799h-12.704v-20.98a2.614 2.614 0 0 1 2.613-2.613h7.478a2.614 2.614 0 0 1 2.613 2.613v20.98ZM140.205 216.726h-31.074a2.614 2.614 0 0 1-2.613-2.613v-26.427h36.3v26.427a2.614 2.614 0 0 1-2.613 2.613Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M117.409 187.686h14.52v29.038h-14.52v-29.038Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M105.71 186.175a.971.971 0 0 0 .808 1.51h36.3a.968.968 0 0 0 .968-1.018.969.969 0 0 0-.161-.492l-5.354-8.032a3.03 3.03 0 0 0-2.519-1.346h-22.171a3.022 3.022 0 0 0-2.517 1.346l-5.354 8.032Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M117.409 155.018h14.52v21.779h-14.52v-21.779Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M114.383 147.759h20.569v7.259h-20.569v-7.259ZM163.387 147.759h20.569v7.259h-20.569v-7.259Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M166.411 155.018h14.52v21.779h-14.52v-21.779Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M199.685 147.759h20.569v7.259h-20.569v-7.259Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M202.71 155.018h14.52v21.779h-14.52v-21.779Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m217.575 112.395-7.606-4.565-7.606 4.565a3.019 3.019 0 0 0-1.469 2.592v14.633h18.145v-14.633a3.024 3.024 0 0 0-1.464-2.592Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M213.599 129.61h-7.259v-9.075a3.63 3.63 0 1 1 7.259 0v9.075Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M224.489 86.05h10.889v20.978a2.615 2.615 0 0 1-2.613 2.613h-5.663a2.611 2.611 0 0 1-2.613-2.613V86.051Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M235.378 80.001h-10.889a3.03 3.03 0 0 0-2.876 1.846 3.037 3.037 0 0 0 0 2.357 3.038 3.038 0 0 0 1.691 1.642 3.03 3.03 0 0 0 1.185.204h10.889a3.03 3.03 0 0 0 2.875-1.846 3.02 3.02 0 0 0-.679-3.349 3.02 3.02 0 0 0-2.196-.854Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m290.94 225-.021-.042-.043.042h-56.318a7.559 7.559 0 0 1 .791-7.161 7.758 7.758 0 0 1 2.815-2.473 7.89 7.89 0 0 1 3.664-.88 7.634 7.634 0 0 1 1.759.346 16.15 16.15 0 0 1-.193-1.887 16.766 16.766 0 0 1 3.842-10.679 17.253 17.253 0 0 1 9.824-5.904c3.866-.8 7.894-.265 11.408 1.513a17.05 17.05 0 0 1 7.885 8.253 12.588 12.588 0 0 1 11.073.879 12.301 12.301 0 0 1 4.071 3.937 12.09 12.09 0 0 1 1.85 5.314 7.747 7.747 0 0 1 4.825-1.785c2.075 0 4.066.811 5.534 2.255a7.639 7.639 0 0 1 2.294 5.444 7.495 7.495 0 0 1-.558 2.815L290.94 225Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m234.267 225.426.013-.026.026.026h34.309a4.747 4.747 0 0 0-4.429-6.509 4.645 4.645 0 0 0-1.071.214c.061-.386.101-.776.117-1.168a10.478 10.478 0 0 0-8.325-10.265 10.482 10.482 0 0 0-11.753 6.045 7.56 7.56 0 0 0-10.353 6.272 4.674 4.674 0 0 0-2.94-1.106 4.77 4.77 0 0 0-4.768 4.766c.001.598.117 1.189.339 1.743l8.835.008ZM107.94 225l-.021-.042-.043.042H51.558a7.553 7.553 0 0 1 .791-7.161 7.75 7.75 0 0 1 2.815-2.473 7.89 7.89 0 0 1 3.664-.88 7.632 7.632 0 0 1 1.759.346 16.064 16.064 0 0 1-.193-1.887 16.766 16.766 0 0 1 3.842-10.679 17.256 17.256 0 0 1 9.824-5.904c3.866-.8 7.894-.265 11.408 1.513a17.045 17.045 0 0 1 7.885 8.253 12.59 12.59 0 0 1 11.073.879 12.301 12.301 0 0 1 4.071 3.937 12.09 12.09 0 0 1 1.85 5.314 7.747 7.747 0 0 1 4.825-1.785c2.075 0 4.066.811 5.534 2.255a7.639 7.639 0 0 1 2.294 5.444 7.495 7.495 0 0 1-.558 2.815L107.94 225Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.096 223.652v-16.156"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m69.818 139.598.003-.009.002-.008a.29.29 0 0 1 .277-.209.286.286 0 0 1 .277.209l.002.008.003.008c2.2 7.461 5.67 19.415 8.587 30.349 1.46 5.469 2.776 10.667 3.727 14.912.97 4.33 1.504 7.448 1.504 8.894a14.095 14.095 0 0 1-8.704 13.026 14.093 14.093 0 0 1-15.366-3.056 14.096 14.096 0 0 1-4.13-9.97c0-1.446.535-4.564 1.504-8.894.95-4.246 2.266-9.443 3.725-14.912 2.918-10.934 6.387-22.888 8.59-30.348Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M293.345 225.024v-12.55"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M286.889 183.42c2.174-8.151 4.744-17.031 6.459-22.855 1.714 5.824 4.284 14.705 6.459 22.855 1.133 4.246 2.154 8.276 2.89 11.566.756 3.374 1.158 5.746 1.158 6.811a10.509 10.509 0 0 1-17.936 7.43 10.505 10.505 0 0 1-2.278-3.409l-1.848.765 1.848-.765a10.509 10.509 0 0 1-.8-4.021c0-1.065.402-3.437 1.158-6.811.736-3.29 1.757-7.32 2.89-11.566Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M153.403 139.766a3.01 3.01 0 0 1-2.015-.784l-26.721-23.894-26.72 23.907a3.03 3.03 0 0 1-4.27-.238 3.022 3.022 0 0 1 .238-4.27l28.743-25.712a3.027 3.027 0 0 1 4.035 0l28.742 25.712a3.022 3.022 0 0 1-2.017 5.279h-.015Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationResidenceCountryHouse;
