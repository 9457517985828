const grayBlue = {
  primary: {
    main: '#0077AA',
    50: '#D5F3FF',
    100: '#B4E9FF',
    150: '#93DFFF',
    200: '#72D5FF',
    300: '#30C1FF',
    400: '#00A5EC',
    500: '#0077AA',
    600: '#00628B',
    700: '#004C6D',
    750: '#00415D',
    800: '#00374E',
    850: '#002C3F',
    900: '#002130',
  },
  secondary: {
    main: '#eeeeee',
    50: '#1F2A35',
    100: '#334455',
    150: '#445577',
    200: '#556688',
    300: '#667799',
    400: '#7788AA',
    500: '#AABBCD',
    600: '#BBCCE0',
    700: '#99AABB',
    750: '#D8E0E8',
    800: '#eeeeee',
    850: '#DAE8F0',
    900: '#E0EBF2',
  },
};

export default grayBlue;
