import { CircularProgress, HStack, Text } from '@wegroup/design-system';
import React from 'react';

interface Props {
  isDisabled?: boolean;
  currentLength: number;
  maxLength: number;
}

const QuestionBodyInputCount: React.FC<Props> = ({
  isDisabled,
  currentLength,
  maxLength,
}) => {
  const [hueUnit, saturationUnit, lightnessUnit] = [
    145 / maxLength,
    29 / maxLength,
    12 / maxLength,
  ];

  const hslValues = [
    145 - hueUnit * currentLength,
    63 + saturationUnit * currentLength,
    49 + lightnessUnit * currentLength,
  ];

  return (
    <HStack opacity={isDisabled ? 0 : 1} transition="0.2s">
      <Text textStyle="extraSmallBody" color="gray.200">
        {currentLength}/{maxLength}
      </Text>
      <CircularProgress
        value={(currentLength / maxLength) * 100}
        color={`hsl(${hslValues[0]},${hslValues[1]}%,${hslValues[2]}%)`}
        size={5}
        capIsRound
        thickness="12px"
      />
    </HStack>
  );
};

export default QuestionBodyInputCount;
