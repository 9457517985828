import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCashAtHome: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_5236_12105"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_5236_12105)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <mask id="path-4-inside-1_5236_12105" fill="#fff">
          <rect width="162" height="22" x="95" y="153" rx="2"></rect>
        </mask>
        <rect
          width="162"
          height="22"
          x="95"
          y="153"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-4-inside-1_5236_12105)"
          rx="2"
        ></rect>
        <mask id="path-5-inside-2_5236_12105" fill="#fff">
          <rect width="162" height="22" x="95" y="171" rx="2"></rect>
        </mask>
        <rect
          width="162"
          height="22"
          x="95"
          y="171"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-5-inside-2_5236_12105)"
          rx="2"
        ></rect>
        <mask id="path-6-inside-3_5236_12105" fill="#fff">
          <rect width="162" height="22" x="95" y="189" rx="2"></rect>
        </mask>
        <rect
          width="162"
          height="22"
          x="95"
          y="189"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-6-inside-3_5236_12105)"
          rx="2"
        ></rect>
        <mask id="path-7-inside-4_5236_12105" fill="#fff">
          <rect width="162" height="22" x="95" y="207" rx="2"></rect>
        </mask>
        <rect
          width="162"
          height="22"
          x="95"
          y="207"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-7-inside-4_5236_12105)"
          rx="2"
        ></rect>
        <mask id="path-8-inside-5_5236_12105" fill="#fff">
          <rect width="62" height="79" x="142" y="151" rx="2"></rect>
        </mask>
        <rect
          width="62"
          height="79"
          x="142"
          y="151"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-8-inside-5_5236_12105)"
          rx="2"
        ></rect>
        <g
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          clipPath="url(#clip0_5236_12105)"
        >
          <path d="M187.356 177.809c-2.196-2.511-4.979-4.209-8-4.882-3.02-.673-6.144-.291-8.979 1.099-2.835 1.39-5.255 3.725-6.957 6.713s-2.609 6.496-2.609 10.084c0 3.587.907 7.095 2.609 10.083 1.702 2.988 4.122 5.323 6.957 6.713 2.835 1.39 5.959 1.772 8.979 1.099 3.021-.673 5.804-2.371 8-4.882M155.603 196.028h20.821m0-10.411h-20.821 20.821z"></path>
        </g>
        <mask id="path-11-inside-6_5236_12105" fill="#fff">
          <rect width="162" height="22" x="107" y="84" rx="2"></rect>
        </mask>
        <rect
          width="162"
          height="22"
          x="107"
          y="84"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-11-inside-6_5236_12105)"
          rx="2"
        ></rect>
        <mask id="path-12-inside-7_5236_12105" fill="#fff">
          <rect width="162" height="22" x="107" y="102" rx="2"></rect>
        </mask>
        <rect
          width="162"
          height="22"
          x="107"
          y="102"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-12-inside-7_5236_12105)"
          rx="2"
        ></rect>
        <mask id="path-13-inside-8_5236_12105" fill="#fff">
          <rect width="162" height="22" x="107" y="120" rx="2"></rect>
        </mask>
        <rect
          width="162"
          height="22"
          x="107"
          y="120"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-13-inside-8_5236_12105)"
          rx="2"
        ></rect>
        <mask id="path-14-inside-9_5236_12105" fill="#fff">
          <rect width="162" height="22" x="107" y="138" rx="2"></rect>
        </mask>
        <rect
          width="162"
          height="22"
          x="107"
          y="138"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-14-inside-9_5236_12105)"
          rx="2"
        ></rect>
        <mask id="path-15-inside-10_5236_12105" fill="#fff">
          <rect width="62" height="79" x="154" y="82" rx="2"></rect>
        </mask>
        <rect
          width="62"
          height="79"
          x="154"
          y="82"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-15-inside-10_5236_12105)"
          rx="2"
        ></rect>
        <g
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          clipPath="url(#clip1_5236_12105)"
        >
          <path d="M199.356 108.809c-2.196-2.511-4.979-4.209-8-4.882-3.02-.673-6.144-.291-8.979 1.099-2.835 1.39-5.255 3.725-6.957 6.713s-2.609 6.496-2.609 10.084c0 3.587.907 7.095 2.609 10.083 1.702 2.988 4.122 5.323 6.957 6.713 2.835 1.39 5.959 1.772 8.979 1.099 3.021-.673 5.804-2.371 8-4.882M167.603 127.028h20.821m0-10.411h-20.821 20.821z"></path>
        </g>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M107.272 83.707a6.178 6.178 0 014.623-4.623l9.275-2.14a.767.767 0 00.602-.751.772.772 0 00-.602-.751l-9.275-2.147a6.158 6.158 0 01-4.623-4.623l-2.142-9.261a.772.772 0 00-1.233-.433.777.777 0 00-.27.433l-2.138 9.26a6.163 6.163 0 01-4.622 4.623l-9.273 2.138a.773.773 0 000 1.503l9.27 2.14a6.166 6.166 0 014.623 4.623l2.14 9.277a.769.769 0 00.752.594.77.77 0 00.751-.594l2.142-9.268zM182.555 53.253a5.262 5.262 0 013.945-3.945l7.919-1.828a.66.66 0 000-1.283L186.5 44.37a5.26 5.26 0 01-3.945-3.946l-1.828-7.913a.66.66 0 00-.641-.512.653.653 0 00-.64.512l-1.826 7.913a5.264 5.264 0 01-3.948 3.946l-7.913 1.826a.657.657 0 00-.366 1.05.657.657 0 00.366.233l7.913 1.828a5.258 5.258 0 013.946 3.945l1.828 7.919a.654.654 0 00.64.511.656.656 0 00.641-.511l1.828-7.919zM225.128 88.122a6.112 6.112 0 014.577-4.576l9.182-2.118a.766.766 0 000-1.488l-9.178-2.117a6.114 6.114 0 01-4.576-4.576l-2.126-9.179a.766.766 0 00-1.489 0l-2.117 9.178a6.105 6.105 0 01-4.576 4.577l-9.179 2.117a.767.767 0 00-.423 1.218c.107.135.256.23.423.27l9.179 2.118a6.105 6.105 0 014.576 4.576l2.117 9.185a.766.766 0 001.489 0l2.121-9.185z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5236_12105">
          <path
            fill="#fff"
            d="M0 0H36.438V41.643H0z"
            transform="translate(153 170)"
          ></path>
        </clipPath>
        <clipPath id="clip1_5236_12105">
          <path
            fill="#fff"
            d="M0 0H36.438V41.643H0z"
            transform="translate(165 101)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationCashAtHome;
