import {
  SimpleGrid,
  GridItem,
  HStack,
  VStack,
  Text,
} from '../../../../../../index';
import React, { useState } from 'react';
import { AddressInputProps } from '../..';
import { Address } from '../../../../../../../../types/Common';
import BoxNumber from './BoxNumber';
import CityPicker from './city/CityPicker';
import Country from './Country';
import HouseNumber from './HouseNumber';
import ManualAddressInputFooter from './ManualAddressInputFooter';
import StreetPicker from './street/StreetPicker';
import Zipcode from './Zipcode';

interface Props {
  onManualModeOff: () => void;
}

const ManualAddressInput: React.FC<AddressInputProps & Props> = ({
  name,
  value,
  onChange,
  onBlur,
  onManualModeOff,
  error,
  errors,
  isWithCountry,
  isFullManual,
  isLookupAllowed = true,
  isPrefilled: isPrefilledProp,
  country,
  rightElement,
  'data-test-id': dataTestId,
  isDisabled,
  supportedCountries,
}) => {
  const isManualInput = Boolean(
    isFullManual ||
      country?.toUpperCase() !== 'BE' ||
      (value?.country_code && value?.country_code !== 'BE'),
  );
  const [isPrefilled, setIsPrefilled] = useState(() =>
    Boolean(value && isPrefilledProp),
  );

  const handleCountryChange = (country?: string) => {
    onChange({
      country_code: country,
    });
    setIsPrefilled(false);
  };

  const handleCityChange = (city?: string, zipcode?: string | number) => {
    onChange({
      country_code: value?.country_code,
      city: city,
      zipcode: zipcode,
    });
    setIsPrefilled(false);
  };

  const handleFieldChange = (fieldName: keyof Address, fieldValue: unknown) => {
    onChange({
      ...value,
      [fieldName]: fieldValue,
    });
    setIsPrefilled(false);
  };

  const isCityPickerDisabled =
    (!isManualInput && isWithCountry && !value?.country_code) || isDisabled;
  const isCityAvailable = !isManualInput && !value?.city;
  const isInputDisabled = isCityAvailable || isDisabled;

  return (
    <HStack spacing="4" data-test-id={dataTestId}>
      <SimpleGrid columns={12} gap="4" w="100%">
        {isWithCountry && (
          <GridItem gridColumn={{ base: 'span 12', mobileL: 'span 6' }}>
            <Country
              name={name}
              value={value?.country_code || undefined}
              onChange={handleCountryChange}
              onBlur={onBlur}
              error={errors?.country_code}
              isPrefilled={isPrefilled}
              isDisabled={isDisabled}
              supportedCountries={supportedCountries}
            />
          </GridItem>
        )}
        <GridItem
          gridColumn={
            isWithCountry
              ? { base: 'span 12', mobileL: 'span 6', laptop: 'span 4' }
              : { base: 'span 12', mobileL: 'span 6' }
          }
        >
          <CityPicker
            name={name}
            value={value?.city || undefined}
            countryCode={value?.country_code || undefined}
            onChange={handleCityChange}
            onBlur={onBlur}
            error={errors?.city}
            isDisabled={isCityPickerDisabled}
            isManualInput={isManualInput}
            isPrefilled={isPrefilled}
          />
        </GridItem>
        <GridItem
          gridColumn={
            isWithCountry
              ? { base: 'span 12', mobileL: 'span 6', laptop: 'span 2' }
              : { base: 'span 12', mobileL: 'span 6' }
          }
        >
          <Zipcode
            name={name}
            value={value?.zipcode || undefined}
            onChange={(value) => handleFieldChange('zipcode', value)}
            onBlur={onBlur}
            error={errors?.zipcode}
            isDisabled={isInputDisabled}
            isPrefilled={isPrefilled}
          />
        </GridItem>
        <GridItem gridColumn={{ base: 'span 12', mobileL: 'span 6' }}>
          <StreetPicker
            name={name}
            value={value?.street || undefined}
            countryCode={value?.country_code || undefined}
            cityName={value?.city || undefined}
            onChange={(value) => handleFieldChange('street', value)}
            onBlur={onBlur}
            error={errors?.street}
            isDisabled={isInputDisabled}
            isManualInput={isManualInput}
            isPrefilled={isPrefilled}
          />
        </GridItem>
        <GridItem
          gridColumn={{
            base: 'span 12',
            mobileL: 'span 6',
            tabletS: 'span 3',
          }}
        >
          <HouseNumber
            name={name}
            value={value?.housenr || undefined}
            onChange={(value) => handleFieldChange('housenr', value)}
            onBlur={onBlur}
            error={errors?.housenr}
            isDisabled={isInputDisabled}
            isPrefilled={isPrefilled}
          />
        </GridItem>
        <GridItem
          gridColumn={{
            base: 'span 12',
            mobileL: 'span 6',
            tabletS: 'span 3',
          }}
        >
          <BoxNumber
            name={name}
            value={value?.boxnr || undefined}
            onChange={(value) => handleFieldChange('boxnr', value)}
            onBlur={onBlur}
            error={errors?.boxnr}
            isDisabled={isInputDisabled}
            isPrefilled={isPrefilled}
          />
        </GridItem>
        <GridItem gridColumn="span 12">
          <VStack align="flex-end" spacing="2">
            {error && (
              <Text color="danger.main" mt="0">
                {error}
              </Text>
            )}
            {isLookupAllowed && (
              <ManualAddressInputFooter onManualModeOff={onManualModeOff} />
            )}
          </VStack>
        </GridItem>
      </SimpleGrid>
      {rightElement}
    </HStack>
  );
};

export default ManualAddressInput;
