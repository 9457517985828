const Tooltip = {
  baseStyle: {
    p: 3,
  },
  defaultProps: {
    hasArrow: true,
    placement: 'top',
  },
};

export default Tooltip;
