import React, { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router';
import useTracking from '../../../../common/src/hooks/tracking/useTracking';

export const TrackingProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { trackPageView } = useTracking();
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageView(pathname);
  }, [pathname]);

  return <>{children}</>;
};
