import React, { useEffect, useState } from 'react';

import {
  Box,
  Menu,
  MenuList,
  Portal,
  Skeleton,
  VStack,
} from '@wegroup/design-system';
import SearchInput from '../SearchInput';
import AvatarPickerMenuItems from './AvatarPickerMenuItems';
import MultipleAvatarPickerMenuButton from './MultipleAvatarPickerMenuButton';

export interface AvatarPickerOption {
  label: string;
  value: string;
}

interface Props {
  placeholder?: string;

  // Selecting
  options: AvatarPickerOption[];
  selectedOptions: AvatarPickerOption[];
  onChange: (options: AvatarPickerOption[]) => void;
  onClear?: () => void;
  onClose?: () => void;

  // Searching
  searchedValue?: string;
  onSearch?: (newSearch: string) => void;
  isLoading?: boolean;
}

const MultipleAvatarPicker: React.FC<React.PropsWithChildren<Props>> = ({
  placeholder,
  // Selecting
  options,
  selectedOptions,
  onChange,
  onClear,
  onClose = () => null,
  // Searching
  searchedValue,
  onSearch,
  isLoading,
}) => {
  // Sort alphabeticly and make sure initialbroker is on top
  const sortedOptions = options.sort((a, b) => {
    if (selectedOptions?.some(({ value }) => value === a.value)) return -1;
    if (selectedOptions?.some(({ value }) => value === b.value)) return 1;
    if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
    if (b.label.toLowerCase() > a.label.toLowerCase()) return -1;
    return 0;
  });

  const [items, setItems] = useState(sortedOptions);

  /** To prevent the list from sorting while changing the values
   * Set the 'items' equal to the sortedoptions when either the menu closes or the items are empty
   */
  useEffect(() => {
    if (!items || items.length === 0) setItems(sortedOptions);
  }, [options]);

  const handleSelect = (option: AvatarPickerOption) => {
    if (selectedOptions?.some(({ value }) => value === option.value)) {
      onChange(selectedOptions.filter(({ value }) => value !== option.value)); // unselect
    } else {
      onChange([...selectedOptions, option]);
    }
  };

  const handleClose = () => {
    setItems(sortedOptions);
    onClose();
  };

  return (
    <Menu isLazy closeOnBlur onClose={handleClose}>
      {/* Main component displaying the selected name */}
      <MultipleAvatarPickerMenuButton
        selectedOptions={selectedOptions}
        placeholder={placeholder}
      />
      {/* Dropdown menu */}
      <Portal>
        <MenuList p="0" zIndex="2">
          {onSearch && (
            <Box p="2" borderBottom="1px solid" borderColor="gray.strokeAndBg">
              <SearchInput
                value={searchedValue}
                onChange={(e) => onSearch(e.currentTarget.value)}
              />
            </Box>
          )}
          {isLoading ? (
            <VStack p="4" spacing="4">
              {[1, 2, 3].map((index) => (
                <Skeleton key={index} h="4" w="100%" />
              ))}
            </VStack>
          ) : (
            <Box maxH="80" overflowY="auto">
              <AvatarPickerMenuItems
                onClick={handleSelect}
                selectedOptions={selectedOptions}
                items={searchedValue ? sortedOptions : items}
              />
            </Box>
          )}
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default MultipleAvatarPicker;
