import { createContext } from 'react';
import { ReactReduxContextValue } from 'react-redux';
import rootReducer from '../redux/reducers/rootReducer';
import flowsStore from '../redux/store';

const FlowsContext = createContext<ReactReduxContextValue>({
  store: flowsStore,
  storeState: rootReducer,
});
export default FlowsContext;
