import { useQuery, useQueryClient } from 'react-query';
import { FormOption } from '../../../../../../types/Form';
import { wgApi } from '../../../../../app/src/features/api/wgApi';
import useAuth from '../../../../../app/src/features/authentication/hooks/useAuth';

interface SupportedInsuranceSubjectsResponse {
  items: FormOption[];
}

interface Params {
  insurance_company: string;
  language?: string;
  country?: string;
}

const getSupportedInsuranceSubjects = async ({
  insurance_company,
  language,
  country,
}: Params) => {
  const { data, isError } = await wgApi.get<SupportedInsuranceSubjectsResponse>(
    `/v1/api/insurance-terms-bot/supported-insurance-subjects`,
    {
      query: {
        insurance_company,
        language,
        country,
      },
    },
  );
  if (isError) throw new Error();
  return data;
};

const useGetSupportedInsuranceSubjectsQKey = (
  insuranceCompany: string,
  language?: string,
  country?: string,
) => [
  'toolkit',
  'insurance-terms-bot',
  insuranceCompany,
  'supported-insurance-subjects',
  language,
  country,
];

export const useSupportedInsuranceSubjects = (insurance_company?: string) => {
  const { user } = useAuth();
  return useQuery(
    useGetSupportedInsuranceSubjectsQKey(
      insurance_company!,
      user?.language,
      user?.country_code,
    ),
    () =>
      getSupportedInsuranceSubjects({
        insurance_company: insurance_company!,
        language: user?.language,
        country: user?.country_code,
      }),
    {
      enabled: !!insurance_company,
    },
  );
};

export const useSupportedInsuranceSubjectsPrefetch = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const handlePrefetch = (insurance_company: string) => {
    const data = queryClient.getQueryData(
      useGetSupportedInsuranceSubjectsQKey(
        insurance_company!,
        user?.language,
        user?.country_code,
      ),
    );
    /** Only prefetch the query when there is no data available for it */
    if (!data) {
      queryClient.prefetchQuery(
        useGetSupportedInsuranceSubjectsQKey(
          insurance_company!,
          user?.language,
          user?.country_code,
        ),
        () =>
          getSupportedInsuranceSubjects({
            insurance_company: insurance_company!,
            language: user?.language,
            country: user?.country_code,
          }),
      );
    }
  };

  return {
    prefetch: handlePrefetch,
  };
};
