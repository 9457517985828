import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsChevronUp: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 21.828l11.586 11.586 2.828-2.828L25 16.172 10.586 30.586l2.828 2.828L25 21.828z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsChevronUp;
