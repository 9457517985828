import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehiclePedestrianDetection: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M10.9 37.1l2.8 2c4.3-6.3 5.4-14.3 2.9-21.5-.8-2.4-2-4.6-3.5-6.6l-2.8 2.1c1.3 1.7 2.3 3.6 3 5.6 2.2 6.1 1.3 13-2.4 18.4z"
    ></path>
    <path
      fill="currentColor"
      d="M7.4 20.1c1.7 4.8.9 10.2-2 14.4l2.8 2c3.6-5.1 4.5-11.7 2.4-17.5-.7-2-1.6-3.8-2.9-5.4L5 15.7c1 1.3 1.8 2.8 2.4 4.4zM43.5 43L36 32.5l2.1-13.9c.5-3-1.8-5.6-4.8-5.6-1.2 0-2.4.5-3.2 1.2-.1 0-.2.1-.2.1l-7 5c-.4.3-.7.7-.8 1.1l-2 8c-.3 1.1.4 2.2 1.5 2.4.2 0 .3.1.5.1.9 0 1.7-.6 1.9-1.5l1.8-7.3 2.3-1.7-.9 7.1c-.2 1.3.2 2.5.9 3.5l11.4 15c.8 1.1 2.4 1.3 3.5.5.7-.5 1-1.2 1-2 0-.5-.2-1-.5-1.5zM35 11a4 4 0 100-8 4 4 0 000 8z"
    ></path>
    <path
      fill="currentColor"
      d="M28.8 36.9l3.3 4.3-3.6 4.8c-.8 1.1-2.4 1.3-3.5.5-.7-.5-1-1.2-1-2 0-.5.2-1 .5-1.5l4.3-6.1zM43 30.7c-.3.2-.7.3-1 .3-.7 0-1.3-.3-1.7-.9l-.7-1.1.9-6.1 3.2 5.1c.5.9.3 2.1-.7 2.7z"
    ></path>
  </Icon>
);

export default RiskObjectsVehiclePedestrianDetection;
