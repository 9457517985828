import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSwimmingPool: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3773_11558"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3773_11558)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <mask
          id="mask1_3773_11558"
          style={{ maskType: 'alpha' }}
          width="320"
          height="321"
          x="17"
          y="1"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill={colors.primary[50]}
            d="M337 161.637c0 88.365-71.634 160-160 160s-160-71.635-160-160c0-88.366 71.634-160 160-160s160 71.634 160 160z"
          ></path>
        </mask>
        <g mask="url(#mask1_3773_11558)">
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M-15 122H358V261H-15z"
          ></path>
          <circle
            cx="133.5"
            cy="238.5"
            r="14.5"
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
          <circle
            cx="193.5"
            cy="238.5"
            r="14.5"
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M136 153H193V165H136z"
          ></path>
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M136 181H193V193H136z"
          ></path>
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M136 209H193V221H136z"
          ></path>
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M175.5 100.503v20.999l-6.333.001H163.5v-21c0-4.208-1.55-6.989-3.594-8.791-2.156-1.902-5.207-3.002-8.448-3.024-3.24-.023-6.255 1.033-8.376 2.893-2.004 1.76-3.582 4.55-3.582 8.922v137.49l-.001.032a7.486 7.486 0 01-.097.898 7.846 7.846 0 01-.691 2.185c-.381.763-.922 1.465-1.696 1.981-.761.507-1.865.914-3.515.914-1.65 0-2.754-.407-3.515-.914-.774-.516-1.315-1.218-1.696-1.981a7.846 7.846 0 01-.691-2.185 7.486 7.486 0 01-.097-.898l-.001-.032v-137.49c0-7.633 2.924-13.78 7.668-17.942 4.627-4.06 10.611-5.913 16.374-5.873 5.762.04 11.71 1.974 16.302 6.024 4.705 4.15 7.656 10.245 7.656 17.791zM236 100.503v20.999l-6.333.001H224v-21c0-4.208-1.55-6.989-3.594-8.791-2.156-1.902-5.207-3.002-8.448-3.024-3.24-.023-6.255 1.033-8.376 2.893-2.004 1.76-3.582 4.55-3.582 8.922v137.49l-.001.032a7.486 7.486 0 01-.097.898 7.846 7.846 0 01-.691 2.185c-.381.763-.922 1.465-1.696 1.981-.761.507-1.865.914-3.515.914-1.65 0-2.754-.407-3.515-.914-.774-.516-1.315-1.218-1.696-1.981a7.846 7.846 0 01-.691-2.185 7.486 7.486 0 01-.097-.898l-.001-.032v-137.49c0-7.633 2.924-13.78 7.668-17.942 4.627-4.06 10.611-5.913 16.374-5.873 5.762.04 11.71 1.974 16.302 6.024 4.705 4.15 7.656 10.245 7.656 17.791z"
          ></path>
          <mask
            id="mask2_3773_11558"
            style={{ maskType: 'alpha' }}
            width="358"
            height="182"
            x="6"
            y="139"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#CDCDF5"
              d="M22.25 145.75l-6.731 6.731A32.5 32.5 0 006 175.462v32.962C6 270.322 56.178 320.5 118.076 320.5h189.481c30.897 0 55.943-25.046 55.943-55.943V162c0-11.993-14.5-18-22.981-9.519-5.257 5.257-13.781 5.257-19.038 0l-6.731-6.731c-8.975-8.975-23.525-8.975-32.5 0-8.975 8.975-23.525 8.975-32.5 0-8.975-8.975-23.525-8.975-32.5 0-8.975 8.975-23.525 8.975-32.5 0-8.975-8.975-23.525-8.975-32.5 0-8.975 8.975-23.525 8.975-32.5 0-8.975-8.975-23.525-8.975-32.5 0-8.975 8.975-23.525 8.975-32.5 0-8.975-8.975-23.525-8.975-32.5 0z"
            ></path>
          </mask>
          <g mask="url(#mask2_3773_11558)">
            <path
              fill={colors.primary[500]}
              d="M-17 137H360V280H-17z"
              opacity="0.25"
            ></path>
            <path
              stroke={colors.primary[500]}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
              d="M16.933 153.895l6.731-6.731c8.194-8.193 21.478-8.193 29.672 0 9.756 9.756 25.573 9.756 35.328 0 8.194-8.193 21.478-8.193 29.672 0 9.755 9.756 25.573 9.756 35.328 0 8.194-8.193 21.478-8.193 29.672 0 9.755 9.756 25.573 9.756 35.328 0 8.194-8.193 21.478-8.193 29.672 0 9.755 9.756 25.573 9.756 35.328 0 8.194-8.193 21.478-8.193 29.672 0l6.731 6.731c6.038 6.038 15.828 6.038 21.866 0 7.221-7.22 19.567-2.107 19.567 8.105v102.557c0 29.792-24.151 53.943-53.943 53.943H118.076C57.283 318.5 8 269.217 8 208.424v-32.962a30.501 30.501 0 018.933-21.567z"
            ></path>
          </g>
        </g>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationSwimmingPool;
