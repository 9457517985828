export const regex = {
  date: /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/,
  dayMonth: /^([0-2][0-9]|(3)[0-1])\/(((0)[0-9])|((1)[0-2]))$/,
  emailRegex:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  number: /^[0-9 _ ,]*$/,
  fsma: /^([0-9]{6,10})$/,
  phoneNumber:
    /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/,
  city: /^[A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- '.&’]{1,150}$/,
  street: /^^[0-9A-Za-zéîèùÿàçâêîõôûäáëïöüúÀÂÄÈÉÊËÎÏÔßŒÙÛÜŸÇ\- '.&’]{1,150}$/,
  firstName: /^[A-Za-zéèùúàçâêîôûäëïöü\- ']{2,50}$/,
  lastName: /^[A-Za-zéèùúàçâêîôûäëïöü\- ']{2,50}$/,
  region: /^[A-Za-zéèùàçâêîôûäëïöüÀ\- '.&’]{1,50}$/,
  houseNr: /^[a-z0-9\- /]{1,20}$/,
  boxNr: /^[a-zA-Z0-9\- /]{1,20}$/,
  companyName: /^[0-9A-Za-zéèùàçâêîôûäëïöü\-_ ]{1,50}$/,
  numberPlate: {
    BE: /^[A-Za-z0-9 -]{1,15}$/,
    NL: /(^[a-zA-Z]{2}-?[0-9]{2}-?[0-9]{2}$)|(^[0-9]{2}-?[0-9]{2}-?[a-zA-Z]{2}$)|(^[0-9]{2}-?[a-zA-Z]{2}-?[0-9]{2}$)|(^[a-zA-Z]{2}-?[0-9]{2}-?[a-zA-Z]{2}$)|(^[a-zA-Z]{2}-?[a-zA-Z]{2}-?[0-9]{2}$)|(^[0-9]{2}-?[a-zA-Z]{2}-?[a-zA-Z]{2}$)|(^[0-9]{2}-?[a-zA-Z]{3}-?[0-9]{1}$)|(^[0-9]{1}-?[a-zA-Z]{3}-?[0-9]{2}$)|(^[a-zA-Z]{2}-?[0-9]{3}-?[a-zA-Z]{1}$)|(^[a-zA-Z]{1}-?[0-9]{3}-?[a-zA-Z]{2}$)|(^[a-zA-Z]{3}-?[0-9]{2}-?[a-zA-Z]{1}$)|(^[a-zA-Z]{1}-?[0-9]{2}-?[a-zA-Z]{3}$)|(^[0-9]{1}-?[a-zA-Z]{2}-?[0-9]{3})|(^[0-9]{3}-?[a-zA-Z]{2}-?[0-9]{1})/,
  },
  chassisNumber: /^[A-Z0-9]{11,17}$/,
  carColor: /^[A-Za-z]{1,20}$/,
  manufacturer: /^[A-Za-zéèùàçâêîôûäëïöü\- ]{1,35}$/,
  insuranceName: /^[0-9A-Za-zéèùàçâêîôûäëïöü&\-+()' _.]+$/,
  year: /^\d{4}$/,
  value: '',
  percentage: '',
  policyNumber: '',
  password: /^[0-9A-Za-zéèùàçâêîôûäëïöü\-_&~?#;[\]{}!+%*$@^ ]{9,31}$/,
  uuid: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i,
  iso: /[+-]?(\d{4}-[01]\d(-[0-3]\d(T[0-2]\d:[0-5]\d:?([0-5]\d(\.\d+)?)?[+-][0-2]\d:[0-5]\dZ?)?)?)/,
};

export default regex;
