import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsLegalTax: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.152 6.235A2 2 0 0115 5h20a6 6 0 016 6v20a2 2 0 01-3.414 1.414l-24-24a2 2 0 01-.434-2.18zM19.828 9L37 26.172V11a2 2 0 00-2-2H19.828zM11 9a2 2 0 012 2v28.263l2.89-1.927a2 2 0 012.524.25L21 40.172l2.586-2.586a2 2 0 012.828 0L29 40.172l2.586-2.586a2 2 0 012.523-.25L37 39.263v-.243a2 2 0 114 0V43a2 2 0 01-3.11 1.664l-4.635-3.09-2.84 2.84a2 2 0 01-2.83 0L25 41.828l-2.586 2.586a2 2 0 01-2.828 0l-2.84-2.84-4.614 3.075a2.006 2.006 0 01-2.71-.42A1.994 1.994 0 019 42.973V11a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21 15a2 2 0 012-2h8a2 2 0 110 4h-8a2 2 0 01-2-2zM17 23a2 2 0 012-2h4a2 2 0 110 4h-4a2 2 0 01-2-2zM25 31a2 2 0 012-2h4a2 2 0 110 4h-4a2 2 0 01-2-2zM31 21a2 2 0 012 2v.02a2 2 0 11-4 0V23a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.586 5.586a2 2 0 012.828 0l36 36a2 2 0 11-2.828 2.828l-36-36a2 2 0 010-2.828z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsLegalTax;
