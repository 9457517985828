import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBuildingSavings: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_3965_10370"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3965_10370)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <g stroke={colors.primary[500]} strokeWidth="4" opacity="0.33">
          <path
            fill={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M245.876 80.914h-9.928V69.979A2.981 2.981 0 01238.926 67h3.971a2.977 2.977 0 012.979 2.979v10.935z"
          ></path>
          <path
            fill="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M249.227 149.809h-16.681v-66.53a2.98 2.98 0 012.979-2.978h10.723a2.977 2.977 0 012.979 2.978v66.53z"
          ></path>
          <rect
            width="89.362"
            height="55.851"
            x="190.638"
            y="150.105"
            fill={colors.primary[100]}
            strokeLinejoin="round"
            rx="4"
          ></rect>
          <path
            fill="#fff"
            strokeLinejoin="round"
            d="M190.638 205.211H280v22.34h-89.362v-22.34z"
          ></path>
          <path
            fill={colors.primary[100]}
            strokeLinejoin="round"
            d="M218.644 205.412h22.242v22.242h-22.242v-22.242z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M229.767 205.412h11.122v22.242h-11.122v-22.242zM218.644 205.412h11.123v22.242h-11.123v-22.242z"
          ></path>
          <path
            fill={colors.primary[100]}
            strokeLinejoin="round"
            d="M238.107 170.237h-88.968v-66.723h86.883a2.086 2.086 0 012.085 2.085v64.638z"
          ></path>
          <path
            fill="#fff"
            strokeLinejoin="round"
            d="M232.34 194.292h-41.496v-24.056h44.475v21.078a2.98 2.98 0 01-2.979 2.978z"
          ></path>
          <path
            fill={colors.primary[100]}
            strokeLinejoin="round"
            d="M138.016 138.686H67.817a2.085 2.085 0 00-2.085 2.085v50.739h72.284v-52.824z"
          ></path>
          <path
            fill="#fff"
            strokeLinejoin="round"
            d="M195.574 177.156l-43.031-71.716a3.971 3.971 0 00-3.404-1.93 3.97 3.97 0 00-3.405 1.93l-46.64 77.73h8.34v44.484h83.404V183.17h1.325a3.974 3.974 0 003.977-4.02 3.972 3.972 0 00-.566-1.994z"
          ></path>
          <path
            fill={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M160.262 202.633h16.681v25.021h-16.681v-25.021z"
          ></path>
          <path
            fill={colors.primary[100]}
            strokeLinejoin="round"
            d="M143.578 155.369H154.7v19.463h-11.122v-19.463z"
          ></path>
          <path
            fill={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M132.458 191.51h58.386v11.122h-58.386V191.51zM195.574 177.156l-43.031-71.716a3.971 3.971 0 00-3.404-1.93 3.97 3.97 0 00-3.405 1.93l-46.64 77.73h11.119l38.926-63.944 37.764 62.037a3.967 3.967 0 003.393 1.907h1.873a3.97 3.97 0 003.405-6.014z"
          ></path>
          <path
            fill="#fff"
            strokeLinejoin="round"
            d="M138.016 191.51l-36.14-52.824-36.144 52.824h5.561v36.144h61.165V191.51h5.558z"
          ></path>
          <path
            fill={colors.primary[100]}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M101.872 216.532h-8.14a2.98 2.98 0 01-2.98-2.978v-13.503a2.977 2.977 0 012.98-2.979h8.14v19.46zM110.016 216.532h-8.143v-19.46h8.14a2.98 2.98 0 012.979 2.979v13.503a2.98 2.98 0 01-2.976 2.978z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M58.096 223.652v-16.156"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M57.818 139.599l.003-.009.002-.008a.29.29 0 01.277-.209.286.286 0 01.277.209l.002.008.003.008c2.2 7.461 5.67 19.414 8.587 30.349 1.46 5.469 2.776 10.667 3.727 14.912.97 4.33 1.504 7.448 1.504 8.894a14.095 14.095 0 01-8.704 13.026 14.093 14.093 0 01-15.366-3.056 14.107 14.107 0 01-4.13-9.97c0-1.446.535-4.564 1.504-8.894.95-4.246 2.266-9.443 3.725-14.912 2.918-10.935 6.387-22.888 8.59-30.348z"
          ></path>
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M302.345 225.025v-12.55"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M295.889 183.42c2.174-8.151 4.744-17.031 6.459-22.855 1.714 5.824 4.284 14.705 6.459 22.855 1.133 4.246 2.154 8.276 2.89 11.566.756 3.374 1.158 5.746 1.158 6.811a10.509 10.509 0 01-17.936 7.43 10.505 10.505 0 01-2.278-3.409l-1.848.765 1.848-.765a10.509 10.509 0 01-.8-4.021c0-1.065.402-3.437 1.158-6.811.736-3.29 1.757-7.32 2.89-11.566z"
          ></path>
        </g>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M68 172h11v54H68v-54zM209.754 217.229l47.682-47.683 5.873 5.873-47.682 47.682-5.873-5.872zM259.496 186.203l5.873-5.873 39.304 39.304-5.873 5.873-39.304-39.304z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M48.723 182.816l5.873-5.872 42.69 42.69-5.873 5.873-42.69-42.691zM259.496 219.629l39.304-39.304 5.873 5.873-39.304 39.304-5.873-5.873zM93.137 169.904l47.682-47.682 5.873 5.873-47.682 47.682-5.873-5.873zM259.496 133.895l5.873-5.873 39.304 39.304-5.873 5.873-39.304-39.304z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M259.496 167.322l39.304-39.304 5.873 5.873-39.304 39.304-5.873-5.873z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M307 226h-8V110.472c0-.391.148-.765.413-1.041.264-.276.622-.431.996-.431h5.182c.374 0 .732.155.996.431.265.276.413.65.413 1.041V226zM265 226h-8V110.472c0-.391.148-.765.413-1.041.264-.276.622-.431.996-.431h5.182c.374 0 .732.155.996.431.265.276.413.65.413 1.041V226zM215.994 226H207v-81.538c0-.388.167-.76.464-1.034a1.659 1.659 0 011.121-.428h5.83c.42 0 .823.154 1.121.428.297.274.464.646.464 1.034L215.994 226zM147 226h-8V110.472c0-.391.148-.765.413-1.041.264-.276.622-.431.996-.431h5.182c.374 0 .732.155.996.431.265.276.413.65.413 1.041V226zM56 226h-9v-84.522c0-.392.167-.768.464-1.045.297-.277.7-.433 1.121-.433h5.83c.42 0 .824.156 1.12.433.298.277.465.653.465 1.045V226zM99 226h-8v-65.525c0-.391.148-.766.413-1.043.264-.277.622-.432.996-.432h5.182c.374 0 .732.155.996.432.265.277.413.652.413 1.043V226z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M56 167h83v9H56v-9zM265 174h34v8h-34v-8zM216 167h41v9h-41v-9zM265 120h34v8h-34v-8z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M147.008 214.572h22.408v11.204h-22.408v-11.204zM113.398 214.572h22.408v11.204h-22.408v-11.204z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M90.992 214.572H113.4v11.204H90.992v-11.204zM121.801 203.367h22.408v11.204h-22.408v-11.204z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M144.207 203.367h22.408v11.204h-22.408v-11.204z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M135.805 192.164h22.408v11.204h-22.408v-11.204z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M238.277 209.163h-35.42a11.198 11.198 0 01-9.792-5.76l-6.18-11.124a5.594 5.594 0 01.073-5.569 5.607 5.607 0 014.843-2.751h49.276l-2.8 25.204z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M221.473 209.164l5.088 12.4a3.468 3.468 0 003.314 2.456 3.466 3.466 0 003.314-2.456l5.088-12.4 3.152-28.944a8.368 8.368 0 018.324-7.464h10.928"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M193.934 217.569a5.603 5.603 0 005.6 5.6 5.599 5.599 0 10-5.6-5.6z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBuildingSavings;
