const lightBlue = {
  primary: {
    main: '#00A3C8',
    50: '#F3FDFF',
    100: '#D2F7FF',
    150: '#B1F1FF',
    200: '#90EAFF',
    300: '#4EDEFF',
    400: '#0BD2FF',
    500: '#00A3C8',
    600: '#00829F',
    700: '#006076',
    750: '#005062',
    800: '#003F4E',
    850: '#002F39',
    900: '#001E25',
  },
  secondary: {
    main: '#A5C6D5',
    50: '#F3F8FA',
    100: '#EBF2F5',
    150: '#E2EDF1',
    200: '#D9E7ED',
    300: '#C8DCE5',
    400: '#B6D1DD',
    500: '#A5C6D5',
    600: '#74A7BE',
    700: '#4B86A0',
    750: '#3F7188',
    800: '#345D70',
    850: '#294957',
    900: '#1D353F',
  },
};

export default lightBlue;
