import {
  Text,
  HStack,
  StackProps,
  ColorProps,
  Box,
} from '@wegroup/design-system';
import React, { PropsWithChildren } from 'react';

interface Props {
  color: ColorProps['color'];
  prefixColor?: ColorProps['color'];
}

const ColorStatus: React.FC<PropsWithChildren<Props & StackProps>> = ({
  color,
  prefixColor = color,
  children,
  ...props
}) => {
  return (
    <HStack spacing="2" color={color} {...props}>
      <Text as="span" color={prefixColor}>
        &#x25cf;
      </Text>{' '}
      <Box pos="relative">{children}</Box>
    </HStack>
  );
};

export default ColorStatus;
