import { useQuery, UseQueryResult } from 'react-query';
import { getI18nLanguageCode } from '../../../../../../../i18n';
import { searchAddressREST } from '../../../../../../common/src/legacy/services/apiRouterService';
import { ErrorResponse } from '../../../../../../../types/API';
import { Address } from '../../../../../../../types/Common';
import { AddressSearchParams } from '../types/API';
import useIsCaribbean from '../../../../../../common/src/hooks/useIsCaribbean';

interface AddressListItem {
  address: Address;
  self: string;
}

interface AddressSearchResponse {
  items: AddressListItem[];
}

export const MINIMUM_REQUIRED_CHARS = 2;

export const getAddress = async ({
  query,
  language,
  countryCode = 'BE',
}: AddressSearchParams): Promise<Address[]> => {
  const [resp, status] = await searchAddressREST(
    query,
    language || getI18nLanguageCode(),
    countryCode,
  );

  if (status >= 400) return Promise.reject(JSON.stringify(resp));

  return (resp as AddressSearchResponse).items.map(({ address, self }) => ({
    ...address,
    self,
  }));
};

export const useAddressSearch = (
  params: AddressSearchParams,
  onError: (error: ErrorResponse) => void,
): UseQueryResult<Address[], ErrorResponse> => {
  const isCaribbean = useIsCaribbean();
  const { query } = params;

  return useQuery(
    ['address', 'search', ...Object.values(params)],
    () => getAddress(params),
    {
      enabled:
        query.length > MINIMUM_REQUIRED_CHARS &&
        (/\d/.test(query) || isCaribbean),
      onError,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );
};
