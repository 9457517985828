import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsMore: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25 29a4 4 0 100-8 4 4 0 000 8zM38 29a4 4 0 100-8 4 4 0 000 8zM12 29a4 4 0 100-8 4 4 0 000 8z"
    />
  </Icon>
);

export default GeneralActionsMore;
