import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationRoomSizeMedium: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_5248_12256"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_5248_12256)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <rect
          width="97.717"
          height="101.408"
          x="180.283"
          y="79.789"
          stroke={colors.primary[500]}
          strokeDasharray="2 10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          rx="4"
        ></rect>
        <path
          fill={colors.primary[100]}
          d="M78 84.492a4 4 0 014-4h124.394a4 4 0 014 4v93.409a4 4 0 01-4 4H82a4 4 0 01-4-4V84.492z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M103.352 79.79H82a4 4 0 00-4 4v93.408a4 4 0 004 4h124.394a4 4 0 004-4V143.79m-85.915-64h81.915a4 4 0 014 4v35.432M103.352 79.789L118.14 65M210.394 119.645L228 137.25"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationRoomSizeMedium;
