import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useManifest } from '../features/new-version/api/getManifest';

export const LOCAL_STORAGE_VERSION_KEY = 'wg-latest-version';

// This component will check if the latest version is currently in use. It will only do 1 max refresh
const VersionCheck: React.FC<PropsWithChildren> = ({ children }) => {
  const { data: manifest, isLoading } = useManifest();

  const [isVersionVerified, setIsVersionVerified] = useState<boolean>();

  const checkVersion = () => {
    const lcVersion = localStorage.getItem(LOCAL_STORAGE_VERSION_KEY);

    if (lcVersion !== manifest?.version && manifest?.version) {
      localStorage.setItem(LOCAL_STORAGE_VERSION_KEY, manifest?.version);
      window.location.reload();
    }

    setIsVersionVerified(true);
  };

  useEffect(() => {
    if (!isLoading) checkVersion();
  }, [isLoading]);

  if (!isVersionVerified) return null;

  return <>{children}</>;
};

export default VersionCheck;
