import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersFullscreen: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M38 15h-6v-4h10v10h-4v-6zM12 35h6v4H8V29h4v6zM38 35v-6h4v10H32v-4h6zM12 15v6H8V11h10v4h-6z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersFullscreen;
