import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleCoverage: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M43.14 20.35A19 19 0 0027 7.12v-1A2.07 2.07 0 0025.34 4 2 2 0 0023 6v1.12A19 19 0 006.86 20.35a.51.51 0 00.48.65H23v20.5a.5.5 0 01-1 0v-3.39A2.07 2.07 0 0020.34 36 2.001 2.001 0 0018 38v3.3a4.64 4.64 0 004.05 4.7A4.51 4.51 0 0027 41.5V21h15.66a.51.51 0 00.48-.65z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleCoverage;
