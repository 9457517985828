import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsPrint: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.757 18.757A6 6 0 0111 17h28a6 6 0 016 6v8a6 6 0 01-6 6h-4a2 2 0 110-4h4a2 2 0 002-2v-8a2 2 0 00-2-2H11a2 2 0 00-2 2v8a2 2 0 002 2h4a2 2 0 110 4h-4a6 6 0 01-6-6v-8a6 6 0 011.757-4.243z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.757 6.757A6 6 0 0119 5h12a6 6 0 016 6v8a2 2 0 11-4 0v-8a2 2 0 00-2-2H19a2 2 0 00-2 2v8a2 2 0 11-4 0v-8a6 6 0 011.757-4.243zM19 29a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2v-8a2 2 0 00-2-2H19zm-6 2a6 6 0 016-6h12a6 6 0 016 6v8a6 6 0 01-6 6H19a6 6 0 01-6-6v-8z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsPrint;
