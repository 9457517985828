import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsSign: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M41.138 30.583h-25.08c.342-.686.685-1.387 1.023-2.1.037-.075.073-.154.109-.226 2.103-.248 4.465-1.747 7.062-4.484.827 1.872 2.183 3.859 4.29 4.258.814.155 2.093.122 3.553-.902a8.902 8.902 0 001.408-1.25c1.293 1.182 3.581 2.222 7.635 2.222a1.862 1.862 0 000-3.724c-5.067 0-5.556-1.801-5.585-1.955a1.862 1.862 0 00-3.578-.62c-1.454 2.16-2.42 2.637-2.74 2.575-.761-.144-1.931-2.436-2.408-4.715a1.862 1.862 0 00-3.427-.57c-1.551 1.92-2.88 3.246-3.984 4.11 3.306-8.252 2.66-11.25 1.78-12.81-.776-1.376-2.173-2.138-3.935-2.146h-.05c-2.792.02-5.031 2.585-6.008 6.869-.536 2.347-.621 4.946-.227 7.127.41 2.28 1.292 3.983 2.577 5a155.877 155.877 0 01-1.668 3.34H8.868a1.862 1.862 0 000 3.724h1.036C8.402 37.048 7.29 38.9 7.266 38.931a1.863 1.863 0 001.602 2.823 1.862 1.862 0 001.596-.902c.096-.163 1.696-2.822 3.678-6.545h26.996a1.862 1.862 0 000-3.724zm-5.585-8.068v-.084a.489.489 0 010 .084zM14.83 15.94c.517-2.267 1.552-3.974 2.415-3.974.569 0 .659.155.713.255.222.394 1.016 2.651-2.702 11.294a6.95 6.95 0 01-.618-1.93c-.3-1.876-.236-3.794.192-5.645z"
    ></path>
  </Icon>
);

export default GeneralActionsSign;
