import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralCompanyLogosANVA: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.311 3.711c1.001-.244 3.047-.192 3.047-.192l28.764.04s3.23-.083 4.833.152c2.059.3 3.429 1.735 3.675 3.797.175 1.495.136 4.498.136 4.498l-.005 18.2s-.083 2.118-.145 4.459c-.006.22-.05.44-.094.66-.021.105-.042.21-.06.316-.9 2.01-2.48 2.947-4.67 2.947H37.46c-7.216 0-14.431.001-21.649-.013-.658 0-1.159.187-1.664.597-2.542 2.067-5.1 4.118-7.658 6.17a8429.685 8429.685 0 00-1.611 1.294 3.604 3.604 0 01-.415.304c-.619.357-1.11.122-1.216-.584-.02-.132-.017-.267-.015-.401l.002-.131V8.066C3.225 6.02 4.529 4.19 6.31 3.71zm8.012 18.914c0-1.273-.672-1.683-1.739-1.683-.979 0-1.628.54-2.019 1.016v6.73H7.22V14.304h3.345v5.239c.628-.732 1.87-1.53 3.626-1.53 2.388 0 3.477 1.338 3.477 3.256v7.42h-3.345v-6.064zm7.604 6.316c1.756 0 2.976-.776 3.626-1.508v1.255h3.345V18.27h-3.345v6.726c-.39.497-1.04 1.016-1.998 1.016-1.088 0-1.738-.432-1.738-1.683V18.27h-3.345v7.415c0 1.918 1.066 3.256 3.455 3.256zM29.702 14.3h3.345v5.196c.825-1.037 1.954-1.486 3.108-1.486 2.608 0 4.627 2.048 4.627 5.457 0 3.535-2.041 5.48-4.627 5.48-1.172 0-2.239-.476-3.108-1.465v1.207h-3.345V14.3zm5.342 11.713c1.326 0 2.323-.972 2.323-2.546 0-1.552-1.001-2.524-2.323-2.524-.715 0-1.584.388-1.997.95v3.191c.413.563 1.282.929 1.997.929zm9.557.907c0-1.099-.935-2.027-2.041-2.027-1.11 0-2.041.924-2.041 2.027 0 1.098.935 2.027 2.04 2.027 1.107 0 2.042-.929 2.042-2.027z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralCompanyLogosANVA;
