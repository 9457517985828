import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsCaravan: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 33a2 2 0 100 4 2 2 0 000-4zm-6 2a6 6 0 1112 0 6 6 0 01-12 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 35a2 2 0 012-2h16a2 2 0 010 4H27a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 13a2 2 0 00-2 2v18h10a2 2 0 010 4H9a4 4 0 01-4-4V15a6 6 0 016-6h20a2 2 0 011.414.586l8 8A2 2 0 0141 19v16a2 2 0 11-4 0V19.828L30.172 13H11z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 19v4h8v-4h-8zm-4 0a4 4 0 014-4h8a4 4 0 014 4v4a4 4 0 01-4 4h-8a4 4 0 01-4-4v-4z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsCaravan;
