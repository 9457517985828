import React from 'react';
import { IconStarFilled, IconStarHalfFilled, IconStarOutline } from 'wg-fe-ui';
import { Flex, Box } from '@wegroup/design-system';

export interface RatingProps {
  starCount: number;
  totalStarCount?: number;
  color?: string;
}

interface StarProps {
  color: string;
}

const FullStar: React.FC<React.PropsWithChildren<StarProps>> = ({ color }) => (
  <Box data-test-id="DesignSystem_StarRating_FullStar">
    <IconStarFilled color={color} />
  </Box>
);

const HalfStar: React.FC<React.PropsWithChildren<StarProps>> = ({ color }) => (
  <Box data-test-id="DesignSystem_StarRating_HalfStar">
    <IconStarHalfFilled color={color} />
  </Box>
);

const EmptyStar: React.FC<React.PropsWithChildren<StarProps>> = ({ color }) => (
  <Box data-test-id="DesignSystem_StarRating_EmptyStar">
    <IconStarOutline color={color} />
  </Box>
);

const StarRating: React.FC<React.PropsWithChildren<RatingProps>> = ({
  starCount = 0,
  totalStarCount = 5,
  color = 'black',
}) => {
  if (totalStarCount < starCount) starCount = totalStarCount;

  const roundedStarCount = Math.floor(starCount);

  /**
   * Gets the last filled star in the array.
   * @param starCount The amount of stars that should be filled
   * @returns Filled star, half filled star or an empty value
   */
  const getLastFilledStar = (starCount: number) => {
    const starModulo = starCount % 1;

    // Interval is ]-0.25, 0.25]
    // Value of 4.2 => 4 full stars
    // Value of 4.3 => 4,5 full stars
    if (starModulo < 0.25) return null;
    if (starCount >= 0.75 && (starModulo < 0.25 || starModulo > 0.75))
      return <FullStar color={color} />;
    return <HalfStar color={color} />;
  };

  const FilledStar = getLastFilledStar(starCount);

  const starArray = [
    ...Array(roundedStarCount > 0 ? roundedStarCount : 0).fill(
      <FullStar color={color} />,
    ),
    FilledStar,
    ...Array(
      totalStarCount - (FilledStar ? roundedStarCount + 1 : roundedStarCount),
    ).fill(<EmptyStar color={color} />),
  ];

  return (
    <Flex data-test-id="DesignSystem_StarRating">
      {starArray.map((star) => star)}
    </Flex>
  );
};

export default StarRating;
