import React from 'react';
import { Icon, Text } from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import LouiseChatIntroColumn from './LouiseChatIntroColumn';
import LouiseChatIntroCard from './LouiseChatIntroCard';

const LouiseChatIntroCapabilities: React.FC = () => {
  const { t } = useTranslation();
  return (
    <LouiseChatIntroColumn
      title={t('TOOLKIT_INSURANCE-TERMS-BOT_INTRO_CAPABILITIES')}
      icon={
        <Icon
          name="GeneralOthersOriginOrConnection"
          boxSize={8}
          color="primary.500"
        />
      }
    >
      <LouiseChatIntroCard>
        <Text>{t('TOOLKIT_INSURANCE-TERMS-BOT_INTRO_CAPABILITY_1')}</Text>
      </LouiseChatIntroCard>
      <LouiseChatIntroCard>
        <Text>{t('TOOLKIT_INSURANCE-TERMS-BOT_INTRO_CAPABILITY_2')}</Text>
      </LouiseChatIntroCard>
      <LouiseChatIntroCard>
        <Text>{t('TOOLKIT_INSURANCE-TERMS-BOT_INTRO_CAPABILITY_3')}</Text>
      </LouiseChatIntroCard>
    </LouiseChatIntroColumn>
  );
};

export default LouiseChatIntroCapabilities;
