import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyDistance: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M14.81 29.64a1.21 1.21 0 000-1.71l-1.73-1.72h16.903c.075.015.15.022.227.022h6.81l-1.69 1.72a1.21 1.21 0 000 1.71 1.17 1.17 0 001.69 0l3.73-3.78a1.23 1.23 0 000-1.72l-3.73-3.78a1.169 1.169 0 00-1.73 0 1.21 1.21 0 000 1.71l1.73 1.72H33v-.021H13.08l1.69-1.72a1.21 1.21 0 000-1.71 1.17 1.17 0 00-1.69 0l-3.73 3.78a1.23 1.23 0 000 1.72l3.73 3.78a1.17 1.17 0 001.73 0z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M43.5 19a1.5 1.5 0 011.5 1.5v10a1.5 1.5 0 01-3 0v-10a1.5 1.5 0 011.5-1.5zM6.5 19A1.5 1.5 0 018 20.5v10a1.5 1.5 0 01-3 0v-10A1.5 1.5 0 016.5 19z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyDistance;
