import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { LOCAL_STORAGE_VERSION_KEY } from '../components/VersionCheck';
import { useManifest } from '../features/new-version/api/getManifest';
import ToasterNewVersionAvailable from '../features/new-version/components/ToasterNewVersionAvailable';

const NEW_VERSION_TOAST_ID = 'new-version-toast';
const MANIFEST_REFETCH_INTERVAL = 1000 * 60 * 5; // 5 minutes

/**
 * Will periodically check if there is a new version available
 */
const useNewVersionCheck = (isLoadingApp: boolean) => {
  const { data: manifest } = useManifest(MANIFEST_REFETCH_INTERVAL);
  const toast = useToast();

  const showNewVersionAvailableToast = () => {
    if (!toast.isActive(NEW_VERSION_TOAST_ID))
      toast({
        id: NEW_VERSION_TOAST_ID,
        position: 'bottom-left',
        duration: null,
        render: ({ onClose }) => (
          <ToasterNewVersionAvailable onClose={onClose} />
        ),
      });
  };

  useEffect(() => {
    if (manifest && !isLoadingApp) {
      const lcVersion = localStorage.getItem(LOCAL_STORAGE_VERSION_KEY);

      if (lcVersion !== manifest?.version && manifest?.version) {
        // A version change will automatically happen in the `VersionCheck` component so we don't need to do anything extra here
        showNewVersionAvailableToast();
      }
    }
  }, [manifest, isLoadingApp]);
};

export default useNewVersionCheck;
