import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusInfoOutline: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 6C14.507 6 6 14.507 6 25s8.507 19 19 19 19-8.507 19-19S35.493 6 25 6zM2 25C2 12.297 12.297 2 25 2s23 10.297 23 23-10.297 23-23 23S2 37.703 2 25z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 15.667a2 2 0 012-2h.023a2 2 0 010 4H25a2 2 0 01-2-2zM20.667 25a2 2 0 012-2H25a2 2 0 012 2v7.333h.333a2 2 0 010 4H25a2 2 0 01-2-2V27h-.333a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralStatusInfoOutline;
