import { wgApi } from '../../../api/wgApi';
import { useQuery, UseQueryResult } from 'react-query';
import { User } from '../../types/User';

const getBrokerById = async (bid: string) => {
  const { data } = await wgApi.get<User>(`v1/api/brokers/${bid}`);
  return data;
};

export const getBrokerByIdQKey = (bid: string): string[] => ['broker', bid];

export const useBroker = (bid?: string): UseQueryResult<User> => {
  return useQuery(getBrokerByIdQKey(bid!), () => getBrokerById(bid!), {
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!bid,
  });
};
