import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleBaPlus: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M37 29.62a1.44 1.44 0 00-1-.84l-2.09-.45-16.22-3.52a1.32 1.32 0 01-.82-.53l-3.72-5a.84.84 0 00-.44-.28l-3.22-.6c-.93-.16-1.86-.32-2.8-.44a.61.61 0 00-.69.61V40h13a6.5 6.5 0 1013 0h4.51a1.5 1.5 0 00.5-.79c.505-1.973.88-3.977 1.12-6-.21-1.243-.59-2.45-1.13-3.59zM25.5 43a3.5 3.5 0 01-3.5-3 2.747 2.747 0 010-.5 3.5 3.5 0 017 0c.015.166.015.334 0 .5a3.5 3.5 0 01-3.5 3zM38.7 15.28l-9.1 10.06-2.42-.52 1.72-4.6a.2.2 0 00-.096-.248.2.2 0 00-.094-.022h-8.29a.21.21 0 01-.16-.33l12-14.75a.2.2 0 01.34.2l-3.5 9.61a.2.2 0 00.096.248.2.2 0 00.094.022h9.26a.2.2 0 01.15.33z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleBaPlus;
