import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyTerraced: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillOpacity="0.3"
      d="M31.546 18.57l-3.558-2.389v-5.357a1 1 0 00-1-1H22.99a1 1 0 00-1 1v1.34l-4.447-3a1 1 0 00-1.1 0L2.45 18.55a1 1 0 00-.45.84V40a1 1 0 001 1h5.997v-9.995a1 1 0 011-1h4.997a1 1 0 011 1V41h14.993a1 1 0 001-1V19.41a1 1 0 00-.44-.84z"
    ></path>
    <path
      fill="currentColor"
      fillOpacity="0.3"
      d="M47.164 18.57l-3.558-2.389v-5.357a1 1 0 00-1-1h-3.998a1 1 0 00-1 1v1.34l-4.447-3a1 1 0 00-1.1 0L18.068 18.55a1 1 0 00-.45.84V40a1 1 0 001 1h5.997v-9.995a1 1 0 011-1h4.997a1 1 0 011 1V41h14.992a1 1 0 001-1V19.41a.999.999 0 00-.44-.84z"
    ></path>
    <path
      fill="currentColor"
      d="M39.543 18.57l-3.559-2.389v-5.357a1 1 0 00-1-1h-3.997a1 1 0 00-1 1v1.34l-4.448-3a1 1 0 00-1.1 0L10.447 18.55a1 1 0 00-.45.84V40a1 1 0 001 1h5.997v-9.995a1 1 0 011-1h4.997a1 1 0 011 1V41h14.993a1 1 0 001-1V19.41a1 1 0 00-.44-.84z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyTerraced;
