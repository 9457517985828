import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskAnalysisProductsWheelchair: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 25a8 8 0 100 16 8 8 0 000-16zM5 33c0-6.627 5.373-12 12-12 6.628 0 12 5.373 12 12s-5.372 12-12 12c-6.627 0-12-5.373-12-12zM39 37a2 2 0 100 4 2 2 0 000-4zm-6 2a6 6 0 1112 0 6 6 0 01-12 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.2 29a2 2 0 012-2H33a8 8 0 018 8 2 2 0 11-4 0 4 4 0 00-4-4h-6.8a2 2 0 01-2-2zM5 7a2 2 0 012-2h2a6 6 0 016 6v12a2 2 0 11-4 0V11a2 2 0 00-2-2H7a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 17a2 2 0 012-2h22a2 2 0 010 4H13a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31 15a2 2 0 012 2v12a2 2 0 11-4 0V17a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsWheelchair;
