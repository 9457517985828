import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusInfo: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25.155 11C17.337 11 11 17.337 11 25.155S17.337 39.31 25.155 39.31 39.31 32.973 39.31 25.155 32.973 11 25.155 11zm0 5.539a1.846 1.846 0 110 3.692 1.846 1.846 0 010-3.692zm2.462 16.617h-4.923v-1.231h1.23v-8h-1.23v-1.232h3.692v9.231h1.23v1.232z"
    ></path>
  </Icon>
);

export default GeneralStatusInfo;
