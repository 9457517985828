import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralLabelsDocumentDocx: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="#0073DD"
      fillRule="evenodd"
      d="M37.28 16h-7a.29.29 0 01-.3-.3v-7a.3.3 0 01.51-.21l7 7a.3.3 0 01-.21.51z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#0073DD"
      fillRule="evenodd"
      d="M28.3 18h9.4a.29.29 0 01.3.3v23.4a.29.29 0 01-.3.3H12.3a.29.29 0 01-.3-.3V8.3a.29.29 0 01.3-.3h15.4a.29.29 0 01.3.3v9.4a.29.29 0 00.3.3zm-5.469 9.798c-.786 0-1.593.547-1.593 1.698 0 1.152.807 1.699 1.593 1.699.793 0 1.6-.547 1.6-1.699 0-1.151-.807-1.698-1.6-1.698zm0 4.361c-1.384 0-2.625-1.014-2.625-2.655 0-1.649 1.24-2.663 2.625-2.663 1.392 0 2.632 1.014 2.632 2.663 0 1.64-1.24 2.655-2.632 2.655zm-6.594-4.29v3.261h.793c.837 0 1.536-.518 1.536-1.626 0-1.109-.692-1.634-1.528-1.634h-.8zm3.36 1.635c0 1.612-1.074 2.547-2.53 2.547h-1.825v-5.103h1.832c1.456 0 2.523.943 2.523 2.556zm9.072 2.655c1.434 0 2.17-.95 2.365-1.734l-.93-.28c-.137.474-.563 1.043-1.435 1.043-.823 0-1.587-.598-1.587-1.684 0-1.16.808-1.706 1.572-1.706.887 0 1.284.54 1.406 1.029l.938-.295c-.202-.828-.93-1.691-2.344-1.691-1.37 0-2.603 1.036-2.603 2.663 0 1.626 1.19 2.655 2.618 2.655zm5.92-5.21h1.161l-1.672 2.555 1.68 2.547h-1.205l-1.103-1.763-1.11 1.763h-1.168l1.68-2.555-1.673-2.547h1.197l1.096 1.763 1.117-1.764z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralLabelsDocumentDocx;
