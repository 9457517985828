import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationJewellery: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M117.995 199.94c-11.125-.001-21.968-3.563-30.995-10.179V226h63v-37c-9.212 7.098-20.45 10.94-32.005 10.94Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M98.67 90.453v7.528a7.432 7.432 0 0 1-5.11 7.072l-24.746 8.263A12.871 12.871 0 0 0 60 125.537v11.253a66.112 66.112 0 0 0 23.812 50.816A53.44 53.44 0 0 0 118 200a53.406 53.406 0 0 0 34.18-12.394 66.16 66.16 0 0 0 17.569-22.759A66.12 66.12 0 0 0 176 136.79v-11.286a12.894 12.894 0 0 0-8.814-12.221l-24.754-8.263a7.44 7.44 0 0 1-5.101-7.073v-7.51a6.428 6.428 0 0 0-1.887-4.552A6.441 6.441 0 0 0 130.89 84h-25.78a6.44 6.44 0 0 0-6.44 6.453Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.645 90.307v4.842h-16.141v-4.842h16.141ZM107.2 93.766a4.766 4.766 0 1 1-9.531-.001 4.766 4.766 0 0 1 9.531 0Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M96.67 99.145a5.303 5.303 0 1 1-10.607 0 5.303 5.303 0 0 1 10.606 0Z"
          fill={colors.primary[100]}
        />
        <path
          d="M96.67 99.145a5.303 5.303 0 1 1-10.607 0 5.303 5.303 0 0 1 10.606 0"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.907 108.753a5.687 5.687 0 1 1-11.374 0 5.687 5.687 0 0 1 11.374 0Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.605 122.048a6.302 6.302 0 0 1-6.303 6.302 6.302 6.302 0 1 1 0-12.604 6.303 6.303 0 0 1 6.303 6.302Z"
          fill={colors.primary[100]}
        />
        <path
          d="M82.605 122.048a6.302 6.302 0 0 1-6.303 6.302 6.302 6.302 0 1 1 0-12.604 6.303 6.303 0 0 1 6.303 6.302"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.605 137.345a6.302 6.302 0 0 1-6.303 6.302 6.302 6.302 0 1 1 0-12.604 6.303 6.303 0 0 1 6.303 6.302Z"
          fill={colors.primary[100]}
        />
        <path
          d="M82.605 137.345a6.302 6.302 0 0 1-6.303 6.302 6.302 6.302 0 1 1 0-12.604 6.303 6.303 0 0 1 6.303 6.302"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.522 151.563a6.686 6.686 0 0 1-6.687 6.686 6.686 6.686 0 1 1 0-13.373 6.687 6.687 0 0 1 6.687 6.687ZM100.513 163.4a7.147 7.147 0 1 1-14.295 0 7.148 7.148 0 1 1 14.295 0ZM101.281 170.087c.385 4.073 4.074 7.071 8.148 6.686 4.073-.384 7.071-4.073 6.686-8.147-.384-4.073-4.073-7.071-8.147-6.687-4.151.385-7.148 4.074-6.687 8.148Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.405 94.073a4.765 4.765 0 1 1-9.53 0 4.765 4.765 0 0 1 9.53 0Z"
          fill={colors.primary[100]}
        />
        <path
          d="M137.405 94.073a4.765 4.765 0 1 1-9.53 0 4.765 4.765 0 0 1 9.53 0"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M148.856 99.76a5.303 5.303 0 1 1-10.605 0 5.303 5.303 0 0 1 10.605 0ZM158.156 109.521a5.687 5.687 0 1 1-11.375 0 5.687 5.687 0 0 1 11.375 0ZM164.458 122.894a6.303 6.303 0 1 1-12.605-.001 6.303 6.303 0 0 1 12.605.001ZM164.151 138.267a6.302 6.302 0 1 1-6.302-6.303 6.302 6.302 0 0 1 6.302 6.303ZM158.617 152.332a6.686 6.686 0 1 1-13.373.001 6.686 6.686 0 0 1 13.373-.001Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M147.32 163.937a7.149 7.149 0 1 1-14.295-.001 7.149 7.149 0 0 1 14.295.001Z"
          fill={colors.primary[100]}
        />
        <path
          d="M147.32 163.937a7.149 7.149 0 1 1-14.295-.001 7.149 7.149 0 0 1 14.295.001"
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M132.101 170.316c-.461 4.073-4.226 6.994-8.3 6.456-4.074-.461-6.994-4.227-6.456-8.301.461-4.073 4.227-6.994 8.301-6.456 4.073.538 6.994 4.228 6.455 8.301Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M98.67 97.98v-7.527a6.433 6.433 0 0 1 3.972-5.962 6.449 6.449 0 0 1 2.469-.491h25.78a6.441 6.441 0 0 1 6.44 6.437v7.51a7.431 7.431 0 0 0 5.102 7.073l-48.872.033a7.436 7.436 0 0 0 5.11-7.072Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M254 225.5v-96M215 127h78"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx={228.041}
          cy={126.653}
          r={2.653}
          stroke={colors.primary[500]}
          strokeWidth={3}
        />
        <path
          d="M228.041 129.306v23.061"
          stroke={colors.primary[500]}
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M216 166.347h12.041V152.47L216 166.347ZM240.082 166.347h-12.041V152.47l12.041 13.877ZM222.837 174 216 166.347h12.041L222.837 174ZM233.245 174l6.837-7.653h-12.041l5.204 7.653Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M233.246 174h-10.409l5.204-7.551 5.205 7.551Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle
          cx={282.041}
          cy={126.653}
          r={2.653}
          stroke={colors.primary[500]}
          strokeWidth={3}
        />
        <path
          d="M282.041 129.306v23.061"
          stroke={colors.primary[500]}
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M270 166.347h12.041V152.47L270 166.347ZM294.082 166.347h-12.041V152.47l12.041 13.877ZM276.837 174 270 166.347h12.041L276.837 174ZM287.245 174l6.837-7.653h-12.041l5.204 7.653Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M287.246 174h-10.409l5.204-7.551 5.205 7.551Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={3}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationJewellery;
