import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubjectTypes } from '../../../../../../types/RiskAnalaysis';

export interface SelectionController {
  flowType: string | undefined;
  customerTypes: string[];
  subjects: SubjectTypes[];
}

const initialState: SelectionController = {
  flowType: '',
  customerTypes: ['INDIVIDUAL'],
  subjects: [
    SubjectTypes.MOBILITY,
    SubjectTypes.HOME_AND_FAMILY,
    SubjectTypes.TRAVEL_AND_LEISURE,
    SubjectTypes.HEALTH,
    SubjectTypes.LIFE_AND_FUTURE,
    SubjectTypes.HOBBIES_AND_INTERESTS,
    SubjectTypes.LEGAL,
  ],
};
const selectionSlice = createSlice({
  name: 'riskAnalysisSelection',
  initialState: initialState,
  reducers: {
    setFlowType(state, action: PayloadAction<string>) {
      const { payload } = action;
      state.flowType = payload;
    },
    setCustomerTypes(state, action: PayloadAction<string[]>) {
      const { payload } = action;
      state.customerTypes = payload;
    },
    setSubjects(state, action: PayloadAction<SubjectTypes[]>) {
      const { payload } = action;
      state.subjects = payload;
    },
    resetSelection() {
      return initialState;
    },
  },
});

export const {
  setFlowType,
  setCustomerTypes,
  setSubjects,
  resetSelection,
} = selectionSlice.actions;

export default selectionSlice.reducer;
