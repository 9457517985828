import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationExtreme: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3824_10635"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3824_10635)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M177.474 16.04c-36.794 0-69.117 28.54-69.117 73.242 7.221-17.537 39.544-15.13 45.046 0 9.628-16.85 40.576-15.474 47.797 0 6.878-14.786 35.418-16.85 45.046 0 0-41.951-28.196-73.243-68.772-73.243z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M172.321 21.195c-9.885 11.806-14.921 19.227-16.505 34.043M154.097 65.898v7.91M182.977 21.195c9.885 11.806 14.922 19.227 16.505 34.043M201.202 65.898v7.91M108.703 88.94L176.1 161.15M153.405 88.94l23.039 72.555M201.546 88.94l-25.102 72.555M246.249 88.94l-69.805 72.555"
        ></path>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M181.437 143.857c3.805 1.04 6.281 2.476 8.981 5.212 2.329 2.358 3.479 4.303 4.388 7.421.309 1.058.324 1.577.377 12.64.054 11.457.052 11.542-.279 12.312-.617 1.434-1.839 2.23-3.426 2.23-1.141 0-1.888-.316-2.667-1.129-1.025-1.069-.993-.698-.993-11.459 0-5.369-.043-9.874-.095-10.011-.056-.145-.28-.249-.535-.249s-.479.104-.535.249c-.052.137-.095 4.575-.095 9.861v9.612l-1.181 14.838c-.649 8.161-1.25 15.254-1.335 15.762-.361 2.169-2.024 3.781-4.349 4.215-1.356.253-5.299.094-6.259-.252-1.024-.369-2.364-1.626-2.867-2.689-.394-.832-.442-1.314-1.662-16.491l-1.256-15.635v-9.486c0-5.217-.043-9.598-.096-9.735-.134-.349-.805-.312-1.001.055-.108.201-.164 3.622-.164 10.011 0 10.7.032 10.335-.992 11.404-.856.892-1.572 1.169-2.854 1.103-.952-.05-1.227-.134-1.862-.573-.412-.285-.94-.846-1.182-1.258l-.437-.743-.048-10.636c-.031-6.956.012-11.152.124-12.126.743-6.461 6.012-12.243 13.003-14.268 2.084-.604 2.897-.697 5.515-.632 1.89.048 2.647.137 3.782.447z"
          clipRule="evenodd"
        ></path>
        <rect
          width="14.257"
          height="18.314"
          x="170.024"
          y="121.596"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="7.128"
        ></rect>
        <mask id="path-15-inside-1_3824_10635" fill="#fff">
          <path
            fillRule="evenodd"
            d="M98.565 114.649a9.928 9.928 0 014.917-1.295c5.507 0 9.972 4.465 9.972 9.972 0 1.425-.299 2.78-.837 4.006a10.664 10.664 0 016.339 9.748c0 5.887-4.773 10.66-10.66 10.66h-42.64c-5.886 0-10.659-4.773-10.659-10.66 0-5.543 4.23-10.098 9.639-10.612.337-10.339 8.825-18.616 19.246-18.616 5.883 0 11.15 2.638 14.683 6.797z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M98.565 114.649a9.928 9.928 0 014.917-1.295c5.507 0 9.972 4.465 9.972 9.972 0 1.425-.299 2.78-.837 4.006a10.664 10.664 0 016.339 9.748c0 5.887-4.773 10.66-10.66 10.66h-42.64c-5.886 0-10.659-4.773-10.659-10.66 0-5.543 4.23-10.098 9.639-10.612.337-10.339 8.825-18.616 19.246-18.616 5.883 0 11.15 2.638 14.683 6.797z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M98.565 114.649l-3.049 2.589 2.144 2.524 2.88-1.635-1.975-3.478zm14.052 12.683l-3.663-1.608-1.602 3.648 3.642 1.616 1.623-3.656zm-47.981-.864l.378 3.982 3.505-.333.115-3.518-3.998-.131zm35.904-8.341a5.927 5.927 0 012.942-.773v-8c-2.499 0-4.855.659-6.893 1.816l3.951 6.957zm2.942-.773a5.972 5.972 0 015.972 5.972h8c0-7.717-6.256-13.972-13.972-13.972v8zm5.972 5.972a5.94 5.94 0 01-.5 2.398l7.325 3.216a13.933 13.933 0 001.175-5.614h-8zm1.54 7.662a6.664 6.664 0 013.962 6.092h8c0-5.988-3.589-11.128-8.717-13.404l-3.245 7.312zm3.962 6.092a6.66 6.66 0 01-6.66 6.66v8c8.096 0 14.66-6.564 14.66-14.66h-8zm-6.66 6.66h-42.64v8h42.64v-8zm-42.64 0a6.66 6.66 0 01-6.659-6.66h-8c0 8.096 6.563 14.66 14.66 14.66v-8zm-6.659-6.66a6.661 6.661 0 016.017-6.63l-.757-7.964c-7.442.708-13.26 6.969-13.26 14.594h8zm9.637-10.481c.267-8.189 6.992-14.747 15.248-14.747v-8c-12.587 0-22.837 9.997-23.244 22.486l7.996.261zm15.248-14.747c4.66 0 8.83 2.084 11.634 5.386l6.097-5.179c-4.26-5.015-10.624-8.207-17.731-8.207v8z"
          mask="url(#path-15-inside-1_3824_10635)"
        ></path>
        <mask id="path-17-inside-2_3824_10635" fill="#fff">
          <path
            fillRule="evenodd"
            d="M268.64 149.613a6.534 6.534 0 019.206 8.309 6.987 6.987 0 014.154 6.387 6.984 6.984 0 01-6.984 6.984H247.08a6.984 6.984 0 01-.669-13.936c.221-6.774 5.782-12.197 12.609-12.197a12.59 12.59 0 019.62 4.453z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M268.64 149.613a6.534 6.534 0 019.206 8.309 6.987 6.987 0 014.154 6.387 6.984 6.984 0 01-6.984 6.984H247.08a6.984 6.984 0 01-.669-13.936c.221-6.774 5.782-12.197 12.609-12.197a12.59 12.59 0 019.62 4.453z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M268.64 149.613l-3.049 2.59 2.144 2.523 2.88-1.634-1.975-3.479zm9.206 8.309l-3.663-1.607-1.601 3.648 3.642 1.616 1.622-3.657zm-31.435-.565l.378 3.982 3.505-.333.115-3.518-3.998-.131zm24.204-4.265a2.5 2.5 0 011.246-.327v-8c-1.882 0-3.659.497-5.196 1.37l3.95 6.957zm1.246-.327a2.534 2.534 0 012.533 2.534h8c0-5.818-4.716-10.534-10.533-10.534v8zm2.533 2.534c0 .369-.076.71-.211 1.016l7.326 3.215c.57-1.3.885-2.734.885-4.231h-8zm1.83 6.28a2.984 2.984 0 011.776 2.73h8c0-4.488-2.691-8.339-6.532-10.043l-3.244 7.313zm1.776 2.73a2.984 2.984 0 01-2.984 2.984v8c6.066 0 10.984-4.917 10.984-10.984h-8zm-2.984 2.984H247.08v8h27.936v-8zm-27.936 0a2.984 2.984 0 01-2.984-2.984h-8c0 6.067 4.918 10.984 10.984 10.984v-8zm-2.984-2.984a2.985 2.985 0 012.693-2.97l-.757-7.964c-5.576.53-9.936 5.221-9.936 10.934h8zm6.313-6.821a8.616 8.616 0 018.611-8.328v-8c-8.993 0-16.316 7.143-16.607 16.067l7.996.261zm8.611-8.328a8.587 8.587 0 016.571 3.043l6.098-5.18a16.593 16.593 0 00-12.669-5.863v8z"
          mask="url(#path-17-inside-2_3824_10635)"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationExtreme;
