import useIsAuthorized from '../../hooks/useIsAuthorized';
import { isSocialLoginUrl } from '../../constants/SocialAuth';
import { wgApi } from '../../../api/wgApi';
import { useQuery, UseQueryResult } from 'react-query';
import { User } from '../../types/User';
import { ErrorResponse } from '../../../../../../../types/API';
import { queryClient } from '../../../../providers/queryClient';

const getUser = async () => {
  const { data, isError } = await wgApi.get<User>('v1/api/brokers/me');
  if (isError) {
    const error = data as ErrorResponse;
    throw new Error(error.msg || 'RESOURCE_NOT_FOUND');
  }
  return data;
};

export const getUserQKey = (): string[] => ['user'];

export const getUserDataFromQStore = (): User => {
  // User will always be defined, otherwise the application won't load
  return queryClient.getQueryData<User>(getUserQKey())!;
};

export const useUser = (
  onError?: (error: Error) => void,
): UseQueryResult<User> => {
  const isAuthorized = useIsAuthorized();

  return useQuery(getUserQKey(), getUser, {
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: isAuthorized && !isSocialLoginUrl,
    onError,
  });
};
