import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceOldtimerCasco: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_1864_6863"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_1864_6863)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M294.11 116.376a5.13 5.13 0 00-5.124 5.128v17.936h10.252v-17.936a5.127 5.127 0 00-5.128-5.128z"
        ></path>
        <circle
          r="33.5"
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 298.694 190.5)"
        ></circle>
        <circle
          r="21.5"
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 298.694 190.5)"
        ></circle>
        <circle
          r="33.5"
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 58.694 190.5)"
        ></circle>
        <circle
          r="21.5"
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 58.694 190.5)"
        ></circle>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M222.362 183.004H118.394a25.356 25.356 0 01-21.972-13.38 43.448 43.448 0 00-70.603-7.836 5.128 5.128 0 01-7.095.278 5.125 5.125 0 01-.562-7.078 53.697 53.697 0 0170.027-9.051 53.697 53.697 0 0117.205 18.739 14.905 14.905 0 0013 8.092h103.968c11.66 0 20.444-7.248 30.608-15.64 11.488-9.484 24.508-20.236 43.704-20.236a65.404 65.404 0 0138.8 13.72 5.121 5.121 0 011.312 7.128 5.123 5.123 0 01-7.128 1.312 55.63 55.63 0 00-32.968-11.908c-15.512 0-26.036 8.688-37.176 17.888-10.72 8.828-21.804 17.972-37.152 17.972z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M58.363 136.876a53.809 53.809 0 0147.052 27.784 14.895 14.895 0 0013 8.092h103.948c11.66 0 20.444-7.248 30.608-15.64 9.88-8.156 20.92-17.2 36-19.6v-16h-61.204a15.661 15.661 0 01-15.656-15.656V67.5c.065-1.544-2.777-7.755-7.688-7.496h-143.5a10.248 10.248 0 00-10.248 10.244v67.256a53.495 53.495 0 017.688-.628z"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M104.487 121.504v15.512a13.275 13.275 0 003.284 8.736l10.688 12.22a13.265 13.265 0 0010 4.532h24.716v-41h-48.688z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M104.486 70.252H71.194a7.712 7.712 0 00-7.712 7.712v28.156a15.384 15.384 0 0015.384 15.384h25.6l.02-51.252zM104.487 70.252h48.688V121.5h-48.688V70.252z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M201.862 121.504V77.96a7.71 7.71 0 00-7.708-7.708h-40.98v51.252h48.688z"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M153.174 121.504v41h40.996a7.683 7.683 0 005.444-2.257 7.702 7.702 0 002.248-5.447v-33.296h-48.688zM135.239 131.752h7.684M163.422 131.752h7.688"
        ></path>
        <g filter="url(#filter0_dd_1864_6863)">
          <circle cx="233" cy="85" r="55" fill="#fff"></circle>
          <circle
            cx="233"
            cy="85"
            r="53"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M216.768 110.029a1.994 1.994 0 00-.268 1.386l1.394 8.126a2.001 2.001 0 003.911.149l1.71-6.791 27.798-45.168c1.469-2.388 2.702-5.36 2.527-8.436-.184-3.23-1.898-6.217-5.597-8.493-6.09-3.748-11.509-.727-13.631 1.41l-10.701 7.32a2 2 0 00.081 3.354l1.122.69a2 2 0 002.098 0l4.261-2.627-.492 4.915a1.998 1.998 0 00.942 1.902l1.346.83a2 2 0 003.019-1.364l1.021-5.91.986-1.603c2.008-3.262 4.698-3.327 6.236-2.411a3.504 3.504 0 011.652 2.358c.208 1.046.042 2.499-1.045 4.265l-28.37 46.098z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_1864_6863"
          width="142"
          height="142"
          x="162"
          y="22"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1864_6863"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_1864_6863"
            result="effect2_dropShadow_1864_6863"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1864_6863"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationInsuranceOldtimerCasco;
