import React from 'react';
import { useFormContext } from 'react-hook-form';
import StrokeBox from '../../../../../../design-system/src/content/components/Card/common/StrokeBox';
import { LouiseQuestionFormValues } from '../../types/Form';
import QuestionBodyHeader from './QuestionBodyHeader';
import QuestionBodyInputAndFooter from './QuestionBodyInputAndFooter';

interface Props {
  onSubmit: () => void;
}

const QuestionBody: React.FC<Props> = ({ onSubmit }) => {
  const {
    watch,
    formState: { isValid, isDirty, isSubmitting, isSubmitSuccessful },
  } = useFormContext<LouiseQuestionFormValues>();

  const isDisabled = isSubmitting || isSubmitSuccessful;

  return (
    <StrokeBox p="0">
      <QuestionBodyHeader isDisabled={isDisabled} />
      <QuestionBodyInputAndFooter
        onSubmit={onSubmit}
        showInput={!!watch('subject')}
        isDisabled={isDisabled}
        isSuccess={isSubmitSuccessful}
        isSubmitDisabled={!isValid || !isDirty || isSubmitting}
      />
    </StrokeBox>
  );
};

export default QuestionBody;
