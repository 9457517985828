import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationManageSportAssociation: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3805_10508"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3805_10508)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <rect
          width="120.795"
          height="152.172"
          x="117.102"
          y="49.828"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="10"
        ></rect>
        <rect
          width="96.549"
          height="127.926"
          x="129.225"
          y="61.951"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6"
        ></rect>
        <rect
          width="60.893"
          height="24.525"
          x="147.052"
          y="44.123"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6"
        ></rect>
        <circle
          cx="177.856"
          cy="41.41"
          r="9.41"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M160.384 61.734h33.873M142.557 93.111h60.971M142.557 110.939h60.971M142.557 130.906h42.073M148.614 151.23c-8.195 8.201 7.491 23.533 14.975 6.775 1.787 9.984 11.414 10.697 14.262 1.426 2.512 6.742 5.628 7.482 12.123 7.131"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationManageSportAssociation;
