import { Flex, BoxProps } from '@wegroup/design-system';
import { forwardRef } from '@chakra-ui/react';

const StrokeBox = forwardRef<BoxProps, 'div'>(({ children, ...props }, ref) => {
  return (
    <Flex
      flexDir="column"
      bg="white"
      boxShadow="level1"
      borderRadius="lg"
      border="1px solid"
      borderColor="gray.strokeAndBg"
      p="4"
      sx={{
        '@media print': {
          boxShadow: 'none',
          borderColor: 'gray.300',
          breakInside: 'avoid',
        },
      }}
      overflow="hidden"
      ref={ref}
      {...props}
    >
      {children}
    </Flex>
  );
});

export default StrokeBox;
