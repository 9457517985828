import {
  FormControl,
  FormLabel,
  SearchSelect,
  CountrySelectWithFlag,
  HStack,
  InputGroup,
} from '../../../../../../index';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressInputProps } from '../..';
import { ErrorResponse } from '../../../../../../../../types/API';
import { Address } from '../../../../../../../../types/Common';
import useIsCaribbean from '../../../../../../../common/src/hooks/useIsCaribbean';
import { formatAddress } from '../../../../../../../common/src/utils/commonUtils';
import { MINIMUM_REQUIRED_CHARS, useAddressSearch } from '../../api/getAddress';
import useDebouncedSearchSelectQuery from '../../hooks/useDebouncedQuery';
import useNothingFoundText from '../../hooks/useNothingFoundText';
import { isAddressObjectComplete } from '../../utils/address';
import AddressSearchFooter from '../AddressSearchFooter';
import { useLocale } from '../../../../../../../../i18n';
import { AddressSearchProps } from '../AddressSearchPicker';
import { isStaging } from '../../../../../../../common/src/utils/globalVariables';

interface Props {
  onCountryChange: (country: string) => void;
}

const AddressSearch: React.FC<
  AddressInputProps & AddressSearchProps & Props
> = ({
  name,
  value,
  onChange,
  onBlur,
  onManualModeOn,
  country,
  error,
  isPrefilled: isPrefilledProp,
  rightElement,
  isManualAllowed,
  'data-test-id': dataTestId,
  isDisabled,
  onCountryChange,
  supportedCountries,
  withLabel,
}) => {
  const { userLang } = useLocale();
  const { t } = useTranslation();
  const isCaribbean = useIsCaribbean();

  const [isPrefilled, setIsPrefilled] = useState(
    () => value && isPrefilledProp,
  );

  const { query, handleSearchChange } = useDebouncedSearchSelectQuery();

  const [customNothingFoundText, setCustomNothingFoundText] =
    useState<string>('');

  const handleOnError = (error: ErrorResponse) => {
    if (error?.msg === 'Query should at least contain one digit') {
      setCustomNothingFoundText(
        'DESIGN-SYSTEM_ADDRESS-INPUT_ADDRESS-SEARCH_DIGIT-ERROR',
      );
    }
  };

  const { data: addressList, isLoading } = useAddressSearch(
    {
      query,
      countryCode: country,
    },
    handleOnError,
  );

  useEffect(() => {
    if (query.length >= MINIMUM_REQUIRED_CHARS) {
      if (/\d/.test(query) || isCaribbean) {
        setCustomNothingFoundText('');
      } else {
        setCustomNothingFoundText(
          'DESIGN-SYSTEM_ADDRESS-INPUT_ADDRESS-SEARCH_DIGIT-ERROR',
        );
      }
    }
  }, [query]);

  const nothingFoundText = useNothingFoundText({
    customText: customNothingFoundText || '',
    isLoading,
    query,
    requiredChars: MINIMUM_REQUIRED_CHARS,
  });

  const getAddressOptions = (addressList?: Address[]) => {
    if (addressList)
      return addressList?.map((address) => ({
        value: JSON.stringify(address),
        label: formatAddress(address),
      }));

    if (value && isAddressObjectComplete(value)) {
      return [
        {
          value: JSON.stringify(value),
          label: formatAddress(value),
        },
      ];
    }

    return [];
  };

  const handleOnChange = (addressJSON: string) => {
    const addressObj = JSON.parse(addressJSON);
    onChange(addressObj);
    if (addressObj) {
      onManualModeOn();
    }
    setIsPrefilled(false);
  };

  const handleFooterClick = () => {
    onManualModeOn();
    onChange({ country_code: country });
  };

  return (
    <FormControl isInvalid={!!error}>
      {withLabel && <FormLabel>{t('ADDRESS')}</FormLabel>}

      <HStack spacing="4" align="flex-start">
        <InputGroup>
          <CountrySelectWithFlag
            onlyFlag
            isPrefilled={isPrefilled}
            value={country}
            onChange={(_country) => onCountryChange(_country)}
            lang={userLang}
            supportedCountries={supportedCountries}
            isAttached
          />
          <SearchSelect
            name={name}
            data={getAddressOptions(addressList)}
            isLoading={isLoading}
            value={
              isAddressObjectComplete(value) ? JSON.stringify(value) : null
            }
            onChange={handleOnChange}
            onBlur={onBlur}
            onSearchChange={handleSearchChange}
            placeholder={
              isCaribbean
                ? t(
                    'DESIGN-SYSTEM_ADDRESS-INPUT_ADDRESS-SEARCH_HELPER-TEXT_STREET-ONLY',
                  )
                : t('DESIGN-SYSTEM_ADDRESS-INPUT_ADDRESS-SEARCH_PLACEHOLDER')
            }
            nothingFound={nothingFoundText}
            prefilled={isPrefilled}
            clearable
            isDataFilterDisabled
            flexGrow={1}
            data-test-id={dataTestId}
            isDisabled={isDisabled}
            extraStyles={{
              input: {
                // Using the !important to overwrite focus, hoverstates
                borderBottomLeftRadius: '0 !important',
                borderTopLeftRadius: '0 !important',
              },
            }}
            // TODO: Disabled because this made tests fail, look into a way to pass data-test-id to portal
            {...(!isStaging && { withinPortal: true })}
          />
        </InputGroup>
        {rightElement}
      </HStack>
      <AddressSearchFooter
        label={
          isCaribbean
            ? t(
                'DESIGN-SYSTEM_ADDRESS-INPUT_ADDRESS-SEARCH_HELPER-TEXT_STREET-ONLY',
              )
            : t('DESIGN-SYSTEM_ADDRESS-INPUT_ADDRESS-SEARCH_HELPER-TEXT')
        }
        error={error}
        onClick={handleFooterClick}
        isManualAllowed={isManualAllowed}
      />
    </FormControl>
  );
};

export default AddressSearch;
