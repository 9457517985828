import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsFlightCancelled: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M35 31.79a8 8 0 100 16 8 8 0 000-16zm3.54 10.12a1.007 1.007 0 010 1.42 1.003 1.003 0 01-1.42 0L35 41.2l-2.12 2.13a1.004 1.004 0 11-1.42-1.42l2.13-2.12-2.13-2.12a1.004 1.004 0 111.42-1.42L35 38.38l2.12-2.13a1.003 1.003 0 011.638.326 1.005 1.005 0 01-.218 1.094l-2.13 2.12 2.13 2.12z"
    ></path>
    <path
      fill="currentColor"
      d="M43.84 8.5l-.74 1.69a14 14 0 01-3.1 4.47L33.51 21l2.64 8.88a8.765 8.765 0 00-1.15-.09 9.89 9.89 0 00-5.45 1.62l-2.41-4.25-9.74 9.47.92 4.67c.049.398-.059.8-.3 1.12l-1.67 2.12a.6.6 0 01-1-.13l-2.69-6.35a1.72 1.72 0 00-.91-.91l-6.35-2.7a.6.6 0 01-.14-1l2.13-1.67a1.44 1.44 0 011.11-.3l4.68.91 9.46-9.74-14.39-8.17A.81.81 0 018 13.25l1.81-2.12a.82.82 0 01.85-.24l18.1 5.38 6.3-6.49a14 14 0 014.5-3.11L41.24 6a2 2 0 011.94.18 2 2 0 01.66 2.32z"
    ></path>
  </Icon>
);

export default RiskObjectsFlightCancelled;
