import {
  VStack,
  Divider,
  HStack,
  Text,
  Center,
  Tag,
  BoxProps,
  Tooltip,
} from '@wegroup/design-system';
import React from 'react';
import SmallBullet from '../SmallBullet';

interface Props {
  title: string;
  description: string;
  type?: string;
  icon: React.ReactElement;
  onClick: () => void;
  isSelected: boolean;
  disabled?: boolean;
  tooltipLabel?: string;
  customH?: string;
  'data-test-id'?: string;
}

const RadioBoxSelect: React.FC<React.PropsWithChildren<Props & BoxProps>> = ({
  title,
  description,
  type,
  icon,
  onClick,
  isSelected,
  disabled,
  tooltipLabel,
  customH,
  'data-test-id': dataTestId,
  ...props
}) => {
  return (
    <Tooltip label={tooltipLabel} isDisabled={!disabled} shouldWrapChildren>
      <VStack
        h="100%"
        spacing="3"
        bg="white"
        p="4"
        divider={<Divider borderColor="gray.50" />}
        boxShadow="level2"
        borderRadius="md"
        align="stretch"
        border="1px"
        borderColor={isSelected ? 'primary.500' : 'gray.50'}
        onClick={onClick}
        opacity={disabled ? '0.6' : '1'}
        cursor="pointer"
        data-test-id={dataTestId}
        {...props}
      >
        <HStack spacing="4" h={customH ? customH : 'auto'}>
          {icon && (
            <Center
              bg={isSelected ? 'primary.50' : 'gray.strokeAndBg'}
              flexShrink={0}
              borderRadius="md"
              w="12"
              h="12"
              color={isSelected ? 'primary.500' : 'gray.300'}
            >
              {icon}
            </Center>
          )}
          <VStack flexGrow={1} align="flex-start">
            <Text
              textStyle="largeBodyMedium"
              color={isSelected ? 'primary.500' : 'gray.700'}
            >
              {title}
            </Text>
            {type && (
              <Tag variant={isSelected ? 'default' : 'gray'}>{type}</Tag>
            )}
          </VStack>
          <SmallBullet isChecked={isSelected} />
        </HStack>

        <Text textStyle="smallBody" color="gray.500">
          {description}
        </Text>
      </VStack>
    </Tooltip>
  );
};

export default RadioBoxSelect;
