import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleBoughtACar: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M30.28 27.71a1 1 0 01-1.41 0l-3.5-3.5a1.002 1.002 0 01.705-1.71 1 1 0 01.705.29l2.79 2.8L34 21.13A6 6 0 1035.45 25a5.771 5.771 0 00-.38-2.08l-4.79 4.79z"
    ></path>
    <path
      fill="currentColor"
      d="M16.45 13L10 24H5.45a3.5 3.5 0 100 7h2.47a3.44 3.44 0 002.47-1l1.24-1.23L16.45 37H46V13H16.45zM9 28.56a1.501 1.501 0 01-1.08.44H5.45a1.5 1.5 0 110-3H10l.55.95L9 28.56zM14 27a2.24 2.24 0 01-.51-.07l1.22-1.22A1 1 0 0014 24h-1.77A2 2 0 1114 27zm24.21-7.17l-1.62 1.57a7.9 7.9 0 01.86 3.6 8 8 0 11-2-5.29l1.29-1.29a1.002 1.002 0 011.71.705 1 1 0 01-.29.705h.05z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleBoughtACar;
