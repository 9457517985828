import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsCloseOrRemove: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M36 16.93L33.07 14 25 22.07 16.93 14 14 16.93 22.07 25 14 33.07 16.93 36 25 27.93 33.07 36 36 33.07 27.93 25 36 16.93z"
    />
  </Icon>
);

export default GeneralActionsCloseOrRemove;
