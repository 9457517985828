import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersCog: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        d="M39.248 20.237a1.35 1.35 0 01-.54-.279 1.404 1.404 0 01-.36-.495 1.349 1.349 0 01.09-1.17 4.902 4.902 0 00-6.728-6.736 1.285 1.285 0 01-1.178.09 1.305 1.305 0 01-.495-.36 1.35 1.35 0 01-.279-.54A4.821 4.821 0 0025 7.005a4.821 4.821 0 00-4.758 3.742 1.304 1.304 0 01-1.943.81 4.902 4.902 0 00-6.737 6.737c.107.174.172.371.189.576a1.36 1.36 0 01-.1.593c-.079.191-.202.36-.36.495a1.35 1.35 0 01-.539.279 4.893 4.893 0 000 9.516c.199.051.383.146.54.28.152.134.275.3.36.485.073.192.104.398.09.603-.014.203-.075.4-.18.575a4.902 4.902 0 006.737 6.738c.174-.108.371-.173.576-.19a1.36 1.36 0 01.593.1c.191.079.36.202.495.36.133.156.228.34.279.539a4.893 4.893 0 009.516 0 1.35 1.35 0 01.28-.54c.136-.155.305-.277.494-.36a1.331 1.331 0 011.17.09 4.902 4.902 0 006.736-6.737 1.286 1.286 0 01-.189-.575 1.36 1.36 0 01.45-1.089 1.45 1.45 0 01.549-.279 4.893 4.893 0 000-9.516zm-.9 6.026a4.867 4.867 0 00-3.373 2.888 4.93 4.93 0 00-.35 2.248c.055.772.296 1.518.701 2.177a1.304 1.304 0 01-1.799 1.8 4.894 4.894 0 00-2.168-.702 4.805 4.805 0 00-2.248.36 4.875 4.875 0 00-2.888 3.364 1.304 1.304 0 01-2.536 0 4.864 4.864 0 00-2.888-3.374 4.967 4.967 0 00-1.88-.368h-.368a4.786 4.786 0 00-2.177.701 1.304 1.304 0 01-1.8-1.799 4.875 4.875 0 00.343-4.416 4.867 4.867 0 00-3.373-2.888 1.304 1.304 0 010-2.536 4.867 4.867 0 003.373-2.887 4.893 4.893 0 00-.342-4.426 1.233 1.233 0 01.198-1.592 1.25 1.25 0 011.6-.198 4.892 4.892 0 007.305-3.022 1.304 1.304 0 012.536 0c.184.75.538 1.449 1.035 2.042a4.967 4.967 0 004.106 1.69c.77-.059 1.514-.3 2.172-.701a1.304 1.304 0 011.8 1.799 4.857 4.857 0 00-.703 2.168 4.805 4.805 0 00.36 2.248c.294.716.753 1.35 1.34 1.853.587.497 1.28.85 2.024 1.035a1.304 1.304 0 010 2.536z"
      ></path>
      <path
        fill="currentColor"
        d="M25 17.8a7.196 7.196 0 100 14.39 7.196 7.196 0 000-14.39zm0 10.793a3.598 3.598 0 110-7.195 3.598 3.598 0 010 7.195z"
      ></path>
    </Icon>
  );
};

export default GeneralOthersCog;
