import React, { PropsWithChildren, ReactElement } from 'react';
import { Text, VStack } from '@wegroup/design-system';

interface Props {
  title: string;
  icon: ReactElement;
}

const LouiseChatIntroColumn: React.FC<PropsWithChildren<Props>> = ({
  title,
  icon,
  children,
}) => {
  return (
    <VStack align="stretch" spacing="4">
      <VStack spacing="2">
        {icon}
        <Text textStyle="normalBodyMedium" align="center">
          {title}
        </Text>
      </VStack>
      {children}
    </VStack>
  );
};

export default LouiseChatIntroColumn;
