import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilityBike: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M39 24a9.001 9.001 0 00-2.76.44L31.55 16H34a1.5 1.5 0 100-3h-5a1.5 1.5 0 00-1.31 2.23l2.37 4.27H19.13l-.85-1.5h.22a1.5 1.5 0 100-3h-5a1.5 1.5 0 100 3h1.34l1.65 2.93-2.21 3.69a9 9 0 105.59 9.88H25c.126.015.254.015.38 0 .122-.032.24-.079.35-.14.098-.055.189-.122.27-.2l.07-.07c.05-.06.11-.12.16-.19l6.34-9.9 1 1.82A9 9 0 1039 24zm-9-1.5l-4.88 7.59-4.31-7.59H30zm-11.82 1.44l4.25 7.56h-2.56a9 9 0 00-3-5.33l1.31-2.23zm-2.84 4.74a6.16 6.16 0 011.6 2.82h-3.29l1.69-2.82zM11 39.13a6.13 6.13 0 111.77-12l-3.06 5.1a1.53 1.53 0 000 1.51 1.51 1.51 0 001.29.76h5.94A6.13 6.13 0 0111 39.13zm28 0a6.13 6.13 0 01-4-10.76l2.7 4.86a1.49 1.49 0 002.03.58 1.5 1.5 0 00.58-2L37.67 27a5.827 5.827 0 011.33-.13 6.13 6.13 0 110 12.26z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilityBike;
