import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleHeavyScooter: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M37.923 25.096a6.982 6.982 0 00-2.173.343l-.532-1.049a8.173 8.173 0 013.615-.42.234.234 0 00.265-.236v-5.267c0-.275-.096-.541-.272-.752l-5.397-6.476a.705.705 0 00-1.225.58l.94 4.652c.052.256.016.521-.1.755l-.706 1.396-1.7-3.402a1.046 1.046 0 00-.47-.47l-2.352-1.176a1.058 1.058 0 10-.94 1.88l2.036 1.024 1.176 2.351-2.272-.454a3.117 3.117 0 00-2.822.854v2.184a2.351 2.351 0 01-2.645 2.333l-7.87-.983a1.827 1.827 0 01-1.06-.522 1.793 1.793 0 01-.497-1.568.235.235 0 00-.235-.28H8.184a.83.83 0 00-.83.832.84.84 0 00.235.588l2.116 2.116.057.083a8.466 8.466 0 0110.355 5.552h6.049a2.586 2.586 0 012.586 2.375.235.235 0 00.235.211h.487a.235.235 0 00.235-.235 8.23 8.23 0 013.633-6.584l.53 1.06a7.054 7.054 0 104.05-1.295zm0 11.757a4.703 4.703 0 01-2.996-8.327l2.048 4.094a1.056 1.056 0 001.44.525 1.058 1.058 0 00.453-1.465l-2.046-4.094c.36-.09.73-.136 1.1-.136a4.702 4.702 0 110 9.405v-.002z"
    ></path>
    <path
      fill="currentColor"
      d="M26.166 30.974H19.01a7.054 7.054 0 100 2.352h7.155a1.176 1.176 0 000-2.352zm-14.11 5.879a4.702 4.702 0 114.549-5.879H10.88a1.176 1.176 0 100 2.352h5.724a4.703 4.703 0 01-4.548 3.527zM14.625 21.597l7.865.983h.148a1.176 1.176 0 001.176-1.176v-.776a.235.235 0 00-.235-.235h-9.145a.641.641 0 00-.317.818.649.649 0 00.507.386z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleHeavyScooter;
