import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilityPersonalLiablity: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M37 17a3 3 0 100-5.999A3 3 0 0037 17zM35.48 30.87c-.07.226-.165.444-.28.65L43 30.4V21a3 3 0 00-3-3h-6a3 3 0 00-3 3v3.34l3.12 2.27a3.77 3.77 0 011.36 4.26z"
    ></path>
    <path
      fill="currentColor"
      d="M43.9 32.32h-.3l-.6.09L32 34l-1-.43-5.42-2.32a1 1 0 01.42-1.83c.116.001.23.022.34.06L31 31.25l.26.1a1.739 1.739 0 001.667-.247 1.781 1.781 0 00-.037-2.873L31 26.82l-4.71-3.43a7.58 7.58 0 00-7.1-1l-3.67 1.41c-.345.133-.71.2-1.08.2H9v-1.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V34h5.59c.273.002.543.05.8.14L31 39.82c.322.113.66.174 1 .18a3.06 3.06 0 001-.16l11.47-3.39a2.11 2.11 0 00-.59-4.13h.02z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilityPersonalLiablity;
