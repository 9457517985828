import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsSearch: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.667 15a8.667 8.667 0 100 17.333 8.667 8.667 0 000-17.333zM11 23.667C11 16.67 16.671 11 23.667 11c6.995 0 12.666 5.671 12.666 12.667 0 6.995-5.67 12.666-12.666 12.666S11 30.663 11 23.667z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.786 29.786a2 2 0 012.828 0l5.8 5.8a2 2 0 11-2.828 2.828l-5.8-5.8a2 2 0 010-2.828z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsSearch;
