import { createDispatchHook, createSelectorHook } from 'react-redux';
import { RootState as GlobalRootState } from '../store/redux/reducers/rootReducer';
import { RootState as FlowRootState } from '../../../flows/src/store/redux/reducers/rootReducer';
import GlobalContext from '../store/context/GlobalReduxContext';
import FlowsContext from '../../../flows/src/store/context/flowsReduxContext';
import {
  flowIdRiskAnalysisBE,
  flowIdRiskAnalysisNL,
  flowIdRiskAnalysisShortBE,
} from '../../../flows/src/constants/flowIds';
import { RootState as CompanyScanRootState } from '../../../company-scan/store/redux/reducers/rootReducer';
import { RootState as CampaignsRootState } from '../../../campaigns/src/store/redux/reducers/rootReducer';
import CompanyScanContext from '../../../company-scan/store/context/CompanyScanContext';
import CampaignsContext from '../../../campaigns/src/store/context/CampaignsContext';

export const isRiskAnalysisFlow = (): boolean =>
  (window.location.hash.includes('/flows') &&
    (window.location.hash.includes(flowIdRiskAnalysisBE) ||
      window.location.hash.includes(flowIdRiskAnalysisShortBE) ||
      window.location.hash.includes(flowIdRiskAnalysisNL))) ||
  window.location.hash.includes('/risk-analysis');

export const isStaging =
  process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT' ||
  process.env.REACT_APP_ENVIRONMENT === 'STAGING' ||
  process.env.REACT_APP_ENVIRONMENT === 'QA';

export const useGlobalSelector =
  createSelectorHook<GlobalRootState>(GlobalContext);
export const useGlobalDispatch = createDispatchHook(GlobalContext);

export const useFlowsSelector = createSelectorHook<FlowRootState>(FlowsContext);
export const useFlowsDispatch = createDispatchHook(FlowsContext);

export const useCompanyScanSelector =
  createSelectorHook<CompanyScanRootState>(CompanyScanContext);
export const useCompanyScanDispatch = createDispatchHook(CompanyScanContext);

export const useCampaignsSelector =
  createSelectorHook<CampaignsRootState>(CampaignsContext);
export const useCampaignsDispatch = createDispatchHook(CampaignsContext);
