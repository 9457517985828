import React from 'react';
import { Box, BoxProps } from '@wegroup/design-system';
import { forwardRef } from '@chakra-ui/react';

export const GENERAL_ADJUSTED_PAGE_MAXW = { base: '1232px', desktop: '1432px' };
export const GENERAL_PAGE_MAXW = '150.4rem';
export const GENERAL_PAGE_PX = { base: '4', laptop: '8', laptopL: '14' };
export const GENERAL_PAGE_PY = { base: '6', laptopL: '8' };

/** Importing the Chakra UI forwardRef here so that `ComponentWithAs` is still supported */
const GeneralPageLayout = forwardRef<BoxProps, 'div'>(
  ({ children, ...props }, ref) => {
    return (
      <Box
        ref={ref}
        w="100%"
        maxW={GENERAL_PAGE_MAXW}
        mx="auto"
        px={GENERAL_PAGE_PX}
        py={GENERAL_PAGE_PY}
        {...props}
      >
        {children}
      </Box>
    );
  },
);

export default GeneralPageLayout;
