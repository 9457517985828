import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsUpload: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M33.328 18.503L24.81 10c-2.713 2.709-5.577 5.53-8.442 8.353h5.352v11.664c0 1.317.64 1.956 1.96 1.956.64 0 1.244-.037 1.884 0 1.62.113 2.224-.715 2.224-2.257-.038-3.688 0-7.375 0-11.213h5.54zM40 40v-2.71H10V40h30z"
    />
  </Icon>
);

export default GeneralActionsUpload;
