import {
  Input,
  InputGroup,
  InputRightAddon,
  InputProps,
} from '@wegroup/design-system';
import React from 'react';
import { Change } from '../../../../../../types/Form';
import { prefilledInputComponentStyling } from '../../../../../flows/src/constants/InputComponentStyling';

interface Props {
  name?: string;
  prefilled?: boolean;
  value?: string;
  onChange?: (e: Change) => void;
  onBlur?: () => void;
  isDisabled?: boolean;
}

const PercentageInput: React.FC<
  React.PropsWithChildren<Props & Omit<InputProps, 'onChange'>>
> = ({ name, prefilled, value, onChange, onBlur, isDisabled, ...props }) => {
  const handleOnChange = ({ name, value }: Change) => {
    if (value === '') onChange?.({ name, value: '' });
    else
      onChange?.({
        name,
        value: value === '' || value == null ? '' : Number(value) / 100,
      });
  };

  return (
    <InputGroup w="auto" {...props}>
      <Input
        type="number"
        min={0}
        max={100}
        name={name}
        value={
          value === '' || value == null
            ? ''
            : Number((Number(value) * 100).toFixed(2))
        }
        onChange={(e) => {
          handleOnChange({ name: name || '', value: e.target.value });
        }}
        onBlur={onBlur}
        isDisabled={isDisabled}
        {...(prefilled && { sx: prefilledInputComponentStyling })}
      />
      <InputRightAddon
        bg="white"
        color="text.gray"
        {...(prefilled && { sx: prefilledInputComponentStyling })}
      >
        %
      </InputRightAddon>
    </InputGroup>
  );
};

export default PercentageInput;
