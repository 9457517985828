import { useTheme, VStack, Center } from '@wegroup/design-system';
import React from 'react';
import { IconChevronDown } from 'wg-fe-ui';

export enum SortType {
  Asc = 'ASC',
  Desc = 'DESC',
}

interface Props {
  type?: SortType;
}

const SortIndicator: React.FC<React.PropsWithChildren<Props>> = ({ type }) => {
  const { colors } = useTheme();
  return (
    <Center>
      <VStack
        sx={{
          svg: {
            w: '20px',
            h: '20px',
            path: {
              transition: '0.2s',
            },
          },
        }}
        spacing="1"
      >
        <Center h="2px" transform="scale(1, -1)">
          <IconChevronDown
            color={type === SortType.Asc ? colors.black : colors.gray[200]}
          />
        </Center>
        <Center h="2px">
          <IconChevronDown
            color={type === SortType.Desc ? colors.black : colors.gray[200]}
          />
        </Center>
      </VStack>
    </Center>
  );
};

export default SortIndicator;
