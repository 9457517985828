import spacing from './spacing';

const sizes = {
  ...spacing,
  '3xs': '224px',
  '2xs': '256px',
  xs: '320px',
  sm: '384px',
  md: '448px',
  lg: '512px',
  xl: '576px',
  '2xl': '672px',
  '3xl': '768px',
  '4xl': '896px',
  '5xl': '1024px',
  '6xl': '1152px',
  '7xl': '1280px',
  '8xl': '1440px',
};

export default sizes;
