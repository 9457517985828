import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersAssignee: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M34.574 18.345a17.734 17.734 0 00-.074-1.494c-.18-3.501-1.48-5.926-3.289-7.47-1.8-1.537-4.062-2.165-6.109-2.165h-.41c-2.047 0-4.308.628-6.109 2.165-1.809 1.544-3.108 3.968-3.288 7.468-.04.37-.188 2.171.088 4.363.279 2.214 1 4.898 2.774 6.891a7.4 7.4 0 001.14 1.069 25.22 25.22 0 01-.23.232c-.935.936-3.095 2.343-8.048 3.564l-.007.002c-1.572.412-2.779 1.374-3.546 2.767-.76 1.382-1.074 3.161-.933 5.212l.003.05.013.047c.242.887.934 1.67 1.92 1.67h.091l.023-.003c1.01-.092 1.78-.934 1.685-1.98-.066-.76-.061-1.747.194-2.581.251-.822.715-1.423 1.54-1.61l.012-.002c6.904-1.743 9.713-4.059 10.799-5.791.635.144 1.3.193 1.947.193h.274c.808 0 1.557-.107 2.288-.27 1.118 1.787 3.848 4.181 10.8 5.936.833.215 1.301.84 1.553 1.664.255.835.26 1.806.194 2.529-.095 1.046.675 1.889 1.685 1.98l.023.003h.09c.97 0 1.707-.77 1.799-1.683v-.008l.001-.008c.141-2.083-.173-3.88-.933-5.27-.766-1.402-1.973-2.365-3.546-2.777l-.006-.001c-5.632-1.408-7.652-3.095-8.343-3.982a6.911 6.911 0 001-.954 8.99 8.99 0 00.605-.76 3.228 3.228 0 01-1.29-.79l-1.547-1.549a5.61 5.61 0 01-.502.658l-.002.002c-.918 1.04-2.211 1.61-3.938 1.61h-.136c-1.726 0-3.018-.569-3.936-1.608-1.194-1.385-1.678-3.46-1.847-5.27a18.824 18.824 0 01-.015-3.3v-.057c.134-2.967 1.279-4.486 2.481-5.285 1.234-.82 2.609-.935 3.25-.935h.41c.64 0 2.014.115 3.248.935 1.202.799 2.347 2.317 2.48 5.285v.088l.004.031c.007.06.015.124.021.193l2.322 2.323 1.327-1.327z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M45.234 9.368c.626-.65 1.65-.65 2.276 0 .616.64.616 1.67 0 2.31l-13.2 13.714c-.626.65-1.65.65-2.276 0l-6.6-6.857a1.675 1.675 0 010-2.31c.626-.65 1.65-.65 2.276 0l5.462 5.675L45.234 9.368z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersAssignee;
