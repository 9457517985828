import React from 'react';
import { Tbody, Tr, Td, Skeleton } from '@wegroup/design-system';

interface Props {
  amountOfColumns: number;
  amountOfRows?: number;
}

const TableBodySkeletonLoading: React.FC<React.PropsWithChildren<Props>> = ({
  amountOfColumns,
  amountOfRows = 5,
  ...otherProps
}) => {
  return (
    <Tbody {...otherProps}>
      {[...Array(amountOfRows)].map((_, i) => (
        <Tr key={`skeleton_row_${i}`}>
          {[...Array(amountOfColumns)].map((_, j) => (
            <Td key={`skeleton_row_${i}_data_${j}`}>
              <Skeleton height="4" w="100%" />
            </Td>
          ))}
        </Tr>
      ))}
    </Tbody>
  );
};

export default TableBodySkeletonLoading;
