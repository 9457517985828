import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsHunt: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 35.727c5.924 0 10.727-4.802 10.727-10.727 0-5.924-4.802-10.727-10.727-10.727-5.924 0-10.727 4.803-10.727 10.727S19.076 35.727 25 35.727zm0 4c8.134 0 14.727-6.593 14.727-14.727S33.134 10.273 25 10.273 10.273 16.866 10.273 25 16.866 39.727 25 39.727z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 25a2 2 0 012-2h9.818a2 2 0 110 4H7a2 2 0 01-2-2zM31.182 25a2 2 0 012-2H43a2 2 0 110 4h-9.818a2 2 0 01-2-2zM25 18.818a2 2 0 01-2-2V7a2 2 0 114 0v9.818a2 2 0 01-2 2zM25 45a2 2 0 01-2-2v-9.818a2 2 0 114 0V43a2 2 0 01-2 2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M26.636 25a1.636 1.636 0 11-3.273 0 1.636 1.636 0 013.273 0z"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsHunt;
