import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyBedroom: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M6.82 23.92A1.841 1.841 0 005 25.78v12.37A1.83 1.83 0 006.82 40a1.84 1.84 0 001.82-1.85 1.83 1.83 0 011.81-1.86h29.1a1.83 1.83 0 011.81 1.86A1.84 1.84 0 0043.18 40 1.83 1.83 0 0045 38.15V25.78a1.841 1.841 0 00-1.82-1.86H6.82zM41.39 12.61h-4.56a1.451 1.451 0 01-.59-.12 26.85 26.85 0 00-22.48 0c-.19.089-.4.133-.61.13H8.62a1.11 1.11 0 00-1.2 1v6.91a1.1 1.1 0 001.2 1h1.24c0-1 .68-3.53 6.9-3.53 5.08 0 6.46 1.66 6.8 2.83a1 1 0 00.93.7h1.25a1 1 0 00.93-.7c.34-1.17 1.72-2.83 6.8-2.83 6.22 0 6.89 2.5 6.9 3.53h1a1.092 1.092 0 001.18-1v-6.95a1.1 1.1 0 00-1.16-.97z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyBedroom;
