import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsExpandOrFullscreen: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M41.573 7h-9.437a1.381 1.381 0 100 2.762h6.122L27.256 20.765a1.381 1.381 0 101.933 1.933l11.003-11.002v6.122a1.381 1.381 0 102.762 0V8.381A1.381 1.381 0 0041.573 7zM22.698 27.072a1.38 1.38 0 00-1.933.046l-10.91 11.14-.047-6.123a1.381 1.381 0 10-2.762.047l.138 9.437A1.381 1.381 0 008.565 43l9.438-.138a1.381 1.381 0 00-.046-2.762l-6.123.092 10.864-11.14a1.379 1.379 0 000-1.98z"
    ></path>
  </Icon>
);

export default GeneralActionsExpandOrFullscreen;
