import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsArrowRight: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 25a2 2 0 012-2h24a2 2 0 110 4H13a2 2 0 01-2-2z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.586 11.586a2 2 0 012.828 0l12 12a2 2 0 010 2.828l-12 12a2 2 0 11-2.828-2.828L34.172 25 23.586 14.414a2 2 0 010-2.828z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsArrowRight;
