import React from 'react';
import { IconChevronLeftRounded } from 'wg-fe-ui';
import { HStack, Flex, Button, Box, useTheme } from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';

export interface PaginationProps {
  onPrev: () => void;
  onNext: () => void;
  onPageClick: (index: number) => void;
  currentPage: number;
  pages: number;
}

const Pagination: React.FC<React.PropsWithChildren<PaginationProps>> = ({
  onPrev,
  onNext,
  onPageClick,
  currentPage,
  pages,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();
  // How many pages can be around the current page
  const maxAround = 1;
  // How many pages are displayed if in beginning or end range
  const maxRange = 4;

  const getPageButtons = () => {
    const buttons = [];
    const totalPages = pages;
    const endRange = totalPages - maxRange;
    const inStartRange = currentPage <= maxRange;
    const inEndRange = currentPage >= endRange;

    let lastDotIndex = -1;
    const dotElem = <p>...</p>;
    for (let i = 0; i < pages; i++) {
      const page = i + 1;
      const isLast = i === pages - 1;
      const isFirst = i === 0;
      let hide;
      const active = currentPage === i + 1;
      if (totalPages < 9) {
        // Show all pages
      } else if (page === 1 || page === totalPages) {
        // Always show first and last
      } else if (inStartRange && page <= maxRange) {
        // Show button if in start range
      } else if (inEndRange && page >= endRange) {
        // Show the button if in ending range
      } else if (
        page === currentPage - maxAround ||
        page === currentPage ||
        page === currentPage + maxAround
      ) {
        // Show button if in the wrap around
      } else {
        hide = true;

        // Append dot if needed
        if (
          lastDotIndex === -1 ||
          (!inStartRange &&
            !inEndRange &&
            buttons?.filter((btn) => btn === dotElem).length < 2 &&
            page > currentPage)
        ) {
          lastDotIndex = page;
          buttons.push(dotElem);
        }
      }
      const button = (
        <Button
          _hover={
            !active
              ? {
                  bg: 'gray.strokeAndBg',
                }
              : {}
          }
          _focus={undefined}
          variant="tertiary"
          border="none"
          px="2"
          minW="unset"
          h="6"
          color={active ? 'white' : 'text.gray'}
          bg={active ? 'primary.500' : 'unset'}
          onClick={() => onPageClick(i + 1)}
          fontSize="sm"
          transition="0.1s"
        >
          {i + 1}
        </Button>
      );
      if (!hide || isFirst || isLast) {
        buttons.push(button);
      }
    }

    return buttons;
  };
  return (
    <Flex
      border="1px solid"
      borderColor="gray.strokeAndBg"
      borderRadius="lg"
      boxShadow="level1"
      bg="white"
      display="inline-flex"
      overflow="hidden"
      h="10"
    >
      <Button
        borderRadius="0"
        border="none"
        px="3"
        variant="tertiary"
        color="text.gray"
        minH="unset"
        _hover={
          currentPage !== 1
            ? {
                bg: 'gray.strokeAndBg',
              }
            : undefined
        }
        onClick={onPrev}
        _focus={undefined}
        isDisabled={currentPage === 1}
      >
        <HStack>
          <Box
            sx={{
              svg: {
                width: '4',
                height: '4',
              },
            }}
          >
            <IconChevronLeftRounded color={colors.text.gray} />
          </Box>
          <p>{t('Previous')}</p>
        </HStack>
      </Button>
      <HStack
        px="4"
        borderLeft="1px solid"
        borderRight="1px solid"
        borderColor="gray.strokeAndBg"
      >
        {getPageButtons().map((button) => button)}
      </HStack>
      <Button
        borderRadius="0"
        border="none"
        px="3"
        variant="tertiary"
        color="text.gray"
        minH="unset"
        _hover={
          currentPage !== pages
            ? {
                bg: 'gray.strokeAndBg',
              }
            : undefined
        }
        onClick={onNext}
        _focus={undefined}
        isDisabled={currentPage === pages}
      >
        <HStack>
          <p>{t('Next')}</p>
          <Box
            transform="rotate(180deg)"
            sx={{
              svg: {
                width: '4',
                height: '4',
              },
            }}
          >
            <IconChevronLeftRounded color={colors.text.gray} />
          </Box>
        </HStack>
      </Button>
    </Flex>
  );
};

export default Pagination;
