import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsArrowUp: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 45a2 2 0 01-2-2V7a2 2 0 114 0v36a2 2 0 01-2 2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.157 23.843a2 2 0 010-2.829L23.586 5.586a2 2 0 112.828 2.828L10.986 23.843a2 2 0 01-2.829 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M41.843 23.843a2 2 0 01-2.829 0L23.586 8.414a2 2 0 012.828-2.828l15.429 15.428a2 2 0 010 2.829z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsArrowUp;
