import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleSearchCar: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M45.27 41.52L35.14 31.4a16.998 16.998 0 002.35-4.4c0-.13.1-.26.14-.39.04-.13.17-.54.24-.81.07-.27.13-.48.19-.73s.1-.46.14-.7c.04-.24.11-.63.15-.95.04-.32 0-.38.07-.57 0-.52.08-1.05.08-1.58a17 17 0 10-17 17c.53 0 1.06 0 1.58-.08l.57-.07c.32 0 .64-.09 1-.15l.7-.14.72-.19c.28-.07.55-.15.82-.24l.39-.14a17 17 0 004.38-2.35l10.07 10.15a2.51 2.51 0 003.54 0 2.52 2.52 0 000-3.54zm-23.48-8.23a12 12 0 01-10.67-6h6.43a4.4 4.4 0 00.88 2.21 4.068 4.068 0 00.5.56c.11.11.23.2.35.3l.25.19c.216.14.443.26.68.36h.05a4.33 4.33 0 001.69.35H22c.195-.005.389-.025.58-.06h.22a4.48 4.48 0 003.65-3.92h3.05c.109-.175.187-.368.23-.57a29.91 29.91 0 00.77-4 9.192 9.192 0 00-.75-2.43.88.88 0 00-.66-.55l-1.41-.31L16.7 17a.94.94 0 01-.55-.36l-2.51-3.39a.5.5 0 00-.29-.19l-.46-.06a12 12 0 0120.6 8.82 12.11 12.11 0 01-11.7 11.47zm-2.29-6.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleSearchCar;
