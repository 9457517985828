import React, { PropsWithChildren } from 'react';
import { PostHogProvider as PostHogProviderSDKComponent } from 'posthog-js/react';
import useAuth from '../../features/authentication/hooks/useAuth';

const PostHogProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    ssoData: { info: userInfo },
  } = useAuth();

  if (!userInfo) return <>{children}</>;

  return (
    <PostHogProviderSDKComponent
      apiKey={process.env.REACT_APP_POSTHOG_TOKEN!}
      options={{
        api_host: 'https://eu.posthog.com',
        opt_out_capturing_by_default: true,
        autocapture: false,
        capture_pageleave: false,
        session_recording: {
          maskInputOptions: {
            password: true,
          },
        },
      }}
    >
      {children}
    </PostHogProviderSDKComponent>
  );
};

export default PostHogProvider;
