import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationTemporaryCaretaker: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_4212_10502"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4212_10502)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M151.5 91.627l10.089-23.67h106.322L278 91.627V205.5c0 11.046-8.954 20-20 20h-86.5c-11.046 0-20-8.954-20-20V91.627z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M161.589 67.957v-2a2 2 0 00-1.84 1.216l1.84.784zM151.5 91.627l-1.84-.784c-.106.248-.16.515-.16.784h2zm66.742-23.67v-2 2zm49.669 0l1.84-.784a2 2 0 00-1.84-1.216v2zM278 91.627h2c0-.27-.054-.536-.16-.784l-1.84.784zM214.362 225.5v2-2zm-42.862 0v-2 2zM159.749 67.173l-10.089 23.67 3.68 1.569 10.089-23.67-3.68-1.57zm58.493-1.216h-56.653v4h56.653v-4zm0 4h49.669v-4h-49.669v4zm47.829-1.216l10.089 23.67 3.68-1.568-10.089-23.67-3.68 1.568zM276 91.627V205.5h4V91.627h-4zm0 113.873c0 9.941-8.059 18-18 18v4c12.15 0 22-9.85 22-22h-4zm-18 18h-43.638v4H258v-4zm-86.5 4h42.862v-4H171.5v4zm-22-22c0 12.15 9.85 22 22 22v-4c-9.941 0-18-8.059-18-18h-4zm0-113.873V205.5h4V91.627h-4z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M161.649 56.943a6 6 0 016-6h93.426a6 6 0 016 6v10.178H161.649V56.943z"
        ></path>
        <circle
          cx="215.138"
          cy="143.237"
          r="39.52"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M206.048 127.473a59.276 59.276 0 00-12.75-6.291 1.802 1.802 0 00-1.54.284 1.773 1.773 0 00-.718 1.383 78.522 78.522 0 002.543 15.692 41.907 41.907 0 0112.465-11.068zM224.993 127.473a59.118 59.118 0 0112.754-6.29 1.782 1.782 0 012.058.892c.124.24.191.505.196.775a78.723 78.723 0 01-2.54 15.691 41.878 41.878 0 00-12.468-11.068z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M215.522 166.13c-13.715 0-24.834-8.693-24.834-19.42 0-10.728 15.178-19.421 15.178-19.421h19.318s15.173 8.695 15.173 19.421c0 10.725-11.121 19.42-24.835 19.42z"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M215.52 132.732c-8.743 0-19.435 6.766-17.44 17.847a18.679 18.679 0 002.957 7.734 18.31 18.31 0 005.984 5.638c2.546-1.327 8.262-4.812 8.505-9.479.24 4.667 5.961 8.152 8.504 9.479a18.29 18.29 0 005.984-5.638 18.68 18.68 0 002.958-7.734c1.983-11.079-8.711-17.847-17.452-17.847z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M225.354 164.678s-9.513-4.096-9.827-10.164c-.316 6.068-9.827 10.164-9.827 10.164"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M212.987 149.432c-.466 0-.921.146-1.305.42a2.358 2.358 0 00-.84 1.112 2.44 2.44 0 00-.076 1.408c.114.466.363.885.714 1.202l2.316 2.087a2.57 2.57 0 001.724.668c.634 0 1.247-.237 1.725-.668l2.316-2.087c.351-.317.6-.736.714-1.202a2.432 2.432 0 00-.077-1.408 2.358 2.358 0 00-.84-1.112 2.24 2.24 0 00-1.304-.42h-5.067z"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M202.481 142.716a3.84 3.84 0 001.099 2.695 3.722 3.722 0 002.653 1.116c.995 0 1.949-.401 2.653-1.116a3.84 3.84 0 001.099-2.695 3.839 3.839 0 00-1.099-2.695 3.723 3.723 0 00-2.653-1.117c-.995 0-1.949.402-2.653 1.117a3.839 3.839 0 00-1.099 2.695zM221.064 142.716c0 1.011.396 1.98 1.099 2.695a3.722 3.722 0 002.653 1.116 3.72 3.72 0 002.653-1.116 3.84 3.84 0 001.099-2.695 3.839 3.839 0 00-1.099-2.695 3.721 3.721 0 00-2.653-1.117c-.995 0-1.949.402-2.653 1.117a3.843 3.843 0 00-1.099 2.695z"
        ></path>
        <mask id="path-15-inside-1_4212_10502" fill="#fff">
          <path
            fillRule="evenodd"
            d="M121 193.004a12.956 12.956 0 01-5 .996c-7.18 0-13-5.82-13-13 0-6.998 5.53-12.705 12.459-12.989A12.998 12.998 0 01127 161c1.648 0 3.224.307 4.675.866C134.297 156.61 139.727 153 146 153c4.857 0 9.209 2.164 12.144 5.582A12.99 12.99 0 01162 158c6.248 0 11.467 4.408 12.716 10.284C180.592 169.533 185 174.752 185 181c0 7.18-5.82 13-13 13v12h-51v-12.996z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          d="M121 193.004a12.956 12.956 0 01-5 .996c-7.18 0-13-5.82-13-13 0-6.998 5.53-12.705 12.459-12.989A12.998 12.998 0 01127 161c1.648 0 3.224.307 4.675.866C134.297 156.61 139.727 153 146 153c4.857 0 9.209 2.164 12.144 5.582A12.99 12.99 0 01162 158c6.248 0 11.467 4.408 12.716 10.284C180.592 169.533 185 174.752 185 181c0 7.18-5.82 13-13 13v12h-51v-12.996z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M121 193.004h4v-6.002l-5.54 2.31 1.54 3.692zm-5.541-24.993l.163 3.997 2.316-.095 1.07-2.057-3.549-1.845zm16.216-6.145l-1.439 3.732 3.394 1.308 1.624-3.254-3.579-1.786zm26.469-3.284l-3.035 2.606 1.711 1.992 2.509-.778-1.185-3.82zm16.572 9.702l-3.913.832.54 2.541 2.541.54.832-3.913zM172 194v-4h-4v4h4zm0 12v4h4v-4h-4zm-51 0h-4v4h4v-4zm-1.54-16.688A8.96 8.96 0 01116 190v8c2.31 0 4.521-.463 6.54-1.304l-3.08-7.384zM116 190a9 9 0 01-9-9h-8c0 9.389 7.611 17 17 17v-8zm-9-9c0-4.844 3.829-8.796 8.622-8.992l-.327-7.994C106.231 164.386 99 171.847 99 181h8zm12.008-11.144A8.997 8.997 0 01127 165v-8c-6.57 0-12.263 3.728-15.09 9.166l7.098 3.69zM127 165c1.148 0 2.237.213 3.236.598l2.877-7.464A16.95 16.95 0 00127 157v8zm8.254-1.348A12 12 0 01146 157v-8c-7.847 0-14.631 4.52-17.905 11.08l7.159 3.572zM146 157c3.642 0 6.902 1.618 9.109 4.188l6.069-5.212A19.961 19.961 0 00146 149v8zm13.329 5.402A9.005 9.005 0 01162 162v-8a16.99 16.99 0 00-5.042.761l2.371 7.641zM162 162a9.004 9.004 0 018.803 7.116l7.825-1.663C176.995 159.767 170.176 154 162 154v8zm11.884 10.197A9.004 9.004 0 01181 181h8c0-8.176-5.767-14.995-13.453-16.628l-1.663 7.825zM181 181a9 9 0 01-9 9v8c9.389 0 17-7.611 17-17h-8zm-13 13v12h8v-12h-8zm4 8h-51v8h51v-8zm-47 4v-12.996h-8V206h8z"
          mask="url(#path-15-inside-1_4212_10502)"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M97.136 187.094L84.5 219.5H204l-12.636-32.406A8 8 0 00183.91 182h-79.32a8 8 0 00-7.454 5.094z"
        ></path>
        <rect
          width="128"
          height="10"
          x="80"
          y="215"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="5"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationTemporaryCaretaker;
