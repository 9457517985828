const Button = {
  baseStyle: {
    fontWeight: 'medium',
    borderRadius: 'md',
    _disabled: {
      opacity: 1,
      bg: 'gray.strokeAndBg',
      color: 'gray.300',
    },
  },
  variants: {
    primary: {
      bg: 'primary.500',
      color: 'white',
      _hover: {
        bg: 'primary.400',
        _disabled: {
          bg: 'gray.strokeAndBg',
        },
      },
    },
    secondary: {
      bg: 'primary.50',
      color: 'primary.500',
      _hover: {
        bg: 'primary.100',
        _disabled: {
          bg: 'gray.strokeAndBg',
        },
      },
    },
    __temp_secondarySecondaryColor: {
      bg: 'secondary.50',
      color: 'secondary.500',
      _hover: {
        bg: 'secondary.100',
        _disabled: {
          bg: 'gray.strokeAndBg',
        },
      },
    },
    tertiary: {
      bg: 'transparent',
      color: 'primary.500',
      border: '1px solid',
      borderColor: 'primary.500',
      _disabled: {
        bg: 'transparent',
        borderColor: 'gray.100',
      },
      _hover: {
        bg: 'primary.50',
      },
    },
    white: {
      bg: 'white',
      color: 'black',
      border: '1px solid',
      borderColor: 'gray.50',
      _disabled: {
        bg: 'white',
        borderColor: 'gray.strokeAndBg',
        color: 'gray.300',
      },
      _hover: {
        bg: 'gray.ultraLight',
        _disabled: {
          bg: 'white',
        },
      },
    },
    ghost: {
      color: 'primary.500',
      _hover: {
        bg: 'primary.50',
        color: 'primary.500',

        _disabled: {
          bg: 'transparent',
          color: 'gray.300',
          border: 'none',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'gray.300',
        border: 'none',
      },
    },
    dangerPrimary: {
      bg: 'danger.main',
      color: 'white',
      _hover: {
        bg: 'danger.dark',
        _disabled: {
          bg: 'gray.strokeAndBg',
        },
      },
    },
    dangerSecondary: {
      bg: 'danger.light',
      color: 'danger.main',
      _hover: {
        bg: 'danger.main',
        color: 'white',
        _disabled: {
          bg: 'gray.strokeAndBg',
          color: 'gray.300',
        },
      },
    },
    dangerTertiary: {
      bg: 'transparent',
      color: 'danger.main',
      border: '1px solid',
      borderColor: 'danger.main',
      _disabled: {
        bg: 'transparent',
        borderColor: 'gray.strokeAndBg',
      },
      _hover: {
        bg: 'danger.light',
      },
    },
    dangerGhost: {
      color: 'danger.main',
      _hover: {
        bg: 'danger.light',
        _disabled: {
          color: 'gray.300',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'gray.300',
        border: 'none',
      },
    },
    warningPrimary: {
      bg: 'warning.main',
      color: 'white',
      _hover: {
        bg: '#F93',
        _disabled: {
          bg: 'gray.strokeAndBg',
        },
      },
    },
    successPrimary: {
      bg: 'success.main',
      color: 'white',
      _hover: {
        bg: 'success.dark',
        _disabled: {
          bg: 'gray.strokeAndBg',
        },
      },
    },
    successSecondary: {
      bg: 'success.light',
      color: 'success.main',
      _hover: {
        bg: 'success.main',
        color: 'white',
        _disabled: {
          bg: 'gray.strokeAndBg',
          color: 'gray.300',
        },
      },
    },
    successTertiary: {
      bg: 'transparent',
      color: 'success.main',
      border: '1px solid',
      borderColor: 'success.main',
      _disabled: {
        bg: 'transparent',
        borderColor: 'gray.strokeAndBg',
      },
      _hover: {
        bg: 'success.light',
      },
    },
    successGhost: {
      color: 'success.main',
      _hover: {
        bg: 'success.light',
        _disabled: {
          color: 'gray.300',
        },
      },
      _disabled: {
        bg: 'transparent',
        color: 'gray.300',
        border: 'none',
      },
    },
    grayed: {
      bg: 'gray.50',
      color: 'gray.300',
      _hover: {
        bg: 'gray.100',
        color: 'gray.400',
        _disabled: {
          bg: 'gray.strokeAndBg',
          color: 'gray.300',
        },
      },
    },
    grayedGhost: {
      color: 'gray.500',
      _hover: {
        bg: 'gray.50',
      },
      _disabled: {
        bg: 'transparent',
        color: 'gray.300',
        border: 'none',
      },
    },
    negativeGhost: {
      color: 'white',
      _hover: {
        bg: 'rgba(255,255,255, 0.15)',
      },
      _disabled: {
        bg: 'transparent',
        color: 'gray.300',
        border: 'none',
      },
    },
    dashed: {
      py: '6',
      border: '1px dashed',
      borderColor: 'gray.50',
      color: 'gray.400',
      _hover: {
        color: 'primary.500',
      },
      _disabled: {
        bg: 'transparent',
        color: 'gray.300',
        border: 'none',
      },
    },
    info: {
      bg: 'info.main',
      color: 'white',
      _hover: {
        bg: 'info.dark',
        _disabled: {
          bg: 'gray.strokeAndBg',
        },
      },
    },
    outline: {
      borderColor: 'primary.500',
      color: 'primary.500',
      _hover: {
        bg: 'primary.50',
        _disabled: {
          bg: 'unset',
        },
      },
      _disabled: {
        borderColor: 'gray.300',
        color: 'gray.300',
      },
    },
  },
  sizes: {
    xs: {
      px: 3,
      py: 2,
    },
    sm: {
      px: 4,
      py: 2,
    },
    md: {
      px: 6,
      py: 3,
    },
    lg: {
      px: 8,
      py: 3,
    },
  },
  defaultProps: {
    colorScheme: 'primary',
    variant: 'primary',
  },
};

export default Button;
