import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Link,
  Portal,
  UnorderedList,
  ListItem,
} from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  items: string[];
}

const MultipleItemsPopover: React.FC<Props> = ({ title, items }) => {
  const { t } = useTranslation();

  return (
    <Popover>
      <PopoverTrigger>
        <Link>{t('VIEW')}</Link>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader textStyle="smallBodyMedium">{title}:</PopoverHeader>
          <PopoverBody>
            <UnorderedList spacing="2">
              {items.map((item, index) => (
                <ListItem key={index} textStyle="smallBody">
                  {item}
                </ListItem>
              ))}
            </UnorderedList>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default MultipleItemsPopover;
