import { HStack, Text, VStack } from '@wegroup/design-system';
import React from 'react';
import LouiseAvatar from '../../../../../dashboard/src/assets/LouiseAvatar';

interface Props {
  title: string;
  description: string;
}

const LouiseQAQuestionHeader: React.FC<Props> = ({ title, description }) => {
  return (
    <HStack spacing="4">
      <LouiseAvatar />
      <VStack spacing="2" align="stretch">
        <Text textStyle="h4Headline">{title}</Text>
        <Text color="text.gray" textStyle="smallBody">
          {description}
        </Text>
      </VStack>
    </HStack>
  );
};

export default LouiseQAQuestionHeader;
