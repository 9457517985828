import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationElevator: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3773_11579"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3773_11579)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <rect
          width="128"
          height="136"
          x="114"
          y="75"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          stroke={colors.primary[200]}
          strokeWidth="4"
          d="M158.306 127.992c3.784 1.055 6.245 2.512 8.93 5.286 2.315 2.392 3.459 4.365 4.363 7.527.307 1.073.323 1.599.375 12.819.054 11.62.052 11.706-.277 12.486-.614 1.455-1.829 2.262-3.407 2.262-1.135 0-1.877-.321-2.652-1.145-1.019-1.084-.987-.708-.987-11.622 0-5.445-.043-10.013-.095-10.153-.055-.146-.278-.252-.532-.252-.253 0-.476.106-.531.252-.053.14-.095 4.64-.095 10.002v9.748l-1.174 15.049c-.646 8.276-1.243 15.47-1.327 15.985-.36 2.2-2.013 3.834-4.325 4.274-1.349.257-5.269.096-6.223-.255-1.019-.374-2.351-1.649-2.851-2.727-.391-.844-.44-1.333-1.652-16.725l-1.25-15.857v-9.62c0-5.292-.042-9.734-.095-9.874-.133-.354-.8-.316-.996.057-.107.203-.162 3.673-.162 10.153 0 10.851.032 10.481-.987 11.565-.85.905-1.563 1.186-2.837 1.118-.947-.05-1.22-.135-1.852-.58-.41-.289-.934-.859-1.175-1.276l-.434-.754-.048-10.787c-.031-7.054.011-11.31.123-12.297.739-6.554 5.977-12.417 12.929-14.471 2.072-.612 2.881-.707 5.483-.64 1.88.047 2.633.138 3.761.452z"
          clipRule="evenodd"
        ></path>
        <rect
          width="14.153"
          height="18.63"
          x="146.969"
          y="102.637"
          fill={colors.primary[200]}
          stroke={colors.primary[200]}
          strokeWidth="4"
          rx="7.076"
        ></rect>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          stroke={colors.primary[200]}
          strokeWidth="4"
          d="M206.306 127.992c3.784 1.055 6.245 2.512 8.93 5.286 2.315 2.392 3.459 4.365 4.363 7.527.307 1.073.323 1.599.375 12.819.054 11.62.052 11.706-.277 12.486-.614 1.455-1.829 2.262-3.407 2.262-1.135 0-1.877-.321-2.652-1.145-1.019-1.084-.987-.708-.987-11.622 0-5.445-.043-10.013-.095-10.153-.055-.146-.278-.252-.532-.252-.253 0-.476.106-.531.252-.053.14-.095 4.64-.095 10.002v9.748l-1.174 15.049c-.646 8.276-1.243 15.47-1.327 15.985-.36 2.2-2.013 3.834-4.325 4.274-1.349.257-5.269.096-6.223-.255-1.019-.374-2.351-1.649-2.851-2.727-.391-.844-.44-1.333-1.652-16.725l-1.25-15.857v-9.62c0-5.292-.042-9.734-.095-9.874-.133-.354-.8-.316-.996.057-.107.203-.162 3.673-.162 10.153 0 10.851.032 10.481-.987 11.565-.85.905-1.563 1.186-2.837 1.118-.947-.05-1.22-.135-1.852-.58-.41-.289-.934-.859-1.175-1.276l-.434-.754-.048-10.787c-.031-7.054.011-11.31.123-12.297.739-6.554 5.977-12.417 12.929-14.471 2.072-.612 2.881-.707 5.483-.64 1.88.047 2.633.138 3.761.452z"
          clipRule="evenodd"
        ></path>
        <rect
          width="14.153"
          height="18.63"
          x="194.969"
          y="102.637"
          fill={colors.primary[200]}
          stroke={colors.primary[200]}
          strokeWidth="4"
          rx="7.076"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M159 23.916v36.167M169.333 34.25L159 23.915M148.667 34.25L159 23.915M197 60.084V23.917M186.667 49.75L197 60.085M207.333 49.75L197 60.085"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationElevator;
