import { createSelector } from '@reduxjs/toolkit';
import { useFlowsSelector } from '../../../common/src/utils/globalVariables';
import { RootState } from '../store/redux/reducers/rootReducer';
import { FlowsQuestion } from '../types/FlowsTypes';
import { flattenQuestionaire } from '../utils/flowsQuestionsUtils';

const useFlowQuestions = (): FlowsQuestion[] => {
  const questionsSelector = createSelector(
    (state: RootState) => state.flows.initialQuestionnaire,
    (initialQuestionnaire) => flattenQuestionaire(initialQuestionnaire),
  );

  const questions = useFlowsSelector((state) => questionsSelector(state));

  return questions;
};

export default useFlowQuestions;
