import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationHome: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="m178.037 63.123-71.302 71.297v91.13h142.599v-91.13l-71.297-71.297Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M249.334 55.984a7.313 7.313 0 0 0-7.312-7.311h-12.085a7.313 7.313 0 0 0-7.311 7.311v14.464L249.36 96.87l-.026-40.886Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m272.153 119.43-81.586-80.642a17.826 17.826 0 0 0-25.066 0L83.915 119.43a13.368 13.368 0 0 0 18.904 18.905l75.218-75.212 75.212 75.212a13.371 13.371 0 0 0 18.904 0 13.367 13.367 0 0 0 0-18.905Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M191.385 115.542a13.376 13.376 0 0 1-15.986 13.116 13.37 13.37 0 0 1-10.506-10.511 13.38 13.38 0 0 1 5.693-13.728 13.372 13.372 0 0 1 20.799 11.123ZM195.858 155.617h35.648v35.648h-35.648v-35.648Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M195.858 173.444h35.648"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M169.124 170.84a17.83 17.83 0 0 0-17.824-18.172 17.838 17.838 0 0 0-12.727 5.344 17.83 17.83 0 0 0-5.097 12.828v35.648h35.648V170.84Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M125.252 208.948a5 5 0 0 1 5-5h44.162a5 5 0 0 1 5 5v15.926h-54.162v-15.926Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M306.68 196.324a13.402 13.402 0 0 1-3.757 9.779 13.404 13.404 0 0 1-19.286 0 13.398 13.398 0 0 1-3.757-9.779c0-7.404 13.408-49.16 13.408-49.16s13.392 41.756 13.392 49.16Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M293.272 225.88v-16.148"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.112 203.028a10.057 10.057 0 0 1-17.167 7.111A10.057 10.057 0 0 1 49 203.028c0-5.552 10.056-36.872 10.056-36.872s10.056 31.32 10.056 36.872Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.056 225.88v-12.796"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationHome;
