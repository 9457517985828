import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationTablet: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_691_2646"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_691_2646)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M229.579 54.99H119.053c-6.105 0-11.053 4.948-11.053 11.052v149.211c0 6.104 4.948 11.053 11.053 11.053h110.526c6.105 0 11.053-4.949 11.053-11.053V66.042c0-6.104-4.948-11.053-11.053-11.053z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M229.579 66.042H119.053a2.763 2.763 0 00-2.763 2.763v138.159a2.763 2.763 0 002.763 2.763h110.526a2.763 2.763 0 002.764-2.763V68.805a2.763 2.763 0 00-2.764-2.763z"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M174.316 217.765a.252.252 0 01.232.155.248.248 0 01.014.145.246.246 0 01-.197.197.254.254 0 01-.258-.106.25.25 0 01-.023-.236.249.249 0 01.232-.155zm0-2.512a2.758 2.758 0 00-2.553 1.706 2.76 2.76 0 00.599 3.011 2.76 2.76 0 003.011.599 2.758 2.758 0 001.706-2.553 2.763 2.763 0 00-2.763-2.763z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationTablet;
