import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationRenovateSellProperties: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3749_10334"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3749_10334)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M212.467 89.97h-24.261v31.334h12.766c14.355 0 34.638-24.402 26.881-43.205-.262-.634-1.082-.776-1.567-.29l-10.99 10.99a4.003 4.003 0 01-2.829 1.172z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M79.101 174.686l71.177-62.279c9.121-8.97 12.378-15.542 12.378-30.173 0-23.446 35.09-35.793 48.774-25.672.888.657.881 1.935.1 2.716l-14.634 14.634a2 2 0 00-.585 1.414v13.558c0 .207.032.414.095.611l11.29 35.158c.949 2.956-1.7 5.868-4.779 5.465-13.739-1.796-27.896.097-32.524 4.725l-62.666 68.469c-6.576 6.575-20.89 4.642-27.079-1.548-6.19-6.189-8.123-20.502-1.547-27.078z"
        ></path>
        <mask id="path-6-inside-1_3749_10334" fill="#fff">
          <rect
            width="19.341"
            height="19.341"
            rx="2"
            transform="matrix(-1 0 0 1 190.122 98.094)"
          ></rect>
        </mask>
        <rect
          width="19.341"
          height="19.341"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-6-inside-1_3749_10334)"
          rx="2"
          transform="matrix(-1 0 0 1 190.122 98.094)"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M184.706 115.889l4.255 13.539"
        ></path>
        <circle
          r="7.284"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 95.735 187.065)"
        ></circle>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M161.883 120.916l-50.288 50.675"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M87.09 78.668l23.338-23.336a3.29 3.29 0 012.334-.967 3.298 3.298 0 012.334.967l6.533 6.533a3.307 3.307 0 01.715 3.597c-.166.4-.409.764-.715 1.07l-6.067 6.07 19.603 19.603a3.963 3.963 0 010 5.6l-5.6 5.601a3.966 3.966 0 01-5.603 0l-19.603-19.604-6.066 6.07a3.314 3.314 0 01-4.668 0l-6.535-6.536a3.314 3.314 0 010-4.668z"
        ></path>
        <path
          fill={colors.primary[150]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M123.963 64.201L112.761 53 84.757 81.004l11.202 11.202L123.963 64.2z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M123.957 103.407a3.962 3.962 0 005.603 0l5.6-5.6a3.963 3.963 0 000-5.6l-19.603-19.603-11.203 11.2 19.603 19.603zM128.164 76.805l-23.805 15.402M136.566 85.205l-23.804 15.402"
        ></path>
        <g filter="url(#filter0_dd_3749_10334)">
          <circle cx="238.256" cy="153.5" r="42.5" fill="#fff"></circle>
          <circle
            cx="238.256"
            cy="153.5"
            r="40.5"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M216.628 163.953c0-8.876 8.005-20.9 16.802-25.203h9.165c8.8 4.309 16.802 16.333 16.802 25.209 0 10.847-9.58 16.042-21.391 16.042-11.811 0-21.378-5.202-21.378-16.048zM238.129 138.743s-1.939-2.093-4.003-4.232a208.17 208.17 0 00-4.33-4.362 1.529 1.529 0 011.286-2.606 49.312 49.312 0 0014.094 0c.32-.046.647.01.933.16a1.53 1.53 0 01.349 2.452 180.362 180.362 0 00-4.328 4.362c-2.06 2.133-4.001 4.226-4.001 4.226z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M241.495 135.178a16.45 16.45 0 011.934-1.461c2.679-1.239 3.896-.657 4.416.487.52 1.145.167 2.444-2.507 3.666a13.823 13.823 0 01-5.315.871h-1.896s2.786-2.953 3.368-3.563zM238.127 138.744c.609.535 4.904 4.505 4.553 9.198"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M234.763 135.178a16.657 16.657 0 00-1.935-1.461c-2.678-1.239-3.896-.657-4.416.487-.52 1.145-.167 2.444 2.506 3.666a13.83 13.83 0 005.317.871h1.894s-2.784-2.953-3.366-3.563zM238.129 138.744c-.608.535-4.903 4.505-4.552 9.198"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M243.444 169.204h-1.825a7.64 7.64 0 01-3.856-1.052 7.722 7.722 0 01-2.813-2.864h4.279c.374-.009.73-.166.991-.436a1.455 1.455 0 000-2.023 1.427 1.427 0 00-.991-.436h-5.234a7.088 7.088 0 010-1.897h7.624a1.433 1.433 0 001.357-.886 1.464 1.464 0 00-.32-1.598 1.433 1.433 0 00-1.037-.412h-6.669a7.724 7.724 0 012.813-2.862 7.63 7.63 0 013.856-1.052h1.825c.374-.009.73-.165.992-.436a1.456 1.456 0 000-2.023 1.431 1.431 0 00-.992-.436h-1.825a10.497 10.497 0 00-5.961 1.87 10.65 10.65 0 00-3.873 4.941h-2.425a1.436 1.436 0 00-1.357.885 1.451 1.451 0 00.32 1.599 1.421 1.421 0 001.037.411h1.75a10.99 10.99 0 00-.048.945c0 .317.021.636.048.949h-1.75a1.428 1.428 0 00-1.357.886 1.446 1.446 0 000 1.124 1.444 1.444 0 00.797.786c.178.07.368.104.56.1h2.425a10.65 10.65 0 003.874 4.94 10.5 10.5 0 005.96 1.871h1.825c.374-.01.73-.166.992-.437a1.456 1.456 0 000-2.023 1.431 1.431 0 00-.992-.436v.002z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_3749_10334"
          width="117"
          height="117"
          x="179.756"
          y="103"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3749_10334"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_3749_10334"
            result="effect2_dropShadow_3749_10334"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_3749_10334"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationRenovateSellProperties;
