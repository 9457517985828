import { Flex, CenterProps, Tag, Box } from '@wegroup/design-system';
import React from 'react';

interface Props {
  title?: string;
}

const GrayTextBox: React.FC<React.PropsWithChildren<Props & CenterProps>> = ({
  title,
  children,
  ...props
}) => {
  // Wrapping in Box component because a VStack won't overwrite the padding top of the grayed box itself
  return (
    <Box w="100%">
      <Flex
        pos="relative"
        p="4"
        pt={title ? '6' : undefined}
        flexDir="column"
        bg="gray.ultraLight"
        border="1px solid"
        borderColor="gray.50"
        borderRadius="sm"
        {...props}
      >
        {title && (
          <Tag
            pos="absolute"
            bottom="100%"
            left="50%"
            transform="translate(-50%, 50%)"
            bg="white"
          >
            {title}
          </Tag>
        )}

        {children}
      </Flex>
    </Box>
  );
};

export default GrayTextBox;
