import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycleTandem: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_911_3580"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_911_3580)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <circle
          cx="92"
          cy="186"
          r="38"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="92"
          cy="186"
          r="30"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="265"
          cy="186"
          r="38"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="265"
          cy="186"
          r="30"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M92.5 185l34.362-71.111a6 6 0 015.402-3.389h9.236"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M228.884 128.5H122.876c-1.52 0-2.484 1.628-1.754 2.96l29.309 53.5a2 2 0 001.754 1.04h109.73c1.566 0 2.524-1.719 1.702-3.051l-33.031-53.5a2 2 0 00-1.702-.949zM152 185.5l25-66.5M187 119h-17.5M209 185.5l25-66.5M244 119h-17.5M208 185.5L173 129"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBicycleTandem;
