import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsAddUser: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path fill="currentColor" d="M25 23a7 7 0 100-14 7 7 0 000 14z"></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M30.05 25a3.5 3.5 0 002.45 6H34v1.5a3.5 3.5 0 004 3.464V44H12V33.636c0-2.29.806-4.487 2.24-6.107C15.674 25.91 17.619 25 19.647 25h10.404z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        d="M42.5 26H39v-3.5a1.5 1.5 0 10-3 0V26h-3.5a1.5 1.5 0 100 3H36v3.5a1.5 1.5 0 103 0V29h3.5a1.5 1.5 0 100-3z"
      ></path>
    </Icon>
  );
};

export default GeneralActionsAddUser;
