import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsFilter: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.212 8.103A2 2 0 0 1 10 7h30a2 2 0 0 1 1.688 3.074L31.303 26.396v9.18a2 2 0 0 1-.76 1.57l-6.803 5.377a2 2 0 0 1-3.24-1.569V26.432L8.397 10.195a2 2 0 0 1-.185-2.092ZM13.986 11l10.117 13.573a2 2 0 0 1 .397 1.195v11.056l2.803-2.216v-8.794a2 2 0 0 1 .313-1.074L36.357 11H13.986Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default GeneralActionsFilter;
