import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsDuplicate: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.5 18a2.5 2.5 0 00-2.5 2.5v18a2.5 2.5 0 002.5 2.5h18a2.5 2.5 0 002.5-2.5v-18a2.5 2.5 0 00-2.5-2.5h-18zM14 20.5a6.5 6.5 0 016.5-6.5h18a6.5 6.5 0 016.5 6.5v18a6.5 6.5 0 01-6.5 6.5h-18a6.5 6.5 0 01-6.5-6.5v-18z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.904 6.904A6.5 6.5 0 0111.5 5h18a6.5 6.5 0 016.5 6.5V16a2 2 0 11-4 0v-4.5A2.5 2.5 0 0029.5 9h-18A2.5 2.5 0 009 11.5v18a2.5 2.5 0 002.5 2.5H16a2 2 0 110 4h-4.5A6.5 6.5 0 015 29.5v-18a6.5 6.5 0 011.904-4.596z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsDuplicate;
