import { useMutation, UseMutationResult } from 'react-query';
import { wgAuthApi } from '../../../api/wgAuthApi';
import { setSocialLoginToken } from '../../utils/jwt';

interface Payload {
  askBrokerId?: string;
  askDistributionId: string;
  email: string;
  token: string;
  version?: 'V2_INVISIBLE' | 'V3';
}

interface Response {
  access_token: string;
  id: string;
}

const postSocialLogin = async (payload: Payload) => {
  const { data } = await wgAuthApi.post<Response>('auth/captcha', {
    token: payload.token,
    email: payload.email,
    ask_broker_id: payload.askBrokerId,
    ask_distribution_id: payload.askDistributionId,
    version: payload.version || 'V2_INVISIBLE',
  });

  return data;
};

export const useSocialLogin = (): UseMutationResult<
  Response,
  unknown,
  Payload
> => {
  return useMutation<Response, unknown, Payload>(postSocialLogin, {
    onSuccess: (data) => {
      setSocialLoginToken(data.access_token);
    },
  });
};
