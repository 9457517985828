import { VStack, Text } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

const BrokerContactInfoHeader: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { t } = useTranslation();

  return (
    <VStack spacing="1" align="stretch">
      <Text textStyle="h5Headline">
        {t('SALES-CONVERSATION_FINALIZATION_BROKER-INFO_HEADER-TITLE')}
      </Text>
      <Text color="gray.500">
        {t('SALES-CONVERSATION_FINALIZATION_BROKER-INFO_HEADER-DESCRIPTION')}
      </Text>
    </VStack>
  );
};

export default BrokerContactInfoHeader;
