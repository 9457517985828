import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNotificationBell: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon fill="none" viewBox="0 0 22 22" {...props}>
      <path
        d="M9.16669 4.58333C9.16669 4.0971 9.35984 3.63079 9.70366 3.28697C10.0475 2.94315 10.5138 2.75 11 2.75C11.4862 2.75 11.9526 2.94315 12.2964 3.28697C12.6402 3.63079 12.8334 4.0971 12.8334 4.58333C13.8861 5.08111 14.7835 5.85597 15.4294 6.82486C16.0753 7.79375 16.4454 8.92015 16.5 10.0833V12.8333C16.569 13.4032 16.7708 13.949 17.0893 14.4266C17.4077 14.9043 17.8338 15.3005 18.3334 15.5833H3.66669C4.16621 15.3005 4.59235 14.9043 4.91078 14.4266C5.22921 13.949 5.43104 13.4032 5.50002 12.8333V10.0833C5.55462 8.92015 5.92471 7.79375 6.57064 6.82486C7.21657 5.85597 8.11398 5.08111 9.16669 4.58333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <path
        d="M8.25 15.5833V16.5C8.25 17.2294 8.53973 17.9288 9.05546 18.4446C9.57118 18.9603 10.2707 19.25 11 19.25C11.7293 19.25 12.4288 18.9603 12.9445 18.4446C13.4603 17.9288 13.75 17.2294 13.75 16.5V15.5833"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </Icon>
  );
};

export default GeneralNotificationBell;
