import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationHolderTypeTenant: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_2695_10248"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_2695_10248)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M178.082 94.783l-57.517 57.512v73.511h115.029v-73.511l-57.512-57.512z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M235.594 89.025a5.899 5.899 0 00-5.898-5.898h-9.748a5.898 5.898 0 00-5.898 5.898v11.668l21.565 21.313-.021-32.981z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[100]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M254.002 140.204l-65.812-65.05a14.381 14.381 0 00-20.22 0l-65.812 65.05a10.784 10.784 0 0015.249 15.249l60.676-60.67 60.669 60.67a10.785 10.785 0 0015.25 0 10.783 10.783 0 000-15.249z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M182.529 156.089c3.822 1.045 6.308 2.487 9.02 5.235 2.339 2.368 3.494 4.322 4.407 7.453.31 1.062.326 1.583.378 12.694.055 11.506.053 11.591-.279 12.364-.62 1.441-1.847 2.24-3.441 2.24-1.146 0-1.897-.318-2.679-1.134-1.029-1.074-.997-.701-.997-11.508 0-5.392-.043-9.916-.096-10.054-.056-.145-.281-.25-.537-.25s-.481.105-.537.25c-.053.138-.096 4.594-.096 9.904v9.653l-1.185 14.902c-.653 8.196-1.256 15.319-1.341 15.829-.363 2.178-2.033 3.797-4.368 4.232-1.362.254-5.322.095-6.285-.252-1.029-.371-2.375-1.633-2.88-2.701-.395-.835-.444-1.32-1.669-16.562l-1.261-15.702v-9.526c0-5.24-.044-9.639-.096-9.777-.135-.351-.809-.313-1.007.056-.107.201-.163 3.637-.163 10.054 0 10.745.032 10.379-.997 11.452-.859.896-1.579 1.174-2.866 1.107-.957-.049-1.232-.134-1.87-.574-.414-.287-.944-.85-1.187-1.264l-.439-.747-.048-10.681c-.031-6.985.011-11.2.124-12.178.747-6.489 6.038-12.295 13.059-14.329 2.092-.606 2.91-.7 5.538-.634 1.899.047 2.659.137 3.798.448z"
          clipRule="evenodd"
        ></path>
        <rect
          width="14.335"
          height="18.409"
          x="171.061"
          y="131"
          fill={colors.primary[500]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="7.167"
        ></rect>
        <g filter="url(#filter0_dd_2695_10248)">
          <circle cx="243.5" cy="101.5" r="42.5" fill="#fff"></circle>
          <circle
            cx="243.5"
            cy="101.5"
            r="40.5"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M221.872 111.952c0-8.876 8.005-20.9 16.801-25.203h9.166c8.8 4.309 16.802 16.333 16.802 25.209 0 10.847-9.58 16.042-21.391 16.042-11.811 0-21.378-5.202-21.378-16.048zM243.372 86.743s-1.939-2.093-4.003-4.232c-1.29-1.339-2.8-2.868-4.329-4.362a1.53 1.53 0 01-.303-1.77 1.527 1.527 0 011.588-.836 49.297 49.297 0 0014.094 0 1.528 1.528 0 011.282 2.612 180.727 180.727 0 00-4.328 4.362 328.04 328.04 0 00-4.001 4.226z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M246.739 83.178a16.429 16.429 0 011.934-1.46c2.679-1.24 3.896-.658 4.416.486.52 1.145.167 2.444-2.507 3.666a13.817 13.817 0 01-5.315.87h-1.896s2.786-2.952 3.368-3.562zM243.371 86.743c.609.535 4.904 4.505 4.553 9.199"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M240.006 83.178a16.484 16.484 0 00-1.934-1.46c-2.679-1.24-3.896-.658-4.416.486-.52 1.145-.167 2.444 2.505 3.666 1.699.636 3.505.932 5.317.87h1.895s-2.785-2.952-3.367-3.562zM243.373 86.743c-.609.535-4.903 4.505-4.552 9.199"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M248.687 117.205h-1.825a7.64 7.64 0 01-3.856-1.052 7.727 7.727 0 01-2.812-2.864h4.278c.374-.009.73-.166.992-.436a1.456 1.456 0 000-2.023 1.431 1.431 0 00-.992-.436h-5.234a7.088 7.088 0 010-1.897h7.624a1.44 1.44 0 001.357-.886 1.446 1.446 0 000-1.124 1.444 1.444 0 00-.797-.786 1.446 1.446 0 00-.56-.1h-6.668a7.714 7.714 0 012.812-2.862 7.634 7.634 0 013.856-1.052h1.825c.375-.009.731-.165.992-.436a1.454 1.454 0 000-2.023 1.427 1.427 0 00-.992-.436h-1.825a10.496 10.496 0 00-5.96 1.87 10.653 10.653 0 00-3.874 4.941h-2.425a1.417 1.417 0 00-1.037.411 1.444 1.444 0 00-.432 1.037 1.464 1.464 0 00.432 1.036 1.421 1.421 0 001.037.411h1.75a10.99 10.99 0 00-.047.945c0 .317.02.636.047.949h-1.75a1.417 1.417 0 00-1.037.412 1.464 1.464 0 000 2.072 1.44 1.44 0 001.037.412h2.425a10.65 10.65 0 003.874 4.94 10.503 10.503 0 005.96 1.871h1.825c.375-.01.731-.166.992-.437a1.455 1.455 0 000-2.023 1.428 1.428 0 00-.992-.436v.002z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_2695_10248"
          width="117"
          height="117"
          x="185"
          y="51"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2695_10248"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_2695_10248"
            result="effect2_dropShadow_2695_10248"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2695_10248"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationHolderTypeTenant;
