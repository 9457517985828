import { useRadioGroup, HStack, Icon, Box } from '@wegroup/design-system';
import React from 'react';
import ThumbsRadio from './ThumbsRadio';

export type YesNoString = 'yes' | 'no';

interface Props {
  onChange: (value: YesNoString) => void;
  onNegativeClick?: (value: YesNoString) => void;
}

/**
 * For this component we need to use text values as otherwise it will not work correctly (chakra UI specifies this)
 */
const ThumbsRadioGroup: React.FC<Props> = ({ onChange, onNegativeClick }) => {
  const { getRootProps, getRadioProps, value } = useRadioGroup({
    name: 'feedback',
    onChange,
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      <ThumbsRadio {...getRadioProps({ value: 'yes' })}>
        <Icon name="GeneralOthersThumbsUp" />
      </ThumbsRadio>
      <ThumbsRadio {...getRadioProps({ value: 'no' })}>
        <Box
          transform="scaleY(-1)"
          onClick={() => onNegativeClick?.(value as YesNoString)}
        >
          <Icon name="GeneralOthersThumbsUp" />
        </Box>
      </ThumbsRadio>
    </HStack>
  );
};

export default ThumbsRadioGroup;
