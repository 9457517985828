import React, { PropsWithChildren, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import useAuth from '../../features/authentication/hooks/useAuth';
import {
  getFeatureFlags,
  getUseFeatureFlagsQKey,
} from '../../hooks/useFeatureFlags';

/**
 * Add prefetches to this provider that might improve app performance. If any of these
 * prefetches fail the app will still render.
 */
export const PrefetchingProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { user, distribution } = useAuth();
  const queryClient = useQueryClient();

  const doPrefetches = async () => {
    await queryClient.prefetchQuery(getUseFeatureFlagsQKey(), () =>
      getFeatureFlags(distribution, user),
    );
  };

  // Prefetching
  useEffect(() => {
    if (user && distribution) doPrefetches();
  }, [user, distribution]);

  return <>{children}</>;
};
