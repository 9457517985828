import { useDisclosure } from '@wegroup/design-system';
import { useForm, UseFormReturn } from 'react-hook-form';
import { usePostInsuranceTermsBotFeedback } from '../api/postInsuranceTermsBotFeedback';

interface UseLouiseChatFeedbackModal {
  handleFeedbackClose: () => void;
  handleNegativeFeedback: (feedback: string) => void;
  handlePositiveFeedback: () => void;
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  formMethods: UseFormReturn<FeedbackFormValues>;
}

export interface FeedbackFormValues {
  feedback: string;
}

export const useLouiseChatFeedbackModal = (
  conversationId: string,
): UseLouiseChatFeedbackModal => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { mutate } = usePostInsuranceTermsBotFeedback();
  const methods = useForm<FeedbackFormValues>({
    defaultValues: {
      feedback: '',
    },
  });

  const handleClose = () => {
    methods.reset({ feedback: '' });
    onClose();
  };

  const handleNegativeFeedback = (feedback?: string) => {
    mutate({
      is_positive: false,
      conversation_id: conversationId,
      ...(feedback && { remark: feedback }),
    });
    handleClose();
  };

  const handleFeedbackClose = () => handleNegativeFeedback();

  const handlePositiveFeedback = () => {
    mutate({
      is_positive: true,
      conversation_id: conversationId,
    });
  };

  return {
    handleFeedbackClose,
    handleNegativeFeedback,
    handlePositiveFeedback,
    onClose,
    onOpen,
    isOpen,
    formMethods: methods,
  };
};
