import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Address } from '../../../../../../../../types/Common';
import { formatAddress } from '../../../../../../../common/src/utils/commonUtils';
import { mq } from '../../../../../../../common/src/utils/styledComponentsUtils';
import BrokerInfoLabelValue from './BrokerInfoLabelValue';

interface Props {
  address: Address;
  email: string;
  phone: string;
  fsma: string;
  registrationType: string;
  registrationNumber: string;
}

const BrokerInfoDetails: React.FC<React.PropsWithChildren<Props>> = ({
  address,
  email,
  phone,
  fsma,
  registrationType,
  registrationNumber,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper data-test-id="ProdFac_BrokerInfoDetails">
      <BrokerInfoLabelValue
        label={t('Address')}
        value={formatAddress(address)}
        data-test-id="ProdFac_BrokerInfoDetails_Address"
      />
      <BrokerInfoLabelValue
        label={t('Email')}
        value={email}
        data-test-id="ProdFac_BrokerInfoDetails_Email"
      />
      <BrokerInfoLabelValue
        label={t('Phone')}
        value={phone}
        data-test-id="ProdFac_BrokerInfoDetails_Phone"
      />
      {registrationType === 'KVK' ? (
        <BrokerInfoLabelValue
          label={t('AFM')}
          value={fsma}
          data-test-id="ProdFac_BrokerInfoDetails_FSMA"
        />
      ) : (
        <BrokerInfoLabelValue
          label={t('FSMA')}
          value={fsma}
          data-test-id="ProdFac_BrokerInfoDetails_FSMA"
        />
      )}
      <BrokerInfoLabelValue
        label={t('COMPANY-NUMBER')}
        value={registrationNumber}
        data-test-id="ProdFac_BrokerInfoDetails_KBO"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-gap: 1.6rem;

  ${mq.mobileL} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2.4rem;
  }
`;

export default BrokerInfoDetails;
