import {
  Text,
  HStack,
  Select,
  StackProps,
  Skeleton,
  Box,
} from '@wegroup/design-system';
import React, { PropsWithChildren, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FormOption } from '../../../../../../../types/Form';

interface Props {
  name: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label: string;
  options?: FormOption[];
  isLoading?: boolean;
  isDisabled?: boolean;
  isAvailable?: boolean;
}

const SelectWithLabel = forwardRef<
  HTMLSelectElement,
  Props & Omit<StackProps, 'onChange'>
>(
  (
    {
      name,
      value,
      onChange,
      label,
      options,
      isLoading,
      isDisabled,
      isAvailable = true,
      ...otherProps
    },
    ref,
  ) => {
    const { t } = useTranslation();

    const isLoadingItems = isLoading || !options;

    if (!isAvailable) {
      return (
        <SelectWrapper
          bg={isDisabled ? 'gray.ultraLight' : 'unset'}
          label={label}
          {...otherProps}
        >
          <Text>-</Text>
        </SelectWrapper>
      );
    }

    if (isLoadingItems) {
      return (
        <SelectWrapper
          bg={isDisabled ? 'gray.ultraLight' : 'unset'}
          label={label}
          {...otherProps}
        >
          <Box p="3" w="100%">
            <Skeleton h="5" w="100%" maxW="48" />
          </Box>
        </SelectWrapper>
      );
    }

    return (
      <SelectWrapper
        bg={isDisabled ? 'gray.ultraLight' : 'unset'}
        label={label}
        {...otherProps}
      >
        <Select
          name={name}
          variant="unstyled"
          h="10"
          value={value}
          onChange={onChange}
          ref={ref}
          placeholder={t('CHOOSE_OPTION')}
          isDisabled={isDisabled}
          _disabled={{ opacity: 1, color: 'gray.400' }}
        >
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </SelectWrapper>
    );
  },
);

interface SelectWrapperProps {
  label: string;
}

const SelectWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<SelectWrapperProps & StackProps>
>(({ label, children, ...otherProps }, ref) => {
  return (
    <HStack w="100%" {...otherProps} ref={ref} h="14" transition="0.2s">
      <Text color={'gray.400'} flexShrink={0}>
        {label}:
      </Text>
      {children}
    </HStack>
  );
});

export default SelectWithLabel;
