import { useBoolean } from "@chakra-ui/hooks";
import { useResizeObserver } from "@mantine/hooks";
import { MutableRefObject, useLayoutEffect } from "react";



interface ReturnProps {
  isOverflowingLeft: boolean;
  isOverflowingRight: boolean;
  isOverflowing: boolean;
  checkOverflow: () => void;
  ref: MutableRefObject<HTMLDivElement | null>
}

const useIsOverflow = (): ReturnProps => {
  const [ref, rect] = useResizeObserver();
  const [isOverflowing, setIsOverflowing] = useBoolean();
  const [isOverflowingLeft, setIsOverflowingLeft] = useBoolean();
  const [isOverflowingRight, setIsOverflowingRight] = useBoolean();
  
  const checkOverflow = () => {
    const { current } = ref;
    if (current) {
      const isScrollable = current.scrollWidth > current.clientWidth;
      const isAtEnd =
        current.clientWidth >= current.scrollWidth - current.scrollLeft - 10;
      const isAtStart = current.scrollLeft < 10;

      if (isScrollable) {
        setIsOverflowing.on();
        // Right
        if (isAtStart) setIsOverflowingLeft.off();
        else setIsOverflowingLeft.on();
        // Left
        if (isAtEnd) setIsOverflowingRight.off();
        else setIsOverflowingRight.on()
      } else {
        setIsOverflowing.off();
        // set all off
        setIsOverflowingLeft.off();
        setIsOverflowingRight.off();
      }
    }
  };

  useLayoutEffect(() => {
    checkOverflow()
  }, [rect])

  return {
    isOverflowingLeft,
    isOverflowingRight,
    isOverflowing,
    checkOverflow,
    ref,
  }
}

export default useIsOverflow;