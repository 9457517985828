import {
  VStack,
  UploadZone,
  UploadList,
  UploadListProps,
  UploadZoneProps,
  HStack,
  Divider,
  Text,
} from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LinkUpload, { LinkUploadProps } from './LinkUpload';
import UploadLinkList, { UploadLinkListProps } from './UploadLinkList';

interface Props {
  withLink?: boolean;
  uploadZoneProps: UploadZoneProps;
  uploadListProps: UploadListProps;
  linkUploadProps?: LinkUploadProps;
  linkUploadListProps?: UploadLinkListProps;
}

const FullUploadComponent: React.FC<Props> = ({
  withLink,
  uploadZoneProps,
  uploadListProps,
  linkUploadProps,
  linkUploadListProps,
}) => {
  const { t } = useTranslation();

  return (
    <VStack spacing={3} alignItems="stretch">
      <UploadZone {...uploadZoneProps} />
      <UploadList {...uploadListProps} />
      {withLink && (
        <>
          <HStack spacing="3" w="100%">
            <Divider borderColor="gray.50" />
            <Text textStyle="smallBody" color="gray.300">
              {t('OR').toUpperCase()}
            </Text>
            <Divider borderColor="gray.50" />
          </HStack>
          {linkUploadProps && <LinkUpload {...linkUploadProps} />}
          {linkUploadListProps && <UploadLinkList {...linkUploadListProps} />}
        </>
      )}
    </VStack>
  );
};

export default FullUploadComponent;
