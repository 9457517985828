import { SlideFade, VStack } from '@wegroup/design-system';
import React, { useEffect } from 'react';
import { useTypewriter } from 'react-simple-typewriter';
import { InsuranceTermsBotResource } from '../../api/postInsuranceTermsBotChat';
import ChatFeedback from './ChatFeedback';
import LouiseSingleChat from './LouiseSingleChat';
import useTracking from '../../../../../../common/src/hooks/tracking/useTracking';
import LouiseChatResetButton from './LouiseChatResetButton';

interface Props {
  conversationId?: string;
  message?: string;
  resources?: InsuranceTermsBotResource[];
  isIdle?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  onTypewriterDone?: () => void;
  onReset?: () => void;
}

const LouiseChat: React.FC<Props> = ({
  conversationId,
  message,
  resources,
  isIdle,
  isLoading,
  isError,
  isSuccess,
  onReset,
  onTypewriterDone,
}) => {
  return (
    <SlideFade in={!isIdle}>
      {isLoading ? (
        <LouiseSingleChat isLoading />
      ) : isError ? (
        <LouiseSingleChat isError />
      ) : isSuccess ? (
        <LouiseChatWithTypeWriter
          conversationId={conversationId}
          message={message}
          resources={resources}
          onTypewriterDone={onTypewriterDone}
          onReset={onReset}
        />
      ) : null}
    </SlideFade>
  );
};

/**
 * We add this component so that the typewriter is only rendered once the response is successful
 */
const LouiseChatWithTypeWriter: React.FC<Props> = ({
  conversationId,
  message,
  resources,
  onReset,
  onTypewriterDone,
}) => {
  const { trackEvent } = useTracking();
  const [text, helper] = useTypewriter({
    words: [message || ''],
    typeSpeed: 0.8,
  });

  // Since the typewriter does not expose a working callback, I added a useEffect here to trigger the callback instead
  useEffect(() => {
    if (helper.isDone) onTypewriterDone?.();
  }, [helper.isDone]);

  const handleReset = () => {
    trackEvent('Submit a new question after a Louise GPT answer');
    onReset?.();
  };

  return (
    <VStack spacing="6" align="stretch">
      <LouiseSingleChat
        message={text}
        resources={resources}
        isTyperDone={helper.isDone}
      />
      {helper.isDone && (
        <SlideFade in>
          <VStack spacing="6">
            <ChatFeedback conversationId={conversationId!} />
            <LouiseChatResetButton onReset={handleReset} />
          </VStack>
        </SlideFade>
      )}
    </VStack>
  );
};

export default LouiseChat;
