import { Button, Flex, Tooltip } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useTracking from '../../../../../../common/src/hooks/tracking/useTracking';

interface Props {
  onSubmit: () => void;
  isDisabled?: boolean;
  isSubmitDisabled?: boolean;
  tooltipText?: string;
}

const QuestionBodyFooter: React.FC<Props> = ({
  onSubmit,
  isDisabled,
  isSubmitDisabled,
  tooltipText,
}) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();

  const handleClick = () => {
    trackEvent('Submit question to Louise GPT');
    onSubmit();
  };

  return (
    <Flex
      justify="flex-end"
      p="3"
      bg={isDisabled ? 'gray.ultraLight' : 'unset'}
    >
      <Tooltip label={tooltipText} isDisabled={!tooltipText}>
        <Button
          onClick={handleClick}
          isDisabled={isDisabled || isSubmitDisabled}
          size="sm"
        >
          {t('TOOLKIT_INSURANCE-TERMS-BOT_ACTIONS_ASK-YOUR-QUESTION')}
        </Button>
      </Tooltip>
    </Flex>
  );
};

export default QuestionBodyFooter;
