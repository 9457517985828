import i18n from '../../../../../i18n';

export const capitalize = (s) => {
  if (!s) return '';
  const l = s.toLowerCase();
  return l && l[0].toUpperCase() + l.slice(1);
};

export const localSort = (a, b) => {
  return i18n.t(a).localeCompare(i18n.t(b));
};

export const toCurrency = (value) =>
  value?.toLocaleString('nl-BE', {
    style: 'currency',
    currency: 'EUR',
  });

export const stringLength = (string) => {
  if (typeof string === 'string') {
    return string.length;
  }

  return 0;
};

export const hasNumber = (address) => {
  return /\d/.test(address);
};

export const parseCBE = (cbe) => {
  if (cbe.length !== 10) return console.error('Invalid CBE number given');
  return `${cbe.substring(0, 4)}.${cbe.substring(4, 7)}.${cbe.substring(
    7,
    10,
  )}`;
};

export const phoneChangeConversion = (value, country) => {
  value = value?.replace().split(' ').join('');
  if (value[0] === '0' && country) {
    value = `+${country.dialCode}${value.substring(1, value.length)}`;
  } else if (value[0] !== '+' && value.length > 0) {
    value = `+${value}`;
  }

  return value;
};

export const phoneBlurConversion = (value, country) => {
  value = value.replace('+', '').split(' ').join('');
  if (value[0] === '0' && country) {
    value = `+${country.dialCode}${value.substring(1, value.length)}`;
  } else if (value[0] !== '+' && value.length > 0) {
    value = `+${value}`;
  }
  return value;
};

export const parseEscapeSequences = (input = '') => {
  const escapeSequences = {
    '\n': '<br/>',
  };

  Object.entries(escapeSequences).forEach(
    ([escapeKey, htmlValue]) =>
      (input = input.replace(
        /* 
        For searching all the occurunces we use regular expression
        We could also use the function replaceAll instead, but it's not supported by older browsers/NodeJS
        Also, IE has no support for replaceAll at all
        */
        new RegExp(escapeKey, 'g'),
        htmlValue,
      )),
  );

  return input;
};

export const getInitialsByName = (name) => {
  const obj = {
    initials: '',
    firstNameWithInitials: '',
  };

  if (name) {
    const nameParts = name.split(' ');
    const lastNameFirstLetter =
      nameParts.length === 1 ? '' : nameParts[nameParts.length - 1].charAt(0);

    // Name examples based on Donald John Trump
    // Normal initials (for example: DT)
    obj.initials = nameParts[0].charAt(0) + lastNameFirstLetter;

    // Semi-initials (for example: Donald J.T. or Lorik K.)
    let firstNameWithInitials = nameParts[0] + ' ';
    if (nameParts.length >= 3) {
      firstNameWithInitials +=
        nameParts[1].charAt(0) +
        '.' +
        (lastNameFirstLetter && lastNameFirstLetter + '.');
    } else {
      firstNameWithInitials += lastNameFirstLetter && lastNameFirstLetter + '.';
    }

    obj.firstNameWithInitials = firstNameWithInitials;
  }

  return obj;
};
