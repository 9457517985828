import { useEffect, useState } from 'react';
import {
  ACCESS_TOKEN_EXPIRED_EVENT_NAME,
  ACCESS_TOKEN_UNEXPIRED_EVENT_NAME,
} from '../constants/Jwt';

/**
 * Hook which tracks the state of the access token expiration.
 * We use window events to track the auth state as we have
 * no react state to track this.
 */
const useIsAccessTokenExpired = () => {
  const [isAccessTokenExpired, setIsAccessTokenExpired] =
    useState<boolean>(false);

  const setExpired = () => {
    setIsAccessTokenExpired(true);
  };

  const setUnexpired = () => {
    setIsAccessTokenExpired(false);
  };

  useEffect(() => {
    window.addEventListener(ACCESS_TOKEN_EXPIRED_EVENT_NAME, setExpired);
    window.addEventListener(ACCESS_TOKEN_UNEXPIRED_EVENT_NAME, setUnexpired);

    return () => {
      window.removeEventListener(ACCESS_TOKEN_EXPIRED_EVENT_NAME, setExpired);
      window.removeEventListener(
        ACCESS_TOKEN_UNEXPIRED_EVENT_NAME,
        setUnexpired,
      );
    };
  }, []);

  return isAccessTokenExpired;
};

export default useIsAccessTokenExpired;
