import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationResidenceAppartment: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="M109.398 79.928h66.762v145.446h-66.762V79.928Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M123.701 94.234h19.074v104.912h-19.074V94.234Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M176.16 103.773h-72.171a1.741 1.741 0 0 1-1.741-1.741v-6.056a1.744 1.744 0 0 1 1.741-1.742h72.171v9.539ZM176.16 127.616h-72.171a1.745 1.745 0 0 1-1.741-1.742v-6.053a1.745 1.745 0 0 1 1.741-1.741h72.171v9.536ZM176.16 151.462h-72.171a1.745 1.745 0 0 1-1.741-1.741v-6.057a1.742 1.742 0 0 1 1.741-1.741h72.171v9.539ZM176.16 175.304h-72.171a1.741 1.741 0 0 1-1.741-1.741v-6.053a1.742 1.742 0 0 1 1.741-1.741h72.171v9.535ZM176.16 199.149h-72.171a1.741 1.741 0 0 1-1.741-1.741v-6.056a1.745 1.745 0 0 1 1.741-1.742h72.171v9.539Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M118.934 208.685h57.227v16.692h-57.227v-16.692ZM163.891 58.47h-6.06a1.74 1.74 0 0 0-1.741 1.742v19.715h9.542V60.212a1.74 1.74 0 0 0-1.741-1.741Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M175.906 58.47h81.069v166.907h-81.069V58.471Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M190.468 77.545h14.307v11.921h-14.307V77.545ZM235.771 77.545h11.921v11.921h-11.921V77.545ZM190.468 101.387h14.307v11.921h-14.307v-11.921ZM235.771 101.387h11.921v11.921h-11.921v-11.921ZM190.468 125.233h14.307v11.922h-14.307v-11.922ZM235.771 125.233h11.921v11.922h-11.921v-11.922ZM190.468 149.075h14.307v11.921h-14.307v-11.921ZM235.771 149.075h11.921v11.921h-11.921v-11.921ZM190.468 172.921h14.307v11.921h-14.307v-11.921ZM235.771 172.921h11.921v11.921h-11.921v-11.921Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M221.181 185.187V77.225"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M198.261 37h-6.052a1.744 1.744 0 0 0-1.742 1.741v19.72h9.536V38.74A1.744 1.744 0 0 0 198.261 37ZM248.335 44.164h-6.057a1.743 1.743 0 0 0-1.741 1.741v12.566h9.539V45.905a1.74 1.74 0 0 0-1.741-1.74ZM231.47 204.676a2.456 2.456 0 0 0-2.45-2.453h-24.54a2.453 2.453 0 0 0-2.454 2.453v20.814h29.444v-20.814Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M216.747 202.223v23.265M68.096 223.652v-16.156"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m67.818 139.598.003-.009.002-.008a.29.29 0 0 1 .277-.209.286.286 0 0 1 .277.209l.002.008.003.008c2.2 7.461 5.67 19.415 8.587 30.349 1.46 5.469 2.776 10.667 3.727 14.912.97 4.33 1.504 7.448 1.504 8.894a14.095 14.095 0 0 1-8.704 13.026 14.093 14.093 0 0 1-15.366-3.056 14.096 14.096 0 0 1-4.13-9.97c0-1.446.535-4.564 1.504-8.894.95-4.246 2.266-9.443 3.725-14.912 2.918-10.934 6.387-22.888 8.59-30.348Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          d="M291.345 225.024v-12.55"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M284.889 183.42c2.174-8.151 4.744-17.031 6.459-22.855 1.714 5.824 4.284 14.705 6.459 22.855 1.133 4.246 2.154 8.276 2.89 11.566.756 3.374 1.158 5.746 1.158 6.811a10.509 10.509 0 0 1-17.936 7.43 10.505 10.505 0 0 1-2.278-3.409l-1.848.765 1.848-.765a10.509 10.509 0 0 1-.8-4.021c0-1.065.402-3.437 1.158-6.811.736-3.29 1.757-7.32 2.89-11.566Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationResidenceAppartment;
