import {
  HStack,
  Button,
  useDisclosure,
  LegacyOverflowCollapse,
} from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OverviewFilterModal from './modal/OverviewFilterModal';

export interface OverviewFilterInput {
  /** Unique translation key */
  tKey: string;
  input: React.ReactElement;
}

interface Props {
  onSubmit: () => void;
  filters: OverviewFilterInput[];

  onReset: () => void;
  haveFiltersChangedFromDefault: boolean;
  haveFiltersChanged: boolean;
}

export const OverviewFilter: React.FC<Props> = ({
  onReset,
  onSubmit,
  filters,
  haveFiltersChangedFromDefault,
  haveFiltersChanged,
}) => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleReset = () => {
    onReset();
    onSubmit();
  };

  return (
    <>
      <OverviewFilterModal
        isOpen={isOpen}
        isDirty={haveFiltersChanged}
        onClose={onClose}
        onApplyFilters={onSubmit}
        filters={filters}
      />
      <HStack
        bg="gray.ultraLight"
        border="1px solid"
        borderColor="gray.50"
        borderRadius="md"
        p="2"
        boxShadow="level1"
        spacing="4"
      >
        <LegacyOverflowCollapse
          expandElement={
            <Button
              ml="6"
              mr="3"
              variant="link"
              onClick={onOpen}
              data-test-id="CONVERSATIONS_OVERVIEW_FILTERS_BUTTON_MORE-FILTERS"
            >
              {t('CONVERSATIONS_OVERVIEW_FILTERS_BUTTON_MORE-FILTERS')}
            </Button>
          }
          spacing="1"
        >
          {filters.map(({ input }) => input)}
        </LegacyOverflowCollapse>
        <HStack>
          {/* Filters should have the ability to be reset to the default */}
          {haveFiltersChanged || !haveFiltersChangedFromDefault ? (
            <Button onClick={onSubmit} isDisabled={!haveFiltersChanged}>
              {t('APPLY-FILTERS')}
            </Button>
          ) : (
            <Button
              onClick={handleReset}
              isDisabled={!haveFiltersChangedFromDefault}
              variant="tertiary"
            >
              {t('CONVERSATIONS_OVERVIEW_FILTERS_BUTTON_RESET-FILTERS')}
            </Button>
          )}
        </HStack>
      </HStack>
    </>
  );
};
