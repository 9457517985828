import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';

interface DebouncedSearchSelectQuery {
  query: string;
  searchValue: string;
  handleSearchChange: (q: string) => void;
  cancelDebouncedSetQuery: () => void;
}

const useDebouncedSearchSelectQuery = (): DebouncedSearchSelectQuery => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [query, setQuery] = useState<string>('');
  const [debouncedSetQuery, cancelDebouncedSetQuery] = useDebouncedCallback(
    setQuery,
    500,
  );

  const handleSearchChange = (query: string) => {
    setSearchValue(query);
    if (query) {
      debouncedSetQuery(query);
    } else {
      /**
       * We only want to debounce when the API is being called.
       * If the value is empty, the API is not being called.
       * Therefore we cancel any pending debounces and
       * immediatly set the value
       */
      cancelDebouncedSetQuery();
      setQuery(query);
    }
  };

  return { searchValue, query, handleSearchChange, cancelDebouncedSetQuery };
};

export default useDebouncedSearchSelectQuery;
