import { Box } from '@wegroup/design-system';
import Overflow, { OverflowProps } from 'rc-overflow';

function OverflowCollapse<T>(props: OverflowProps<T>) {
  return (
    <Box
      w="100%"
      sx={{
        '.rc-overflow': {
          display: 'flex',
          flexWrap: 'wrap',
          maxWidth: '100%',
          position: 'relative',

          '&-item': {
            flex: 'none',
            maxWidth: ' 100%',
          },
        },
      }}
    >
      <Overflow {...props} />
    </Box>
  );
}

export default OverflowCollapse;
