import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycleTrekking: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_912_3665"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_912_3665)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2zM214 113.367l-33 68-3.599-1.746 33-68 3.599 1.746z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M150 182.367c0 22.091-17.909 40-40 40s-40-17.909-40-40c0-22.092 17.909-40 40-40s40 17.908 40 40z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M139 182.367c0 16.016-12.984 29-29 29s-29-12.984-29-29c0-16.017 12.984-29 29-29s29 12.983 29 29z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M286 182.367c0 22.091-17.909 40-40 40s-40-17.909-40-40c0-22.092 17.909-40 40-40s40 17.908 40 40z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M275 182.367c0 16.016-12.984 29-29 29s-29-12.984-29-29c0-16.017 12.984-29 29-29s29 12.983 29 29z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M111.5 182.367l25.974-67.139a1 1 0 00-.933-1.361H120"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M135.804 122.867c-1.611 0-2.561 1.806-1.648 3.133l40.248 58.5c.373.543.989.867 1.648.867h68.144c1.611 0 2.561-1.807 1.648-3.134l-40.248-58.5a2 2 0 00-1.648-.866h-68.144zm7.607 4c-1.611 0-2.561 1.806-1.648 3.133l34.744 50.5a2 2 0 001.648.867h58.434c1.611 0 2.561-1.807 1.648-3.134l-34.744-50.5a2.001 2.001 0 00-1.648-.866h-58.434z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M190 182.367c0 6.627-5.373 12-12 12s-12-5.373-12-12c0-6.628 5.373-12 12-12s12 5.372 12 12z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M178 190.367a8 8 0 100-16 8 8 0 000 16zm0 4c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12zM190 113.367a2 2 0 012-2h28a2 2 0 110 4h-28a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <rect
          width="48"
          height="57"
          x="222"
          y="146"
          fill={colors.primary[200]}
          stroke="#fff"
          strokeWidth="4"
          rx="6"
        ></rect>
        <rect
          width="40"
          height="49"
          x="226"
          y="150"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M226 168h40M236.5 162v12M255.5 162v12"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="4"
          d="M107.791 175.908l18.962-47.404c1.059-2.648-.906-5.482-3.703-5.515-.996-.012-1.978-.031-2.943-.049-2.542-.049-4.967-.096-7.22-.008-3.145.122-6.142.505-8.998 1.565-5.835 2.164-10.564 6.946-15.129 16.505-.084.177-.159.368-.217.559l1.904.579-1.904-.579c-2.457 8.087-3.033 14.485-.862 20.292 2.156 5.766 6.883 10.566 14.087 15.804 2.111 1.534 5.069.637 6.023-1.749z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M105.934 175.165l18.962-47.404c.527-1.319-.45-2.755-1.87-2.772-14.792-.178-23.564-1.759-32.46 16.874-.043.089-.08.186-.11.279-4.816 15.85-1.751 23.545 12.488 33.897 1.04.756 2.513.32 2.99-.874z"
        ></path>
        <rect
          width="11"
          height="14"
          x="101.689"
          y="140"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
          transform="rotate(19.572 101.689 140)"
        ></rect>
      </g>
    </svg>
  );
};

export default IllustrationBicycleTrekking;
