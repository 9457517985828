import { FormControl, FormLabel, Box } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  tKey: string;
  input: React.ReactElement;
}

const SingleOverviewFilterModalInput: React.FC<Props> = ({ tKey, input }) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <FormLabel>{t(tKey)}</FormLabel>
      <Box
        sx={{
          /**
           * We make all children max width as the input might have
           * a static width or a different max width
           */

          '> *': {
            w: '100%',
            maxW: '100%',
          },
        }}
      >
        {input}
      </Box>
    </FormControl>
  );
};

export default SingleOverviewFilterModalInput;
