/**
 * Retrieves the broker id used by flows to identify the session
 * @returns The broker id included in the url
 */
const useBrokerId = (): string | undefined => {
  return getBrokerIdParam();
};

export const getBrokerIdParam = (): string => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  const broker_id = params.get('broker_id');
  if (broker_id && typeof broker_id === 'string') {
    return broker_id;
  }

  const brokerId = params.get('brokerId');
  if (brokerId && typeof brokerId === 'string') {
    return brokerId;
  }

  return '';
};

export default useBrokerId;
