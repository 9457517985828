import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMusicalInstruments: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="356"
      height="229"
      fill="none"
      viewBox="0 0 356 229"
    >
      <mask
        id="mask0_5603_12627"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .843h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_5603_12627)">
        <path
          fill={colors.primary[50]}
          d="M337 161.843c0 88.365-71.634 160-160 160s-160-71.635-160-160c0-88.366 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M198.618 96.91c-4.537-1.352-9.457-2.554-14.279-2.904-4.821-.35-9.607.149-13.832 2.261-8.544 4.272-14.281 14.842-14.04 36.448.007.598.174 1.187.45 1.692 1.516 2.772 3.273 7.577 2.351 13.279-.917 5.67-4.509 12.395-13.998 18.993a1.295 1.295 0 01-.544.223c-.383.053-.752.09-1.105.112-1.244.077-2.477.717-3.423 1.55-.944.832-1.737 1.977-1.951 3.216-.369 2.137-1.282 4.633-3.209 6.858-1.191 1.375-3.875 4.534-6.36 8.758-2.48 4.216-4.801 9.557-5.202 15.296-.404 5.776 1.141 11.912 6.315 17.628 5.147 5.686 13.83 10.896 27.59 14.996s23.879 4.491 31.298 2.549c7.459-1.952 12.111-6.241 14.934-11.297 2.805-5.023 3.785-10.762 4.017-15.649.232-4.896-.285-9.008-.529-10.811-.396-2.917.206-5.506 1.067-7.495.499-1.155.462-2.547.127-3.76-.335-1.215-1.017-2.425-2.016-3.171a13.87 13.87 0 01-.863-.699 1.285 1.285 0 01-.334-.484c-4.33-10.715-3.656-18.309-1.32-23.557 2.349-5.276 6.448-8.336 9.235-9.827.507-.271.97-.673 1.303-1.17 12.026-17.95 13.009-29.936 8.197-38.188-2.38-4.08-6.113-7.117-10.339-9.463-4.227-2.346-9.003-4.032-13.54-5.384z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M199.031 95.68c-8.972-2.552-19.993-4.706-28.719-.29-9.005 4.558-14.671 15.546-14.29 36.926.022 1.236.392 2.407.906 3.39 1.453 2.784 2.956 7.287 2.024 12.57-.922 5.228-4.285 11.538-13.141 17.896l-.032.017a2.714 2.714 0 01-.42.146 1.331 1.331 0 01-.126.026c-1.747.221-3.341 1.201-4.527 2.348-1.192 1.154-2.192 2.687-2.566 4.317-.407 1.774-1.223 3.721-2.719 5.491-1.187 1.406-3.877 4.653-6.356 8.989-2.468 4.318-4.801 9.844-5.143 15.806-.347 6.037 1.358 12.43 6.805 18.297 5.391 5.808 14.333 10.981 28.242 14.937 13.909 3.956 24.235 4.263 31.875 2.161 7.72-2.124 12.534-6.663 15.416-11.979 2.846-5.249 3.77-11.176 3.944-16.147.174-4.991-.405-9.168-.675-10.988-.34-2.292-.01-4.377.578-6.1.539-1.582.496-3.412.089-5.021-.404-1.6-1.244-3.272-2.613-4.379a2.419 2.419 0 01-.374-.434l-.019-.03c-4.184-10.068-3.723-17.202-1.756-22.133 1.987-4.983 5.635-8.021 8.336-9.624.954-.566 1.885-1.366 2.555-2.406 11.575-17.98 12.539-30.305 7.28-38.919-5.097-8.348-15.602-12.315-24.574-14.867zM160.063 232.7c13.521 3.845 23.046 3.987 29.72 2.151 6.596-1.814 10.559-5.6 12.96-10.028 2.437-4.495 3.301-9.733 3.463-14.38.162-4.627-.376-8.522-.634-10.262-.448-3.026-.008-5.758.749-7.978.233-.684.263-1.701-.002-2.749-.268-1.056-.758-1.85-1.251-2.249a5.923 5.923 0 01-.848-.865c-.231-.286-.524-.695-.712-1.149-4.511-10.837-4.221-19.08-1.79-25.176 2.413-6.048 6.815-9.686 10.011-11.582.536-.318.965-.715 1.233-1.131 11.301-17.556 11.265-28.059 7.229-34.67-4.199-6.877-13.211-10.532-22.254-13.103-9.043-2.572-18.63-4.207-25.819-.569-6.911 3.498-12.469 12.41-12.097 33.285.009.495.164 1.059.452 1.611 1.72 3.294 3.549 8.704 2.418 15.116-1.14 6.463-5.232 13.625-14.77 20.467-.399.287-.863.481-1.21.603a5.926 5.926 0 01-1.176.288c-.629.08-1.465.498-2.248 1.255-.777.752-1.286 1.632-1.448 2.337a16.954 16.954 0 01-3.561 7.177c-1.135 1.344-3.642 4.373-5.94 8.393-2.308 4.037-4.33 8.945-4.623 14.05-.288 5.029 1.09 10.333 5.743 15.347 4.71 5.073 12.884 9.965 26.405 13.811z"
          clipRule="evenodd"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M175.362 148.861l-14.558 48.863M186.674 178.831l-22.476-6.696M230.615 27.242l-20.648-6.152M182.977 151.131l-14.559 48.862M201.205 88.227l-14.558 48.862M190.306 153.314l-14.558 48.863"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M179.119 136.786l35.248-106.693a3.844 3.844 0 017.366 2.185L193.106 140.94a5 5 0 01-6.263 3.518l-4.404-1.312a5 5 0 01-3.32-6.36z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M200.596 40.104a1.466 1.466 0 011.824-.987l6.744 2.01a1.466 1.466 0 11-.838 2.81l-6.743-2.01a1.466 1.466 0 01-.987-1.823z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M203.752 38.363l-2.741-.816-1.467 4.923 2.741.817 1.467-4.924z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M206.563 39.2a2.933 2.933 0 00-1.973-3.648l-2.742-.816a2.933 2.933 0 00-3.648 1.973l-1.467 4.924a2.933 2.933 0 001.973 3.648l2.742.817a2.933 2.933 0 003.648-1.974l1.467-4.924zm-2.811-.837l-2.741-.817-1.467 4.924 2.741.817 1.467-4.924zM194.633 55.017a1.466 1.466 0 011.824-.987l6.744 2.01a1.467 1.467 0 11-.838 2.81l-6.743-2.01a1.467 1.467 0 01-.987-1.823z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M197.789 53.276l-2.741-.816-1.467 4.923 2.741.817 1.467-4.924z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M200.6 54.114a2.933 2.933 0 00-1.973-3.649l-2.741-.816a2.934 2.934 0 00-3.649 1.973l-1.467 4.924a2.933 2.933 0 001.974 3.648l2.741.817a2.933 2.933 0 003.648-1.974l1.467-4.923zm-2.811-.838l-2.741-.817-1.467 4.924 2.741.817 1.467-4.924zM230.173 41.877a1.639 1.639 0 01-2.039 1.103l-7.537-2.245a1.64 1.64 0 11.936-3.142l7.537 2.245a1.638 1.638 0 011.103 2.04z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M226.646 43.823l3.063.913 1.64-5.503-3.064-.913-1.639 5.503z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M223.504 42.886a3.278 3.278 0 002.206 4.077l3.064.913a3.278 3.278 0 004.078-2.205l1.639-5.504a3.278 3.278 0 00-2.205-4.078l-3.064-.912a3.279 3.279 0 00-4.078 2.205l-1.64 5.504zm3.142.936l3.064.912 1.64-5.503-3.064-.913-1.64 5.504zM226.186 58.382a1.64 1.64 0 01-2.039 1.103l-7.537-2.246a1.638 1.638 0 11.936-3.141l7.537 2.245a1.64 1.64 0 011.103 2.04z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M222.658 60.328l3.064.913 1.64-5.503-3.064-.913-1.64 5.503z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M219.517 59.39a3.279 3.279 0 002.206 4.078l3.064.913a3.278 3.278 0 004.077-2.206l1.64-5.503a3.278 3.278 0 00-2.206-4.078l-3.064-.912a3.277 3.277 0 00-4.077 2.205l-1.64 5.504zm3.142.937l3.064.912 1.639-5.503-3.064-.913-1.639 5.504z"
          clipRule="evenodd"
        ></path>
        <rect
          width="10.908"
          height="17.636"
          x="217.583"
          y="14.158"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="5.454"
          transform="rotate(16.59 217.583 14.158)"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M150.012 190.811c-1.618-2.65-5.055-5.983-5.859 1.875-1.006 9.824 1.466 20.731-5.215 16.366M189.932 202.11c2.804-1.332 7.504-2.241 3.877 4.777-4.535 8.771-12.573 16.547-4.593 16.551"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.843a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M118.72 71.791a6.087 6.087 0 11-11.76-3.151 6.087 6.087 0 0111.76 3.151zm0 0l6.827-25.476 19.597 5.25-6.826 25.478m0 0a6.088 6.088 0 11-11.76-3.152 6.088 6.088 0 0111.76 3.152zm-14.872-22.89l19.598 5.252M269.968 164.274a6.09 6.09 0 01-4.822 7.131 6.088 6.088 0 01-6.197-9.386 6.087 6.087 0 0111.019 2.255zm0 0l-5.004-25.897 19.92-3.849 5.004 25.897m0 0a6.09 6.09 0 01-4.822 7.131 6.086 6.086 0 114.822-7.131zm-23.384-14.08l19.92-3.849"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationMusicalInstruments;
