import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationOwnerOfPets: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_3963_10265"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3963_10265)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M213.418 85.165a166.86 166.86 0 00-29.98-25.975 5.1 5.1 0 00-2.146-.674 5.039 5.039 0 00-2.219.316 4.908 4.908 0 00-1.841 1.243 4.783 4.783 0 00-1.086 1.916 213.216 213.216 0 00-4.313 43.599c12.295-9.488 26.437-16.434 41.585-20.425zM264.612 98.882a166.606 166.606 0 0138.96-7.502 5.076 5.076 0 012.195.486 5.03 5.03 0 011.763 1.383c.473.586.805 1.27.969 1.998a4.779 4.779 0 01-.022 2.202 213.63 213.63 0 01-18.054 39.915c-5.906-14.368-14.684-27.455-25.811-38.482z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M211.45 194.884c-37.072-9.933-60.93-41.12-53.282-69.664 7.649-28.544 54.874-40.684 54.874-40.684l52.22 13.992s34.817 34.129 27.17 62.668c-7.647 28.539-43.909 43.622-80.982 33.688z"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M235.252 106.017c-23.634-6.332-57.361 3.927-59.868 34.856a50.988 50.988 0 002.481 22.723 50.856 50.856 0 0012.155 19.335c7.827-1.687 25.765-6.82 29.748-19.061-2.677 12.591 10.302 26.008 16.231 31.381a50.864 50.864 0 0020.195-10.667 50.985 50.985 0 0013.51-18.438c13.26-28.043-10.823-53.798-34.452-60.129z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M239.051 198.14s-22.794-17.788-19.316-34.163c-5.18 15.919-33.81 19.928-33.81 19.928"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M216.51 148.618a6.338 6.338 0 00-3.825.174 6.494 6.494 0 00-3.064 2.351 6.604 6.604 0 00-1.211 3.69 6.529 6.529 0 001.073 3.716l4.772 7.232a7.278 7.278 0 004.186 3.025 7.275 7.275 0 005.138-.527l7.749-3.877a6.526 6.526 0 002.787-2.682 6.604 6.604 0 00.796-3.801 6.494 6.494 0 00-1.478-3.567 6.341 6.341 0 00-3.225-2.064l-13.698-3.67z"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M192.891 123.136a10.497 10.497 0 007.424 12.86 10.497 10.497 0 0012.86-7.424 10.502 10.502 0 00-7.425-12.86 10.494 10.494 0 00-7.967 1.049 10.493 10.493 0 00-4.892 6.375zM243.119 136.595a10.497 10.497 0 007.424 12.86 10.497 10.497 0 0012.86-7.424 10.497 10.497 0 00-7.424-12.86 10.497 10.497 0 00-12.86 7.424z"
        ></path>
        <g filter="url(#filter0_dd_3963_10265)">
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M94.703 87.858a21.196 21.196 0 01-33.487 25.963c-5.3-6.853-7.778-29.027-8.71-40.092a4.217 4.217 0 015.614-4.367c10.447 3.677 31.284 11.65 36.583 18.496zM138.834 76.032a21.204 21.204 0 0010.318 21.175 21.194 21.194 0 0023.511-1.428 21.205 21.205 0 008.18-14.013c1.163-8.585-7.778-29.027-12.503-39.076a4.223 4.223 0 00-3.252-2.416 4.22 4.22 0 00-3.787 1.44c-7.235 8.416-21.301 25.74-22.467 34.318z"
          ></path>
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M147.168 195.388c-40.946 10.971-118.251-85.33-29.254-109.177 88.998-23.847 70.194 98.207 29.254 109.177z"
          ></path>
          <path
            fill={colors.primary[500]}
            d="M155.197 181.441c1.629 6.077-8.049 13.952-8.049 13.952s-12.325-1.98-13.953-8.056a11.395 11.395 0 0116.648-12.613 11.395 11.395 0 015.354 6.717z"
          ></path>
          <path
            fill={colors.primary[100]}
            d="M178.096 154.987c9.709-35.723-.833-80.249-52.694-70.532a55.653 55.653 0 0052.694 70.532z"
          ></path>
          <path
            fill={colors.primary[500]}
            d="M96.9 132.065a10.598 10.598 0 1020.474-5.487 10.598 10.598 0 00-20.474 5.487zM138.561 120.901a10.597 10.597 0 1020.472-5.484 10.597 10.597 0 00-20.472 5.484z"
          ></path>
          <path
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M147.168 195.388c-40.946 10.971-118.251-85.33-29.254-109.177 88.998-23.847 70.194 98.207 29.254 109.177z"
          ></path>
        </g>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_3963_10265"
          width="180.86"
          height="208.021"
          x="26.487"
          y="30.234"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="16"></feOffset>
          <feGaussianBlur stdDeviation="12"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3963_10265"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="4"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_3963_10265"
            result="effect2_dropShadow_3963_10265"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_3963_10265"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationOwnerOfPets;
