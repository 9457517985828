import React, { PropsWithChildren } from 'react';
import useInitTracking from '../../../../common/src/hooks/tracking/useInitTracking';
import BrokerComponents from '../components/BrokerComponents';
import {
  NotificationProvider,
  HubspotProvider,
  UserPilotProvider,
  PermissionsProvider,
  PrefetchingProvider,
  TrackingProvider,
} from '../function-providers';

const BrokerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  useInitTracking();

  return (
    <PrefetchingProvider>
      <PermissionsProvider>
        <BrokerComponents>
          <UserPilotProvider>
            <HubspotProvider>
              <TrackingProvider>
                <NotificationProvider>{children}</NotificationProvider>
              </TrackingProvider>
            </HubspotProvider>
          </UserPilotProvider>
        </BrokerComponents>
      </PermissionsProvider>
    </PrefetchingProvider>
  );
};

export default BrokerProvider;
