import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehiclePerformance: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.506 5.844a2 2 0 012.15-1.838c.748.059 1.485.156 2.21.291C39.617 6.113 47 14.663 47 24.942a2 2 0 01-4 0c0-8.317-5.974-15.243-13.866-16.712a17.007 17.007 0 00-1.79-.236 2 2 0 01-1.838-2.15zm-1.04.053a2 2 0 01-1.6 2.333 16.873 16.873 0 00-5.3 1.947 2 2 0 01-1.987-3.47 20.875 20.875 0 016.555-2.41 2 2 0 012.332 1.6zm-10.415 4.137a2 2 0 01-.013 2.829 17.1 17.1 0 00-1.902 2.238 2 2 0 01-3.26-2.318 21.101 21.101 0 012.347-2.762 2 2 0 012.828.013zm-5.072 6.22a2 2 0 011.149 2.585c-.173.449-.327.907-.462 1.373a2 2 0 01-3.843-1.11c.167-.578.358-1.144.571-1.7a2 2 0 012.585-1.148zm-1.91 6.112a2 2 0 011.939 2.059c-.005.171-.008.344-.008.517a2 2 0 01-4 0c0-.213.003-.425.01-.636a2 2 0 012.058-1.94z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.596 24.213l5.967-5.966c.643-.644.532-1.799-.25-2.58-.78-.78-1.935-.892-2.578-.25l-5.727 5.727a4 4 0 102.588 3.07z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskObjectsVehiclePerformance;
