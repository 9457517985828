import { SimpleGrid, Skeleton } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Distribution } from '../../../../../../../app/src/features/authentication/types/Distribution';
import LabeledParam from '../../../../../../../common/src/components/LabeledParam';
import useIsNLplan from '../../../../../../../common/src/hooks/useIsNLPlan';
import { formatAddress } from '../../../../../../../common/src/utils/commonUtils';

interface Props {
  isLoading: boolean;
  distribution?: Distribution;
}

const BrokerContactInfoDetails: React.FC<React.PropsWithChildren<Props>> = ({
  isLoading,
  distribution,
}) => {
  const { t } = useTranslation();
  const { isNL } = useIsNLplan();

  return (
    <SimpleGrid columns={2} gap="4">
      <SkeletonParam
        label={t('ADDRESS')}
        value={distribution?.address && formatAddress(distribution.address)}
        isLoading={isLoading}
      />

      <SkeletonParam
        label={t('EMAIL')}
        value={distribution?.email}
        isLoading={isLoading}
      />
      <SkeletonParam
        label={t('TELEPHONE-NUMBER')}
        value={distribution?.phone}
        isLoading={isLoading}
      />
      {isNL ? (
        <SkeletonParam
          label={t('AFM')}
          value={distribution?.afm}
          isLoading={isLoading}
        />
      ) : (
        <SkeletonParam
          label={t('FSMA')}
          value={distribution?.fsma?.key}
          isLoading={isLoading}
        />
      )}
      <SkeletonParam
        label={t('COMPANY-NUMBER')}
        value={distribution?.company_registration?.nr}
        isLoading={isLoading}
      />
    </SimpleGrid>
  );
};

interface SkeletonParamProps {
  isLoading?: boolean;
  label: string;
  value?: string;
}

const SkeletonParam: React.FC<React.PropsWithChildren<SkeletonParamProps>> = ({
  isLoading,
  label,
  value,
}) => {
  return (
    <LabeledParam label={label}>
      {isLoading ? <Skeleton h="4" /> : value ? value : '-'}
    </LabeledParam>
  );
};

export default BrokerContactInfoDetails;
