import posthog from 'posthog-js';
import { isSocialLoginUrl } from '../constants/SocialAuth';
import { ChangeBrokerProps } from '../types/Distribution';
import OAuth from './OAuth';

// To support HMR we cannot use top level await here sadly
export const WgOAuth = (() => {
  if (isSocialLoginUrl) return undefined;

  let oauth = undefined;
  try {
    oauth = new OAuth({
      redirectUri: window.location.origin,
    });
  } catch (error) {
    console.error(error);
  }

  return oauth;
})();

export const login = (redirectUri?: string) => {
  if (redirectUri === '/logout') redirectUri = '/dashboard';
  return WgOAuth!.fetchAuthorizationToken({ redirectUri });
};

export const authenticateByLoginToken = ({
  loginToken,
  brokerId,
  redirectUri,
}: {
  loginToken: string;
  brokerId?: string;
  redirectUri?: string;
}) => {
  return WgOAuth!.fetchAuthorizationToken({
    loginToken,
    brokerId,
    redirectUri,
  });
};

export const changeDistributionByBrokerId = ({
  brokerId,
  redirectUri,
}: ChangeBrokerProps) => {
  return WgOAuth!.fetchAuthorizationToken({
    brokerId,
    redirectUri: redirectUri || '/dashboard',
  });
};

export const onUserReceivedAuthorizationToken = () => {
  return WgOAuth!.authorizationCodeGrantRequest();
};

export const refreshToken = () => {
  return WgOAuth!.refreshTokenGrantRequest();
};

export const logout = () => {
  posthog?.reset();
  localStorage.setItem('logout-event', 'logout' + Math.random());
  return WgOAuth!.revocationRequest();
};
