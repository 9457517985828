import {
  VStack,
  IconButton,
  Icon,
  Card,
  Text,
  Flex,
  Image,
  ButtonGroup,
  Link,
} from '@wegroup/design-system';
import React, { useMemo } from 'react';
import { formatBytes } from '../../../../../common/src/utils/commonUtils';
import UploadSkeleton from './UploadSkeleton';

interface SingleFileProps {
  file: File;
  onRemove: () => void;
  onEdit?: () => void;
}

const SingleFile: React.FC<React.PropsWithChildren<SingleFileProps>> = ({
  file,
  onRemove,
  onEdit,
}) => {
  // This useMemo is for memory management
  // https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
  const preview = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }

    return '';
  }, [file]);

  const handleDownload = () => {
    const url = URL.createObjectURL(file);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.target = '_blank';
    a.href = url;
    a.setAttribute('download', file.name);
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <Card>
      <Flex as={Flex} alignItems="center">
        {file.type.includes('image/') && (
          <Link variant="gray" href={preview} isExternal mr="2">
            <Image src={preview} maxH="6" maxW="12" />
          </Link>
        )}
        <Text textStyle="smallBody" color="black" mr="1" flexGrow={1}>
          {file.name}
        </Text>
        <Text textStyle="smallBody" color="text.gray" flexShrink={0} mr="1">
          ({formatBytes(file.size)})
        </Text>
        <ButtonGroup variant="white" isAttached>
          {onEdit && (
            <IconButton
              size="sm"
              aria-label="edit"
              onClick={onEdit}
              icon={<Icon boxSize="5" name="GeneralActionsEditDocument" />}
            />
          )}
          <IconButton
            color="danger.main"
            size="sm"
            aria-label="download"
            onClick={handleDownload}
            icon={<Icon boxSize="5" name="GeneralActionsDownload" />}
            borderRight="none"
          />
          <IconButton
            color="danger.main"
            size="sm"
            aria-label="remove"
            onClick={onRemove}
            icon={<Icon boxSize="5" name="GeneralActionsThrash" />}
          />
        </ButtonGroup>
      </Flex>
    </Card>
  );
};

export interface UploadListProps {
  files?: File[];
  file?: File;
  onRemove: ((index: number) => void) | (() => void);
  onEdit?: ((index: number) => void) | (() => void);
  isLoading?: boolean;
}

const UploadList: React.FC<React.PropsWithChildren<UploadListProps>> = ({
  files = [],
  file,
  onRemove,
  onEdit,
  isLoading,
}) => {
  if (isLoading) return <UploadSkeleton />;

  if (file) {
    return (
      <SingleFile
        file={file}
        onRemove={() => onRemove(0)}
        {...(onEdit && { onEdit: () => onEdit(0) })}
      />
    );
  }

  return (
    <VStack spacing="2" alignItems="stretch">
      {files.map((file, index) => {
        return (
          <SingleFile
            key={index}
            file={file}
            {...(onEdit && { onEdit: () => onEdit(index) })}
            onRemove={() => onRemove(index)}
          />
        );
      })}
    </VStack>
  );
};

export default UploadList;
