const Link = {
  baseStyle: {
    textDecoration: 'underline',
    color: 'primary.500',
    _hover: {
      textDecoration: 'none',
      color: 'primary.500',
    },
  },
  variants: {
    gray: {
      textDecoration: 'underline',
      color: 'gray.500',
      _hover: {
        textDecoration: 'none',
        color: 'primary.500',
      },
    },
    success: {
      textDecoration: 'underline',
      color: 'success.main',
      _hover: {
        textDecoration: 'none',
        color: 'success.dark',
      },
    },
    danger: {
      textDecoration: 'underline',
      color: 'danger.main',
      _hover: {
        textDecoration: 'none',
        color: 'danger.dark',
      },
    },
  },
};

export default Link;
