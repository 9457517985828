import { keyframes as styledKeyframes } from 'styled-components';
import { keyframes } from '@chakra-ui/react';

export const outlineCircleSlideUp = styledKeyframes`
    0% {
      opacity: 0;
      border-bottom-right-radius: 0;
    }
    29% {
      opacity: 0;
    }
    30% {
      opacity: 1;
      border-bottom-right-radius: 0;
    }
    100% {
      opacity: 1;
      border-bottom-right-radius: 30px;
    }
`;
export const outlineCircleSlideDown = styledKeyframes`
    0% {
      border-top-right-radius: 0;
    }
    29% {
      opacity: 0;
    }
    30% {
      opacity: 1;
      border-top-right-radius: 0;
    }
    100% {
      opacity: 1;
    }
`;

export const goUp = styledKeyframes`
from {
  bottom: -5rem;
}
to {
  bottom: 1.6rem;
}
`;

export const goUpChakra = keyframes`
from {
  transform: translate(0, 100%);
}
to {
  transform: translate(0, 0);
}
`;

export const blink = keyframes`
  0% {opacity: 0.1}
  50% {opacity: 1}
  100% {opacity: 0.1}
`;

export const fadeIn = keyframes`
  from {opacity: 0}
  to {opacity: 1}
`;
