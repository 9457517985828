import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersChatAbandoned: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 23h4a2 2 0 100-4H9a4 4 0 00-4 4v20a2 2 0 003.414 1.414L13.828 39H27a4 4 0 004-4v-4a2 2 0 10-4 0v4H13a2 2 0 00-1.414.586L9 38.172V23z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M38.452 22.704a1.87 1.87 0 01-2.645 0L31 17.897l-4.807 4.807a1.87 1.87 0 01-2.645-2.645l4.807-4.807-4.807-4.807A1.87 1.87 0 1126.193 7.8L31 12.607 35.807 7.8a1.87 1.87 0 112.645 2.645l-4.807 4.807 4.807 4.807c.73.73.73 1.915 0 2.645z"
    ></path>
  </Icon>
);

export default GeneralOthersChatAbandoned;
