import { setTokens } from '../utils/jwt';
import useAuth from './useAuth';

const LOCAL_STORAGE_OLD_OAUTH_OBJECT_KEY = 'oauth2authcodepkce-state';

/**
 * This hook makes sure that users don't have to relog when they still have
 * access token from the old method of authenticating.
 */
const useAuthMigration = (): void => {
  const { isAuthorized } = useAuth();

  if (!isAuthorized) {
    const oldAuthJson = localStorage.getItem(
      LOCAL_STORAGE_OLD_OAUTH_OBJECT_KEY,
    );
    if (oldAuthJson) {
      localStorage.removeItem(LOCAL_STORAGE_OLD_OAUTH_OBJECT_KEY);
      const oldAuthObject = JSON.parse(oldAuthJson);
      const accessToken = oldAuthObject?.accessToken?.value;
      const idToken = oldAuthObject?.idToken;
      const refreshToken = oldAuthObject?.refreshToken?.value;

      if (accessToken && idToken && refreshToken)
        setTokens({
          accessToken,
          idToken,
          refreshToken,
        });
    }
  }
};

export default useAuthMigration;
