import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBuildingListed: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3773_10880"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3773_10880)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M87.368 245.786a4.904 4.904 0 003.976 5.682l140.059 24.697a4.908 4.908 0 005.68-3.981l27.677-156.963-33.812-48.275-103.839-18.31a4.901 4.901 0 00-5.679 3.977L87.368 245.786z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M264.763 115.221l-36.541-6.443a4.578 4.578 0 01-3.715-5.305l6.441-36.528 33.815 48.276z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M123.261 144.461l30.127 5.312M125.828 129.906l49.149 8.667M128.348 115.617l103.255 18.207"
        ></path>
        <rect
          width="72.089"
          height="22.922"
          x="136.156"
          y="69.606"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
          transform="rotate(10 136.156 69.606)"
        ></rect>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M224.443 207.211l1.888-.66-1.888.66 3.402 9.721c.23.658.102 1.388-.34 1.927l-6.521 7.971 1.547 1.266-1.547-1.266a5.996 5.996 0 00-1.252 2.684l-1.914 10.12a2 2 0 01-1.258 1.499l-9.633 3.642a6.007 6.007 0 00-2.427 1.699l-6.717 7.807a2.001 2.001 0 01-1.839.669l-10.163-1.663a5.991 5.991 0 00-2.951.259l.661 1.887-.661-1.887-9.721 3.402a2.001 2.001 0 01-1.927-.34l-7.971-6.522-1.266 1.548 1.266-1.548a6.007 6.007 0 00-2.684-1.252l-10.12-1.913a2.003 2.003 0 01-1.499-1.258l-3.642-9.634a5.985 5.985 0 00-1.699-2.426l-7.806-6.717a2 2 0 01-.67-1.839l1.663-10.164a6.004 6.004 0 00-.258-2.951l-1.888.661 1.888-.661-3.402-9.72a1.998 1.998 0 01.339-1.927l6.522-7.971a5.995 5.995 0 001.252-2.685l1.914-10.119a1.998 1.998 0 011.258-1.499l9.633-3.642a6.004 6.004 0 002.426-1.699l6.717-7.807a2 2 0 011.839-.669l10.164 1.663c.99.161 2.004.073 2.951-.259l-.661-1.887.661 1.887 9.72-3.402a2.003 2.003 0 011.928.34l7.97 6.522a6.006 6.006 0 002.685 1.252l10.119 1.913a2 2 0 011.499 1.258l3.642 9.634a6.004 6.004 0 001.699 2.426l7.807 6.717a2 2 0 01.669 1.839l-1.662 10.164a6 6 0 00.258 2.95z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M192.687 179.872l1.267-1.548-1.267 1.548a6.006 6.006 0 002.685 1.252l6.64 1.256a2.002 2.002 0 011.499 1.257l1.87-.707-1.87.707 2.389 6.321a6.007 6.007 0 001.699 2.427l5.122 4.407a2 2 0 01.67 1.839L212.3 205.3a6 6 0 00.258 2.95l1.888-.66-1.888.66 2.232 6.378a2 2 0 01-.339 1.928l-4.28 5.23 1.548 1.266-1.548-1.266a5.995 5.995 0 00-1.251 2.684l-1.256 6.64a2.001 2.001 0 01-1.258 1.499l-6.321 2.39a5.992 5.992 0 00-2.426 1.698l-4.407 5.123a2 2 0 01-1.839.669l-6.669-1.091a6.003 6.003 0 00-2.951.258l.661 1.888-.661-1.888-6.378 2.233a2 2 0 01-1.927-.34l-5.23-4.279-1.267 1.548 1.267-1.548a5.995 5.995 0 00-2.685-1.252l-6.639-1.256a1.998 1.998 0 01-1.499-1.258l-2.39-6.32a6 6 0 00-1.699-2.427l-5.122-4.407a2 2 0 01-.67-1.839l1.091-6.669a6.003 6.003 0 00-.258-2.951l-1.888.661 1.888-.661-2.232-6.378a1.999 1.999 0 01.34-1.927l4.279-5.23a6.007 6.007 0 001.252-2.684l1.255-6.64a2 2 0 011.258-1.499l6.321-2.39a5.994 5.994 0 002.426-1.699l4.408-5.122a1.999 1.999 0 011.839-.669l6.668 1.091c.99.161 2.005.073 2.951-.259l6.378-2.232a2 2 0 011.927.34l5.23 4.279z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBuildingListed;
