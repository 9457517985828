import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationPersonalTransport: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M146 203.5c0 11.874-9.626 21.5-21.5 21.5s-21.5-9.626-21.5-21.5 9.626-21.5 21.5-21.5 21.5 9.626 21.5 21.5Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M124.5 221c9.665 0 17.5-7.835 17.5-17.5s-7.835-17.5-17.5-17.5-17.5 7.835-17.5 17.5 7.835 17.5 17.5 17.5Zm0 4c11.874 0 21.5-9.626 21.5-21.5s-9.626-21.5-21.5-21.5-21.5 9.626-21.5 21.5 9.626 21.5 21.5 21.5Z"
          fill={colors.primary[500]}
        />
        <path
          d="M135 203.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M124.5 210a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Zm0 4c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5 4.701 10.5 10.5 10.5Z"
          fill={colors.primary[500]}
        />
        <path
          d="M254 203.5c0 11.874-9.626 21.5-21.5 21.5s-21.5-9.626-21.5-21.5 9.626-21.5 21.5-21.5 21.5 9.626 21.5 21.5Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M232.5 221c9.665 0 17.5-7.835 17.5-17.5s-7.835-17.5-17.5-17.5-17.5 7.835-17.5 17.5 7.835 17.5 17.5 17.5Zm0 4c11.874 0 21.5-9.626 21.5-21.5s-9.626-21.5-21.5-21.5-21.5 9.626-21.5 21.5 9.626 21.5 21.5 21.5Z"
          fill={colors.primary[500]}
        />
        <path
          d="M243 203.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M232.5 210a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Zm0 4c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5 4.701 10.5 10.5 10.5ZM156.217 86.446l-.002-.002c.001-.001 0-.002-.001-.002l-23.749-6.364a2 2 0 0 0-2.45 1.414l-.517 1.932a2 2 0 0 0 1.414 2.45l16.182 4.336a2 2 0 0 1 1.423 2.415l-15.033 60.296a2 2 0 0 0 1.457 2.424l1.94.484a2.001 2.001 0 0 0 2.425-1.457l16.931-67.908a.012.012 0 0 0-.009-.015l-.011-.003Z"
          fill={colors.primary[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M137.196 159.732a1.999 1.999 0 0 0-2.732-.732l-1.732 1a1.999 1.999 0 0 0-.732 2.732l25.19 43.631a1 1 0 0 0 .582.459c.383.113.828.178 1.228.178h72a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-68.823a1.999 1.999 0 0 1-1.732-1l-23.249-40.268Z"
          fill={colors.primary[500]}
        />
        <path
          d="M130.09 155.397a2.001 2.001 0 0 1 2.425-1.457l6.792 1.694a2 2 0 0 1 1.457 2.424l-11.855 47.545a2 2 0 0 1-2.424 1.457l-6.792-1.694a2 2 0 0 1-1.457-2.424l11.854-47.545Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128.15 154.913a4 4 0 0 1 4.849-2.913l6.792 1.693a4 4 0 0 1 2.913 4.849l-11.854 47.545a4 4 0 0 1-4.849 2.913l-6.792-1.693a4 4 0 0 1-2.913-4.849l11.854-47.545Zm10.673 2.662-6.792-1.694-11.854 47.545 6.792 1.693 11.854-47.544Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationPersonalTransport;
