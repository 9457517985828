import {
  BoxProps,
  ComponentWithAs,
  Divider,
  TextProps,
  Box,
  Text,
} from '@wegroup/design-system';
import React from 'react';
import StrokeBox from '../common/StrokeBox';

interface Props {
  textStyle?: TextProps['textStyle'];
  title?: string;
  rightElement?: JSX.Element;
}

const GrayedCard: ComponentWithAs<'div', Props & BoxProps> = ({
  title,
  rightElement,
  children,
  textStyle,
  ...props
}) => {
  return (
    <StrokeBox
      align="stretch"
      p="0"
      {...props}
      divider={<Divider borderColor="gray.50" />}
    >
      {(title != null || rightElement != null) && (
        <Box
          position="relative"
          py="3"
          bg="gray.ultraLight"
          color="text.gray"
          w="100%"
          borderBottom="1px"
          borderBottomColor="gray.50"
          sx={{
            '@media print': {
              bg: 'transparent',
              color: 'black',
              borderBottom: '1px solid',
              borderBottomColor: 'gray.300',
            },
          }}
        >
          <Text textAlign="center" textStyle="normalBodyBold">
            {title}
          </Text>
          <Box position="absolute" right="0" top="0">
            {rightElement}
          </Box>
        </Box>
      )}
      <Box flexGrow={1} p="4">
        {children}
      </Box>
    </StrokeBox>
  );
};

export default GrayedCard;
