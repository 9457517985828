import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsAddCompany: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.573 10.144a1.693 1.693 0 00-1.693 1.693v3.386a1.693 1.693 0 11-3.386 0v-3.386a5.08 5.08 0 015.08-5.08h6.771a5.08 5.08 0 015.08 5.08v3.386a1.693 1.693 0 11-3.387 0v-3.386a1.693 1.693 0 00-1.693-1.693h-6.772zM22.96 21.995c.934 0 1.692.758 1.692 1.693v.017a1.693 1.693 0 01-3.386 0v-.017c0-.935.758-1.693 1.693-1.693z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M44.11 35.315h-4.343v-4.343a1.862 1.862 0 10-3.723 0v4.343H31.7a1.861 1.861 0 100 3.723h4.343v4.343a1.861 1.861 0 103.723 0v-4.343h4.343a1.861 1.861 0 000-3.723z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.108 16.916c-.935 0-1.693.758-1.693 1.693v5.71a32.168 32.168 0 0027.089 0v-5.71c0-.935-.758-1.693-1.694-1.693H11.108zM6.03 25.414V18.61a5.08 5.08 0 015.08-5.08H34.81a5.08 5.08 0 015.08 5.08v6.082a6.82 6.82 0 00-6.467 1.8 6.825 6.825 0 00-1.784 3.13 35.551 35.551 0 01-22.224-1.606v5.83c0 .936.758 1.694 1.693 1.694h14.406a6.825 6.825 0 00-.148 3.386H11.108a5.08 5.08 0 01-5.079-5.08v-8.43zm33.86 5.021v3.41c0 1.486-.637 2.822-1.652 3.75L39.566 30c.13.13.238.277.323.435zm-9.066 8.49a1.892 1.892 0 000 0z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsAddCompany;
