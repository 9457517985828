import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsSport: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 9C16.163 9 9 16.163 9 25s7.163 16 16 16 16-7.163 16-16S33.837 9 25 9zM5 25C5 13.954 13.954 5 25 5s20 8.954 20 20-8.954 20-20 20S5 36.046 5 25z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.826 13.38a2 2 0 012.347 0l9.52 6.9a2 2 0 01.733 2.224l-3.52 11.1A2 2 0 0131 35H19a2 2 0 01-1.907-1.396l-3.52-11.1a2 2 0 01.733-2.224l9.52-6.9zm-6.003 9.292L20.463 31h9.073l2.64-8.328L25 17.47l-7.177 5.202z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 5a2 2 0 012 2v8a2 2 0 11-4 0V7a2 2 0 012-2zM6.137 18.277a2 2 0 012.588-1.142l7.48 2.9a2 2 0 01-1.446 3.73l-7.48-2.9a2 2 0 01-1.142-2.588zm37.73 0a2 2 0 01-1.142 2.588l-7.48 2.9a2 2 0 01-1.446-3.73l7.48-2.9a2 2 0 012.587 1.142zM29.72 31.464a2 2 0 012.817.256l5 6a2 2 0 11-3.073 2.56l-5-6a2 2 0 01.256-2.816zm-9.288.126a2 2 0 01.2 2.82l-5.12 5.9a2 2 0 11-3.022-2.62l5.12-5.9a2 2 0 012.822-.2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsSport;
