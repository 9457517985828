import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCarMiniOmnium: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_4242_11093"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0-.001h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4242_11093)">
        <path
          fill={colors.primary[50]}
          d="M337 160.999c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.635 160 160z"
        ></path>
        <path
          fill="#fff"
          d="M344.131 186.499c0 14.5-11 21-21 21h-11.5l-25.5-5.5h-215.5c-19 0-35.5 3.5-50 0-11.6-2.8-13.167-9.5-12.5-12.5v-51.5c0-10.4 4.666-15.667 7-17l37.5-33.5h103c28.5 0 30 0 57 18s41 23.5 51 26 38.5 8.5 59.5 13c21 6.5 21 27.5 21 42z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M184.105 91.66c6.978 2.148 13.847 6.457 27.416 15.503 27.105 18.07 41.32 23.7 51.625 26.276 8.205 2.052 28.765 6.439 47.448 10.426 4.155.887 8.217 1.754 12.029 2.571 9.727 3.038 14.536 9.346 16.985 16.695 2.506 7.516 2.523 16.059 2.523 23.368 0 6.694-2.515 11.38-6.054 14.421-3.586 3.081-8.352 4.579-12.946 4.579h-11.287l-25.5-5.5H70.631c-6.686 0-13.112.432-19.172.839-2.611.176-5.154.346-7.62.476-8.277.436-15.785.419-22.739-1.259-5.469-1.32-8.296-3.489-9.706-5.413-1.425-1.945-1.516-3.784-1.31-4.709l.047-.214v-51.72c0-9.82 4.393-14.35 5.992-15.263l.183-.105 37.088-33.132h102.237c14.356 0 21.512.02 28.474 2.162zm-28.13-6.161H51.868l-37.926 33.88c-3.077 1.892-7.811 7.872-7.811 18.62v51.3c-.385 2.063.022 4.958 2.037 7.707 2.106 2.876 5.863 5.457 11.994 6.937 7.546 1.822 15.538 1.805 23.886 1.366 2.581-.136 5.188-.311 7.834-.489 6-.404 12.202-.821 18.749-.821h215.287l25.5 5.5h11.713c5.406 0 11.139-1.752 15.553-5.546 4.462-3.834 7.447-9.648 7.447-17.454v-.134c0-7.171 0-16.315-2.728-24.498-2.793-8.379-8.464-15.807-19.681-19.279l-.085-.026-.087-.019c-3.861-.827-7.97-1.704-12.17-2.6-18.683-3.987-39.135-8.352-47.264-10.384-9.696-2.424-23.48-7.794-50.376-25.724-13.431-8.954-20.811-13.645-28.458-15.997-7.601-2.34-15.38-2.34-29.307-2.339z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M323.131 186.999v20.5h-18.5l-18-6h-37.5v-14.5c0-16 19-26 27-26h16.5c19.2 0 30.5 15 30.5 26z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M292.631 162.999c18.132 0 28.5 14.141 28.5 24v18.5h-16.176l-18-6h-35.824v-12.5c0-7.154 4.25-13.134 9.732-17.425a36.046 36.046 0 018.431-4.911c2.773-1.133 5.191-1.664 6.837-1.664h16.5zm32.5 24c0-12.141-12.232-28-32.5-28h-16.5c-2.355 0-5.311.719-8.35 1.961a40.058 40.058 0 00-9.383 5.464c-6.017 4.709-11.267 11.729-11.267 20.575v16.5h39.175l18 6h20.825v-22.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M256.131 198.999c0 16.569 13.431 30 30 30 16.568 0 30-13.431 30-30 0-16.569-13.432-30-30-30-16.569 0-30 13.431-30 30z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M286.131 224.999c-14.36 0-26-11.641-26-26s11.64-26 26-26c14.359 0 26 11.641 26 26s-11.641 26-26 26zm0 4c-16.569 0-30-13.431-30-30 0-16.569 13.431-30 30-30 16.568 0 30 13.431 30 30 0 16.569-13.432 30-30 30z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M269.131 198.999c0 9.389 7.611 17 17 17s17-7.611 17-17-7.611-17-17-17-17 7.611-17 17z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M286.131 211.999c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13zm0 4c-9.389 0-17-7.611-17-17s7.611-17 17-17 17 7.611 17 17-7.611 17-17 17z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M34.13 186.999v16.5h18.5l18-2h37.501v-14.5c0-16-19-26-27-26h-16.5c-19.2 0-30.5 15-30.5 26z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M64.63 162.999c-18.131 0-28.5 14.141-28.5 24v14.5h16.39l18-2h35.611v-12.5c0-7.154-4.25-13.134-9.733-17.425a36.035 36.035 0 00-8.43-4.911c-2.773-1.133-5.192-1.664-6.837-1.664h-16.5zm-32.5 24c0-12.141 12.233-28 32.5-28h16.5c2.355 0 5.312.719 8.351 1.961a40.05 40.05 0 019.383 5.464c6.017 4.709 11.267 11.729 11.267 20.575v16.5h-39.39l-18 2h-20.61v-18.5z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M41.13 198.999c0 16.569 13.432 30 30 30 16.57 0 30.001-13.431 30.001-30 0-16.569-13.432-30-30-30-16.569 0-30 13.431-30 30z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M71.13 224.999c-14.358 0-26-11.641-26-26s11.642-26 26-26c14.36 0 26 11.641 26 26s-11.64 26-26 26zm0 4c-16.568 0-30-13.431-30-30 0-16.569 13.432-30 30-30 16.57 0 30.001 13.431 30.001 30 0 16.569-13.432 30-30 30z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M54.13 198.999c0 9.389 7.612 17 17 17 9.39 0 17-7.611 17-17s-7.61-17-17-17c-9.388 0-17 7.611-17 17z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M71.13 211.999c-7.179 0-13-5.82-13-13s5.821-13 13-13c7.18 0 13 5.82 13 13s-5.82 13-13 13zm0 4c-9.388 0-17-7.611-17-17s7.612-17 17-17c9.39 0 17 7.611 17 17s-7.61 17-17 17z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M216.131 133.499h-170.5c-14.5 0-10.4-11.6-6-16 5.5-5.5 11.5-11 17-16 4.4-4 12.834-5 16.5-5h92c12.4 0 18.834 2.667 20.5 4 10.167 6.5 31.5 20.1 35.5 22.5 5 3 4.5 10.5-5 10.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M178.926 99.94c3.258.807 4.955 1.72 5.456 2.121l.083.066.089.057c10.147 6.488 31.517 20.111 35.548 22.53 1.884 1.13 2.419 2.882 2.054 4.076-.327 1.072-1.742 2.709-6.025 2.709h-170.5c-3.396 0-5.38-.685-6.495-1.497-1.043-.76-1.513-1.768-1.597-2.99-.089-1.291.265-2.814.955-4.328.685-1.505 1.633-2.852 2.551-3.771 5.463-5.463 11.431-10.934 16.932-15.934 1.834-1.667 4.657-2.816 7.647-3.535 2.951-.71 5.818-.945 7.507-.945h92c6.06 0 10.605.652 13.795 1.441zm.961-3.882c-3.543-.877-8.415-1.559-14.756-1.559h-92c-1.977 0-5.16.266-8.443 1.056-3.243.78-6.836 2.131-9.402 4.464-5.5 5-11.532 10.529-17.069 16.066-1.281 1.281-2.495 3.034-3.364 4.942-.864 1.898-1.453 4.1-1.304 6.259.153 2.228 1.101 4.395 3.23 5.948 2.058 1.5 4.998 2.265 8.852 2.265h170.5c5.218 0 8.803-2.113 9.851-5.541 1.01-3.306-.706-6.804-3.822-8.674-3.958-2.375-25.155-15.887-35.372-22.42-1.195-.915-3.516-1.968-6.901-2.806z"
          clipRule="evenodd"
        ></path>
        <path fill="#fff" d="M148.131 96.999h-11l9 36h11l-9-36z"></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M149.693 94.999H134.57l10 40h15.123l-10-40zm-3.123 4l8 32h-6.877l-8-32h6.877z"
          clipRule="evenodd"
        ></path>
        <path fill="#fff" d="M92.13 96.999h-11l-18 36h11l18-36z"></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M95.367 94.999H79.895l-20 40h15.472l20-40zm-6.472 4l-16 32h-6.528l16-32h6.528z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M326.131 165.499c3.313 2.65 9 2.5 16.5 2.5 0-5.6-4.333-11.333-6.5-13.5h-13.5c-5.2 0-5.5 3.5-3.5 5s4.5 4 7 6z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M336.959 152.499h-14.328c-2.953 0-5.333 1.011-6.276 3.121-.93 2.081-.016 4.285 1.576 5.479.918.688 1.976 1.627 3.179 2.703l.189.17c1.124 1.006 2.35 2.103 3.582 3.089 2.104 1.682 4.814 2.363 7.719 2.671 2.601.275 5.624.272 8.876.268l1.155-.001h2v-2c0-3.283-1.257-6.466-2.695-9.019-1.448-2.571-3.186-4.69-4.391-5.895l-.586-.586zm-1.688 4a25.679 25.679 0 013.18 4.444c.911 1.617 1.636 3.351 1.974 5.057-2.845 0-5.291-.022-7.404-.246-2.642-.28-4.431-.849-5.641-1.817-1.143-.914-2.293-1.943-3.438-2.968l-.165-.148c-1.172-1.049-2.364-2.11-3.446-2.922-.409-.306-.344-.602-.324-.646.006-.015.377-.754 2.624-.754h12.64z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M15.131 133.499h-6.5v23h13.5c3.2 0 3.334-2.667 3-4-1.5-4.167-4.8-13.1-6-15.5-1.2-2.4-3.166-3.333-4-3.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M15.524 131.538a1.988 1.988 0 00-.393-.039h-6.5a2 2 0 00-2 2v23a2 2 0 002 2h13.5c1.078 0 2.056-.226 2.885-.717a4.394 4.394 0 001.75-1.932c.656-1.394.538-2.906.306-3.836a1.976 1.976 0 00-.059-.192c-1.477-4.103-4.826-13.183-6.093-15.717-1.493-2.987-3.997-4.287-5.396-4.567zm-.652 3.961c.45.159 1.666.786 2.47 2.394 1.123 2.246 4.328 10.899 5.867 15.171.032.155.053.35.046.551-.009.24-.055.419-.109.533a.4.4 0 01-.168.193c-.086.051-.324.158-.847.158h-11.5v-19h4.24z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M8.63 144.499h13v-4h-13v4z"
          clipRule="evenodd"
        ></path>
        <g filter="url(#filter0_dd_4242_11093)">
          <circle cx="238" cy="102.999" r="55" fill="#fff"></circle>
          <circle
            cx="238"
            cy="102.999"
            r="53"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M221.768 128.028a1.994 1.994 0 00-.268 1.386l1.394 8.126a2.001 2.001 0 003.911.149l1.71-6.791 27.798-45.168c1.469-2.388 2.702-5.36 2.527-8.436-.184-3.23-1.898-6.216-5.597-8.493-6.09-3.748-11.509-.726-13.631 1.41l-10.701 7.32a2 2 0 00.081 3.354l1.122.69a2 2 0 002.098 0l4.261-2.626-.492 4.914a1.998 1.998 0 00.942 1.903l1.346.828a2 2 0 003.019-1.363l1.021-5.91.986-1.603c2.008-3.262 4.698-3.327 6.236-2.411a3.504 3.504 0 011.652 2.358c.208 1.046.042 2.499-1.045 4.265l-28.37 46.098z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_4242_11093"
          width="142"
          height="142"
          x="167"
          y="39.999"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4242_11093"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_4242_11093"
            result="effect2_dropShadow_4242_11093"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_4242_11093"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationCarMiniOmnium;
