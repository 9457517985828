import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationRubberBoat: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          d="m247.444 176.513-12.528 26.464a2.985 2.985 0 0 0-.288 1.331l.356 22.233a3.001 3.001 0 0 0 2.891 2.95l50.388 1.826a3 3 0 0 0 3.108-3.047l-.781-48.765c-.342-5.714-2.222-7.754-8.757-9.021a3.073 3.073 0 0 0-.458-.052l-22.253-.737c-6.686.014-9.203 1.557-11.678 6.818Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="m110.911 240.873-40.782-51.964c-1.662-2.117.088-5.181 2.755-4.826l196.9 26.213a3 3 0 0 1 2.53 3.637l-5.29 23.335a2.998 2.998 0 0 1-2.877 2.336l-150.828 2.416a3 3 0 0 1-2.408-1.147Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="m118.273 207.315 147.099 7.589c19.179 1.534 20.328-18.744 1.523-19.916l-141.943-7.672c-22.481-1.6-32.322-3.99-43.838-11.335a3.009 3.009 0 0 0-.378-.205c-7.446-3.355-11.73-2.722-15.952 3.142a2.703 2.703 0 0 0-.476 1.162c-1.061 6.81 1.255 9.811 8.444 13.966 14.218 7.993 24.399 10.727 45.521 13.269Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationRubberBoat;
