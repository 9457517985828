import {
  Button,
  Icon,
  Spacer,
  useClipboard,
  Stack,
} from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useTracking from '../../../../../../common/src/hooks/tracking/useTracking';
import { InsuranceTermsBotResource } from '../../api/postInsuranceTermsBotChat';
import LouiseChatResources from './LouiseChatResources';

interface Props {
  message?: string;
  resources?: InsuranceTermsBotResource[];
  isCopyDisabled?: boolean;
  withCopy?: boolean;
}

const LouiseSingleChatFooter: React.FC<Props> = ({
  message,
  resources,
  isCopyDisabled,
  withCopy = true,
}) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { onCopy, hasCopied } = useClipboard(message || '');

  const handleClick = () => {
    trackEvent('Copy answer from Louise GPT to clipboard');
    onCopy();
  };

  return (
    <Stack direction={{ base: 'column', tabletS: 'row' }} p="3" spacing="2">
      {resources?.length && <LouiseChatResources resources={resources} />}
      {withCopy && (
        <>
          <Spacer />
          <Button
            leftIcon={
              <Icon
                name={
                  hasCopied
                    ? 'GeneralStatusSuccessCheckmark'
                    : 'GeneralActionsDuplicate'
                }
                boxSize={4}
              />
            }
            onClick={handleClick}
            isDisabled={hasCopied || isCopyDisabled}
            variant="white"
            size="sm"
            flexShrink={0}
          >
            {t('TOOLKIT_INSURANCE-TERMS-BOT_ANSWER_COPY-ACTION')}
          </Button>
        </>
      )}
    </Stack>
  );
};

export default LouiseSingleChatFooter;
