import { Box, HStack, VStack, Divider, Text } from '@wegroup/design-system';
import React, { PropsWithChildren } from 'react';
import LouiseAvatar from '../../../../../../dashboard/src/assets/LouiseAvatar';
import StrokeBox from '../../../../../../design-system/src/content/components/Card/common/StrokeBox';

interface LouiseChatWrapperProps {
  name?: string;
}

export const LouiseChatBox: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <StrokeBox p="0" w="100%" flexGrow={1} borderTopLeftRadius="0">
      <VStack
        divider={<Divider borderColor="gray.50" />}
        align="stretch"
        h="100%"
        minH="44px"
      >
        {children}
      </VStack>
    </StrokeBox>
  );
};

export const LouiseSingleAvatarWrapper: React.FC<
  PropsWithChildren<LouiseChatWrapperProps>
> = ({ name = 'Louise', children }) => {
  return (
    <HStack spacing="3" align="flex-start">
      <Box
        w="10"
        sx={{
          svg: {
            w: '100%',
            h: '100%',
          },
          '@media print': {
            display: 'none',
          },
        }}
        flexShrink={0}
      >
        <LouiseAvatar />
      </Box>
      <VStack spacing="1.5" align="stretch" w="100%">
        <Text
          textStyle="smallBody"
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
        >
          {name}
        </Text>

        {children}
      </VStack>
    </HStack>
  );
};
