import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMail: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="181"
      height="116"
      viewBox="0 0 181 116"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_774_17116"
        style={{ maskType: 'alpha' }}
        width="181"
        height="116"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M.5.5h180v115H.5V.5z"></path>
      </mask>
      <g mask="url(#mask0_774_17116)">
        <path
          fill={colors.primary[50]}
          d="M170.893 81.707c0 44.57-36.219 80.701-80.898 80.701-44.68 0-80.9-36.131-80.9-80.701 0-44.57 36.22-80.702 80.9-80.702 44.679 0 80.898 36.131 80.898 80.702z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M9.096 114.491a1.01 1.01 0 011.01-1.009h156.742a1.01 1.01 0 011.012 1.009 1.01 1.01 0 01-1.012 1.009H10.107a1.01 1.01 0 01-1.011-1.009z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M36.399 44.017c0-4.457 3.622-8.07 8.09-8.07h92.08c4.468 0 8.09 3.613 8.09 8.07V96.39c0 4.457-3.622 8.071-8.09 8.071h-92.08c-4.468 0-8.09-3.614-8.09-8.07V44.016z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M136.569 37.965h-92.08c-3.351 0-6.068 2.71-6.068 6.052V96.39c0 3.343 2.717 6.053 6.068 6.053h92.08c3.351 0 6.067-2.71 6.067-6.053V44.017c0-3.343-2.716-6.052-6.067-6.052zm-92.08-2.018c-4.468 0-8.09 3.613-8.09 8.07V96.39c0 4.457 3.622 8.071 8.09 8.071h92.08c4.468 0 8.09-3.614 8.09-8.07V44.016c0-4.457-3.622-8.07-8.09-8.07h-92.08z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M39.729 37.86c.356-.43.993-.49 1.424-.136l49.99 41.187c.431.355.492.99.137 1.42-.356.43-.993.49-1.424.135L39.865 39.28a1.007 1.007 0 01-.136-1.42z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M141.271 37.86a1.013 1.013 0 00-1.424-.136l-49.99 41.187c-.431.355-.492.99-.137 1.42.356.43.993.49 1.424.135l49.991-41.186c.431-.355.492-.991.136-1.42zM76.835 67.406a1.013 1.013 0 00-1.428-.062L39.76 99.957a1.007 1.007 0 00-.061 1.425c.377.41 1.017.438 1.428.061l35.646-32.612c.412-.376.44-1.014.062-1.425z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M103.912 67.406a1.013 1.013 0 011.429-.062l35.646 32.613c.411.376.439 1.014.062 1.425a1.013 1.013 0 01-1.429.061l-35.646-32.612a1.006 1.006 0 01-.062-1.425z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M149.657 44.634c0 10.446-8.489 18.914-18.96 18.914-10.472 0-18.961-8.468-18.961-18.914 0-10.446 8.489-18.915 18.961-18.915 10.471 0 18.96 8.469 18.96 18.915z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M130.697 61.53c9.354 0 16.938-7.564 16.938-16.896s-7.584-16.897-16.938-16.897c-9.355 0-16.939 7.565-16.939 16.897 0 9.332 7.584 16.897 16.939 16.897zm0 2.018c10.471 0 18.96-8.468 18.96-18.914 0-10.446-8.489-18.915-18.96-18.915-10.472 0-18.961 8.469-18.961 18.915s8.489 18.914 18.961 18.914z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill={colors.primary[500]}
        d="M128.552 54.53L118.5 44.476l2.261-2.26 7.583 7.583 11.703-13.9 2.453 2.068-13.948 16.561z"
      ></path>
    </svg>
  );
};

export default IllustrationMail;
