import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyVandalism: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M30.678 21.11l-2.476-.752c-.31-.089-.663.088-.751.398l-.266.796c1.017.795 1.503 2.122 1.194 3.492a3.387 3.387 0 01-2.962 2.564.598.598 0 00.398.398l2.476.752c.309.088.663-.089.751-.398l2.078-6.455c.088-.354-.088-.707-.442-.796zM31.518 19.43l-.398-.133.088-.221c.045-.177-.044-.398-.22-.442l-1.548-.487c-.177-.044-.398.045-.442.221l-.442 1.327 2.21.707.177-.575.398.133c.088.044.177 0 .177-.088l.088-.266a.335.335 0 00-.088-.177zM33.348 20.302a.575.575 0 10-1.097-.342.575.575 0 001.097.342zM34.505 21.74a.575.575 0 10-1.097-.341.575.575 0 001.097.341zM35.094 19.789a.575.575 0 10-1.097-.342.575.575 0 001.097.342zM35.677 23.133a.575.575 0 10-1.097-.341.575.575 0 001.097.341zM36.295 21.237a.575.575 0 10-1.098-.341.575.575 0 001.098.341zM36.883 19.287a.575.575 0 10-1.098-.34.575.575 0 001.098.34z"
    ></path>
    <path
      fill="currentColor"
      d="M27.097 24.69c.265-1.105-.398-2.254-1.548-2.52l-3.757-.928c-1.327-3.537-1.769-4.111-1.946-4.288-.265-.354-1.282-1.548-2.564-2.344l-1.459-.884a3.172 3.172 0 00-2.608-.353 3.278 3.278 0 00-1.99 1.68l-.795 1.635-3.803 7.207a2.455 2.455 0 00-.132 2.078l3.537 8.93v8.533a2.519 2.519 0 105.04 0V34.46c0-.31-.045-.619-.177-.928l-1.99-5.085 4.333-7.383c.31.796.707 1.769 1.017 2.653.22.663.796 1.15 1.459 1.326l4.863 1.194c.177.044.353.044.486.044.929.044 1.769-.619 2.034-1.591zM20.543 13.937a4.421 4.421 0 10-1.386-8.734 4.421 4.421 0 001.386 8.734zM43.013 4h-3.007a.68.68 0 00-.663.663v40.674c0 .353.31.663.663.663h3.007a.68.68 0 00.663-.663V4.663A.651.651 0 0043.013 4z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyVandalism;
