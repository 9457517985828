import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleSecondHandCar: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M16.45 13L10 24H5.45a3.5 3.5 0 100 7h2.46a3.45 3.45 0 002.48-1l1.24-1.23L16.45 37H46V13H16.45zM9 28.56a1.52 1.52 0 01-1.09.44H5.45a1.5 1.5 0 110-3H10l.56.95L9 28.56zM14 27a2.087 2.087 0 01-.51-.07l1.22-1.22a.999.999 0 00.21-1.09A1 1 0 0014 24h-1.77A2 2 0 1114 27zm10-.16a1 1 0 01-1-.84 7.54 7.54 0 016-8.38 7.39 7.39 0 014.78.62v-.54a.71.71 0 011.41 0V21a.24.24 0 01-.19.21h-3.38a.71.71 0 010-1.41h1.06a5.61 5.61 0 00-7.82 4.56 5.252 5.252 0 000 1.43.92.92 0 01-.86 1.05zm6.49 5.63a7.339 7.339 0 01-3.75-1v.78a.71.71 0 11-1.41 0V29a.24.24 0 01.24-.24h3.28a.71.71 0 010 1.41h-.59a5.63 5.63 0 007.79-6 .92.92 0 01.92-1.05 1 1 0 01.94.81c.048.328.078.659.09.99a7.57 7.57 0 01-7.55 7.55h.04z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleSecondHandCar;
