const parts = ['root', 'tablist', 'tab', 'tabpanels', 'tabpanel', 'indicator'];

const variantLine = {
  tablist: {
    borderBottom: '2px solid',
    borderColor: 'gray.50',
  },
  tab: {
    px: '3',
    mx: '2',
    py: '4',
    fontSize: '16',
    color: 'gray.500',
    _selected: {
      color: 'primary.500',
      borderColor: 'primary.500',
      textStyle: 'normalBodyMedium',
    },
    _active: { bg: 'none' },
    _focus: { boxShadow: 'none' },
    _first: {
      marginLeft: 0,
    },
    _last: {
      marginRight: 0,
    },
  },
};

const variants = {
  line: variantLine,
  box: {
    ...variantLine,
    tablist: {
      h: '14',
      bg: 'white',
    },
    tab: {
      ...variantLine.tab,
      px: '4',
      h: '100%',
      flexShrink: 0,
      _selected: {
        ...variantLine.tab._selected,
        borderBottom: '2px',
      },
    },
  },
};

const defaultProps = {
  size: 'lg',
};

const Tabs = {
  defaultProps,
  parts,
  variants,
};

export default Tabs;
