import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyAttic: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M29.64 29.73a1.21 1.21 0 01-1.71 0L26.21 28v6.77a1.21 1.21 0 01-2.42 0V28l-1.72 1.69a1.21 1.21 0 01-1.71 0 1.172 1.172 0 010-1.69l3.78-3.73a1.23 1.23 0 011.72 0L29.64 28a1.17 1.17 0 010 1.73z"
    ></path>
    <path
      fill="currentColor"
      d="M42.36 17.36l-4-2.64V8.24a1.12 1.12 0 00-1.12-1.12h-5.57a1.12 1.12 0 00-1.12 1.12v1.25L25.6 6.2a1.18 1.18 0 00-1.32 0L7.53 17.36a1.19 1.19 0 00-.53 1V42.8A1.2 1.2 0 008.2 44h33.48a1.2 1.2 0 001.2-1.2V18.35a1.2 1.2 0 00-.52-.99zm-2.72 23.4h-29.4V20h29.4v20.76z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyAttic;
