import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

// Components
import { Flex, useBreakpointValue } from '@wegroup/design-system';

// Services
import BrokerCardContainer from '../../../flows/src/components/ui/common/components/finalization/BrokerCardContainer';
import AssistantQuestion from '../../../sales-conversation/src/components/flow/AssistantQuestion';
import SmallToolbarWithBack from '../components/recommendations/SmallToolbarWithBack';
import useAuth from '../../../app/src/features/authentication/hooks/useAuth';

interface Props {
  customTitle?: string;
}

const CampaignsFinalizationView: React.FC<React.PropsWithChildren<Props>> = ({
  customTitle,
}) => {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, laptop: false });

  const { distribution } = useAuth();

  return (
    <Flex
      direction={{ base: 'column', tablet: 'row' }}
      alignItems="stretch"
      h="100%"
      w="100%"
    >
      {!isMobile && (
        <SmallToolbarWithBack
          tooltipText={t('RISK-ANALYSIS_RECOMMENDATIONS_SUCCESS-BUTTON-BACK')}
        />
      )}
      <Flex
        width="100%"
        height="100%"
        flexDir="column"
        background="white"
        overflowY="scroll"
        p={{ base: '3', tablet: '6' }}
        pb={{ base: '16', laptopL: '6' }}
      >
        <Flex maxW="4xl" flexDir="column" margin="0 auto">
          <StyledAssistantQuestion
            question={
              customTitle ||
              t('FLOWS_CAMPAIGNS_FINALIZATION_TITLE', {
                distribution: distribution.name,
              })
            }
          />

          <BrokerCardContainer />
        </Flex>
      </Flex>
    </Flex>
  );
};

const StyledAssistantQuestion = styled(AssistantQuestion)`
  margin: 0;
  align-self: center;
  margin-bottom: 1.6rem;

  h2 {
    margin: 0;
  }

  & .AssistantQuestion_avatar {
    display: none;
  }
`;

export default CampaignsFinalizationView;
