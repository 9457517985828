import React, { PropsWithChildren } from 'react';
import { ButtonProps, Button, Text, Spinner } from '@wegroup/design-system';

interface Props {
  isActive?: boolean;
  amount?: number;
  isLoading?: boolean;
}

const index: React.FC<PropsWithChildren<Props> & ButtonProps> = ({
  children,
  amount,
  isActive,
  isLoading,
  ...props
}) => {
  return (
    <Button
      gridGap="3"
      justifyContent="space-between"
      size="sm"
      px="2"
      py="1.5"
      variant={isActive ? 'secondary' : 'grayedGhost'}
      color={isActive ? undefined : 'black'}
      fontWeight={isActive ? undefined : '400'}
      wordWrap="break-word"
      {...props}
    >
      <Text whiteSpace="break-spaces" align="left">
        {children}
      </Text>
      {isLoading ? (
        <Spinner size="sm" />
      ) : (
        amount != null && (
          <Text color={isActive ? undefined : 'gray.500'}>{amount}</Text>
        )
      )}
    </Button>
  );
};

export default index;
