import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationClassicYacht: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_1661_15028"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_1661_15028)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M175.421 209.68V34"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M310.279 199.316c-102.224 4.468-163.136 4.486-264.048.007-2.91-.129-4.295 3.547-2.01 5.355l24.219 19.174c.53.42 1.186.648 1.862.648h215.896c.676 0 1.332-.228 1.862-.648l24.229-19.181c2.283-1.807.899-5.482-2.01-5.355zM89.921 181.895c30.778-3.419 50.417-3.106 82.145.457 1.785.201 3.351-1.213 3.347-3.01-.103-50.681.123-81.212.036-128.512-.006-3.121-4.191-4.175-5.671-1.427-26.807 49.802-46.113 80.442-82.602 127.612-1.635 2.113.09 5.175 2.745 4.88z"
        ></path>
        <mask
          id="mask1_1661_15028"
          style={{ maskType: 'alpha' }}
          width="94"
          height="140"
          x="84"
          y="45"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M89.921 181.895c30.778-3.419 50.417-3.106 82.145.457 1.785.201 3.351-1.213 3.347-3.01-.103-50.681.123-81.212.036-128.512-.006-3.121-4.191-4.175-5.671-1.427-26.807 49.802-46.113 80.442-82.602 127.612-1.635 2.113.09 5.175 2.745 4.88z"
          ></path>
        </mask>
        <g
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_1661_15028)"
        >
          <path
            d="M51.922 -4.273H323.496V18.672H51.922z"
            transform="rotate(30 51.922 -4.273)"
          ></path>
          <path
            d="M51.922 47.51H323.496V70.455H51.922z"
            transform="rotate(30 51.922 47.51)"
          ></path>
          <path
            d="M51.922 99.294H323.496V122.239H51.922z"
            transform="rotate(30 51.922 99.294)"
          ></path>
          <path
            d="M51.922 151.077H323.496V174.022H51.922z"
            transform="rotate(30 51.922 151.077)"
          ></path>
        </g>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M89.921 181.895c30.778-3.419 50.417-3.106 82.145.457 1.785.201 3.351-1.213 3.347-3.01-.103-50.681.123-81.212.036-128.512-.006-3.121-4.191-4.175-5.671-1.427-26.807 49.802-46.113 80.442-82.602 127.612-1.635 2.113.09 5.175 2.745 4.88z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M263.08 191.001c-32.67-3.909-52.742-3.881-83.808.357-1.946.265-3.633-1.383-3.418-3.335 5.585-50.649 5.755-82.575 1.232-131.32-.309-3.337 4.343-4.668 5.855-1.677 25.794 51.054 46.004 82.841 82.912 131.121 1.623 2.123-.119 5.171-2.773 4.854z"
        ></path>
        <mask
          id="mask2_1661_15028"
          style={{ maskType: 'alpha' }}
          width="96"
          height="143"
          x="173"
          y="51"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinejoin="round"
            strokeWidth="4"
            d="M263.08 191.001c-32.67-3.909-52.742-3.881-83.808.357-1.946.265-3.633-1.383-3.418-3.335 5.585-50.649 5.755-82.575 1.232-131.32-.309-3.337 4.343-4.668 5.855-1.677 25.794 51.054 46.004 82.841 82.912 131.121 1.623 2.123-.119 5.171-2.773 4.854z"
          ></path>
        </mask>
        <g
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask2_1661_15028)"
        >
          <path
            d="M85.463 119.707H357.03700000000003V142.652H85.463z"
            transform="rotate(-30 85.463 119.707)"
          ></path>
          <path
            d="M85.463 172.519H357.03700000000003V195.464H85.463z"
            transform="rotate(-30 85.463 172.519)"
          ></path>
          <path
            d="M85.463 223.714H357.03700000000003V246.659H85.463z"
            transform="rotate(-30 85.463 223.714)"
          ></path>
          <path
            d="M85.463 274.909H357.03700000000003V297.854H85.463z"
            transform="rotate(-30 85.463 274.909)"
          ></path>
        </g>
        <path
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M263.08 191.001c-32.67-3.909-52.742-3.881-83.808.357-1.946.265-3.633-1.383-3.418-3.335 5.585-50.649 5.755-82.575 1.232-131.32-.309-3.337 4.343-4.668 5.855-1.677 25.794 51.054 46.004 82.841 82.912 131.121 1.623 2.123-.119 5.171-2.773 4.854z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationClassicYacht;
