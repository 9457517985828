import useAuth from '../../../app/src/features/authentication/hooks/useAuth';
import { AppModules } from '../../../app/src/features/permissions/ability';

const useHasVisitedModule = (module: AppModules): boolean => {
  const {
    ssoData: { data: userData },
  } = useAuth();
  return Boolean(userData?.metadata?.hasVisited?.includes(module));
};

export default useHasVisitedModule;
