import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralCompanyLogoX: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M7.088 7.5l13.899 19.856L7 43.5h3.148l12.245-14.134L32.288 43.5H43L28.319 22.527 41.338 7.5H38.19L26.912 20.517 17.8 7.5H7.088zm4.629 2.477h4.921L38.37 41.022h-4.921L11.717 9.977z"
    ></path>
  </Icon>
);

export default GeneralCompanyLogoX;
