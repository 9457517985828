import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationChild: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_3965_10307"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3965_10307)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <g filter="url(#filter0_dd_3965_10307)">
          <circle cx="221.5" cy="83.5" r="42.5" fill="#fff"></circle>
          <circle
            cx="221.5"
            cy="83.5"
            r="40.5"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M199.872 93.953c0-8.876 8.005-20.9 16.801-25.203h9.166c8.8 4.309 16.802 16.333 16.802 25.209 0 10.847-9.58 16.042-21.391 16.042-11.811 0-21.378-5.202-21.378-16.048zM221.373 68.743s-1.94-2.093-4.004-4.232c-1.29-1.339-2.8-2.868-4.329-4.362a1.53 1.53 0 01-.303-1.77 1.527 1.527 0 011.589-.836 49.297 49.297 0 0014.094 0 1.53 1.53 0 011.282 2.612 180.853 180.853 0 00-4.329 4.362c-2.06 2.133-4 4.226-4 4.226z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M224.738 65.178a16.44 16.44 0 011.935-1.46c2.678-1.24 3.896-.658 4.416.486.52 1.145.167 2.444-2.507 3.666a13.822 13.822 0 01-5.316.87h-1.895s2.785-2.952 3.367-3.562zM221.371 68.744c.608.535 4.904 4.505 4.553 9.198"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M218.006 65.178a16.484 16.484 0 00-1.934-1.46c-2.679-1.24-3.896-.658-4.416.486-.52 1.145-.167 2.444 2.505 3.666 1.699.636 3.505.932 5.317.87h1.895s-2.785-2.952-3.367-3.562zM221.373 68.744c-.609.535-4.903 4.505-4.552 9.198"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M226.688 99.204h-1.826a7.634 7.634 0 01-3.856-1.052 7.724 7.724 0 01-2.812-2.864h4.278c.375-.009.731-.165.992-.436a1.453 1.453 0 000-2.023 1.427 1.427 0 00-.992-.436h-5.234a7.09 7.09 0 010-1.897h7.624a1.42 1.42 0 001.037-.412 1.443 1.443 0 00.433-1.036 1.447 1.447 0 00-1.47-1.448h-6.668a7.72 7.72 0 012.812-2.862 7.632 7.632 0 013.856-1.052h1.826c.374-.009.73-.165.991-.436a1.453 1.453 0 000-2.023 1.426 1.426 0 00-.991-.436h-1.826a10.493 10.493 0 00-5.96 1.87 10.648 10.648 0 00-3.874 4.941h-2.425a1.421 1.421 0 00-1.037.411 1.459 1.459 0 000 2.073 1.439 1.439 0 001.037.411h1.751c-.028.313-.048.628-.048.945 0 .317.02.636.048.95h-1.751a1.421 1.421 0 00-1.037.41 1.459 1.459 0 000 2.073 1.437 1.437 0 001.037.412h2.425a10.649 10.649 0 003.874 4.94 10.503 10.503 0 005.96 1.871h1.826c.374-.01.73-.166.991-.437a1.453 1.453 0 000-2.023 1.426 1.426 0 00-.991-.436v.002z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke={colors.primary[100]}
          strokeWidth="4"
          d="M151.228 101.965c6.485 1.773 10.703 4.22 15.305 8.882 3.967 4.019 5.927 7.333 7.477 12.646.526 1.803.553 2.686.642 21.539.093 19.524.089 19.669-.475 20.98-1.051 2.445-3.134 3.8-5.838 3.8-1.945 0-3.218-.539-4.545-1.923-1.746-1.822-1.692-1.19-1.692-19.528 0-9.149-.073-16.825-.163-17.059-.094-.247-.476-.425-.911-.425-.434 0-.816.178-.911.425-.089.234-.163 7.796-.163 16.805v16.379l-2.012 25.285c-1.106 13.907-2.13 25.994-2.274 26.859-.616 3.696-3.45 6.443-7.411 7.182-2.312.431-9.031.162-10.666-.428-1.746-.629-4.029-2.771-4.886-4.583-.67-1.417-.753-2.239-2.831-28.102l-2.141-26.643v-16.164c0-8.891-.074-16.356-.163-16.59-.229-.595-1.372-.531-1.708.095-.182.342-.277 6.171-.277 17.059 0 18.233.054 17.611-1.692 19.433-1.458 1.52-2.679 1.992-4.863 1.879-1.623-.084-2.091-.228-3.173-.976-.703-.486-1.601-1.442-2.014-2.144l-.744-1.267-.082-18.124c-.053-11.852.019-19.003.21-20.663 1.267-11.011 10.245-20.862 22.158-24.314 3.551-1.028 4.938-1.187 9.398-1.076 3.222.08 4.512.233 6.445.761z"
          clipRule="evenodd"
        ></path>
        <rect
          width="27.11"
          height="34.024"
          x="130.372"
          y="58"
          fill="#fff"
          stroke={colors.primary[100]}
          strokeWidth="4"
          rx="13.555"
        ></rect>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M117.804 144.826c4.209 1.15 6.945 2.738 9.931 5.763 2.575 2.608 3.846 4.758 4.852 8.206.342 1.169.359 1.743.417 13.976.06 12.668.058 12.762-.308 13.612-.683 1.587-2.034 2.466-3.789 2.466-1.262 0-2.088-.349-2.949-1.248-1.133-1.182-1.097-.772-1.097-12.67 0-5.937-.048-10.918-.106-11.069-.062-.161-.309-.276-.591-.276s-.53.115-.591.276c-.059.151-.106 5.058-.106 10.903v10.628l-1.306 16.407c-.718 9.024-1.382 16.866-1.475 17.427-.4 2.399-2.239 4.181-4.809 4.661-1.5.279-5.86.104-6.921-.278-1.133-.409-2.614-1.798-3.17-2.974-.435-.919-.489-1.453-1.837-18.234l-1.39-17.288v-10.488c0-5.769-.047-10.613-.105-10.764-.148-.386-.891-.345-1.108.061-.119.222-.18 4.004-.18 11.069 0 11.831.035 11.427-1.098 12.609-.946.987-1.738 1.293-3.155 1.219-1.053-.054-1.357-.148-2.06-.633-.455-.315-1.038-.935-1.306-1.391l-.483-.822-.053-11.76c-.035-7.69.013-12.33.136-13.407.822-7.145 6.648-13.537 14.378-15.776 2.304-.668 3.204-.771 6.098-.699 2.09.052 2.927.151 4.181.494z"
          clipRule="evenodd"
        ></path>
        <rect
          width="16.186"
          height="20.672"
          x="104.974"
          y="120"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="8.093"
        ></rect>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M180.804 144.826c4.209 1.15 6.945 2.738 9.931 5.763 2.575 2.608 3.846 4.758 4.852 8.206.342 1.169.359 1.743.417 13.976.06 12.668.058 12.762-.308 13.612-.683 1.587-2.034 2.466-3.789 2.466-1.262 0-2.088-.349-2.949-1.248-1.133-1.182-1.097-.772-1.097-12.67 0-5.937-.048-10.918-.106-11.069-.062-.161-.309-.276-.591-.276s-.53.115-.591.276c-.059.151-.106 5.058-.106 10.903v10.628l-1.306 16.407c-.718 9.024-1.382 16.866-1.475 17.427-.4 2.399-2.239 4.181-4.809 4.661-1.5.279-5.86.104-6.921-.278-1.133-.409-2.614-1.798-3.17-2.974-.435-.919-.489-1.453-1.837-18.234l-1.39-17.288v-10.488c0-5.769-.047-10.613-.105-10.764-.148-.386-.891-.345-1.108.061-.119.222-.18 4.004-.18 11.069 0 11.831.035 11.427-1.098 12.609-.946.987-1.738 1.293-3.155 1.219-1.053-.054-1.357-.148-2.059-.633-.456-.315-1.039-.935-1.307-1.391l-.483-.822-.053-11.76c-.035-7.69.013-12.33.136-13.407.822-7.145 6.648-13.537 14.378-15.776 2.304-.668 3.204-.771 6.098-.699 2.09.052 2.927.151 4.181.494z"
          clipRule="evenodd"
        ></path>
        <rect
          width="16.186"
          height="20.672"
          x="167.974"
          y="120"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="8.093"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_3965_10307"
          width="117"
          height="117"
          x="163"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3965_10307"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_3965_10307"
            result="effect2_dropShadow_3965_10307"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_3965_10307"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationChild;
