import React from 'react';
import { Box, Collapse, Card } from '../../../../index';
import SingleDocumentHeader from './SingleDocumentHeader';

interface SingleDocumentProps {
  file?: File;
  filename?: string;
  onRemove: () => void;
  onSave?: () => void;
  onEdit?: () => void;
  onCancel?: () => void;
  isEditing?: boolean;
}

const SingleDocument: React.FC<
  React.PropsWithChildren<SingleDocumentProps>
> = ({
  file,
  filename,
  onRemove,
  onSave = () => null,
  onCancel = () => null,
  onEdit,
  isEditing,
  children,
}) => {
  return (
    <Card>
      {onEdit ? (
        <SingleDocumentHeader
          file={file}
          filename={filename}
          isEditing={isEditing}
          onRemove={onRemove}
          onEdit={onEdit}
          onCancel={onCancel}
          onSave={onSave}
        />
      ) : (
        <SingleDocumentHeader
          file={file}
          filename={filename}
          onRemove={onRemove}
        />
      )}
      <Collapse in={isEditing}>
        <Box mt="4">{children}</Box>
      </Collapse>
    </Card>
  );
};

export default SingleDocument;
