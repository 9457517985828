import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsChevronDownRounded: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.586 17.586a2 2 0 012.828 0L25 29.172l11.586-11.586a2 2 0 112.828 2.828l-13 13a2 2 0 01-2.828 0l-13-13a2 2 0 010-2.828z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsChevronDownRounded;
