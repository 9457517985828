import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationHospitalisationInsurance: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_683_2266"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_683_2266)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M167.03 200.854a13.21 13.21 0 01-13.194-13.195v-6.944a4.02 4.02 0 011.139-2.919 4.02 4.02 0 014.454-.902 4.028 4.028 0 012.46 3.821v6.944a5.144 5.144 0 005.14 5.016 5.144 5.144 0 005.14-5.016V21.392a4.026 4.026 0 014.028-4.029 4.027 4.027 0 014.028 4.029v166.267a13.21 13.21 0 01-13.195 13.195z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M96 119.324c0-8.517 11.74-15.421 26.225-15.421 14.485 0 26.225 6.904 26.225 15.421 0-8.517 12.427-15.421 27.75-15.421s27.75 6.904 27.75 15.421c0-8.517 11.74-15.421 26.225-15.421 14.485 0 26.225 6.904 26.225 15.421v-6.169a80.201 80.201 0 00-136.91-56.71A80.201 80.201 0 0096 113.155v6.169z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M176.197 32.961s-27.75 16.486-27.75 86.363M176.197 32.961s27.75 16.486 27.75 86.363"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M209.046 212.392a3.443 3.443 0 003.905 0c39.499-27.152 47.067-41.095 47.049-59.689a28.291 28.291 0 00-3.323-12.348 28.17 28.17 0 00-8.414-9.608c-18.174-13.634-31.779 5.802-37.263 12.07-3.852-4.387-18.45-26.175-37.263-12.07a28.19 28.19 0 00-8.414 9.608A28.319 28.319 0 00162 152.703c-.029 18.594 7.553 32.537 47.046 59.689z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationHospitalisationInsurance;
