import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyLiabilityBuilding: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M34.12 26.61a3.81 3.81 0 011.37 4.26 4.274 4.274 0 01-.29.65l7.5-1.07h.3V20.17a.51.51 0 00-.21-.41l-.79-.57V16.5a.51.51 0 00-.5-.5h-2a.5.5 0 00-.5.5v.5l-2.71-1.95a.51.51 0 00-.58 0l-6.5 4.67a.51.51 0 00-.21.41v2.72l3.18 2.32 1.94 1.44z"
    ></path>
    <path
      fill="currentColor"
      d="M43.9 32.32h-.3l-.6.09L32 34l-1-.43-2-.86-3.42-1.46a1 1 0 01.42-1.83c.116.001.23.021.34.06l2.71 1 2 .75.26.1a1.739 1.739 0 001.667-.247 1.78 1.78 0 00-.037-2.873L31 26.82c-.62-.46-1.31-.95-2-1.45l-2.71-2a7.58 7.58 0 00-7.1-1l-3.67 1.43c-.345.133-.71.2-1.08.2H9v-1.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V34h5.59c.273.002.543.05.8.14L31 39.82c.322.113.66.174 1 .18a3.06 3.06 0 001-.16L39.25 38 43 36.89l1.49-.44a2.11 2.11 0 00-.59-4.13z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyLiabilityBuilding;
