import React from 'react';
import {
  ButtonGroup,
  VStack,
  Text,
  Flex,
  Icon,
  IconButton,
  Tag,
  Wrap,
} from '@wegroup/design-system';
import { useBoolean } from '@chakra-ui/hooks';

interface Props {
  title: string;
  description: string;
  tags?: string[];
  onClick?: () => void;
  onEdit?: () => void;
  onRemove?: () => void;
}

const SearchSelectDetailedOption: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  description,
  tags,
  onClick,
  onEdit,
  onRemove,
  ...otherProps
}) => {
  const [showActions, setShowActions] = useBoolean(false);

  return (
    <Flex
      {...otherProps}
      pos="relative"
      cursor="pointer"
      borderBottom="1px solid"
      borderColor="gray.strokeAndBg"
      _last={{ borderBottom: 'none' }}
      _hover={{
        bg: 'primary.50',
        p: { color: 'gray.400' },
        h4: { color: 'primary.500' },
      }}
      transition="0.1s"
      onClick={onClick}
      onMouseOver={setShowActions.on}
      onMouseLeave={setShowActions.off}
    >
      <VStack py="4" pl="4" flexGrow={1} spacing="2" alignItems="stretch">
        <Text
          textStyle="normalBody"
          as="h4"
          transition="0.1s"
          color="gray.700"
          dangerouslySetInnerHTML={{ __html: title }}
          sx={{ span: { textStyle: 'normalBodyBold' } }}
          mr="20"
          data-test-id="DESIGN-SYSTEM_CONTENT_SEARCH-SELECT-DETAILED_OPTION_TITLE"
        />
        <Text
          textStyle="smallBody"
          color="gray.500"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <Wrap>
          {tags?.map((tag) => (
            <Tag key={tag} variant="secondary">
              {tag}
            </Tag>
          ))}
        </Wrap>
      </VStack>
      {(onEdit || onRemove) && (
        <ButtonGroup
          variant="white"
          pos="absolute"
          top="0"
          right="0"
          opacity={showActions ? '1' : '0'}
          transition="0.1s"
          spacing="0"
          isAttached
        >
          {onEdit && (
            <IconButton
              borderTopLeftRadius="unset"
              borderTop="none"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
              aria-label="Edit item"
              icon={<Icon name="GeneralActionsEdit" boxSize="6" />}
            />
          )}
          {onRemove && (
            <IconButton
              borderRadius="unset"
              borderLeft="none"
              borderRight="none"
              borderTop="none"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onRemove();
              }}
              aria-label="Remove item"
              icon={<Icon name="GeneralActionsThrash" boxSize="5" />}
            />
          )}
        </ButtonGroup>
      )}
    </Flex>
  );
};

export default SearchSelectDetailedOption;
