import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyMeasurePlus: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M40.36 33H17V22.64A2.6 2.6 0 0014.76 20 2.5 2.5 0 0012 22.5V37a1 1 0 001 1h20v2.36A2.6 2.6 0 0035.24 43 2.5 2.5 0 0038 40.5V38h2.5a2.501 2.501 0 002.5-2.76A2.6 2.6 0 0040.36 33z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17 9.64V13h14.05a3.5 3.5 0 00-.025 4.975l.026.025H9.5A2.501 2.501 0 017 15.24 2.6 2.6 0 019.64 13H12V9.5A2.5 2.5 0 0114.76 7 2.6 2.6 0 0117 9.64zm16 9.324v9.396A2.6 2.6 0 0035.24 31 2.5 2.5 0 0038 28.5v-4.536a3.5 3.5 0 01-3-3.464V19h-1.5c-.168 0-.335-.012-.5-.036z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M43.5 14H40v-3.5a1.5 1.5 0 10-3 0V14h-3.5a1.5 1.5 0 100 3H37v3.5a1.5 1.5 0 103 0V17h3.5a1.5 1.5 0 100-3z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyMeasurePlus;
