import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsClipboard: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.2 12.6a1.6 1.6 0 00-1.6 1.6v25.2a1.6 1.6 0 001.6 1.6h21.6a1.6 1.6 0 001.6-1.6V14.2a1.6 1.6 0 00-1.6-1.6h-3.6a2 2 0 110-4h3.6a5.6 5.6 0 015.6 5.6v25.2a5.6 5.6 0 01-5.6 5.6H14.2a5.6 5.6 0 01-5.6-5.6V14.2a5.6 5.6 0 015.6-5.6h3.6a2 2 0 010 4h-3.6z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19.8 9v3.2h10.4V9H19.8zm-4-.2A3.8 3.8 0 0119.6 5h10.8a3.8 3.8 0 013.8 3.8v3.6a3.8 3.8 0 01-3.8 3.8H19.6a3.8 3.8 0 01-3.8-3.8V8.8z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsClipboard;
