import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycle: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2ZM214 113l-33 68-3.599-1.746 33-68L214 113Z"
          fill={colors.primary[500]}
        />
        <path
          d="M152 182c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110 220c20.987 0 38-17.013 38-38s-17.013-38-38-38-38 17.013-38 38 17.013 38 38 38Zm0 4c23.196 0 42-18.804 42-42s-18.804-42-42-42-42 18.804-42 42 18.804 42 42 42Z"
          fill={colors.primary[500]}
        />
        <path
          d="M145 182c0 19.33-15.67 35-35 35s-35-15.67-35-35 15.67-35 35-35 35 15.67 35 35Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M110 213c17.121 0 31-13.879 31-31 0-17.121-13.879-31-31-31-17.12 0-31 13.879-31 31 0 17.121 13.88 31 31 31Zm0 4c19.33 0 35-15.67 35-35s-15.67-35-35-35-35 15.67-35 35 15.67 35 35 35Z"
          fill={colors.primary[500]}
        />
        <path
          d="M288 182c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M246 220c20.987 0 38-17.013 38-38s-17.013-38-38-38-38 17.013-38 38 17.013 38 38 38Zm0 4c23.196 0 42-18.804 42-42s-18.804-42-42-42-42 18.804-42 42 18.804 42 42 42Z"
          fill={colors.primary[500]}
        />
        <path
          d="M281 182c0 19.33-15.67 35-35 35s-35-15.67-35-35 15.67-35 35-35 35 15.67 35 35Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M246 213c17.121 0 31-13.879 31-31 0-17.121-13.879-31-31-31-17.121 0-31 13.879-31 31 0 17.121 13.879 31 31 31Zm0 4c19.33 0 35-15.67 35-35s-15.67-35-35-35-35 15.67-35 35 15.67 35 35 35ZM118 113a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2c0 12.14-4.491 27.802-10.091 41.268-2.811 6.76-5.931 13.036-8.965 18.117-2.997 5.017-6.026 9.07-8.705 11.185a2 2 0 0 1-2.478-3.14c2.071-1.635 4.792-5.145 7.749-10.096 2.919-4.888 5.955-10.987 8.706-17.602 5.206-12.519 9.289-26.639 9.742-37.732H120a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m135 122.5 43 62.5h73l-43-62.5h-73Zm7.607 4 37.496 54.5h63.29l-37.496-54.5h-63.29Z"
          fill={colors.primary[500]}
        />
        <path
          d="M190 182c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M178 190a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0 4c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12ZM190 113a2 2 0 0 1 2-2h28a2 2 0 1 1 0 4h-28a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationBicycle;
