import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyArt: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M29.5 25a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
    ></path>
    <path
      fill="currentColor"
      d="M37.5 38h-25a.5.5 0 01-.5-.5v-25a.5.5 0 01.5-.5h25a.5.5 0 01.5.5v25a.5.5 0 01-.5.5zM8 8.5v33a.5.5 0 00.5.5h33a.5.5 0 00.5-.5v-33a.5.5 0 00-.5-.5h-33a.5.5 0 00-.5.5z"
    ></path>
    <path
      fill="currentColor"
      d="M27.59 28.43c-1.76-.5-3.05-2-4.63-2.93-2.24-1.32-4.85-1.45-7.45-1.48a.5.5 0 00-.51.5v10a.5.5 0 00.5.5h19a.5.5 0 00.5-.5v-6.14a.5.5 0 00-.5-.5c-2.31.12-4.69 1.18-6.91.55z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyArt;
