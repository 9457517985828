import { Box } from '@wegroup/design-system';
import React from 'react';
import styled from 'styled-components';
import useUserAvatar from '../../../../common/src/hooks/useUserAvatar';
import { mq } from '../../../../common/src/utils/styledComponentsUtils';

interface Props {
  src?: string;
  alt?: string;
  className?: string;
}

const Avatar: React.FC<React.PropsWithChildren<Props>> = ({
  src,
  alt,
  className,
  ...rest
}) => {
  const avatarSrc = useUserAvatar();

  if (!avatarSrc)
    return <Box borderRadius="full" w="4.5rem" h="4.5rem" bg="white" />;

  return (
    <AvatarWrapper
      src={avatarSrc}
      alt="Assistant's avatar"
      className={className}
      {...rest}
    />
  );
};

const AvatarWrapper = styled.img`
  border-radius: 100%;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  width: 4.5rem;
  height: 4.5rem;
  background-color: #fff;
  object-fit: contain;

  ${mq.laptop} {
    width: 9.5rem;
    height: 9.5rem;
  }
`;

export default Avatar;
