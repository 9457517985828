export const LOUISE_CHAT_EXAMPLES = [
  {
    insuranceCompany: 'axa',
    subject: '235fefad-179f-4720-b8b9-43cfe442624c',
    question: 'TOOLKIT_INSURANCE-TERMS-BOT_INTRO_EXAMPLE_AXA',
  },
  {
    insuranceCompany: 'pnp',
    subject: '2f935cd4-c414-4dae-8c40-6ac5bae9a42e',
    question: 'TOOLKIT_INSURANCE-TERMS-BOT_INTRO_EXAMPLE_PNP',
  },
  {
    insuranceCompany: 'baloise',
    subject: '54cc4d5c-71ae-425a-9414-5cc5b765f6b1',
    question: 'TOOLKIT_INSURANCE-TERMS-BOT_INTRO_EXAMPLE_BALOISE',
  },
];
