import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationFamily: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M31 33h7a5 5 0 015 5v6H26v-6a5 5 0 015-5zM34.5 31a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM28.18 30.52A8 8 0 0023 38v6H7V33a8 8 0 018-8h10a8.14 8.14 0 012.11.28A6.15 6.15 0 0027 26.5a7.38 7.38 0 001.18 4.02zM20 23a7 7 0 100-14 7 7 0 000 14z"
    ></path>
  </Icon>
);

export default GeneralNavigationFamily;
