import { Address } from '../../../../../../../types/Common';

export const isAddressObjectComplete = (address?: Address): boolean => {
  if (address) {
    return (
      address.street != null &&
      address.street !== '' &&
      address.zipcode != null &&
      address.zipcode !== '' &&
      address.city != null &&
      address.city !== '' &&
      address.housenr != null &&
      address.housenr !== ''
    );
  }

  return false;
};
