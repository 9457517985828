import React from 'react';
import { Card, VStack } from '@wegroup/design-system';
import { Offer } from '../../../../../../types/Offer';
import OfferCardHeader from './OfferCardHeader';
import { capitalize } from 'lodash';
import OfferCardBody from './OfferCardBody';
import OfferCardFooter from './OfferCardFooter';

interface Props {
  offer: Offer;
  label?: string;
  logo?: string;
  isSelectable?: boolean;
  onSelect?: (offer: Offer) => void;
  isLoading?: boolean;
}

const OfferCard: React.FC<Props> = ({
  offer,
  logo,
  label,
  isSelectable,
  isLoading,
  onSelect = () => null,
}) => {
  return (
    <Card as={VStack} align="stretch" spacing="3">
      <OfferCardHeader
        name={label || capitalize(offer.insurance?.company)}
        logo={logo || ''}
      />
      <OfferCardBody offer={offer} />
      {isSelectable && (
        <OfferCardFooter
          isLoading={isLoading}
          onSelect={() => onSelect(offer)}
        />
      )}
    </Card>
  );
};

export default OfferCard;
