import React, { ChangeEvent, ReactElement, useRef } from 'react';
import {
  Box,
  useDisclosure,
  BoxProps,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Icon,
  Portal,
} from '@wegroup/design-system';
import SearchInput from '../SearchPickerInputField';
import SearchPickerTagList from './SearchPickerTagList';

export interface Option {
  label: string;
  value: string;
}

export interface SearchPickerProps {
  placeholder?: string;
  options?: Option[];
  searchValue: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  selectedOptions: Option[];
  setSelectedOptions: (options: Option[]) => void;
  onAdd: (tag: string) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  leftIcon?: ReactElement;
}

/**
 * When passing options you will see a list of the existing tags
 * When you don't pass options the list will be hidden en the popover will only show when typing
 */
const SearchPickerTagInput: React.FC<
  React.PropsWithChildren<SearchPickerProps & BoxProps>
> = ({
  placeholder,
  options,
  searchValue,
  onChange,
  selectedOptions,
  setSelectedOptions,
  isDisabled,
  onAdd,
  isLoading,
  leftIcon = <Icon name="GeneralOthersTag" />,
  ...props
}) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const inputRef = useRef<HTMLInputElement>(null);
  const tagExists = (options || [])
    .map(({ value }) => value.toLowerCase())
    .includes(searchValue.toLowerCase());

  const handleClick = () => {
    if (!isDisabled) onOpen();
  };

  const cleanupInput = () => {
    // Cleanup (We dispatch our own event)
    if (inputRef.current) inputRef.current.value = '';
    inputRef.current?.dispatchEvent(new Event('change'));
  };

  const handleAdd = () => {
    if (!tagExists && searchValue) {
      // Add the new tag
      onAdd(searchValue);
      setSelectedOptions([
        ...selectedOptions,
        { label: searchValue, value: searchValue },
      ]);

      // Close the popover
      onClose();

      cleanupInput();
      onOpen();
    }
  };

  return (
    <Box position="relative">
      <Popover
        isOpen={!isLoading && isOpen}
        onClose={onClose}
        closeOnBlur={false}
        isLazy
        autoFocus={false}
      >
        <PopoverTrigger>
          <Box
            ref={boxRef}
            tabIndex={1}
            onClick={handleClick}
            _focusVisible={{ outline: 'none' }}
          >
            <SearchInput
              ref={inputRef}
              value={searchValue}
              selectedOptions={selectedOptions}
              onChangeSelected={(option) =>
                setSelectedOptions(
                  selectedOptions.filter((o) => o.value !== option.value),
                )
              }
              onPressEnter={handleAdd}
              onChange={onChange}
              onBlur={cleanupInput}
              onClosePopover={onClose}
              boxProps={props}
              placeholder={placeholder}
              isDisabled={isDisabled}
              icon={leftIcon}
              maxLength={36}
              isLoading={isLoading}
            />
          </Box>
        </PopoverTrigger>
        <Portal appendToParentPortal={false}>
          <Box
            sx={{
              '.chakra-popover__popper': {
                zIndex: 'popover',
              },
            }}
          >
            <PopoverContent w={boxRef.current?.clientWidth}>
              <Box
                w="100%"
                border="1px solid"
                borderRadius="5px"
                borderColor="gray.strokeAndBg"
              >
                <SearchPickerTagList
                  inputRef={inputRef}
                  options={options}
                  selectedOptions={selectedOptions}
                  onChangeSelectedOptions={setSelectedOptions}
                  onClosePopover={() => {
                    onClose();
                  }}
                  onClickAdd={handleAdd}
                  searchValue={searchValue}
                  tagExists={tagExists}
                />
              </Box>
            </PopoverContent>
          </Box>
        </Portal>
      </Popover>
    </Box>
  );
};

export default SearchPickerTagInput;
