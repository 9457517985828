import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleJoker: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M28.68 44a.499.499 0 00.5-.55c-.31-3-2.44-17.78-14.77-20.63a18.421 18.421 0 00-5.47-.37 3 3 0 10-.45 2.22C15 30.2 15.09 41 15 43.49a.5.5 0 00.5.51h13.18zM44.47 20a3 3 0 00-3.42 2.48c-1.363.043-2.72.217-4.05.52-3 .7-6.42 2.45-9.87 6.66a.53.53 0 000 .62c2.55 3.49 4.11 8.44 4.82 13.3a.5.5 0 00.49.42h3.15a.5.5 0 00.49-.44c.91-7 2.67-13.35 6.55-17.92.422.225.892.348 1.37.36a3.01 3.01 0 00.47-6zM33.89 38.26a31.927 31.927 0 00-3.26-8 14.19 14.19 0 017-4.29c.3-.07.67-.14 1.07-.2a34.9 34.9 0 00-4.81 12.49zM32 8a3 3 0 00-6 0 3 3 0 00.67 1.86 27.534 27.534 0 00-3.29 4.51A28.58 28.58 0 0020 22.72a.77.77 0 00.29.81 22.221 22.221 0 015.06 4.3.51.51 0 00.36.17.482.482 0 00.36-.18 20.289 20.289 0 014.62-4.26.78.78 0 00.22-.94A23.71 23.71 0 0129.23 11 3 3 0 0032 8zm-6.35 15.83a24.784 24.784 0 00-2.33-1.9 25.78 25.78 0 012.63-6l.23-.37a26.197 26.197 0 001.36 6.58c-.66.53-1.29 1.094-1.89 1.69z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleJoker;
