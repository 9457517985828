import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationGoldware: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="m231.239 192.554 36.846.072a4.216 4.216 0 0 1 3.751 2.335l12.58 25.25a4.197 4.197 0 0 1-3.774 6.072l-62.058-.126a4.194 4.194 0 0 1-3.746-6.085l12.663-25.198a4.196 4.196 0 0 1 3.738-2.32Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="m160.753 192.554 36.858.072a4.216 4.216 0 0 1 3.751 2.335l12.565 25.25a4.196 4.196 0 0 1-1.722 5.55 4.191 4.191 0 0 1-2.051.522l-62.058-.126a4.194 4.194 0 0 1-3.746-6.085l12.663-25.198a4.192 4.192 0 0 1 3.74-2.32ZM88.852 192.554l36.846.072a4.21 4.21 0 0 1 3.752 2.335l12.579 25.25a4.201 4.201 0 0 1-.188 4.094 4.193 4.193 0 0 1-3.589 1.978l-62.058-.126a4.2 4.2 0 0 1-2.046-.532 4.201 4.201 0 0 1-2.144-3.487 4.19 4.19 0 0 1 .447-2.066l12.66-25.198a4.19 4.19 0 0 1 3.741-2.32ZM200.727 158.372l36.857.073a4.188 4.188 0 0 1 3.752 2.334l12.565 25.248a4.193 4.193 0 0 1-3.774 6.072l-62.058-.123a4.19 4.19 0 0 1-3.574-1.997 4.196 4.196 0 0 1-.169-4.092l12.661-25.198a4.193 4.193 0 0 1 3.74-2.317Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="m130.24 158.372 36.846.073a4.188 4.188 0 0 1 3.752 2.334l12.565 25.248a4.193 4.193 0 0 1-3.774 6.072l-62.058-.123a4.19 4.19 0 0 1-3.574-1.997 4.186 4.186 0 0 1-.169-4.092l12.661-25.198a4.193 4.193 0 0 1 3.751-2.317ZM164.728 124.643l36.846.072a4.206 4.206 0 0 1 2.209.633 4.205 4.205 0 0 1 1.543 1.701l12.565 25.249a4.196 4.196 0 0 1-.185 4.094 4.204 4.204 0 0 1-3.589 1.98l-62.058-.126a4.194 4.194 0 0 1-3.745-6.088l12.66-25.198a4.194 4.194 0 0 1 3.754-2.317Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M252.347 120.989a7.832 7.832 0 0 1 5.87-5.87l11.78-2.717a.981.981 0 0 0 0-1.909l-11.78-2.717a7.832 7.832 0 0 1-5.87-5.871l-2.72-11.774a.98.98 0 0 0-1.562-.545.974.974 0 0 0-.344.545l-2.717 11.774a7.83 7.83 0 0 1-5.871 5.871l-11.774 2.714a.981.981 0 0 0 0 1.909l11.774 2.717a7.83 7.83 0 0 1 5.871 5.871l2.717 11.779a.975.975 0 0 0 .953.758.98.98 0 0 0 .953-.758l2.72-11.777ZM101.448 135.147a5.867 5.867 0 0 1 4.4-4.405l8.828-2.038a.733.733 0 0 0 0-1.428l-8.828-2.038a5.873 5.873 0 0 1-4.4-4.395l-2.038-8.83a.736.736 0 0 0-.714-.571.736.736 0 0 0-.714.571l-2.038 8.822a5.87 5.87 0 0 1-4.4 4.4l-8.825 2.038a.732.732 0 0 0 0 1.428l8.825 2.038a5.867 5.867 0 0 1 4.4 4.4l2.038 8.831a.734.734 0 0 0 1.428 0l2.038-8.823ZM176.321 78.614a7.822 7.822 0 0 1 5.87-5.87l11.785-2.718a.98.98 0 0 0 0-1.91L182.197 65.4a7.826 7.826 0 0 1-5.871-5.87l-2.717-11.772a.98.98 0 0 0-1.906 0l-2.718 11.771a7.822 7.822 0 0 1-5.87 5.87l-11.774 2.718a.98.98 0 0 0 0 1.91l11.774 2.716a7.827 7.827 0 0 1 5.87 5.87l2.72 11.78a.981.981 0 0 0 1.563.546.98.98 0 0 0 .344-.546l2.709-11.78Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationGoldware;
