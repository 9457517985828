import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsCancellation: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="4"
      d="M25 43c9.941 0 18-8.059 18-18S34.941 7 25 7 7 15.059 7 25s8.059 18 18 18zM12.4 12.4l25.2 25.2"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsCancellation;
