import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersChatCompleted: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9 23h4a2 2 0 100-4H9a4 4 0 00-4 4v20a2 2 0 003.414 1.414L13.828 39H27a4 4 0 004-4v-4a2 2 0 10-4 0v4H13a2 2 0 00-1.414.586L9 38.172V23zM40.762 7.24c.626-.65 1.65-.65 2.276 0 .616.64.616 1.67 0 2.31l-13.2 13.714c-.626.65-1.65.65-2.276 0l-6.6-6.857a1.675 1.675 0 010-2.31c.626-.65 1.65-.65 2.276 0l5.462 5.675L40.762 7.24z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersChatCompleted;
