import React, { ReactElement } from 'react';
import {
  IconButton,
  StackDivider,
  Center,
  VStack,
  Icon,
  HStack,
  Text,
  Square,
  Input,
  InputProps,
} from '@wegroup/design-system';

interface Props {
  label: string;
  icon: ReactElement;
  onIncrement?: () => void;
  onDecrement?: () => void;
}

const AmountIconInput: React.FC<InputProps & Props> = ({
  icon,
  label,
  onIncrement,
  onDecrement,
  ...props
}) => {
  const { min, max, value } = props;
  return (
    <VStack
      align="stretch"
      bg="white"
      border="1px"
      borderColor="gray.50"
      borderRadius="md"
    >
      <VStack spacing="3" p="4">
        <Square
          bg={value ? 'primary.50' : 'gray.50'}
          p="1"
          borderRadius="sm"
          color={value ? 'primary.500' : 'gray.300'}
          transition="0.2s"
        >
          {icon}
        </Square>
        <Text textStyle="normalBodyMedium">{label}</Text>
      </VStack>
      <HStack
        borderTop="1px"
        borderColor="gray.50"
        spacing="0"
        divider={<StackDivider w="1px" bg="gray.50" />}
      >
        <Center>
          <IconButton
            variant="white"
            w="10"
            h="10"
            borderRadius="0"
            border="0px"
            size="sm"
            aria-label="decrement"
            isDisabled={min != null ? min >= Number(value) : undefined}
            borderBottomLeftRadius="md"
            onClick={onDecrement}
            icon={<Icon name="GeneralActionsMinusOrRemove" />}
          />
        </Center>
        <Input
          textAlign="center"
          borderRadius="0"
          {...props}
          type="number"
          border="none"
        />
        <Center>
          <IconButton
            onClick={onIncrement}
            isDisabled={max != null ? Number(value) >= max : undefined}
            variant="white"
            w="10"
            h="10"
            borderRadius="0"
            borderBottomRightRadius="md"
            border="0px"
            size="sm"
            aria-label="increment"
            icon={<Icon name="GeneralActionsAdd" />}
          />
        </Center>
      </HStack>
    </VStack>
  );
};

export default AmountIconInput;
