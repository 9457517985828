import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycleElectricCargo: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3628_10526"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3628_10526)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <circle
          r="26.639"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 69.64 198.699)"
        ></circle>
        <circle
          r="16.662"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 69.615 198.675)"
        ></circle>
        <circle
          r="33.482"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 280.518 191.857)"
        ></circle>
        <circle
          r="21.121"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 280.487 191.827)"
        ></circle>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M92.009 147.666L80.189 188.1c-2.568 6.429-5.061 8.318-10.574 9.953"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M87.033 164.461l31.709 39.637a1.999 1.999 0 001.658.748l76.737-3.683 82.734-8.709"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M90.765 155.752l32.332 35.993a2 2 0 001.589.661l73.073-3.684M197.759 201.163l1.862-85.011c.003-.14.021-.283.054-.42 1.998-8.237 5.346-10.984 15.502-12.232M200.87 145.178l55.363-4.355M230.729 196.808l31.725-69.048M279.871 192.454l-24.882-50.387M245.036 125.271h29.236"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M192.783 152.304v28.686a2.001 2.001 0 01-1.9 1.998l-63.838 3.196a1.997 1.997 0 01-1.614-.691l-22.115-25.645c-1.074-1.245-.27-3.182 1.37-3.301l85.952-6.238a2 2 0 012.145 1.995z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M107.953 65.585a1.493 1.493 0 011.999.677l3.899 7.884 3.241-3.29a1.49 1.49 0 012.384.353l4.81 9.157a1.491 1.491 0 11-2.642 1.388l-3.86-7.348-3.279 3.328a1.49 1.49 0 01-2.4-.386l-4.829-9.764a1.493 1.493 0 01.677-1.999zM142.87 50.083c.778.27 1.191 1.12.921 1.898l-2.881 8.31h4.619a1.493 1.493 0 011.421 1.947l-3.148 9.854a1.491 1.491 0 11-2.843-.908l2.526-7.908h-4.671a1.492 1.492 0 01-1.41-1.98l3.567-10.292a1.493 1.493 0 011.899-.921zM195.035 87.412a1.492 1.492 0 01-1.762 1.162l-8.616-1.77.604 4.58a1.49 1.49 0 01-1.743 1.663l-10.181-1.832a1.492 1.492 0 11.529-2.937l8.169 1.47-.611-4.631a1.494 1.494 0 011.78-1.657l10.67 2.19a1.492 1.492 0 011.161 1.762zM182.455 115.269a1.492 1.492 0 01-2.094.258l-6.935-5.411-1.492 4.371a1.49 1.49 0 01-2.301.716l-8.308-6.162a1.492 1.492 0 111.778-2.397l6.667 4.945 1.509-4.421a1.494 1.494 0 012.33-.695l8.588 6.701c.649.507.765 1.445.258 2.095zM134.764 100.187c.51.648.397 1.586-.251 2.095l-10.446 8.208a1.407 1.407 0 01-.149.103c-2.48 1.499-5.325 4.309-6.136 7.808-.772 3.326.192 7.769 6.321 12.901a1.492 1.492 0 11-1.915 2.288c-6.704-5.613-8.414-11.118-7.314-15.863 1.051-4.529 4.566-7.891 7.42-9.639l10.375-8.152a1.492 1.492 0 012.095.251z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M156.818 76.743c.485.667.337 1.6-.33 2.084l-9.49 6.895a1.492 1.492 0 11-1.754-2.414l9.49-6.895a1.492 1.492 0 012.084.33zM164.279 86.442c.485.667.337 1.6-.33 2.085l-9.49 6.894a1.492 1.492 0 11-1.754-2.414l9.49-6.895a1.492 1.492 0 012.084.33z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M149.108 105.542c-.001.001-.002.002-.001.003v.003a12.31 12.31 0 01-17.194-2.725 12.31 12.31 0 012.719-17.192l.001-.004v-.003l6.505-4.726a1.493 1.493 0 012.085.33l12.718 17.505c.484.667.336 1.6-.331 2.084l-6.502 4.725z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M140.261 79.69a2.985 2.985 0 014.169.661l12.718 17.505a2.985 2.985 0 01-.66 4.169l-6.411 4.657c-.03.026-.061.05-.093.074a13.806 13.806 0 01-21.745-13.328 13.804 13.804 0 015.418-8.933c.032-.027.065-.053.099-.078l6.505-4.726zm-4.659 7.075a1.34 1.34 0 01-.092.073 10.818 10.818 0 0012.633 17.565 1.57 1.57 0 01.088-.068l6.502-4.725-12.718-17.505-6.413 4.66z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBicycleElectricCargo;
