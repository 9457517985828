import { yupResolver } from '@hookform/resolvers/yup';
import { ModuleTopBar, SlideFade } from '@wegroup/design-system';
import { isEqual } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isLouiseQAUrl } from '../../../../../app/src/features/authentication/constants/SocialAuth';
import useAuth from '../../../../../app/src/features/authentication/hooks/useAuth';
import useIsLouiseQAPlan from '../../../../../app/src/hooks/useIsLouiseQAPlan';
import useIsNLplan from '../../../../../common/src/hooks/useIsNLPlan';
import { usePostInsuranceTermsBotChat } from '../api/postInsuranceTermsBotChat';
import LouiseGPTAcknowledgementPage from '../components/acknowledgement/LouiseGPTAcknowledgementPage';
import LouiseChat from '../components/chat/LouiseChat';
import LouiseChatIntro from '../components/intro/LouiseChatIntro';
import LouiseQAPageSection from '../components/LouiseQAPageSection';
import QuestionBody from '../components/question-body/QuestionBody';
import LouiseQAQuestionHeader from '../components/QuestionHeader';
import { LOUISE_CHAT_EXAMPLES } from '../constants/examples';
import { LOUISE_CHAT_EXAMPLES_NL } from '../constants/examples_nl';
import { LouiseQuestionFormValues, louiseQuestionSchema } from '../types/Form';

const InsuranceTermsBot: React.FC = () => {
  const { ssoData } = useAuth();
  const { t } = useTranslation();
  const { isNL } = useIsNLplan();
  const isLouiseQAPlan = useIsLouiseQAPlan();
  const chatExamples = isNL ? LOUISE_CHAT_EXAMPLES_NL : LOUISE_CHAT_EXAMPLES;
  const translatedChatExamples = chatExamples.map((example) => ({
    ...example,
    question: t(example.question),
  }));

  const methods = useForm<LouiseQuestionFormValues>({
    mode: 'onChange',
    resolver: yupResolver(louiseQuestionSchema),
  });
  const { mutateAsync, data, isLoading, isError, isSuccess, isIdle, reset } =
    usePostInsuranceTermsBotChat();

  const handleSubmit = methods.handleSubmit(async (values) => {
    await mutateAsync({
      message: values.question,
      insuranceCompany: values.insuranceCompany,
      insuranceSubject: values.subject,
    });
  });

  const handleReset = () => {
    const isExample = translatedChatExamples.some((example) =>
      isEqual(example, methods.getValues()),
    );
    // If there is an error or it is an example, we fully reset the form because there might be issues with the insurance company/subject
    if (isError || isExample) {
      methods.reset({
        question: '',
        insuranceCompany: '',
        subject: '',
      });
    } else {
      /** When the form is not fully reset, we don't want the defaultValues to be overwritten */
      methods.reset(
        {
          ...methods.getValues(),
          question: '',
        },
        { keepDefaultValues: true },
      );
    }

    reset();
  };

  const handleExampleClick = (values: LouiseQuestionFormValues) => {
    /** When the form is not fully reset, we don't want the defaultValues to be overwritten  */
    methods.reset(values, { keepDefaultValues: true });
    handleSubmit();
  };

  /**
   * We need to call setFocus in useEffect because the references are reset when the form is reset.
   * References are added again after first render.
   */
  useEffect(() => {
    if (isIdle) methods.setFocus('question');
  }, [isIdle]);

  if (
    !isLouiseQAUrl &&
    !ssoData.data?.metadata?.insurance_terms_bot_acknowledgement
  )
    return <LouiseGPTAcknowledgementPage />;

  return (
    <>
      {!(isNL || isLouiseQAPlan || isLouiseQAUrl) && (
        <ModuleTopBar backRef={'/toolkit'} />
      )}
      <FormProvider {...methods}>
        <LouiseQAPageSection>
          <LouiseQAQuestionHeader
            title={t('TOOLKIT_INSURANCE-TERMS-BOT_HEADER_TITLE')}
            description={t('TOOLKIT_INSURANCE-TERMS-BOT_HEADER_DESCRIPTION')}
          />
          <QuestionBody onSubmit={handleSubmit} />
        </LouiseQAPageSection>
        <LouiseQAPageSection>
          <LouiseChat
            conversationId={data?.id}
            isIdle={isIdle}
            isError={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            message={data?.message}
            resources={data?.resources}
            onReset={handleReset}
          />
        </LouiseQAPageSection>
        <SlideFade in={!methods.formState.isDirty} unmountOnExit>
          <LouiseQAPageSection>
            <LouiseChatIntro
              onExampleClick={handleExampleClick}
              examples={chatExamples}
            />
          </LouiseQAPageSection>
        </SlideFade>
      </FormProvider>
    </>
  );
};

export default InsuranceTermsBot;
