import React from 'react';
import { Center } from '@wegroup/design-system';
import LoadingSpinner from '../../../common/src/components/LoadingSpinner';

const LoadingScreen: React.FC = () => {
  return (
    <Center h="100%" w="100%" p="20">
      <LoadingSpinner />
    </Center>
  );
};

export default LoadingScreen;
