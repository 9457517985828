import { Icon } from '@chakra-ui/react';
import React from 'react';

const GeneralOthersChat: React.FC = ({ ...props }) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M23 9v12h14a2 2 0 011.414.586L41 24.172V9H23zm-2.828-2.828A4 4 0 0123 5h18a4 4 0 014 4v20a2 2 0 01-3.414 1.414L36.172 25H23a4 4 0 01-4-4V9a4 4 0 011.172-2.828z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9 23h4a2 2 0 100-4H9a4 4 0 00-4 4v20a2 2 0 003.414 1.414L13.828 39H27a4 4 0 004-4v-4a2 2 0 10-4 0v4H13a2 2 0 00-1.414.586L9 38.172V23z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersChat;
