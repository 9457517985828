import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationFire: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_4209_10282"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0-.001h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4209_10282)">
        <path
          fill={colors.primary[50]}
          d="M337 160.999c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.635 160 160z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M143.361 81.353a2.718 2.718 0 014.14 2.353 36.843 36.843 0 007.1 23.19 2.7 2.7 0 003.585.656c4.396-2.745 14.578-11.363 19.664-34.256a91.54 91.54 0 001.303-35.129 2.706 2.706 0 014.04-2.79 111.466 111.466 0 0154.473 94.731c.775 33.957-26.047 63.001-60.011 62.892A59.847 59.847 0 01118 133.155c.005-24.629 11.142-42.567 25.361-51.802z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M196.668 122.086a2.727 2.727 0 00-2.703.336 2.726 2.726 0 00-1.057 2.51 34.04 34.04 0 01.348 6.202c-.47 13.56-8.933 21.846-18.426 21.517a14.914 14.914 0 01-13.354-8.912 2.72 2.72 0 00-5.169.659 29.93 29.93 0 0014.844 31.629 29.928 29.928 0 0024.058 2.243 29.915 29.915 0 0017.872-16.261 29.946 29.946 0 002.563-12.078 29.93 29.93 0 00-5.196-16.848 29.931 29.931 0 00-13.78-10.997z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationFire;
