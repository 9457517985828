import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsOpenDrawer: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 10a6 6 0 016-6h30a6 6 0 016 6v30a6 6 0 01-6 6H10a6 6 0 01-6-6V10zm6-2a2 2 0 00-2 2v30a2 2 0 002 2h30a2 2 0 002-2V10a2 2 0 00-2-2H10z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.925 18.728c.63.63.63 1.653 0 2.284l-2.373 2.373h7.833a1.615 1.615 0 010 3.23h-7.833l2.373 2.373a1.615 1.615 0 11-2.284 2.284l-5.13-5.13a1.615 1.615 0 010-2.284l5.13-5.13c.63-.63 1.653-.63 2.284 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M28.264 5.593H32.263999999999996V44.408H28.264z"
    ></path>
  </Icon>
);

export default GeneralActionsOpenDrawer;
