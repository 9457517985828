import { Text } from '@wegroup/design-system';
import React from 'react';

const RedAsterix: React.FC = () => {
  return (
    <Text as="span" color="danger.main">
      *
    </Text>
  );
};

export default RedAsterix;
