import { IConfigCatClient } from 'configcat-common';
import * as configcat from 'configcat-js';
import { CONFIGCAT_KEY } from '../../../../env.config';
import { isStorybookUrl } from '../features/authentication/constants/SocialAuth';
import { getJwtToken } from '../features/authentication/utils/jwt';

let configCatClient: IConfigCatClient;
if (!isStorybookUrl) configCatClient = configcat.createClient(CONFIGCAT_KEY!);

/**
 * This function is used to get the feature flag before the app is initialized.
 */
export const getFeatureFlagBeforeInit = async (
  featureFlagName: string,
): Promise<boolean> => {
  if (configCatClient) {
    const jwtData = getJwtToken();
    const payload = {
      identifier: jwtData?.ext.bid || jwtData?.ext.did || '',
      country: jwtData?.ext.country,
      email: jwtData?.ext.email,
      custom: {
        plan: jwtData?.ext.broker_plan || '',
        admin_distro_id: jwtData?.ext.did || '',
        origin: window.location.origin,
      },
    };

    const featureFlag = await configCatClient.getValueAsync(
      featureFlagName,
      false,
      payload,
    );

    return featureFlag;
  }
  return false;
};
