import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSilverware: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          opacity={0.33}
          d="M119.062 136.559a62.94 62.94 0 0 0 62.937 62.937 62.939 62.939 0 0 0 62.937-62.937 62.939 62.939 0 0 0-125.874 0Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          opacity={0.33}
          d="M148.875 136.559a33.128 33.128 0 0 0 33.126 33.126 33.126 33.126 0 1 0-33.126-33.126Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M278.733 192.545a8.997 8.997 0 0 1-8.955 9.829 8.991 8.991 0 0 1-8.954-9.829l5.305-53.067a3.66 3.66 0 0 1 3.643-3.285 3.665 3.665 0 0 1 3.643 3.285l5.318 53.067Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M269.784 136.182a9.942 9.942 0 0 1-7.028-2.91 9.942 9.942 0 0 1-2.91-7.028v-43.06a19.883 19.883 0 0 1 15.902-19.466 3.313 3.313 0 0 1 3.971 3.25v59.276a9.924 9.924 0 0 1-.756 3.803 9.927 9.927 0 0 1-9.179 6.135Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.627 199.171a8.986 8.986 0 0 1-2.306 6.893A9 9 0 0 1 92.673 209a9 9 0 0 1-8.528-6.139 8.997 8.997 0 0 1-.426-3.69l5.318-79.566a3.662 3.662 0 0 1 7.285 0l5.305 79.566Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M109.126 66.62v36.437c0 7.321-5.191 13.252-11.594 13.252h-9.938C81.2 116.309 76 110.378 76 103.057V66.619a3.314 3.314 0 0 1 6.626 0v29.814a3.312 3.312 0 0 0 6.624 0V66.619a3.315 3.315 0 0 1 6.626 0v29.814a3.312 3.312 0 0 0 6.623 0V66.619a3.317 3.317 0 0 1 3.314-3.221 3.313 3.313 0 0 1 3.313 3.221Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationSilverware;
