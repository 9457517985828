// Risk analysis
export const flowIdRiskAnalysisNL = '4fccc81c-c38c-4a1f-abb3-c141edc77a2f';
export const flowIdRiskAnalysisBE = '8b651328-5304-4980-bb23-177d6694a34b';
export const flowIdRiskAnalysisShortBE = '61c125a5-6475-4f6f-a901-f2ae336b1dca';

// Sales conversation
export const flowIdSalesConversation = '97f9d89e-2c28-49e8-a6f2-0f25e89b42c8';
export const flowIdDeMotoVerzekering = '74fee5e3-385d-463e-9f64-35b3892438f8';
export const flowIdVillasureOfferFlow = '2e65ee48-d1b2-4ed6-a6f4-dddf74cb0b33';
export const flowIdVillasureContractFlow =
  'aea262bc-d47c-46db-9057-58662f9636e0';
export const flowIdPiaBoatOfferFlow = '752df63f-2399-4a19-989e-0a2b478b68ab';
export const flowIdPiaBoatContractFlow = '8d5bf1d3-1b8d-4026-aaec-317a841dbd61';
export const flowIdBikmoOfferFlow = 'df934285-3fcb-4701-8c6f-bdf794a1806b';
export const flowIdBikmoContractFlow = '3a956a89-8570-4974-ab87-bbb4292f1674';
export const flowIdCyclingVlaanderenOfferFlowId =
  'ae2f00d5-8bc0-429e-a7bf-d6d7827fd2d4';
export const flowIdCyclingVlaanderenContractFlowId =
  '11b80d10-cfd1-439d-b8d3-a6108a3b8019';

// Company scan
export const flowIdCompanyScanBE = '83882ca1-af11-4776-a92a-793ebf149d42';
export const flowIdCompanyScanNL = 'f2487edd-d796-4f8f-9a70-c61c96b8e01d';
