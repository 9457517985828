import { CSSObject } from '@mantine/core';
import { RichTextEditorStylesNames } from '@mantine/rte';
import useTheme from './useTheme';

/**
 * This component will show a text input styling when the collapsed value is false
 * It will show the expanded richtTexteditor when the value is true
 * @param isCollapsed
 */
const useRichTextAnimatedFocusStyling = (
  isCollapsed: boolean,
): Partial<Record<RichTextEditorStylesNames, CSSObject>> => {
  const { space } = useTheme();
  return {
    root: {
      '.ql-editor': {
        minHeight: 'unset',
        height: isCollapsed ? space[28] : `calc(${space[10]} + 0px)`,
        padding: isCollapsed ? undefined : space[2],
        paddingBottom: isCollapsed ? undefined : 0,
        transition: '0.2s',
      },
      '.ql-blank::before': !isCollapsed
        ? {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }
        : {},
      p: {
        marginBottom: isCollapsed ? undefined : 0,
        transition: '0.2s',
      },
    },
    toolbar: {
      ...(!isCollapsed && {
        height: 0,
        paddingTop: 0,
        paddingBottom: 0,
        borderWidth: '0',
      }),
      overflow: 'hidden',
      transition: '0.2s',
    },
  };
};

export default useRichTextAnimatedFocusStyling;
