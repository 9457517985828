import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyCollisionThirdParty: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M7.578 16.786l7.442-2.901.582 1.503c.016.04 1.705 4.097 4.356 4.097.994 0 2.109-.541 3.32-1.6l1.164-1.026 4.429 4.654-1.584 1.067c-.024.016-2.61 1.81-4.259 5.22.243.04.493.057.736.057a4.958 4.958 0 004.929-4.477 4.962 4.962 0 003.232-4.647c0-2.15-1.35-4-3.337-4.678a4.582 4.582 0 00-.057-1.204 4.925 4.925 0 00-5.705-4.065A4.944 4.944 0 0018.794 6.7a4.962 4.962 0 00-4.485 2.836 3.118 3.118 0 00-.412-.072 4.944 4.944 0 00-5.511 4.323c-.04.307-.049.582-.04.857A4.974 4.974 0 005 19.33a4.96 4.96 0 002.554 4.34 4.963 4.963 0 006.368 5.31 9.337 9.337 0 00-.106-.688c-1.42-7.68-4.805-10.125-6.238-11.507zM20.75 39.024a2.405 2.405 0 01-.42-.38c-.14.024-.489.006-.833-.105-.63-.185-1.148-.687-1.382-1.325l-.582.073a2.189 2.189 0 01-1.737-.501 44.41 44.41 0 01-.832 5.705h7.07a25.42 25.42 0 01-1.236-3.297l-.048-.17z"
    ></path>
    <path
      fill="currentColor"
      d="M17.849 27.606a2.16 2.16 0 011.244-.388c.29 0 .582.057.849.17.306-.226.678-.38 1.082-.428l.09-.009h.153c.12 0 .242.009.363.025 1.883-3.806 4.752-5.738 4.752-5.738l-2.037-2.141c-6.723 5.907-10.246-3.12-10.246-3.12l-3.564 1.39s3.524 3.354 4.873 10.635c.194 1.075.348 2.23.437 3.475l.024-.097a2.276 2.276 0 011.09-1.31 2.214 2.214 0 01.89-2.464zM44.394 23.945h-1.85a17.133 17.133 0 00-11.023 4.017 15.62 15.62 0 01-3.54 2.246c.041.065.074.13.106.202a2.255 2.255 0 01-.647 2.61l-.218.17c.194.34.307.727.307 1.14 0 .169-.016.339-.064.492a5.1 5.1 0 011.575-.242c2.805 0 5.091 2.23 5.196 5.01h10.158a.61.61 0 00.606-.606V24.55a.61.61 0 00-.606-.606zm-2.085 7.41l-9.875.009v-.38c.259-.194.518-.396.784-.622a14.537 14.537 0 019.091-3.386v4.38z"
    ></path>
    <path
      fill="currentColor"
      d="M29.04 36.115a3.592 3.592 0 10-.008 7.184 3.592 3.592 0 00.008-7.184zm0 5.172a1.585 1.585 0 01-1.583-1.576c0-2.093 3.16-2.085 3.16 0 0 .865-.712 1.576-1.577 1.576zM25.541 33.772l-.889-.582c1.31-1.206 2.244-1.436 1.948-2.141-.296-.683-1.107-.187-2.578-.105l.372-1.6c.14-.58-.578-1.006-1.026-.558l-1.14 1.18c-.335-.656-.326-1.456-1.018-1.398-.687.08-.545.877-.679 1.293l-1.05-.881a.595.595 0 00-.728-.04c-.628.426.03 1.145.388 2.529-.76.06-1.497-.286-1.729.388-.213.68.71.911 1.212 1.325-1.306 1.209-2.243 1.444-1.947 2.15.302.696 1.092.176 2.788.064.144 1.802.144 2.17 1.591 1.018.316.624.312 1.39.954 1.39h.057a.618.618 0 00.541-.5l.137-.777c.738.508 1.176 1.322 1.778.913a.602.602 0 00.235-.694l-.615-1.851 1.148.04h.016c.657 0 .843-.915.234-1.163z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyCollisionThirdParty;
