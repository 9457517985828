import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@wegroup/design-system';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  description?: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
  confirmButtonText?: string;
  isLoading?: boolean;
}

const RemoveAlertDialog: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  description = '',
  isOpen,
  onClose,
  onConfirm,
  confirmButtonText,
  isLoading,
  children,
}) => {
  const { t } = useTranslation();
  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleOnConfirm = async () => {
    await onConfirm();
    onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      motionPreset="slideInBottom"
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>
          {children ? (
            <AlertDialogBody>{children}</AlertDialogBody>
          ) : (
            <AlertDialogBody
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          )}

          <AlertDialogFooter>
            <Button
              variant="grayedGhost"
              ref={cancelRef}
              onClick={onClose}
              isDisabled={isLoading}
            >
              {t('CANCEL')}
            </Button>
            <Button
              variant="dangerPrimary"
              onClick={handleOnConfirm}
              ml="3"
              isLoading={isLoading}
              data-test-id="DESIGN-SYSTEM_REMOVE-ALERT-MODAL_REMOVE-BUTTON"
            >
              {confirmButtonText || t('DELETE')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default RemoveAlertDialog;
