import React, { forwardRef } from 'react';

import { chakraCustomScrollBar } from '../../../../../../common/src/utils/styledComponentsUtils';

import { Box } from '@wegroup/design-system';

const SearchSelectDrawerDropdown = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren
>(({ children, ...otherProps }, ref) => {
  return (
    <Box
      ref={ref}
      {...otherProps}
      border="none"
      borderRadius="0"
      p="4"
      sx={chakraCustomScrollBar}
    >
      {children}
    </Box>
  );
});

export default SearchSelectDrawerDropdown;
