import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersFolder: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21.484 10.813c-.18-.062-.426-.088-1.869-.088h-6.912c-1.045 0-1.71.002-2.212.043-.48.039-.634.104-.692.133a1.617 1.617 0 00-.706.707c-.03.058-.094.212-.134.691-.033.408-.04.922-.042 1.66h14.655l-.57-1.14c-.645-1.292-.778-1.499-.914-1.632a1.618 1.618 0 00-.604-.374zm6.56 3.145L26.58 11.03l-.105-.21c-.473-.95-.908-1.824-1.588-2.49a5.616 5.616 0 00-2.1-1.297c-.899-.31-1.875-.309-2.937-.307h-7.222c-.948 0-1.778 0-2.463.056-.727.06-1.466.192-2.182.556A5.617 5.617 0 005.53 9.792c-.365.716-.497 1.455-.556 2.181-.056.686-.056 1.515-.056 2.463v18.24c0 1.45 0 2.658.08 3.645.084 1.028.266 1.99.729 2.9a7.425 7.425 0 003.245 3.245c.909.463 1.871.644 2.9.728.986.081 2.194.081 3.644.081h18.97c1.45 0 2.658 0 3.644-.08 1.029-.085 1.991-.266 2.9-.73a7.425 7.425 0 003.245-3.244c.463-.91.645-1.872.729-2.9.08-.987.08-2.195.08-3.645v-8.12c0-1.449 0-2.657-.08-3.644-.084-1.028-.266-1.99-.729-2.9a7.425 7.425 0 00-3.245-3.244c-.909-.464-1.871-.645-2.9-.73-.986-.08-2.194-.08-3.644-.08h-6.44zm-1.276 4h7.635c1.552 0 2.596.002 3.4.068.782.064 1.159.178 1.41.306a3.425 3.425 0 011.497 1.496c.128.252.242.63.306 1.41.066.804.067 1.848.067 3.4v7.957c0 1.552-.001 2.596-.067 3.4-.064.781-.178 1.159-.306 1.41a3.426 3.426 0 01-1.497 1.497c-.251.128-.629.242-1.41.306-.804.065-1.848.067-3.4.067H15.597c-1.552 0-2.596-.002-3.4-.067-.781-.064-1.159-.178-1.41-.306a3.426 3.426 0 01-1.497-1.497c-.128-.251-.242-.629-.306-1.41-.066-.804-.067-1.848-.067-3.4V17.958h17.851z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersFolder;
