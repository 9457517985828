import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  InputProps,
} from '@wegroup/design-system';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick?: () => void;
  onClear?: () => void;
  isClearable?: boolean;
  value: [Date | null, Date | null];
  leftPlaceholder?: string;
  rightPlaceholder?: string;
}

const inputStyleProps: InputProps = {
  _focus: {
    border: '1px',
    borderColor: 'gray.100',
  },
  _hover: {
    border: '1px',
    borderColor: 'gray.100',
  },
  _focusVisible: {
    border: '1px',
    borderColor: 'gray.100',
  },
  focusBorderColor: 'gray.100',
  sx: {
    caretColor: 'transparent',
  },
};

const DateRangerPickerInputs: React.FC<Props> = ({
  onClick,
  onClear,
  isClearable,
  value,
  leftPlaceholder,
  rightPlaceholder,
}) => {
  const { t } = useTranslation();
  const [fromDate, tillDate] = value;

  return (
    <Flex onClick={onClick} w="100%">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon name="GeneralOthersCalendarDateOnly" color="gray.300" />
        </InputLeftElement>
        <Input
          borderRightRadius={0}
          cursor="pointer"
          placeholder={leftPlaceholder || t('FROM')}
          value={fromDate ? format(fromDate, 'dd/MM/yyyy') : ''}
          data-test-id="DATE-RANGE-INPUT-PICKER_FROM"
          {...inputStyleProps}
        />
        {isClearable && (
          <InputRightElement cursor="pointer" onClick={onClear}>
            <Icon
              name="GeneralActionsCloseOrRemove"
              color="gray.300"
              boxSize={5}
            />
          </InputRightElement>
        )}
      </InputGroup>
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon name="GeneralOthersCalendarDateOnly" color="gray.300" />
        </InputLeftElement>
        <Input
          // The negative pixel is to hide the border on the left.
          // Its a cleaner solution than removing the border on the left
          // as otherwise the pseude selectors would have to be repeated
          // specifically for the left border.
          ml="-1px"
          borderLeftRadius={0}
          cursor="pointer"
          placeholder={rightPlaceholder || t('TILL')}
          value={tillDate ? format(tillDate, 'dd/MM/yyyy') : ''}
          data-test-id="DATE-RANGE-INPUT-PICKER_TILL"
          {...inputStyleProps}
        />
        {isClearable && (
          <InputRightElement cursor="pointer" onClick={onClear}>
            <Icon
              name="GeneralActionsCloseOrRemove"
              color="gray.300"
              boxSize={5}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </Flex>
  );
};

export default DateRangerPickerInputs;
