import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationAccident: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3768_10696"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0-.001h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3768_10696)">
        <path
          fill={colors.primary[50]}
          d="M337 160.999c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.635 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M237.21 171V43a5 5 0 00-5-5h-57.76a5 5 0 00-5 5v88.136a5 5 0 01-4.257 4.944l-43.068 6.476c-15.61 2.348-27.155 15.759-27.155 31.545 0 17.617 14.281 31.899 31.899 31.899h75.341c19.33 0 35-15.67 35-35z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M112.33 146.08l17.92 59.36M176.73 206l-26.32-67.76"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="4"
          d="M199.13 206l38.08-68.88-73.92 30.8"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M170.01 68.24l66.64-17.36"
        ></path>
        <g filter="url(#filter0_dd_3768_10696)">
          <path
            fill="#fff"
            d="M110.562 64.053L96.653 108.56l22.96-3.92 138.05 41.201c3.207.957 6.43-1.445 6.43-4.791v-40.454a5 5 0 00-3.657-4.816L122.792 57.403a10 10 0 00-12.23 6.65z"
          ></path>
          <path
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M110.562 64.053L96.653 108.56l22.96-3.92 138.05 41.201c3.207.957 6.43-1.445 6.43-4.791v-40.454a5 5 0 00-3.657-4.816L122.792 57.403a10 10 0 00-12.23 6.65z"
          ></path>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M131.493 119.295c-.734 2.738-3.126 5.007-6.872 6.274-3.731 1.262-8.581 1.428-13.63.076-5.049-1.353-9.167-3.923-11.766-6.881-2.611-2.97-3.548-6.132-2.815-8.87.734-2.738 3.126-5.007 6.873-6.274 3.73-1.262 8.58-1.429 13.629-.076 5.05 1.353 9.167 3.922 11.766 6.88 2.611 2.971 3.548 6.133 2.815 8.871z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_3768_10696"
          width="203.44"
          height="125.021"
          x="78.653"
          y="47.033"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3768_10696"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_3768_10696"
            result="effect2_dropShadow_3768_10696"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_3768_10696"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationAccident;
