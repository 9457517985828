import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyScorchDamage: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M26 8a3 3 0 013 3v17.59l.89.59a7 7 0 11-7.78 0l.89-.59V11a3 3 0 013-3zm0-2a5 5 0 00-5 5v16.52a9 9 0 1010 0V11a5 5 0 00-5-5z"
    ></path>
    <path
      fill="currentColor"
      d="M27 30.1V12h-2v18.1a5 5 0 102 0zM15.21 32.84a7.19 7.19 0 01-2.88-1.17C10.67 30.5 9 28.33 9 24.33s4-6.66 4-6.66v2A1.3 1.3 0 0014.33 21c1.77 0 1.34-2.67 1.34-4a11.34 11.34 0 01.66-4.67 6 6 0 012.77-2.48c-.069.38-.102.764-.1 1.15v15.52a11 11 0 00-3.79 6.32z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyScorchDamage;
