import React from 'react';
import { HeaderGroup } from 'react-table';
import { Thead, Th, Tr, Flex } from '@wegroup/design-system';
import SortIndicator, { SortType } from '../SortIndicator';
import { CustomColumnProps } from '.';

interface Props {
  headerGroups: HeaderGroup[];
  isHeaderSticky?: boolean;
  isScrollable?: boolean;
}
const ReactTableHead: React.FC<React.PropsWithChildren<Props>> = ({
  headerGroups,
  isHeaderSticky,
  isScrollable,
}) => {
  const getSortType = (column: HeaderGroup) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return SortType.Desc;
      }
      return SortType.Asc;
    }
  };

  return (
    <Thead position={isHeaderSticky ? 'sticky' : 'unset'} top="0" zIndex={1}>
      {headerGroups.map((headerGroup, i) => (
        <Tr {...headerGroup.getHeaderGroupProps()} key={`HeaderRow_${i}`}>
          {headerGroup.headers.map(
            (column: HeaderGroup & CustomColumnProps, j) => {
              const isStickyColumn = !!column.isSticky && isScrollable;
              return (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={`headerRowItem_${j}`}
                  position={isStickyColumn ? 'sticky' : 'unset'}
                  zIndex={isStickyColumn ? '1' : 'unset'}
                  transition="0.2s"
                  _last={
                    isStickyColumn
                      ? {
                          right: 0,
                          borderLeft: '1px',
                          borderColor: 'gray.50',
                          boxShadow: '-1px 0 10px -2px rgba(40, 41, 61, 0.08)',
                        }
                      : {}
                  }
                  _first={
                    isStickyColumn
                      ? {
                          left: '0',
                          borderRight: '1px',
                          boxShadow: '1px 0 10px -2px rgba(40, 41, 61, 0.08)',
                          borderColor: 'gray.50',
                        }
                      : {}
                  }
                  sx={{
                    // Important is needed because table styling cant be overwritten otherwise
                    '@media print': {
                      color: 'black !important',
                    },
                  }}
                >
                  <Flex>
                    {column.render('Header')}
                    {column.canSort && (
                      <SortIndicator type={getSortType(column)} />
                    )}
                  </Flex>
                </Th>
              );
            },
          )}
        </Tr>
      ))}
    </Thead>
  );
};

export default ReactTableHead;
