import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusWarningTriangle: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.574 8.542a4.946 4.946 0 0 1 6.654 1.745l.005.007 14.592 24.36.012.021a4.945 4.945 0 0 1-4.228 7.419H10.39a4.945 4.945 0 0 1-4.228-7.419l.012-.02 14.592-24.361 1.287.77-1.282-.777a4.945 4.945 0 0 1 1.802-1.745Zm.764 3.298s0 .002-.002.003L8.757 36.184a1.945 1.945 0 0 0 1.661 2.91h29.166a1.945 1.945 0 0 0 1.661-2.91l-14.58-24.341-.002-.003a1.944 1.944 0 0 0-3.324 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25 18.42a1.5 1.5 0 0 1 1.5 1.5v6.891a1.5 1.5 0 0 1-3 0V19.92a1.5 1.5 0 0 1 1.5-1.5ZM23.5 33.703a1.5 1.5 0 0 1 1.5-1.5h.017a1.5 1.5 0 1 1 0 3H25a1.5 1.5 0 0 1-1.5-1.5Z"
      fill="currentColor"
    />
  </Icon>
);

export default GeneralStatusWarningTriangle;
