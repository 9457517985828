import { WithCSSVar, Theme as ChakraTheme } from '@chakra-ui/react';
import { useThemeHookNoType } from '@wegroup/design-system';
import baseTheme from '../theme';

export type Theme = typeof baseTheme & { zIndices: ChakraTheme['zIndices'] };
export type ThemeObject = WithCSSVar<Theme>;

const useTheme = (): WithCSSVar<Theme> => useThemeHookNoType<Theme>();

export default useTheme;
