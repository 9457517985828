import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralLabelsID: React.FC<React.PropsWithChildren<unknown>> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M4 9.79v30h42v-30H4zm18 27H8v-2h14v2zm0-5h-2v-5.5a3.5 3.5 0 00-3.5-3.5h-3a3.5 3.5 0 00-3.5 3.5v5.5H8v-18h14v18zm20 5h-3v-2h3v2zm0-8H25v-4h17v4zm0-7H25v-2h17v2zm0-5H25v-3h17v3z"
    ></path>
    <path fill="currentColor" d="M15 21.79a3 3 0 100-6 3 3 0 000 6z"></path>
  </Icon>
);

export default GeneralLabelsID;
