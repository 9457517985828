import React, { RefObject, useRef } from 'react';
import { Box, Text, useOutsideClick, Kbd } from '@wegroup/design-system';
import SearchPickerOption from './SearchPickerTagOption';
import { chakraCustomScrollBar } from '../../../../../common/src/utils/styledComponentsUtils';
import { useVirtual } from 'react-virtual';
import { Option } from '.';
import { Trans, useTranslation } from 'react-i18next';

interface Props {
  searchValue?: string;
  options?: Option[];
  selectedOptions: Option[];
  onChangeSelectedOptions: (options: Option[]) => void;
  inputRef?: RefObject<HTMLInputElement>;
  withSelectAll?: boolean;
  onClosePopover?: () => void;
  onClickAdd: () => void;
  isDisabled?: boolean;
  tagExists: boolean;
}

const SearchPickerTagList: React.FC<React.PropsWithChildren<Props>> = ({
  searchValue,
  onClickAdd,
  options,
  selectedOptions,
  onChangeSelectedOptions,
  inputRef,
  onClosePopover,
  tagExists,
}) => {
  const { t } = useTranslation();
  const parentRef = useRef(null);
  const rowVirtualizer = useVirtual({
    size: options?.length || 0,
    parentRef,
  });

  useOutsideClick({
    ref: parentRef,
    handler: () => onClosePopover?.(),
  });

  const handleOptionClick = (option: Option) => {
    if (selectedOptions.some((o) => o.value === option.value)) {
      // remove it from selectedOptions
      onChangeSelectedOptions([
        ...selectedOptions.filter((o) => o.value !== option.value),
      ]);
    } else {
      // add it to selectedOptions
      onChangeSelectedOptions([...selectedOptions, option]);
    }
    inputRef?.current?.focus();
  };

  return (
    <Box
      h="100%"
      maxH="60"
      overflow="auto"
      sx={{ ...chakraCustomScrollBar }}
      data-test-id="General_SearchPickerList_List"
      background="white"
      ref={parentRef}
    >
      {options?.length === 0 ? (
        <Text color="gray.400" align="center" p="4">
          {t('NO-RESULTS-FOUND')}
        </Text>
      ) : (
        !!options && (
          <Box h={rowVirtualizer.totalSize} w="100%" position="relative">
            {rowVirtualizer.virtualItems.map(({ index, measureRef, start }) => {
              const option = options[index];
              return (
                <Box
                  key={index}
                  ref={measureRef}
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  transform={`translateY(${start}px)`}
                >
                  <SearchPickerOption
                    option={option}
                    isChecked={selectedOptions.some(
                      (o) => o.value === option.value,
                    )}
                    onClick={() => handleOptionClick(option)}
                  />
                </Box>
              );
            })}
          </Box>
        )
      )}
      {searchValue && !tagExists && (
        <Text
          px="3"
          py="4"
          textStyle="normalBody"
          cursor="pointer"
          color="gray.400"
          onClick={onClickAdd}
          _hover={{ bg: 'gray.ultraLight' }}
        >
          <Trans
            i18nKey="DESIGN-SYSTEM_COMPONENTS_SEARCH-PICKER-TAG-LIST_LIST_ADD-TAG"
            values={{ tag: searchValue }}
            components={{
              Kbd: <Kbd />,
              Text: <Text as="span" color="gray.600" fontWeight="medium" />,
            }}
          />
        </Text>
      )}
      {!searchValue && (
        <Text
          px="3"
          py="4"
          textStyle="normalBody"
          color="gray.400"
          borderTop={options?.length ? '1px' : undefined}
          borderColor="gray.50"
        >
          {t(
            'DESIGN-SYSTEM_COMPONENTS_SEARCH-PICKER-TAG-LIST_LIST_ADD-TAG_EMPTY',
          )}
        </Text>
      )}
    </Box>
  );
};

export default SearchPickerTagList;
