import React, { FocusEventHandler } from 'react';
import { isMobile } from 'react-device-detect';

import { SearchSelectProps } from './types';

import { useDisclosure } from '@wegroup/design-system';
import SearchSelectInput from './SearchSelectInput';
import SearchSelectDrawer from './drawer/SearchSelectDrawer';

const SearchSelect: React.FC<React.PropsWithChildren<SearchSelectProps>> = (
  props,
) => {
  //  Destructuring props to filter chakra styling props
  const {
    name,
    data,
    clearable,
    isLoading,
    prefilled,
    error,
    value,
    onChange,
    'data-test-id': dataTestId,
    placeholder,
    extraStyles,
    dataFetcher,
    onSearchChange,
    nothingFound,
    isDisabled,
    isDataFilterDisabled,
    icon,
    withinPortal,
    ...chakraProps
  } = props;

  const mantineProps = {
    name,
    data,
    clearable,
    isLoading,
    isDisabled,
    prefilled,
    error,
    value,
    onChange,
    'data-test-id': dataTestId,
    placeholder,
    extraStyles,
    dataFetcher,
    onSearchChange,
    nothingFound,
    isDataFilterDisabled,
    icon,
    withinPortal,
  };

  // Drawer for mobile
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openDrawer = (
    e:
      | React.TouchEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement, Element>,
  ) => {
    // Stops dropdown from opening
    e.preventDefault();
    e.stopPropagation();

    // Open drawer
    onOpen();
  };

  const handleOnFocusCapture: FocusEventHandler<HTMLInputElement> = (e) => {
    e.target.blur(); // Unfocus the element
    openDrawer(e);
  };

  return (
    <>
      {/* Mobile */}
      {isMobile && (
        <SearchSelectDrawer
          isOpen={isOpen}
          onClose={onClose}
          chakraProps={chakraProps}
          {...mantineProps}
        />
      )}

      {/* Desktop */}
      <SearchSelectInput
        onFocusCapture={isMobile ? handleOnFocusCapture : undefined}
        chakraProps={chakraProps}
        {...mantineProps}
      />
    </>
  );
};

export default SearchSelect;
