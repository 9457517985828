import { useRouteMatch } from 'react-router';
import {
  FlowResumeRouteParams,
  FlowSessionRouteParams,
} from '../types/RouteParams';

export interface FlowsRouteParams {
  flowId?: string;
  sessionId?: string;
  position?: string;
  subView?: string;
}

/**
 * Will only return a valid while inside a route
 * @returns All session params for an active session
 */
export const useFlowsRouteParams = (): FlowsRouteParams => {
  const { params } =
    useRouteMatch<FlowSessionRouteParams>(
      '/flows/:flowId/questions/:sessionId/:position',
    ) || {};

  const { params: resumeRouteParams } =
    useRouteMatch<FlowResumeRouteParams>(
      '/flows/:flowId/questions/:sessionId',
    ) || {};

  const { params: devtoolsRouteParams } =
    useRouteMatch<FlowResumeRouteParams>(
      '/flows/:flowId/devtools/:sessionId',
    ) || {};

  const { params: sourceRouteParams } =
    useRouteMatch<FlowResumeRouteParams>('/flows/:flowId') || {};

  return {
    flowId:
      params?.flowId ||
      resumeRouteParams?.flowId ||
      devtoolsRouteParams?.flowId ||
      sourceRouteParams?.flowId,
    sessionId:
      params?.sessionId ||
      resumeRouteParams?.sessionId ||
      devtoolsRouteParams?.sessionId,
    position: params?.position,
    subView: params?.subView,
  };
};
