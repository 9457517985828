import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleTruck: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M17.911 33.182a3.273 3.273 0 11-6.546-.001 3.273 3.273 0 016.546 0z"
    ></path>
    <path
      fill="currentColor"
      d="M21.183 27.728V13.545H7v19.637h3.273a4.363 4.363 0 118.726 0h5.455a4.363 4.363 0 118.727 0 4.363 4.363 0 118.727 0H43v-5.454H21.183zm-5.455-5.455H9.183v-6.546h6.545v6.546z"
    ></path>
    <path
      fill="currentColor"
      d="M32.09 33.182a3.273 3.273 0 11-6.546 0 3.273 3.273 0 016.546 0zM40.819 33.182a3.273 3.273 0 11-6.545 0 3.273 3.273 0 016.545 0z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleTruck;
