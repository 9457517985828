import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycleAllowance: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_698_3132"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_698_3132)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M180 113l-33 68-3.599-1.747 33-68L180 113z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M118 182c0 23.195-18.804 42-42 42s-42-18.805-42-42c0-23.196 18.804-42 42-42s42 18.804 42 42z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M76 220c20.987 0 38-17.014 38-38 0-20.987-17.013-38-38-38s-38 17.013-38 38c0 20.986 17.013 38 38 38zm0 4c23.196 0 42-18.805 42-42 0-23.196-18.804-42-42-42s-42 18.804-42 42c0 23.195 18.804 42 42 42z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M111 182c0 19.329-15.67 35-35 35s-35-15.671-35-35c0-19.33 15.67-35 35-35s35 15.67 35 35z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M76 213c17.12 0 31-13.88 31-31 0-17.121-13.88-31-31-31-17.12 0-31 13.879-31 31 0 17.12 13.88 31 31 31zm0 4c19.33 0 35-15.671 35-35 0-19.33-15.67-35-35-35s-35 15.67-35 35c0 19.329 15.67 35 35 35z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M254 182c0 23.195-18.804 42-42 42s-42-18.805-42-42c0-23.196 18.804-42 42-42s42 18.804 42 42z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M212 220c20.987 0 38-17.014 38-38 0-20.987-17.013-38-38-38s-38 17.013-38 38c0 20.986 17.013 38 38 38zm0 4c23.196 0 42-18.805 42-42 0-23.196-18.804-42-42-42s-42 18.804-42 42c0 23.195 18.804 42 42 42z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M247 182c0 19.329-15.67 35-35 35s-35-15.671-35-35c0-19.33 15.67-35 35-35s35 15.67 35 35z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M212 213c17.121 0 31-13.88 31-31 0-17.121-13.879-31-31-31-17.121 0-31 13.879-31 31 0 17.12 13.879 31 31 31zm0 4c19.33 0 35-15.671 35-35 0-19.33-15.67-35-35-35s-35 15.67-35 35c0 19.329 15.67 35 35 35zM84 113a2 2 0 012-2h18a2 2 0 012 2c0 12.139-4.491 27.802-10.09 41.268-2.812 6.76-5.932 13.036-8.966 18.116-2.996 5.018-6.026 9.07-8.705 11.185a2 2 0 11-2.478-3.139c2.07-1.635 4.792-5.145 7.749-10.097 2.919-4.888 5.955-10.987 8.706-17.601 5.206-12.519 9.289-26.64 9.742-37.732H86a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M101 122.5l43 62.5h73l-43-62.5h-73zm7.607 4l37.496 54.5h63.29l-37.496-54.5h-63.29z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M156 182c0 6.627-5.373 12-12 12s-12-5.373-12-12c0-6.628 5.373-12 12-12s12 5.372 12 12z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M144 190a8 8 0 100-16 8 8 0 000 16zm0 4c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12zM156 113a2 2 0 012-2h28a2 2 0 110 4h-28a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M125.941 274.408a5.532 5.532 0 004.507 6.391l158.031 27.278a5.53 5.53 0 006.388-4.511l30.57-177.105-38.322-54.298L169.951 51.94a5.528 5.528 0 00-6.387 4.507l-37.623 217.961z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M325.441 126.462l-41.23-7.117a5.165 5.165 0 01-4.21-5.967l7.114-41.215 38.326 54.299z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M250.53 201.266l33.993 5.868M223.945 227.238l55.456 9.573"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M229.992 187.701l-7.85-1.356a33.307 33.307 0 01-15.806-7.388 33.706 33.706 0 01-9.97-14.405l18.402 3.177a6.234 6.234 0 004.59-1.141 6.349 6.349 0 002.505-4.047 6.348 6.348 0 00-1.003-4.653 6.236 6.236 0 00-3.942-2.613l-22.513-3.886a30.879 30.879 0 011.409-8.161l32.793 5.661a6.224 6.224 0 004.766-.999 6.356 6.356 0 002.563-6.639 6.274 6.274 0 00-2.846-3.97 6.203 6.203 0 00-2.333-.846l-28.684-4.951a33.718 33.718 0 0114.223-10.225 33.312 33.312 0 0117.367-1.659l7.85 1.355a6.23 6.23 0 004.59-1.14 6.35 6.35 0 002.506-4.047 6.354 6.354 0 00-1.004-4.653 6.232 6.232 0 00-3.942-2.613l-7.851-1.355a45.797 45.797 0 00-27.026 3.62 46.465 46.465 0 00-20.329 18.375l-10.431-1.801a6.224 6.224 0 00-4.766.999 6.327 6.327 0 00-1.728 1.802 6.374 6.374 0 00-.835 4.837 6.314 6.314 0 001.025 2.277 6.22 6.22 0 004.154 2.539l7.529 1.299c-.349 1.325-.671 2.665-.906 4.028-.236 1.364-.385 2.749-.5 4.118l-7.529-1.299a6.232 6.232 0 00-4.765.999 6.327 6.327 0 00-1.729 1.801 6.387 6.387 0 00-.835 4.837 6.331 6.331 0 001.025 2.277 6.251 6.251 0 001.822 1.694 6.206 6.206 0 002.333.845l10.431 1.801a46.463 46.463 0 0012.994 24.125 45.814 45.814 0 0024.247 12.472l7.851 1.355a6.238 6.238 0 004.59-1.14 6.35 6.35 0 002.505-4.048 6.35 6.35 0 00-1.003-4.653 6.236 6.236 0 00-3.942-2.613l-.002.008z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBicycleAllowance;
