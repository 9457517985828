import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationRecreationalBoat: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3799_10360"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3799_10360)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[200]}
          d="M58 157v22a3 3 0 003 3h49.644c1.79 0 3.183-1.557 2.966-3.333-1.494-12.253-3.251-20.356-6.914-27.646-2.319-4.613-7.337-7.021-12.5-7.021H71c-7.18 0-13 5.82-13 13z"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M106.696 151.021l-1.787.898 1.787-.898zm6.914 27.646l1.986-.243-1.986.243zM60 179v-22h-4v22h4zm11-33h23.196v-4H71v4zm33.909 5.919c3.503 6.971 5.228 14.792 6.716 26.99l3.971-.485c-1.502-12.308-3.29-20.693-7.113-28.301l-3.574 1.796zM110.644 180H61v4h49.644v-4zm.981-1.091a.979.979 0 01-.981 1.091v4c2.977 0 5.315-2.594 4.952-5.576l-3.971.485zM94.195 146c4.601 0 8.814 2.138 10.714 5.919l3.574-1.796c-2.737-5.446-8.56-8.123-14.287-8.123v4zM60 157c0-6.075 4.925-11 11-11v-4c-8.284 0-15 6.716-15 15h4zm-4 22a5 5 0 005 5v-4a1 1 0 01-1-1h-4z"
        ></path>
        <path
          fill="#fff"
          d="M182.481 132l-23.312 46.659c-.997 1.995.454 4.341 2.684 4.341h119.249c2.241 0 3.691-2.367 2.673-4.363l-19.613-38.452A15 15 0 00250.8 132h-68.319z"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M159.169 178.659l1.789.894-1.789-.894zM182.481 132v-2h-1.236l-.553 1.106 1.789.894zm81.681 8.185l1.782-.909-1.782.909zm19.613 38.452l-1.782.909 1.782-.909zm-122.817.916l23.313-46.659-3.579-1.788-23.312 46.659 3.578 1.788zM182.481 134H250.8v-4h-68.319v4zm79.9 7.093l19.612 38.453 3.563-1.818-19.612-38.452-3.563 1.817zM281.102 181H161.853v4h119.249v-4zm.891-1.454a1 1 0 01-.891 1.454v4c3.735 0 6.151-3.945 4.454-7.272l-3.563 1.818zM250.8 134c4.886 0 9.36 2.74 11.581 7.093l3.563-1.817A17 17 0 00250.8 130v4zm-93.42 43.765c-1.661 3.325.756 7.235 4.473 7.235v-4a1 1 0 01-.895-1.447l-3.578-1.788z"
        ></path>
        <path
          fill={colors.primary[300]}
          d="M206.638 155.358l-12.501 25.314c-.984 1.993.467 4.328 2.69 4.328h85.301c2.234 0 3.685-2.355 2.679-4.35L267.844 147h-47.757a15 15 0 00-13.449 8.358z"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M194.137 180.672l1.794.885-1.794-.885zm12.501-25.314l1.793.886-1.793-.886zM267.844 147l1.786-.9-.555-1.1h-1.231v2zm16.963 33.65l-1.786.9 1.786-.9zm-88.876.907l12.5-25.313-3.586-1.771-12.501 25.313 3.587 1.771zM220.087 149h47.757v-4h-47.757v4zm45.971-1.1l16.963 33.65 3.572-1.801L269.63 146.1l-3.572 1.8zm16.07 35.1h-85.301v4h85.301v-4zm.893-1.45a1 1 0 01-.893 1.45v4c3.724 0 6.141-3.925 4.465-7.251l-3.572 1.801zm-74.59-25.306A13 13 0 01220.087 149v-4a16.999 16.999 0 00-15.242 9.473l3.586 1.771zm-16.087 23.542c-1.641 3.323.777 7.214 4.483 7.214v-4a1 1 0 01-.896-1.443l-3.587-1.771z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M168.171 109.682l14.222 21a3 3 0 002.484 1.318h27.422c2.415 0 3.84-2.708 2.473-4.699l-14.428-21a3.001 3.001 0 00-2.473-1.301h-27.216c-2.406 0-3.833 2.69-2.484 4.682zM58 181v40.15c.314 10.445 4.853 13.141 18.61 13.85h124.682c55.978-6.77 81.855-18.394 112.346-52.071 1.725-1.905.366-4.929-2.205-4.929H61a3 3 0 00-3 3z"
        ></path>
        <mask
          id="mask1_3799_10360"
          style={{ maskType: 'alpha' }}
          width="261"
          height="61"
          x="56"
          y="176"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M58 181v40.15c.314 10.445 4.853 13.141 18.61 13.85h124.682c55.978-6.77 81.855-18.394 112.346-52.071 1.725-1.905.366-4.929-2.205-4.929H61a3 3 0 00-3 3z"
          ></path>
        </mask>
        <g mask="url(#mask1_3799_10360)">
          <path
            stroke={colors.primary[500]}
            strokeWidth="16"
            d="M58 196h258"
          ></path>
        </g>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationRecreationalBoat;
