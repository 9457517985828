import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSportsCar: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3824_10570"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3824_10570)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M319.786 155.486c-78.961-33.014-171.219-31.868-225.209 7.056-1.536 1.107-.73 3.458 1.164 3.458h223.267a2 2 0 002-2v-6.675c0-.803-.481-1.529-1.222-1.839z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M132.508 144l-3.5 27M195.008 132l-6.5 34.5M224.008 133l10.5 24"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M30.5 193.001c-.004 14.171 2.64 17.683 10.448 24.052.355.289.802.448 1.26.448h220.594c.132 0 .263-.013.392-.039l44.226-8.845a1.991 1.991 0 001.003-.528l15.417-15.011c1.153-1.122.358-3.077-1.25-3.077a1.755 1.755 0 01-1.758-1.571c-1.08-10.899.494-23.195 1.478-32.57.109-1.046-.585-1.986-1.625-2.143-10.351-1.561-63.134-8.688-110.685.284-24.423 4.609-37.66 11.186-62.5 12-19.217.63-48.401-4.369-51.694-4.946a1.956 1.956 0 00-.625-.008c-34.75 5.067-64.68 26.471-64.681 31.954z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M32.508 192l62-5"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M189.508 202c21.872-8.482 29.865-13.64 29-25.5h12c-1 15-19.5 25.5-41 25.5z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M114.008 203.5l82.5-1.5c17.073-.949 32.347-11.343 33.874-23.505.138-1.096-.77-1.995-1.874-1.995h-24.416c-.387 0-.773.056-1.144.167l-32.94 9.833M272.008 183.5l44-22.5"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M313.484 162.574l9.024-8.074-2.5 35h-10.497a2 2 0 01-1.95-2.442l5.306-23.435a2 2 0 01.617-1.049zM42.003 191.5l-11 1c-3.315 12.988 1.989 17.071 10 25 4.57-8.476 6.505-13.5 1-26z"
        ></path>
        <circle
          cx="97.008"
          cy="204"
          r="22"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="97.008"
          cy="204"
          r="11.225"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="268.008"
          cy="201"
          r="25"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="268.008"
          cy="201"
          r="14"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationSportsCar;
