import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationDiamonds: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="m114.972 100.957 14.119-4.712a1.325 1.325 0 0 0 0-2.515l-11.604-3.866a5.315 5.315 0 0 1-3.358-3.351l-3.866-11.606a1.326 1.326 0 0 0-2.517 0l-3.866 11.606a5.319 5.319 0 0 1-3.357 3.354l-11.614 3.87a1.325 1.325 0 0 0 0 2.514l11.614 3.873a5.297 5.297 0 0 1 3.351 3.357l3.872 11.612a1.328 1.328 0 0 0 2.034.657c.226-.163.395-.393.483-.657l4.709-14.136ZM241.984 92.961a10.646 10.646 0 0 1 7.978-7.979l16.01-3.693A1.335 1.335 0 0 0 267 79.992a1.335 1.335 0 0 0-1.028-1.296l-16.01-3.693a10.638 10.638 0 0 1-7.978-7.976l-3.693-15.992a1.334 1.334 0 0 0-2.597 0l-3.688 15.995a10.636 10.636 0 0 1-7.977 7.979l-16.001 3.69A1.334 1.334 0 0 0 207 79.995a1.335 1.335 0 0 0 1.028 1.297l16.001 3.69a10.643 10.643 0 0 1 7.977 7.976l3.688 16.007a1.33 1.33 0 0 0 2.597 0l3.693-16.004Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M229 138h-33.036L173 201a5.248 5.248 0 0 0 2.938-1.564l51.732-58.069A5.226 5.226 0 0 0 229 138Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M196 138h33v-.045a5.247 5.247 0 0 0-1.204-3.412l-17.934-21.698a10.737 10.737 0 0 0-3.658-2.845l-10.189 27.988L196 138ZM206 110.027a10.647 10.647 0 0 0-4.563-1.027H172l23.875 29L206 110.027Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m172 109-24 29h48l-24-29Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="m148 138 22.923 63.768c.346.115.704.193 1.066.232h.019c.362-.039.72-.117 1.066-.232L196 138h-48Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M115 138h33.038L171 201a5.26 5.26 0 0 1-2.939-1.564l-51.731-58.069A5.226 5.226 0 0 1 115 138v0Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148 138h-32.999v-.045a5.217 5.217 0 0 1 1.201-3.412l17.936-21.698a10.745 10.745 0 0 1 3.673-2.845L148 137.988V138ZM138 110.027a10.652 10.652 0 0 1 4.544-1.027H172l-23.87 29L138 110.027Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationDiamonds;
