/**
ORIGINAL AUTHOR: Chakra-UI team
MODIFIED BY: Maxime Verhoeve
SOURCE: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/accordion/src/accordion-icon.tsx
REASON: Made it possible to add WeGroup's custom icons to the AccordionIcon component
*/

import React from 'react';
import { SystemStyleObject } from '@chakra-ui/system';
import { cx } from '@chakra-ui/shared-utils';
import { Icon } from '@wegroup/design-system';
import {
  useAccordionContext,
  useAccordionStyles,
  useAccordionItemState,
  Center,
  BoxProps,
} from '@chakra-ui/react';
import { IconProps } from '../Icon';

/**
 * AccordionIcon that gives a visual cue of the open/close state of the accordion item.
 * It rotates `180deg` based on the open/close state.
 */

const AccordionIcon: React.FC<IconProps & BoxProps> = ({
  name = 'GeneralActionsChevronDownRounded',
  boxSize,
  ...props
}) => {
  const { reduceMotion } = useAccordionContext();
  const { isOpen, isDisabled } = useAccordionItemState();

  const _className = cx('chakra-accordion__icon');
  const styles = useAccordionStyles();

  const iconStyles: SystemStyleObject = {
    opacity: isDisabled ? 0.4 : 1,
    transform: isOpen ? 'rotate(-180deg)' : undefined,
    transition: reduceMotion ? undefined : 'transform 0.2s',
    transformOrigin: 'center',
    svg: {
      transition: '0.2s',
    },
    ...styles.icon,
  };

  return (
    <Center className={_className} __css={iconStyles} {...props}>
      <Icon name={name} boxSize={boxSize || (iconStyles?.boxSize as string)} />
    </Center>
  );
};

AccordionIcon.displayName = 'AccordionIcon';

export default AccordionIcon;
