import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralLabelsWorkAccident: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M41.82 28.17l-8.21-3.88a3 3 0 00-1.28-.29H28l-4.13-9h7A2.07 2.07 0 0033 13.34 2.001 2.001 0 0031 11h-7l-3.07-.51a7 7 0 00-5.31 1.29 6.93 6.93 0 00-2.33 3.12l-2.16 5.76a1.85 1.85 0 00-.13.7v5.53A2.07 2.07 0 0012.66 29 2.001 2.001 0 0015 27v-5.53a2 2 0 01.21-.89L16 19l3.25 8.11A3 3 0 0022 29h3.9l5.39 11.73a2.08 2.08 0 002.51 1.16 2 2 0 001.2-2.73L29.84 28h1.81l8.43 3.77a2.07 2.07 0 002.58-.67 2 2 0 00-.84-2.93zM13.5 11a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"
    ></path>
    <path
      fill="currentColor"
      d="M22 46a1 1 0 01-.29 0 1 1 0 01-.71-1v-4.56l-6.65 2.5a1 1 0 01-1.16-1.52l4.32-6.05-5-2.48A1 1 0 0113 31h8a1 1 0 010 2h-3.76l2.21 1.11a1 1 0 01.36 1.47l-3.09 4.33 4.93-1.85A1 1 0 0123 39v2.7l2.17-3.25a1 1 0 011.66 1.1l-4 6A1 1 0 0122 46z"
    ></path>
  </Icon>
);

export default GeneralLabelsWorkAccident;
