import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationFireBlankets: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M125.02 91h65.436c12.098 0 21.926 9.769 22 21.867l.544 89.8h-74.444v-90.01c-.887-15.197-3.71-20.154-13.536-21.657Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M138.556 187.778h-43.99c11.24-2.062 14.51-6.048 15.227-17.935v-64.462c0-7.942 6.439-14.381 14.381-14.381 7.943 0 14.382 6.439 14.382 14.381v82.397Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M109.455 124.5H79v48.051c0 8.409 6.817 15.227 15.227 15.227s15.228-6.818 15.228-15.227V124.5Z"
          fill={colors.primary[300]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g filter="url(#b)">
          <circle cx={223} cy={96} r={55} fill="#fff" />
          <circle
            cx={223}
            cy={96}
            r={53}
            stroke={colors.primary[500]}
            strokeWidth={4}
          />
        </g>
        <path
          d="M206.843 80.645a1.267 1.267 0 0 1 1.764.452c.113.197.172.42.169.647a17.205 17.205 0 0 0 3.315 10.83 1.26 1.26 0 0 0 1.674.305c2.053-1.282 6.808-5.306 9.183-15.996a42.757 42.757 0 0 0 .609-16.404 1.259 1.259 0 0 1 .526-1.255 1.261 1.261 0 0 1 1.36-.048 52.049 52.049 0 0 1 25.437 44.236c.362 15.857-12.163 29.419-28.023 29.368A27.941 27.941 0 0 1 195 104.835c.003-11.501 5.203-19.877 11.843-24.19Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
        <path
          d="M231.736 99.666a1.273 1.273 0 0 0-1.756 1.329c.142.958.197 1.927.163 2.896-.22 6.332-4.172 10.201-8.605 10.047a6.961 6.961 0 0 1-6.236-4.161 1.27 1.27 0 0 0-2.413.308 13.972 13.972 0 0 0 12.438 16.477 13.958 13.958 0 0 0 10.701-3.577 13.976 13.976 0 0 0 4.569-10.317 13.971 13.971 0 0 0-8.861-13.002Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="b"
          x={152}
          y={33}
          width={142}
          height={142}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={8} />
          <feGaussianBlur stdDeviation={8} />
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1812_6704"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0" />
          <feBlend
            in2="effect1_dropShadow_1812_6704"
            result="effect2_dropShadow_1812_6704"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1812_6704"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationFireBlankets;
