import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsCaretUp: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M24.292 16.71a1 1 0 011.416 0L36.29 27.32c.629.63.182 1.706-.708 1.706H14.419c-.89 0-1.337-1.075-.708-1.706l10.58-10.61z"
    />
  </Icon>
);

export default GeneralActionsCaretUp;
