import Input from './input';

const TextArea = {
  variants: {
    outline: Input.variants.outline.field,
    flows: Input.variants.flows.field,
    unstyled: Input.variants.unstyled.field,
  },
};

export default TextArea;
