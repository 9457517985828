import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersPercentage: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M13.1 17.724c0-1.681 1.189-2.829 2.706-2.829 1.558 0 2.706 1.148 2.706 2.829 0 1.681-1.148 2.87-2.706 2.87-1.517 0-2.706-1.148-2.706-2.87zm-4.1 0c0 3.731 3.075 6.683 6.806 6.683 3.69 0 6.847-2.87 6.847-6.683 0-3.772-3.157-6.724-6.847-6.724C12.116 11 9 13.952 9 17.724zm22.017 14.104c0-1.681 1.189-2.829 2.706-2.829 1.558 0 2.706 1.148 2.706 2.829 0 1.681-1.148 2.87-2.706 2.87-1.517 0-2.706-1.148-2.706-2.87zm-4.1 0c0 3.731 3.075 6.683 6.806 6.683 3.69 0 6.847-2.87 6.847-6.683 0-3.772-3.157-6.724-6.847-6.724-3.69 0-6.806 2.952-6.806 6.724zm-9.143 6.232l18.778-26.609h-4.756L13.018 38.06h4.756z"
    ></path>
  </Icon>
);

export default GeneralOthersPercentage;
