import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyFlooding: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M6.602 33.248c.22-.088.44-.264.573-.484l.749-1.102c.088-.132.22-.264.308-.397-.264-.088-.44-.352-.484-.617a.779.779 0 01.705-.925c.44-.088.881.22.925.705v.088a3.221 3.221 0 011.322-.264c1.102 0 2.16.573 2.777 1.454l.749 1.102c.264.44.705.66 1.233.66.53 0 .926-.22 1.234-.66l.75-1.102a3.094 3.094 0 011.366-1.146l-.22-1.454c-.133-.705-.441-1.366-.97-1.85-.088-.089-.177-.133-.22-.177l-1.631-3.437c-.132-.309-.485-.485-.837-.441l-7.007 1.102a.75.75 0 00-.661.66l-.485 3.658c-.044.044-.088.132-.176.22-.353.617-.485 1.367-.353 2.072l.353 2.335zm10.444-4.098a.778.778 0 01-.705.925.778.778 0 01-.926-.705.779.779 0 01.706-.925.865.865 0 01.925.705zm-8.99-4.054l7.007-1.102 1.41 2.997-8.858 1.366.441-3.261zM45.646 23.289L34.232 11.876a1.17 1.17 0 00-1.63 0L21.188 23.289c-.749.75-.22 1.983.838 1.983h1.85v7.668c.265.308.661.485 1.102.485.529 0 .925-.22 1.234-.661l.75-1.102c.616-.926 1.674-1.454 2.775-1.454 1.102 0 2.16.528 2.777 1.454l.749 1.102c.264.44.705.66 1.234.66s.97-.22 1.234-.66l.749-1.102a3.372 3.372 0 012.776-1.498c1.102 0 2.16.528 2.776 1.498l.75 1.102c.088.132.176.22.264.308v-7.8h1.85c.97 0 1.499-1.278.75-1.983z"
    ></path>
    <path
      fill="currentColor"
      d="M43.927 35.1c-1.057 0-2.027-.53-2.644-1.411l-.75-1.102a1.691 1.691 0 00-1.365-.705c-.529 0-1.058.265-1.366.75l-.75 1.101c-.616.881-1.542 1.41-2.644 1.41-1.057 0-2.027-.529-2.643-1.41l-.75-1.102a1.691 1.691 0 00-1.366-.705c-.529 0-1.057.265-1.366.705l-.749 1.102c-.617.881-1.542 1.41-2.644 1.41-1.058 0-2.027-.529-2.644-1.41l-.75-1.102c-.308-.44-.836-.749-1.365-.749-.53 0-1.058.265-1.366.705l-.75 1.102c-.617.881-1.542 1.41-2.644 1.41-1.101 0-2.027-.529-2.644-1.41l-.749-1.102a1.691 1.691 0 00-1.366-.705c-.529 0-1.058.265-1.366.75l-.75 1.101a3.135 3.135 0 01-2.775 1.41c-.882-.044-1.675.661-1.719 1.587-.044.925.661 1.674 1.586 1.718 1.895.088 3.702-.705 4.98-2.07a6.431 6.431 0 004.76 2.07c1.806 0 3.525-.749 4.759-2.07a6.431 6.431 0 004.759 2.07c1.807 0 3.525-.749 4.76-2.07 1.189 1.321 2.908 2.07 4.758 2.07 1.807 0 3.526-.749 4.76-2.07 1.19 1.321 2.908 2.07 4.715 2.07.925 0 1.63-.749 1.63-1.63.089-.97-.617-1.719-1.542-1.719z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyFlooding;
