import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsRemoveUser: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.7 7.697c-1.991 0-4.191.61-5.943 2.106-1.76 1.502-3.025 3.86-3.2 7.266-.039.361-.183 2.113.086 4.245.271 2.154.972 4.767 2.699 6.705.357.409.719.748 1.11 1.04l-.224.226c-.91.91-3.012 2.28-7.831 3.468l-.007.002c-1.53.4-2.704 1.336-3.45 2.691-.74 1.345-1.046 3.077-.908 5.072l.003.048.013.047c.235.862.908 1.623 1.868 1.623h.089l.022-.002c.982-.089 1.73-.908 1.639-1.926-.064-.74-.06-1.7.189-2.512.244-.8.696-1.384 1.498-1.565l.012-.003c6.718-1.696 9.45-3.949 10.507-5.635a8.574 8.574 0 001.895.188h.266c.786 0 1.515-.104 2.226-.263 1.088 1.74 3.744 4.069 10.508 5.776.81.21 1.266.817 1.511 1.619.249.813.253 1.757.19 2.46-.093 1.019.656 1.839 1.639 1.928l.022.002h.088c.943 0 1.661-.75 1.75-1.637v-.008l.001-.007c.137-2.028-.168-3.775-.908-5.128-.745-1.365-1.92-2.301-3.45-2.702l-.005-.001c-5.481-1.37-7.447-3.011-8.118-3.875.339-.266.656-.558.973-.928 1.725-1.938 2.426-4.532 2.697-6.677.27-2.133.124-3.89.086-4.269-.175-3.407-1.44-5.765-3.2-7.268-1.752-1.495-3.952-2.106-5.944-2.106H24.7zm-5.507 9.61v-.055c.13-2.887 1.244-4.365 2.413-5.142 1.2-.798 2.538-.91 3.162-.91h.398c.624 0 1.96.112 3.161.91 1.17.777 2.284 2.255 2.414 5.142v.086l.003.03c.095.753.129 2.255-.13 3.867-.26 1.62-.806 3.276-1.815 4.407l-.001.002c-.894 1.012-2.152 1.567-3.832 1.567h-.133c-1.678 0-2.935-.554-3.829-1.565-1.162-1.347-1.633-3.366-1.797-5.128-.14-1.517-.05-2.719-.022-3.095.005-.061.008-.1.008-.115z"
      clipRule="evenodd"
    ></path>
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth="6"
      d="M40.906 43c0-2.206.942-7.79-3.127-7.79-.477 0-1.364-.35-1.777-.584-.42-.239-.89-.353-1.291-.632-.465-.323-1.44-.511-1.711-.994"
    ></path>
    <path
      fill="currentColor"
      d="M42.265 39.324l-2.677-2.677 2.677-2.676a1.622 1.622 0 00-2.294-2.294l-2.677 2.676-2.676-2.677a1.622 1.622 0 10-2.294 2.295L35 36.647l-2.676 2.677a1.622 1.622 0 002.294 2.294l2.676-2.677 2.677 2.677a1.622 1.622 0 102.294-2.294z"
    ></path>
  </Icon>
);

export default GeneralActionsRemoveUser;
