import React, { ReactElement } from 'react';
import { Tbody } from '@wegroup/design-system';
import { Row } from 'react-table';
import { useHistory } from 'react-router';
import ReactTableBodyRow from './ReactTableBodyRow';

interface Props {
  rows: Row[];
  prepareRow: (row: Row) => void;
  isScrollable?: boolean;
  onRowClick?: (index: number) => void;
  spannedCellRenderer: (value: unknown) => ReactElement;
}

const ReactTableBody: React.FC<React.PropsWithChildren<Props>> = ({
  rows,
  isScrollable = false,
  prepareRow,
  spannedCellRenderer,
  onRowClick,
  ...otherProps
}) => {
  const history = useHistory();
  const handleVisitURL = (url: string) => {
    history.push(url);
  };

  const rowSpanMap = {};
  rows.forEach((row) => {
    prepareRow(row);
    const spannedRowValue = row.cells[0].value;
    rowSpanMap[spannedRowValue] = rowSpanMap[spannedRowValue]
      ? rowSpanMap[spannedRowValue] + 1
      : 1;
  });

  return (
    <Tbody {...otherProps}>
      {rows.map((row, i) => {
        prepareRow(row);
        // We need the length of the subRow Array to determine if this row is the last subRow
        // First we check if there is a depth (if it's a subrow)
        // We need the row that has the subrows array in it's values (the expandable 'parent' row)
        // so we take the current index of all the rows, we then look at the index of the subrow
        // If the index of this loop (all rows) is 7 and the subrow index is 2, you have to do: 7 - 3 (3 because index 2 === the 3th subrow), then you have the row (index 4) that contains the subrow array. Because you now know index 5, 6, 7 are subrows
        const subRowLength = row.depth
          ? rows[i - (row.index + 1)]?.subRows?.length
          : 0;
        const isLastOfSubRows = row.index === subRowLength - 1;
        return (
          <ReactTableBodyRow
            key={`TableBodyRow_${i}`}
            row={row}
            handleVisitURL={handleVisitURL}
            isScrollable={isScrollable}
            spannedCellRenderer={spannedCellRenderer}
            isLastSubRow={!!row.depth && isLastOfSubRows}
            previousRow={rows[i - 1]}
            rowSpanMap={rowSpanMap}
            {...(onRowClick && { onClick: () => onRowClick(row.index) })}
          />
        );
      })}
    </Tbody>
  );
};

export default ReactTableBody;
