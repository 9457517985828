import React from 'react';
import { Flex, Box } from '@wegroup/design-system';
import Avatar from '../../../../../../../sales-conversation/src/components/default/ProdFacAvatar';

const SmallToolbar: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Flex
      justifyContent={{ base: 'space-between', tablet: 'space-between' }}
      alignItems="center"
      direction={{ tablet: 'column' }}
      bg={{ tablet: 'secondary.800' }}
      flexShrink={0}
      py={{ base: '2', tablet: '6' }}
      px={{ base: '3', tablet: '6' }}
      borderTopRightRadius={{ tablet: '20px' }}
      zIndex="1"
    >
      <Box
        display={{ base: 'none', tablet: 'block' }}
        w="12"
        h="12"
        sx={{ img: { w: '100%', h: '100%' } }}
      >
        <Avatar />
      </Box>
      {children}
    </Flex>
  );
};

export default SmallToolbar;
