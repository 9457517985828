import React, { PropsWithChildren } from 'react';
import { Redirect, useLocation } from 'react-router';
import LoadingPage from '../../../pages/LoadingPage';
import { isSocialLoginUrl } from '../../authentication/constants/SocialAuth';
import useAuth from '../../authentication/hooks/useAuth';

interface Props {
  // TODO: Remove for react-router v6 migration
  noLoading?: boolean;
}

const BrokerRoute: React.FC<PropsWithChildren<Props>> = ({
  children,
  noLoading = false,
}) => {
  const {
    isAuthorized,
    isReturningFromSSO,
    isChangingBroker,
    isAllBrokerDataFetched,
  } = useAuth();
  const location = useLocation();

  if (isReturningFromSSO || isChangingBroker)
    return noLoading ? null : <LoadingPage />;

  if (!isAuthorized || isSocialLoginUrl) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  if (!isAllBrokerDataFetched) return noLoading ? null : <LoadingPage />;

  // TODO: React router v6 migration: move `BrokerProvider` to here
  return <>{children}</>;
};

export default BrokerRoute;
