import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyDryRot: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M15 8.5v11.28a.5.5 0 00.17.37l3 2.65a.5.5 0 00.51.09l2.1-.8a.48.48 0 01.45.05l2 1.34a.5.5 0 00.78-.41V8.5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.51.5zM21.41 25.14l2.25.75a.5.5 0 01.34.47V41.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5V27.62a.501.501 0 01.61-.48l3.51.78a.49.49 0 00.55-.27l1.14-2.26a.5.5 0 01.6-.25zM26 8.5v15.13a.51.51 0 00.36.48l2.6.77a.49.49 0 00.59-.28l1.85-4.09a.489.489 0 01.71-.22l2.14 1.26a.5.5 0 00.75-.43V8.5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5zM32.54 24.5l2.37 3.37a.5.5 0 01.09.29V41.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5V26.67a.5.5 0 01.64-.48l2.44.72a.49.49 0 00.51-.14l2.18-2.32a.499.499 0 01.77.05z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyDryRot;
