import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsArrowDownRight: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M8.8 7a1.8 1.8 0 011.8 1.8v14.954a5.677 5.677 0 005.677 5.677h20.577l-6.896-6.897a1.8 1.8 0 112.546-2.545l9.969 9.969a1.8 1.8 0 010 2.546l-9.97 9.969a1.8 1.8 0 01-2.545-2.546l6.896-6.896H16.277A9.277 9.277 0 017 23.754V8.8A1.8 1.8 0 018.8 7z"
    />
  </Icon>
);

export default GeneralActionsArrowDownRight;
