import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  HStack,
} from '@wegroup/design-system';
import React from 'react';
import { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FeedbackFormValues } from '../../hooks/useLouiseChatFeedbackModal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (feedback: string) => void;
  register: UseFormRegister<FeedbackFormValues>;
  handleSubmit: UseFormHandleSubmit<FeedbackFormValues>;
}

const ChatFeedbackModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  register,
  handleSubmit,
}) => {
  const { t } = useTranslation();

  const handleClick = handleSubmit((values) => {
    onConfirm(values.feedback);
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('TOOLKIT_INSURANCE-TERMS-BOT_ANSWER_FEEDBACK_MODAL_TITLE')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>
              {t(
                'TOOLKIT_INSURANCE-TERMS-BOT_ANSWER_FEEDBACK_MODAL_TEXTAREA_LABEL',
              )}
            </FormLabel>
            <Textarea
              {...register('feedback')}
              rows={4}
              placeholder={t(
                'TOOLKIT_INSURANCE-TERMS-BOT_ANSWER_FEEDBACK_MODAL_TEXTAREA_PLACEHOLDER',
              )}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter as={HStack} spacing="2">
          <Button variant="ghost" onClick={onClose}>
            {t('CLOSE')}
          </Button>
          <Button onClick={handleClick}>
            {t('TOOLKIT_INSURANCE-TERMS-BOT_ANSWER_FEEDBACK_MODAL_SEND')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChatFeedbackModal;
