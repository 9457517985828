const deepPurple = {
  primary: {
    main: '#6D1C74',
    50: '#FAF4FB',
    100: '#F9EAFA',
    150: '#F6E2F8',
    200: '#F2D6F5',
    300: '#E6ADEB',
    400: '#D678DF',
    500: '#6D1C74',
    600: '#5E1864',
    700: '#4E1453',
    750: '#46124B',
    800: '#3F1043',
    850: '#370E3A',
    900: '#2F0C32',
  },
  secondary: {
    main: '#EFC9F2',
    50: '#E6AEEB',
    100: '#DE93E5',
    150: '#D678DF',
    200: '#D678DF',
    300: '#D168DB',
    400: '#9F29A9',
    500: '#6D1C74',
    600: '#56165B',
    700: '#3F1043',
    750: '#330D36',
    800: '#280A2A',
    850: '#1C071E',
    900: '#100411',
  },
};

export default deepPurple;
