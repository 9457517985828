import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSecondResidence: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3938_10105"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3938_10105)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M103.862 121.205l-46.447 46.443v59.362H150.304v-59.362l-46.442-46.443z"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M150.304 116.556a4.764 4.764 0 00-4.763-4.763h-7.872a4.764 4.764 0 00-4.763 4.763v9.422l17.415 17.211-.017-26.633z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M165.169 157.884l-53.145-52.53a11.614 11.614 0 00-16.329 0l-53.145 52.53a8.708 8.708 0 0012.315 12.315l48.997-48.993 48.992 48.993a8.708 8.708 0 0012.315-12.315z"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M112.559 155.352a8.716 8.716 0 01-5.379 8.049 8.707 8.707 0 01-11.878-6.352 8.709 8.709 0 013.708-8.942 8.708 8.708 0 0110.998 1.087 8.71 8.71 0 012.551 6.158zM115.471 181.457h23.221v23.221h-23.221v-23.221z"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M115.471 193.068h23.221"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M98.053 191.372a11.614 11.614 0 10-23.221 0v23.221h23.221v-23.221z"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeWidth="4"
          d="M70.173 218.637a5 5 0 015-5H99.06a5 5 0 015 5v7.237H70.173v-7.237z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M218.037 64.123l-71.302 71.296v91.131h142.599v-91.131l-71.297-71.296z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M289.334 56.984a7.313 7.313 0 00-7.312-7.312h-12.085a7.313 7.313 0 00-7.311 7.312v14.464L289.36 97.87l-.026-40.886z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M312.153 120.43l-81.586-80.642a17.826 17.826 0 00-25.066 0l-81.586 80.642a13.367 13.367 0 0018.904 18.904l75.218-75.211 75.212 75.211a13.367 13.367 0 0018.904-18.904z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M231.385 116.542a13.376 13.376 0 01-15.986 13.116 13.37 13.37 0 01-10.506-10.511 13.38 13.38 0 015.693-13.728 13.372 13.372 0 0120.799 11.123zM235.858 156.617h35.648v35.648h-35.648v-35.648z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M235.858 174.443h35.648"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M209.124 171.84a17.83 17.83 0 00-17.824-18.172 17.838 17.838 0 00-12.727 5.344 17.83 17.83 0 00-5.097 12.828v35.648h35.648V171.84z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M165.252 209.947a5 5 0 015-5h44.162a5 5 0 015 5v15.927h-54.162v-15.927z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationSecondResidence;
