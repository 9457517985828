import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSport: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3805_10466"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3805_10466)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <circle
          cx="193.5"
          cy="92.5"
          r="51.5"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <mask
          id="mask1_3805_10466"
          style={{ maskType: 'alpha' }}
          width="107"
          height="107"
          x="140"
          y="39"
          maskUnits="userSpaceOnUse"
        >
          <circle
            cx="193.5"
            cy="92.5"
            r="51.5"
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_3805_10466)"
        >
          <path
            fill={colors.primary[100]}
            d="M182.212 79.17l11.288-7.942 11.288 7.941 11.04 8.282-4.063 13.189-4.465 13.06-13.8.21-13.8-.21-4.465-13.06-4.063-13.189 11.04-8.282zM204.788 43.507l-11.288 7.94-11.288-7.94-11.04-8.282 4.063-13.19L179.7 8.976l13.8-.21 13.8.21 4.465 13.06 4.063 13.189-11.04 8.282zM266.01 88.18l-11.288 7.941-11.289-7.94-11.04-8.282 4.064-13.19 4.465-13.059 13.8-.21 13.799.21 4.465 13.06 4.064 13.189-11.04 8.281zM242.293 159.882l-11.288 7.94-11.288-7.94-11.04-8.282 4.063-13.19 4.465-13.059 13.8-.211 13.8.211 4.465 13.059 4.063 13.19-11.04 8.282zM166.731 160.434l-11.288 7.941-11.288-7.941-11.04-8.281 4.064-13.19 4.465-13.059 13.799-.211 13.8.211 4.465 13.059 4.064 13.19-11.041 8.281zM143.566 87.63l-11.288 7.94-11.288-7.94-11.04-8.282 4.064-13.19 4.465-13.059 13.799-.21 13.8.21 4.465 13.06 4.064 13.189-11.041 8.282z"
          ></path>
          <circle cx="193.5" cy="92.5" r="51.5"></circle>
        </g>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M193.5 52.514v17.925M216.665 87.26l14.892-6.895M208.392 114.562l7.997 9.928M179.16 114.562l-9.377 10.756M170.335 87.26l-14.892-7.446"
        ></path>
        <circle
          cx="122.5"
          cy="138.5"
          r="68.5"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <mask
          id="mask2_3805_10466"
          style={{ maskType: 'alpha' }}
          width="141"
          height="141"
          x="52"
          y="68"
          maskUnits="userSpaceOnUse"
        >
          <circle
            cx="122.5"
            cy="138.5"
            r="68.5"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask2_3805_10466)"
        >
          <circle
            cx="43.132"
            cy="138.944"
            r="51.208"
            fill={colors.primary[100]}
          ></circle>
          <circle
            cx="201.868"
            cy="138.944"
            r="51.208"
            fill={colors.primary[100]}
          ></circle>
          <circle cx="122.5" cy="138.5" r="68.5"></circle>
        </g>
        <path
          fill={colors.primary[500]}
          d="M121.17 68H124.717V209H121.17z"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M52.443 140.717H55.989999999999995V281.717H52.443z"
          transform="rotate(-90 52.443 140.717)"
        ></path>
        <circle
          cx="249.5"
          cy="171.5"
          r="35.5"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M249.5 137c0 31-31 35.5-35 35.5M249 206.5c0-31 31-35.5 35-35.5"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationSport;
