import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationEmigrate: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3944_10704"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3944_10704)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <rect
          width="233.11"
          height="153.19"
          x="43"
          y="73.637"
          fill="#fff"
          rx="2"
        ></rect>
        <mask id="path-4-inside-1_3944_10704" fill="#fff">
          <rect
            width="30"
            height="30"
            x="72.672"
            y="101"
            rx="2"
            transform="rotate(20.834 72.672 101)"
          ></rect>
        </mask>
        <rect
          width="30"
          height="30"
          x="72.672"
          y="101"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-4-inside-1_3944_10704)"
          rx="2"
          transform="rotate(20.834 72.672 101)"
        ></rect>
        <circle
          cx="87.502"
          cy="147.5"
          r="18.5"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="239.502"
          cy="135.5"
          r="18.5"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M158.27 173c.77-1.333 2.694-1.333 3.464 0l13.856 24c.77 1.333-.192 3-1.732 3h-27.712c-1.54 0-2.502-1.667-1.733-3l13.857-24z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M142.902 47h33.304a13.32 13.32 0 0113.32 13.32h-59.944A13.32 13.32 0 01142.902 47zM196.19 73.64h33.3V226.83h-33.3V73.641z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M206.182 100.285a6.66 6.66 0 1013.32-.001 6.66 6.66 0 00-13.32.001zM206.182 200.189a6.66 6.66 0 1013.321 0 6.66 6.66 0 00-13.321 0z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M89.618 73.64h33.3V226.83h-33.3V73.641z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M99.61 100.285a6.66 6.66 0 1013.32-.001 6.66 6.66 0 00-13.32.001zM99.61 200.189a6.66 6.66 0 1013.321 0 6.66 6.66 0 00-13.32 0zM118.658 137.048a3.334 3.334 0 004.132 2.264l25.572-7.46a3.331 3.331 0 002.268-4.128l-8-27.448a3.316 3.316 0 00-1.6-2l-9.968-5.468a3.336 3.336 0 00-2.536-.276l-10.14 2.956a3.33 3.33 0 00-2 1.6l-5.456 9.976a3.335 3.335 0 00-.276 2.536l8.004 27.448z"
        ></path>
        <rect
          width="233.11"
          height="153.19"
          x="43"
          y="73.637"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M189.526 60.32v13.32M129.582 60.32v13.32M126.25 103.613s-12.052-37.34-3.328-41.72a5.728 5.728 0 016.66 1.26"
        ></path>
        <rect
          width="64"
          height="84"
          x="248"
          y="142"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M264 165.5h33"
        ></path>
        <circle
          cx="280"
          cy="192"
          r="11"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationEmigrate;
