export enum CustomerSegmentGoal {
  COMMERCIAL_LINES = 'commercial-lines',
  PERSONAL_LINES = 'personal-lines',
}

export interface GoalItem {
  titleKey: string;
  illustration: JSX.Element;
  value: CustomerSegmentGoal;
}
