import { createContext } from 'react';
import { ReactReduxContextValue } from 'react-redux';
import rootReducer from '../redux/reducers/rootReducer';
import campaignsStore from '../redux/store';

const CampaignsContext = createContext<ReactReduxContextValue>({
  store: campaignsStore,
  storeState: rootReducer,
});
export default CampaignsContext;
