import React, { useRef } from 'react';
import { Box } from '@wegroup/design-system';
import { chakraCustomScrollBar } from '../../../../../common/src/utils/styledComponentsUtils';
import useResizeObserver from 'use-resize-observer';

interface Props {
  isScrollable: boolean;
  setIsScrollable: {
    on: () => void;
    off: () => void;
    toggle: () => void;
  };
  isEmpty?: boolean;
}

const ReactTableContainer: React.FC<React.PropsWithChildren<Props>> = ({
  isScrollable,
  setIsScrollable,
  children,
  isEmpty,
  ...otherProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { ref: sizeRef } = useResizeObserver<HTMLDivElement>({
    onResize: ({ width }) => {
      if (ref.current && width && width < ref.current?.scrollWidth) {
        if (!isScrollable) {
          setIsScrollable.on();
        }
      } else if (isScrollable) {
        setIsScrollable.off();
      }
    },
  });

  return (
    <div ref={sizeRef}>
      <Box
        ref={ref}
        border="1px"
        borderRadius="md"
        borderBottomLeftRadius={isEmpty ? 'none' : 'md'}
        borderBottomRightRadius={isEmpty ? 'none' : 'md'}
        borderBottom={isEmpty ? 'none' : '1px'}
        borderColor="gray.50"
        sx={{
          ...chakraCustomScrollBar,
          '&::-webkit-scrollbar:horizontal': {
            height: '12px',
          },
        }}
        position="relative"
        overflowX="auto"
        {...otherProps}
      >
        {children}
      </Box>
    </div>
  );
};

export default ReactTableContainer;
