import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationHouselhold: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_4222_10793"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4222_10793)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M240.763 65.393H247.137V183.659H240.763z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M242.467 179.732h-5.928v18.526l-1.807 7.681a4 4 0 003.894 4.916h10.647a4 4 0 003.894-4.916l-1.807-7.681v-18.526H242.467z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M236.539 179.732v-2a2 2 0 00-2 2h2zm0 18.526l1.947.458c.036-.15.053-.304.053-.458h-2zm-1.807 7.681l-1.947-.459 1.947.459zm18.435 0l-1.947.458 1.947-.458zm-1.807-7.681h-2c0 .154.017.308.053.458l1.947-.458zm0-18.526h2a2 2 0 00-2-2v2zm-14.821 2h5.928v-4h-5.928v4zm2 16.526v-18.526h-4v18.526h4zm-1.86 8.139l1.807-7.681-3.893-.916-1.808 7.68 3.894.917zm1.947 2.458a2 2 0 01-1.947-2.458l-3.894-.917c-.886 3.767 1.972 7.375 5.841 7.375v-4zm3.841 0h-3.841v4h3.841v-4zm0 4h1.482v-4h-1.482v4zm1.482 0h5.324v-4h-5.324v4zm5.324 0c3.869 0 6.727-3.608 5.841-7.375l-3.894.917a2 2 0 01-1.947 2.458v4zm5.841-7.375l-1.808-7.68-3.893.916 1.807 7.681 3.894-.917zm-1.754-7.222v-18.526h-4v18.526h4zm-2-20.526h-7.411v4h7.411v-4zm-7.411 0h-1.482v4h1.482v-4z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M268.266 204.186h-49.365c-1.74 0-3.393.75-4.497 2.095-2.779 3.386-7.877 9.929-7.875 12.354.002 3.097 4.816 6.299 4.816 6.299h65.209s4.446-3.288 4.446-6.299c0-2.172-5.371-8.919-8.242-12.37-1.109-1.334-2.757-2.079-4.492-2.079z"
        ></path>
        <mask
          id="mask1_4222_10793"
          style={{ maskType: 'alpha' }}
          width="79"
          height="25"
          x="204"
          y="202"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinecap="square"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M268.263 204.186h-49.364c-1.74 0-3.393.75-4.497 2.095-2.78 3.386-7.878 9.929-7.876 12.354.003 3.097 4.817 6.299 4.817 6.299h65.209s4.446-3.288 4.446-6.299c0-2.172-5.371-8.919-8.242-12.37-1.11-1.334-2.758-2.079-4.493-2.079z"
          ></path>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_4222_10793)"
        >
          <path
            fill={colors.primary[200]}
            d="M202.969 200.258H287.149V217.006H202.969z"
          ></path>
          <path
            strokeLinecap="square"
            strokeLinejoin="round"
            d="M268.263 204.186h-49.364c-1.74 0-3.393.75-4.497 2.095-2.78 3.386-7.878 9.929-7.876 12.354.003 3.097 4.817 6.299 4.817 6.299h65.209s4.446-3.288 4.446-6.299c0-2.172-5.371-8.919-8.242-12.37-1.11-1.334-2.758-2.079-4.493-2.079z"
          ></path>
        </g>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M210.709 34.582c-2.582 2.495-4.328 6.31-4.328 11.398v55.204c0 1.531-.57 3.006-1.6 4.138l-17.612 19.374a2 2 0 01-2.825.135l-6.143-5.585a2 2 0 01-.135-2.825l14.453-15.898a6 6 0 001.56-4.036V45.98c0-8.019 2.824-15.164 8.081-20.244 5.256-5.08 12.494-7.66 20.504-7.393 7.661.254 14.581 2.931 19.624 7.903 5.072 5 7.812 11.9 7.812 19.734v19.712a2 2 0 01-2 2h-8.302a2 2 0 01-2-2V45.98c0-4.883-1.653-8.515-4.147-10.974-2.523-2.487-6.346-4.2-11.395-4.368-5.088-.169-8.964 1.448-11.547 3.944z"
        ></path>
        <mask id="path-11-inside-1_4222_10793" fill="#fff">
          <path
            fillRule="evenodd"
            d="M133.921 144.837a12.54 12.54 0 00-7.05-2.156h-21.49c-6.957 0-12.597 5.64-12.597 12.597s5.64 12.597 12.597 12.597h21.49a12.54 12.54 0 007.05-2.156c-4.258 11.636-15.429 19.94-28.54 19.94-16.779 0-30.381-13.602-30.381-30.381s13.602-30.382 30.381-30.382c13.111 0 24.282 8.305 28.54 19.941z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M133.921 144.837a12.54 12.54 0 00-7.05-2.156h-21.49c-6.957 0-12.597 5.64-12.597 12.597s5.64 12.597 12.597 12.597h21.49a12.54 12.54 0 007.05-2.156c-4.258 11.636-15.429 19.94-28.54 19.94-16.779 0-30.381-13.602-30.381-30.381s13.602-30.382 30.381-30.382c13.111 0 24.282 8.305 28.54 19.941z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M133.921 144.837l-2.241 3.313 10.251 6.936-4.254-11.623-3.756 1.374zm0 20.882l3.756 1.374 4.254-11.623-10.252 6.936 2.242 3.313zm-7.05-19.038c1.788 0 3.437.541 4.809 1.469l4.483-6.626a16.538 16.538 0 00-9.292-2.843v8zm-21.49 0h21.49v-8h-21.49v8zm-8.597 8.597a8.597 8.597 0 018.597-8.597v-8c-9.166 0-16.597 7.431-16.597 16.597h8zm8.597 8.597a8.597 8.597 0 01-8.597-8.597h-8c0 9.166 7.43 16.597 16.597 16.597v-8zm21.49 0h-21.49v8h21.49v-8zm4.808-1.469a8.541 8.541 0 01-4.808 1.469v8c3.435 0 6.639-1.048 9.291-2.843l-4.483-6.626zm-1.514 1.938c-3.7 10.11-13.405 17.315-24.784 17.315v8c14.843 0 27.48-9.404 32.296-22.566l-7.512-2.749zm-24.784 17.315c-14.57 0-26.381-11.811-26.381-26.381h-8c0 18.988 15.393 34.381 34.381 34.381v-8zM79 155.278c0-14.57 11.811-26.382 26.381-26.382v-8C86.393 120.896 71 136.29 71 155.278h8zm26.381-26.382c11.379 0 21.084 7.206 24.784 17.316l7.512-2.749c-4.816-13.162-17.453-22.567-32.296-22.567v8z"
          mask="url(#path-11-inside-1_4222_10793)"
        ></path>
        <mask id="path-13-inside-2_4222_10793" fill="#fff">
          <path
            fillRule="evenodd"
            d="M148.54 64.875c-8.284 0-15 6.716-15 15V98.22h10.374V79.25a4 4 0 014-4h14.971a4 4 0 014 4v18.97h10.374V79.875c0-8.284-6.715-15-15-15H148.54z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M148.54 64.875c-8.284 0-15 6.716-15 15V98.22h10.374V79.25a4 4 0 014-4h14.971a4 4 0 014 4v18.97h10.374V79.875c0-8.284-6.715-15-15-15H148.54z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M133.54 98.22h-4v4h4v-4zm10.374 0v4h4v-4h-4zm22.971 0h-4v4h4v-4zm10.374 0v4h4v-4h-4zM137.54 79.875c0-6.075 4.925-11 11-11v-8c-10.493 0-19 8.507-19 19h8zm0 18.345V79.875h-8V98.22h8zm6.374-4H133.54v8h10.374v-8zm4 4V79.25h-8v18.97h8zm0-18.97v-8a8 8 0 00-8 8h8zm0 0h14.971v-8h-14.971v8zm14.971 0h8a8 8 0 00-8-8v8zm0 0v18.97h8V79.25h-8zm14.374 14.97h-10.374v8h10.374v-8zm-4-14.345V98.22h8V79.875h-8zm-11-11c6.076 0 11 4.925 11 11h8c0-10.493-8.506-19-19-19v8zm-13.719 0h13.719v-8H148.54v8z"
          mask="url(#path-13-inside-2_4222_10793)"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M126.858 88.875l-11.361 21.64c-1.398 2.664.533 5.86 3.542 5.86h72.944c3.043 0 4.971-3.262 3.505-5.928l-11.902-21.64a4 4 0 00-3.505-2.073H130.4a4 4 0 00-3.542 2.141z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M139.245 113.221c0-7.18 5.82-13 13-13h6.309c7.18 0 13 5.82 13 13v8.194h-32.309v-8.194z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M111.827 112.818H198.971V205.149H111.827z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M111.827 112.818H198.971V125.86099999999999H111.827z"
        ></path>
        <mask id="path-19-inside-3_4222_10793" fill="#fff">
          <rect
            width="15.561"
            height="22.23"
            x="111.309"
            y="204.926"
            rx="2"
          ></rect>
        </mask>
        <rect
          width="15.561"
          height="22.23"
          x="111.309"
          y="204.926"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-19-inside-3_4222_10793)"
          rx="2"
        ></rect>
        <mask id="path-20-inside-4_4222_10793" fill="#fff">
          <rect
            width="15.561"
            height="22.23"
            x="183.187"
            y="204.926"
            rx="2"
          ></rect>
        </mask>
        <rect
          width="15.561"
          height="22.23"
          x="183.187"
          y="204.926"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-20-inside-4_4222_10793)"
          rx="2"
        ></rect>
        <mask id="path-21-inside-5_4222_10793" fill="#fff">
          <rect
            width="101.518"
            height="18.525"
            x="104.64"
            y="193.07"
            rx="2"
          ></rect>
        </mask>
        <rect
          width="101.518"
          height="18.525"
          x="104.64"
          y="193.07"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-21-inside-5_4222_10793)"
          rx="2"
        ></rect>
        <rect
          width="10.82"
          height="27.863"
          x="105.899"
          y="112.818"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <rect
          width="10.82"
          height="27.863"
          x="193.338"
          y="112.818"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationHouselhold;
