import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsSendEmail: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M44.414 5.586a2 2 0 010 2.828L22.8 30.03a2 2 0 01-2.828-2.828L41.586 5.586a2 2 0 012.828 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M44.414 5.586a2 2 0 01.467 2.093L32.11 43.05a3.081 3.081 0 01-5.646.193l-6.568-13.137-13.136-6.568a3.08 3.08 0 01.192-5.646L42.321 5.12a2 2 0 012.093.467zm-34 15.307l11.866 5.933a2 2 0 01.894.894l5.933 11.865 10.565-29.257-29.257 10.565z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsSendEmail;
