import React, { PropsWithChildren } from 'react';
import { Box } from '@wegroup/design-system';
import ReactTableBodyCell from './ReactTableBodyCell';

interface Props {
  spanCount: number;
}

const ReactTableSpannedRow: React.FC<PropsWithChildren<Props>> = ({
  spanCount,
  children,
}) => {
  return (
    <ReactTableBodyCell
      colSpan={spanCount}
      isSticky={false}
      position="relative"
      pt="0 !important"
    >
      {/* This overlaps the borderbottom of the row above this row, so it looks like there is no border */}
      <Box
        position="absolute"
        top="-1px"
        left="0"
        height="1px"
        w="100%"
        bg="white"
      />
      {children}
    </ReactTableBodyCell>
  );
};

export default ReactTableSpannedRow;
