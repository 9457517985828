import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsBudget: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.958 10.473C25.47 11.366 25 12.322 25 13c0 .678.47 1.634 1.958 2.528C28.408 16.398 30.54 17 33 17s4.593-.603 6.042-1.472C40.532 14.634 41 13.678 41 13c0-.678-.469-1.634-1.958-2.527C37.592 9.603 35.462 9 33 9c-2.461 0-4.592.603-6.042 1.473zM24.9 7.043C27.07 5.74 29.94 5 33 5c3.062 0 5.93.74 8.1 2.043C43.23 8.32 45 10.365 45 13c0 2.636-1.77 4.68-3.9 5.958C38.93 20.26 36.062 21 33 21c-3.061 0-5.93-.74-8.1-2.042C22.77 17.68 21 15.636 21 13c0-2.635 1.77-4.68 3.9-5.957z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 11a2 2 0 012 2v8c0 .678.47 1.634 1.959 2.527C28.408 24.398 30.538 25 33 25c2.461 0 4.592-.603 6.042-1.473C40.532 22.634 41 21.678 41 21v-8a2 2 0 114 0v8c0 2.635-1.77 4.68-3.9 5.957C38.93 28.26 36.06 29 33 29c-3.06 0-5.93-.74-8.1-2.043C22.77 25.68 21 23.636 21 21v-8a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 19a2 2 0 012 2v8c0 .678.47 1.634 1.959 2.527C28.408 32.397 30.538 33 33 33c2.461 0 4.592-.602 6.042-1.472C40.532 30.634 41 29.677 41 29v-8a2 2 0 114 0v8c0 2.635-1.77 4.68-3.9 5.957C38.93 36.26 36.06 37 33 37c-3.06 0-5.93-.74-8.1-2.042C22.77 33.68 21 31.635 21 29v-8a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23 27a2 2 0 012 2v8c0 .678.47 1.634 1.959 2.528C28.408 40.398 30.538 41 33 41c2.461 0 4.592-.603 6.042-1.472C40.532 38.634 41 37.678 41 37v-8a2 2 0 114 0v8c0 2.635-1.77 4.68-3.9 5.958C38.93 44.259 36.06 45 33 45c-3.06 0-5.93-.74-8.1-2.042C22.77 41.68 21 39.635 21 37v-8a2 2 0 012-2zM6.464 18.464A5 5 0 0110 17h5a2 2 0 110 4h-5a1 1 0 000 2h2a5 5 0 110 10H7a2 2 0 110-4h5a1 1 0 100-2h-2a5 5 0 01-3.536-8.536z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 15a2 2 0 012 2v2a2 2 0 01-4 0v-2a2 2 0 012-2zm0 14a2 2 0 012 2v2a2 2 0 01-4 0v-2a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsBudget;
