import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import GoalsModalBody from './GoalsModalBody';
import { CustomerSegmentGoal } from '../../../../dashboard/src/types/goals';
import { useMutation, useQueryClient } from 'react-query';
import { patchUserMetaData } from '../../../../common/src/legacy/services/apiRouterService';
import { useClickOutside } from '@mantine/hooks';
import useUserpilot from '../../../../common/src/hooks/tracking/useUserpilot';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const GoalsModal: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { update } = useUserpilot();
  const queryClient = useQueryClient();

  const toast = useToast();

  const ref = useClickOutside(() => {
    const toastId = 'DASHBOARD_NEW-GOALS_MODAL-FOOTER_BUTTON-TOOLTIP-TEXT';

    if (!toast.isActive(toastId))
      toast({
        id: toastId,
        title: t('DASHBOARD_NEW-GOALS_MODAL-FOOTER_BUTTON-TOOLTIP-TEXT'),
        isClosable: true,
        duration: 7500,
        position: 'top',
        status: 'warning',
      });
  });

  const { mutateAsync: patchGoals, isLoading: isPatchingGoals } = useMutation(
    async (goal: CustomerSegmentGoal) => {
      const [, status] = await patchUserMetaData({
        metadata: { customer_segment: goal },
      });
      if (status >= 400) throw new Error();

      update({
        focus_on_commercial_lines:
          goal === CustomerSegmentGoal.COMMERCIAL_LINES,
        focus_on_private_lines: goal === CustomerSegmentGoal.PERSONAL_LINES,
      });
    },
    {
      onError: () => onClose(),
    },
  );

  const handleOnChange = async (goal: CustomerSegmentGoal) => {
    await patchGoals(goal);
    queryClient.invalidateQueries(['user', 'data']);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={() => null} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent ref={ref} maxW="860px">
        <ModalHeader>{t('CUSTOMER-SEGMENT')}</ModalHeader>
        <GoalsModalBody onChange={handleOnChange} isLoading={isPatchingGoals} />
      </ModalContent>
    </Modal>
  );
};

export default GoalsModal;
