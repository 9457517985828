import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyPlayroom: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M40.69 25.77c1.2-2.74-2.04-5.41-3.8-6.62-.26-.15-.11-.62.18-.57 4 .6 6.88 3.88 6.88 6.32.05 3.1-1.942 2.133-3.138 1.249a.3.3 0 01-.122-.379zM5.09 36.41c5.87 6.06 15.3 8.26 23.43 7.36 5.44-.6 15.1-2.54 17.08-8.87.58-1.84-2.31-2.63-2.89-.79-1.77 5.66-13 6.82-17.49 6.88-6.29.1-13.51-2.06-18-6.7-1.35-1.39-3.47.71-2.13 2.12z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M38 24.48a8.822 8.822 0 00.48 4.07c.9 2.45 1.64 6.34.99 6.99-.47.47-1.172-.79-2.002-2.28-.316-.568-.65-1.169-.998-1.72-.78-1.23-2.91-3.16-4.36-3.34l-3.25-.41a21.32 21.32 0 00-4.79-.06l-4.67.47c-1.178.116-1.62.712-2.333 1.676a19.3 19.3 0 01-.997 1.264c-.585.671-1.16 1.499-1.685 2.254-.804 1.157-1.491 2.146-1.915 2.146-1 0-2-2 2-8 1.88-2.82 1.77-4.97 1.13-6.47a2.38 2.38 0 00-3.47-1 6.32 6.32 0 01-4.66.48c-5-1 3-10 3-10L9 9.08a.56.56 0 01.24-.92 11.74 11.74 0 014.3-.61c4.68-.54 5.93 4 6.17 7a4.32 4.32 0 001.95 3.3v2.221a5.25 5.25 0 005.239 5.239h.512a5.26 5.26 0 005.249-5.238V18.55c5.198.063 5.637 3.58 5.34 5.93zm-27.173-8.923a1.5 1.5 0 101.667-2.495 1.5 1.5 0 00-1.667 2.495z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      d="M31.66 18.55H24a4.322 4.322 0 01-1.34-.219v1.738a4.25 4.25 0 004.24 4.241h.51a4.26 4.26 0 004.25-4.241V18.55z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyPlayroom;
