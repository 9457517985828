import React from 'react';
import { Box, Skeleton, Flex } from '@wegroup/design-system';
import GeneralPageLayout from '../../../../common/src/components/GeneralPageLayout';
import useHasVisitedModule from '../../../../common/src/hooks/useHasVisitedModule';
import LoadingSpinner from '../../../../common/src/components/LoadingSpinner';

const ToolkitLoading: React.FC = () => {
  const hasVisited = useHasVisitedModule('toolkit');
  if (!hasVisited) return <LoadingSpinner />;
  return (
    <GeneralPageLayout>
      <Skeleton w="160px" h="45px" />
      <Box mt="16">
        <ToolkitTitle />
        <Flex gridGap="8" mt="4" mb="14">
          {Array.from({ length: 2 }, (i: number) => (
            <ToolkitItem key={i} />
          ))}
        </Flex>
        <ToolkitTitle />
        <Flex gridGap="8" mt="4" mb="14">
          {Array.from({ length: 2 }, (i: number) => (
            <ToolkitItem key={i} />
          ))}
        </Flex>
        <ToolkitTitle />
        <Flex gridGap="8" mt="4" mb="14">
          {Array.from({ length: 3 }, (i: number) => (
            <ToolkitItem key={i} />
          ))}
        </Flex>
      </Box>
    </GeneralPageLayout>
  );
};

const ToolkitItem = () => {
  return <Skeleton w="180px" h="120px" />;
};

const ToolkitTitle = () => {
  return <Skeleton w="180px" h="25px" />;
};

export default ToolkitLoading;
