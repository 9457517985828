import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceMotoCasco: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_1864_6947"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_1864_6947)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M154.901 142.041a2 2 0 00-2.361 1.558l-8.5 41.5a2.001 2.001 0 003.919.803l8.5-41.5a2 2 0 00-1.558-2.361zM121.894 144.212a2 2 0 00-2.683.894l-16 32a2 2 0 103.577 1.789l16-32a2 2 0 00-.894-2.683z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M223 191c0 18.778 15.222 34 34 34s34-15.222 34-34-15.222-34-34-34-34 15.222-34 34z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M257 221c-16.569 0-30-13.431-30-30 0-16.569 13.431-30 30-30 16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30zm0 4c-18.778 0-34-15.222-34-34s15.222-34 34-34 34 15.222 34 34-15.222 34-34 34z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M238.692 191c0 10.111 8.197 18.308 18.308 18.308s18.308-8.197 18.308-18.308-8.197-18.308-18.308-18.308-18.308 8.197-18.308 18.308z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M257 205.308c-7.902 0-14.308-6.406-14.308-14.308s6.406-14.308 14.308-14.308 14.308 6.406 14.308 14.308-6.406 14.308-14.308 14.308zm0 4c-10.111 0-18.308-8.197-18.308-18.308s8.197-18.308 18.308-18.308 18.308 8.197 18.308 18.308-8.197 18.308-18.308 18.308z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[50]}
          d="M64 191c0 18.778 15.222 34 34 34s34-15.222 34-34-15.222-34-34-34-34 15.222-34 34z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M98 221c-16.569 0-30-13.431-30-30 0-16.569 13.431-30 30-30 16.569 0 30 13.431 30 30 0 16.569-13.431 30-30 30zm0 4c-18.778 0-34-15.222-34-34s15.222-34 34-34 34 15.222 34 34-15.222 34-34 34z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M79.692 191c0 10.111 8.197 18.308 18.308 18.308s18.308-8.197 18.308-18.308-8.197-18.308-18.308-18.308S79.692 180.889 79.692 191z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M98 205.308c-7.902 0-14.308-6.406-14.308-14.308S90.098 176.692 98 176.692s14.308 6.406 14.308 14.308-6.406 14.308-14.308 14.308zm0 4c-10.111 0-18.308-8.197-18.308-18.308S87.89 172.692 98 172.692s18.308 8.197 18.308 18.308-8.197 18.308-18.308 18.308zM225.069 130.73a2 2 0 00-.84 2.701l31 59a2 2 0 003.541-1.861l-31-59a2 2 0 00-2.701-.84z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M259 149a2 2 0 00-2-2c-10.864 0-26.071 4.115-35.083 15.777a2.001 2.001 0 003.166 2.446C233.071 154.885 246.864 151 257 151a2 2 0 002-2zM133.437 144.93c6.808.365 13.974-1.574 21.914-7.147a1.999 1.999 0 112.298 3.274c-8.56 6.008-16.595 8.287-24.426 7.867-7.777-.417-15.141-3.487-22.4-7.784a2 2 0 012.038-3.442c6.997 4.142 13.713 6.864 20.576 7.232z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M144.028 185.834c-5.779-34.191-37.503-47.825-62.953-36.242a2 2 0 11-1.657-3.64c27.814-12.66 62.333 2.406 68.554 39.215a2 2 0 11-3.944.667z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M239 125c-3.5 0-7.5 2-14 7.5 6 5.5 9 8 14 8 3 1.5 3.5-15.5 0-15.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M241.78 124.6c-.506-.741-1.413-1.6-2.78-1.6-4.284 0-8.775 2.459-15.292 7.973a2 2 0 00-.059 3.001c5.778 5.297 9.31 8.399 14.998 8.522a2.69 2.69 0 001.374.017c.657-.165 1.137-.545 1.46-.898.605-.662.962-1.557 1.193-2.33.488-1.628.731-3.816.764-5.942.032-2.133-.144-4.402-.601-6.202-.224-.886-.552-1.801-1.057-2.541zm-3.058 13.897c-3.541-.076-5.893-1.636-10.667-5.924 5.068-4.062 8.121-5.418 10.514-5.56.115.221.257.581.391 1.112.34 1.34.508 3.228.478 5.157-.03 1.936-.255 3.718-.596 4.855a5.385 5.385 0 01-.12.36z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M224 137.591c0-12.103-11.212-21.09-23.025-18.456l-39.684 8.848A14.432 14.432 0 00150 142.069c0 7.971 6.461 14.432 14.432 14.432h40.658c10.444 0 18.91-8.466 18.91-18.91z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M162.161 131.887l39.685-8.848c9.314-2.077 18.154 5.009 18.154 14.552 0 8.235-6.675 14.91-14.91 14.91h-40.658a10.433 10.433 0 01-2.271-20.614zm38.814-12.752c11.813-2.634 23.025 6.353 23.025 18.456 0 10.444-8.466 18.91-18.91 18.91h-40.658c-7.971 0-14.432-6.461-14.432-14.432 0-6.76 4.693-12.615 11.291-14.086l39.684-8.848z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M147 174.818c0-9.721 9.556-16.561 18.757-13.426l23.409 7.975a10.087 10.087 0 01-3.252 19.634h-24.731c-7.833 0-14.183-6.35-14.183-14.183z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M187.876 173.154l-23.409-7.976c-6.606-2.25-13.467 2.661-13.467 9.64 0 5.624 4.559 10.183 10.183 10.183h24.731a6.086 6.086 0 001.962-11.847zm-22.119-11.762c-9.201-3.135-18.757 3.705-18.757 13.426 0 7.833 6.35 14.183 14.183 14.183h24.731a10.087 10.087 0 003.252-19.634l-23.409-7.975z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M195.162 164.213a2 2 0 001.05 2.626l8.626 3.697c-2.564 5.675-6.874 15.21-8.659 19.137-1.104 2.429-2.313 3.524-3.484 4.088-1.251.602-2.729.74-4.695.74h-44.5v4H188c2.034 0 4.306-.113 6.43-1.136 2.204-1.061 3.995-2.966 5.391-6.037 2.001-4.403 7.169-15.838 9.502-21.004a1.999 1.999 0 00-1.035-2.662l-10.5-4.5a2 2 0 00-2.626 1.051zM199.143 106.258a2 2 0 001.114 2.599c2.41.964 8.9 3.913 15.183 7.84 2.711 1.694 4.664 4.606 5.991 7.765 1.319 3.142 1.924 6.307 2.075 8.198a2 2 0 003.988-.319c-.183-2.276-.877-5.86-2.375-9.427-1.49-3.55-3.87-7.304-7.559-9.609-6.518-4.074-13.227-7.125-15.817-8.161a1.999 1.999 0 00-2.6 1.114z"
          clipRule="evenodd"
        ></path>
        <g filter="url(#filter0_dd_1864_6947)">
          <circle cx="233" cy="85" r="55" fill="#fff"></circle>
          <circle
            cx="233"
            cy="85"
            r="53"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M216.768 110.029a1.994 1.994 0 00-.268 1.386l1.394 8.126a2.001 2.001 0 003.911.149l1.71-6.791 27.798-45.168c1.469-2.388 2.702-5.36 2.527-8.436-.184-3.23-1.898-6.217-5.597-8.493-6.09-3.748-11.509-.727-13.631 1.41l-10.701 7.32a2 2 0 00.081 3.354l1.122.69a2 2 0 002.098 0l4.261-2.627-.492 4.915a1.998 1.998 0 00.942 1.902l1.346.83a2 2 0 003.019-1.364l1.021-5.91.986-1.603c2.008-3.262 4.698-3.327 6.236-2.411a3.504 3.504 0 011.652 2.358c.208 1.046.042 2.499-1.045 4.265l-28.37 46.098z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_1864_6947"
          width="142"
          height="142"
          x="162"
          y="22"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1864_6947"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_1864_6947"
            result="effect2_dropShadow_1864_6947"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1864_6947"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationInsuranceMotoCasco;
