import React from 'react';

import { Box } from '@wegroup/design-system';
import MapIllustration from './MapIllustration';
import { blink } from '../../utils/cssAnimationsUtils';

interface Props {
  color?: string;
}

const MapSkeleton: React.FC<React.PropsWithChildren<Props>> = ({ color }) => {
  return (
    <Box
      w="100%"
      h="100%"
      overflow="hidden"
      position="relative"
      animation={`${blink} infinite 1.5s linear`}
    >
      <MapIllustration color={color} fullscreen />
    </Box>
  );
};

export default MapSkeleton;
