import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyPassiveHouse: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M24.66 31.61V32A7.7 7.7 0 0017 25v4.4a7.7 7.7 0 006.38 7.6v5h2.55v-5a9.59 9.59 0 008.34-9.5V22a9.591 9.591 0 00-9.61 9.61zm0 0V32A7.7 7.7 0 0017 25v4.4a7.7 7.7 0 006.38 7.6v5h2.55v-5a9.59 9.59 0 008.34-9.5V22a9.591 9.591 0 00-9.61 9.61zm0 0V32A7.7 7.7 0 0017 25v4.4a7.7 7.7 0 006.38 7.6v5h2.55v-5a9.59 9.59 0 008.34-9.5V22a9.591 9.591 0 00-9.61 9.61z"
      opacity="0.41"
    ></path>
    <path
      fill="currentColor"
      d="M42.36 17.36l-4-2.64V8.24a1.12 1.12 0 00-1.12-1.12h-5.57a1.12 1.12 0 00-1.12 1.12v1.25L25.6 6.2a1.18 1.18 0 00-1.32 0L7.53 17.36a1.19 1.19 0 00-.53 1V42.8A1.2 1.2 0 008.2 44h33.48a1.2 1.2 0 001.2-1.2V18.35a1.2 1.2 0 00-.52-.99zm-9.89-4.08a.51.51 0 01-.24.07.44.44 0 00.24-.07zm7.17 27.48H27v-4A10 10 0 0035 27v-5a10 10 0 00-10 10v1a8 8 0 00-8-8v4a8 8 0 006 7.74v4H10.24V19.52l14.7-9.69L29 12.56l2.33 1.53a1.56 1.56 0 002.42-1.31v-2.43h1.36v5.44a1.54 1.54 0 00.71 1.31l3.78 2.43.04 21.23z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyPassiveHouse;
