import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsLegalMobility: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25 18a4 4 0 100-8 4 4 0 000 8zM25 23a2 2 0 110 4 2 2 0 010-4zm0-2a4 4 0 100 8 4 4 0 000-8zM25 34a2 2 0 110 4 2 2 0 010-4zm0-2a4 4 0 100 8 4 4 0 000-8z"
    ></path>
    <path
      fill="currentColor"
      d="M33.11 17A2 2 0 0035 15.61l.6-1.88a2 2 0 01.9-1.12l3.49-2a2 2 0 001-1.73V8.2a.2.2 0 00-.2-.2H33V7a1 1 0 00-1-1H18a1 1 0 00-1 1v1H9.2a.2.2 0 00-.2.2v.65a2 2 0 001 1.73l3.49 2a2 2 0 01.9 1.12l.6 1.88a2 2 0 001.9 1.42H17v3H9.2a.2.2 0 00-.2.2v.65a2 2 0 001 1.73l3.49 2a2 2 0 01.9 1.12l.6 1.88a2 2 0 001.9 1.42H17v3H9.2a.2.2 0 00-.2.2v.65a2 2 0 001 1.73l3.49 2a2 2 0 01.9 1.12l.6 1.88a2 2 0 001.9 1.42H17v2a1 1 0 001 1h14a1 1 0 001-1v-2h.11A2 2 0 0035 39.61l.6-1.88a2 2 0 01.9-1.12l3.49-2a2 2 0 001-1.73v-.68a.2.2 0 00-.2-.2H33v-3h.11A2 2 0 0035 27.61l.6-1.88a2 2 0 01.9-1.12l3.49-2a2 2 0 001-1.73v-.68a.2.2 0 00-.2-.2H33v-3h.11zM31 41.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-33a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v33z"
    ></path>
  </Icon>
);

export default RiskObjectsLegalMobility;
