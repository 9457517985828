import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyTenant: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M32 33l-6.42-2.75a1 1 0 01-.58-.88 1 1 0 011.29-.89l5 1.87a1.8 1.8 0 002.33-1.11 1.8 1.8 0 00-.65-2l-6.65-4.84a7.57 7.57 0 00-7.1-1l-3.7 1.4c-.345.133-.71.2-1.08.2H9v-1.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V33h5.59c.273.002.543.05.8.14L31 38.82a3.17 3.17 0 002 0l11.47-3.39a2.11 2.11 0 001.51-2 2.109 2.109 0 00-2.4-2.09L32 33z"
    ></path>
    <path
      fill="currentColor"
      d="M38 25h1.5a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5H38v-1.21a5.5 5.5 0 10-3 0v7.71a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5H38v-2zm-3.92-7.88a2.49 2.49 0 013-3 2.46 2.46 0 011.8 1.8 2.49 2.49 0 01-3 3 2.46 2.46 0 01-1.8-1.8z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyTenant;
