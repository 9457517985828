import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleBaXL: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M37.06 29.62a1.44 1.44 0 00-1-.84L34 28.33l-16.26-3.52a1.32 1.32 0 01-.82-.53l-3.72-5a.84.84 0 00-.44-.28l-3.22-.6c-.93-.16-1.86-.32-2.8-.44a.61.61 0 00-.69.61V40h13a6.5 6.5 0 1013 0h4.51a1.5 1.5 0 00.5-.79c.505-1.973.88-3.977 1.12-6a13.747 13.747 0 00-1.12-3.59zM25.55 43a3.5 3.5 0 01-3.46-3 2.747 2.747 0 010-.5 3.5 3.5 0 117 0c.015.166.015.334 0 .5a3.5 3.5 0 01-3.54 3zM38.75 15.28l-9.1 10.06-2.42-.52 1.77-4.6a.2.2 0 00-.096-.248.2.2 0 00-.094-.022h-8.34a.21.21 0 01-.16-.33l12-14.75a.2.2 0 01.34.2l-3.49 9.61a.2.2 0 00.096.248.2.2 0 00.094.022h9.25a.2.2 0 01.15.33z"
    ></path>
    <path fill="#3297FD" d="M40.5 21a6.5 6.5 0 100-13 6.5 6.5 0 000 13z"></path>
    <path
      fill="#fff"
      d="M40.8 12.18l-1.49 2.33 1.51 2.31h-1.26l-.91-1.51-.91 1.51h-1.22L38 14.5l-1.5-2.32h1.24l.92 1.51.91-1.51h1.23zM44.48 16.82h-3v-4.64h1v3.65h2v.99z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleBaXL;
