import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../features/authentication/hooks/useAuth';
import LoadingPage from './LoadingPage';

const Authentication: React.FC = () => {
  const { login } = useAuth();

  const location = useLocation<{ from: Location }>();
  const from = location.state?.from;
  const redirectUri = from ? from.pathname + from.search : '/';

  useEffect(() => {
    login(redirectUri);
  }, []);

  return <LoadingPage />;
};

export default Authentication;
