import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsAddUserOutline: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M43.5 33H40v-3.5a1.5 1.5 0 10-3 0V33h-3.5a1.5 1.5 0 100 3H37v3.5a1.5 1.5 0 103 0V36h3.5a1.5 1.5 0 100-3z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24.692 7.216c-2.047 0-4.308.628-6.109 2.165-1.809 1.544-3.108 3.968-3.288 7.468-.04.37-.188 2.171.088 4.363.279 2.214 1 4.898 2.774 6.891a7.4 7.4 0 001.14 1.069 25.22 25.22 0 01-.23.232c-.935.936-3.095 2.343-8.048 3.564l-.007.002c-1.572.412-2.779 1.374-3.546 2.767-.76 1.382-1.074 3.161-.933 5.212l.003.05.013.047c.242.887.934 1.67 1.92 1.67h.091l.023-.003c1.01-.092 1.78-.934 1.685-1.98-.066-.76-.061-1.747.194-2.581.251-.822.715-1.423 1.54-1.61l.012-.002c6.904-1.743 9.713-4.059 10.799-5.791.635.144 1.3.193 1.947.193h.274c.808 0 1.557-.107 2.288-.27.518.827 1.38 1.785 2.849 2.749a3.5 3.5 0 013.084-2.412c-1.491-.77-2.245-1.478-2.616-1.954a6.911 6.911 0 001-.954c1.773-1.992 2.494-4.658 2.773-6.863.277-2.192.126-3.998.088-4.387-.18-3.501-1.48-5.926-3.289-7.47-1.8-1.537-4.062-2.165-6.109-2.165h-.41zm-5.66 9.878v-.057c.133-2.967 1.278-4.486 2.48-5.285 1.234-.82 2.609-.935 3.25-.935h.41c.64 0 2.014.115 3.248.935 1.202.799 2.347 2.317 2.48 5.285v.088l.004.031c.097.774.132 2.318-.134 3.974-.268 1.665-.829 3.368-1.865 4.53l-.002.002c-.918 1.04-2.211 1.61-3.938 1.61h-.136c-1.726 0-3.018-.569-3.936-1.608-1.194-1.385-1.678-3.46-1.847-5.27a18.824 18.824 0 01-.015-3.3z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsAddUserOutline;
