import localforage from 'localforage';

const DATABASE_NAME = 'Wegroup - Broker platform';

export const proFlowStorage = localforage.createInstance({
  name: DATABASE_NAME,
  storeName: 'pro_flow',
});

export const piaFlowStorage = localforage.createInstance({
  name: DATABASE_NAME,
  storeName: 'pia_flow',
});

export const baloiseFlowStorage = localforage.createInstance({
  name: DATABASE_NAME,
  storeName: 'baloise_flow',
});

export const claimsFileStorage = localforage.createInstance({
  name: DATABASE_NAME,
  storeName: 'claim_files',
});

export const productFlowStorage = localforage.createInstance({
  name: DATABASE_NAME,
  storeName: 'product_store',
});
