import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersPartyDetails: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M24.692 7.216c-2.047 0-4.308.628-6.109 2.165-1.809 1.544-3.108 3.968-3.288 7.468-.04.37-.188 2.171.088 4.363.279 2.214 1 4.898 2.774 6.891a7.4 7.4 0 001.14 1.069 25.22 25.22 0 01-.23.232c-.935.936-3.095 2.343-8.048 3.564l-.007.002c-1.572.412-2.779 1.374-3.546 2.767-.76 1.382-1.074 3.161-.933 5.212l.003.05.013.047c.242.887.934 1.67 1.92 1.67h.091l.023-.003c1.01-.092 1.78-.934 1.685-1.98-.066-.76-.061-1.747.194-2.581.251-.822.715-1.423 1.54-1.61l.012-.002c3.278-.828 5.632-1.784 7.318-2.746.089-2.745.883-5.311 2.208-7.524a4.615 4.615 0 01-.647-.606c-1.194-1.385-1.678-3.46-1.847-5.27a18.824 18.824 0 01-.015-3.3v-.057c.134-2.967 1.279-4.486 2.481-5.285 1.234-.82 2.609-.935 3.25-.935h.41c.64 0 2.014.115 3.248.935 1.202.799 2.347 2.317 2.48 5.285v.088l.004.031c.056.45.091 1.158.06 1.997a15.683 15.683 0 013.611-.52 18.424 18.424 0 00-.075-1.782c-.18-3.501-1.48-5.926-3.289-7.47-1.8-1.537-4.062-2.165-6.109-2.165h-.41z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M34.5 26a8.5 8.5 0 100 17 8.5 8.5 0 000-17zM22 34.5C22 27.596 27.596 22 34.5 22S47 27.596 47 34.5 41.404 47 34.5 47 22 41.404 22 34.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M31.867 33.842c0-.727.59-1.316 1.317-1.316H34.5c.727 0 1.316.589 1.316 1.316v3.949a1.316 1.316 0 010 2.632H34.5c-.727 0-1.316-.589-1.316-1.316v-3.949c-.727 0-1.317-.59-1.317-1.316zM33.184 29.893c0-.727.589-1.316 1.316-1.316h.013a1.316 1.316 0 010 2.632H34.5c-.727 0-1.316-.59-1.316-1.316z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersPartyDetails;
