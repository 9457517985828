export enum Inputs {
  INPUT = 'INPUT',
  DATE = 'DATE',
  SELECT = 'SELECT',
  SELECT_DETAILED = 'SELECT_DETAILED',
  ICON_SELECT = 'ICON_SELECT',
  SINGLE_CHECKBOX = 'SINGLE_CHECKBOX',
  CHECKBOX = 'CHECKBOX',
  ADDRESS = 'ADDRESS',
  TELEPHONE_NUMBER = 'TELEPHONE_NUMBER',
  CAR = 'CAR',
  ACCIDENT = 'ACCIDENT',
  HOME = 'HOME',
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
  ANNEXES = 'ANNEXES',
  ROOM = 'ROOM',
  SECURITY_SYSTEM = 'SECURITY_SYSTEM',
  MULTISELECT_SIMPLE = 'MULTISELECT_SIMPLE',
  MULTISELECT_DETAILED = 'MULTISELECT_DETAILED',
  MULTISELECT = 'MULTISELECT',
  SLIDER = 'SLIDER',
  ADDITIONAL_DRIVER = 'ADDITIONAL_DRIVER',
  LINK = 'LINK',
  LINKS = 'LINKS',
  MASKED = 'MASKED',
  COMPANY_SEARCH = 'COMPANY_SEARCH',
  RADIO = 'RADIO',
  AMOUNT = 'AMOUNT',
  HIDDEN = 'HIDDEN',

  // Company scan ONLY
  MULTILINE = 'MULTILINE',
  UNIT = 'UNIT',
  UNITS = 'UNITS',
  COMPANY_LOAN = 'COMPANY_LOAN',
  COMPANY_LEASE = 'COMPANY_LEASE',
  COMPANY_INVESTMENT = 'COMPANY_INVESTMENT',
  CLAIMS = 'CLAIMS',
  COMPANIES = 'COMPANIES',
  NACE_CODE = 'NACE_CODE',
  TABLE = 'TABLE',
  NOTES = 'NOTES',
  DATETIME = 'DATETIME',
  INDEX_SELECT = 'INDEX_SELECT',
  INDEX_MULTISELECT = 'INDEX_MULTISELECT',
  NUMBER_PLATE = 'NUMBER_PLATE',
  INDEX_PERCENTAGE = 'INDEX_PERCENTAGE',
  COVERAGE = 'COVERAGE',

  // Distribution specific
  PIA_CLAIMS = 'PIA_CLAIMS',
}
