import { Center } from '@wegroup/design-system';
import React from 'react';
import LoadingScreen from '../../../common/src/components/LoadingScreen';

const LoadingPage: React.FC = () => {
  return (
    <Center h="100vh" w="100%">
      <LoadingScreen />
    </Center>
  );
};

export default LoadingPage;
