import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskAnalysisSubjectsLegal: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.5 42a1.5 1.5 0 011.5-1.5h20a1.5 1.5 0 010 3H15a1.5 1.5 0 01-1.5-1.5zM24.753 10.52a1.5 1.5 0 01.494 0l12 2a1.5 1.5 0 01-.494 2.96L25 13.52l-11.753 1.96a1.5 1.5 0 11-.494-2.96l12-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 6.5A1.5 1.5 0 0126.5 8v34a1.5 1.5 0 01-3 0V8A1.5 1.5 0 0125 6.5zM13 12.5a1.5 1.5 0 011.342.83l6 12a1.5 1.5 0 01.158.67 7.5 7.5 0 01-15 0 1.5 1.5 0 01.158-.67l6-12A1.5 1.5 0 0113 12.5zM8.512 26.33a4.5 4.5 0 008.976 0L13 17.354 8.512 26.33zM37 12.5a1.5 1.5 0 011.342.83l6 12a1.5 1.5 0 01.158.67 7.5 7.5 0 01-15 0 1.5 1.5 0 01.158-.67l6-12A1.5 1.5 0 0137 12.5zm-4.488 13.83a4.5 4.5 0 008.976 0L37 17.354l-4.488 8.976z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisSubjectsLegal;
