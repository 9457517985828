import React from 'react';
import styled from 'styled-components';
import Drawer from 'react-bottom-drawer';
import { use100vh } from 'react-div-100vh';
import {
  customScrollBar,
  DisableBodyScroll,
} from '../../../../common/src/utils/styledComponentsUtils';

interface Props {
  isVisible: boolean;
  customHeight?: string;
  onClose: () => void;
}

const BottomDrawer: React.FC<React.PropsWithChildren<Props>> = ({
  isVisible,
  children,
  customHeight,
  onClose,
  ...otherProps
}) => {
  const height = use100vh();
  const calculatedHeight = customHeight
    ? customHeight
    : height
    ? `${height * 0.85}px`
    : '75vh';

  return (
    <>
      <DisableBodyScroll />
      <Wrapper
        isVisible={isVisible}
        onClose={onClose}
        calculatedHeight={calculatedHeight}
        className="drawer"
        {...otherProps}
      >
        {children}
      </Wrapper>
    </>
  );
};

const Wrapper = styled(Drawer)<{ calculatedHeight: string }>`
  /* important needed for overwriting package css */
  &__handle-wrapper {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: calc(100% + 66px);
      left: 0;
      top: -22px;
      z-index: 1;
    }
  }
  .drawer__content {
    height: ${({ calculatedHeight }) => calculatedHeight};
  }
  > div:last-child {
    max-height: ${({ calculatedHeight }) => calculatedHeight} !important;
    padding: 0;
  }

  ${customScrollBar}
`;

export default BottomDrawer;
