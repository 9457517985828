/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty-function */
import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { Broker, Distribution } from '../../../../../../types/Broker';

// Types
import { Campaign } from '../../../../../../types/Campaigns';
import { RootState } from '../reducers/rootReducer';

/* * * * * * * * * * * * * * *
 * SELECTORS
 * * * * * * * * * * * * * * */

export enum CampaignExtend {
  LEAD,
  INTERNAL_OFFER,
  EXTERNAL_OFFER,
}

export const getCampaignExtend = createSelector(
  (state: RootState) => state.productFactory.campaign.extend,
  (extend) => CampaignExtend[extend],
);

const initialState = {
  distribution: {} as Distribution,
  campaign: {} as Campaign,
  brokerData: {} as Broker,
  sessionActive: false,
  showInfoModal: false,
};

const productFactoryController = createSlice({
  name: 'productFactory',
  initialState: initialState,
  reducers: {
    setDistributionData(state, action: PayloadAction<Distribution>) {
      state.distribution = action.payload;
    },
    setCampaignData(state, action: PayloadAction<Campaign>) {
      state.campaign = action.payload;
    },
    setBrokerData(state, action: PayloadAction<Broker>) {
      state.brokerData = action.payload;
    },
    setSessionActive(state) {
      state.sessionActive = true;
    },
    setShowInfoModal(state, action: PayloadAction<boolean>) {
      state.showInfoModal = action.payload;
    },
    resetProductFactory: () => {
      return initialState;
    },
  },
});

export const {
  setDistributionData,
  setCampaignData,
  setSessionActive,
  resetProductFactory,
  setShowInfoModal,
  setBrokerData,
} = productFactoryController.actions;

export default productFactoryController.reducer;
