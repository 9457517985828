import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsCaretDown: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25.708 32.29a1 1 0 01-1.416 0L13.71 21.68c-.629-.63-.182-1.706.708-1.706h21.162c.89 0 1.337 1.075.708 1.706l-10.58 10.61z"
    />
  </Icon>
);

export default GeneralActionsCaretDown;
