import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralLabelsSMS: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M35 16v-6a2 2 0 00-2-2H15a2 2 0 00-2 2v33a2 2 0 002 2h18a2 2 0 002-2v-8h-4v2H17V12h14v4h4zM24 39a2 2 0 110 4 2 2 0 010-4z"
    ></path>
    <path
      fill="currentColor"
      d="M21 19v13a1 1 0 001 1h18a1 1 0 001-1V19a1 1 0 00-1-1H22a1 1 0 00-1 1zm13.88 2l-3.6 3.53a.5.5 0 01-.68 0l-4-3.55 8.28.02zM38 30H24v-7.34l6.35 5.71a1 1 0 001.37 0L38 22.12V30z"
    ></path>
  </Icon>
);

export default GeneralLabelsSMS;
