import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationStocks: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3952_10226"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3952_10226)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M77 59.121v145.937h179.746"
        ></path>
        <mask
          id="mask1_3952_10226"
          style={{ maskType: 'alpha' }}
          width="202"
          height="171"
          x="78"
          y="33"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#C4C4C4"
            d="M78.713 33.443H279.001V203.774H78.713z"
          ></path>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          mask="url(#mask1_3952_10226)"
        >
          <path
            fill={colors.primary[200]}
            strokeLinecap="round"
            d="M72.309 182.663c.733 0 1.407-.401 1.757-1.046 21.658-39.87 43.309-66.839 71.958-84.657 29.656-18.445 66.028-26.59 115.804-30.258a6.56 6.56 0 11.964 13.083c-49.045 3.614-82.879 11.546-109.84 28.315-26.897 16.729-47.737 42.744-69.729 84.196l1.767.938-1.767-.938a6.558 6.558 0 01-5.794 3.485H59.882a6.559 6.559 0 110-13.118H72.31z"
          ></path>
          <path
            fill={colors.primary[100]}
            strokeLinecap="round"
            d="M76.414 167.267l.007-.011c.014-.024.029-.047.044-.069v-.001s0 0 0 0l.001-.001.024-.037.002-.002.068-.102-.146.223zm0 0v-.002l.002-.002.002-.003.002-.004a4.128 4.128 0 01-.163.233 6.34 6.34 0 01-.616.7l-.006.007a6.56 6.56 0 01-4.626 1.908H53.464a6.56 6.56 0 010-13.119h12.895c.684 0 1.32-.35 1.688-.927a266.57 266.57 0 001.565-2.495c2.297-3.708 4.924-8.169 6.612-11.687 6.094-12.695 16.151-18.565 26.895-17.491 10.069 1.007 19.037 7.985 24.125 17.144 3.671 6.607 8.737 14.948 12.925 21.696 2.085 3.361 3.939 6.303 5.27 8.404l1.69-1.07-1.69 1.07c.666 1.051 1.2 1.891 1.568 2.467l1.686-1.076-1.686 1.076.422.659h0l.075.118.041.061c2.702 3.897 8.59 7.197 15.719 7.635 6.882.422 14.112-1.898 19.434-8.118a1.83 1.83 0 00.069-.085l29.822-38.971a32.235 32.235 0 0125.601-12.647h24.121a6.56 6.56 0 110 13.119h-24.121a19.118 19.118 0 00-15.183 7.5l-29.953 39.143 1.589 1.215M76.414 167.267l118.228 16.458m0 0l-1.589-1.215c-.062.081-.126.16-.191.239l1.468 1.237-1.468-1.237c-8.359 9.926-19.896 13.558-30.402 12.914-10.266-.63-20.382-5.42-25.836-13.456l-1.655 1.123 1.655-1.123a6.646 6.646 0 01-.091-.139m58.109 1.657l-58.109-1.657m0 0h0m0 0h0m0 0l-.153-.238-.001-.002-.249-.389-.001-.002-.181-.283v-.001a1084.837 1084.837 0 01-6.927-11.011c-4.208-6.781-9.413-15.344-13.245-22.242-3.477-6.258-9.063-9.971-13.964-10.461-1.978-.198-4.403.08-6.889 1.625-2.46 1.53-4.814 4.2-6.873 8.489-2.008 4.182-4.944 9.137-7.287 12.918a277.856 277.856 0 01-4.203 6.572l59.973 15.025z"
          ></path>
          <path
            fill="#fff"
            strokeLinecap="square"
            d="M159.035 157.554l-.055.052c-7.633 7.595-21.565 17.892-37.146 23.124-15.782 5.3-34.618 5.729-49.47-9.123l-4.638-4.638 9.276-9.276 4.639 4.638c9.96 9.961 22.787 10.406 36.017 5.963 13.328-4.476 25.65-13.568 32.168-20.086a5.44 5.44 0 01.19-.182l105.28-97.149a6.56 6.56 0 018.896 9.641l-105.157 97.036z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default IllustrationStocks;
