const Checkbox = {
  baseStyle: {
    control: {
      bg: 'white',
      borderColor: 'gray.100',
      mr: '1',
      _hover: {
        borderColor: 'primary.500',
      },
      _checked: {
        bg: 'primary.500',
        borderColor: 'primary.500',
        _hover: {
          bg: 'primary.600',
          borderColor: 'primary.600',
        },
      },
      _indeterminate: {
        bg: 'primary.500',
        borderColor: 'primary.500',
        _hover: {
          bg: 'primary.600',
          borderColor: 'primary.600',
        },
      },
    },
  },
  variants: {
    rounded: {
      control: {
        borderRadius: 'full',
      },
      container: {
        borderRadius: 'full',
      },
    },
  },
};

export default Checkbox;
