import {
  RichTextEditorProps,
  RichTextEditor as MantineRichTextEditor,
  RichTextEditorStylesNames,
} from '@mantine/rte';
import { merge } from 'lodash';
import type { Delta, Sources } from 'quill';
import React, { useCallback } from 'react';
import useUpload from '../../../../../../common/src/hooks/useUpload';
import { hasRichText } from '../../../../../../company-scan/utils/textUtils';
import { useDefaultRichTextEditorStyling } from '../useDefaultRichTextEditorStyling';
import { useTheme } from '@wegroup/design-system';
import cogoToast from 'cogo-toast';
import { useTranslation } from 'react-i18next';
import { CSSObject } from '@mantine/core';

interface Controls {
  image: boolean;
}

interface Props {
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  extraControls?: Controls;
  extraStyling?: Partial<Record<RichTextEditorStylesNames, CSSObject>>;
}

// We use `any` here because ReactQuill is not exposed by Mantine
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const RichTextEditor = React.forwardRef<any, Props & RichTextEditorProps>(
  (
    {
      value,
      onChange,
      isDisabled,
      extraControls = {
        image: false,
      },
      extraStyling,
      ...otherProps
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const defaultStyling = useDefaultRichTextEditorStyling();
    const { uploadFile } = useUpload();
    const { space } = useTheme();

    const handleOnChange = (value: string, _: Delta, sources: Sources) => {
      /**
       * React quill has an issue where it calls the onChange initially, causing a mismatch between
       * the initial and the actual value. This makes the form instantly dirty. By checking if the
       * change source comes from the user we avoid the instant dirtyness of the form.
       *
       * More info here: https://github.com/zenoamaro/react-quill/issues/259#issuecomment-343191875
       */
      if (sources === 'user') {
        if (hasRichText(value)) {
          onChange(value);
        } else {
          onChange('');
        }
      }
    };

    const handleImageUpload = useCallback((file: File): Promise<string> => {
      if (extraControls.image) {
        if (file.size <= 2000000) {
          return new Promise((resolve, reject) => {
            uploadFile(file)
              .then(({ public_url }) => {
                resolve(public_url);
              })
              .catch(() => reject(new Error('Upload failed')));
          });
        }
        cogoToast.warn(t('UPLOAD-MAX-FILE-SIZE'));
        return new Promise((_, reject) => reject(new Error('File to big')));
      }
      return new Promise((_, reject) =>
        reject(new Error('Image upload disabled')),
      );
    }, []);

    const defaultControls: RichTextEditorProps['controls'] = [
      ['bold', 'italic', 'underline', 'strike', 'clean', 'link'],
      ['h1', 'h2', 'h3', 'h4'],
      ['unorderedList', 'orderedList'],
      ['alignLeft', 'alignCenter', 'alignRight'],
    ];

    if (extraControls.image) defaultControls.push(['image']);

    return (
      <MantineRichTextEditor
        ref={ref}
        controls={defaultControls}
        value={value}
        onChange={handleOnChange}
        styles={merge(
          defaultStyling,
          {
            root: {
              // cursor
              ...(isDisabled && {
                opacity: '0.4',
                cursor: 'not-allowed',
              }),
              '.ql-toolbar': {
                ...(isDisabled && {
                  pointerEvents: 'none',
                }),
              },
              '.ql-tooltip': {
                top: '0 !important',
                left: '0 !important',
              },
              '.ql-editor': {
                minHeight: space[28],
                overflow: 'auto',
                ...(isDisabled && {
                  pointerEvents: 'none',
                }),
              },
            },
          },
          extraStyling,
        )}
        onImageUpload={handleImageUpload}
        {...otherProps}
      />
    );
  },
);

export default RichTextEditor;
