import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersSegments: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillOpacity="0.1"
        d="M18.066 18.822c-.778 5.255.779 15.103 13.235 12.456-.487-6.715-1.46-13.04-13.235-12.456z"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M30.336 31.17c-.478.065-.967.1-1.464.1-5.826 0-10.549-4.723-10.549-10.549 0-.497.035-.986.101-1.464.479-.067.968-.1 1.465-.1 5.825 0 10.548 4.722 10.548 10.548 0 .497-.034.986-.1 1.464zm4.267-1.591c-.067-8.027-6.561-14.522-14.588-14.589a10.54 10.54 0 018.857-4.818c5.826 0 10.549 4.723 10.549 10.55a10.54 10.54 0 01-4.818 8.857zm-.834 5.023c5.72-2.018 9.819-7.47 9.819-13.88 0-8.128-6.589-14.716-14.716-14.716-6.41 0-11.863 4.098-13.88 9.818-5.72 2.018-9.819 7.47-9.819 13.88 0 8.128 6.588 14.716 14.716 14.716 6.41 0 11.863-4.098 13.88-9.818zm-5.023.834c-8.027-.067-14.521-6.562-14.589-14.589a10.54 10.54 0 00-4.817 8.858c0 5.826 4.723 10.549 10.549 10.549a10.54 10.54 0 008.857-4.818z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersSegments;
