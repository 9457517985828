import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleElectronicStabilityControl: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M38.244 18.9l-.8-2.2c-.2-.5-.6-.8-1.1-.8l-1.9-8c-.1-.6-.6-.9-1.2-.9h-16.3c-.5 0-1 .4-1.2.9l-1.9 8c-.5 0-.9.3-1.1.8l-.7 2.4c-.1.2 0 .6 0 .6l.9 5.2c.1.6.6 1 1.1 1v2.9c0 .5.4.9.8.9h1.3c.4 0 .8-.4.8-.9v-2.9h16.5v2.9c0 .5.4.9.8.9h1.3c.4 0 .8-.4.8-.9v-2.9c.5 0 1-.4 1-1l.9-5.4s.1-.4 0-.6zm-22.7-3l1.6-6.7c.1-.5.5-.8 1-.8h14c.5 0 .9.3 1 .8l1.6 6.7h-19.2z"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.52 30.508a1.5 1.5 0 011.908-.925c.837.29 1.782.697 2.613 1.2.78.472 1.683 1.157 2.147 2.087.253.506.391 1.117.265 1.78-.125.653-.478 1.2-.929 1.645-.855.84-2.262 1.503-4.16 2.067-1.618.48-3.087.882-4.346 1.226-.54.148-1.042.285-1.5.413-1.094.306-1.892.551-2.447.763.368.258.864.564 1.511.929a1.5 1.5 0 11-1.47 2.614c-.766-.43-1.413-.829-1.927-1.204-.491-.358-.971-.772-1.289-1.262-.356-.549-.575-1.324-.207-2.133.3-.66.877-1.051 1.297-1.285.867-.483 2.21-.887 3.723-1.31.488-.137 1.007-.279 1.557-.43 1.247-.34 2.658-.725 4.244-1.197 1.781-.53 2.597-1.022 2.91-1.33l.03-.03c-.122-.167-.412-.444-.96-.776-.606-.366-1.348-.691-2.044-.933a1.5 1.5 0 01-.926-1.909zm4.002 3.527l-.002.003a.017.017 0 01.002-.003zM9.29 41.145zM31.22 30.538a1.5 1.5 0 011.889-.965c.887.287 1.889.69 2.77 1.187.821.464 1.78 1.14 2.279 2.073.274.512.43 1.145.29 1.837-.14.675-.525 1.225-.999 1.66-.9.826-2.38 1.479-4.401 2.04-1.732.48-3.302.88-4.65 1.224-.58.148-1.119.285-1.612.414-1.155.302-2.013.547-2.619.762.393.254.913.551 1.58.902a1.5 1.5 0 11-1.394 2.656c-.818-.43-1.507-.825-2.052-1.197-.52-.353-1.028-.763-1.367-1.25-.383-.551-.636-1.364-.224-2.209.324-.664.934-1.045 1.365-1.27.91-.472 2.327-.872 3.953-1.296.52-.137 1.076-.278 1.665-.428 1.337-.341 2.851-.727 4.553-1.2 1.92-.532 2.82-1.033 3.174-1.357-.15-.171-.464-.437-1.016-.748-.659-.372-1.464-.702-2.22-.946a1.5 1.5 0 01-.964-1.889zm4.311 3.46L35.53 34l.001-.003zm-12.258 7.179l.003-.002-.003.002z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleElectronicStabilityControl;
