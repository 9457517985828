import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationHolderTypeOwner: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_2695_9914"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_2695_9914)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M178.082 94.783l-57.517 57.512v73.511h115.029v-73.511l-57.512-57.512z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M235.594 89.025a5.899 5.899 0 00-5.898-5.898h-9.748a5.898 5.898 0 00-5.898 5.898v11.668l21.565 21.313-.021-32.981z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[100]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M254.002 140.204l-65.812-65.05a14.381 14.381 0 00-20.22 0l-65.812 65.05a10.784 10.784 0 0015.249 15.249l60.676-60.67 60.669 60.67a10.785 10.785 0 0015.25 0 10.783 10.783 0 000-15.249z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M182.529 156.089c3.822 1.045 6.308 2.487 9.02 5.235 2.339 2.368 3.494 4.322 4.407 7.453.31 1.062.326 1.583.378 12.694.055 11.506.053 11.591-.279 12.364-.62 1.441-1.847 2.24-3.441 2.24-1.146 0-1.897-.318-2.679-1.134-1.029-1.074-.997-.701-.997-11.508 0-5.392-.043-9.916-.096-10.054-.056-.145-.281-.25-.537-.25s-.481.105-.537.25c-.053.138-.096 4.594-.096 9.904v9.653l-1.185 14.902c-.653 8.196-1.256 15.319-1.341 15.829-.363 2.178-2.033 3.797-4.368 4.232-1.362.254-5.322.095-6.285-.252-1.029-.371-2.375-1.633-2.88-2.701-.395-.835-.444-1.32-1.669-16.562l-1.261-15.702v-9.526c0-5.24-.044-9.639-.096-9.777-.135-.351-.809-.313-1.007.056-.107.201-.163 3.637-.163 10.054 0 10.745.032 10.379-.997 11.452-.859.896-1.579 1.174-2.866 1.107-.957-.049-1.232-.134-1.87-.574-.414-.287-.944-.85-1.187-1.264l-.439-.747-.048-10.681c-.031-6.985.011-11.2.124-12.178.747-6.489 6.038-12.295 13.059-14.329 2.092-.606 2.91-.7 5.538-.634 1.899.047 2.659.137 3.798.448z"
          clipRule="evenodd"
        ></path>
        <rect
          width="14.335"
          height="18.409"
          x="171.061"
          y="131"
          fill={colors.primary[500]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="7.167"
        ></rect>
        <g filter="url(#filter0_dd_2695_9914)">
          <circle cx="243.5" cy="101.5" r="42.5" fill="#fff"></circle>
          <circle
            cx="243.5"
            cy="101.5"
            r="40.5"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M267.97 104.3a3.524 3.524 0 00-.696-2.602 3.513 3.513 0 00-2.327-1.347l-22.231-2.933a11.473 11.473 0 00-4.035-6.841 11.436 11.436 0 00-14.878.356 11.486 11.486 0 00-1.959 14.78 11.437 11.437 0 0014.271 4.233 11.463 11.463 0 005.677-5.551l7.704 1.018-.337 2.559a3.516 3.516 0 005.606 3.227 3.513 3.513 0 001.358-2.301l.338-2.558 4.085.541-.67 5.093a3.526 3.526 0 001.707 3.547 3.498 3.498 0 002.685.357 3.506 3.506 0 002.141-1.663c.229-.405.376-.852.431-1.315l1.13-8.6zm-37.096-.434a4.427 4.427 0 01-3.694-3.248 4.436 4.436 0 011.581-4.662 4.419 4.419 0 016.66 1.557 4.44 4.44 0 01.418 2.539 4.44 4.44 0 01-1.695 2.935 4.427 4.427 0 01-3.27.879z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_2695_9914"
          width="117"
          height="117"
          x="185"
          y="51"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2695_9914"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_2695_9914"
            result="effect2_dropShadow_2695_9914"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2695_9914"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationHolderTypeOwner;
