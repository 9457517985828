import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyHomePlusExtra: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M44.5 19H41v-3.5a1.5 1.5 0 10-3 0V19h-3.5a1.5 1.5 0 100 3H38v3.5a1.5 1.5 0 103 0V22h3.5a1.5 1.5 0 100-3z"
    ></path>
    <path
      fill="currentColor"
      d="M39.5 29a3.5 3.5 0 01-3.5-3.5V24h-1.5a3.5 3.5 0 110-7H36v-6.19a1 1 0 00-1-1h-4a1 1 0 00-1 1v1.34l-4.45-3a1 1 0 00-1.1 0l-14 9.39a1 1 0 00-.45.84V40a1 1 0 001 1h6V31a1 1 0 011-1h5a1 1 0 011 1v10h15a1 1 0 001-1V29a2.729 2.729 0 01-.5 0z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyHomePlusExtra;
