import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralRiskAnalysisAdvisoryReportCompleted: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.089 16.44A31 31 0 0025 39.33c.682-.486 1.343-1 1.982-1.538l2.785 2.883a35.005 35.005 0 01-3.249 2.469 2.72 2.72 0 01-3.036 0 34.999 34.999 0 01-15.44-27.316c-.057-1.164.635-2.222 1.693-2.71l14.01-6.452a3 3 0 012.51 0l14.01 6.453c1.058.488 1.75 1.545 1.692 2.71a34.996 34.996 0 01-1.276 7.783l-7.726 8.14-.28-.288a30.986 30.986 0 005.236-15.025L25 10.493 12.089 16.44z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M45.262 23.184c.626-.651 1.65-.651 2.276 0 .616.64.616 1.67 0 2.31l-13.2 13.714c-.626.65-1.65.65-2.276 0l-6.6-6.858a1.675 1.675 0 010-2.31c.626-.65 1.65-.65 2.276 0l5.462 5.675 12.062-12.531z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralRiskAnalysisAdvisoryReportCompleted;
