import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleFrontEmergencyBraking: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M24.5 29.124a1.861 1.861 0 11-1.86 1.86 1.868 1.868 0 011.86-1.86zm0-4.64a6.5 6.5 0 100 13.001 6.5 6.5 0 000-13z"
    ></path>
    <path
      fill="currentColor"
      d="M41 31.355a16.171 16.171 0 01-4.187 10.821 16.156 16.156 0 01-27.8-7.603.77.77 0 01.77-.909h1.538c.554 0 .862.254.939.608a12.943 12.943 0 009.035 9.538 12.93 12.93 0 0012.717-3.287 12.943 12.943 0 00-6.247-21.763.77.77 0 01-.616-.77v-1.716a.77.77 0 01.94-.77 16.158 16.158 0 019.278 5.624A16.171 16.171 0 0141 31.355z"
    ></path>
    <path
      fill="currentColor"
      d="M24.227 14.485A17 17 0 008 30.712a.773.773 0 00.773.773h3.268a.773.773 0 00.773-.726 12.194 12.194 0 0111.46-11.46.773.773 0 00.726-.773v-3.268a.773.773 0 00-.773-.773zM37.97 9.095l2.1-2.84c-6.717-4.34-15.319-5.44-23.01-2.93A26 26 0 0010 6.855l2.228 2.75a22.458 22.458 0 016.042-3.02c6.599-2.15 13.958-1.21 19.7 2.51z"
    ></path>
    <path
      fill="currentColor"
      d="M19.791 12.605c5.153-1.68 10.916-.94 15.415 1.97l2.1-2.84c-5.463-3.54-12.47-4.43-18.736-2.39a21.343 21.343 0 00-5.753 2.88l2.24 2.74c1.424-1.01 3.02-1.8 4.734-2.36z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleFrontEmergencyBraking;
