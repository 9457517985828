import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsEyeClosed: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        d="M25 13c-8 0-15.45 5-18 12 2.55 7 10 12 18 12s15.45-5 18-12c-2.55-7-10-12-18-12zm0 21a9 9 0 110-17.999A9 9 0 0125 34z"
      ></path>
      <path fill="currentColor" d="M25 30a5 5 0 100-10 5 5 0 000 10z"></path>
    </Icon>
  );
};

export default GeneralActionsEyeClosed;
