import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationPlaceholder: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_643_2976"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="356"
        height="228"
      >
        <path d="M0 0H356V228H0V0Z" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_643_2976)">
        <path
          d="M337 161C337 249.366 265.366 321 177 321C88.6344 321 17 249.366 17 161C17 72.6344 88.6344 1 177 1C265.366 1 337 72.6344 337 161Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9999 226C19.9999 224.895 20.8954 224 21.9999 224H332C333.105 224 334 224.895 334 226C334 227.105 333.105 228 332 228H21.9999C20.8954 228 19.9999 227.105 19.9999 226Z"
          fill={colors.primary[500]}
        />
        <circle
          cx="178"
          cy="120"
          r="83"
          stroke={colors.primary[200]}
          strokeWidth="8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="8 16"
        />
        <path
          d="M152.462 94.4615C152.462 89.3815 154.816 84.5097 159.007 80.9176C163.197 77.3256 168.881 75.3076 174.808 75.3076H181.192C187.119 75.3076 192.803 77.3256 196.993 80.9176C201.184 84.5097 203.538 89.3815 203.538 94.4615C203.774 98.6067 202.655 102.716 200.352 106.171C198.049 109.625 194.686 112.238 190.769 113.615C186.852 115.452 183.489 118.935 181.186 123.541C178.883 128.148 177.765 133.627 178 139.154"
          stroke={colors.primary[500]}
          strokeWidth="8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M178 164.692V164.756"
          stroke={colors.primary[500]}
          strokeWidth="8"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationPlaceholder;
