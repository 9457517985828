import React, { ChangeEvent, forwardRef } from 'react';
import {
  Spinner,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Icon,
  InputGroupProps,
  Center,
  ScaleFade,
} from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';

export interface SimpleSearchInputProps {
  value?: string;
  placeholder?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClear?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SimpleSearchInput = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<InputGroupProps & SimpleSearchInputProps>
>(
  (
    {
      value,
      onChange,
      placeholder,
      isDisabled,
      isClearable,
      isLoading,
      onClear,
      ...otherProps
    },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <InputGroup {...otherProps} ref={ref}>
        <InputLeftElement>
          {isLoading ? (
            <Spinner color="primary.500" size="sm" />
          ) : (
            <Icon name="IconSearch" color="gray.500" />
          )}
        </InputLeftElement>
        <Input
          value={value}
          onChange={onChange}
          placeholder={placeholder || t('SEARCH')}
          isDisabled={isDisabled}
          pr="20"
          autoComplete="off"
        />
        <InputRightElement w="auto" px="3">
          <ScaleFade in={isClearable && !!value} unmountOnExit>
            <Center
              cursor="pointer"
              onClick={onClear}
              color="gray.300"
              _hover={{ color: 'gray.400' }}
              transition="0.2s"
            >
              <Icon name="GeneralActionsCloseOrRemove" boxSize={6} />
            </Center>
          </ScaleFade>
        </InputRightElement>
      </InputGroup>
    );
  },
);

export default SimpleSearchInput;
