import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersLevel1: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <rect
      width="11.587"
      height="8"
      x="15.031"
      y="31.913"
      fill="currentColor"
      rx="4"
      transform="rotate(90 15.03 31.913)"
    ></rect>
    <rect
      width="24.289"
      height="8"
      x="29"
      y="19.211"
      fill="currentColor"
      fillOpacity="0.25"
      rx="4"
      transform="rotate(90 29 19.21)"
    ></rect>
    <rect
      width="37"
      height="8"
      x="42.965"
      y="6.5"
      fill="currentColor"
      fillOpacity="0.25"
      rx="4"
      transform="rotate(90 42.965 6.5)"
    ></rect>
  </Icon>
);

export default GeneralOthersLevel1;
