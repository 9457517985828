import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyPorch: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M41.511 20.52c.332 0 .63-.127.847-.335.218-.206.352-.492.352-.807 0-.631-.537-1.144-1.199-1.144H34.683a.507.507 0 00-.079-.058L21.31 10.084a.575.575 0 00-.302-.084.577.577 0 00-.298.081L7.26 18.177a.529.529 0 00-.26.453v21.723h10.994V28.822c0-.19.103-.357.26-.451a.567.567 0 01.3-.084h4.755c.31 0 .56.239.56.534v11.532h18.122v-9.769h-1.878V20.521h1.398zm-6.648 10.064V20.521h3.252v10.063h-3.252z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyPorch;
