import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycleSpeedPedelec: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3628_10298"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3628_10298)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2zM214 114.367l-33 68-3.599-1.746 33-68 3.599 1.746z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M150 183.367c0 22.091-17.909 40-40 40s-40-17.909-40-40c0-22.092 17.909-40 40-40s40 17.908 40 40z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M139 183.367c0 16.016-12.984 29-29 29s-29-12.984-29-29c0-16.017 12.984-29 29-29s29 12.983 29 29z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M286 183.367c0 22.091-17.909 40-40 40s-40-17.909-40-40c0-22.092 17.909-40 40-40s40 17.908 40 40z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M275 183.367c0 16.016-12.984 29-29 29s-29-12.984-29-29c0-16.017 12.984-29 29-29s29 12.983 29 29z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M111.5 183.367l25.974-67.139a1 1 0 00-.933-1.361H120"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M135.804 123.867c-1.611 0-2.561 1.806-1.648 3.133l40.248 58.5c.373.543.989.867 1.648.867h68.144c1.611 0 2.561-1.807 1.648-3.134l-40.248-58.5a2 2 0 00-1.648-.866h-68.144zm7.607 4c-1.611 0-2.561 1.806-1.648 3.133l34.744 50.5a2 2 0 001.648.867h58.434c1.611 0 2.561-1.807 1.648-3.134l-34.744-50.5a2.001 2.001 0 00-1.648-.866h-58.434z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          d="M190 183.367c0 6.627-5.373 12-12 12s-12-5.373-12-12c0-6.628 5.373-12 12-12s12 5.372 12 12z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M178 191.367a8 8 0 100-16 8 8 0 000 16zm0 4c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12s-12 5.372-12 12c0 6.627 5.373 12 12 12zM190 114.367a2 2 0 012-2h28a2 2 0 110 4h-28a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M174.666 159.576l13.026-27.848a3 3 0 012.717-1.728h6.519a1 1 0 01.906 1.423l-13.025 27.848a3.001 3.001 0 01-2.717 1.729h-6.52a1 1 0 01-.906-1.424z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M280.5 152.5l8 11.5"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M147.292 34.147a1.492 1.492 0 011.999.676l3.899 7.884 3.241-3.29a1.494 1.494 0 012.385.353l4.81 9.158a1.491 1.491 0 11-2.642 1.388l-3.86-7.349-3.279 3.328a1.492 1.492 0 01-2.4-.385l-4.829-9.764a1.493 1.493 0 01.676-2zM182.209 18.644c.779.27 1.192 1.12.922 1.899l-2.881 8.31h4.618a1.492 1.492 0 011.422 1.947l-3.148 9.853a1.492 1.492 0 11-2.843-.908l2.526-7.907h-4.672a1.492 1.492 0 01-1.41-1.981l3.568-10.292a1.492 1.492 0 011.898-.92zM234.375 55.974a1.493 1.493 0 01-1.762 1.162l-8.616-1.77.604 4.58a1.489 1.489 0 01-1.743 1.663l-10.181-1.832a1.492 1.492 0 11.529-2.937l8.169 1.47-.611-4.632a1.491 1.491 0 011.78-1.657l10.669 2.191a1.492 1.492 0 011.162 1.762zM221.795 83.83a1.493 1.493 0 01-2.095.258l-6.934-5.41-1.492 4.37a1.493 1.493 0 01-2.301.717l-8.308-6.162a1.492 1.492 0 111.777-2.397l6.668 4.944 1.509-4.42a1.492 1.492 0 012.33-.695l8.587 6.7c.65.508.766 1.445.259 2.095zM174.104 68.749a1.492 1.492 0 01-.251 2.095l-10.446 8.207a1.52 1.52 0 01-.15.104c-2.479 1.499-5.324 4.309-6.136 7.808-.771 3.326.193 7.769 6.322 12.9a1.492 1.492 0 11-1.916 2.288c-6.703-5.612-8.413-11.118-7.313-15.862 1.05-4.53 4.566-7.892 7.419-9.64l10.376-8.152a1.492 1.492 0 012.095.252z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M196.158 45.304c.484.667.337 1.6-.33 2.085l-9.49 6.895a1.492 1.492 0 11-1.754-2.415l9.49-6.895a1.492 1.492 0 012.084.33zM203.619 55.004c.484.666.337 1.6-.33 2.084l-9.49 6.895a1.492 1.492 0 11-1.754-2.415l9.49-6.894a1.492 1.492 0 012.084.33z"
        ></path>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          d="M188.448 74.103c-.001.001-.002.003-.001.004v.003a12.313 12.313 0 01-17.194-2.725 12.306 12.306 0 012.719-17.193v-.003l.001-.003 6.505-4.727a1.492 1.492 0 012.084.33l12.718 17.505c.485.667.337 1.6-.33 2.085l-6.502 4.724z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M179.601 48.252a2.985 2.985 0 014.169.66l12.718 17.506a2.984 2.984 0 01-.661 4.168l-6.41 4.658c-.03.025-.061.05-.094.073a13.801 13.801 0 01-21.744-13.327 13.804 13.804 0 015.418-8.933c.032-.028.065-.053.099-.078l6.505-4.727zm-4.659 7.074c-.03.026-.06.05-.092.073a10.816 10.816 0 004.67 19.435 10.819 10.819 0 007.963-1.869c.028-.024.057-.047.088-.069l6.502-4.724-12.718-17.505-6.413 4.66z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBicycleSpeedPedelec;
