import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// Components
import BrokerInfoHeader from './BrokerInfoHeader';

// Types
import BrokerInfoDetails from './BrokerInfoDetails';
import { mq } from '../../../../../../../common/src/utils/styledComponentsUtils';
import { Distribution } from '../../../../../../../app/src/features/authentication/types/Distribution';

interface Props {
  distribution: Distribution;
}

const BrokerInfo: React.FC<React.PropsWithChildren<Props>> = ({
  distribution: {
    name,
    fsma,
    address,
    company_registration,
    cbe,
    phone,
    email,
    website,
    logos,
    afm,
  },
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper data-test-id="ProdFac_BrokerInfo">
      <Title data-test-id="ProdFac_BrokerInfo_Title">
        {t('Contact your broker')}
      </Title>
      <BrokerInfoHeader
        logo={logos.logo}
        name={name}
        url={website}
        email={email}
        phone={phone}
      />
      <BrokerInfoDetails
        address={address}
        email={email}
        phone={phone}
        fsma={fsma?.key || afm || '-'}
        registrationType={company_registration.type || 'CBE'}
        registrationNumber={company_registration.nr || cbe}
      />
    </Wrapper>
  );
};

const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 125%;

  ${mq.tabletS} {
    font-size: 2.4rem;
  }

  ${mq.tablet} {
    font-size: 3.6rem;
  }
`;

const Wrapper = styled.div`
  padding: 0.8rem;
  max-width: 90vw;

  & > * {
    margin-bottom: 1.6rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${mq.tabletS} {
    padding: 1.6rem;
    & > * {
      margin-bottom: 2.4rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

export default BrokerInfo;
