import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsWarranty: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31 24a4 4 0 100 8 4 4 0 000-8zm-8 4a8 8 0 1116 0 8 8 0 01-16 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M27 31a2 2 0 012 2v5l.8-.6a2 2 0 012.4 0l.8.6v-5a2 2 0 014 0v9a2 2 0 01-3.2 1.6L31 41.5l-2.8 2.1A2 2 0 0125 42v-9a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 10c-1.095 0-2 .905-2 2v20a2 2 0 002 2h10a2 2 0 110 4H11a6 6 0 01-6-6V12c0-3.305 2.695-6 6-6h28a6 6 0 016 6v20.002a6 6 0 01-3 5.19 2 2 0 11-2-3.464 2 2 0 001-1.73V12a2 2 0 00-2-2H11z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 16a2 2 0 012-2h24a2 2 0 110 4H13a2 2 0 01-2-2zM11 22a2 2 0 012-2h6a2 2 0 110 4h-6a2 2 0 01-2-2zM11 28a2 2 0 012-2h4a2 2 0 110 4h-4a2 2 0 01-2-2z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsWarranty;
