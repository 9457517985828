import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationYacht: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3929_10285"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3929_10285)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M286.297 93.288l-27.944 66.433a1 1 0 01-.961.612l-147.277-5.849c-.907-.036-1.3-1.164-.613-1.756l45.068-38.852a1 1 0 01.653-.242h87.234c.265 0 .519-.106.707-.293l21.148-21.148a.997.997 0 01.707-.293h20.357a1 1 0 01.921 1.388z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M264.583 150.59l23.273 47.477c1.303 2.657-.632 5.76-3.592 5.76H63.371a4 4 0 01-1.698-.378l-28.669-13.438c-2.548-1.195-3.098-4.578-1.06-6.519l40.205-38.291a4.002 4.002 0 012.85-1.103l186.084 4.254a3.999 3.999 0 013.5 2.238z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M258.87 197.307h51.989a4 4 0 013.68 2.433l9.09 21.34c1.123 2.638-.813 5.567-3.68 5.567H55.42c-4.004 0-41.661-24.894-53.097-45.382-1.347-2.414.581-5.089 3.345-5.034l235.921 4.74a4 4 0 012.993 1.438l11.215 13.459a4 4 0 003.073 1.439z"
        ></path>
        <mask
          id="mask1_3929_10285"
          style={{ maskType: 'alpha' }}
          width="327"
          height="55"
          x="-1"
          y="174"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M258.87 197.307h51.989a4 4 0 013.68 2.433l9.09 21.34c1.123 2.638-.813 5.567-3.68 5.567H55.42c-4.004 0-41.661-24.894-53.097-45.382-1.347-2.414.581-5.089 3.345-5.034l235.921 4.74a4 4 0 012.993 1.438l11.215 13.459a4 4 0 003.073 1.439z"
          ></path>
        </mask>
        <g mask="url(#mask1_3929_10285)">
          <path
            fill={colors.primary[100]}
            d="M0 0H350.993V11.953H0z"
            transform="matrix(-1 0 0 1 341.753 206.543)"
          ></path>
          <rect
            width="14.127"
            height="8.693"
            fill={colors.primary[500]}
            rx="4.347"
            transform="matrix(-1 0 0 1 78.788 184.811)"
          ></rect>
          <rect
            width="14.127"
            height="8.693"
            fill={colors.primary[500]}
            rx="4.347"
            transform="matrix(-1 0 0 1 52.711 184.811)"
          ></rect>
          <path
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M258.87 197.307h51.989a4 4 0 013.68 2.433l9.09 21.34c1.123 2.638-.813 5.567-3.68 5.567H55.42c-4.004 0-41.661-24.894-53.097-45.382-1.347-2.414.581-5.089 3.345-5.034l235.921 4.74a4 4 0 012.993 1.438l11.215 13.459a4 4 0 003.073 1.439z"
          ></path>
        </g>
        <rect
          width="21.733"
          height="9.78"
          fill={colors.primary[500]}
          rx="4.89"
          transform="scale(-1 1) rotate(-1.936 4514.177 7590.294)"
        ></rect>
        <rect
          width="21.733"
          height="9.78"
          fill={colors.primary[500]}
          rx="4.89"
          transform="scale(-1 1) rotate(-1.936 4514.645 6722.02)"
        ></rect>
        <rect
          width="21.733"
          height="9.78"
          fill={colors.primary[500]}
          rx="4.89"
          transform="scale(-1 1) rotate(-1.936 4515.18 5821.784)"
        ></rect>
        <rect
          width="21.733"
          height="9.78"
          fill={colors.primary[500]}
          rx="4.89"
          transform="scale(-1 1) rotate(-1.936 4515.618 4953.509)"
        ></rect>
        <rect
          width="21.733"
          height="9.78"
          fill={colors.primary[500]}
          rx="4.89"
          transform="scale(-1 1) rotate(-1.936 4516.094 4053.242)"
        ></rect>
        <rect
          width="21.733"
          height="9.78"
          fill={colors.primary[500]}
          rx="4.89"
          transform="scale(-1 1) rotate(-1.936 4516.621 3184.969)"
        ></rect>
        <rect
          width="26.08"
          height="8.693"
          fill={colors.primary[500]}
          rx="4.347"
          transform="matrix(-1 0 0 1 201.572 122.871)"
        ></rect>
        <rect
          width="29.34"
          height="8.693"
          fill={colors.primary[500]}
          rx="4.347"
          transform="matrix(-1 0 0 1 167.883 122.871)"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M279.82 179.92h27.253a1 1 0 011 1v16.387M296.119 179.92v17.387"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationYacht;
