import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCarTinkerer: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3884_11375"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3884_11375)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M93.099 89.97h24.26v31.334h-12.765c-14.355 0-34.638-24.402-26.88-43.205.26-.634 1.08-.776 1.566-.29l10.99 10.99a4 4 0 002.829 1.172z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M226.465 174.686l-71.177-62.279c-9.121-8.97-12.379-15.542-12.379-30.173 0-23.446-35.089-35.793-48.773-25.672-.888.657-.881 1.935-.1 2.716l14.633 14.634c.376.375.586.883.586 1.414v13.558c0 .207-.032.414-.095.611l-11.29 35.158c-.949 2.956 1.7 5.868 4.779 5.465 13.739-1.796 27.896.097 32.524 4.725l62.666 68.469c6.576 6.575 20.889 4.642 27.078-1.548 6.19-6.189 8.124-20.502 1.548-27.078z"
        ></path>
        <mask id="path-6-inside-1_3884_11375" fill="#fff">
          <rect
            width="19.341"
            height="19.341"
            x="115.444"
            y="98.094"
            rx="2"
          ></rect>
        </mask>
        <rect
          width="19.341"
          height="19.341"
          x="115.444"
          y="98.094"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-6-inside-1_3884_11375)"
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M120.86 115.889l-4.256 13.539"
        ></path>
        <circle
          cx="209.831"
          cy="187.065"
          r="7.284"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M143.683 120.916l50.288 50.675"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M248.477 88.668l-23.339-23.336a3.296 3.296 0 00-4.667 0l-6.533 6.533a3.294 3.294 0 00-.716 3.597c.166.4.409.764.716 1.07l6.066 6.07-19.603 19.603a3.963 3.963 0 000 5.6l5.6 5.601a3.966 3.966 0 005.603 0l19.603-19.604 6.067 6.07a3.312 3.312 0 004.667 0l6.536-6.536a3.317 3.317 0 000-4.668z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M211.603 74.201L222.805 63l28.005 28.005-11.203 11.202-28.004-28.005z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M211.609 113.407a3.96 3.96 0 01-5.602 0l-5.601-5.6a3.962 3.962 0 010-5.6l19.604-19.603 11.203 11.2-19.604 19.603zM207.403 86.805l23.804 15.402M199 95.205l23.805 15.402"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationCarTinkerer;
