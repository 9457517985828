import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationPension: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3952_10205"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3952_10205)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M158.401 137.108a2.007 2.007 0 00-1.205-.216l-29.997 4.03a9 9 0 01-2.396-17.84l33.5-4.5c1.864-.25 3.76.09 5.421.972l16 8.5a9 9 0 013.725 12.17 9 9 0 01-12.17 3.726l-12.878-6.842zM149.719 162.204c4.064.948 6.524 4.929 5.578 8.852l-9.954 41.274c-.949 3.935-4.988 6.419-9.062 5.47-4.064-.948-6.524-4.929-5.578-8.852l9.954-41.274c.949-3.935 4.988-6.419 9.062-5.47zM183.09 160.297c3.864-1.576 8.172.252 9.667 4l15.729 39.436c1.499 3.76-.361 8.122-4.235 9.701-3.864 1.576-8.172-.252-9.667-4l-15.729-39.436c-1.499-3.76.361-8.122 4.235-9.701z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M140.59 154.676a2 2 0 002.12 1.535l36.347-3.16.058-.006c9.698-1.133 17.249-.606 24.272 1.795 6.8 2.325 12.468 6.231 18.27 10.285a8.999 8.999 0 012.223 12.532 8.999 8.999 0 01-12.532 2.223c-6.046-4.224-9.741-6.626-13.784-8.008-3.776-1.291-8.499-1.881-16.502-.932h0a8.557 8.557 0 01-.28.029l-46 4a9.002 9.002 0 01-9.542-6.911l-19-81a9 9 0 0117.525-4.11l16.825 71.728zM110.947 205.211a9 9 0 0111.348-5.764c19.297 6.298 33.561 9.18 47.492 9.162 13.939-.018 28.182-2.942 47.448-9.17a9 9 0 015.536 17.128c-20.104 6.499-36.365 10.02-52.96 10.042-16.603.022-32.904-3.459-53.1-10.05a9 9 0 01-5.764-11.348z"
        ></path>
        <g filter="url(#filter0_dd_3952_10205)">
          <circle cx="210.5" cy="79.5" r="42.5" fill="#fff"></circle>
          <circle
            cx="210.5"
            cy="79.5"
            r="40.5"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M188.872 89.953c0-8.876 8.005-20.9 16.801-25.203h9.166c8.8 4.309 16.802 16.333 16.802 25.209 0 10.847-9.58 16.042-21.391 16.042-11.811 0-21.378-5.202-21.378-16.048zM210.372 64.743s-1.939-2.092-4.003-4.232c-1.29-1.339-2.8-2.868-4.329-4.362a1.53 1.53 0 01-.303-1.77 1.527 1.527 0 011.588-.836 49.297 49.297 0 0014.094 0 1.528 1.528 0 011.282 2.612 180.727 180.727 0 00-4.328 4.362 328.04 328.04 0 00-4.001 4.226z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M213.739 61.178a16.429 16.429 0 011.934-1.46c2.679-1.24 3.896-.658 4.416.486.52 1.145.167 2.444-2.507 3.666a13.817 13.817 0 01-5.315.87h-1.896s2.786-2.952 3.368-3.562zM210.371 64.744c.609.535 4.904 4.505 4.553 9.198"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M207.006 61.178a16.484 16.484 0 00-1.934-1.46c-2.679-1.24-3.896-.658-4.416.486-.52 1.145-.167 2.444 2.505 3.666 1.699.636 3.505.932 5.317.87h1.895s-2.785-2.952-3.367-3.562zM210.373 64.744c-.609.535-4.903 4.505-4.552 9.198"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M215.687 95.204h-1.825a7.631 7.631 0 01-3.856-1.052 7.724 7.724 0 01-2.812-2.864h4.278c.374-.009.73-.165.992-.436a1.458 1.458 0 000-2.023 1.43 1.43 0 00-.992-.436h-5.234a7.09 7.09 0 010-1.897h7.624a1.42 1.42 0 001.037-.412 1.443 1.443 0 00.433-1.036 1.447 1.447 0 00-1.47-1.448h-6.668a7.72 7.72 0 012.812-2.862 7.632 7.632 0 013.856-1.052h1.825c.375-.009.731-.165.992-.436a1.453 1.453 0 000-2.023 1.427 1.427 0 00-.992-.436h-1.825a10.493 10.493 0 00-5.96 1.87 10.648 10.648 0 00-3.874 4.941h-2.425a1.43 1.43 0 00-1.357.885 1.465 1.465 0 00.32 1.599 1.435 1.435 0 001.037.411h1.75c-.027.313-.047.628-.047.945 0 .317.02.636.047.95h-1.75a1.43 1.43 0 00-1.357.885 1.465 1.465 0 00.32 1.598 1.433 1.433 0 001.037.412h2.425a10.648 10.648 0 003.874 4.94 10.496 10.496 0 005.96 1.87h1.825c.375-.009.731-.165.992-.436a1.453 1.453 0 000-2.023 1.427 1.427 0 00-.992-.436v.002z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_3952_10205"
          width="117"
          height="117"
          x="152"
          y="29"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3952_10205"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_3952_10205"
            result="effect2_dropShadow_3952_10205"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_3952_10205"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationPension;
