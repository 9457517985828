import React from 'react';
import styled from 'styled-components';

interface Props {
  label: string;
  value: string;
}

const BrokerInfoLabelValue: React.FC<React.PropsWithChildren<Props>> = ({
  label,
  value,
  ...otherProps
}) => {
  return (
    <div {...otherProps} data-test-id="ProdFac_BrokerInfoLabelValue_LabelValue">
      <Label>{label}</Label>
      <Value>{value || '-'}</Value>
    </div>
  );
};

const Label = styled.label`
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 120%;
`;

const Value = styled.p`
  font-size: 1.4rem;
  line-height: 120%;
  color: ${({ theme }) => theme.labels.guaranteeText};
`;

export default BrokerInfoLabelValue;
