const FormError = {
  baseStyle: {
    text: {
      color: 'danger.main',
      justifyContent: 'flex-end',
    },
  },
};

export default FormError;
