import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationAgility: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3824_10719"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3824_10719)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <rect
          width="276.299"
          height="180"
          x="88"
          y="329.148"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6"
          transform="rotate(-90 88 329.148)"
        ></rect>
        <rect
          width="256.523"
          height="140.449"
          x="107.776"
          y="329.148"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="6"
          transform="rotate(-90 107.776 329.148)"
        ></rect>
        <circle
          cx="107.492"
          cy="208.195"
          r="10.037"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="rotate(-90 107.492 208.195)"
        ></circle>
        <circle
          cx="107.492"
          cy="72.345"
          r="10.037"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="rotate(-90 107.492 72.345)"
        ></circle>
        <circle
          cx="248.502"
          cy="72.345"
          r="10.037"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="rotate(-90 248.502 72.345)"
        ></circle>
        <circle
          cx="248.502"
          cy="208.195"
          r="10.037"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="rotate(-90 248.502 208.195)"
        ></circle>
        <circle
          cx="194.337"
          cy="207.334"
          r="9.178"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="rotate(-90 194.337 207.334)"
        ></circle>
        <circle
          cx="143.608"
          cy="132.531"
          r="9.178"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="rotate(-90 143.608 132.531)"
        ></circle>
        <circle
          cx="187.458"
          cy="124.793"
          r="9.178"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="rotate(-90 187.458 124.793)"
        ></circle>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M10.287 190.386a6 6 0 014.416-7.36l214.339-50.922a1.976 1.976 0 011.004 3.823L18.325 196.823a5.999 5.999 0 01-7.462-4.243l-.576-2.194z"
        ></path>
        <mask
          id="mask1_3824_10719"
          style={{ maskType: 'alpha' }}
          width="226"
          height="70"
          x="8"
          y="130"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M10.29 190.386a6 6 0 014.417-7.36l214.339-50.922a1.977 1.977 0 011.003 3.823l-211.72 60.896a5.999 5.999 0 01-7.463-4.243l-.576-2.194z"
          ></path>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_3824_10719)"
        >
          <path
            fill={colors.primary[200]}
            d="M218.322 131.8H231.416V142.562H218.322z"
            transform="rotate(-14.706 218.322 131.8)"
          ></path>
          <path
            fill={colors.primary[200]}
            d="M0.178 179.411H59.89V209.598H0.178z"
            transform="rotate(-14.706 .178 179.411)"
          ></path>
          <path
            strokeLinecap="square"
            strokeLinejoin="round"
            d="M59.48 171.42l-4.064 15.124M51.96 173.393l-4.063 15.124M44.449 175.366l-4.064 15.124M36.93 177.338l-4.064 15.124M29.418 179.311l-4.063 15.124M21.899 181.284l-4.064 15.124M14.387 183.254l-4.064 15.124"
          ></path>
          <path d="M10.29 190.386a6 6 0 014.417-7.36l214.339-50.922a1.977 1.977 0 011.003 3.823l-211.72 60.896a5.999 5.999 0 01-7.463-4.243l-.576-2.194z"></path>
        </g>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationAgility;
