import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyLandlord: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M30.38 24h-.19L33 26h5.34a5.11 5.11 0 012.59-2.59v-3.84a4.91 4.91 0 01-2-1.57 5.259 5.259 0 01-.59-1H22.47c-.16.354-.357.69-.59 1a4.909 4.909 0 01-2 1.57v.61a8.083 8.083 0 012-.23 8.48 8.48 0 015 1.64l1.19.86a2.56 2.56 0 01-.19-.95 2.5 2.5 0 112.5 2.5z"
    ></path>
    <path
      fill="currentColor"
      d="M18.88 20.47v-1.71a4.23 4.23 0 001-.42A3.93 3.93 0 0021.22 17a4.23 4.23 0 00.42-1h17.48c.097.35.238.686.42 1a3.931 3.931 0 001.34 1.34c.314.182.65.323 1 .42v5.48c-.353.088-.69.23-1 .42-.55.33-1.01.79-1.34 1.34-.19.31-.332.647-.42 1h-5c.436.57.63 1.288.54 2h9.23V14h-27v7.23l1.95-.74.04-.02z"
    ></path>
    <path
      fill="currentColor"
      d="M43.9 31.32h-.31L32 33l-6.43-2.75a.95.95 0 01-.49-1.25.93.93 0 01.87-.58.999.999 0 01.33.06l1.38.52 3.6 1.35a1.79 1.79 0 002.32-1.11c.03-.077.05-.158.06-.24a1.78 1.78 0 00-.7-1.77l-.31-.23-1.38-1-5-3.61A7.57 7.57 0 0021.84 21a7.76 7.76 0 00-2.7.48l-.3.11-2 .75-1.36.51a3.08 3.08 0 01-1.08.2H9V21.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h5a.5.5 0 00.5-.5V33h5.58c.276 0 .55.048.81.14L31 38.82c.321.114.66.175 1 .18a3.06 3.06 0 001-.16l11.47-3.39a2.11 2.11 0 00-.59-4.13h.02z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyLandlord;
