import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsChevronLeft: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20.828 25l11.586-11.586-2.828-2.828L15.172 25l14.414 14.414 2.828-2.828L20.828 25z"
      clipRule="evenodd"
    />
  </Icon>
);

export default GeneralActionsChevronLeft;
