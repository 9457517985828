import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationAlarm: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="356"
      height="228"
      fill="none"
      viewBox="0 0 356 228"
      {...otherProps}
    >
      <mask
        id="mask0_5603_12805"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_5603_12805)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 010 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M178.003 63.613l-71.302 71.297v91.13H249.3v-91.13l-71.297-71.297z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M249.3 56.474a7.313 7.313 0 00-7.312-7.312h-12.084a7.313 7.313 0 00-7.312 7.312v14.464l26.734 26.422-.026-40.886z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M272.119 119.92l-81.586-80.642a17.826 17.826 0 00-25.066 0L83.881 119.92a13.368 13.368 0 0018.904 18.904l75.218-75.211 75.212 75.211a13.367 13.367 0 0018.904-18.904z"
        ></path>
      </g>
      <path
        fill={colors.primary[200]}
        d="M166.31 136.126c.972-1.719 3.165-5.101 6.48-7.448l1.644-.939a4.999 4.999 0 016.248 1.054l5.719 6.555c.075.085.149.165.229.245l11.499 11.499a4.992 4.992 0 011.452 3.187l1.324 19.057a5.13 5.13 0 00.753 2.332c1.166 1.903 3.531 5.883 4.369 8.169h-48.858c-3.278 0-5.664-3.097-4.85-6.273 1.842-7.183 4.787-18.912 6.33-26.323a4.55 4.55 0 01.468-1.286c.84-1.522 3.171-5.53 5.682-7.929a8.298 8.298 0 001.511-1.9z"
      ></path>
      <path
        fill={colors.primary[200]}
        d="M169.262 192.161l-3.697-12.322h24.029l-3.389 12.322-7.701 3.081-9.242-3.081z"
      ></path>
      <path
        stroke={colors.primary[500]}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M166.5 180.167V184c0 3.05 1.212 5.975 3.368 8.132a11.504 11.504 0 0016.264 0A11.504 11.504 0 00189.5 184v-3.833m23-39.38a42.35 42.35 0 00-10.71-14.287m-58.29 14.287a42.352 42.352 0 0110.703-14.287m16.13 7.667a7.667 7.667 0 1115.334 0 26.833 26.833 0 0115.333 23v11.5a15.33 15.33 0 007.667 11.5h-61.334a15.335 15.335 0 007.667-11.5v-11.5a26.832 26.832 0 0115.333-23z"
      ></path>
    </svg>
  );
};

export default IllustrationAlarm;
