import useIsAuthorized from '../../hooks/useIsAuthorized';
import { isSocialLoginUrl } from '../../constants/SocialAuth';
import { wgApi } from '../../../api/wgApi';
import { UserData } from '../../types/UserData';
import { useQuery, UseQueryResult } from 'react-query';
import { useLocation } from 'react-router';
import { isStaging } from '../../../../../../common/src/utils/globalVariables';
import { useUser } from './getUser';

const getUserData = async () => {
  const { data } = await wgApi.get<UserData>('v1/api/users/me');
  return data;
};

export const getUserDataQKey = (): string[] => ['user', 'data'];

export const useUserData = (): UseQueryResult<UserData> => {
  const { pathname } = useLocation();
  const isAuthorized = useIsAuthorized();
  const { data: broker } = useUser();

  return useQuery<UserData>(getUserDataQKey(), getUserData, {
    cacheTime: Infinity,
    staleTime: Infinity,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: isAuthorized && !isSocialLoginUrl,

    // Handle user redirect to correct domain
    onSuccess: (data) => {
      const host = window.location.host;

      if (
        !isStaging &&
        data.allowed_app_hosts &&
        data.allowed_app_hosts?.length > 0 &&
        data.preferred_app_host &&
        !data.allowed_app_hosts.includes(host)
      ) {
        const url = new URL(
          `${window.location.protocol}//${data.preferred_app_host}${pathname}`,
        );

        if (data.login_token)
          url.searchParams.set('login_token', data.login_token);

        if (broker?.id) url.searchParams.set('broker_id', broker?.id);

        window.location.replace(url);
      }
    },
  });
};
