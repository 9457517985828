import React, { PropsWithChildren } from 'react';
import LoadingPage from '../../../pages/LoadingPage';
import { isSocialLoginUrl } from '../../authentication/constants/SocialAuth';
import useAuth from '../../authentication/hooks/useAuth';

const SocialRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    isAuthorized,
    isAllSocialUserDataFetched,
    isAllConversationsDataFetched,
  } = useAuth();

  if (
    isAuthorized &&
    isSocialLoginUrl &&
    (!isAllSocialUserDataFetched || !isAllConversationsDataFetched)
  )
    return <LoadingPage />;

  return <>{!isAuthorized ? <LoadingPage /> : children}</>;
};

export default SocialRoute;
