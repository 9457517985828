import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusFinished: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.542 7.952a2 2 0 012 2V43a2 2 0 01-4 0V9.952a2 2 0 012-2zM39.458 7.952a2 2 0 012 2v18.59a2 2 0 01-4 0V9.952a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.77 9c-2.179 0-4.272.855-5.828 2.38a2 2 0 01-2.8-2.856 12.327 12.327 0 0117.258 0 8.328 8.328 0 0011.659 0 2 2 0 012.8 2.857 12.328 12.328 0 01-17.259 0A8.327 8.327 0 0017.77 9zM17.77 27.59c-2.179 0-4.272.854-5.828 2.38a2 2 0 01-2.8-2.857 12.327 12.327 0 0117.258 0 8.328 8.328 0 0011.659 0 2 2 0 112.8 2.857 12.327 12.327 0 01-17.259 0 8.327 8.327 0 00-5.83-2.38z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralStatusFinished;
