import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersClock: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 14.471c-5.789 0-10.5 4.73-10.5 10.587 0 5.856 4.711 10.586 10.5 10.586s10.5-4.73 10.5-10.586C35.5 19.2 30.789 14.47 25 14.47zM11.5 25.058c0-7.494 6.034-13.587 13.5-13.587s13.5 6.093 13.5 13.587c0 7.493-6.034 13.586-13.5 13.586S11.5 32.55 11.5 25.058z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 16.306a1.5 1.5 0 011.5 1.5v6.327l3.975 2.002a1.5 1.5 0 01-1.35 2.68l-4.8-2.418a1.5 1.5 0 01-.825-1.34v-7.251a1.5 1.5 0 011.5-1.5z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersClock;
