import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSegway: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_698_3309"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_698_3309)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <mask id="path-3-inside-1_698_3309" fill="#fff">
          <rect
            width="16"
            height="57.436"
            x="197.427"
            y="106.407"
            rx="2"
            transform="rotate(5 197.427 106.407)"
          ></rect>
        </mask>
        <rect
          width="16"
          height="57.436"
          x="197.427"
          y="106.407"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-3-inside-1_698_3309)"
          rx="2"
          transform="rotate(5 197.427 106.407)"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M211.192 161.627l-38.109 3.718a1 1 0 00-.758 1.514l17.32 28.555c.214.353.623.538 1.029.467l15.635-2.76a1 1 0 00.812-.812l5.153-29.515a1 1 0 00-1.082-1.167z"
        ></path>
        <circle
          cx="171.5"
          cy="194.5"
          r="31.5"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <circle
          cx="171.5"
          cy="194.5"
          r="10.5"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <rect
          width="6"
          height="45"
          x="205.758"
          y="64.727"
          fill={colors.primary[500]}
          rx="2"
          transform="rotate(5 205.758 64.727)"
        ></rect>
        <rect
          width="27"
          height="6"
          x="179"
          y="57.999"
          fill={colors.primary[500]}
          rx="3"
        ></rect>
        <mask id="path-9-inside-2_698_3309" fill="#fff">
          <rect width="19" height="15" x="199" y="52.999" rx="2"></rect>
        </mask>
        <rect
          width="19"
          height="15"
          x="199"
          y="52.999"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-9-inside-2_698_3309)"
          rx="2"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationSegway;
