import React from 'react';

import { Box, BoxProps, ComponentWithAs } from '@wegroup/design-system';

const StrokeBox: ComponentWithAs<'div', BoxProps> = ({
  children,
  ...props
}) => {
  return (
    <Box
      bg="white"
      boxShadow="level1"
      borderRadius="lg"
      border="1px solid"
      borderColor="gray.strokeAndBg"
      p="4"
      {...props}
    >
      {children}
    </Box>
  );
};

export default StrokeBox;
