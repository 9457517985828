import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilityEStep: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M40.5 29a6.5 6.5 0 00-6 4h-10a2.5 2.5 0 00-2 1h-1.2a.51.51 0 01-.45-.28l-5.24-10.48 3.25-11.72c.092-.346.139-.702.14-1.06V9a1 1 0 00-1-1h-7.5a1.5 1.5 0 000 3h5.39l-5 18.15a6.5 6.5 0 102.73 1.32l.79-2.86 4.32 8.83a1 1 0 00.89.56h2.89a2.5 2.5 0 002 1h10a6.5 6.5 0 106-9h-.01zm-31 9a2.5 2.5 0 110-5 2.5 2.5 0 010 5zm31 0a2.5 2.5 0 110-5 2.5 2.5 0 010 5z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilityEStep;
