import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsDrag: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M15 24.25a3.75 3.75 0 107.5 0 3.75 3.75 0 00-7.5 0zM27.5 24.25a3.75 3.75 0 107.5 0 3.75 3.75 0 00-7.5 0zM15 37.25a3.75 3.75 0 107.5 0 3.75 3.75 0 00-7.5 0zM27.5 37.25a3.75 3.75 0 107.5 0 3.75 3.75 0 00-7.5 0zM15 11.25a3.75 3.75 0 107.5 0 3.75 3.75 0 00-7.5 0zM27.5 11.25a3.75 3.75 0 107.5 0 3.75 3.75 0 00-7.5 0z"
    />
  </Icon>
);

export default GeneralActionsDrag;
