import { setThemeName } from '../../../common/src/store/redux/slices/generalDataSlice';
import {
  useGlobalDispatch,
  useGlobalSelector,
} from '../../../common/src/utils/globalVariables';
import { ThemeName } from '../../../design-system/src/theme/themes';

interface UseAppTheme {
  appTheme: ThemeName;
  setAppTheme: (arg0: ThemeName) => void;
}

const useAppTheme = (): UseAppTheme => {
  const globalDispatch = useGlobalDispatch();
  const appTheme =
    useGlobalSelector((state) => state.generalData.themeName) || 'orange';

  const setAppTheme = (theme: ThemeName) => {
    globalDispatch(setThemeName(theme));
  };

  return {
    appTheme,
    setAppTheme,
  };
};

export default useAppTheme;
