import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusWarningExclamation: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M27 10v15.3l-.58 6.7h-2.83L23 25.3V10h4zM25 40a3 3 0 100-6 3 3 0 000 6z"
    />
  </Icon>
);

export default GeneralStatusWarningExclamation;
