import React from 'react';
import { HStack, Image, Skeleton } from '@wegroup/design-system';
import LabeledParam from '../../../../../common/src/components/LabeledParam';
import { useTranslation } from 'react-i18next';

interface Props {
  name: string;
  logo: string;
}

const OfferHeader: React.FC<Props> = ({ name, logo }) => {
  const { t } = useTranslation();
  return (
    <HStack justify="space-between">
      <LabeledParam label={t('Insurance name')}>{name}</LabeledParam>
      <Image
        maxW="28"
        maxH="8"
        src={logo}
        alt="logo of insurance company"
        fallback={<Skeleton w="28" h="8" />}
      />
    </HStack>
  );
};

export default OfferHeader;
