import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralWeatherWind: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.821 6.595a6.472 6.472 0 11.942 12.858H12.062a1.85 1.85 0 010-3.699h15.753a2.774 2.774 0 10-2.597-3.594 1.85 1.85 0 01-3.532-1.095 6.472 6.472 0 015.135-4.47zM38.527 27.235a2.773 2.773 0 00-1.467-.386H8.363a1.85 1.85 0 110-3.698H37.01a6.472 6.472 0 11-6.077 8.388 1.85 1.85 0 013.532-1.096 2.773 2.773 0 104.063-3.208z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.885 34.632a2.773 2.773 0 00-1.467-.387H10.213a1.85 1.85 0 010-3.698h10.153a6.472 6.472 0 11-6.076 8.388 1.85 1.85 0 013.532-1.095 2.775 2.775 0 104.063-3.208z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralWeatherWind;
