import React, { PropsWithChildren } from 'react';
import ReAuthenticateComponentV3 from '../../features/authentication/components/ReAuthenticateComponentV3';
import { isSocialLoginUrl } from '../../features/authentication/constants/SocialAuth';
import useAuth from '../../features/authentication/hooks/useAuth';

const CaptchaProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAuthorized, isAccessTokenExpired, distribution } = useAuth();

  if (!isSocialLoginUrl) return <>{children}</>;

  return (
    <ReAuthenticateComponentV3
      isAuthorized={isAuthorized}
      isAccessTokenExpired={isAccessTokenExpired}
      email={distribution?.email || ''}
    >
      {children}
    </ReAuthenticateComponentV3>
  );
};

export default CaptchaProvider;
