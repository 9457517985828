import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilityAllRisk10: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M26.5 18a2.5 2.5 0 012.5 2.5v10a2.5 2.5 0 01-5 0v-10a2.5 2.5 0 012.5-2.5zm0-4a6.5 6.5 0 00-6.5 6.5v10a6.5 6.5 0 1013 0v-10a6.5 6.5 0 00-6.5-6.5zM18 16.11V35a2 2 0 01-4 0V21.5l-1.4 1.68a2.06 2.06 0 01-2.61.53 2 2 0 01-.53-3l5-6a2 2 0 012.69-.36 2.16 2.16 0 01.85 1.76zM6.5 34A1.5 1.5 0 008 32.48V31h1.5a1.5 1.5 0 100-3H8v-1.5a1.5 1.5 0 00-3-.02V28H3.5a1.5 1.5 0 000 3H5v1.5A1.5 1.5 0 006.5 34zM37 21.05a3 3 0 100 6 3 3 0 000-6zM44 31a3 3 0 100 5.999A3 3 0 0044 31zM45.33 22.33a1.6 1.6 0 00-2.25.36l-7.74 10.78a1.611 1.611 0 00.37 2.25c.107.075.22.138.34.19a1.61 1.61 0 001.9-.56l7.75-10.78a1.599 1.599 0 00-.37-2.24z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilityAllRisk10;
