import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersDocumentOrFile: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15 5a6 6 0 00-6 6v29a6 6 0 006 6h20a6 6 0 006-6V18.414a3 3 0 00-.879-2.121L29.707 5.879A3 3 0 0027.586 5H15zm-2 6a2 2 0 012-2h11v8a4 4 0 004 4h7v19a2 2 0 01-2 2H15a2 2 0 01-2-2V11zm22.172 6L30 11.828V17h5.172z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralOthersDocumentOrFile;
