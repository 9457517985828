import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleForwardCollisionWarning: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M30.333 29.62a1.44 1.44 0 00-1-.84l-2.09-.45-16.22-3.52a1.32 1.32 0 01-.82-.53l-3.72-5a.84.84 0 00-.44-.28l-3.26-.6a.391.391 0 00-.45.39V40h10a6.5 6.5 0 1013 0h4.51a1.5 1.5 0 00.5-.79c.505-1.973.88-3.978 1.12-6-.21-1.243-.59-2.45-1.13-3.59zm-8 10.38a3.5 3.5 0 11-7 0 2.747 2.747 0 010-.5 3.5 3.5 0 017 0c.016.166.016.334 0 .5zM46.996 25.14l-12.57 2.3c.48.37.859.855 1.1 1.41 1.11 2.68 1.25 3.73 1.25 4.34a44.753 44.753 0 01-1.18 6.46 3.51 3.51 0 01-1.16 1.84 6.19 6.19 0 0012.1-1.17h.62a.55.55 0 00.55-.59V25.68a.561.561 0 00-.71-.54zm-3.33 15.21a3.45 3.45 0 01-6.78 0 5.194 5.194 0 01-.06-.69 3.458 3.458 0 013.455-3.646 3.46 3.46 0 013.455 3.646 3.946 3.946 0 01-.07.69zM31.491 15c-2.247 0-4.439.575-6.275 1.648a.411.411 0 00-.205.25.346.346 0 00.06.296l1.754 2.391a.478.478 0 00.142.12c.056.032.12.055.187.066a.662.662 0 00.382 0c1.205-.687 2.64-1.043 4.101-1.017 1.462.025 2.878.43 4.046 1.159a.523.523 0 00.29.087h.093a.576.576 0 00.33-.175l1.858-2.336a.349.349 0 00.072-.292.402.402 0 00-.19-.254c-1.893-1.24-4.23-1.925-6.645-1.943zM41.485 11.76c-2.761-2.157-6.196-3.363-9.754-3.424-3.558-.062-7.036 1.024-9.876 3.085a.44.44 0 00-.122.597l1.704 2.502a.416.416 0 00.304.2.49.49 0 00.353-.083 12.567 12.567 0 017.573-2.361c2.728.046 5.362.968 7.482 2.619a.41.41 0 00.28.105h.06a.464.464 0 00.317-.175l1.777-2.457a.431.431 0 00-.098-.608z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleForwardCollisionWarning;
