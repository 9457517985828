import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';

import { getInitialsByName } from '../../../../../common/src/legacy/services/stringService';

import {
  Text,
  Box,
  Center,
  Icon,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  useTheme,
  Portal,
  Skeleton,
  VStack,
} from '@wegroup/design-system';
import SearchInput from '../SearchInput';
import AvatarPickerMenuItems from './AvatarPickerMenuItems';

export interface AvatarPickerOption {
  label: string;
  value: string;
}

interface Props {
  placeholder?: string;

  // Selecting
  options: AvatarPickerOption[];
  selectedOption?: AvatarPickerOption;
  onSelect: (newOption: AvatarPickerOption | undefined) => void;
  onClear?: () => void;

  // Searching
  searchedValue?: string;
  onSearch?: (newSearch: string) => void;
  isLoading?: boolean;
}

const AvatarPicker: React.FC<React.PropsWithChildren<Props>> = ({
  placeholder,
  // Selecting
  options,
  selectedOption,
  onSelect,
  onClear,
  // Searching
  searchedValue,
  onSearch,
  isLoading,
}) => {
  const { colors } = useTheme();
  // Sort alphabeticly and make sure initialbroker is on top
  const sortedOptions = options.sort((a, b) => {
    if (a.value === selectedOption?.value) return -1;
    if (b.value === selectedOption?.value) return 1;
    return a.label.toLowerCase() > b.label.toLowerCase()
      ? 1
      : b.label.toLowerCase() > a.label.toLowerCase()
      ? -1
      : 0;
  });
  const [items, setItems] = useState(sortedOptions);
  const { initials, firstNameWithInitials } = getInitialsByName(
    selectedOption?.label,
  );

  useEffect(() => {
    if (!items || items.length === 0) setItems(sortedOptions);
  }, [options]);

  const handleSelect = (option: AvatarPickerOption) => {
    if (isEqual(option, selectedOption)) {
      onSelect(undefined); // unselect
    } else {
      onSelect(option);
    }
  };

  return (
    <Menu closeOnBlur onClose={() => setItems(sortedOptions)} isLazy>
      <HStack
        _hover={{
          '.AvatarPicker_initials': {
            color: 'primary.300',
          },
          '.AvatarPicker_label': {
            textDecor: 'underline',
          },
          '.AvatarPicker_clear': {
            visibility: 'visible',
          },
        }}
      >
        {/* Main component displaying the selected name */}
        <MenuButton>
          <HStack spacing="2">
            <Center
              w="8"
              h="8"
              borderRadius="full"
              border="1px"
              borderColor="gray.300"
              borderStyle={initials ? 'none' : 'dashed'}
              bg={initials ? 'primary.50' : 'gray.strokeAndBg'}
              flexShrink={0}
            >
              {initials && (
                <Text
                  className="AvatarPicker_initials"
                  textStyle="smallBodyBold"
                  color="primary.500"
                >
                  {initials}
                </Text>
              )}
            </Center>
            <Text
              className="AvatarPicker_label"
              textStyle="normalBody"
              color="gray.400"
            >
              {firstNameWithInitials || placeholder}
            </Text>
          </HStack>
        </MenuButton>

        {/* "x" that clears the state */}
        {onClear && selectedOption && (
          <Box
            className="AvatarPicker_clear"
            cursor="pointer"
            visibility="hidden"
            _hover={{
              path: {
                fill: 'black',
              },
            }}
            onClick={onClear}
          >
            <Icon name="IconActionClose" color={colors.gray[400]} />
          </Box>
        )}
      </HStack>

      {/* Dropdown menu */}
      <Portal>
        <MenuList p="0" zIndex="2">
          {onSearch && (
            <Box p="2" borderBottom="1px solid" borderColor="gray.strokeAndBg">
              <SearchInput
                value={searchedValue}
                onChange={(e) => onSearch(e.currentTarget.value)}
              />
            </Box>
          )}
          {isLoading ? (
            <VStack p="4" spacing="4">
              {[1, 2, 3].map((index) => (
                <Skeleton key={index} h="4" w="100%" />
              ))}
            </VStack>
          ) : (
            <Box maxH="80" overflowY="auto">
              <AvatarPickerMenuItems
                onClick={handleSelect}
                selectedOption={selectedOption}
                items={items}
              />
            </Box>
          )}
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default AvatarPicker;
