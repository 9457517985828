import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsLegalNoHeavyMachinery: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M38 15a3 3 0 100-6 3 3 0 000 6zM40 16l-2 2-2-2h-1.17a2.001 2.001 0 00-1.42.58l-9 9a1.49 1.49 0 000 2.12 1.51 1.51 0 002.12 0L34 20.23V41.5a1.5 1.5 0 103 0V33a1 1 0 012 0v8.5a1.5 1.5 0 103 0V30.91c.161.056.33.086.5.09a1.5 1.5 0 001.5-1.5V18a2 2 0 00-2-2h-2zM18 21h-3v10h3V21zM23 25h-3v6h3v-6z"
    ></path>
    <path fill="currentColor" d="M28 29h-3v2h3v-2z"></path>
    <path
      fill="currentColor"
      d="M32.19 15c.39-.342.841-.607 1.33-.78A5 5 0 0133 12h-8v-1.5a1.5 1.5 0 10-3 0V12H9.5a1.5 1.5 0 100 3h.5v21h10.88l-4.44 4.44a1.5 1.5 0 002.12 2.12L22 39.12v2.38a1.5 1.5 0 103 0v-2.38l3.44 3.44a1.5 1.5 0 102.12-2.12L26.12 36H32v-3H13V15h19.19z"
    ></path>
  </Icon>
);

export default RiskObjectsLegalNoHeavyMachinery;
