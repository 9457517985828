import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, VStack, Text, Spacer } from '@wegroup/design-system';
import StrokeBox from '../../../../../dashboard/src/components/common/StrokeBox';

interface Props {
  title: string;
  description: string;
  illustration: ReactElement;
  onButtonClick?: () => void;
  buttonText?: string;
  customButton?: ReactElement;
}

const IllustrationActionCard: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  description,
  illustration,
  onButtonClick,
  buttonText,
  customButton,
}) => {
  const { t } = useTranslation();

  return (
    <StrokeBox as={VStack} p="6" pt="8" spacing="6" flex={1}>
      {illustration}

      <VStack spacing="3.5" flexGrow={1}>
        <Text textAlign="center" textStyle="normalBodyMedium" color="gray.700">
          {title}
        </Text>
        <Text textAlign="center" textStyle="smallBody" color="gray.500">
          {description}
        </Text>
        <Spacer />
        {customButton || (
          <Button w="100%" variant="primary" onClick={onButtonClick}>
            {buttonText || t('DOWNLOAD')}
          </Button>
        )}
      </VStack>
    </StrokeBox>
  );
};

export default IllustrationActionCard;
