import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSmartphone: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_691_2542"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_691_2542)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M127.995 81.1v134.511-134.51zm0 0A12.09 12.09 0 01140.089 69l-12.094 12.102zm12.094 144.606h-.001a10.103 10.103 0 01-7.138-2.956 10.083 10.083 0 01-2.955-7.138V81.099A10.089 10.089 0 01140.089 71h62.725a10.097 10.097 0 0110.094 10.095V215.61s0 0 0 0a10.1 10.1 0 01-10.097 10.095h-62.722z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M137.554 93.67h67.797v107.476h-67.797V93.67z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M167.673 80.335l20.784-.002"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M158.225 80.333a1.887 1.887 0 01-1.167 1.745 1.888 1.888 0 01-2.468-2.468 1.887 1.887 0 011.746-1.167 1.889 1.889 0 011.889 1.89z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M164.838 213.424a6.615 6.615 0 006.613 6.614 6.617 6.617 0 006.614-6.614 6.613 6.613 0 00-13.227 0z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationSmartphone;
