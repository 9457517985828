import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilitySharedEconomy: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M37.77 19.5l-2.64 2.62a.48.48 0 01-.7 0l-1.22-1.21c-.1-.09-.21 0-.21.11V35.5a.5.5 0 01-.5.5h-15a.5.5 0 01-.5-.5V21c0-.13-.11-.2-.21-.11l-1.22 1.21a.48.48 0 01-.7 0l-2.64-2.6a.498.498 0 010-.71l4.25-4.21a2 2 0 011.39-.58H21a.2.2 0 01.19.14 3.93 3.93 0 007.56 0A.2.2 0 0129 14h3.16a2 2 0 011.39.58l4.25 4.21a.5.5 0 01-.03.71z"
    ></path>
    <path
      fill="currentColor"
      d="M23.85 4a21 21 0 00-9.67 39H13a2 2 0 000 4h6a2 2 0 002-2v-6a2 2 0 10-4 0v.76a16.78 16.78 0 119.92 1.93 2.101 2.101 0 10.15 4.2h.21A21.015 21.015 0 0023.85 4z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilitySharedEconomy;
