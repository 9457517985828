import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsCompare: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M34.234 18.257H10.371A1.375 1.375 0 019 16.886c0-.732.594-1.372 1.371-1.372H34.19c.731 0 1.371.595 1.371 1.372 0 .731-.594 1.371-1.326 1.371z"
    ></path>
    <path
      fill="currentColor"
      d="M16.909 24.749c-.366 0-.686-.138-.96-.412L9.41 17.846a1.41 1.41 0 010-1.92l6.492-6.492a1.326 1.326 0 011.92 0 1.326 1.326 0 010 1.92l-5.532 5.532 5.578 5.531a1.41 1.41 0 010 1.92c-.275.274-.64.412-.96.412zM39.629 34.486H15.81a1.375 1.375 0 01-1.371-1.372c0-.731.594-1.371 1.371-1.371H39.63c.731 0 1.371.594 1.371 1.371s-.594 1.372-1.371 1.372z"
    ></path>
    <path
      fill="currentColor"
      d="M33.137 40.977c-.365 0-.685-.137-.96-.411a1.41 1.41 0 010-1.92l5.577-5.532-5.577-5.531a1.326 1.326 0 010-1.92 1.326 1.326 0 011.92 0l6.492 6.491a1.41 1.41 0 010 1.92l-6.492 6.537a1.475 1.475 0 01-.96.366z"
    ></path>
  </Icon>
);

export default GeneralActionsCompare;
