import React, { PropsWithChildren } from 'react';
import { isSocialLoginUrl } from '../../features/authentication/constants/SocialAuth';
import useAuth from '../../features/authentication/hooks/useAuth';
import BrokerProvider from './BrokerProvider';
import SocialProvider from './SocialProvider';

/**
 * TODO: Remove this component once react router v6 migration is done and use nested routing instead
 */
const ConditionalProviders: React.FC<PropsWithChildren> = ({ children }) => {
  const { role } = useAuth();

  if (role === 'BROKER') {
    return <BrokerProvider>{children}</BrokerProvider>;
  }

  // TODO: Because of the app structure this check can't be used right now, although this should change in the future
  // if (role === 'SOCIAL') {
  if (isSocialLoginUrl) {
    return <SocialProvider>{children}</SocialProvider>;
  }

  return <>{children}</>;
};

export default ConditionalProviders;
