import { Userpilot } from 'userpilot';
import React, { useEffect } from 'react';
import useAuth from '../../features/authentication/hooks/useAuth';
import { useLocation } from 'react-router';
import useUserpilot from '../../../../common/src/hooks/tracking/useUserpilot';

const USERPILOT_ID = 'NX-01a823ec';

export const UserPilotProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { pathname } = useLocation();
  const { user, ssoData } = useAuth();
  const { shouldTrackUserpilot, update } = useUserpilot();

  useEffect(() => {
    Userpilot.reload();
  }, [pathname]);

  useEffect(() => {
    if (shouldTrackUserpilot) {
      Userpilot.initialize(USERPILOT_ID);
      update({
        name: user?.name,
        email: user?.email,
        created_at: user?.created_at,

        company: {
          name: user?.distribution_name,
          id: user?.distribution_id,
          created_at: user?.created_at,
        },

        // Additional user properties
        language: user?.language,
        locale_code: user?.language?.toLowerCase(),
        country_code: user?.country_code,
        assistant_name: user?.assistant_name,
        role: user?.is_admin ? 'admin' : 'user',
        plan: user?.plan,
        phone: user?.phone,
        crm: user?.crm,
        user_id: user?.user_id,
        customer_segment: ssoData.data?.metadata?.customer_segment,
      });
    }
  }, [shouldTrackUserpilot]);

  return <>{children}</>;
};
