import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertySnowPressure: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M33.39 37.73l-.66-.38.57-.08a.809.809 0 00.4-.18.87.87 0 00.32-.8.89.89 0 00-1-.73l-2.31.36h-.29l-.23-.13-2.38-1.37-.1-.06a2.6 2.6 0 000-1l.1-.06 2.37-1.37.23-.13h.3l2.31.35a.868.868 0 001-.72.86.86 0 00-.71-1l-.58-.08.67-.39a.86.86 0 00-.86-1.48l-.66.38.21-.54a.8.8 0 00.05-.43.86.86 0 00-1.65-.19l-.85 2.18-.11.27-.23.13-2.38 1.38-.08.05a2.694 2.694 0 00-.44-.31 2.622 2.622 0 00-.48-.22v-3.05l.18-.24 1.47-1.82a.87.87 0 00-.14-1.2.88.88 0 00-1.21.12l-.35.46v-.77a.86.86 0 00-1.72 0v.77l-.37-.45a.88.88 0 00-1.21-.12.87.87 0 00-.13 1.2l1.46 1.82.18.24v3.12c-.33.114-.636.29-.9.52l-2.48-1.44-.22-.13-.11-.27-.85-2.18a.86.86 0 00-1.65.19.79.79 0 00.05.43l.21.54-.67-.39A.86.86 0 0016.6 30l.67.39-.57.08a.811.811 0 00-.4.18.85.85 0 00-.191 1.119.872.872 0 00.881.4l2.31-.35.29-.05.23.14 2.48 1.42c-.063.331-.063.67 0 1l-2.48 1.43-.22.13h-.3l-2.3-.32a.85.85 0 00-1 .71.82.82 0 00.31.8.67.67 0 00.4.18l.57.09-.67.38a.87.87 0 00-.09 1.433.85.85 0 00.95.058l.66-.38-.2.53a.75.75 0 00-.05.44.84.84 0 00.54.67.86.86 0 001.1-.48l.86-2.18.1-.27.23-.14L23.17 36c.135.114.279.218.43.31.152.087.313.157.48.21v3.12l-.18.23-1.46 1.83a.87.87 0 00.13 1.2.85.85 0 00.85.12.699.699 0 00.36-.25l.36-.45v.77a.86.86 0 101.72 0v-.77l.35.45a.7.7 0 00.36.25.85.85 0 00.85-.12.87.87 0 00.14-1.2l-1.47-1.83-.18-.24V36.5c.334-.114.643-.29.91-.52l.1.06 2.37 1.37.23.13.12.28.84 2.18a.86.86 0 001.11.48.839.839 0 00.54-.67.93.93 0 000-.44l-.22-.53.66.38a.86.86 0 10.86-1.49h-.04z"
    ></path>
    <path
      fill="currentColor"
      d="M42.47 27.41a1.07 1.07 0 01-1 .65h-5.8a2.233 2.233 0 00-.13-.27 3 3 0 00-1.79-1.38 3 3 0 00-3.89-.95 3 3 0 00-1.09-2.23 3.09 3.09 0 00-1.68-.67 3 3 0 00-2.09-.85 3 3 0 00-2.1.85 3.07 3.07 0 00-1.66.66 3 3 0 00-1.11 2.23 3.079 3.079 0 00-1.42-.35c-.367 0-.73.071-1.07.21a2.9 2.9 0 00-1.39 1.1 3 3 0 00-1.8 1.38 1.31 1.31 0 00-.12.27H8.92a1.06 1.06 0 01-.9-.49 7.31 7.31 0 01-1-3.75 7.41 7.41 0 018.27-7.36 10.59 10.59 0 0120.13 2.2c.378-.08.763-.123 1.15-.13a6.23 6.23 0 016.32 5 6.34 6.34 0 01-.42 3.88z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertySnowPressure;
