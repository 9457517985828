/* eslint-disable @typescript-eslint/no-explicit-any */
import { stringify } from 'query-string';
import OAuth from '../../authentication/classes/OAuth';
import {
  ACCESS_TOKEN_EXPIRED_EVENT_NAME,
  ACCESS_TOKEN_REFRESHING,
  ACCESS_TOKEN_REFRESHING_DONE,
  ACCESS_TOKEN_UNEXPIRED_EVENT_NAME,
} from '../../authentication/constants/Jwt';
import { isSocialLoginUrl } from '../../authentication/constants/SocialAuth';
import { getTokens, waitForEvent } from '../../authentication/utils/jwt';

type HTTPMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface ErrorResponse {
  code?: number | null;
  detail?: string | null;
  domain?: string | null;
  error?: string | null;
  formatted?: string | null;
  msg?: string | null;
  rpc_code?: number | null;
}

// Inspiration by Axios: https://axios-http.com/docs/res_schema
interface Response<T> {
  data: T;
  status: number;
  statusText: string;
  headers: Headers;
  error?: ErrorResponse;
  isError: boolean;
}

interface ConstructorObject {
  baseURL: string;
  /** Function so that it can always retrieve the latest values */
  defaultHeadersFn?: () => Record<string, string>;
  /** OAuth object as defined in our codebase */
  oauthObject?: OAuth;
}

type RequestOptions = {
  /** Pass query params as an object in the API call */
  query?: Record<string, unknown>;
  /** Set undefined values explicitly on null in the API call payload */
  explicitNull?: boolean;
} & RequestInit;

interface Request {
  path: string;
  payload?: unknown;
  options?: RequestOptions;
}

interface GetPayloadProps {
  payload: unknown;
  explicitNull?: boolean;
}

let isRefreshing = false;
let wasExpired = false;

class API {
  baseURL: string;
  defaultHeadersFn?: () => Record<string, string>;
  oauthObject?: OAuth;

  constructor({ baseURL, defaultHeadersFn, oauthObject }: ConstructorObject) {
    this.baseURL = baseURL;
    this.defaultHeadersFn = defaultHeadersFn;
    this.oauthObject = oauthObject;
  }

  get = <T>(path: string, options?: RequestOptions): Promise<Response<T>> => {
    return this.#request<T>({ path, options }, 'GET');
  };

  post = <T>(
    path: string,
    payload?: unknown,
    options?: RequestOptions,
  ): Promise<Response<T>> => {
    return this.#request<T>({ path, payload, options }, 'POST');
  };

  put = <T>(
    path: string,
    payload?: unknown,
    options?: RequestOptions,
  ): Promise<Response<T>> => {
    return this.#request<T>({ path, payload, options }, 'PUT');
  };

  patch = <T>(
    path: string,
    payload?: unknown,
    options?: RequestOptions,
  ): Promise<Response<T>> => {
    return this.#request<T>({ path, payload, options }, 'PATCH');
  };

  delete = <T>(
    path: string,
    options?: RequestOptions,
  ): Promise<Response<T>> => {
    return this.#request<T>({ path, options }, 'DELETE');
  };

  // Checks if the token is expired
  #isTokenExpired = (error: ErrorResponse) => {
    if (error.msg === 'AUTHENTICATION_EXPIRED') {
      return true;
    }
    return false;
  };

  #isTokenInvalid = (error: ErrorResponse) => {
    if (
      error.msg === 'INVALID_TOKEN' ||
      error.msg === 'NOT_AUTHENTICATED' ||
      error.error === 'invalid_token'
    ) {
      return true;
    }
    return false;
  };

  #refreshToken = async () => {
    if (this.oauthObject) {
      if (!isRefreshing) {
        isRefreshing = true;
        window.dispatchEvent(new Event(ACCESS_TOKEN_REFRESHING));

        // If this fails, OAuth class will automatically fetch a new auth code
        await this.oauthObject.refreshTokenGrantRequest();

        window.dispatchEvent(new Event(ACCESS_TOKEN_REFRESHING_DONE));
        isRefreshing = false;
      } else {
        await waitForEvent(ACCESS_TOKEN_REFRESHING_DONE);
      }
    }
  };

  #refreshSocialToken = async () => {
    window.dispatchEvent(new Event(ACCESS_TOKEN_EXPIRED_EVENT_NAME));
    await waitForEvent(ACCESS_TOKEN_UNEXPIRED_EVENT_NAME);
  };

  #request = async <T>(
    req: Request,
    method: HTTPMethods,
  ): Promise<Response<T>> => {
    const {
      headers: customHeaders,
      query,
      explicitNull,
      ...otherOptions
    } = req.options || {};
    const request = async (authExpired?: boolean) => {
      const response = await fetch(this.#getRequestURL(req.path, query), {
        method,
        body: this.#getPayload({ payload: req.payload, explicitNull }),
        headers: this.#buildHeaders(req, authExpired),
        ...otherOptions,
      });
      return this.#getResponseObject<T>(response);
    };

    let response = await request();

    if (response.isError) {
      const errorHandlingResponse = await this.#handleErrorResponse(
        response,
        request,
      );
      if (errorHandlingResponse) response = errorHandlingResponse;
    }

    return response;
  };

  #buildHeaders = (req: Request, authExpired?: boolean) => {
    const { headers: customHeaders } = req.options || {};

    let headersObject = {};

    // Payload
    if (req.payload) {
      if (!(req.payload instanceof FormData))
        headersObject['content-type'] = 'application/json';
    }

    // Authentication
    if ((this.oauthObject || isSocialLoginUrl) && !authExpired) {
      headersObject['Authorization'] = this.#getBearerToken();
    }

    // Extra headers
    headersObject = {
      ...headersObject,
      ...this.defaultHeadersFn?.(),
      ...customHeaders,
    };

    return headersObject;
  };

  #handleErrorResponse = async <T>(
    response: Response<T>,
    request: (expired?: boolean) => Promise<Response<T>>,
  ) => {
    // SOCIAL LOGIN
    if (isSocialLoginUrl) {
      if (
        this.#isTokenExpired(response.error!) ||
        this.#isTokenInvalid(response.error!) ||
        response.status === 401
      ) {
        if (wasExpired) {
          await this.#refreshSocialToken();
          wasExpired = false;
          return request();
        }
        wasExpired = true;
        return request(true);
      }
      // BROKER
    } else if (
      this.#isTokenExpired(response.error!) ||
      this.#isTokenInvalid(response.error!) ||
      response.status === 401
    ) {
      window.dispatchEvent(new Event(ACCESS_TOKEN_EXPIRED_EVENT_NAME));
      await this.#refreshToken();
      return request();
    }
  };

  #getBearerToken = () => {
    const accessToken = getTokens()?.accessToken;
    return `Bearer ${accessToken}`;
  };

  #getRequestURL = (path: string, query?: Record<string, unknown>) => {
    let _path = path;

    if (query) {
      Object.entries(query).forEach(([key, value]) => {
        if (value == null || value === '') {
          delete query[key];
        }
      });

      const queryString = stringify(query);

      if (_path.includes('?')) _path = `${_path}&${queryString}`;
      else _path = `${_path}?${queryString}`;
    }

    return new URL(_path, this.baseURL);
  };

  #getResponseObject = async <T>(
    res: globalThis.Response,
  ): Promise<Response<T>> => {
    let data: T;

    const contentType = res.headers.get('content-type') || '';
    if (
      contentType.includes('application/vnd.api+json') ||
      contentType.includes('application/json')
    ) {
      data = await res.json();
    } else if (contentType.includes('image/')) {
      data = URL.createObjectURL(await res.blob()) as any;
    } else {
      data = res as any;
    }

    let response: Response<T> = {
      data,
      status: res.status,
      statusText: res.statusText,
      headers: res.headers,
      isError: res.status >= 400,
    };

    if (response.isError)
      response = {
        ...response,
        error: data as ErrorResponse,
      };

    return response;
  };

  #getPayload = ({ payload, explicitNull }: GetPayloadProps) => {
    if (payload instanceof FormData) {
      return payload;
    }

    // If JSON payload, check on explicitNull param
    if (explicitNull) {
      return JSON.stringify(payload, (key, value) =>
        typeof value === 'undefined' ? null : value,
      );
    }
    return JSON.stringify(payload);
  };
}

export default API;
