import i18n from '../../../../i18n';

export const phoneChangeConversion = (
  value: string,
  country: { dialCode: string },
): string => {
  value = value.replace(' ', '');
  if (value[0] === '0' && country) {
    value = `+${country.dialCode}${value.substring(1, value.length)}`;
  } else if (value[0] !== '+' && value.length > 0) {
    value = `+${value}`;
  }
  return value;
};

export const phoneBlurConversion = (
  value: string,
  country: { dialCode: string },
): string => {
  value = value.replace('+', '').split(' ').join('');
  if (value[0] === '0' && country) {
    value = `+${country.dialCode}${value.substring(1, value.length)}`;
  } else if (value[0] !== '+' && value.length > 0) {
    value = `+${value}`;
  }
  return value;
};

export const getProFlowRouteNaming = (type: string): string => {
  switch (type.toLowerCase()) {
    case 'residence':
      return 'home';
    default:
      return type;
  }
};

export const toEuro = (amount: number, currency?: string): string => {
  return Number(amount).toLocaleString(currency === 'USD' ? 'en-US' : 'nl-BE', {
    style: 'currency',
    currency: currency || 'EUR',
  });
};

export const toPercentage = (amount: number, fractionDigits = 2): string =>
  Intl.NumberFormat('nl-BE', {
    style: 'percent',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(Math.abs(amount));

export const arrayToWords = (listOfWords: string[]): string =>
  listOfWords.slice(0, listOfWords.length - 1).join(', ') +
  `, ${i18n.t('and')} ` +
  listOfWords.slice(-1);
