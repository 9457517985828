import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleTravelling: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M28.86 17.18H7.29a1.14 1.14 0 100 2.28h2.89v4.4a1.14 1.14 0 102.28 0v-4.4H23.7v4.4a1.14 1.14 0 102.27 0v-4.4h2.89a1.14 1.14 0 100-2.28zM7.9 7h9.87a.76.76 0 01.76.76v7H7.14v-7A.76.76 0 017.9 7zM28.48 10.33v4.49h-7.6V9.57h6.84a.76.76 0 01.76.76z"
      opacity="0.15"
    ></path>
    <path
      fill="currentColor"
      d="M48.14 31.41a1.08 1.08 0 00-.78-.64L33 27.72a1.12 1.12 0 01-.64-.41l-2.91-3.85a.56.56 0 00-.33-.21l-2.52-.45-.6-.1V19h3a1 1 0 000-2H7a1 1 0 000 2h3v3.63c-.63.09-1.25.2-1.88.32l-1.28.26a1.1 1.1 0 00-.63.37C5.15 24.86 1 29.8 1 30.89c0 1.09.64 5.9.94 7.77a1.09 1.09 0 001.06.91h3.49a4.91 4.91 0 009.75 0h17.52a4.91 4.91 0 009.75 0h3.54a1.1 1.1 0 001.07-.83c.395-1.512.689-3.049.88-4.6a11 11 0 00-.86-2.73zM14 39.57a2.73 2.73 0 01-5.35 0 3.19 3.19 0 010-.54 2.73 2.73 0 015.45 0 3.186 3.186 0 01-.1.54zm-2-17.22V19h12v3.4a49.164 49.164 0 00-12-.05zm29.31 17.22a2.73 2.73 0 01-5.35 0 3.186 3.186 0 01-.05-.54 2.73 2.73 0 015.45 0 3.186 3.186 0 01-.05.54zM19 8a1 1 0 00-1-1H8a1 1 0 00-1 1v7h12V8zM29 11a2 2 0 00-2-2h-6v6h8v-4z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleTravelling;
