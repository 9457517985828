import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersPin: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M32.801 6.747a2 2 0 010 2.828l-9.29 9.29a2 2 0 01-.712.46l-8.885 3.331-1.763 1.763 13.43 13.43 1.763-1.763 3.332-8.885c.1-.267.256-.51.458-.712l9.29-9.29a2 2 0 112.829 2.828l-8.984 8.984-3.332 8.885c-.1.267-.256.51-.458.712l-3.484 3.484a2 2 0 01-2.829 0L7.908 25.834a2 2 0 010-2.829l3.484-3.484a2 2 0 01.712-.458l8.885-3.332 8.984-8.984a2 2 0 012.828 0z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18.866 31.134a2 2 0 010 2.829L8.414 44.414a2 2 0 11-2.828-2.828l10.451-10.452a2 2 0 012.829 0zM28.812 5.586a2 2 0 012.828 0L44.414 18.36a2 2 0 01-2.828 2.828L28.812 8.414a2 2 0 010-2.828z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersPin;
