import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleLaneKeepAssist: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M7 6H3v10h4V6zM7 20H3v10h4V20zM7 34H3v10h4V34zM47 6h-4v10h4V6zM47 20h-4v10h4V20zM47 34h-4v10h4V34zM25 9a16 16 0 100 32 16 16 0 000-32zm-3.2 27a.4.4 0 01-.52.39 12 12 0 01-7-6 .4.4 0 01.36-.58h3.16a4 4 0 014 4V36zm3.2-7a2.4 2.4 0 112.4-2.4A2.39 2.39 0 0125 29zm10.72 1.38a12.08 12.08 0 01-7.82 6.27.41.41 0 01-.5-.39V33.8a4 4 0 014-4h4a.4.4 0 01.32.58zm.68-6l-5.71-2.15a13.57 13.57 0 00-11.38 0L13.6 24.4a.4.4 0 01-.54-.4 12 12 0 0123.88 0 .399.399 0 01-.54.4v-.02z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleLaneKeepAssist;
