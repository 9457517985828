import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralNavigationProducts: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25 11a2 2 0 012-2h16a2 2 0 110 4H27a2 2 0 01-2-2zM25 19a2 2 0 012-2h10a2 2 0 110 4H27a2 2 0 01-2-2zM25 31a2 2 0 012-2h16a2 2 0 110 4H27a2 2 0 01-2-2zM25 39a2 2 0 012-2h10a2 2 0 110 4H27a2 2 0 01-2-2zM5 11a4 4 0 014-4h8a4 4 0 014 4v8a4 4 0 01-4 4H9a4 4 0 01-4-4v-8zm12 0H9v8h8v-8zM5 31a4 4 0 014-4h8a4 4 0 014 4v8a4 4 0 01-4 4H9a4 4 0 01-4-4v-8zm12 0H9v8h8v-8z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralNavigationProducts;
