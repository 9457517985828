import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralStatusLike: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M28.177 10.054a2.235 2.235 0 00-2.236 2.235v2.118a10.47 10.47 0 01-8.47 10.277v10.9a4.353 4.353 0 004.352 4.352h14.824a2 2 0 01.17.007c.224.02.577-.05 1.005-.478.44-.44.865-1.184 1.106-2.19l2.066-10.33a2.236 2.236 0 00-2.23-2.068h-6.352a2 2 0 01-2-2V12.29a2.235 2.235 0 00-2.235-2.235zm-11.46 32.14a4.12 4.12 0 01-3.364 1.742H9.118A4.118 4.118 0 015 39.818V24.995a4.118 4.118 0 014.118-4.118h6.353a6.47 6.47 0 006.47-6.47v-2.118a6.235 6.235 0 1112.47 0v8.588h4.354A6.235 6.235 0 0145 27.113c0 .131-.013.263-.039.392l-2.117 10.588a1.98 1.98 0 01-.014.064c-.37 1.577-1.097 3.054-2.18 4.137-1.078 1.078-2.506 1.745-4.077 1.642h-14.75a8.353 8.353 0 01-5.106-1.743zM13.47 24.876H9.118a.118.118 0 00-.118.118v14.823a.118.118 0 00.118.118h4.235a.117.117 0 00.118-.118v-14.94z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralStatusLike;
