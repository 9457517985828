import {
  HStack,
  Circle,
  SquareProps,
  ColorProps,
} from '@wegroup/design-system';
import React from 'react';
import { blink } from '../../../../../common/src/utils/cssAnimationsUtils';

interface Props {
  size?: SquareProps['size'];
  color?: ColorProps['color'];
}

type AnimationDotProps = Props & {
  delay?: string | number;
};

const AnimatedDot: React.FC<React.PropsWithChildren<AnimationDotProps>> = ({
  delay = 0,
  size = '1.5',
  color = 'gray.700',
}) => (
  <Circle
    size={size}
    animation={`${blink} infinite 1.5s linear`}
    sx={{ animationDelay: delay }}
    bg={color}
  />
);

const DotLoader: React.FC<Props> = ({ color }) => {
  return (
    <HStack spacing="1" textAlign="center">
      <AnimatedDot color={color} />
      <AnimatedDot color={color} delay="0.2s" />
      <AnimatedDot color={color} delay="0.4s" />
    </HStack>
  );
};

export default DotLoader;
