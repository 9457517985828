import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyConstruction: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M38.93 12.78A10.785 10.785 0 0038 11a11 11 0 00-6.46-4.62A12.73 12.73 0 0029.2 6h-9.7a.5.5 0 00-.5.5V8h-2V6.5a.5.5 0 00-.5-.5h-2a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h2a.5.5 0 00.5-.5V12h2v1.5a.5.5 0 00.5.5h3.11v11h-1l-.78 16.32A4.41 4.41 0 0024.72 46a4.28 4.28 0 004.67-4.47L28.61 25h-1V14h3.89a.5.5 0 00.5-.5v-3.16A10.22 10.22 0 0138.68 13a.166.166 0 10.25-.22z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyConstruction;
