import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleLEZ: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M45.5 32.8l-.8-2.2c-.2-.5-.6-.8-1.1-.8l-1.9-8c-.1-.6-.6-.9-1.2-.9H24.2c-.5 0-1 .4-1.2.9l-1.9 8c-.5 0-.9.3-1.1.8l-.7 2.4c-.1.2 0 .6 0 .6l.9 5.2c.1.6.6 1 1.1 1v2.9c0 .5.4.9.8.9h1.3c.4 0 .8-.4.8-.9v-2.9h16.5v2.9c0 .5.4.9.8.9h1.3c.4 0 .8-.4.8-.9v-2.9c.5 0 1-.4 1-1l.9-5.4s.1-.4 0-.6zm-22.7-3l1.6-6.7c.1-.5.5-.8 1-.8h14c.5 0 .9.3 1 .8l1.6 6.7H22.8zM16.8 33.4c.2-.4.2-.8.1-1.1-.3-.9-1.7-1.3-3-.8S11.6 33 12 34c.2.7 1 1 1.9 1 .3.5.8.9 1.5.9.9 0 1.7-.7 1.7-1.7-.1-.3-.2-.6-.3-.8zM14.1 24.5c.2-.5.3-1 .1-1.5-.3-.7-1-1.2-1.9-1.3-.6-.5-1.4-.9-2.4-.9-1.7 0-3 1-3 2.3 0 .5.3 1 .7 1.4 0 .3 0 .6.1.9.3.9 1.3 1.4 2.5 1.3.4.7 1.1 1.2 2 1.2 1.2 0 2.3-1 2.3-2.3-.1-.3-.2-.7-.4-1.1zM15.4 11.4c-.4-1.3-2-1.9-3.8-1.6C10.9 9.3 10 9 9 9c-2 0-3.7 1.2-3.7 2.7 0 .4.1.8.4 1.2-.4.5-.7 1.1-.7 1.8 0 1.5 1.2 2.7 2.7 2.7.6 0 1.1-.2 1.6-.5.5.6 1.3 1 2.1 1 1.5 0 2.7-1.2 2.7-2.7 0-.2 0-.4-.1-.6 1.3-.9 1.8-2.1 1.4-3.2z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleLEZ;
