import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyElectricity: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M23.85 28.5H11.5a.5.5 0 01-.38-.82l18-21.5a.5.5 0 01.87.44l-3.35 13.26a.5.5 0 00.49.62H39.5a.5.5 0 01.39.81c-2.83 3.54-14.1 17.62-18 22.51a.5.5 0 01-.87-.43l3.37-14.27a.511.511 0 00-.54-.62z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyElectricity;
