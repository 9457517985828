import React from 'react';
import { SimpleGrid } from '@wegroup/design-system';
import LouiseChatIntroExamples, {
  LouiseChatExample,
} from './LouiseChatIntroExamples';
import LouiseChatIntroCapabilities from './LouiseChatIntroCapabilities';
import LouiseChatIntroLimitations from './LouiseChatIntroLimitations';

interface Props<T> {
  onExampleClick: (values: T) => void;
  examples: T[];
}

function LouiseChatIntro<T extends LouiseChatExample>({
  onExampleClick,
  examples,
}: Props<T>) {
  return (
    <SimpleGrid columns={{ base: 1, tabletS: 2, tablet: 3 }} gridGap="4">
      <LouiseChatIntroExamples onClick={onExampleClick} examples={examples} />
      <LouiseChatIntroCapabilities />
      <LouiseChatIntroLimitations />
    </SimpleGrid>
  );
}

export default LouiseChatIntro;
