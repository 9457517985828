import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyOil: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M30 13h-6.28A2 2 0 0020 14v18a2 2 0 003.73 1H30a1 1 0 001-1V14a1 1 0 00-1-1zM17 13h-6.28A2 2 0 007 14v18a2 2 0 003.73 1H17a1 1 0 001-1V14a1 1 0 00-1-1zM38 24h-1V14a2 2 0 00-4 0v18a2 2 0 004 0v-3h1a1 1 0 001-1v-3a1 1 0 00-1-1z"
    ></path>
    <path
      fill="currentColor"
      d="M43 35a4 4 0 01-3 3.87 5.49 5.49 0 01-9.86 3A3.57 3.57 0 0129 42a4 4 0 01-3.5-2.08c-.498.052-.999.079-1.5.08-4.41 0-8-2-8-4.5a4.01 4.01 0 010-.5h1a3 3 0 001.8-.61 4 4 0 005.83.61H30a3 3 0 001.77-.58c.1.12.19.24.3.35.07.092.155.173.25.24.322.303.698.54 1.11.7.093.048.19.089.29.12h.16c.08.032.164.055.25.07.125.03.252.05.38.06.162.025.326.038.49.04a4 4 0 004-4v-1a4 4 0 014 4z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyOil;
