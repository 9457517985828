import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
} from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import { OverviewFilterInput } from '..';
import SingleOverviewFilterModalInput from './SingleOverviewFilterModalInput';

interface Props {
  isOpen: boolean;
  isDirty: boolean;
  onClose: () => void;
  onApplyFilters: () => void;
  filters: OverviewFilterInput[];
}

const OverviewFilterModal: React.FC<Props> = ({
  isOpen,
  isDirty,
  onClose,
  onApplyFilters,
  filters,
}) => {
  const { t } = useTranslation();

  const handleOnApplyFilters = () => {
    onApplyFilters();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('FILTERS')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={VStack} spacing="4" align="stretch">
          {filters.map(({ tKey, input }) => (
            <SingleOverviewFilterModalInput
              key={tKey}
              tKey={tKey}
              input={input}
            />
          ))}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" colorScheme="blue" mr={3} onClick={onClose}>
            {t('CLOSE')}
          </Button>
          <Button
            data-test-id="ADVISORY-REPORT_FILTER_SUBMIT-BUTTON"
            onClick={handleOnApplyFilters}
            isDisabled={!isDirty}
          >
            {t('APPLY-FILTERS')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OverviewFilterModal;
