import React, { ChangeEvent, forwardRef } from 'react';
import {
  InputProps,
  Input,
  InputGroup,
  InputRightAddon,
} from '../../../../index';

interface Props {
  value: string | null;
  onChange: (value: string) => void;
}

const DocumentNameInput = forwardRef<
  HTMLInputElement,
  Omit<InputProps, 'onChange'> & Props
>(({ value, onChange, defaultValue, ...props }, ref) => {
  /** Take the last part of the string after a 'dot' */
  const extensionArray = value?.split('.') || [];
  const extension = extensionArray[extensionArray.length - 1];
  /** Get the value without it's extension (without the last dot + ...) */
  const extensionLessValue = extensionArray.slice(0, -1).join('.');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    /** Put the extension back to the end of the inputted value */
    onChange(e.currentTarget.value + '.' + extension);
  };

  return (
    <InputGroup>
      <Input
        ref={ref}
        {...props}
        value={extensionLessValue}
        onChange={handleChange}
      />
      <InputRightAddon bg="white" fontWeight="normal" color="black">
        .{extension}
      </InputRightAddon>
    </InputGroup>
  );
});

export default DocumentNameInput;
