import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsZoomIn: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14 22a2 2 0 012-2h12a2 2 0 110 4H16a2 2 0 01-2-2z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22 9C14.82 9 9 14.82 9 22s5.82 13 13 13 13-5.82 13-13S29.18 9 22 9zM5 22c0-9.389 7.611-17 17-17s17 7.611 17 17-7.611 17-17 17S5 31.389 5 22z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M31.193 31.193a2 2 0 012.828 0l10.393 10.393a2 2 0 01-2.828 2.828L31.193 34.021a2 2 0 010-2.828zM22 14a2 2 0 012 2v12a2 2 0 01-4 0V16a2 2 0 012-2z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralActionsZoomIn;
