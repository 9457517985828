import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyLiability: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25 15.648a.656.656 0 00.656-.656v-4.205l.125.172a.656.656 0 001.063-.768l-1.312-1.817a.656.656 0 00-1.064 0l-1.312 1.817a.654.654 0 00.147.916.655.655 0 00.916-.148l.125-.172v4.205a.656.656 0 00.656.656zM45.728 28.57l-1.817-1.313a.657.657 0 00-.769 1.064l.172.124H39.11a.657.657 0 000 1.313h4.206l-.173.124a.656.656 0 10.769 1.064l1.817-1.313a.656.656 0 000-1.063zM10.89 28.445H6.687l.172-.124a.655.655 0 10-.769-1.064L4.272 28.57a.656.656 0 000 1.063l1.817 1.313a.657.657 0 10.769-1.064l-.172-.124h4.205a.656.656 0 000-1.313zM11.586 16.615l2.973 2.974a.656.656 0 00.928-.928l-2.973-2.974.21.034a.656.656 0 00.208-1.296l-2.213-.357a.657.657 0 00-.752.753l.357 2.213a.656.656 0 001.296-.209l-.034-.21zM38.924 17.578a.657.657 0 00.752-.544l.357-2.213a.657.657 0 00-.752-.753l-2.213.357a.655.655 0 00-.047 1.287c.083.02.17.023.255.01l.21-.035-2.973 2.974a.657.657 0 10.928.928l2.973-2.974-.034.21a.656.656 0 00.544.753zM26.064 18.358a.662.662 0 00-.05-.052c-.52-.476-1.414-.558-2.127.215l-9.55 10.74a.657.657 0 00.982.872l9.54-10.73c.1-.11.201-.164.255-.138l9.564 10.925a.655.655 0 001.082-.141.656.656 0 00-.094-.723l-9.602-10.968z"
    ></path>
    <path
      fill="currentColor"
      d="M26.013 22.66a1.412 1.412 0 00-1.071-.449 1.447 1.447 0 00-1.06.491l-6.956 7.9-.028.03a1.338 1.338 0 00-.43.947v8.799a1.512 1.512 0 001.501 1.52h14.062a1.512 1.512 0 001.5-1.52v-8.799a1.294 1.294 0 00-.448-.949l-7.07-7.97zm-2.26 9.91c.252.371.855.525 1.495.688.895.23 2.01.514 2.406 1.622.35.982-.214 2.006-1.338 2.437-.213.08-.434.138-.66.172v.472a.656.656 0 01-1.312 0v-.516a3.077 3.077 0 01-2.254-1.79.656.656 0 111.226-.47c.31.809 1.587 1.265 2.53.907.258-.099.708-.387.572-.771-.15-.42-.665-.579-1.495-.791-.81-.207-1.727-.44-2.256-1.225a1.957 1.957 0 01.009-2.278 2.506 2.506 0 011.668-.945v-.652a.656.656 0 111.312 0v.648l.043.005a2.86 2.86 0 012.224 1.812.657.657 0 01-1.252.394 1.572 1.572 0 00-1.191-.911c-.616-.104-1.426-.015-1.744.423a.667.667 0 00.018.769z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyLiability;
