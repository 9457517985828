import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  HStack,
  Icon,
  Box,
} from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { usePatchUserMetaData } from '../../../../../../app/src/features/api/userData';
import useIsLouiseQAPlan from '../../../../../../app/src/hooks/useIsLouiseQAPlan';
import useTracking from '../../../../../../common/src/hooks/tracking/useTracking';
import GrayTextBox from '../../../../../../company-scan/components/GrayTextBox';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const LouiseGPTAcknowledgementModal: React.FC<Props> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { mutate: patchUserMetaData } = usePatchUserMetaData();
  const history = useHistory();
  const isLouiseQAPlan = useIsLouiseQAPlan();

  const handleDecline = () => {
    trackEvent('User declined Louise GPT Acknowledgement');
    patchUserMetaData({
      metadata: { insurance_terms_bot_acknowledgement: false },
    });
    if (isLouiseQAPlan) history.push('/settings');
    else onClose();
  };

  const handleAgree = () => {
    trackEvent('User agreed to Louise GPT Acknowledgement');
    patchUserMetaData({
      metadata: { insurance_terms_bot_acknowledgement: true },
    });
    if (isLouiseQAPlan) history.push('/louiseqa');
    else history.push('/toolkit/louiseqa');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {t('TOOLKIT_INSURANCE-TERMS-BOT_ACKNOWLEDGEMENT-MODAL_TITLE')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          sx={{
            p: {
              marginBottom: '2',
            },
            ul: {
              listStyleType: 'circle',
              paddingLeft: '4',
            },
            li: {
              marginBottom: '0.5',
            },
          }}
        >
          <GrayTextBox
            title={t(
              'TOOLKIT_INSURANCE-TERMS-BOT_ACKNOWLEDGEMENT-MODAL-BODY_TITLE',
            )}
          >
            <Box
              dangerouslySetInnerHTML={{
                __html: t(
                  'TOOLKIT_INSURANCE-TERMS-BOT_ACKNOWLEDGEMENT-MODAL_DESCRIPTION',
                ),
              }}
            />
          </GrayTextBox>
        </ModalBody>
        <ModalFooter as={HStack} spacing={3}>
          <Button
            variant="dangerPrimary"
            onClick={handleDecline}
            leftIcon={<Icon name="GeneralActionsCloseOrRemove" />}
          >
            {t('DECLINE')}
          </Button>
          <Button
            variant="successPrimary"
            onClick={handleAgree}
            leftIcon={<Icon name="GeneralStatusSuccessCheckmark" />}
          >
            {t('AGREE')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LouiseGPTAcknowledgementModal;
