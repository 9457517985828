import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralLabelsDocumentPdf: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="#F74040"
      fillRule="evenodd"
      d="M37.28 16h-7a.29.29 0 01-.3-.3v-7a.3.3 0 01.51-.21l7 7a.3.3 0 01-.21.51z"
      clipRule="evenodd"
    ></path>
    <path
      fill="#F74040"
      fillRule="evenodd"
      d="M28.3 18h9.4a.292.292 0 01.28.184.29.29 0 01.02.116v23.4a.29.29 0 01-.3.3H12.3a.29.29 0 01-.3-.3V8.3a.29.29 0 01.3-.3h15.4a.29.29 0 01.3.3v9.4a.29.29 0 00.3.3zM16.558 29.645v-2.291h1.222c.774 0 1.245.44 1.245 1.15 0 .69-.471 1.14-1.245 1.14h-1.222zm1.413 1.342c1.57 0 2.612-1.038 2.612-2.493 0-1.445-1.042-2.494-2.612-2.494H15v8h1.547v-3.013h1.424zm5.364 1.569v-5.112h1.245c1.3 0 2.376.824 2.376 2.562 0 1.737-1.087 2.55-2.388 2.55h-1.233zM24.625 34c2.264 0 3.934-1.467 3.934-3.994C28.56 27.478 26.9 26 24.636 26h-2.848v8h2.836zm6.91-3.114V34h-1.558v-8H35v1.478h-3.476v1.975h3.095v1.433h-3.083z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralLabelsDocumentPdf;
