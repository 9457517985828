import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsLink: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.975 9.823a10.041 10.041 0 0 1 14.084.122 10.058 10.058 0 0 1 .098 14.117l-4.827 4.832a10.045 10.045 0 0 1-7.823 2.92 10.042 10.042 0 0 1-7.327-4.006 2 2 0 1 1 3.204-2.395 6.048 6.048 0 0 0 4.409 2.412 6.038 6.038 0 0 0 4.706-1.758l4.814-4.819a6.057 6.057 0 0 0-.084-8.476 6.041 6.041 0 0 0-8.464-.083l-2.756 2.744a2 2 0 1 1-2.822-2.836l2.768-2.754.02-.02Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.264 18.794a10.037 10.037 0 0 1 11.556 3.398 2 2 0 1 1-3.204 2.394 6.047 6.047 0 0 0-4.409-2.411 6.037 6.037 0 0 0-4.706 1.757l-4.815 4.82a6.058 6.058 0 0 0 .085 8.475 6.041 6.041 0 0 0 8.462.085l2.738-2.74a2 2 0 0 1 2.83 2.826l-2.752 2.754a10.04 10.04 0 0 1-14.108-.098 10.058 10.058 0 0 1-.098-14.116l4.827-4.832a10.045 10.045 0 0 1 3.594-2.312Z"
      fill="currentColor"
    />
  </Icon>
);

export default GeneralActionsLink;
