import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyAppartment: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 9a1 1 0 00-1 1v30a1 1 0 001 1h7.727v-8.727h5.819V41h7.727a1 1 0 001-1V10a1 1 0 00-1-1H14zm6.273 2.91h-2.91v5.817h2.91V11.91zm2.909 0h2.909v5.817h-2.91V11.91zm8.727 0H29v5.817h2.91V11.91zM17.364 22.09h2.909v5.82h-2.91v-5.82zm8.727 0h-2.91v5.82h2.91v-5.82zm5.818 0H29v5.82h2.91v-5.82z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyAppartment;
