import { UseQueryResult, useQuery } from 'react-query';

import {
  getAllBrokersForUser,
  retrieveBrokerByUserId,
} from '../legacy/services/apiRouterService';

import { Broker } from '../../../../types/Broker';
import useAuth from '../../../app/src/features/authentication/hooks/useAuth';

export const useUserBrokersQKey = (): string[] => ['user', 'brokers'];

export const useUserBrokers = (): UseQueryResult<Broker[], unknown> => {
  const { isAuthorized } = useAuth();
  return useQuery<Broker[]>(
    useUserBrokersQKey(),
    async () => {
      const [resp] = await getAllBrokersForUser();
      return resp.items;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: false,
      enabled: isAuthorized,
    },
  );
};

/**
 * Related to user data since it is needed for a user when he goes trough a campaign
 */
export const useBrokerById = (id?: string): UseQueryResult<Broker, unknown> =>
  useQuery<Broker>(
    ['user', 'broker', id],
    async () => {
      const [resp] = await retrieveBrokerByUserId(id);
      return resp;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: false,
      enabled: !!id,
    },
  );
