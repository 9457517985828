import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersEuro: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M27.786 13c-4.645 0-8.648 2.775-10.568 6.75H15.25a2.25 2.25 0 000 4.5h.796c-.015.25-.046.497-.046.75s.031.5.046.75h-.796a2.25 2.25 0 000 4.5h1.968c1.918 3.979 5.92 6.75 10.568 6.75 3.618 0 6.418-1.468 8.577-3.68a2.256 2.256 0 00-1.65-3.824 2.255 2.255 0 00-1.584.684c-1.648 1.688-2.737 2.32-5.343 2.32-2.05 0-3.9-.85-5.225-2.25h4.686a2.25 2.25 0 000-4.5h-6.701c-.023-.24-.047-.503-.047-.75 0-.254.023-.503.047-.75h6.701a2.25 2.25 0 000-4.5h-4.686a7.193 7.193 0 015.225-2.25c2.606 0 3.695.632 5.343 2.32a2.256 2.256 0 003.187.047 2.253 2.253 0 00.047-3.187C34.204 14.468 31.404 13 27.786 13z"
    ></path>
  </Icon>
);

export default GeneralOthersEuro;
