import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsFlightMissed: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M45.4 7.46a3.79 3.79 0 00-2.21-.7 3.91 3.91 0 00-1.55.32l-.86.37a10.27 10.27 0 00-3.25 2.24l-2.11 2.18-7.84-2.33a3.25 3.25 0 00-3.37.99l-.95 1.11a3.232 3.232 0 00.86 4.91L28.47 19l-1.36 1.4-.95-.19H26a2.82 2.82 0 00-.5 0 3.58 3.58 0 00-2.21.77l-1.11.87a3.12 3.12 0 00.71 5.32l2.61 1.11 1.11 2.61a3.11 3.11 0 002.87 1.9 3.069 3.069 0 002.45-1.19l.88-1.11a3.64 3.64 0 00.73-2.7v-.16l-.19-.95 1.41-1.36 2.47 4.35a3.23 3.23 0 002.77 1.6 3.27 3.27 0 002.11-.78l1.1-.95a3.21 3.21 0 001-3.37l-2.33-7.84 2.19-2.12A9.998 9.998 0 0046.3 13l.39-.88a3.85 3.85 0 00-1.29-4.66zm-1.46 3.44l-.39.89A7.11 7.11 0 0142 14.06l-2.19 2.12-1.3 1.27.49 1.74L41.34 27a.22.22 0 01-.07.23l-1.11.95a.2.2 0 01-.15.06.239.239 0 01-.19-.12l-2.47-4.32-1.92-3.38-2.78 2.71-1.41 1.36-1.16 1.13.31 1.6.19.94a.63.63 0 01-.12.44l-.88 1.11a.1.1 0 01-.09 0 .11.11 0 01-.11-.07l-1.11-2.61L27.8 26l-1.12-.47-2.61-1.11a.13.13 0 01-.07-.09.14.14 0 01.05-.11l1.11-.87a.58.58 0 01.35-.13h.08l.94.18 1.6.32 1.13-1.17 1.37-1.4 2.7-2.79L30 16.41l-4.4-2.47a.229.229 0 01-.11-.16.26.26 0 01.05-.19l.95-1.11a.22.22 0 01.17-.07h.07l7.84 2.32 1.74.52L37.57 14l2.12-2.18A7.231 7.231 0 0142 10.2l.86-.37a.89.89 0 01.36-.07.86.86 0 01.47.14.84.84 0 01.25 1zM12 43.79a2 2 0 01-1.41-.59l-6-6a2 2 0 012.82-2.82L12 39l8.59-8.58a2 2 0 112.82 2.82l-10 10a2 2 0 01-1.41.55z"
    ></path>
  </Icon>
);

export default RiskObjectsFlightMissed;
