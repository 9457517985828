import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationAntiques: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#a)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M218 225.5h-11c-9.667-5.167-29-20.7-29-41.5 0-17.985 8.317-25.426 15.885-32.197C200.203 146.151 206 140.965 206 130.5c0-7.5-6-17-17.5-24.5h59c-11.5 7.5-17.5 17-17.5 24.5 0 10.465 5.797 15.651 12.115 21.303C249.683 158.574 258 166.015 258 184c0 20.8-19.333 36.333-29 41.5h-11z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M207 225.5l-.943 1.764a2 2 0 00.943.236v-2zm-13.115-73.697l-1.333-1.49 1.333 1.49zM188.5 106v-2a2 2 0 00-1.093 3.675L188.5 106zm59 0l1.093 1.675A2 2 0 00247.5 104v2zm-5.385 45.803l-1.334 1.491 1.334-1.491zM229 225.5v2a2 2 0 00.943-.236L229 225.5zm-22 2h11v-4h-11v4zM176 184c0 11.037 5.127 20.563 11.349 27.906 6.222 7.342 13.678 12.669 18.708 15.358l1.886-3.528c-4.637-2.478-11.681-7.5-17.542-14.417C184.54 202.403 180 193.763 180 184h-4zm16.552-33.687c-3.763 3.366-7.954 7.099-11.155 12.341C178.165 167.948 176 174.67 176 184h4c0-8.656 1.993-14.646 4.811-19.261 2.85-4.668 6.602-8.04 10.408-11.445l-2.667-2.981zM204 130.5c0 9.489-5.104 14.136-11.448 19.813l2.667 2.981C201.51 147.665 208 141.941 208 130.5h-4zm-16.593-22.825C198.643 115.003 204 123.971 204 130.5h4c0-8.471-6.643-18.503-18.407-26.175l-2.186 3.35zM218 104h-29.5v4H218v-4zm0 4h29.5v-4H218v4zm28.407-3.675C234.643 111.997 228 122.029 228 130.5h4c0-6.529 5.357-15.497 16.593-22.825l-2.186-3.35zM228 130.5c0 11.441 6.49 17.165 12.781 22.794l2.667-2.981C237.104 144.636 232 139.989 232 130.5h-4zm12.781 22.794c3.806 3.405 7.558 6.777 10.408 11.445C254.007 169.354 256 175.344 256 184h4c0-9.33-2.165-16.052-5.397-21.346-3.201-5.242-7.392-8.975-11.155-12.341l-2.667 2.981zM256 184c0 9.763-4.54 18.403-10.401 25.319-5.861 6.917-12.905 11.939-17.542 14.417l1.886 3.528c5.03-2.689 12.486-8.016 18.708-15.358C254.873 204.563 260 195.037 260 184h-4zm-27 39.5h-11v4h11v-4z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M218 163c-11.2-7.6-23-8.5-27.5-8-3.553 3.023-9.952 11.457-11 16.5 23 0 27.5-1 38.5-8.5z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M217.5 163c11.2-7.6 23-8.5 27.5-8 3.553 3.023 9.952 11.457 11 16.5-23 0-27.5-1-38.5-8.5z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M178.499 177.5h79c1 5 .5 7 0 12h-78.998c-.501-4.5-.501-7.5-.002-12z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M180 195.5c37.5 0 30.5 14.5 68 14.5M189 210c37.5 0 28.5-14.5 66-14.5"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinejoin="round"
          strokeWidth="4"
          d="M101 64l31.5-33L164 64v103h-63V64z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M95 61l37.5-39L170 61"
        ></path>
        <circle
          cx="133"
          cy="74"
          r="23"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M133 74.5h8.5m-8.5.5v11m-.223-11.222L123 65m5 32v39m10-38v22"
        ></path>
        <path
          fill={colors.primary[300]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M133 142a5 5 0 11-10.001-.001A5 5 0 01133 142zm10-16a5 5 0 11-10.001-.001A5 5 0 01143 126z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationAntiques;
