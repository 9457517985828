/**
 * Removes all HTML tags and checks if there is actually a value to be shown.
 * This is useful for the Rich Text Editor value, hence the name. The function
 * itself will simply remove all tags and check if the length of the remaining
 * string is greater than 1.
 * @param text The HTML string to be checked
 * @returns A boolean depending on whether the remaining string still has a value
 */
export const hasRichText = (text?: string): boolean =>
  Boolean(
    text &&
      (text
        ?.replaceAll(/<[^>]*>/gi, ' ')
        .replaceAll(/\\s+/g, ' ')
        .trim().length > 0 ||
        text.includes(`<img`)),
  );
