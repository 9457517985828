import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceOldtimerSVI: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_1864_6905"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_1864_6905)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M293.419 116.376a5.132 5.132 0 00-3.624 1.503 5.133 5.133 0 00-1.5 3.625v17.936h10.252v-17.936a5.127 5.127 0 00-5.128-5.128z"
        ></path>
        <circle
          r="33.5"
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 298.003 190.5)"
        ></circle>
        <circle
          r="21.5"
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 298.003 190.5)"
        ></circle>
        <circle
          r="33.5"
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 58.003 190.5)"
        ></circle>
        <circle
          r="21.5"
          fill="#fff"
          stroke={colors.primary[200]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 58.003 190.5)"
        ></circle>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M221.671 183.004H117.703a25.353 25.353 0 01-12.808-3.698 25.35 25.35 0 01-9.164-9.682 43.446 43.446 0 00-70.604-7.836 5.128 5.128 0 01-7.095.278 5.125 5.125 0 01-.561-7.078 53.697 53.697 0 0170.027-9.051 53.698 53.698 0 0117.205 18.739 14.89 14.89 0 0013 8.092h103.968c11.66 0 20.444-7.248 30.608-15.64 11.488-9.484 24.508-20.236 43.704-20.236a65.404 65.404 0 0138.8 13.72 5.123 5.123 0 01-1.98 9.26 5.126 5.126 0 01-3.836-.82 55.633 55.633 0 00-32.968-11.908c-15.512 0-26.036 8.688-37.176 17.888-10.72 8.828-21.804 17.972-37.152 17.972z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M57.671 136.876a53.809 53.809 0 0147.052 27.784 14.897 14.897 0 0013 8.092h103.948c11.66 0 20.444-7.248 30.608-15.64 9.88-8.156 20.92-17.2 36-19.6v-16h-61.204a15.659 15.659 0 01-15.656-15.656V67.5c.065-1.544-2.777-7.755-7.688-7.496h-143.5a10.248 10.248 0 00-10.248 10.244v67.256a53.494 53.494 0 017.688-.628z"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M103.795 121.504v15.512a13.286 13.286 0 003.284 8.736l10.688 12.22a13.263 13.263 0 004.51 3.348 13.263 13.263 0 005.49 1.184h24.716v-41h-48.688z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M103.795 70.252H70.503a7.712 7.712 0 00-7.712 7.712v28.156a15.384 15.384 0 0015.384 15.384h25.6l.02-51.252zM103.795 70.252h48.688V121.5h-48.688V70.252zM201.171 121.504V77.96a7.706 7.706 0 00-7.708-7.708h-40.98v51.252h48.688z"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M152.483 121.504v41h40.996a7.69 7.69 0 007.692-7.704v-33.296h-48.688zM134.547 131.752h7.684M162.731 131.752h7.688"
        ></path>
        <g filter="url(#filter0_dd_1864_6905)">
          <circle cx="233" cy="85" r="55" fill="#fff"></circle>
          <circle
            cx="233"
            cy="85"
            r="53"
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </g>
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M236.022 67.331c3.009.823 4.967 1.959 7.103 4.122 1.841 1.865 2.75 3.403 3.47 5.869.244.836.256 1.246.298 9.995.042 9.061.041 9.128-.221 9.737-.488 1.134-1.454 1.763-2.709 1.763-.903 0-1.494-.25-2.109-.892-.811-.846-.786-.552-.786-9.063 0-4.245-.034-7.808-.075-7.916-.044-.115-.221-.197-.423-.197-.202 0-.379.082-.423.197-.041.108-.075 3.617-.075 7.798v7.602l-.934 11.734c-.514 6.454-.989 12.063-1.056 12.464-.285 1.716-1.601 2.99-3.439 3.333-1.073.2-4.191.075-4.95-.198-.81-.293-1.869-1.286-2.267-2.127-.311-.658-.35-1.039-1.314-13.042l-.994-12.364v-7.502c0-4.126-.034-7.59-.075-7.698-.106-.276-.637-.247-.793.044-.084.158-.129 2.864-.129 7.916 0 8.462.026 8.173-.785 9.019-.676.705-1.243.924-2.256.872-.753-.04-.971-.106-1.473-.453a3.747 3.747 0 01-.934-.995l-.346-.588-.038-8.411c-.025-5.5.009-8.819.098-9.59.588-5.109 4.754-9.68 10.283-11.283 1.648-.477 2.291-.55 4.361-.499 1.495.037 2.094.108 2.991.353z"
          clipRule="evenodd"
        ></path>
        <rect
          width="14.438"
          height="17.646"
          x="225.415"
          y="46"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="7.219"
        ></rect>
        <path
          fill={colors.primary[100]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M217.511 87.222c1.953.533 3.223 1.27 4.609 2.674 1.195 1.21 1.785 2.208 2.251 3.808.159.543.167.809.194 6.486.028 5.879.027 5.923-.143 6.317-.317.737-.944 1.145-1.758 1.145-.586 0-.969-.162-1.369-.579-.526-.549-.509-.359-.509-5.88 0-2.755-.022-5.067-.049-5.137-.029-.075-.144-.128-.275-.128-.131 0-.246.053-.274.128-.027.07-.049 2.347-.049 5.06v4.932l-.606 7.614c-.333 4.188-.641 7.827-.685 8.088-.185 1.113-1.039 1.94-2.232 2.162-.696.13-2.719.049-3.211-.129-.526-.189-1.213-.834-1.472-1.38-.201-.426-.226-.674-.852-8.462l-.645-8.023v-4.867c0-2.677-.022-4.925-.049-4.995-.069-.18-.413-.16-.514.028-.055.103-.084 1.858-.084 5.137 0 5.49.017 5.303-.509 5.852-.439.457-.806.599-1.464.565-.489-.025-.63-.068-.956-.293a2.458 2.458 0 01-.606-.646l-.224-.381-.025-5.458c-.016-3.569.006-5.722.063-6.222.382-3.316 3.085-6.282 6.673-7.321 1.069-.31 1.486-.358 2.83-.324.97.024 1.358.07 1.94.229z"
          clipRule="evenodd"
        ></path>
        <rect
          width="9.368"
          height="11.45"
          x="210.629"
          y="74.772"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="4.684"
        ></rect>
        <path
          fill={colors.primary[200]}
          fillRule="evenodd"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M252.747 87.222c1.953.533 3.223 1.27 4.609 2.674 1.195 1.21 1.785 2.208 2.252 3.808.158.543.166.809.193 6.486.028 5.879.027 5.923-.143 6.317-.317.737-.944 1.145-1.758 1.145-.586 0-.969-.162-1.368-.579-.526-.549-.51-.359-.51-5.88 0-2.755-.022-5.067-.049-5.137-.029-.075-.144-.128-.274-.128-.131 0-.246.053-.275.128-.027.07-.049 2.347-.049 5.06v4.932l-.606 7.614c-.333 4.188-.641 7.827-.684 8.088-.186 1.113-1.039 1.94-2.232 2.162-.696.13-2.72.049-3.212-.129-.526-.189-1.213-.834-1.471-1.38-.202-.426-.227-.674-.853-8.462l-.644-8.023v-4.867c0-2.677-.022-4.925-.049-4.995-.069-.18-.414-.16-.515.028-.055.103-.083 1.858-.083 5.137 0 5.49.016 5.303-.51 5.852-.438.457-.806.599-1.464.565-.489-.025-.629-.068-.955-.293a2.462 2.462 0 01-.607-.646l-.224-.381-.025-5.458c-.016-3.569.006-5.722.064-6.222.381-3.316 3.085-6.282 6.672-7.321 1.069-.31 1.487-.358 2.83-.324.97.024 1.358.07 1.94.229z"
          clipRule="evenodd"
        ></path>
        <rect
          width="9.368"
          height="11.45"
          x="245.865"
          y="74.772"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="4.684"
        ></rect>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd_1864_6905"
          width="142"
          height="142"
          x="162"
          y="22"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="8"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1864_6905"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_1864_6905"
            result="effect2_dropShadow_1864_6905"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_1864_6905"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
};

export default IllustrationInsuranceOldtimerSVI;
