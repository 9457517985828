import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertySoftware: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M25 41a16 16 0 100-32 16 16 0 000 32zm12.8-16a12.801 12.801 0 01-14.752 12.65L25 30.868A5.867 5.867 0 0030.867 25l6.784-1.941c.098.642.148 1.291.149 1.941zm-7.77-11.77a12.875 12.875 0 016.74 6.74l-6.789 1.942a5.904 5.904 0 00-1.893-1.893l1.941-6.79zM25 27.666a2.667 2.667 0 110-5.334 2.667 2.667 0 010 5.334zM12.2 25a12.8 12.8 0 0114.752-12.65L25 19.132A5.867 5.867 0 0019.133 25l-6.784 1.952c-.099-.646-.149-1.299-.149-1.952zm7.819 3.088a5.902 5.902 0 001.893 1.893l-1.941 6.79a12.875 12.875 0 01-6.742-6.742l6.79-1.941z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertySoftware;
