import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBonds: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3952_10331"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3952_10331)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <rect
          width="210.916"
          height="109.41"
          x="73"
          y="64"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="3"
        ></rect>
        <g
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          clipPath="url(#clip0_3952_10331)"
        >
          <path d="M264.136 84.487c-2.196-2.511-4.979-4.21-8-4.882-3.02-.673-6.144-.29-8.979 1.099-2.834 1.39-5.255 3.725-6.956 6.713-1.702 2.988-2.61 6.496-2.61 10.083 0 3.588.908 7.096 2.61 10.084 1.701 2.988 4.122 5.323 6.956 6.713 2.835 1.389 5.959 1.772 8.979 1.099 3.021-.673 5.804-2.371 8-4.882M232.383 102.706h20.822m0-10.411h-20.822 20.822z"></path>
        </g>
        <rect
          width="78.081"
          height="26.027"
          x="85.33"
          y="76.678"
          fill={colors.primary[200]}
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M179 127L264 127"
        ></path>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M179 148L264 148"
        ></path>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M179 137L264 137"
        ></path>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M179 158L264 158"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M104.5 205v-63h39v63L124 183.5 104.5 205z"
        ></path>
        <path
          fill={colors.primary[50]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M135.499 114.277l1.267-1.548-1.267 1.548a5.995 5.995 0 002.685 1.252l6.268 1.186a2 2 0 011.499 1.258l1.871-.708-1.871.708 2.256 5.967a5.994 5.994 0 001.699 2.426l4.835 4.161a2 2 0 01.67 1.839l-1.03 6.295a6.002 6.002 0 00.258 2.951l1.888-.661-1.888.661 2.107 6.021a1.998 1.998 0 01-.339 1.927l-4.04 4.938 1.548 1.266-1.548-1.266a5.996 5.996 0 00-1.252 2.684l-1.185 6.268a2.003 2.003 0 01-1.258 1.5l-5.967 2.255a6.007 6.007 0 00-2.427 1.699l-4.161 4.836a1.998 1.998 0 01-1.838.669l-6.296-1.029a5.992 5.992 0 00-2.951.258l.661 1.887-.661-1.887-6.021 2.107a1.998 1.998 0 01-1.927-.34l-4.937-4.039-1.267 1.547 1.267-1.547a6.005 6.005 0 00-2.685-1.252l-6.268-1.186a1.998 1.998 0 01-1.499-1.258l-2.256-5.967a5.998 5.998 0 00-1.7-2.426l-4.835-4.161a2 2 0 01-.67-1.839l1.03-6.295a6 6 0 00-.257-2.951l-1.888.661 1.888-.661-2.108-6.021a2 2 0 01.34-1.927l4.04-4.938a6 6 0 001.252-2.684l1.185-6.268a2.002 2.002 0 011.258-1.5l5.967-2.255a6.004 6.004 0 002.426-1.699l4.161-4.836a2 2 0 011.839-.669l6.296 1.029a5.988 5.988 0 002.95-.258l6.022-2.107a1.999 1.999 0 011.927.34l4.937 4.039z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M126.426 122.433a2.001 2.001 0 011.927.34l2.306 1.887a6.007 6.007 0 002.684 1.252l2.928.553c.684.13 1.252.606 1.499 1.258l1.053 2.787a6.004 6.004 0 001.699 2.426l2.259 1.943c.528.455.781 1.152.669 1.839l-.481 2.94a6.002 6.002 0 00.258 2.951l.984 2.812a1.998 1.998 0 01-.339 1.927l-1.887 2.306 1.548 1.267-1.548-1.267a5.995 5.995 0 00-1.252 2.685l-.554 2.927a1.998 1.998 0 01-1.257 1.499l-2.787 1.054a6.004 6.004 0 00-2.427 1.699l-1.943 2.258a1.999 1.999 0 01-1.839.669l-2.94-.481a6.004 6.004 0 00-2.951.259l-2.812.984a2.002 2.002 0 01-1.927-.34l-2.306-1.887-1.266 1.548 1.266-1.548a5.995 5.995 0 00-2.684-1.251l-2.927-.554a2.003 2.003 0 01-1.5-1.258l-1.053-2.787-1.871.708 1.871-.708a6.004 6.004 0 00-1.699-2.426l-2.258-1.943a2 2 0 01-.67-1.839l.481-2.94a6.002 6.002 0 00-.258-2.951l-.984-2.812a1.998 1.998 0 01.34-1.927l1.886-2.306a5.995 5.995 0 001.252-2.685l.554-2.927a1.998 1.998 0 011.258-1.499l2.786-1.054a5.988 5.988 0 002.427-1.699l1.943-2.258a1.999 1.999 0 011.839-.669l2.94.481c.99.162 2.004.073 2.951-.258l2.812-.985z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_3952_10331">
          <path
            fill="#fff"
            d="M0 0H36.438V41.643H0z"
            transform="translate(229.78 76.678)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationBonds;
