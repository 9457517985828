const lightRed = {
  primary: {
    main: '#FA6864',
    50: '#FEEAE9',
    100: '#FEDCDB',
    150: '#FDCDCC',
    200: '#FDBFBD',
    300: '#FCA29F',
    400: '#FB8582',
    500: '#FA6864',
    600: '#F82E29',
    700: '#DC0D07',
    750: '#BF0B06',
    800: '#A10905',
    850: '#840804',
    900: '#660603',
  },
  secondary: {
    main: '#FD8768',
    50: '#FFF3F0',
    100: '#FFE7E1',
    150: '#FEDBD2',
    200: '#FECFC3',
    300: '#FEB7A4',
    400: '#FD9F86',
    500: '#FD8768',
    600: '#FC572C',
    700: '#E83303',
    750: '#C92C03',
    800: '#AB2502',
    850: '#8D1F02',
    900: '#6F1801',
  },
};

export default lightRed;
