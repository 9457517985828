import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersActivity: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M29.048 6.253a2.083 2.083 0 012.07 1.349l5.742 15.315h6.89a2.083 2.083 0 110 4.166h-8.333a2.083 2.083 0 01-1.951-1.351l-3.9-10.403-6.711 26.843a2.084 2.084 0 01-3.972.226L13.14 27.083H6.25a2.083 2.083 0 010-4.166h8.333c.869 0 1.646.538 1.951 1.351l3.9 10.403 6.712-26.843a2.083 2.083 0 011.902-1.575z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersActivity;
