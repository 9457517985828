import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationPlane: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3799_10177"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3799_10177)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="4"
          d="M208.016 47.687l-63.952 64.631h52.655l41.362-59.536c1.843-2.652-.055-6.282-3.285-6.282h-23.937a4 4 0 00-2.843 1.187z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="4"
          d="M99.736 99.154h150.62a4 4 0 003.592-2.239l15.125-30.855a4 4 0 013.592-2.24h14.983a4 4 0 014 4v29.298a4 4 0 001.648 3.234l5.974 4.345c7.621 11.778-49.203 46.419-71.361 46.419H78.259c-29.802 0-36.04-43.648 21.477-51.962z"
        ></path>
        <mask
          id="mask1_3799_10177"
          style={{ maskType: 'alpha' }}
          width="248"
          height="93"
          x="54"
          y="61"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="bevel"
            strokeWidth="4"
            d="M99.739 99.154h150.62a4 4 0 003.592-2.239l15.125-30.855a4 4 0 013.592-2.24h14.983a4 4 0 014 4v29.298a4 4 0 001.648 3.234l5.973 4.345c7.622 11.778-49.202 46.419-71.361 46.419H78.261c-29.801 0-36.039-43.648 21.478-51.962z"
          ></path>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="4"
          mask="url(#mask1_3799_10177)"
        >
          <path
            fill="#fff"
            d="M90.316 126.175H33.907l27.02-39.491h52.655l-14.051 33.371a10 10 0 01-9.216 6.12zM101.49 138.961l-29.476 29.477h196.762l35.334-35.334H115.632a20.003 20.003 0 00-14.142 5.857z"
          ></path>
          <path d="M99.739 99.154h150.62a4 4 0 003.592-2.239l15.125-30.855a4 4 0 013.592-2.24h14.983a4 4 0 014 4v29.298a4 4 0 001.648 3.234l5.973 4.345c7.622 11.778-49.202 46.419-71.361 46.419H78.261c-29.801 0-36.039-43.648 21.478-51.962z"></path>
        </g>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="4"
          d="M213.556 199.8l-64.642-66.003h53.348l41.474 60.954c1.806 2.655-.096 6.25-3.307 6.25h-24.015a4.003 4.003 0 01-2.858-1.201z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationPlane;
