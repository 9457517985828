import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationElectricMoped: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2ZM137.39 114.095a2 2 0 0 1 1.296-2.514l21.19-6.781a2 2 0 0 1 1.219 3.81l-21.19 6.781a2.002 2.002 0 0 1-2.515-1.296Z"
          fill={colors.primary[500]}
        />
        <path
          d="M135 201.5c0 12.979-10.521 23.5-23.5 23.5S88 214.479 88 201.5 98.521 178 111.5 178s23.5 10.521 23.5 23.5Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.5 221c10.77 0 19.5-8.73 19.5-19.5s-8.73-19.5-19.5-19.5S92 190.73 92 201.5s8.73 19.5 19.5 19.5Zm0 4c12.979 0 23.5-10.521 23.5-23.5S124.479 178 111.5 178 88 188.521 88 201.5 98.521 225 111.5 225Z"
          fill={colors.primary[500]}
        />
        <path
          d="M122 201.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.5 208a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Zm0 4c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5 4.701 10.5 10.5 10.5Z"
          fill={colors.primary[500]}
        />
        <path
          d="M254 201.5c0 12.979-10.521 23.5-23.5 23.5S207 214.479 207 201.5s10.521-23.5 23.5-23.5 23.5 10.521 23.5 23.5Z"
          fill={colors.primary[100]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.5 221c10.77 0 19.5-8.73 19.5-19.5s-8.73-19.5-19.5-19.5-19.5 8.73-19.5 19.5 8.73 19.5 19.5 19.5Zm0 4c12.979 0 23.5-10.521 23.5-23.5S243.479 178 230.5 178 207 188.521 207 201.5s10.521 23.5 23.5 23.5Z"
          fill={colors.primary[500]}
        />
        <path
          d="M241 201.5c0 5.799-4.701 10.5-10.5 10.5s-10.5-4.701-10.5-10.5 4.701-10.5 10.5-10.5 10.5 4.701 10.5 10.5Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M230.5 208a6.5 6.5 0 1 0 0-13 6.5 6.5 0 0 0 0 13Zm0 4c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5-10.5 4.701-10.5 10.5 4.701 10.5 10.5 10.5Z"
          fill={colors.primary[500]}
        />
        <path
          d="M143.5 167v-48c0-8-9.2-7-12-1-3.5 7.5-16 31.5-19.5 37.5s-5.5 15.5 5 15.5 11 4.5 19.5 16 14.5 15 22.5 15h84c7 0 24.5 2.5 24.5-9s-8-42-38.5-42h-27.5c-5 0-11.5 1.5-11.5 11.5v20h-31c-5 0-15.5-.5-15.5-15.5Z"
          fill="#fff"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.55 115.25c-1.851 0-4.157 1.28-5.238 3.596-3.523 7.549-16.05 31.603-19.584 37.662-1.666 2.855-2.791 6.267-2.492 8.754.14 1.165.573 2.01 1.308 2.596.766.611 2.112 1.142 4.456 1.142 2.755 0 5.01.293 6.964 1.003 1.988.722 3.547 1.832 4.968 3.288 1.383 1.418 2.657 3.19 4.058 5.222.304.44.615.896.938 1.367 1.19 1.739 2.529 3.696 4.18 5.931 4.193 5.672 7.649 9.202 10.918 11.324 3.203 2.079 6.315 2.865 9.974 2.865h84c1.083 0 2.315.05 3.641.103 3.355.135 7.305.294 10.928-.267 2.48-.384 4.49-1.075 5.847-2.147 1.256-.993 2.084-2.408 2.084-4.689 0-5.418-1.916-15.533-7.496-24.233C252.487 160.165 243.445 153 229 153h-27.5c-2.368 0-4.721.37-6.454 1.621-1.604 1.159-3.046 3.35-3.046 7.879v20a2 2 0 0 1-2 2h-31c-2.504 0-6.898-.099-10.673-2.422-3.97-2.443-6.827-7.049-6.827-15.078v-48c0-1.615-.457-2.507-.919-2.985-.465-.481-1.147-.765-2.031-.765Zm4.907-2.015c1.35 1.397 2.043 3.38 2.043 5.765v48c0 6.971 2.393 10.115 4.923 11.672 2.725 1.677 6.081 1.828 8.577 1.828h29v-18c0-5.471 1.808-9.03 4.704-11.121 2.767-1.999 6.164-2.379 8.796-2.379H229c16.055 0 26.263 8.085 32.371 17.608 6.045 9.425 8.129 20.31 8.129 26.392 0 3.469-1.36 6.054-3.604 7.827-2.143 1.693-4.945 2.533-7.715 2.962-4.043.626-8.701.439-12.132.301-1.189-.048-2.231-.09-3.049-.09h-84c-4.341 0-8.229-.964-12.151-3.51-3.856-2.503-7.65-6.473-11.957-12.301-1.7-2.3-3.091-4.333-4.284-6.078-.317-.463-.62-.905-.911-1.327-1.411-2.046-2.512-3.555-3.629-4.7-1.079-1.106-2.145-1.84-3.47-2.321-1.358-.494-3.103-.763-5.598-.763-2.906 0-5.247-.656-6.95-2.014-1.734-1.383-2.551-3.288-2.786-5.248-.451-3.763 1.174-8.101 3.008-11.246 3.466-5.941 15.938-29.887 19.416-37.338 1.719-3.684 5.413-5.904 8.862-5.904 1.766 0 3.559.591 4.907 1.985Z"
          fill={colors.primary[500]}
        />
        <path
          d="M192 142.5a5.5 5.5 0 0 1 5.5-5.5h36a5.5 5.5 0 1 1 0 11h-36a5.5 5.5 0 0 1-5.5-5.5Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.5 141h-36a1.5 1.5 0 0 0 0 3h36a1.5 1.5 0 0 0 0-3Zm-36-4a5.5 5.5 0 1 0 0 11h36a5.5 5.5 0 1 0 0-11h-36Z"
          fill={colors.primary[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M114 128c0 1.105.901 2.015 1.994 1.859C122.782 128.891 128 123.055 128 116s-5.218-12.891-12.006-13.859c-1.093-.156-1.994.754-1.994 1.859v24Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112 104c0-2.119 1.786-4.194 4.276-3.839C124.035 101.267 130 107.935 130 116c0 8.065-5.965 14.733-13.724 15.839-2.49.355-4.276-1.72-4.276-3.839v-24Zm4-.002v.168a14.334 14.334 0 0 0-.288-.045.333.333 0 0 0 .258-.078.113.113 0 0 0 .03-.043v-.002Zm0 23.836v-23.668c5.675.951 10 5.889 10 11.834 0 5.945-4.325 10.883-10 11.834Zm0 0v.168V128a.113.113 0 0 0-.03-.043.333.333 0 0 0-.258-.078l.288-.045ZM164.298 35.889a2 2 0 0 1 2.679.906l5.225 10.567 4.344-4.41a2.001 2.001 0 0 1 3.196.474l6.448 12.273a2 2 0 0 1-3.542 1.86l-5.174-9.849-4.394 4.46a2 2 0 0 1-3.217-.516l-6.472-13.086a2 2 0 0 1 .907-2.68ZM211.096 15.11a2 2 0 0 1 1.235 2.546l-3.861 11.138h6.19a1.998 1.998 0 0 1 1.905 2.609l-4.218 13.206a2 2 0 0 1-3.811-1.217l3.386-10.598h-6.262a2 2 0 0 1-1.89-2.655l4.782-13.793a2 2 0 0 1 2.544-1.235ZM281.013 65.143a2 2 0 0 1-2.361 1.557l-11.548-2.37.809 6.136a1.998 1.998 0 0 1-2.337 2.23l-13.644-2.456a2 2 0 1 1 .708-3.936l10.95 1.97-.819-6.208a1.998 1.998 0 0 1 2.385-2.22l14.3 2.936a2 2 0 0 1 1.557 2.361ZM264.152 102.478a2 2 0 0 1-2.807.347l-9.294-7.253-2 5.859a1.999 1.999 0 0 1-3.084.96l-11.136-8.26a2 2 0 0 1 2.383-3.212l8.936 6.628 2.023-5.926a2 2 0 0 1 3.123-.93l11.51 8.98a2 2 0 0 1 .346 2.807ZM200.233 82.265a2 2 0 0 1-.337 2.808l-14 11a1.96 1.96 0 0 1-.201.139c-3.323 2.01-7.136 5.776-8.224 10.465-1.034 4.458.258 10.412 8.473 17.29a2 2 0 1 1-2.568 3.067c-8.985-7.522-11.276-14.901-9.802-21.26 1.408-6.07 6.12-10.577 9.944-12.92l13.907-10.926a2 2 0 0 1 2.808.337Z"
          fill={colors.primary[500]}
        />
        <path
          d="M229.791 50.843a2 2 0 0 1-.442 2.793l-12.719 9.241a2 2 0 0 1-2.351-3.236l12.719-9.24a2 2 0 0 1 2.793.442ZM239.791 63.843a2 2 0 0 1-.443 2.793l-12.719 9.242a2 2 0 1 1-2.351-3.237l12.719-9.24a2 2 0 0 1 2.794.442Z"
          fill={colors.primary[500]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M219.457 89.442c-.001 0-.002.003-.001.004v.004a16.5 16.5 0 0 1-19.4-26.695v-.004c-.001-.002 0-.004.001-.005l8.719-6.334a2 2 0 0 1 2.793.442l17.046 23.462a2 2 0 0 1-.442 2.793l-8.716 6.333Z"
          fill={colors.primary[200]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M207.6 54.794a4 4 0 0 1 5.587.885l17.046 23.461a4 4 0 0 1-.885 5.588l-8.592 6.242c-.04.034-.082.067-.125.099a18.5 18.5 0 0 1-21.882-29.836c.042-.036.087-.071.132-.104l8.719-6.335Zm-6.244 9.48a2.09 2.09 0 0 1-.124.099 14.505 14.505 0 0 0-5.794 9.459 14.5 14.5 0 0 0 22.726 14.084 2 2 0 0 1 .118-.092l8.715-6.332-17.046-23.462-8.595 6.245Z"
          fill={colors.primary[500]}
        />
      </g>
    </svg>
  );
};

export default IllustrationElectricMoped;
