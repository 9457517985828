import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleLookingForCar: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M16.57 13l-6.42 11H5.57a3.5 3.5 0 100 7H8a3.45 3.45 0 002.48-1l1.24-1.23L16.57 37h29.5V13h-29.5zM9.1 28.56A1.521 1.521 0 018 29H5.57a1.5 1.5 0 110-3h4.58l.56.95-1.61 1.61zm5-1.56a2.087 2.087 0 01-.51-.07l1.22-1.22a1 1 0 00.19-1.09 1 1 0 00-.92-.62h-1.73a2 2 0 111.72 3h.03zm24.56-2.94a5.7 5.7 0 01-4.27 4.3 5.8 5.8 0 01-4.6-.87l-5.2 5.2a1 1 0 01-1.48 0 1.06 1.06 0 010-1.48l5.2-5.2a5.76 5.76 0 1110.35-2v.05zm-6.32-4.9a3.67 3.67 0 101.07 7.26 3.67 3.67 0 00-1.07-7.26z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleLookingForCar;
