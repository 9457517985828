import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleTractor: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M29.047 11.666c.433-.09.878-.068 1.317-.069h6.767c.501.003 1.038.069 1.437.4.444.343.74.881.719 1.448 0 2.257.003 4.514-.002 6.77-.093.94.634 1.662.927 2.502-.369.767-.75 1.526-1.138 2.284-1.024-.332-2.116-.546-3.192-.405-2.025.142-3.956 1.21-5.193 2.814-.993 1.285-1.575 2.905-1.55 4.537-4.093.016-8.188.003-12.281.006-.601-1.647-1.984-3.002-3.683-3.476-1.13-.354-2.342-.242-3.464.082-.01-1.907-.001-3.815-.006-5.723-.011-.685.323-1.392.938-1.727.591-.356 1.303-.23 1.958-.252 1.817-.019 3.638.04 5.452-.029-.023-2.456-.004-4.91-.01-7.366.616-.005 1.233-.006 1.85 0 .003 2.465-.004 4.929.001 7.393 1.535.006 3.069.002 4.604.001 1.11-2.683 2.154-5.393 3.25-8.081.223-.55.72-.98 1.299-1.11zm.892 1.815c-.348.039-.62.31-.734.627-.735 1.83-1.468 3.66-2.197 5.493-.237.57.216 1.27.841 1.252 2.559.009 5.118.001 7.676.003.498.044 1.004-.37.99-.885.007-1.87.007-3.74 0-5.61.021-.528-.492-.936-.996-.9-1.86.01-3.722-.029-5.58.02z"
    ></path>
    <path
      fill="currentColor"
      d="M35.568 25.55c1.446-.213 2.968.067 4.227.815 1.639.943 2.81 2.65 3.111 4.514.282 1.579-.083 3.25-.953 4.59a6.541 6.541 0 01-4.313 2.86c-1.288.17-2.644.077-3.822-.51-1.855-.84-3.268-2.616-3.635-4.624a6.447 6.447 0 01.755-4.507c.959-1.678 2.718-2.862 4.63-3.137zm.457 2.74c-1.599.19-2.959 1.546-3.158 3.145-.224 1.456.53 2.989 1.81 3.71 1.507.907 3.637.526 4.715-.87 1.093-1.294 1.088-3.353.008-4.653-.787-.998-2.12-1.512-3.375-1.332zM11.041 29.211c1.07-.118 2.177.105 3.08.703 1.224.77 2.02 2.154 2.106 3.595a4.63 4.63 0 01-1.28 3.486c-.87.902-2.119 1.463-3.382 1.403-1.25.033-2.475-.55-3.323-1.453-1.025-1.073-1.454-2.66-1.142-4.107.37-1.886 2.034-3.412 3.941-3.627zm.122 2.41c-.975.198-1.752 1.111-1.759 2.11-.054 1.066.78 2.09 1.834 2.248.402.028.83.047 1.208-.123.87-.353 1.478-1.288 1.374-2.233-.045-1.292-1.405-2.297-2.657-2.001z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleTractor;
