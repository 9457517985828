import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyStone: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M10.9 43.63L8.05 33.2a.56.56 0 010-.38L12 26.07a.47.47 0 00.06-.15L15 12.21a.43.43 0 01.22-.32l7.24-4.53a.5.5 0 01.75.3L28 25.9a.49.49 0 010 .21l-2.93 17.47a.5.5 0 01-.49.42h-13.2a.5.5 0 01-.48-.37z"
    ></path>
    <path
      fill="currentColor"
      d="M42 29.26L39.08 43.6a.5.5 0 01-.49.4h-11a.498.498 0 01-.49-.58L30 26.33l.07-.42-.11-.42-2.2-8.34a.5.5 0 01.67-.59l8.35 3.34a.48.48 0 01.3.37l.92 4.58a.51.51 0 00.14.26l3.7 3.7a.509.509 0 01.16.45z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyStone;
