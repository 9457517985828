import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsVolunteerInsurance: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.843 11.042a7.996 7.996 0 00-8.254 10.962 7.995 7.995 0 001.83 2.717l.013.013 13.585 13.454 13.585-13.454a2.03 2.03 0 01.128-.117 7.997 7.997 0 00-3.3-13.199 7.995 7.995 0 00-8.815 2.814 2 2 0 01-3.203-.01 7.996 7.996 0 00-5.57-3.18zm23.435 16.67L26.424 42.424a2 2 0 01-2.815 0L8.623 27.582a11.996 11.996 0 0116.402-17.5 11.99 11.99 0 0111.618-2.476 11.995 11.995 0 014.767 19.988 2.04 2.04 0 01-.132.118z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M26.43 11.603a2 2 0 010 2.829l-6.58 6.581 1.084 1.085c.598.598 1.57.598 2.169 0l1.999-1.999a8.36 8.36 0 0111.823 0l4.498 4.498a2 2 0 01-2.828 2.828l-4.498-4.498a4.36 4.36 0 00-6.167 0l-1.999 2a5.535 5.535 0 01-7.826 0L17.02 23.84a4 4 0 010-5.655l6.582-6.583a2 2 0 012.829 0zM24.602 30.593a2 2 0 012.828 0l3.998 3.998A2 2 0 0128.6 37.42l-3.998-3.998a2 2 0 010-2.829z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M29.6 25.596a2 2 0 012.828 0l3.998 3.998a2 2 0 01-2.829 2.828L29.6 28.425a2 2 0 010-2.829z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsVolunteerInsurance;
