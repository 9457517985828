const elvas = {
  primary: {
    main: '#188BEE',
    50: '#D8ECFC',
    100: '#C3E1FB',
    150: '#AED6F9',
    200: '#98CBF7',
    300: '#6EB6F4',
    400: '#43A0F1',
    500: '#188BEE',
    600: '#0E70C5',
    700: '#0B5595',
    750: '#09487D',
    800: '#073A66',
    850: '#062C4E',
    900: '#041F36',
  },
  secondary: {
    main: '#00DDBB',
    50: '#DAFFF9',
    100: '#BEFFF5',
    150: '#A2FFF1',
    200: '#86FFEC',
    300: '#4EFFE4',
    400: '#16FFDB',
    500: '#00DDBB',
    600: '#00AA90',
    700: '#007765',
    750: '#005E4F',
    800: '#00443A',
    850: '#002B24',
    900: '#00110E',
  },
};

export default elvas;
