import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsThrash: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 15a2 2 0 012-2h32a2 2 0 110 4H9a2 2 0 01-2-2zM21 21a2 2 0 012 2v12a2 2 0 11-4 0V23a2 2 0 012-2zM29 21a2 2 0 012 2v12a2 2 0 11-4 0V23a2 2 0 012-2z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.834 13.007a2 2 0 012.16 1.827l2 24c.004.055.006.11.006.166a2 2 0 002 2h16a2 2 0 002-2c0-.055.002-.11.007-.166l2-24a2 2 0 113.986.332L39 39.092A6 6 0 0133 45H17a6 6 0 01-6-5.908L9.008 15.166a2 2 0 011.827-2.16z"
      clipRule="evenodd"
    ></path>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.172 6.172A4 4 0 0121 5h8a4 4 0 014 4v6a2 2 0 11-4 0V9h-8v6a2 2 0 11-4 0V9a4 4 0 011.172-2.828z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default GeneralActionsThrash;
