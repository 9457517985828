import React from 'react';
import { PaginationProps } from '..';

interface Props {
  page?: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  pages?: number;
}

const usePagination = ({
  page = 1,
  setPage,
  pages = 1,
}: Props): PaginationProps => {
  const onNext = () => {
    setPage((prev) => (prev += 1));
  };

  const onPrev = () => {
    setPage((prev) => (prev -= 1));
  };

  const onPageClick = (page: number) => {
    setPage(page);
  };

  return {
    pages,
    currentPage: page,
    onNext,
    onPrev,
    onPageClick,
  };
};

export default usePagination;
