import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationTravelsAYearOnce: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_2628_9841"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_2628_9841)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <rect
          width="126"
          height="82.802"
          x="115"
          y="142.97"
          fill="#fff"
          rx="2"
        ></rect>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M168.999 128.571h18.002a7.201 7.201 0 017.2 7.2H161.8a7.197 7.197 0 017.199-7.2v0z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M197.805 142.971h17.999v82.8h-17.999v-82.8z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M203.205 157.372a3.6 3.6 0 107.201.001 3.6 3.6 0 00-7.201-.001zM203.205 211.372a3.6 3.6 0 107.201.001 3.6 3.6 0 00-7.201-.001z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M140.199 142.971h17.999v82.8h-17.999v-82.8z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M145.599 157.372a3.601 3.601 0 006.146 2.546 3.6 3.6 0 10-6.146-2.546zM145.599 211.372a3.601 3.601 0 006.146 2.546 3.6 3.6 0 10-6.146-2.546zM155.894 177.244a1.801 1.801 0 002.233 1.224l13.822-4.032a1.802 1.802 0 001.226-2.232l-4.324-14.836a1.82 1.82 0 00-.324-.627 1.818 1.818 0 00-.541-.454l-5.388-2.955a1.8 1.8 0 00-1.371-.149l-5.48 1.597a1.787 1.787 0 00-.627.325c-.186.148-.34.332-.454.54l-2.95 5.392a1.804 1.804 0 00-.149 1.371l4.327 14.836z"
        ></path>
        <rect
          width="126"
          height="82.802"
          x="115"
          y="142.97"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M194.2 135.771v7.2M161.8 135.771v7.2M159.999 159.171s-6.514-20.183-1.799-22.551a3.1 3.1 0 013.6.681"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationTravelsAYearOnce;
