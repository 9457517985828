import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleDamageAnimals: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M26.59 41.77l3.09.35v.37a1.08 1.08 0 01-.91 1.07l-1.84.3c-1.135.19-2.295.19-3.43 0l-1.83-.3a1.09 1.09 0 01-.92-1.07v-.37l3.1-.35c.91-.1 1.83-.1 2.74 0zM30.43 31.94l-3.66-.47a12.057 12.057 0 00-3.1 0l-3.67.47a4.1 4.1 0 00.5 8.16l3.32-.37c.92-.1 1.85-.1 2.77 0l3.33.37a4.1 4.1 0 00.5-8.16h.01zM21 37a1 1 0 110-2.002A1 1 0 0121 37zm8 0a1 1 0 110-2.002A1 1 0 0129 37z"
    ></path>
    <path
      fill="currentColor"
      d="M42.84 15.16l-6.34-2.53a2.32 2.32 0 00-1.64 0l-1.47.54c.165-.712.249-1.44.25-2.17a8.37 8.37 0 00-1.95-4.95.15.15 0 00-.27.08c-.073 1.37-.36 2.72-.85 4a8.55 8.55 0 01-1.8 2.64H21.5a8.49 8.49 0 01-1.8-2.65 12.69 12.69 0 01-.87-4 .14.14 0 00-.25-.08 8.37 8.37 0 00-1.95 5c.003.758.094 1.513.27 2.25l-1.8-.65a2.32 2.32 0 00-1.64 0l-6.31 2.52a.24.24 0 00-.06.41l2.73 2.3a6.06 6.06 0 003.91 1.42h1.94l-.36.6a7.24 7.24 0 00-.74 5.5l1.64 6.2a6 6 0 013.06-1.32l4.05-.52c1.262-.16 2.538-.16 3.8 0l4 .52a6.08 6.08 0 013.07 1.32l1.64-6.2a7.198 7.198 0 00-.76-5.5l-.35-.6h1.5a6.11 6.11 0 003.92-1.42l2.73-2.3a.24.24 0 00-.03-.41zM20.27 25a2 2 0 01-1.73-1A2 2 0 0122 24a2 2 0 01-1.73 1zm9.46 0A2 2 0 0128 24a2 2 0 013.46 0 2.002 2.002 0 01-1.73 1z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleDamageAnimals;
