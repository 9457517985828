import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationCamper: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={356}
        height={228}
      >
        <path d="M0 0h356v228H0V0Z" fill="#C0C2C9" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160Z"
          fill={colors.primary[50]}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 226a2 2 0 0 1 2-2h310a2 2 0 1 1 0 4H22a2 2 0 0 1-2-2Z"
          fill={colors.primary[500]}
        />
        <path
          d="M325.789 70.82H92.253a10.27 10.27 0 0 0-8.328 4.24l-57.848 80.102a20.543 20.543 0 0 0-3.08 17.674l7.527 26.352 20.538 5.134a20.54 20.54 0 0 1 41.078 0h164.312a20.542 20.542 0 0 1 20.539-20.539 20.539 20.539 0 0 1 20.539 20.539l28.804-2.526a8 8 0 0 0 7.301-7.958l.154-115.008a8 8 0 0 0-8-8.01Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.21 163.353c.516-3.001 2.208-5.881 4.067-8.183l1.602-2.215h300.289v10.398H25.209ZM82.767 81.089h248.401v10.27H75.348l7.419-10.27Z"
          fill={colors.primary[100]}
        />
        <path
          d="M164.027 204.322h51.347v-92.425a10.27 10.27 0 0 0-10.269-10.269h-30.809a10.27 10.27 0 0 0-10.269 10.269v92.425Z"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M297.529 204.322a20.542 20.542 0 0 1-6.015 14.524 20.542 20.542 0 0 1-29.047 0 20.54 20.54 0 1 1 35.062-14.524Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M285.169 204.322a8.178 8.178 0 1 1-13.962-5.783 8.178 8.178 0 0 1 13.962 5.783Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.135 204.322a20.54 20.54 0 1 1-41.08 0 20.54 20.54 0 0 1 41.08 0Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M79.775 204.322a8.179 8.179 0 1 1-16.358.001 8.179 8.179 0 0 1 16.358-.001Z"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M169.521 55.415h23.044a10.272 10.272 0 0 1 9.746 7.024l2.793 8.38h-51.347l7.189-10.824a10.274 10.274 0 0 1 8.575-4.58Z"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M271.856 101.628h-30.808v30.808h30.808v-30.808ZM143.488 101.628h-30.809v30.808h30.809v-30.808ZM64.762 101.628h22.243a5.135 5.135 0 0 1 5.135 5.134v20.539a5.135 5.135 0 0 1-5.135 5.135H42.498l22.264-30.808Z"
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth={4}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default IllustrationCamper;
