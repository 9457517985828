import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilityPets: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M32.32 12.34l-.24-.17a5.4 5.4 0 00-5.66-.32 3.26 3.26 0 01-3 0 5.42 5.42 0 00-5.67.32L14.08 30c.51 1 1 2 1.4 3 1.49 3.76 2.5 7.93 5 8.84.8.3 2.62-.29 3.61-.65a1.93 1.93 0 011.36 0c1 .41 3 1.07 3.8.66 2.8-1.41 3.73-5.09 5.22-8.87.4-1 .91-2.05 1.41-3.09l-3.56-17.55zM20.5 24a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm5.5 9v5a1 1 0 01-2 0v-5a3 3 0 01-3-3 1 1 0 011-1h6a1 1 0 011 1 3 3 0 01-3 3zm3.5-9a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM39.24 29.78l6.65-8.53a.69.69 0 00-.08-.94l-10-9.17a.73.73 0 00-1.22.69l3.62 17.7a.59.59 0 001.03.25zM10.75 29.76l-6.61-8.48a.73.73 0 01.08-1l9.93-9.1a.76.76 0 011.26.72L11.8 29.52a.59.59 0 01-1.05.24z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilityPets;
