import { Box, RadioProps, useRadio } from '@wegroup/design-system';
import React from 'react';

const ThumbsRadio: React.FC<RadioProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        color="gray.200"
        _checked={{
          color: 'gray.600',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default ThumbsRadio;
