import React from 'react';

import { Text, ModalBody, VStack, Stack } from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import { CustomerSegmentGoal } from '../../../../dashboard/src/types/goals';
import GoalItem from './GoalItem';
import IllustrationBusiness from '../../../../design-system/src/assets/illustrations-rounded/IllustrationBusiness';
import IllustrationPersonal from '../../../../design-system/src/assets/illustrations-rounded/IllustrationPersonal';

interface Props {
  onChange: (goal: CustomerSegmentGoal) => void;
  isLoading: boolean;
}

const GoalsModalBody: React.FC<React.PropsWithChildren<Props>> = ({
  onChange,
}) => {
  const { t } = useTranslation();

  return (
    <ModalBody>
      <VStack spacing="6" align="stretch">
        <VStack spacing="2">
          <Text textStyle="h5Headline" textAlign="center">
            {t('DASHBOARD_NEW-GOALS_MODAL-HEADER_TITLE')}
          </Text>
        </VStack>

        <Stack
          spacing="6"
          align="stretch"
          direction={{ base: 'column', tablet: 'row' }}
        >
          <GoalItem
            title={t('DASHBOARD_NEW-GOALS_MODAL-HEADER_COMMERCIAL-LINES')}
            goal={CustomerSegmentGoal.COMMERCIAL_LINES}
            onClick={() => onChange(CustomerSegmentGoal.COMMERCIAL_LINES)}
            illustration={<IllustrationBusiness />}
          />
          <GoalItem
            title={t('DASHBOARD_NEW-GOALS_MODAL-HEADER_PERSONAL-LINES')}
            goal={CustomerSegmentGoal.PERSONAL_LINES}
            onClick={() => onChange(CustomerSegmentGoal.PERSONAL_LINES)}
            illustration={<IllustrationPersonal />}
          />
        </Stack>
      </VStack>
    </ModalBody>
  );
};

export default GoalsModalBody;
