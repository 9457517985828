import React from 'react';
import { Card, HStack, Icon, Text } from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import LouiseChatIntroColumn from './LouiseChatIntroColumn';

export type LouiseChatExample = {
  question: string;
};

interface Props<T> {
  onClick: (values: T) => void;
  examples: T[];
}

function LouiseChatIntroExamples<T extends LouiseChatExample>({
  onClick,
  examples,
}: Props<T>) {
  const { t } = useTranslation();

  return (
    <LouiseChatIntroColumn
      title={t('TOOLKIT_INSURANCE-TERMS-BOT_INTRO_EXAMPLES')}
      icon={
        <Icon name="GeneralOthersComment" boxSize={8} color="primary.500" />
      }
    >
      {examples.map((example, index) => (
        <Card
          key={index}
          onClick={() => onClick({ ...example, question: t(example.question) })}
          cursor="pointer"
          _hover={{ borderColor: 'primary.500' }}
          boxShadow="none"
          transition="0.1s"
        >
          <HStack justify="space-between">
            <Text>{t(example.question)}</Text>
            <Icon name="GeneralActionsArrowRight" />
          </HStack>
        </Card>
      ))}
    </LouiseChatIntroColumn>
  );
}

export default LouiseChatIntroExamples;
