import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleMechanicalBreakdown: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M40 15.09a5.75 5.75 0 002-4.32A5.9 5.9 0 0031.39 7.3a3.1 3.1 0 00-1.18-.23 3.17 3.17 0 00-.66 6.28 3.66 3.66 0 00-.41 1.65 3.7 3.7 0 003.36 3.66 4.08 4.08 0 001.17 2 3.17 3.17 0 00-.24 1.2c0 .202.02.403.06.6a9.058 9.058 0 01-1.67 1.72l-2.54 2a.42.42 0 00.33.74l2.39-.31A10.88 10.88 0 0036.32 25h.32a3.16 3.16 0 003-4.37 4.14 4.14 0 00.36-5.54z"
    ></path>
    <path
      fill="currentColor"
      d="M32.15 30H15.5a.5.5 0 01-.5-.5V29l14.74-8.07a.5.5 0 00-.24-.93 28.389 28.389 0 00-12.37 2.8l-4.75 2.3c-1.31-1.79-4.23-5.83-4.23-5.83a.84.84 0 00-.44-.27l-3.26-.6a.38.38 0 00-.45.38V40h10a6.5 6.5 0 1013 0h4.51a1.5 1.5 0 00.5-.79c.505-1.973.88-3.977 1.12-6-.18-1.11-.51-2.189-.98-3.21zM24 40a3.5 3.5 0 01-7 0 3.105 3.105 0 010-.32 3.44 3.44 0 012.33-3.49A3.519 3.519 0 0124 39.45c.018.183.018.367 0 .55z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleMechanicalBreakdown;
