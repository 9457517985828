import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsMinusOrRemove: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path stroke="currentColor" strokeWidth="4" d="M12 25L38 25" />
  </Icon>
);

export default GeneralActionsMinusOrRemove;
