import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleRearAutomaticEmergencyBraking: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M24.5 16.64a1.861 1.861 0 11-1.86 1.86 1.869 1.869 0 011.86-1.86zm0-4.64a6.5 6.5 0 100 13 6.5 6.5 0 000-13z"
    ></path>
    <path
      fill="currentColor"
      d="M41 18.87a16.171 16.171 0 01-4.187 10.822 16.156 16.156 0 01-27.8-7.604.77.77 0 01.77-.909h1.538c.554 0 .862.255.939.609a12.943 12.943 0 009.035 9.537 12.93 12.93 0 0012.717-3.287 12.942 12.942 0 00-6.247-21.763.77.77 0 01-.616-.77V3.79a.77.77 0 01.94-.77 16.158 16.158 0 019.278 5.624A16.171 16.171 0 0141 18.87z"
    ></path>
    <path
      fill="currentColor"
      d="M24.227 2A17 17 0 008 18.227a.773.773 0 00.773.773h3.268a.773.773 0 00.773-.726 12.194 12.194 0 0111.46-11.46.773.773 0 00.726-.773V2.773A.773.773 0 0024.227 2zM12.1 40.87L10 43.71c6.717 4.34 15.319 5.44 23.01 2.93a25.999 25.999 0 007.06-3.53l-2.228-2.75a22.46 22.46 0 01-6.042 3.02c-6.599 2.15-13.958 1.21-19.7-2.51z"
    ></path>
    <path
      fill="currentColor"
      d="M30.279 37.36c-5.153 1.68-10.916.94-15.415-1.97l-2.1 2.84c5.463 3.54 12.47 4.43 18.736 2.39a21.343 21.343 0 005.753-2.88L35.013 35a17.644 17.644 0 01-4.734 2.36z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleRearAutomaticEmergencyBraking;
