import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSecurities: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="a"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#a)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M75.437 254.408a5.533 5.533 0 004.507 6.392l158.031 27.278a5.534 5.534 0 006.388-4.511l30.571-177.105-38.323-54.298L119.447 31.94a5.526 5.526 0 00-6.387 4.507L75.437 254.408z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M274.937 106.462l-41.23-7.116a5.166 5.166 0 01-4.21-5.967l7.114-41.215 38.326 54.298z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M200.026 181.267l33.993 5.867m-60.578 20.105l55.456 9.573m-121.628 9.566l116.505 20.11"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M179.488 167.702l-7.85-1.356a33.307 33.307 0 01-15.806-7.388 33.715 33.715 0 01-9.97-14.405l18.402 3.176a6.23 6.23 0 004.59-1.14 6.345 6.345 0 002.505-4.047 6.348 6.348 0 00-1.003-4.653 6.232 6.232 0 00-3.942-2.613l-22.513-3.886a30.879 30.879 0 011.409-8.161l32.793 5.661a6.23 6.23 0 004.766-.999 6.356 6.356 0 002.563-6.639 6.33 6.33 0 00-1.024-2.277 6.26 6.26 0 00-1.822-1.693 6.203 6.203 0 00-2.333-.846l-28.684-4.951a33.71 33.71 0 0114.223-10.225 33.305 33.305 0 0117.367-1.659l7.85 1.355a6.23 6.23 0 004.59-1.14 6.353 6.353 0 002.506-4.047 6.355 6.355 0 00-1.004-4.653 6.235 6.235 0 00-3.942-2.613l-7.851-1.355a45.803 45.803 0 00-27.026 3.62 46.463 46.463 0 00-20.329 18.375l-10.431-1.801a6.224 6.224 0 00-4.766.999 6.327 6.327 0 00-1.728 1.802 6.374 6.374 0 00-.835 4.837 6.314 6.314 0 001.025 2.277 6.22 6.22 0 004.154 2.539l7.529 1.299c-.349 1.325-.671 2.665-.906 4.028-.236 1.364-.384 2.749-.5 4.118l-7.529-1.299a6.239 6.239 0 00-4.765.999 6.327 6.327 0 00-2.63 4.136 6.331 6.331 0 001.091 4.779 6.251 6.251 0 001.822 1.694 6.212 6.212 0 002.333.845l10.431 1.801a46.463 46.463 0 0012.994 24.125 45.814 45.814 0 0024.247 12.472l7.851 1.355a6.238 6.238 0 004.59-1.14 6.35 6.35 0 002.505-4.048 6.35 6.35 0 00-1.003-4.653 6.236 6.236 0 00-3.942-2.613l-.002.008z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationSecurities;
