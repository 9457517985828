import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationRealEstate: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3939_10168"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3939_10168)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M55.398 80.926h66.762v145.446H55.398V80.926z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M69.7 95.232h19.075v104.912H69.701V95.232z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M122.16 104.771H49.99a1.74 1.74 0 01-1.742-1.741v-6.056a1.741 1.741 0 011.741-1.742h72.171v9.539zM122.16 128.614H49.99a1.743 1.743 0 01-1.742-1.742v-6.053a1.743 1.743 0 011.741-1.741h72.171v9.536zM122.16 152.459H49.99a1.743 1.743 0 01-1.742-1.741v-6.057a1.741 1.741 0 011.741-1.741h72.171v9.539zM122.16 176.301H49.99a1.74 1.74 0 01-1.742-1.741v-6.053a1.74 1.74 0 011.741-1.741h72.171v9.535zM122.16 200.148H49.99a1.74 1.74 0 01-1.742-1.741v-6.056a1.743 1.743 0 011.741-1.742h72.171v9.539z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M64.934 209.684h57.227v16.692H64.934v-16.692zM109.891 59.469h-6.06a1.74 1.74 0 00-1.741 1.741v19.715h9.542V61.21a1.74 1.74 0 00-1.741-1.741z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M121.906 59.47h81.069v166.907h-81.069V59.471z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M136.468 78.545h14.307v11.921h-14.307V78.545zM181.771 78.545h11.921v11.921h-11.921V78.545zM136.468 102.387h14.307v11.921h-14.307v-11.921zM181.771 102.387h11.921v11.921h-11.921v-11.921zM136.468 126.232h14.307v11.922h-14.307v-11.922zM181.771 126.232h11.921v11.922h-11.921v-11.922zM136.468 150.074h14.307v11.921h-14.307v-11.921zM181.771 150.074h11.921v11.921h-11.921v-11.921zM136.468 173.922h14.307v11.921h-14.307v-11.921zM181.771 173.922h11.921v11.921h-11.921v-11.921z"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M167.181 186.187V78.225"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M144.261 38h-6.052a1.744 1.744 0 00-1.742 1.741v19.72h9.536V39.74A1.744 1.744 0 00144.261 38zM194.335 45.164h-6.057a1.743 1.743 0 00-1.741 1.741v12.566h9.539V46.905a1.74 1.74 0 00-1.741-1.74zM177.47 205.676a2.456 2.456 0 00-2.45-2.453h-24.54a2.453 2.453 0 00-2.454 2.453v20.814h29.444v-20.814z"
        ></path>
        <path
          stroke={colors.primary[200]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M162.747 203.223v23.265"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M314.877 161.444l-20.27-60.548a3.907 3.907 0 00-3.706-2.671h-136.99a3.91 3.91 0 00-3.707 2.671l-20.251 60.548a3.897 3.897 0 001.925 4.714 3.897 3.897 0 001.781.428h177.496a3.914 3.914 0 003.179-1.617 3.903 3.903 0 00.543-3.525v0z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M132.534 166.596h179.743v60.567H132.534v-60.567z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M222.407 193.947h17.584v33.213h-17.584v-33.213zM204.824 193.947h17.583v33.213h-17.583v-33.213zM239.991 193.947h-35.167v-14.704a3.076 3.076 0 013.073-3.073h29.02a3.076 3.076 0 013.074 3.073v14.704z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M208.73 90.4h27.351v15.629H208.73V90.4z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M232.175 68.91a3.911 3.911 0 00-3.907-3.91h-11.722a3.913 3.913 0 00-3.91 3.91V90.4h19.539V68.91z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M208.73 74.772h27.351"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M132.535 166.596l-33.213 22.068v38.495h33.213v-60.563z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M132.534 166.596L90 194.857"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M119.709 199.809h-7.53a3.072 3.072 0 00-3.074 3.073v24.281h13.677v-24.281a3.074 3.074 0 00-3.073-3.073zM157.934 186.131h15.629v25.399h-15.629v-25.399z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M177.473 183.986c0 1.401-1.138 2.151-2.539 2.151h-18.371c-1.401 0-2.535-.744-2.535-2.151v-5.084a2.528 2.528 0 01.741-1.795 2.535 2.535 0 011.794-.744h18.371a2.54 2.54 0 012.539 2.539v5.084z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M154.028 211.531h23.445"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M271.249 186.131h15.628v25.399h-15.628v-25.399z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M290.787 183.986c0 1.401-1.137 2.151-2.539 2.151h-18.367c-1.401 0-2.539-.744-2.539-2.151v-5.084a2.54 2.54 0 012.539-2.539h18.367a2.542 2.542 0 012.539 2.539v5.084z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M267.342 211.531h23.445"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M191.147 134.032a3.912 3.912 0 00-1.405-3l-12.909-10.419a3.903 3.903 0 00-5 0l-12.506 10.419a3.914 3.914 0 00-1.405 3v18.881a3.907 3.907 0 003.919 3.921h25.399a3.904 3.904 0 003.907-3.906v-18.896z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M181.379 156.835h-13.677v-18.426a3.071 3.071 0 013.073-3.073h7.53a3.076 3.076 0 013.074 3.073v18.426z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M286.88 134.03a3.901 3.901 0 00-1.407-2.999l-12.909-10.42a3.91 3.91 0 00-5.004 0l-12.503 10.42a3.897 3.897 0 00-1.408 2.999v18.881a3.91 3.91 0 003.925 3.922h25.397a3.911 3.911 0 003.909-3.907V134.03z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M277.11 156.833h-13.674v-18.425a3.072 3.072 0 013.073-3.074h7.527a3.074 3.074 0 013.074 3.074v18.425z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationRealEstate;
