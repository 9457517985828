import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPropertyEarthquakeLandslide: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M34.51 25.431l-2.987 3.535 2.735 4.124-3.619 3.704.968 3.871-3.283-3.83 2.147-3.787-3.535-5.008 2.104-3.408c-3.114-.421-6.439-.884-9.553-1.263l-.126-3.787h4.46c.674 0 1.053-.8.632-1.347l-2.525-3.156h1.22c.59 0 .884-.673.547-1.136l-2.398-2.946h.757c.379 0 .631-.463.379-.758L18.35 5.19a.433.433 0 00-.716 0l-4.166 5.134c-.252.295-.042.758.379.758h.757l-2.398 2.946c-.38.463-.043 1.136.547 1.136h1.22L11.45 18.32c-.42.547-.042 1.347.631 1.347h4.461l-.126 3.409c-4.166-.463-7.785-.8-10.016-.8V45h37.202V26.82c.042 0-3.788-.59-9.09-1.389z"
    ></path>
  </Icon>
);

export default RiskObjectsPropertyEarthquakeLandslide;
