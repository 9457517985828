import { HStack, LinkProps, Link, Text } from '@wegroup/design-system';
import React from 'react';

interface Props {
  href?: string;
  onClick?: () => void;
  icon?: React.ReactElement;
}

const AcademyLink: React.FC<React.PropsWithChildren<Props & LinkProps>> = ({
  href = '',
  icon,
  onClick,
  children,
  ...props
}) => {
  return (
    <Link
      textStyle="smallBody"
      onClick={onClick}
      {...(href && { href })}
      {...props}
    >
      <HStack spacing="1" align="center">
        {icon}
        <Text>{children}</Text>
      </HStack>
    </Link>
  );
};

export default AcademyLink;
