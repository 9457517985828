import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsEnhanceAIWriting: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <g fillRule="evenodd" clipPath="url(#clip0_3727_14304)" clipRule="evenodd">
      <path
        fill="currentColor"
        d="M15.216 6.532a2 2 0 010 2.828l-1.532 1.533 1.532 1.532a2 2 0 01-2.829 2.828l-1.532-1.532-1.532 1.532a2 2 0 11-2.828-2.828l1.532-1.532L6.495 9.36a2 2 0 012.828-2.828l1.532 1.532 1.532-1.532a2 2 0 012.829 0z"
      ></path>
      <path
        fill="currentColor"
        d="M28.196 11.137c.76-.76 2.193-.56 3.2.448l7.728 7.727c1.008 1.008 1.208 2.44.448 3.201-.76.76-2.193.56-3.2-.448l-7.728-7.727c-1.007-1.008-1.208-2.441-.448-3.201z"
      ></path>
      <path
        fill="currentColor"
        d="M39.841 10.342c-1.825-1.825-4.661-1.825-6.486 0L8.974 34.722v6.488h6.487l24.38-24.38c1.825-1.826 1.825-4.662 0-6.488zM15.974 41.21h.003v1.245-1.245h-.002zM42.67 7.514c-3.388-3.387-8.757-3.387-12.144 0L5.781 32.259a2.754 2.754 0 00-.807 1.948v8.248a2.754 2.754 0 002.754 2.755h8.249c.73 0 1.43-.29 1.947-.807L42.67 19.658c3.387-3.388 3.387-8.757 0-12.144z"
      ></path>
    </g>
  </Icon>
);

export default GeneralActionsEnhanceAIWriting;
