import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsEdit: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        d="M12.67 29.344c0-.28.186-.56.373-.748l12.751-12.75a1.254 1.254 0 111.775 1.774L15.098 30.091l-.56 5.325 5.324-.56L32.38 22.337a1.254 1.254 0 011.775 0c.467.467.467 1.307 0 1.775L21.357 36.91c-.187.186-.467.373-.748.373l-7.333.794c-.42.047-.747-.093-1.027-.373-.28-.28-.374-.654-.374-1.028l.794-7.333zm16.394-10.23L17.387 30.793a1.254 1.254 0 000 1.775c.467.467 1.307.467 1.774 0L30.838 20.89a1.254 1.254 0 000-1.775 1.254 1.254 0 00-1.774 0zm-.14-6.398l2.101-2.102a3.552 3.552 0 015.045 0l3.316 3.316a3.552 3.552 0 010 5.045l-2.102 2.102a1.254 1.254 0 01-1.775 0l-6.586-6.586c-.56-.467-.513-1.261 0-1.775zm2.662.887l4.764 4.764 1.214-1.214a.973.973 0 000-1.401l-3.316-3.316a.973.973 0 00-1.401 0l-1.215 1.214-.046-.047z"
      ></path>
    </Icon>
  );
};

export default GeneralActionsEdit;
