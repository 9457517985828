import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersCompanyInfo: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M21 10a2 2 0 00-2 2v2h12v-2a2 2 0 00-2-2h-8zm-4 8h-6a2 2 0 00-2 2v6.746a38.003 38.003 0 0011.656 3.284 13.98 13.98 0 00-.653 3.95A42.003 42.003 0 019 31.113V38a2 2 0 002 2h10.218a14.024 14.024 0 002.707 4H11a6 6 0 01-6-6v-9.957V20a6 6 0 016-6h4v-2a6 6 0 016-6h8a6 6 0 016 6v2h4a6 6 0 016 6v5.618a14.07 14.07 0 00-4-3.466V20a2 2 0 00-2-2H17z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M34.5 26a8.5 8.5 0 100 17 8.5 8.5 0 000-17zM22 34.5C22 27.596 27.596 22 34.5 22S47 27.596 47 34.5 41.404 47 34.5 47 22 41.404 22 34.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M31.867 33.842c0-.727.59-1.316 1.317-1.316H34.5c.727 0 1.316.589 1.316 1.316v3.949a1.316 1.316 0 010 2.632H34.5c-.727 0-1.316-.589-1.316-1.316v-3.949c-.727 0-1.317-.59-1.317-1.316zM33.184 29.893c0-.727.589-1.316 1.316-1.316h.013a1.316 1.316 0 010 2.632H34.5c-.727 0-1.316-.59-1.316-1.316z"
        clipRule="evenodd"
      ></path>
    </Icon>
  );
};

export default GeneralOthersCompanyInfo;
