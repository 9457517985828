import {
  GENERAL_ADJUSTED_PAGE_MAXW,
  GENERAL_PAGE_PX,
  GENERAL_PAGE_PY,
} from '../../../../../common/src/components/GeneralPageLayout';
import { VStack, Box, BoxProps } from '@wegroup/design-system';
import React, { PropsWithChildren } from 'react';

const LouiseQAPageSection: React.FC<PropsWithChildren<BoxProps>> = ({
  children,
  ...otherProps
}) => (
  <Box px={GENERAL_PAGE_PX} py={GENERAL_PAGE_PY} {...otherProps}>
    <VStack
      maxW={GENERAL_ADJUSTED_PAGE_MAXW}
      w="100%"
      align="stretch"
      spacing="6"
      margin="0 auto"
    >
      {children}
    </VStack>
  </Box>
);

export default LouiseQAPageSection;
