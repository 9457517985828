import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationTelephoneSubscription: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_692_2736"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_692_2736)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M63.134 256.162a5.532 5.532 0 002.966 7.236l147.954 61.867a5.531 5.531 0 007.234-2.971l69.334-165.812L265.387 95 155.695 49.132a5.527 5.527 0 00-7.233 2.968L63.134 256.162z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M290.626 156.483l-38.602-16.141a5.165 5.165 0 01-2.772-6.756l16.135-38.587 25.239 61.484z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M200.906 212.678l31.826 13.308"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M183.915 194.87l-7.35-3.073a33.317 33.317 0 01-13.757-10.731 33.716 33.716 0 01-6.504-16.267l17.229 7.205a6.237 6.237 0 004.729-.087 6.353 6.353 0 003.346-3.386 6.353 6.353 0 00.06-4.76 6.236 6.236 0 00-3.259-3.427l-21.077-8.813a30.903 30.903 0 013.195-7.641l30.702 12.839a6.22 6.22 0 004.868.089 6.294 6.294 0 002.087-1.37 6.37 6.37 0 001.894-4.528 6.299 6.299 0 00-1.888-4.506 6.216 6.216 0 00-2.086-1.345L169.25 133.84a33.708 33.708 0 0116.146-6.792 33.297 33.297 0 0117.299 2.26l7.35 3.073a6.234 6.234 0 004.728-.087 6.346 6.346 0 003.346-3.386 6.35 6.35 0 00.061-4.759 6.235 6.235 0 00-3.26-3.427l-7.35-3.074a45.805 45.805 0 00-27.152-2.504 46.466 46.466 0 00-23.918 13.373l-9.766-4.084a6.22 6.22 0 00-4.868-.089 6.358 6.358 0 00-3.487 3.445 6.363 6.363 0 00-.494 2.453c0 .842.166 1.675.491 2.448a6.226 6.226 0 003.483 3.403l7.048 2.947c-.636 1.213-1.249 2.448-1.782 3.725-.534 1.276-.988 2.593-1.407 3.902l-7.048-2.948a6.22 6.22 0 00-4.869-.089 6.314 6.314 0 00-2.087 1.37 6.38 6.38 0 00-1.893 4.528 6.316 6.316 0 00.49 2.448c.325.774.8 1.474 1.398 2.058a6.206 6.206 0 002.086 1.345l9.765 4.083a46.469 46.469 0 007.281 26.417 45.812 45.812 0 0020.852 17.57l7.35 3.074a6.234 6.234 0 004.728-.087 6.35 6.35 0 003.346-3.386 6.353 6.353 0 00.061-4.76 6.241 6.241 0 00-3.26-3.427l-.003.007z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M71.995 81.1v134.511-134.51zm0 0A12.092 12.092 0 0184.089 69L71.995 81.1zm12.094 144.606h0a10.1 10.1 0 01-7.138-2.956 10.094 10.094 0 01-2.956-7.138V81.099A10.093 10.093 0 0184.088 71h62.726a10.097 10.097 0 0110.094 10.095V215.61s0 0 0 0a10.1 10.1 0 01-10.097 10.095H84.089z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M81.554 93.67h67.797v107.476H81.554V93.67z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M111.673 80.335l20.784-.002"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M102.225 80.333a1.887 1.887 0 01-1.167 1.745 1.888 1.888 0 01-2.468-2.468 1.889 1.889 0 013.635.723z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M108.838 213.424a6.615 6.615 0 006.613 6.614 6.617 6.617 0 006.614-6.614 6.613 6.613 0 00-13.227 0z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationTelephoneSubscription;
