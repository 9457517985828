import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsEye: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => {
  return (
    <Icon viewBox="0 0 50 50" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.825 17.19C10.185 19.237 8.12 21.925 7 25c2.55 7 10 12 18 12 2.248 0 4.453-.395 6.518-1.118l-2.722-2.722a8.998 8.998 0 01-12.623-9.916c.14-.708.366-1.392.667-2.04l-4.014-4.015zm20.257 11.77A8.999 8.999 0 0021.04 16.918l-2.737-2.736A19.752 19.752 0 0125 13c8 0 15.45 5 18 12-1.14 3.127-3.256 5.855-5.963 7.916l-3.955-3.956z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M29.934 25.813a5 5 0 00-5.747-5.747l5.747 5.747z"
        clipRule="evenodd"
      ></path>
      <rect
        width="39"
        height="3"
        x="13.121"
        y="9"
        fill="currentColor"
        rx="1.5"
        transform="rotate(45 13.121 9)"
      ></rect>
    </Icon>
  );
};

export default GeneralActionsEye;
