import React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralActionsCaretLeft: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M17.026 25.024a1 1 0 010-1.416l10.61-10.581c.63-.629 1.706-.182 1.706.708v21.162c0 .89-1.076 1.336-1.706.708l-10.61-10.581z"
    />
  </Icon>
);

export default GeneralActionsCaretLeft;
