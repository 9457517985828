import { Text } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LouiseChatBox } from './LouiseSingleChatWrapper';

const ErrorChatMessage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <LouiseChatBox>
      <Text m="3" color="danger.main">
        {t('Something went wrong')}
      </Text>
    </LouiseChatBox>
  );
};

export default ErrorChatMessage;
