import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsVehicleHealth: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M46.61 11.9l-8.5-1.9h-.22l-8.5 1.89a.51.51 0 00-.39.53l.54 7.58a12.061 12.061 0 005.63 9.37l2.56 1.6a.48.48 0 00.54 0l2.56-1.6A12.06 12.06 0 0046.46 20l.54-7.57a.51.51 0 00-.39-.53zM42 21h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2zM43.9 35.37h-.3l-.6.09-11 1.59-1-.43-2-.86-3.42-1.46a1 1 0 01.42-1.83c.116.001.23.022.34.06l2.71 1 2 .75.26.1a1.739 1.739 0 001.667-.247 1.78 1.78 0 00-.037-2.873L31 29.87c-.62-.46-1.31-.95-2-1.45l-2.71-2a7.58 7.58 0 00-7.1-1l-3.67 1.37c-.345.133-.71.2-1.08.2H9v-1.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-1.5h5.59c.273.002.543.05.8.14L31 42.87a3.18 3.18 0 002.05.02l6.23-1.84L43 39.94l1.49-.44a2.11 2.11 0 00-.59-4.13z"
    ></path>
  </Icon>
);

export default RiskObjectsVehicleHealth;
