import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationRecreational: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_3824_10656"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_3824_10656)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M91.973 46.74c-6.415 42.786 3.78 59.908 41.738 77.438 17.727 10.955 23.853 19.335 29.916 37.893"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M91.133 45.393a1.315 1.315 0 011.589-.943l203.986 50.51a4.098 4.098 0 11-2.075 7.929L92.056 46.995a1.315 1.315 0 01-.923-1.602z"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M253.86 83.66l42.73 11.183a4.18 4.18 0 11-2.116 8.088l-42.731-11.183 2.117-8.088z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M260.672 85.63l-9.059 5.896M267.704 87.472l-9.059 5.896M274.737 89.312l-9.059 5.896M281.775 91.153l-9.059 5.897M288.807 92.995l-9.059 5.897M295.839 94.835l-9.059 5.896"
        ></path>
        <mask id="path-13-inside-1_3824_10656" fill="#fff">
          <rect
            width="8.725"
            height="21.085"
            x="249.315"
            y="75.894"
            rx="2"
            transform="rotate(14.666 249.315 75.894)"
          ></rect>
        </mask>
        <rect
          width="8.725"
          height="21.085"
          x="249.315"
          y="75.894"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="8"
          mask="url(#path-13-inside-1_3824_10656)"
          rx="2"
          transform="rotate(14.666 249.315 75.894)"
        ></rect>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M175.196 178.323c-.065 8.427-5.351 14.319-10.834 14.276-5.484-.042-10.678-6.014-10.613-14.441.065-8.428 5.35-14.319 10.834-14.277 5.484.043 10.678 6.015 10.613 14.442z"
        ></path>
        <mask
          id="mask1_3824_10656"
          style={{ maskType: 'alpha' }}
          width="27"
          height="34"
          x="151"
          y="161"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeWidth="4"
            d="M175.193 178.323c-.065 8.427-5.351 14.319-10.834 14.276-5.484-.042-10.678-6.014-10.613-14.441.065-8.428 5.35-14.319 10.834-14.277 5.484.043 10.678 6.015 10.613 14.442z"
          ></path>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_3824_10656)"
        >
          <path
            fill={colors.primary[100]}
            d="M147.199 178.289H183.18900000000002V196.10199999999998H147.199z"
            transform="rotate(.442 147.199 178.289)"
          ></path>
          <path d="M175.193 178.323c-.065 8.427-5.351 14.319-10.834 14.276-5.484-.042-10.678-6.014-10.613-14.441.065-8.428 5.35-14.319 10.834-14.277 5.484.043 10.678 6.015 10.613 14.442z"></path>
        </g>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M164.352 193.75l-.053 6.968s-.046 4.18-4.213 4.148c-4.167-.032-4.149-4.213-4.149-4.213M164.353 193.75l-.054 6.968s-.018 4.18 4.149 4.212c4.167.033 4.213-4.148 4.213-4.148"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationRecreational;
