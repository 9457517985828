import darkBlue from './darkBlue';
import darkGreen from './darkGreen';
import darkGrey from './darkGrey';
import darkRed from './darkRed';
import lightBlue from './lightBlue';
import lightGreen from './lightGreen';
import lightRed from './lightRed';
import oceanBlue from './oceanBlue';
import grayBlue from './grayBlue';
import oceanGreen from './oceanGreen';
import pinkPurple from './pinkPurple';
import sidefishBlue from './sidefishBlue';
import orange from './orange';
import deepPurple from './deepPurple';
import elvas from './elvas';
import hillewaereBlue from './hillewaereBlue';
import immothekerOrange from './immothekerOrange';
import paardeKooper from './paardeKooper';
import fidus from './fidus';
import verzekerje from './verzekerje';

/**
 * These are all the available colors.
 * Some should not be visible to the user.
 * Reference: https://www.figma.com/file/ochSvYcIzcv14PFAyUjBKA/Louise-v2?node-id=3603%3A28353
 */
const colors = {
  /// Colours available to all brokers
  darkBlue,
  darkGreen,
  darkGrey,
  darkRed,
  lightBlue,
  lightGreen,
  lightRed,
  oceanBlue,
  oceanGreen,
  pinkPurple,
  sidefishBlue,
  orange,
  deepPurple,
  grayBlue,

  // Project/company specific colours
  elvas,
  hillewaereBlue,
  verzekerje,
  immothekerOrange,
  paardeKooper,
  fidus,
};

export default colors;
