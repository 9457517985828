import * as React from 'react';

const LouiseAvatar = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="92"
    height="91"
    fill="none"
    viewBox="0 0 92 91"
  >
    <g filter="url(#filter0_d_3_1558)">
      <circle cx="46" cy="43.5" r="38.5" fill="#fff"></circle>
    </g>
    <mask
      id="mask0_3_1558"
      style={{ maskType: 'alpha' }}
      width="78"
      height="77"
      x="7"
      y="5"
      maskUnits="userSpaceOnUse"
    >
      <circle cx="46" cy="43.5" r="38.5" fill="#fff"></circle>
    </mask>
    <g mask="url(#mask0_3_1558)">
      <path
        fill="#FF8000"
        d="M52.474 114.591c24.098-73.298 8.58-86.98 4.845-91.676a1.747 1.747 0 00-1.046-.64l-11.697-2.327-9.84 6.34-.768 1.024c-.193.278-2.028 5.016-2.199 5.464a125.991 125.991 0 00-1.686 4.76c-1.067 3.18-2.007 6.383-2.924 9.606-1.857 6.531-3.608 13.105-5.828 19.53-2.39 6.916-5.357 13.66-9.22 19.894-1.068 1.728-2.135 3.457-3.224 5.186-.661 1.068-.832 1.58-1.387 2.732v20.107h44.974z"
      ></path>
      <path
        fill="#31213C"
        d="M77.49 114.591l-3.65-31.654c-.598-5.165-4.141-9.52-9.072-11.185l-10.373-2.817H39.688l-10.374 2.817a13.428 13.428 0 00-9.071 11.185l-3.629 31.654H77.49z"
      ></path>
      <path
        fill="#EFEAE6"
        d="M53.306 114.591l2.049-45.336H38.748l2.05 45.336h12.508z"
      ></path>
      <path
        fill="#554561"
        d="M40.84 114.592l-2.049-45.337.32-1.728-2.155 2.305-3.95 6.638 5.53 38.122h2.304zM53.285 114.592h2.305l5.528-38.122-3.948-6.638-2.156-2.306.341 1.73-2.07 45.336z"
      ></path>
      <path
        fill="url(#paint0_linear_3_1558)"
        d="M55.334 69.084c-.683-3.223-.918-7.492-.982-10.886-1.43 2.07-3.244 3.757-5.72 4.461a5.455 5.455 0 01-3.095 0 10.15 10.15 0 01-1.516-.598c-1.772-.896-3.116-2.305-4.226-3.927-.064 3.394-.299 7.706-.982 10.95-.021.064-.043.107-.043.17 0 1.046 3.714 1.9 8.303 1.9 4.59 0 8.304-.853 8.304-1.9-.022 0-.022-.127-.043-.17z"
      ></path>
      <path
        fill="#EECEBA"
        d="M62.377 39.18c-.235-.064-.491 0-.747.15.299-2.477.341-3.586.064-6.041a23.52 23.52 0 00-1.153-5.166c-.79-2.347-3.03-5.485-5.016-6.873-2.497-1.729-5.507-2.56-8.474-2.56-2.988 0-5.976.831-8.474 2.56-1.985 1.388-4.226 4.526-5.037 6.873-.576 1.665-.94 3.394-1.153 5.166-.277 2.455-.213 3.564.064 6.04-.256-.149-.512-.213-.747-.149-1.003.256-1.323 2.583-.704 5.166.619 2.582 1.942 4.46 2.946 4.183a.588.588 0 00.32-.17c.064-.065.15-.022.17.042a48.652 48.652 0 002.135 5.486c1.75 3.778 3.586 8.004 7.385 9.925a8.281 8.281 0 001.516.598c.512.15 1.024.213 1.536.213.513 0 1.025-.064 1.537-.213.513-.15 1.025-.363 1.516-.598 3.8-1.92 5.635-6.147 7.385-9.925a48.652 48.652 0 002.135-5.486c.021-.085.128-.107.17-.043.107.086.214.15.32.171 1.004.256 2.327-1.6 2.946-4.183.683-2.583.363-4.888-.64-5.166z"
      ></path>
      <path
        fill="#E0B8A4"
        d="M49.421 51.047a.295.295 0 00-.277-.213H44.96a.295.295 0 00-.278.213.274.274 0 00.128.32l.876.513c.426.256.896.384 1.366.384.47 0 .939-.128 1.366-.384l.875-.512a.274.274 0 00.128-.32z"
      ></path>
      <path
        fill="#0E0E0E"
        d="M40.648 44.92c.684 0 1.238-.86 1.238-1.92 0-1.061-.554-1.921-1.238-1.921-.683 0-1.238.86-1.238 1.92 0 1.062.555 1.922 1.238 1.922zM53.477 44.92c.683 0 1.238-.86 1.238-1.92 0-1.061-.555-1.921-1.239-1.921-.683 0-1.237.86-1.237 1.92 0 1.062.554 1.922 1.238 1.922z"
      ></path>
      <path
        fill="#0E0E0E"
        d="M51.663 42.253c-.021 0-.043 0-.085-.021a.14.14 0 01-.043-.193c.448-.725 1.153-1.13 1.985-1.13.085 0 .811.02 1.473.789.17.213.384.32.533.256.107-.043.171-.15.171-.32 0-.086.064-.15.15-.15.085 0 .149.064.149.15 0 .277-.128.49-.363.576-.256.107-.576-.021-.832-.341-.449-.534-1.004-.683-1.238-.683-.513 0-1.238.17-1.75 1.003-.065.043-.107.064-.15.064zM42.463 42.254c-.043 0-.086-.022-.128-.064-.513-.812-1.238-1.004-1.75-1.004-.257 0-.79.15-1.239.683-.256.32-.576.449-.832.342-.214-.085-.363-.299-.363-.576 0-.086.064-.15.15-.15.085 0 .149.064.149.15 0 .149.064.277.17.32.15.042.342-.043.534-.256.64-.769 1.366-.79 1.452-.79.832 0 1.536.405 1.985 1.131a.139.139 0 01-.043.192c-.021 0-.064.022-.085.022z"
      ></path>
      <path
        fill="#E0B8A4"
        d="M62.015 45.563l-.769-1.302c.491-1.281.3-2.348.278-2.412-.022-.086-.086-.128-.17-.107-.086.021-.129.085-.108.17 0 .022.385 1.943-1.11 3.63-.042.063-.042.149.022.191a.162.162 0 00.085.043.163.163 0 00.107-.043c.341-.384.576-.768.747-1.13l.662 1.109c.021.043.064.064.128.064.021 0 .042 0 .064-.021a.172.172 0 00.064-.192zM33.711 45.54c-1.494-1.686-1.11-3.607-1.11-3.628.022-.085-.042-.15-.106-.17-.086-.022-.15.042-.171.106-.021.064-.214 1.131.277 2.412l-.768 1.302a.139.139 0 00.043.192c.02.021.042.021.064.021.042 0 .085-.02.128-.064l.661-1.11c.171.385.427.769.747 1.132a.163.163 0 00.107.042c.043 0 .064-.02.086-.042.085-.043.085-.15.042-.192z"
      ></path>
      <path
        fill="#FF8000"
        d="M56.572 38.454c-.064 0-.15-.02-.192-.085-1.537-1.451-3.287-1.622-4.91-.47a.302.302 0 01-.405-.064.302.302 0 01.064-.405c1.857-1.302 3.907-1.11 5.636.534a.297.297 0 01.02.405.45.45 0 01-.213.085zM37.575 38.454a.278.278 0 01-.192-.49c1.729-1.623 3.778-1.815 5.635-.535.128.086.17.256.064.406-.085.128-.277.17-.406.064-1.643-1.131-3.372-.982-4.909.47-.064.042-.128.085-.192.085z"
      ></path>
      <path
        fill="#FF8000"
        d="M57.682 37.11c2.775 2.753 3.97 2.22 3.97 2.22.3-2.477.342-3.586.064-6.041a23.52 23.52 0 00-1.152-5.166c-.811-2.347-3.031-5.485-5.038-6.873-2.497-1.729-5.485-2.56-8.452-2.56-2.988 0-5.977.831-8.474 2.56-1.985 1.388-4.226 4.526-5.037 6.873-.577 1.665-1.132 3.864-1.345 5.614-.278 2.455-.576 3.693.256 5.593 1.985-1.58 3.309-4.824 3.309-8.304 0-1.665-.32-3.223-.854-4.589 5.677 7.321 14.642 2.668 22.753 10.673z"
      ></path>
      <path
        fill="#E0B8A4"
        d="M50.937 55.338c-.598 1.836-2.07 3.415-3.8 3.415-1.728 0-3.18-1.58-3.799-3.415h7.599z"
      ></path>
      <path
        fill="#D6A894"
        d="M43.296 55.338s2.262-.576 3.97-.576c1.708 0 3.693.576 3.693.576h-7.663z"
      ></path>
      <path
        fill="#EFEAE6"
        d="M50.937 55.338a4.09 4.09 0 01-3.8 2.562 4.08 4.08 0 01-3.799-2.562h7.599z"
      ></path>
    </g>
    <defs>
      <filter
        id="filter0_d_3_1558"
        width="91"
        height="91"
        x="0.5"
        y="0"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        ></feColorMatrix>
        <feOffset dy="2"></feOffset>
        <feGaussianBlur stdDeviation="3.5"></feGaussianBlur>
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3_1558"
        ></feBlend>
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_3_1558"
          result="shape"
        ></feBlend>
      </filter>
      <linearGradient
        id="paint0_linear_3_1558"
        x1="47.064"
        x2="47.064"
        y1="70.84"
        y2="56.181"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EECEBA"></stop>
        <stop offset="1" stopColor="#E3B487"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default LouiseAvatar;
