import React from 'react';
import { ColorProps, useTheme } from '@wegroup/design-system';
import { get } from 'lodash';
// I ignore the next line because otherwise I'd need to add a fake
// type declaration for wg-fe-ui/dist/components/Icons
// eslint-disable-next-line
// @ts-ignore
import * as oldDesignSystemIcons from 'wg-fe-ui/dist/components/Icons';
import * as designSystemIcons from '../../../assets/icons';

// Yes, I need this. Don't ask me why, but if I try doing the same thing
// to oldDesignSystemIcons as I do to designSystemIcons, it won't work.
// Probably because the old design system is not TS-native
const oldKeys = [
  'IconCarFilled',
  'IconFireFilled',
  'IconTheftFilled',
  'IconOthersFilled',
  'IconTaxFilled',
  'IconLegalFilled',
  'IconNotificationBellFilled',
  'IconFlightFilled',
  'IconConsumerFilled',
  'IconMechanicalBreakdownFilled',
  'IconOilFilled',
  'IconHistoryFilled',
  'IconIncomeFilled',
  'IconElectricityFilled',
  'IconThawingFilled',
  'IconWaterDamageFilled',
  'IconSnowPressureFilled',
  'IconCracksFilled',
  'IconDryRotFilled',
  'IconScorchDamageFilled',
  'IconPropertyFilled',
  'IconBaggageFilled',
  'IconNaturalDisasterFilled',
  'IconAssistancePersonFilled',
  'IconAssistanceCarFilled',
  'IconNewCarFilled',
  'IconLookingForCarFilled',
  'IconBoughtCarFilled',
  'IconSecondHandCarFilled',
  'IconAccidentFilled',
  'IconWebWorldFilled',
  'IconWebFilled',
  'IconPersonalLiabilityFilled',
  'IconLiabilitybuildingFilled',
  'IconLandlordFilled',
  'IconTenantFilled',
  'IconGlassBreakageFilled',
  'IconActionDropDown',
  'IconActionClose',
  'IconActionMarker',
  'IconActionPhone',
  'IconActionPower',
  'IconActionChevronLeft',
  'IconActionChevronRight',
  'IconActionEye',
  'IconActionEyeCrossed',
  'IconActionRead',
  'IconActionUnRead',
  'IconActionPlus',
  'IconActionMessage',
  'IconStatusExclamation',
  'IconStatusCheck',
  'IconStudent',
  'IconDashboard',
  'IconSettingsFilled',
  'IconUnderwritingFilled',
  'IconClaimsFilled',
  'IconToolkitFilled',
  'IconFamilyFilled',
  'IconActivePVFilled',
  'IconWitnessFilled',
  'IconQualityFilled',
  'IconSmsFilled',
  'IconWorkAccidentFilled',
  'IconIdFilled',
  'IconDocumentFilled',
  'IconAtticFilled',
  'IconBasementFilled',
  'IconPassiveHouseFilled',
  'IconConstructionFilled',
  'IconStoneFilled',
  'IconCornFilled',
  'IconMeasureFilled',
  'IconJewelryFilled',
  'IconArtFilled',
  'IconFurnitureFilled',
  'IconGarageFilled',
  'IconGuestsFilled',
  'IconPropertyExtraFilled',
  'IconHomeOfficeFilled',
  'IconPoolFilled',
  'IconShedFilled',
  'IconGardenFilled',
  'IconVacationFilled',
  'IconSurroundingsFilled',
  'IconPetsFilled',
  'IconEStepFilled',
  'IconBikeFilled',
  'IconSharedEconomyFilled',
  'IconSolarPanelsFilled',
  'IconDisabilityFilled',
  'IconChildrenFilled',
  'IconComputerFilled',
  'IconAllRisksFilled',
  'IconTenPercentFilled',
  'IconDroneFilled',
  'IconEmergencyBrakeFilled',
  'IconCruiseControlFilled',
  'IconSupportFilled',
  'IconBlindSpotFilled',
  'IconDamageAnimalsFilled',
  'IconLaneControlFilled',
  'IconBAPlusPlusFilled',
  'IconBAPlusFilled',
  'IconReplacementFilled',
  'IconAttentionAssistanceFilled',
  'IconECallFilled',
  'IconCoverageFilled',
  'IconJokerFilled',
  'IconExtraObjectDamageFilled',
  'IconDriverFilled',
  'IconExtraCarDamageFilled',
  'IconMirrorBreakageFilled',
  'IconParkingFilled',
  'IconTravellingFilled',
  'IconSearchCarFilled',
  'IconSavingFilled',
  'IconFlightAcceptedFilled',
  'IconFlightCanceledFilled',
  'IconFlightDelayedFilled',
  'IconFlightMissedFilled',
  'IconCancelFlightFilled',
  'IconMobilityFilled',
  'IconCertificateFilled',
  'IconInjuredFilled',
  'IconDragHandlerFilled',
  'Upload',
  'IconPlusFilled',
  'IconPencilFilled',
  'IconCabrioFilled',
  'IconSuvFilled',
  'IconSportsCarFilled',
  'IconCloseFamily',
  'IconHeavyWork',
  'IconParkingAids',
  'IconPedestrian',
  'IconPrint',
  'IconStats',
  'IconWoman',
  'IconPorch',
  'IconElectricBike',
  'IconHeavyScooter',
  'IconLightScooter',
  'IconMotor',
  'IconCompanyCar',
  'IconSixWheeler',
  'IconMinus',
  'IconEngineFilled',
  'IconInfoFilled',
  'IconPassengerFilled',
  'IconHealthFilled',
  'IconBAXL',
  'IconBAPlusPlusXL',
  'IconDownloadFilled',
  'IconVanFilled',
  'IconJeepFilled',
  'IconShoppingCartFilled',
  'IconShoppingBasket',
  'IconChevronDown',
  'GeneralActionsDuplicate',
  'StatusIcon',
  'Icon',
  'IconPopup',
  'IconCustomLink',
  'IconBedroomFilled',
  'IconBathroomFilled',
  'IconDressingFilled',
  'IconFireplaceFilled',
  'IconKitchenFilled',
  'IconOtherRoomsFilled',
  'IconPlayroomFilled',
  'IconPrivateHomeOfficeFilled',
  'IconStorageRoomFilled',
  'IconWashingRoomFilled',
  'IconWellnessRoomFilled',
  'IconStar',
  'IconCarGlassBreakage',
  'IconCarCollision',
  'IconResetFilled',
  'IconPrintWhite',
  'IconCompare',
  'IconClockFilled',
  'IconArrow',
  'IconBreadCrumbChevronRight',
  'IconSpeedAssistance',
  'IconRearCrossTrafficWarning',
  'IconRearEmergencyBreaking',
  'IconLaneCenteringAssist',
  'IconLaneDepartingWarning',
  'IconPedestrianDetection',
  'IconPerformance',
  'IconElectronicStabilityControl',
  'IconForwardCollisionWarning',
  'IconFrontEmergencyBraking',
  'IconHighSpeedEmergencyBreaking',
  'IconABS',
  'IconCityEmergencyBraking',
  'IconSafetySystems',
  'IconSafetySystemsPlus',
  'IconThumbsUp',
  'IconThumbsDown',
  'IconMeasurePlus',
  'IconHousePlus',
  'IconDistances',
  'IconRooms',
  'IconAppartment',
  'IconAttached',
  'IconSemiDetached',
  'IconFullscreen',
  'IconSearch',
  'IconStarFilled',
  'IconStarOutline',
  'IconStarHalfFilled',
  'IconPencilEdit',
  'IconExpandScreen',
  'IconChevronLeftRounded',
  'IconFuneral',
  'IconSupport',
  'IconWriting',
  'IconUser',
  'IconUserCircle',
  'IconShrinkScreen',
  'IconOriginConnection',
  'IconEmptyCircle',
  'IconCalendar',
  'IconCalendarTime',
] as const;

export type IconName = keyof typeof designSystemIcons | typeof oldKeys[number];

export interface IconProps {
  color?: ColorProps['color'];
  boxSize?: string | number;
  name?: IconName;
}

const index = React.forwardRef<SVGElement, IconProps>(
  ({ color, boxSize = 6, name = 'IconOthersFilled', ...otherProps }, ref) => {
    const { colors } = useTheme();

    // If color provided was NOT HEX (was for example primary.400)
    // It will get the color from our theme colors :)
    const hexColorRx = new RegExp(/^#[0-9a-f]{3,6}$/i);
    if (
      typeof color === 'string' &&
      !hexColorRx.test(color) &&
      color?.toLowerCase() !== 'currentcolor'
    ) {
      color = get(colors, color);
    }

    if (designSystemIcons[name]) {
      const Icon = designSystemIcons[name];
      return <Icon color={color} boxSize={boxSize} {...otherProps} ref={ref} />;
    }

    if (oldDesignSystemIcons[name]) {
      const Icon = oldDesignSystemIcons[name];
      const size = typeof boxSize === 'string' ? parseInt(boxSize) : boxSize;
      return <Icon color={color} {...otherProps} size={size * 4} ref={ref} />;
    }

    const Icon = oldDesignSystemIcons.IconOthersFilled;
    return <Icon color={color} boxSize={boxSize} {...otherProps} ref={ref} />;
  },
);

export default index;
