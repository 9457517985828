export const BASE_URL = process.env.REACT_APP_API_HOST;
export const CONFIGCAT_KEY = process.env.REACT_APP_CONFIGCAT_KEY;
export const AFFINITY_BASE_URL = process.env.REACT_APP_AFFINITY_BASE_URL;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const RELEASE = process.env.REACT_APP_RELEASE;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const SSO_REDIRECT_URI = process.env.REACT_APP_SSO_REDIRECT_URI;
export const SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID;
export const SSO_HOST = process.env.REACT_APP_SSO_HOST;
export const REST_HOST = process.env.REACT_APP_REST_HOST;
export const AUTH_HOST = process.env.REACT_APP_AUTH_HOST;
export const PROJECT_NAME = 'wg-fe-broker-platform';
export const LAUNCH_DARKLY_CLIENT_ID =
  process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID;
