import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const GeneralOthersTag: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      d="M16.628 7c-2.06 0-3.625-.004-4.919.142-1.294.146-2.43.463-3.269 1.303-.84.84-1.155 1.976-1.3 3.27C6.995 13.008 7 14.57 7 16.628v3.46a9.63 9.63 0 0 0 2.82 6.807l11.337 11.337c.053.054.11.105.172.15 1.374 1.375 2.447 2.449 3.425 3.23 1.018.81 2.047 1.389 3.235 1.388 1.187 0 2.214-.58 3.231-1.392.984-.784 2.06-1.863 3.444-3.247.05-.042.096-.087.14-.135l3.339-3.368.008-.008c.028-.025.055-.051.08-.078 1.455-1.455 2.566-2.556 3.377-3.573C42.42 30.182 43 29.155 43 27.967c0-1.187-.577-2.213-1.389-3.231-.795-.998-1.888-2.085-3.301-3.498a1.411 1.411 0 0 0-.078-.08L26.895 9.82A9.63 9.63 0 0 0 20.088 7h-3.46V7Zm0 2.75h3.46c1.824 0 3.573.726 4.862 2.015l11.27 11.267c.021.024.044.047.067.07 1.456 1.456 2.529 2.54 3.172 3.347.644.807.79 1.243.79 1.518 0 .275-.149.709-.792 1.515-.632.792-1.687 1.86-3.1 3.275a.425.425 0 0 0-.08.075l-3.327 3.36c-.035.03-.07.062-.102.095-1.454 1.454-2.538 2.526-3.344 3.17-.807.643-1.243.792-1.518.792-.275 0-.708-.146-1.515-.79-.807-.643-1.892-1.717-3.347-3.172a1.367 1.367 0 0 0-.167-.145L11.766 24.95a6.876 6.876 0 0 1-2.015-4.862v-3.46c0-2.056.009-3.582.124-4.607.115-1.026.316-1.439.51-1.633.194-.195.608-.398 1.633-.513 1.026-.116 2.551-.124 4.61-.124Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M16.628 19.354a2.734 2.734 0 0 0 2.729-2.738 2.734 2.734 0 0 0-2.729-2.739 2.734 2.734 0 0 0-2.729 2.739 2.734 2.734 0 0 0 2.73 2.738Z"
      fill="currentColor"
    />
  </Icon>
);

export default GeneralOthersTag;
