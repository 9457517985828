import React, { useLayoutEffect } from 'react';
import { isSocialLoginUrl } from '../constants/SocialAuth';
import useAuth from '../hooks/useAuth';
import useCaptchaV3 from '../hooks/useCaptchaV3';

interface Props {
  isAuthorized: boolean;
  isAccessTokenExpired: boolean;
  email: string;
}

const CaptchaComponentV3: React.FC<Props> = ({
  isAuthorized,
  isAccessTokenExpired,
  email,
}) => {
  const { isAllConversationsDataFetched } = useAuth();
  const { executeCaptcha, isCaptchaAvailable } = useCaptchaV3();

  const handleCaptcha = async () => {
    await executeCaptcha(email);
  };

  useLayoutEffect(() => {
    if (
      (!isAuthorized || isAccessTokenExpired) &&
      isCaptchaAvailable &&
      isAllConversationsDataFetched &&
      isSocialLoginUrl
    )
      handleCaptcha();
  }, [isAccessTokenExpired, isCaptchaAvailable, isAllConversationsDataFetched]);

  return null;
};

export default CaptchaComponentV3;
