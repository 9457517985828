import React from 'react';
import { Checkbox, Spacer, HStack, Text } from '@wegroup/design-system';
import { Option } from '.';

interface Props {
  option: Option;
  isChecked?: boolean;
  onClick?: () => void;
}

const SearchPickerOption: React.FC<React.PropsWithChildren<Props>> = ({
  option,
  isChecked = false,
  onClick,
}) => {
  return (
    <HStack
      onMouseUp={onClick}
      spacing="3"
      p="3"
      key={option.value}
      bg="white"
      _hover={{ bg: 'gray.ultraLight' }}
      cursor="pointer"
      borderBottom="1px solid"
      borderColor="gray.strokeAndBg"
      _last={{ borderBottom: 'none' }}
      data-test-id={`COMPONENT_SEARCH-PICKER_SINGLE-OPTION`}
    >
      <Text
        textStyle={isChecked ? 'normalBodyMedium' : 'normalBody'}
        transition="0.1s"
        color={isChecked ? 'black' : 'gray.400'}
      >
        {option.label}
      </Text>
      <Spacer />
      <Checkbox
        borderColor="gray.100"
        pointerEvents="none"
        isChecked={isChecked}
      />
    </HStack>
  );
};

export default SearchPickerOption;
