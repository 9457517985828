import React from 'react';
import { Text, TextProps } from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../../../../../../types/Common';

interface Props {
  pagination: Pagination;
}

const PaginationInfo: React.FC<Props & TextProps> = ({
  pagination,
  ...props
}) => {
  const { t } = useTranslation();
  const { count, current, requested_pagelen } = pagination;
  const startIndex = (current - 1) * requested_pagelen + 1;
  const endIndex = Math.min(current * requested_pagelen, count);

  if (!count) return null;

  return (
    <Text
      color="gray.500"
      {...props}
      dangerouslySetInnerHTML={{
        __html: t('DESIGN-SYSTEM_COMPONENTS_PAGINATION-INFO_TEXT', {
          first_row_index: startIndex,
          last_row_index: endIndex,
          total_count: count,
        }),
      }}
    />
  );
};

export default PaginationInfo;
