import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsLegalFamily: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentTarget"
      d="M36.56 16a3 3 0 001.22-5.73A1.47 1.47 0 0038 9.5a1.5 1.5 0 10-3 0c.001.29.084.575.24.82A3 3 0 0036.56 16zM45.8 26.09l-4.87-8.42a.65.65 0 00-.26-.26 1 1 0 00-.79-.41h-.33a5 5 0 01-6 0h-.44a1.002 1.002 0 00-.77.37.79.79 0 00-.33.3l-4.87 8.42a1.5 1.5 0 002.214 1.94 1.5 1.5 0 00.386-.44l2.87-5 .39 2.97c.03.29 0 .583-.09.86l-2.42 7.26a1 1 0 00.9 1.32H33v6.5a1.5 1.5 0 103 0V35h1v6.5a1.5 1.5 0 103 0V35h1.61a1 1 0 001-1.32l-2.42-7.26a2.09 2.09 0 01-.09-.86l.32-2.87 2.83 4.9a1.501 1.501 0 002.6-1.5h-.05zM18 18.2v11.3a1.5 1.5 0 01-1.5 1.5 1.639 1.639 0 01-.5-.09V41.5a1.5 1.5 0 11-3 0V33a1 1 0 00-2 0v8.5a1.5 1.5 0 11-3 0V30.91c-.161.056-.33.086-.5.09A1.5 1.5 0 016 29.5V18.2A2.19 2.19 0 018 16h2l1.65 1.65a.5.5 0 00.7 0L14 16h2a2.19 2.19 0 012 2.2zM11.95 15a3 3 0 100-6 3 3 0 000 6zM24.45 24a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
    ></path>
    <path
      fill="currentTarget"
      d="M21 33.91v7.59a1.5 1.5 0 103 0V35h1v6.5a1.5 1.5 0 103 0v-7.59a1.51 1.51 0 001-1.41V27a2 2 0 00-2-2l-2.13.85a1 1 0 01-.74 0L22 25a2 2 0 00-2 2v5.5a1.51 1.51 0 001 1.41z"
    ></path>
  </Icon>
);

export default RiskObjectsLegalFamily;
