import { useBoolean } from '@wegroup/design-system';
import React, { useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';

const ReactQueryDevTools: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [showDevtools, setShowDevtools] = useBoolean(false);

  const handleKeyboardInput = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === 'F12') setShowDevtools.toggle();
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardInput);
    return () => {
      window.removeEventListener('keydown', handleKeyboardInput);
    };
  }, []);

  if (!showDevtools) return null;

  return <ReactQueryDevtools initialIsOpen={true} />;
};

export default ReactQueryDevTools;
