import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationSmallCattle: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = false, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width="356"
      height="228"
      viewBox="0 0 356 228"
      fill="none"
      {...otherProps}
    >
      <mask
        id="mask0_4212_10544"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 .998h356v228H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_4212_10544)">
        <path
          fill={colors.primary[50]}
          d="M337 161.998c0 88.366-71.634 160-160 160s-160-71.634-160-160c0-88.365 71.634-160 160-160s160 71.634 160 160z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M170.97 194.924v41.075M187.566 194.924v41.075"
        ></path>
        <mask id="path-5-inside-1_4212_10544" fill="#fff">
          <path
            fillRule="evenodd"
            d="M218.266 35.188c-5.444 0-10.043 3.614-11.528 8.575a12.083 12.083 0 00-2.579-.277c-6.645 0-12.032 5.387-12.032 12.032 0 5.096 3.169 9.453 7.644 11.207-.116.95-.176 1.918-.176 2.9 0 13.06 10.588 23.649 23.65 23.649 13.061 0 23.649-10.588 23.649-23.65 0-4.975-1.536-9.591-4.161-13.4a11.974 11.974 0 002.501-7.345c0-6.645-5.387-12.032-12.032-12.032-2.365 0-4.571.683-6.431 1.861a11.996 11.996 0 00-8.505-3.52z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M218.266 35.188c-5.444 0-10.043 3.614-11.528 8.575a12.083 12.083 0 00-2.579-.277c-6.645 0-12.032 5.387-12.032 12.032 0 5.096 3.169 9.453 7.644 11.207-.116.95-.176 1.918-.176 2.9 0 13.06 10.588 23.649 23.65 23.649 13.061 0 23.649-10.588 23.649-23.65 0-4.975-1.536-9.591-4.161-13.4a11.974 11.974 0 002.501-7.345c0-6.645-5.387-12.032-12.032-12.032-2.365 0-4.571.683-6.431 1.861a11.996 11.996 0 00-8.505-3.52z"
          clipRule="evenodd"
        ></path>
        <path
          fill={colors.primary[500]}
          d="M206.738 43.763l-.854 3.907 3.622.791 1.064-3.551-3.832-1.147zm-6.967 22.962l3.971.485.376-3.078L201.23 63l-1.459 3.725zm42.962-10.501l-3.166-2.444-1.783 2.31 1.656 2.403 3.293-2.27zm-15.962-17.515l-2.828 2.829 2.265 2.263 2.704-1.714-2.141-3.379zM210.57 44.91a8.037 8.037 0 017.696-5.722v-8c-7.259 0-13.382 4.82-15.36 11.427l7.664 2.295zm-6.411 2.576c.596 0 1.173.064 1.725.184l1.707-7.816a16.082 16.082 0 00-3.432-.368v8zm-8.032 8.032a8.032 8.032 0 018.032-8.032v-8c-8.854 0-16.032 7.177-16.032 16.032h8zM201.23 63a8.036 8.036 0 01-5.103-7.482h-8c0 6.797 4.229 12.597 10.185 14.93L201.23 63zm2.365 6.624c0-.819.05-1.624.147-2.414l-7.941-.97a27.852 27.852 0 00-.206 3.384h8zm19.65 19.65c-10.853 0-19.65-8.798-19.65-19.65h-8c0 15.27 12.379 27.65 27.65 27.65v-8zm19.649-19.65c0 10.852-8.797 19.65-19.649 19.65v8c15.27 0 27.649-12.38 27.649-27.65h-8zm-3.454-11.131a19.54 19.54 0 013.454 11.131h8a27.534 27.534 0 00-4.867-15.67l-6.587 4.539zm1.794-9.614c0 1.85-.62 3.544-1.667 4.9l6.333 4.888a15.982 15.982 0 003.334-9.788h-8zm-8.032-8.032a8.032 8.032 0 018.032 8.032h8c0-8.854-7.178-16.032-16.032-16.032v8zm-4.29 1.24a7.975 7.975 0 014.29-1.24v-8c-3.145 0-6.091.91-8.572 2.483l4.282 6.757zm-10.646-2.9c2.218 0 4.219.894 5.677 2.351l5.655-5.659a15.995 15.995 0 00-11.332-4.691v8z"
          mask="url(#path-5-inside-1_4212_10544)"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M88.588 192.627c10.658-27.465 20.729-39.191 42.53-54.545h-29.043c-19.247 17.32-22.651 29.73-15.369 54.475.269.912 1.538.956 1.882.07z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M71.696 172.832c16.117-23.846 25.608-26.866 53.22-26.866 0 20.746 20.33 53.937 44.809 53.937h20.33c26.969 0 61.406-23.234 61.406-53.937.282-29.437-7.286-43.515-28.213-64.31h24.479c5.273-.254 6.559-1.677 6.223-6.223-11.617-30.288-48.543-19.5-48.543 4.15l-15.352 46.053c-12.032 19.915-32.362 19.541-48.128 0C112.469 89.125 66 118.168 66 145.966v25.198c0 3.016 4.007 4.167 5.696 1.668z"
        ></path>
        <mask
          id="mask1_4212_10544"
          style={{ maskType: 'alpha' }}
          width="193"
          height="148"
          x="64"
          y="54"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            stroke={colors.primary[500]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M71.696 172.832c16.117-23.846 25.608-26.866 53.22-26.866 0 20.746 20.33 53.937 44.809 53.937h20.33c26.969 0 61.406-23.234 61.406-53.937.282-29.437-7.286-43.515-28.213-64.31h24.479c5.273-.254 6.559-1.677 6.223-6.223-11.617-30.288-48.543-19.5-48.543 4.15l-15.352 46.053c-12.032 19.915-32.362 19.541-48.128 0C112.469 89.125 66 118.168 66 145.966v25.198c0 3.016 4.007 4.167 5.696 1.668z"
          ></path>
        </mask>
        <g
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          mask="url(#mask1_4212_10544)"
        >
          <path
            fill={colors.primary[100]}
            d="M137.363 130.201l19.915 1.244-24.479-40.66-81.735 3.32-4.15 80.075 25.724 14.107 50.203-28.628 4.564-21.99 9.958 3.319v-10.787zM194.204 134.765l-13.691-25.724V30.209l89.203-1.244-7.883 85.47-20.33 21.159-9.128-10.787-12.862 10.787-12.032-10.787-13.277 9.958z"
          ></path>
          <path d="M71.696 172.832c16.117-23.846 25.608-26.866 53.22-26.866 0 20.746 20.33 53.937 44.809 53.937h20.33c26.969 0 61.406-23.234 61.406-53.937.282-29.437-7.286-43.515-28.213-64.31h24.479c5.273-.254 6.559-1.677 6.223-6.223-11.617-30.288-48.543-19.5-48.543 4.15l-15.352 46.053c-12.032 19.915-32.362 19.541-48.128 0C112.469 89.125 66 118.168 66 145.966v25.198c0 3.016 4.007 4.167 5.696 1.668z"></path>
        </g>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          d="M224.003 81.996h14.596c5.77 0 10.447 4.677 10.447 10.447s-4.677 10.447-10.447 10.447h-2.075c-6.915 0-12.521-5.606-12.521-12.521v-8.373z"
        ></path>
        <circle
          cx="230.301"
          cy="67.549"
          r="4.979"
          fill={colors.primary[500]}
        ></circle>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226.998a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationSmallCattle;
