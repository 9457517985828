import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationBicycleCargo: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_729_2471"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_729_2471)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
        <circle
          r="26.639"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 69.64 198.699)"
        ></circle>
        <circle
          r="16.662"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 69.615 198.675)"
        ></circle>
        <circle
          r="33.482"
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 280.518 191.857)"
        ></circle>
        <circle
          r="21.121"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          transform="matrix(-1 0 0 1 280.487 191.827)"
        ></circle>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M92.009 147.666L80.189 188.1c-2.568 6.429-5.061 8.318-10.574 9.953"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M87.033 164.461l31.709 39.637a1.999 1.999 0 001.658.748l76.737-3.683 82.734-8.709"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M90.765 155.752l32.332 35.993a2 2 0 001.589.661l73.073-3.684M197.759 201.163l1.862-85.011c.003-.14.021-.283.054-.42 1.998-8.237 5.346-10.984 15.502-12.232M200.87 145.178l55.363-4.355M230.729 196.808l31.725-69.048M279.871 192.454l-24.882-50.387M245.036 125.271h29.236"
        ></path>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M192.783 152.304v28.686a2.001 2.001 0 01-1.9 1.998l-63.838 3.196a1.997 1.997 0 01-1.614-.691l-22.115-25.645c-1.074-1.245-.27-3.182 1.37-3.301l85.952-6.238a2 2 0 012.145 1.995z"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationBicycleCargo;
