import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import Avatar from '../default/ProdFacAvatar';
import {
  bph,
  customMediaHWQuery,
  mq,
} from '../../../../common/src/utils/styledComponentsUtils';
import { bp } from '../../../../design-system/src/theme/foundations/breakpoints';
import { Box, Flex } from '@wegroup/design-system';
// import DuplicateObjectTag from '../default/DuplicateObjectTag';
import { useParams } from 'react-router';
import { ProductFactoryGroup } from '../../../../../types/ProductFactory';
import { useFlowsSelector } from '../../../../common/src/utils/globalVariables';
import { parseStringForAnswers } from '../../../../flows/src/utils/stringUtils';

interface Props {
  question: string;
  description?: string;
  horizontal?: boolean;
  noPadding?: boolean;
  Tag?: ReactElement;
}

const AssistantQuestion: React.FC<React.PropsWithChildren<Props>> = ({
  question,
  description,
  horizontal,
  noPadding,
  Tag,
  ...otherProps
}) => {
  const answers = useFlowsSelector((state) => state.flows.answers);
  const questionnaire = useFlowsSelector((state) => state.flows.questionnaire);
  const position = parseInt(useParams<{ position: string }>().position);
  const currentGroup = questionnaire.groups[position] as
    | ProductFactoryGroup
    | undefined;

  return (
    <Wrapper
      horizontal={horizontal}
      noPadding={noPadding}
      {...otherProps}
      data-test-id="ProdFac_AssistantQuestion"
    >
      <StyledAvatar className="AssistantQuestion_avatar" />
      <Box>
        {currentGroup && position != null && (
          <Flex
            justifyContent={{ tablet: horizontal ? 'flex-start' : 'center' }}
            mt="4"
            mb={{ base: '3', laptop: horizontal ? '1' : '3' }}
          >
            {/* <DuplicateObjectTag group={currentGroup} /> */}
          </Flex>
        )}

        <div>
          <Question data-test-id="ProdFac_AssistantQuestion_Question">
            {parseStringForAnswers({ answers, question })}
          </Question>
          {description && (
            <Description data-test-id="ProdFac_AssistantQuestion_Description">
              {parseStringForAnswers({ answers, question: description })}
            </Description>
          )}
        </div>
      </Box>
    </Wrapper>
  );
};

const StyledAvatar = styled(Avatar)`
  display: none;
  ${mq.laptop} {
    display: block;
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.gray[500]};
  font-size: 1.8rem;
  line-height: 120%;

  ${mq.tablet} {
    text-align: center;
    font-size: 2.1rem;
  }
`;

const Question = styled.h2`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 125%;

  ${customMediaHWQuery(bp.tablet, bph.hd)} {
    margin: 1.6rem 0;
  }

  ${mq.tablet} {
    font-size: 3.2rem;
    text-align: center;
  }
`;

const Wrapper = styled.div<{ horizontal?: boolean; noPadding?: boolean }>`
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1.6rem;

  ${mq.tablet} {
    align-items: ${({ horizontal }) => (horizontal ? 'flex-start' : 'center')};
    max-width: 64rem;
  }

  ${customMediaHWQuery(bp.tablet, bph.hd)} {
    margin-bottom: 2.4rem;
  }

  ${customMediaHWQuery(bp.laptop, bph.hd)} {
    padding: ${({ noPadding }) => (noPadding ? ' 0' : '2.4rem 0 1.6rem')};
  }

  ${customMediaHWQuery(bp.laptop, bph.hdplus)} {
    padding: ${({ noPadding }) => (noPadding ? ' 0' : '5.6rem 0 2.4rem')};
  }

  ${({ horizontal }) =>
    horizontal &&
    css`
      margin-bottom: 0;
      padding: 0;

      ${mq.laptop} {
        margin-bottom: 3.2rem;
        padding: 0;
      }

      > img:first-child {
        margin-right: 3.2rem;
      }

      > div:last-child > p,
      h2 {
        margin: 0;
        text-align: left;
      }
    `}
`;

export default AssistantQuestion;
