import { Icon } from '@chakra-ui/react';
import React from 'react';

const RiskAnalysisProductsTooth: React.FC<React.PropsWithChildren<unknown>> = (
  props,
) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.282 7.101C11.683 5.925 13.445 5 15.256 5c3.518 0 5.566.484 7.197.871 1.025.243 1.725.404 2.547.435.822-.03 1.522-.192 2.547-.435C29.178 5.484 31.226 5 34.744 5c1.811 0 3.573.925 4.974 2.101 1.443 1.211 2.736 2.877 3.6 4.772.865 1.898 1.337 4.112 1.003 6.383-.304 2.068-1.26 4.074-3.003 5.83l-.005.073a78.218 78.218 0 01-.185 2.305c-.18 1.897-.487 4.448-1 7.017-.506 2.537-1.24 5.23-2.324 7.337C36.781 42.806 35.051 45 32.286 45c-2.605 0-4.1-1.942-4.937-3.608-.838-1.668-1.385-3.764-1.834-5.481l-.029-.113a75.819 75.819 0 00-.486-1.8 75.819 75.819 0 00-.486 1.8l-.03.113c-.448 1.717-.995 3.813-1.833 5.481C21.814 43.058 20.32 45 17.714 45c-2.765 0-4.495-2.194-5.518-4.182-1.085-2.106-1.818-4.8-2.324-7.337-.513-2.569-.82-5.12-1-7.017a78.31 78.31 0 01-.19-2.378c-1.743-1.756-2.7-3.762-3.003-5.83-.334-2.271.138-4.485 1.003-6.383.864-1.895 2.157-3.56 3.6-4.772zm2.35 15.993l.001.01.002.037.008.155a74.099 74.099 0 00.211 2.791c.172 1.819.464 4.223.94 6.61.483 2.418 1.13 4.68 1.958 6.29.89 1.73 1.606 2.013 1.962 2.013.247 0 .715-.115 1.363-1.404.628-1.25 1.082-2.952 1.567-4.809l.022-.084c.32-1.226.666-2.551 1.092-3.612.22-.547.5-1.129.877-1.631.375-.5.956-1.067 1.81-1.313l.555-.16.554.16c.855.246 1.436.813 1.811 1.313.377.502.658 1.084.877 1.631.426 1.06.772 2.386 1.092 3.612l.022.084c.485 1.857.939 3.559 1.567 4.81C31.571 40.884 32.04 41 32.286 41c.356 0 1.072-.284 1.962-2.012.829-1.61 1.475-3.873 1.958-6.29.476-2.388.768-4.792.94-6.611a74.16 74.16 0 00.22-2.946v-.038l.001-.01.036-.852.64-.564c1.469-1.293 2.126-2.677 2.32-4.002.2-1.36-.07-2.792-.685-4.143-.617-1.354-1.546-2.54-2.532-3.367C36.12 9.302 35.244 9 34.744 9c-3.06 0-4.763.404-6.258.76l-.078.018c-.996.236-2.063.49-3.348.529l-.06.001-.06-.001c-1.285-.04-2.352-.293-3.348-.53l-.063-.014-.015-.004C20.02 9.404 18.316 9 15.256 9c-.5 0-1.375.302-2.402 1.165-.986.827-1.915 2.013-2.532 3.367-.616 1.35-.885 2.784-.685 4.143.194 1.325.851 2.71 2.32 4.002l.64.564.036.853z"
      clipRule="evenodd"
    ></path>
  </Icon>
);

export default RiskAnalysisProductsTooth;
