import React from 'react';
import { Box } from '@wegroup/design-system';

interface Props {
  position: 'left' | 'right';
  isVisible: boolean;
}

const TabListShadow: React.FC<Props> = ({ position, isVisible }) => {
  const isLeft = position === 'left';
  return (
    <Box
      opacity={isVisible ? 1 : 0}
      transition="0.2s"
      position="absolute"
      h="100%"
      right={isLeft ? 'unset' : '0'}
      left={isLeft ? '0' : 'unset'}
      top="0"
      w="40px"
      bg={`linear-gradient(${
        isLeft ? '90deg' : '270deg'
      }, #F5F5F5 0%, rgba(245, 245, 245, 0) 100%)`}
      pointerEvents="none"
    />
  );
};

export default TabListShadow;
