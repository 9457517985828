import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { getI18nLanguageCode } from '../../../../i18n';
import { PreferenceExpressed } from '../../../../types/CookieBanner';
import { Box, getTheme } from '@wegroup/design-system';
import { ThemeName } from '../../../design-system/src/theme/themes';
import { camelCase } from 'lodash';

interface Props {
  themeName?: ThemeName;
}

const Iubenda: React.FC<React.PropsWithChildren<Props>> = ({
  themeName = 'orange',
}) => {
  const { colors } = getTheme(camelCase(themeName) as ThemeName);
  const buttonRef = useRef<HTMLDivElement>(null);

  // Send vanilla event to enable logRocket in TrackingProvider.tsx
  const onPreferenceExpressed = (e: PreferenceExpressed) => {
    const event = new CustomEvent('cookie-preference-given', {
      detail: e,
    });
    document.dispatchEvent(event);
  };

  const handleCookiePreferenceOpen = () => {
    if (buttonRef.current) buttonRef.current.click();
  };

  useEffect(() => {
    document.addEventListener(
      'cookie-preference-open',
      handleCookiePreferenceOpen,
    );
    return () => {
      document.removeEventListener(
        'cookie-preference-open',
        handleCookiePreferenceOpen,
      );
    };
  }, []);

  return (
    <>
      <Helmet>
        <script type="text/javascript">{`
        var _iub = _iub || [];
        _iub.csConfiguration = {
          "consentOnContinuedBrowsing":false,
          "cookiePolicyInOtherWindow":true,
          "enableTcf":true,
          "googleAdditionalConsentMode":true,
          "lang": "${getI18nLanguageCode()}",
          "perPurposeConsent":true,
          "siteId":2675053,
          "floatingPreferencesButtonDisplay": false,
          "cookiePolicyId":86243673,
          "banner":{
             "acceptButtonCaptionColor":"white",
             "acceptButtonColor": ${JSON.stringify(colors.primary[500])},
             "acceptButtonDisplay":true,
             "backgroundColor": ${JSON.stringify(colors.primary[850])},
             "closeButtonDisplay":false,
             "customizeButtonCaptionColor":"white",
             "customizeButtonColor":${JSON.stringify(colors.primary[800])},
             "customizeButtonDisplay":true,
             "listPurposes":true,
             "position":"float-bottom-center",
             "rejectButtonCaptionColor": ${JSON.stringify(colors.primary[500])},
             "rejectButtonColor":${JSON.stringify(colors.primary[150])},
             "rejectButtonDisplay":true,
             "textColor":"white"
          },
          callback: {
            onPreferenceExpressed: ${onPreferenceExpressed},
          },  
        };
      `}</script>
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/tcf/stub-v2.js"
        ></script>
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/tcf/safe-tcf-v2.js"
        ></script>
        <script
          type="text/javascript"
          src="//cdn.iubenda.com/cs/iubenda_cs.js"
          async
        ></script>
      </Helmet>
      <Box
        ref={buttonRef}
        display="none"
        className="iubenda-cs-preferences-link"
      />
    </>
  );
};

export default Iubenda;
