import { Center } from '@wegroup/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPage from '../../../../common/src/components/ErrorPage';
import useAuth from '../../features/authentication/hooks/useAuth';
import { HubspotProvider } from '../function-providers';

const UserDataError: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();

  if (!user)
    return (
      <HubspotProvider>
        <Center h="100vh">
          <ErrorPage />
        </Center>
      </HubspotProvider>
    );

  return (
    <Center h="100vh">
      <ErrorPage error={t('CONTACT-SUPPORT-EMAIL')} />
    </Center>
  );
};

export default UserDataError;
