import React from 'react';
import { Button } from '@wegroup/design-system';
import { useTranslation } from 'react-i18next';

interface Props {
  onSelect: () => void;
  isLoading?: boolean;
}

const OfferCardFooter: React.FC<Props> = ({ onSelect, isLoading }) => {
  const { t } = useTranslation();
  return (
    <Button isLoading={isLoading} size="sm" onClick={onSelect}>
      {t('SELECT-OFFER')}
    </Button>
  );
};

export default OfferCardFooter;
