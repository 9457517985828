import { Provider } from 'react-redux';
import React from 'react';
import store from '../../../../common/src/store/redux/store';
import GlobalContext from '../../../../common/src/store/context/GlobalReduxContext';

export const ReduxProviders: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  // These double Provider components should get removed once every selctor is based on GlobalSelector for global data
  return (
    <Provider store={store} context={GlobalContext}>
      <Provider store={store}>{children}</Provider>
    </Provider>
  );
};
