import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationInsuranceTravel: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_1833_7101"
        style={{ maskType: 'alpha' }}
        width="356"
        height="228"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v228H0V0z"></path>
      </mask>
      <g mask="url(#mask0_1833_7101)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.366-71.634 160-160 160S17 249.366 17 161 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <rect
          width="233.11"
          height="153.19"
          x="50"
          y="72.05"
          fill="#fff"
          rx="2"
        ></rect>
        <path
          fill={colors.primary[200]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M149.902 45.412h33.304a13.32 13.32 0 0113.32 13.32h-59.944a13.32 13.32 0 0113.32-13.32z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M203.19 72.052h33.3V225.24h-33.3V72.052z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M213.182 98.696a6.66 6.66 0 1013.318.001 6.66 6.66 0 00-13.318 0zM213.182 198.599a6.66 6.66 0 1013.32.003 6.66 6.66 0 00-13.32-.003z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M96.618 72.052h33.3V225.24h-33.3V72.052z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M106.61 98.696a6.66 6.66 0 1013.32-.001 6.66 6.66 0 00-13.32.001zM106.61 198.599a6.66 6.66 0 1013.321 0 6.66 6.66 0 00-13.321 0zM125.658 135.46a3.334 3.334 0 004.132 2.264l25.572-7.46a3.33 3.33 0 002.268-4.128l-8-27.448a3.316 3.316 0 00-1.6-2l-9.968-5.468a3.336 3.336 0 00-2.536-.276l-10.14 2.956a3.33 3.33 0 00-2 1.6l-5.456 9.976a3.336 3.336 0 00-.276 2.536l8.004 27.448z"
        ></path>
        <rect
          width="233.11"
          height="153.19"
          x="50"
          y="72.05"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="2"
        ></rect>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M196.526 58.732v13.32M136.582 58.732v13.32M133.25 102.024s-12.052-37.34-3.328-41.72a5.728 5.728 0 016.66 1.26"
        ></path>
        <circle
          cx="259.87"
          cy="170.5"
          r="67.5"
          fill={colors.primary[100]}
        ></circle>
        <mask
          id="mask1_1833_7101"
          style={{ maskType: 'alpha' }}
          width="136"
          height="135"
          x="192"
          y="103"
          maskUnits="userSpaceOnUse"
        >
          <circle
            cx="259.87"
            cy="170.5"
            r="65.5"
            fill={colors.primary[100]}
            stroke={colors.primary[500]}
            strokeWidth="4"
          ></circle>
        </mask>
        <g
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          mask="url(#mask1_1833_7101)"
        >
          <path d="M275.058 132.68l18.861-24.31a4.002 4.002 0 013.789-1.498l17.667 2.811a3.998 3.998 0 012.924 2.111l20.662 39.915c.269.521.421 1.095.444 1.681l2.375 59.848a3.998 3.998 0 01-1.804 3.503l-36.372 23.845c-2.765 1.812-6.41-.321-6.184-3.619l1.587-23.17c.182-2.653-2.228-4.742-4.828-4.185l-6.36 1.363a4 4 0 01-3.991-1.448l-10.179-13.03a4.002 4.002 0 01-.843-2.26l-1.292-25.415a4 4 0 012.467-3.901l14.779-6.104c2.655-1.096 3.323-4.551 1.268-6.558l-14.605-14.265a4 4 0 01-.365-5.314zM213.112 185.576l-27.357 1.19c-2.681.116-4.489 2.788-3.6 5.32l7.79 22.203a4.008 4.008 0 001.475 1.949l35.659 25.046c2.432 1.708 5.812.261 6.255-2.678l4.235-28.109a4.001 4.001 0 00-1.127-3.425l-20.328-20.328a4 4 0 00-3.002-1.168zM244.746 128.782l-15.639 5.371a4 4 0 00-1.244.695l-7.305 6.015a4 4 0 00-1.447 3.381l.647 8.808a4 4 0 001.351 2.714l5.456 4.787a4 4 0 006.162-1.115l5.594-10.422a4 4 0 015.1-1.784l3.438 1.474a4 4 0 004.583-1.039l4.775-5.442a4 4 0 00-.207-5.495l-7.165-7.021a4 4 0 00-4.099-.927z"></path>
        </g>
        <circle
          cx="259.87"
          cy="170.5"
          r="65.5"
          stroke={colors.primary[500]}
          strokeWidth="4"
        ></circle>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 226a2 2 0 012-2h310a2 2 0 110 4H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
};

export default IllustrationInsuranceTravel;
