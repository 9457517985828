import React from 'react';
import { IllustrationProps } from '../../../../../types/Common';
import useToggleIllustrationColors from '../../hooks/useToggleIllustrationColors';

const IllustrationMealVouchers: React.FC<
  React.PropsWithChildren<IllustrationProps>
> = ({ isActive = true, ...otherProps }) => {
  const colors = useToggleIllustrationColors(isActive);
  return (
    <svg
      width={356}
      height={228}
      viewBox="0 0 356 228"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...otherProps}
    >
      <mask
        id="mask0_683_2327"
        style={{ maskType: 'alpha' }}
        width="356"
        height="229"
        x="0"
        y="-1"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#C0C2C9" d="M0 0h356v227.999H0v-228z"></path>
      </mask>
      <g mask="url(#mask0_683_2327)">
        <path
          fill={colors.primary[50]}
          d="M337 161c0 88.365-71.634 160-160 160S17 249.365 17 161C17 72.634 88.634 1 177 1s160 71.634 160 160z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M101.362 126.052a12.111 12.111 0 01-1.59-9.566 12.093 12.093 0 015.944-7.662l14.546-7.928a12.056 12.056 0 019.663-.849 12.067 12.067 0 017.164 6.54c9.151 20.457 28.204 66.146 16.4 72.583-11.805 6.437-39.889-34.361-52.127-53.118zM107.673 107.744L86.657 97.436a3.02 3.02 0 011.53-5.56l10.631-.378-3.23-12.424a3.022 3.022 0 015.149-2.808l8.696 9.442 6.075-8.729a3.022 3.022 0 015.483 1.727l-2.741 23.253-10.577 5.785z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M104.186 130.328l20.309-11.07"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M82.918 159.322a12.094 12.094 0 016.13-16.674l15.297-6.365a12.096 12.096 0 0113.673 3.125 12.103 12.103 0 012.469 4.293c6.958 21.307 21.131 68.74 8.718 73.905-12.414 5.165-36.078-38.337-46.287-58.284z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M91.11 141.793l-19.822-12.452a3.02 3.02 0 012.1-5.367l10.615.734-1.908-12.693a3.02 3.02 0 015.412-2.248l7.676 10.297 6.952-8.049a3.031 3.031 0 013.491-.793 3.025 3.025 0 011.806 3.091l-5.143 22.836-11.18 4.644z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M85.28 163.884l21.351-8.883"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M166.878 147.166a21.823 21.823 0 11-43.646 0 21.823 21.823 0 0143.646 0z"
        ></path>
        <path
          fill="#fff"
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M197.1 148.499a21.824 21.824 0 01-26.079 21.403 21.83 21.83 0 01-17.146-17.146 21.827 21.827 0 019.279-22.402 21.822 21.822 0 0133.946 18.145z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M203.208 202.955a28.24 28.24 0 01-27.261 23.462H91.312a30.002 30.002 0 01-28.895-23.462l-4.348-27.919H209.19l-5.982 27.919z"
        ></path>
        <path
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M65.597 175.036l55.18 51.386M109.406 175.036l55.038 51.392M153.072 175.036l43.946 41.007M202.281 175.085L147.2 226.373M158.664 175.036l-55.136 51.386M115.042 174.987l-45.108 42.081"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M49 159.92h169.259v15.111H49V159.92z"
        ></path>
        <path
          fill={colors.primary[100]}
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M73.18 108.539a60.45 60.45 0 11120.899 0v51.381h12.09v-51.381a72.543 72.543 0 00-72.539-72.54 72.54 72.54 0 00-72.54 72.54v51.381h12.09v-51.381z"
        ></path>
        <rect
          width="163"
          height="85"
          x="157.517"
          y="32.483"
          fill="#fff"
          stroke={colors.primary[500]}
          strokeWidth="4"
          rx="3"
          transform="rotate(20 157.517 32.483)"
        ></rect>
        <g
          stroke={colors.primary[500]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4"
          clipPath="url(#clip0_683_2327)"
        >
          <path d="M290.423 98.102c-.926-2.39-2.489-4.348-4.493-5.628-2.005-1.28-4.361-1.825-6.773-1.566-2.412.258-4.774 1.309-6.788 3.02-2.014 1.71-3.591 4.004-4.534 6.594-.943 2.591-1.209 5.362-.766 7.967.444 2.605 1.578 4.927 3.259 6.676 1.682 1.748 3.837 2.846 6.195 3.153 2.358.308 4.814-.187 7.06-1.422M262.706 102.912l15.035 5.473m2.736-7.518l-15.035-5.472 15.035 5.472z"></path>
        </g>
        <rect
          width="60"
          height="20"
          x="163.361"
          y="45.47"
          fill={colors.primary[200]}
          rx="2"
          transform="rotate(20 163.361 45.47)"
        ></rect>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M152.244 81.862L228.359 109.566"
        ></path>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M146.772 96.897L222.887 124.601"
        ></path>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M149.508 89.38L225.623 117.084"
        ></path>
        <path
          stroke={colors.primary[100]}
          strokeLinecap="round"
          strokeWidth="4"
          d="M144.036 104.415L220.151 132.119"
        ></path>
        <path
          fill={colors.primary[500]}
          fillRule="evenodd"
          d="M20 225.999A2 2 0 0122 224h310a2 2 0 110 3.999H22a2 2 0 01-2-2z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_683_2327">
          <path
            fill="#fff"
            d="M0 0H28V32H0z"
            transform="rotate(20 -102.756 800.725)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default IllustrationMealVouchers;
