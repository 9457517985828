import React from 'react';
import { Icon } from '@chakra-ui/react';

const RiskObjectsPersonalLiabilityAllRisks: React.FC<
  React.PropsWithChildren<unknown>
> = (props) => (
  <Icon viewBox="0 0 50 50" {...props}>
    <path
      fill="currentColor"
      d="M43.5 19H40v-3.5a1.5 1.5 0 10-3 0V19h-3.5a1.5 1.5 0 100 3H37v3.5a1.5 1.5 0 103 0V22h3.5a1.5 1.5 0 100-3z"
    ></path>
    <path
      fill="currentColor"
      d="M30 20.5a2.747 2.747 0 000 .5h-3v20.5a4.51 4.51 0 01-4.95 4.5A4.64 4.64 0 0118 41.3V38a2 2 0 012.34-2A2.07 2.07 0 0122 38.11v3.39a.5.5 0 101 0V21H7.34a.51.51 0 01-.48-.65A19 19 0 0123 7.12V6a2 2 0 012.34-2A2.07 2.07 0 0127 6.11v1a18.93 18.93 0 0110.9 4.94A3.49 3.49 0 0035 15.5V17h-1.5a3.5 3.5 0 00-3.5 3.5z"
    ></path>
  </Icon>
);

export default RiskObjectsPersonalLiabilityAllRisks;
